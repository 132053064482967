import React from 'react';
import { FormattedMessage } from 'react-intl';

import IconPrivacy from './icons/IconPrivacy';

import IconSurveys from './icons/IconSurveys';

interface Props {
  title?: any;
  subTitle?: string;
}

export const PrivacyDisclaimerHeader = () => (
  <div className="cmp-privacy-header" style={{ display: 'flex', alignItems: 'center' }}>
    <IconPrivacy.Large color="rb-dark-accent" />
    <div className="font-color-rb-dark-accent font-subhead">
      <FormattedMessage id="shareCard.confidentialBlock.heading" />
    </div>
  </div>
);

export const InformedConsentHeader = () => (
  <div className="cmp-privacy-header">
    <IconSurveys.Large color="rb-dark-accent" />
    <div>
      <div className="font-color-rb-dark-accent font-subhead">
        <FormattedMessage id="informedConsent.informedConsentBlock.heading" />
      </div>
      <div className="font-color-gsMedDark font-footnote">
        <FormattedMessage id="branding.tagline.informedConsent" />
      </div>
    </div>
  </div>
);

export const GeneralPrivacyHeader = (props: Props) => (
  <div className="cmp-privacyheader-general">
    <IconPrivacy.Large color="rb-dark-accent" />
    <div className="font-color-rb-dark-accent font-subhead">
      {props.title}
    </div>
  </div>
);


const PrivacyHeader = (props: Props) => (
  <div className="cmp-privacy-header">
    <IconPrivacy.Large color="rb-dark-accent" />
    <div>
      <div className="font-color-rb-dark-accent font-subhead">
        <FormattedMessage id="privacy.privacyBlock.heading" /> {props.title ? ` – ${props.title}` : null}
      </div>
      <div className="font-color-gsMedDark font-footnote">
        <FormattedMessage id={props.subTitle || 'branding.tagline.healthinfo'} />
      </div>
    </div>
  </div>
);

export default PrivacyHeader;
