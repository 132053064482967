const cutProtocols = (url) => {
  if (!url) return url;
  return url.replace(/(^\w+:|^)\/\//, '');
};

const UrlUtil = {
  cutProtocols,
};

export default UrlUtil;
