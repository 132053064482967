import { getLocalText } from '../utilities/localizedUtil';

export interface GroupContentDataInterface {
  id: number;
  group_id: number;
  category: string;
  title: {
    default: string;
    i18n: any;
  };
  short_description: {
    default: string;
    i18n: any;
  };
  body: {
    default: string;
    i18n: any;
  };
  created_at: Date;
  updated_at: Date;
}

export default class GroupContentModel {
  public constructor(public data: GroupContentDataInterface) {
  }

  get groupId() {
    return this.data.group_id;
  }

  title = {
    get: (languageCode) => getLocalText(this.data.title, languageCode) || this.data.title.default,
  };

  description = {
    get: (languageCode) => getLocalText(this.data.short_description, languageCode) || this.data.short_description.default,
  };

  body = {
    get: (languageCode) => getLocalText(this.data.body, languageCode) || this.data.body.default,
  };
}

export function sortGroupContent(languageCode: string) {
  return (a: GroupContentModel, b: GroupContentModel) => {
    return a.title.get(languageCode).localeCompare(b.title.get(languageCode));
  };
}
