import React from 'react';

import IconRevealDotsHorizontal from './icons/IconRevealDotsHorizontal';
import IconRevealDotsHorizontalEmpty from './icons/IconRevealDotsHorizontalEmpty';

interface ExpandToggleProps {
  isExpanded: boolean;
  onToggle?: (value: boolean) => void;
  style?: any;
}

const DEFAULT_COLOR_NAME = 'gsMedLight';

class ExpandToggleControl extends React.Component<ExpandToggleProps, {}> {
  render() {
    const { isExpanded, onToggle, style } = this.props;

    return (
      <label style={{ cursor: 'pointer', height: '24px', ...style }}>
        {
          isExpanded ? <IconRevealDotsHorizontalEmpty.Medium colorName={DEFAULT_COLOR_NAME} /> : <IconRevealDotsHorizontal.Medium colorName={DEFAULT_COLOR_NAME} />
        }

        <input
          type="checkbox"
          value="isCollapsible"
          checked={isExpanded}
          onClick={(e) => { e.stopPropagation(); }}
          onChange={() => { onToggle && onToggle(!isExpanded); }}
          style={{ display: 'none' }}
        />
      </label>
    );
  }
}

export default ExpandToggleControl;
