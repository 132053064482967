
import React from 'react';

import InputWithLabel from '../../../components/InputWithLabel';

import FileModel from '../../../../models/FileModel';
import { FileSize } from '../../../localization/LocalizedFile';
import COLORS from '../../../styles/Colors';

import { Card, CardRowUI, Icons } from '../../../../bphComponents/bundle';
import { DateControl } from '../../components/attributes/DateControl';

import { Comment } from '../../components/attributes/Comment';

export const FileMetadata = (props) => {
  const { file, setFileData, showComments, nameHasAutoFocus } = props;

  const fileDate = file.getObservedDate();

  return (
    <Card.CardShape>
      <CardRowUI.InsetIcon hasBottomBorder={true}>
        <Icons.IconDocumentFile.Medium color={COLORS.gsDark} />
        <InputWithLabel
          label="fileUpload.name.field.label"
          value={file.getName()}
          initialFocus={nameHasAutoFocus}
          selectAllonFocus={true}
          onChange={(e) => {
            const newFile = new FileModel(file.data);
            newFile.setName(e.target.value);
            setFileData(newFile.data);
          }}
          hideLabel
          noForcedHeight={true}
          colorTheme="default"
          style={{ marginBottom: 8, marginTop: 0 }}
        >
          <div className="font-footnote font-color-gsDarkest">
            <FileSize fileSizeData={file.fileSizeData()} />
          </div>
        </InputWithLabel>
      </CardRowUI.InsetIcon>

      <CardRowUI.InsetIcon hasBottomBorder={true}>
        <Icons.IconCalendar.Medium color={COLORS.gsDark} />
        <DateControl
          label="fileUpload.date.field.label"
          dateData={fileDate}
          isFuzzy
          onChange={(dateData) => {
            const newFile = new FileModel(file.data);
            newFile.setDate(dateData);
            setFileData(newFile.data);
          }}
          hideLabel
          color={COLORS.gsDarkest}
          hasToday={true}
        />
      </CardRowUI.InsetIcon>

      {
        showComments && (
          <Comment
            value={file.getComment()}
            onChange={(value) => {
              const newFile = new FileModel(file.data);
              newFile.setComment(value);
              setFileData(newFile.data);
            }}
          />
        )
      }

    </Card.CardShape>
  );
};
