
import { useSelector } from 'react-redux';
import { selectors as consentSelectors } from '../redux/ducks/consent';

import { useUIState } from './useUIState';

export function useConsent(profileId) {
  const consentData = useSelector(state => consentSelectors.getAll(state, profileId));
  const isLoaded = Object.keys(consentData).length > 0;

  const isInformedConsentForbidden = useSelector(state => consentSelectors.getIsInformedConsentForbiddenForProfile(state, profileId))

  return [
    {
      data: consentData,
      isLoaded,
      isInformedConsentForbidden,
    },
  ];
}

export function useConsentForActiveProfile() {
  const [{ activeProfileId }] = useUIState();
  return useConsent(activeProfileId);
}
