import React from 'react';
import { FormattedMessage } from 'react-intl';

import HealthEntryGroup from './HealthEntryGroup';
import { healthEntriesZeroState } from './ZeroState';
import HealthEntryIcons from '../icons/HealthEntryIcons';
import { Card, Icons } from '../../../bphComponents/bundle';
import COLORS from '../../styles/Colors';
import { useTrackableShortcutsForActiveProfile } from '../../hooks/useTrackable';

interface Props {
  languageCode: string;
  healthEntries: any;
  healthEntriesCount: number;
  goToHealthEntry?: (entry: any) => void;
}

const iconProps = {
  color: 'profile-highlight',
  size: '24',
};

const HealthEntriesList: React.FC<Props> = (props) => {
  const { goToHealthEntry, languageCode, healthEntriesCount } = props;
  const { allergy, condition, medication, other_treatment, procedure, vaccination, lab, measurement, genetic_result } = props.healthEntries;
  const [{ shortcutList }] = useTrackableShortcutsForActiveProfile();
  const hasShortcuts = shortcutList.length > 0;

  return (
    <div className="content-mybackpack" style={{ height: '100%', position: 'relative' }}>

      {
        hasShortcuts ? (
          <Card.ExternalCardTitle>
            <FormattedMessage id="healthInfo.viewTracking.label" />
            <Icons.IconHealthInfo.Small color={COLORS.gsMedDark} />
          </Card.ExternalCardTitle>
        ) : null
      }

      <HealthEntryGroup
        alwaysVisibleContent={condition.newer}
        hideableContent={condition.older}
        icon={<HealthEntryIcons.IconCondition {...iconProps} />}
        name="list.healthEntry.conditions.name"
        titleShowHidden="bpAll.hidePast.button.label"
        titleHideRevealed="bpAll.showPast.button.label"
        languageCode={languageCode}
        onClick={goToHealthEntry}
      />

      <HealthEntryGroup
        alwaysVisibleContent={medication.newer}
        hideableContent={medication.older}
        icon={<HealthEntryIcons.IconMedication {...iconProps} />}
        name="list.healthEntry.medications.name"
        titleShowHidden="bpAll.hidePast.button.label"
        titleHideRevealed="bpAll.showPast.button.label"
        languageCode={languageCode}
        onClick={goToHealthEntry}
      />

      <HealthEntryGroup
        alwaysVisibleContent={other_treatment.newer}
        hideableContent={other_treatment.older}
        icon={<HealthEntryIcons.IconTreatment {...iconProps} />}
        name="list.healthEntry.treatments.name"
        titleShowHidden="bpAll.hidePast.button.label"
        titleHideRevealed="bpAll.showPast.button.label"
        languageCode={languageCode}
        onClick={goToHealthEntry}
      />

      <HealthEntryGroup
        alwaysVisibleContent={procedure.newer}
        hideableContent={procedure.older}
        icon={<HealthEntryIcons.IconProcedure {...iconProps} />}
        name="list.healthEntry.procedures.name"
        titleShowHidden="bpAll.hideOlder.button.label"
        titleHideRevealed="bpAll.showOlder.button.label"
        languageCode={languageCode}
        onClick={goToHealthEntry}
      />

      <HealthEntryGroup
        alwaysVisibleContent={allergy.newer}
        hideableContent={allergy.older}
        icon={<HealthEntryIcons.IconAllergy {...iconProps} />}
        name="list.healthEntry.allergies.name"
        titleShowHidden="bpAll.hidePast.button.label"
        titleHideRevealed="bpAll.showPast.button.label"
        languageCode={languageCode}
        onClick={goToHealthEntry}
      />

      <HealthEntryGroup
        alwaysVisibleContent={vaccination.newer}
        hideableContent={vaccination.older}
        icon={<HealthEntryIcons.IconVaccination {...iconProps} />}
        name="list.healthEntry.vaccinations.name"
        titleShowHidden="bpAll.hideOlder.button.label"
        titleHideRevealed="bpAll.showOlder.button.label"
        languageCode={languageCode}
        onClick={goToHealthEntry}
      />

      <HealthEntryGroup
        alwaysVisibleContent={measurement.all}
        hideableContent={[]}
        icon={<HealthEntryIcons.IconMeasurement {...iconProps} />}
        name="list.healthEntry.measurements.name"
        languageCode={languageCode}
        onClick={goToHealthEntry}
      />

      <HealthEntryGroup
        alwaysVisibleContent={lab.all}
        hideableContent={[]}
        icon={<HealthEntryIcons.IconLab {...iconProps} />}
        name="list.healthEntry.lab.name"
        languageCode={languageCode}
        onClick={goToHealthEntry}
      />

      <HealthEntryGroup
        alwaysVisibleContent={genetic_result.all}
        hideableContent={[]}
        icon={<HealthEntryIcons.IconGeneticResult {...iconProps} />}
        name="list.healthEntry.genes.name"
        languageCode={languageCode}
        onClick={goToHealthEntry}
      />

      {healthEntriesZeroState(healthEntriesCount)}
    </div>
  );
};

export default HealthEntriesList;
