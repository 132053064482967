import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useUIState } from '../../../hooks/useUIState';
import { TrackableShortcutModel } from '../../../../apiServices/apiModels/Trackable';
import { useTrackableShortcutsForActiveProfile } from '../../../hooks/useTrackable';
import { Card, CardRowUI, Icons } from '../../../../bphComponents/bundle';
import FlexRow from '../../../components/FlexRow';
import { useNavigate } from '../../../hooks/useNavigate';
import RouteNames from '../../../appAuthorized/RouteNames';

export const TrackableShortcuts = (props) => {
  const [{ shortcutList, trackableTermLookup }, { createObservation, requestTrackable }] = useTrackableShortcutsForActiveProfile();
  const [, { getProfileColorValue }] = useUIState();
  const [, navActions] = useNavigate();
  const [isCreating, setIsCreating] = React.useState(false);


  if (shortcutList.length === 0) { return null; }

  const shortcuts = shortcutList.map(d => new TrackableShortcutModel(d, trackableTermLookup))
    .sort((a, b) => a.name.localeCompare(b.name));
  const profileColor = getProfileColorValue();

  return (
    <>
      {
        shortcuts.map((s, i) => (
          <Card.CardShape key={i}>
            <Card.CardContent
              roundedCorners="top"
              onClick={() => {
                navActions.goTo(RouteNames.trackableObservationList(s.trackableId));
              }}
            >
              <FlexRow className="font-body font-color-profile-accent">
                {s.name}
                <Icons.IconTracking.Medium color={profileColor.base} />
              </FlexRow>
            </Card.CardContent>
            <CardRowUI.AddInlineItem
              className="profile-background-color-5"
              roundedCorners="bottom"
              onClick={() => {
                if (isCreating) { return; }
                setIsCreating(true);

                createObservation(s.data)
                  .then((obs: any) => {
                    navActions.goTo(RouteNames.trackableObservation(s.trackableId, obs.id));
                  }).catch(err => {
                    setIsCreating(false);
                  });
              }}
              color={profileColor.accent}
            >
              <div className="font-color-profile-accent">
                <FormattedMessage id="bpHealth.occurrenceShortcut.button.label" />
              </div>
            </CardRowUI.AddInlineItem>
          </Card.CardShape>
        ))
      }
    </>
  );
};

{/*  */}