import { joinGroupIntent } from '../../../utilities/LocalStorage';
import AppUtils from '../../../utilities/AppUtils';
import RouteNames from '../../appAuthorized/RouteNames';

export function loginWithPendingIntent(userData, submitUserUpdate) {
  const groupId = joinGroupIntent.pop();

  if (groupId) {
    const newUser = {
      ...userData,
      intent: {
        type: 'join_group',
        join_group: {
          group_id: groupId,
        },
      },
    };

    submitUserUpdate(newUser, () => {
      AppUtils.reloadAppToPathname(RouteNames.intendedGroup());
    });
  } else {
    // could also fetch user and check if it has previous intent
    AppUtils.reloadApp();
  }
}
