
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import ShareCardIncludedTypeNames from '../../constants/ShareCardIncludedTypeNames';
import { Inputs } from '../../bphComponents/bundle';

const INCLUDED_VALUE_CHOICES = [
  {
    name: 'LATEST',
    label: <FormattedMessage id="healthEntry.addMostRecentToShareCards.name.text" />,
    value: ShareCardIncludedTypeNames.latest,
  },
  {
    name: 'ALL',
    label: <FormattedMessage id="healthEntry.addAllToShareCards.name.text" />,
    value: ShareCardIncludedTypeNames.all,
  },
];

interface Props {
  uniqueID: string;
  isDisabled: boolean;
  onToggle(): void;
  value: string;
}

const ShareCardIncludedValueToggleSwitch: FC<Props> = (props) => {
  const { uniqueID, isDisabled, onToggle, value } = props;

  return (
    <Inputs.ToggleSwitch.GrayWhiteToggleSwitch
      uniqueID={uniqueID}
      value={value}
      choices={INCLUDED_VALUE_CHOICES}
      isOff={false}
      disabled={isDisabled}
      onChange={onToggle}
    />
  );
};

export default ShareCardIncludedValueToggleSwitch;
