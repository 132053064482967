import React from 'react';
import { iObservationAttributeListDefinition, iObservationsAttributeValue } from '../../../../../apiServices/apiModels/Trackable';
import _ from 'lodash';

import { Card, CardRowUI } from '../../../../../bphComponents/bundle';

const RowItem = (props) => {
  const { children, isSelected, onSelect, isLast } = props;

  return (
    <CardRowUI.SelectableRow
      isSelected={isSelected}
      selectedClassName="fill-color-profile-highlight"
      hasBottomBorder={!isLast}
      borderType="inset"
      onClick={onSelect}
    >
      {children}
    </CardRowUI.SelectableRow>
  );
};

interface Props {
  listValue: iObservationsAttributeValue;
  listDefinition: iObservationAttributeListDefinition;
  terms: any[];
  onChange(d: iObservationsAttributeValue): void;
  languageCode: string;
}
export const ListSelect: React.FC<Props> = (props) => {
  const { listValue, listDefinition, terms, onChange, languageCode } = props;
  const def = listDefinition.list;

  const allowMultiSelect = def.multi_select;
  const options = def.options.trackable_term_id;
  const selectedIds = _.get(listValue, 'list.selected_option_ids', null) || [];

  const helpText = '';
  const hintText = _.get(listDefinition, `hint_text.string.${languageCode}`, '');

  return (
    <>
    {
      hintText ? (
        <Card.CardContentFirst>
          <div className="font-caption-1 font-color-gsDark">{hintText}</div>
        </Card.CardContentFirst>
      ) : null
    }
    {
      options.map((id, i) => {
        const term = terms[id];
        return !term
          ? null
          : (
            <RowItem
              key={id}
              isSelected={selectedIds.includes(id)}
              isLast={i === options.length - 1}
              onSelect={() => {
                let newSelection = [];

                if (selectedIds.includes(id)) {
                  newSelection = allowMultiSelect
                    ? selectedIds.filter(d => d !== id)
                    : [];
                } else if (allowMultiSelect) {
                  newSelection = [...selectedIds, id];
                } else {
                  newSelection = [id];
                }
                onChange({
                  ...listValue,
                  list: {
                    selected_option_ids: newSelection,
                  },
                });
              }}
            >
              <div className="font-body font-color-gsDark">
                {term.name}
              </div>
            </RowItem>
          );
      })
    }
    {
      helpText ? (
        <Card.CardContent borderType="inset" hasTopBorder={true}>
          {helpText}
        </Card.CardContent>
      ) : null
    }
    </>
  );
};
