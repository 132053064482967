import React, { FC } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { selectors as listSelectors } from '../redux/ducks/lists/lists';
import ContactModel, { ContactModelType } from '../../models/ContactModel';


interface Props {
  item: any;
  customText: string;
  data: ContactModelType;
  isSingleLine?: boolean;
}
const BasicText: FC<Props> = (props) => {
  if (!props.data) { return null; }
  if (props.customText) { return <>{props.customText}</>; }

  if (Array.isArray(props.item)) {
    return props.item.length > 0
      ? <span style={props.isSingleLine ? { display: 'block', width: `calc(100% - ${1}px)`, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' } : null}>
          {
            props.item.map((item, i) => (
              <FormattedMessage key={item.key} id={item.key}>
                {
                  (t) => (i < (props.item.length - 1) ? `${t}, ` : t)
                }
              </FormattedMessage>
            ))
          }
        </span>
      : null;
  }
  return props.item ? <FormattedMessage id={props.item.key} /> : null;
};
const BasicContactSpecialtyText: FC<Props> = (props) => {
  if (!props.data) { return null; }
  if (props.customText) { return <>{props.customText}</>; }

  return props.item ? <FormattedMessage id={props.item.key} /> : <ContactRelationshipText data={props.data} />;
};

const ContactSpecialtyText = connect((state, props) => {
  return {
    customText: props.data.specialty_custom_text,
    item: listSelectors.getContactSpecialty(state, props.data.contact_specialty_id),
  };
})(BasicContactSpecialtyText);

const ContactRelationshipText = connect((state, props) => {
  return {
    customText: props.data.relationship_custom_text,
    item: listSelectors.getContactRelationship(state, props.data.contact_relationship_id),
  };
})(BasicText);

const ContactHealthcareProxyText = connect((state, props) => {
  return {
    customText: '',
    item: listSelectors.getContactHealthcareProxy(state, props.data.contact_healthcare_proxy_ids),
  };
})(BasicText);

const ContactSummaryText: FC<{ data: ContactModelType; isSingleLine?: boolean; }> = (props) => {
  const { data, isSingleLine } = props;

  return (
    <span style={isSingleLine ? { display: 'block', width: `calc(100% - ${1}px)`, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' } : null}>
      {
        ContactModel.isProvider(data)
          ? <ContactSpecialtyText data={data} isSingleLine={false} />
          : ContactModel.hasHealthcareProxy(data)
            ? <><ContactRelationshipText data={data} isSingleLine={false} /> – <ContactHealthcareProxyText data={data} isSingleLine={false} /></>
            : <ContactRelationshipText data={data} isSingleLine={false} />
      }
    </span>
  );
};

const ProviderContactCurrentText: FC<{ data: ContactModelType; }> = (props) => {
  const { data } = props;

  return (
    data.current ? (
      <FormattedMessage id="ui-data.providers.current.text" />
    ) : (
      <FormattedMessage id="ui-data.providers.past.text" />
    )
  );
};

const ProviderContactNameSpecialtyText: FC<{ data: ContactModelType; }> = (props) => {
  const { data } = props;
  return (
    <>
      {data.name}{ContactModel.hasSpecialty(data) ? <> – <ContactSpecialtyText data={data} /></> : null}
    </>
  );
};

const ContactNameHealthcareProxyText: FC<{ data: ContactModelType; }> = (props) => {
  const { data } = props;
  return (
    <>
      {data.name}{ContactModel.hasHealthcareProxy(data) ? <> – <ContactHealthcareProxyText data={data} /></> : null}
    </>
  );
};

export {
  ContactSpecialtyText,
  ContactRelationshipText,
  ContactHealthcareProxyText,
  ContactSummaryText,
  ProviderContactCurrentText,
  ProviderContactNameSpecialtyText,
  ContactNameHealthcareProxyText,
};
