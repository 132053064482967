import { useQuery } from 'react-query';

import { useDispatch } from 'react-redux';

import ListsService from '../../apiServices/ListsService';
import ListModel from '../../models/ListModel';
import AppUtils from '../../utilities/AppUtils';

const STALE_TIME = 100000;

const INITIAL_LIST_STATE = {
  birthSexList: [],
  birthSexLookup: {},
  bloodTypeList: [],
  bloodTypeLookup: {},
  cancelReasonList: [],
  cancelReasonLookup: {},
  categoryList: [],
  categoryLookup: {},
  colorShareCardList: [],
  colorShareCardLookup: {},
  geneVariantPresentList: [],
  geneVariantPresentLookup: {},
  residenceStateList: [],
  residenceStateLookup: {},
  residenceCountryList: [],
  residenceCountryLookup: {},
  raceList: [],
  raceLookup: {},
  profileStateList: [],
  profileStateLookup: {},
  localeMedliList: [],
  localeMedliLookup: {},
  localeList: [],
  localeLookup: {},
  genderList: [],
  genderLookup: {},
  frequencyList: [],
  frequencyLookup: {},
  diagnosisList: [],
  diagnosisLookup: {},
  countryList: [],
  countryLookup: {},
  contactSpecialtyList: [],
  contactSpecialtyLookup: {},
  contactRelationshipList: [],
  contactRelationshipLookup: {},
  contactHealthcareProxyList: [],
  contactHealthcareProxyLookup: {},
  contactDetailLabelList: [],
  contactDetailLabelLookup: {},
  colorThemeList: [],
  colorThemeLookup: {},

  contactRelationshipHealthcareProviderValue: {},
  contactRelationshipOtherValue: {},
  contactSpecialtyCustomValue: {},
  contactRelationshipCustomValue: {},

  isLoaded: false,
};

function requestList(dispatch) {
  return function requestList_API(queryKey) {
    return new Promise((res, rej) => {
      ListsService.all((apiError, responseCollection) => {
        if (apiError) { rej(apiError); return; }

        const reduxData = {};

        const data: any = Object.keys(responseCollection).reduce((acc, key) => {
          const list = responseCollection[key];
          const lookup = list.reduce((listAcc, v) => ({
            ...listAcc,
            [v.id]: v,
          }), {});

          reduxData[key] = {
            collection: list,
            lookup,
          };

          return {
            ...acc,
            [`${key}List`]: list,
            [`${key}Lookup`]: lookup,
          };
        }, {});

        dispatch({
          type: 'app/lists/all/RECEIVE',
          payload: {
            ...reduxData,
            isFetching: false,
            lastUpdated: new Date().getTime(),
          },
        });

        const contactRelationshipHealthcareProviderValue = ListModel.ContactRelationship.getHealthcareProviderRelationship(data.contactRelationshipList);
        const contactRelationshipOtherValue = ListModel.ContactRelationship.getOtherRelationship(data.contactRelationshipList);
        const contactSpecialtyCustomValue = data.contactSpecialtyList.find(c => c.is_custom === 1);
        const contactRelationshipCustomValue = data.contactRelationshipList.find(c => c.is_custom === 1);

        res({
          ...data,
          contactRelationshipHealthcareProviderValue,
          contactRelationshipOtherValue,
          contactSpecialtyCustomValue,
          contactRelationshipCustomValue,
          isLoaded: true,
        });
      });
    });
  };
}

export function useLists() {
  const dispatch = useDispatch();
  const queryData: any = useQuery(['lists'], requestList(dispatch), { staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_LIST_STATE;

  return [
    responseData,
    {

      getClosestMatchingLocale: () => {
        const languageCodes = AppUtils.getClientLocales();

        let exactMatchIndex = -1;
        let matchedLocale;
        const supportedLocaleList = responseData.localeList;
        supportedLocaleList.sort((a, b) => a.id - b.id); // sort by ids so that first defined locale will be default

        const regions = languageCodes.map(l => l.split('-')[0]);

        for (let i = 0; i < languageCodes.length; i++) {
          const matches = supportedLocaleList.filter((l) => l.language_region === languageCodes[i]);
          if (exactMatchIndex < 0 && matches.length > 0) {
            exactMatchIndex = i;
            matchedLocale = matches[0];
            break;
          }
        }

        for (let i = 0; i < regions.length; i++) {
          const matches = supportedLocaleList.filter((l) => l.language_region.split('-')[0] === regions[i]);
          if (matches.length > 0) {
            if (exactMatchIndex < 0 || i < exactMatchIndex) {
              matchedLocale = matches[0];
              break;
            }
          }
        }

        if (matchedLocale) {
          return matchedLocale;
        } else {
          return supportedLocaleList[0] || {};
        }
      },
    },
  ];
}

export function useContactSpecialtyList(contactSpecialtyId) {
  const dispatch = useDispatch();
  const queryData: any = useQuery(['lists'], requestList(dispatch), { staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_LIST_STATE;
  const contactSpecialtyList = responseData.contactSpecialtyList;
  const contactSpecialtyLookup = responseData.contactSpecialtyLookup;

  return [
    {
      contactSpecialty: contactSpecialtyLookup[contactSpecialtyId],
      contactSpecialtyList,
      contactSpecialtyLookup,
      isLoaded: responseData.isLoaded,
    },
  ];
}
