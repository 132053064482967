import React from 'react';
import { withRouter, Redirect } from 'react-router';

import SystemService from '../../../apiServices/SystemService';
import ContentLoader from '../../components/ContentLoader';
import RouteNames from '../../appAuthorized/RouteNames';
import { useUIState } from '../../hooks/useUIState';
import { useActiveProfile } from '../../hooks/useProfile';

const DesinationLinkRedirect = (props) => {
  const[uiState, uiStateActions] = useUIState();
  const [activeProfileData] = useActiveProfile();
  const activeProfileId = activeProfileData.data.id;
  const token = props.match.params.token;
  const [state, setState] = React.useState(() => {
    return token ? '' : '/';
  });

  React.useEffect(() => {
    SystemService.redirectLink(token)
    .then(res => {
      switch (res.view_name) {
        case 'pendingData':
          if (activeProfileId !== res.profile_id) {
            uiStateActions.setActiveProfile(res.profile_id);
          }
          setState(RouteNames.reviewPendingData());
          break;
        default:
          setState('/');
          break;
      }
    }).catch(() => {
      setState('/');
    });
  }, []);

  return !state
  ? <ContentLoader isContentLoaded={false} isFixed />
  : <Redirect to={state} />;
};

export default withRouter(DesinationLinkRedirect);
