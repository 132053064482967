import React, { FC } from 'react';

interface Props {
  color: string;
}

const IconSVG = (props) => (
  <svg height={props.size} width={props.size} viewBox="0 0 24 24">
    <path
      id="st-icon-security-2"
      data-name="st-icon-security"
      fill={props.color}
      d="M11,8.3a2.66,2.66,0,0,0-2,.27,2.74,2.74,0,0,0-1.27,1.78,3,3,0,0,0,.68,2.73,16.1,16.1,0,0,0,2.26,2.07l.51.38a1.46,1.46,0,0,0,.83.26,1.43,1.43,0,0,0,.83-.26l.51-.38a16.16,16.16,0,0,0,2.26-2.07,3,3,0,0,0,.68-2.73A2.74,2.74,0,0,0,15,8.57a2.6,2.6,0,0,0-2-.27,2.54,2.54,0,0,0-1,.49A2.5,2.5,0,0,0,11,8.3Zm2.32,1.44a1.13,1.13,0,0,1,.88.12,1.29,1.29,0,0,1,.58.83,1.5,1.5,0,0,1-.35,1.41A14.65,14.65,0,0,1,12.41,14c-.11.09-.26.21-.41.31-.15-.1-.29-.22-.41-.31A14,14,0,0,1,9.54,12.1a1.48,1.48,0,0,1-.36-1.41,1.29,1.29,0,0,1,.58-.83,1.14,1.14,0,0,1,.57-.16,1.55,1.55,0,0,1,.31,0,1.27,1.27,0,0,1,.74.57l.62.93.62-.93A1.27,1.27,0,0,1,13.36,9.74Zm8-6.16a24.26,24.26,0,0,1-8-1.71l-1-.41h0L12,1.34l-.29.12h0l-1,.41a24.21,24.21,0,0,1-8,1.71l-.81,0,1.26,11.8a3.35,3.35,0,0,0,.94,2,24.06,24.06,0,0,0,7.69,5.15h0l.29.12.29-.12A23.7,23.7,0,0,0,20,17.38a3.29,3.29,0,0,0,.94-2l1.26-11.8ZM19.44,15.25a1.85,1.85,0,0,1-.51,1.08,22.56,22.56,0,0,1-6.18,4.34V18a.75.75,0,0,0-1.5,0v2.72a22.36,22.36,0,0,1-6.17-4.34,1.81,1.81,0,0,1-.52-1.08L3.47,5a25.18,25.18,0,0,0,7.75-1.77h0V5.91a.75.75,0,0,0,1.5,0V3.25h0A25.18,25.18,0,0,0,20.53,5Z"
    />
  </svg>
);

const Small: FC<Props> = (props) => (
  <IconSVG {...props} size="16px" />
);

const Medium: FC<Props> = (props) => (
  <IconSVG {...props} size="24px" />
);

const Large: FC<Props> = (props) => (
  <IconSVG {...props} size="36px" />
);

const XLarge: FC<Props> = (props) => (
  <IconSVG {...props} size="56px" />
);

export {
  Small,
  Medium,
  Large,
  XLarge
};
