import * as React from 'react';
import { isMobileApplication } from '../../../../utilities/MobileUtil';
import { SelectFileItem } from './SelectFileItem';
import AddMenu from './AddMenu';

export const MobileFileMenuChoices = ({ successRoute, onCancel }) => (
  <>
    <SelectFileItem
      labelId="list.fileSource.cameraPhoto.name"
      type="image"
      successRoute={successRoute}
      onCancel={onCancel}
    />
    <SelectFileItem
      labelId="list.fileSource.cameraVideo.name"
      type="video"
      successRoute={successRoute}
      onCancel={onCancel}
    />
    <SelectFileItem
      labelId={isMobileApplication() ? 'list.fileSource.androidFiles.name' : 'list.fileSource.files.name'}
      type="library"
      successRoute={successRoute}
      onCancel={onCancel}
    />
  </>
);

export const AddMenuMobileFiles = ({ successRoute, onCancel }) => (
  <AddMenu>
    <MobileFileMenuChoices successRoute={successRoute} onCancel={onCancel} />
  </AddMenu>
);
