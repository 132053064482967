import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ProfileActions, { setCurrentColorTheme } from '../appAuthorized/redux/ProfileActions';
import { getMainProfileId, getActiveProfileId } from '../appAuthorized/redux/reduxSelectors';
import { actions as localeActions, loadLocale } from '../redux/ducks/locale';

import AppUtils from '../../utilities/AppUtils';
import UserMetrics from '../../utilities/UserMetrics';
import UI_CONSTANTS from '../../constants/uiConstants';
import { activeProfile, lastAccessedProfiles } from '../../utilities/LocalStorage';
import { actions as groupActions } from '../redux/ducks/group';
import { actions as originalUIActions } from '../redux/ducks/ui';
import { isMenuShielded, isMenuVisible } from '../appAuthorized/appMenu/appMenuVisibility';
import { actions as systemModalActions, selectors as systemModalSelectors, OverlayBlur } from '../redux/ducks/systemModal';
import { selectors as listSelectors } from '../redux/ducks/lists/lists';
import { actions as systemNotificationAction } from '../redux/ducks/systemNotification';
import { ChangePasswordFlowActions, EnableMfaFlowActions } from '../appAuthorized/redux/userFlowActions';

import { BreakingChangeWarningModal } from '../components/modal/WarningModal';
import DateUtil from '../../utilities/DateUtil';
import { useLists } from './useLists';
import { useActiveProfile, useProfile } from './useProfile';

const UISTATE_ACTIONS = {
  SET_ACTIVE_PROFILE: 'uiState/SET_ACTIVE_PROFILE',
  SET_LOCALE: 'uiState/SET_LOCALE',
  SWITCH_PROFILE: 'uiState/SWITCH_PROFILE',
  SET_USER: 'uiState/SET_USER',
  UPDATE: 'uiState/UPDATE',
  SET_WINDOW_TITLE_PHRASE: 'app/locale/SET_WINDOW_TITLE_PHRASE',
  SHOW_SECTION: 'app/systemNotification/SHOW_SECTION',
  SHOW_MODAL: 'app/systemModal/SHOW',
  DISMISS_MODAL: 'app/systemModal/DISMISS',
  SET_SEARCH_PARAMETERS: 'uiState/search/SET_SEARCH_PARAMETERS',
};

const initialState = {
  userId: null,
  userProfileId: null,
  activeProfileId: null,
  activeGroupId: null,
  activeColorTheme: null,
  locale: 'en',
  isLoading: false,
  isWideDesktop: false,
  isAppMenuVisible: false,
  activeModals: [],
  search: {},
  lastActiveProfileIds: [],
};
function initializeState(initState) {
  return {
    ...initState,
    lastActiveProfileIds: lastAccessedProfiles.getAll(),
  };
}

function uiStateReducer(state, action) {
  switch (action.type) {
    case UISTATE_ACTIONS.SET_ACTIVE_PROFILE:
      return {
        ...state,
        activeProfileId: action.payload.profileId,
        lastActiveProfileIds: [action.payload.profileId].concat(state.lastActiveProfileIds.filter(id => id !== action.payload.profileId)),
      };
    case UISTATE_ACTIONS.SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      };
    case UISTATE_ACTIONS.SWITCH_PROFILE:
    case UISTATE_ACTIONS.SET_USER:
    case UISTATE_ACTIONS.UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case UISTATE_ACTIONS.SET_WINDOW_TITLE_PHRASE:
      return state;
    case UISTATE_ACTIONS.SHOW_SECTION:
      return {
        ...state,
        isAppMenuVisible: action.payload.isVisible,
        appMenuSectionType: action.payload.sectionType,
      };
    case UISTATE_ACTIONS.SHOW_MODAL:
      return {
        ...state,
        activeModals: [action.payload],
      };
    case UISTATE_ACTIONS.DISMISS_MODAL:
      return {
        ...state,
        activeModals: state.activeModals.slice(0, state.activeModals.length - 1),
      };
    case UISTATE_ACTIONS.SET_SEARCH_PARAMETERS:
      return {
        ...state,
        search: { ...action.payload },
      };
    default:
      throw new Error(`Unhandled redux action: ${action.type}`);
  }
}

function setSearchParameters(search) {
  return {
    type: UISTATE_ACTIONS.SET_SEARCH_PARAMETERS,
    payload: {
      ...search,
    },
  };
}

function setActiveProfile(profileId) {
  return {
    type: UISTATE_ACTIONS.SET_ACTIVE_PROFILE,
    payload: {
      profileId,
    },
  };
}

function setLocale(locale) {
  return {
    type: UISTATE_ACTIONS.SET_ACTIVE_PROFILE,
    payload: {
      locale,
    },
  };
}

function setUser(userId, profileId) {
  return {
    type: UISTATE_ACTIONS.SET_USER,
    payload: {
      userId,
      userProfileId: profileId,
    },
  };
}

function updateUIState(payload) {
  return {
    type: UISTATE_ACTIONS.UPDATE,
    payload: {
      ...payload,
    },
  };
}

function useUIStateReducer() {
  const reduxDispatch = useDispatch();
  const [state, dispatch] = React.useReducer(uiStateReducer, initialState, initializeState);

  const colorTheme = useSelector(state => listSelectors.getCurrentColorTheme(state));
  const isAppMenu = useSelector(state => systemModalSelectors.getIsAppMenuVisible(state));
  const currentBlurSize = useSelector(state => systemModalSelectors.getBlurSize(state));
  const blurSize = isAppMenu ? OverlayBlur.darkBigBlur : currentBlurSize;
  const isSystemModalVisible = useSelector(state => systemModalSelectors.isVisible(state));
  const userProfileId = useSelector(state => state.user.profile_id);
  const activeProfileId = useSelector(state => state.activeProfileId === -1 ? null : state.activeProfileId);
  const isLoggedIn = Boolean(activeProfileId);

  return [{
    ...state,
    activeProfileId,
    isActiveProfileMainProfile: userProfileId === activeProfileId,
    colorTheme,
    // activeProfileColorTheme: {},//colorThemeLookup[profile.color_theme_id],
    blurSize,
    isSystemModalVisible,
    sliding: isAppMenu,
    isLoggedIn,
  }, {
    isMenuVisible: (location) => {
      return isLoggedIn && isMenuVisible(state, location);
    },
    isMenuShielded: () => {
      return isMenuShielded(state, window.location);
    },
    setActiveProfile: (profileId) => {
      activeProfile.set(profileId);
      window.scrollTo(0, 0);
      dispatch(setActiveProfile(profileId));
      reduxDispatch(ProfileActions.setActiveProfile(profileId));
      lastAccessedProfiles.update(profileId);
    },
    switchToMainProfile: () => {
      activeProfile.set(userProfileId);
      window.scrollTo(0, 0);
      dispatch(setActiveProfile(userProfileId));
      reduxDispatch(ProfileActions.setActiveProfile(userProfileId));
      lastAccessedProfiles.update(userProfileId);
    },
    sortLastActiveProfiles: (profileList) => {
      if (profileList.length === 0) return [];

      let newProfileList = [...profileList];
      const sorted = [];
      state.lastActiveProfileIds.forEach(pId => {
        const profile = profileList.find(p => p.id === pId);
        if (profile) {
          sorted.push(profile);
        }
        newProfileList = newProfileList.filter(p => p.id !== pId);
      });

      const remaining = newProfileList.sort((a, b) => {
        return DateUtil.isBefore(a.date_of_birth, b.date_of_birth) ? -1 : 1;
      });

      return sorted.concat(remaining);
    },
    setActiveGroup: (groupId) => {
      reduxDispatch(groupActions.setActiveGroup(groupId));
    },
    setLocale: (locale) => {
      dispatch(setLocale(locale));
      // reduxDispatch();
    },
    setUser: (userId, profileId) => {
      dispatch(setUser(userId, profileId));
      // reduxDispatch();
    },
    prefillSearch: (searchData) => {
      dispatch(setSearchParameters(searchData));
    },
    clearSearch: () => {
      dispatch(setSearchParameters({}));
    },
    setActiveColorTheme: (activeColorTheme) => {
      dispatch(updateUIState({ activeColorTheme }));
      reduxDispatch(setCurrentColorTheme(activeColorTheme));
    },
    setStartLoading: () => {
      dispatch(updateUIState({ isLoading: true }));
    },
    setStopLoading: () => {
      dispatch(updateUIState({ isLoading: false }));
    },
    isMainProfile: () => {
      const mainProfileId = useSelector(state => getMainProfileId(state));
      const activeProfileId = useSelector(state => getActiveProfileId(state));
      return mainProfileId === activeProfileId;
    },
    setIsWide: (value) => {
      dispatch(updateUIState({ isWideDesktop: value }));
    },
    setWindowTitle: (textArray) => {
      dispatch(localeActions.setWindowTitlePhrase(textArray));
      reduxDispatch(localeActions.setWindowTitlePhrase(textArray));
    },
    setWindowTitlePhrase: (phraseData) => {
      dispatch(localeActions.setWindowTitlePhrase([phraseData]));
      reduxDispatch(localeActions.setWindowTitlePhrase([phraseData]));
    },
    loadLocale: (newMedliLocale) => {
      return loadLocale(newMedliLocale);
    },
    initializeClientLocales: () => {
      reduxDispatch(localeActions.initializeClientLocales());
    },
    showAppMenuProfileSection: () => {
      reduxDispatch(originalUIActions.showProfileSection());
    },
    showAppMenuHelpSection: () => {
      reduxDispatch(originalUIActions.showHelpSection());
    },
    closeAppMenuSection: () => {
      reduxDispatch(originalUIActions.closeSection());
    },
    showBreakingChangeModal: (res) => {
      dispatch(systemModalActions.showDarkBigBlur(<BreakingChangeWarningModal changes={res.changes} />));
    },
    showDarkBigBlur: (content, options) => {
      reduxDispatch(systemModalActions.showDarkBigBlur(content, options));
    },
    hideAppMenu: () => {
      reduxDispatch(systemModalActions.hideAppMenu());
    },
    showNotLoaded: (content, options?) => {
      reduxDispatch(systemModalActions.showNotLoaded(content, { isWithSliding: true, blurSize: OverlayBlur.darkBigBlur }));
    },
    showModal: (content) => {
      reduxDispatch(systemModalActions.show(content, { blurSize: OverlayBlur.lightSmallBlur }));
    },
    dismissAllModals: () => {
      reduxDispatch(systemModalActions.dismissAll());
    },
    dismissModal: () => {
      reduxDispatch(systemModalActions.dismiss());
    },
    showErrorNotification: (errorPhraseKey) => {
      reduxDispatch(systemNotificationAction.showNotification(errorPhraseKey));
    },
    showNotification: (phraseId, color) => {
      reduxDispatch(systemNotificationAction.showNotification(phraseId, color));
    },
    showHintNotification: (phraseId) => {
      reduxDispatch(systemNotificationAction.showHintNotification(phraseId));
    },
    dismissNotification: () => {
      reduxDispatch(systemNotificationAction.dismissNotification());
    },
    pushDarkBigBlur: (content, options?) => {
      reduxDispatch(systemModalActions.pushDarkBigBlur(content, options));
    },
    setBlur: (blurSize: OverlayBlur) => {
      reduxDispatch(systemModalActions.setBlur(blurSize));
    },
    removeBlur: () => {
      reduxDispatch(systemModalActions.removeBlur());
    },
    pushSystemModal: (content, options?) => {
      reduxDispatch(systemModalActions.push(content, options));
    },
    popSystemModal: () => {
      reduxDispatch(systemModalActions.pop());
    },
    showAppMenu: () => {
      reduxDispatch(systemModalActions.showAppMenu());
    },
    startChangePasswordFlow: () => {
      reduxDispatch(ChangePasswordFlowActions.init());
    },
    startEnableMfaFlow: () => {
      reduxDispatch(EnableMfaFlowActions.init());
    },
  }];
}

const UIStateContext = React.createContext(null);

export const UIStateProvider = (props) => {
  const [state, actions] = useUIStateReducer();
  const dispatch = useDispatch();

  const newState = {
    ...state,
    ...props.initialState,
  };

  const resizeListener = React.useCallback(() => {
    actions.setIsWide(window.innerWidth > UI_CONSTANTS.wideDesktopWidth);
  }, []);

  React.useEffect(() => {
    dispatch(localeActions.initializeClientLocales());

    AppUtils.enableTouchRelatedStyles();
    UserMetrics.init();
    resizeListener();
    addEventListener('resize', resizeListener);
    return () => {
      removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <UIStateContext.Provider value={[newState, actions]}>
      {props.children}
    </UIStateContext.Provider>
  );
};

export function useUIState() {
  const [uiState, uiActions] = React.useContext(UIStateContext);
  const { activeProfileId } = uiState;
  const [{ colorThemeLookup, colorThemeList }] = useLists();
  const [{ profile }] = useProfile(activeProfileId);
  const currentColorTheme = useSelector(state => listSelectors.getCurrentColorTheme(state));

  return [{
    ...uiState,
    isMainProfile: uiState.userProfileId === uiState.activeProfileId,
  }, {
    ...uiActions,
    getProfileColorValue: () => {
      const colorTheme = colorThemeLookup[profile.color_theme_id];
      return {
        base: AppUtils.cssColor(colorTheme.base_color),
        accent: AppUtils.cssColor(colorTheme.accent_color),
        highlight: AppUtils.cssColor(colorTheme.highlight_color),
      };
    },
    getActiveProfileColorTheme: () => {
      return colorThemeLookup[profile.color_theme_id] || colorThemeList[0];
    },
    getCurrentColorTheme: () => {
      return currentColorTheme;
    },
  }];
}
