import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconArrowRight from '../../../components/icons/IconArrowRight';

import RouteNames from '../../../appAuthorized/RouteNames';
import ConnectedIntlProvider from '../../../localization/ConnectedIntlProvider';

import { useNavigate } from '../../../hooks/useNavigate';

const RegisterNewJewelry = (props) => {
  const { color, shareCardId, languageCode, noExistingJewelry } = props;

  const phraseId = noExistingJewelry ? 'shareCardEdit.noMedicalJewelry.button.label' : 'shareCardEdit.anotherMedicalJewelry.button.label';
  const [, navActions] = useNavigate();

  return (
    <button
      className="font-footnote"
      style={{ color, display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'left' }}
      onClick={() => {
        navActions.goTo(RouteNames.shareCardRegisterNewJewelry(shareCardId));
      }}
    >
      <ConnectedIntlProvider locale={{ language_code: languageCode }}>
        <FormattedMessage id={phraseId} />
        <IconArrowRight.Small className=" " style={{ marginLeft: 10, fill: color }} />
      </ConnectedIntlProvider>
    </button>
  );
};

export default RegisterNewJewelry;
