import { useQuery } from 'react-query';

import { useDispatch } from 'react-redux';
import { actions as actionItemActions } from '../redux/ducks/actionItems';

import ProfileService from '../../apiServices/ProfileService';
import UserService from '../../apiServices/UserService';
import { useActiveProfile } from './useProfile';

const STALE_TIME = 5000;

function requestProfileActionItems(dispatch) {
  return function requestProfileActionItemsAPI(queryKey, profileId) {
    return new Promise((res, rej) => {
      ProfileService.getProfileActionsItems(profileId)
        .then((payload) => {
          const profileActionItems = payload.body.data;

          dispatch(actionItemActions.receiveProfileActionItems(profileId, profileActionItems));
          res({
            data: profileActionItems,
            isLoaded: true,
          });
        });
    });
  };
}

function requestUserActionItems(dispatch) {
  return function requestUserActionItemsAPI(queryKey, userId) {
    return new Promise((res, rej) => {
      UserService.getUserActionItems(userId)
        .then(payload => {
          const userActionItems = payload;

          dispatch(actionItemActions.receiveUserActionItems(userId, userActionItems));
          res({
            data: userActionItems,
            isLoaded: true,
          });
        });
    });
  };
}

const INITIAL_ACTION_ITEMS_STATE = {
  data: { steps: [] },
  isLoaded: false,
};
export function useUserActionItems(userId) {
  const dispatch = useDispatch();

  const queryData: any = useQuery(['userActionItems', userId], requestUserActionItems(dispatch), { enabled: Boolean(userId), staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_ACTION_ITEMS_STATE;
  const actionItemList = responseData.data.steps;
  const isLoaded = responseData.isLoaded;

  return [
    {
      isLoaded,
      actionItemList,
      isComplete: actionItemList.length === 0,
    },
  ];
}

export function useProfileActionItems(profileId) {
  const dispatch = useDispatch();
  const queryData: any = useQuery(['profileActionItems', profileId], requestProfileActionItems(dispatch), { enabled: Boolean(profileId), staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_ACTION_ITEMS_STATE;
  const actionItemList = responseData.data.steps;
  const isLoaded = responseData.isLoaded;

  return [
    {
      isLoaded,
      actionItemList,
    },
  ];
}

export function useActiveProfileActionItems() {
  const [{ profileId }] = useActiveProfile();
  return useProfileActionItems(profileId);
}
