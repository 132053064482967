
import React from 'react';

export const IconSlot: React.FC = (props) => {

  return (
    <div
      style={{
        margin: '0 4px',
      }}
    >
      {props.children}
    </div>
  );
};
