import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as _ from 'lodash';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SelectableRow from '../../../components/SelectableRow';
import { Button } from '../../../components/buttons/Buttons';
import PreformattedText from '../../../components/PreformattedText';
import UserService from '../../../../apiServices/UserService';
import NavigationActions from '../../../appAuthorized/redux/NavigationActions';
import PublicRouteNames from '../../PublicRouteNames';
import SystemNotification from '../../../components/SystemNotification';

import './Unsubscribe.styles.scss';

const BACKPACK_KEY = 'backpack';
const THIRDPARTY_KEY = 'thirdparty';

interface Props {
  history: any;
  cuid: string;
  showNotification: Function;
}

interface State {
  [BACKPACK_KEY]: boolean;
  [THIRDPARTY_KEY]: boolean;
}

class Unsubscribe extends Component {
  props: Props;
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      backpack: false,
      thirdparty: false,
    };
  }

  handleUnsubscribeOptionClick = (optionName: 'backpack' | 'thirdparty') => {
    const { [optionName]: optionValue } = this.state;

    if (optionName === BACKPACK_KEY && !optionValue) {
      // case when user selects Backpack News
      this.setState({ [BACKPACK_KEY]: true, [THIRDPARTY_KEY]: true });
      return;
    }

    if (optionName === THIRDPARTY_KEY && optionValue) {
      // case when user unselects Third-Party Promotions
      this.setState({ [BACKPACK_KEY]: false, [THIRDPARTY_KEY]: false });
      return;
    }

    this.setState({ [optionName]: !optionValue });
  }

  handleUnsubscribeBtnClick = () => {
    const { cuid } = this.props;

    if (!cuid) {
      throw new Error('cuid param is not provided');
    }

    const data = [];
    this.state[BACKPACK_KEY] && data.push(BACKPACK_KEY);
    this.state[THIRDPARTY_KEY] && data.push(THIRDPARTY_KEY);

    UserService.unsubscribe(cuid, data, (apiError) => {
      if (apiError) {
        this.props.showNotification(apiError.messageKey());
        return;
      }

      const { history } = this.props;
      NavigationActions.goTo(history, PublicRouteNames.unsubscribeSuccess());
    });
  }

  render() {
    const { [BACKPACK_KEY]: backpack, [THIRDPARTY_KEY]: thirdparty } = this.state;

    return (
      <div className="cmp-unsubscribe bkgd-color-gsLightest">
        <SystemNotification />
        <Header />
        <div className="content util-inset-listitem-frame-padding">
          <div className="font-title-1 font-color-gsDark">
            <FormattedMessage id="unsubscribe.title.text" />
          </div>
          <div className="util-inset-listitem-frame-padding font-body">
            <SelectableRow
              data={BACKPACK_KEY}
              isSelected={backpack}
              fillClassName="fill-color-rb-highlight"
              isLeftBasedCircle
              onClick={this.handleUnsubscribeOptionClick}
            >
              <FormattedMessage id="accountCommsOptions.backpackNews.option" />
            </SelectableRow>
            <SelectableRow
              data={THIRDPARTY_KEY}
              isSelected={thirdparty}
              fillClassName="fill-color-rb-highlight"
              isLeftBasedCircle
              onClick={this.handleUnsubscribeOptionClick}
            >
              <FormattedMessage id="accountCommsOptions.3rdParty.option" />
            </SelectableRow>
          </div>
          <Button
            action="backpack"
            className="font-body"
            size="wide"
            isDisabled={!backpack && !thirdparty}
            onClick={this.handleUnsubscribeBtnClick}
          >
            <FormattedMessage id="unsubscribe.unsubscribe.button.label" />
          </Button>
          <PreformattedText textClassName="font-body" text="accountCommsOptions.systemMessages.message.text" />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Unsubscribe;
