import React from 'react';
import classnames from 'classnames';

interface Props {
  className?: string;
  children: any;
}

const AppNavHeaderTitle = (props: Props) => (
  <div className={classnames('cmp-appnav-header-title', 'font-caption-1-bold__STATIC', props.className)}>{props.children}</div>
);

export default AppNavHeaderTitle;
