
import React from 'react';

import ContactForm from './components/ContactForm';

import { ContactModelType } from '../../../models/ContactModel';
import RouteNames from '../../appAuthorized/RouteNames';

import { useUIEditContact } from '../../hooks/useUIEdit';
import { useUIState } from '../../hooks/useUIState';
import { useNavigate } from '../../hooks/useNavigate';
import { useContactForActiveProfile } from '../../hooks/useContacts';
import AppUtils from '../../../utilities/AppUtils';
import { useHealthEntriesForActiveProfile, useHealthEntryValuesForActiveProfile } from '../../hooks/useHealthEntry';
import { attachmentTypes } from '../../../models/AttachmentModel';
import { useTrackableListForActiveProfile } from '../../hooks/useTrackable';

const ViewContactEditNew = (props) => {
  const params = AppUtils.getQueryParams(window.location.search);
  const { attachmentType, parentId } = params;

  // if these values exist it is being attached and should default to provider
  const isDefaultProvider = Boolean(attachmentType || parentId);

  const [, contactActions] = useContactForActiveProfile(null);
  const [{ activeProfileId }, uiActions] = useUIState();
  const [{ editingContact }, uiEditActions] = useUIEditContact(null, activeProfileId, isDefaultProvider);
  const [{ heValueToHeLookup }] = useHealthEntryValuesForActiveProfile();
  const [{ observationToTrackableId }] = useTrackableListForActiveProfile();
  const [, navActions] = useNavigate();

  React.useEffect(() => {
    uiEditActions.updateContact(editingContact);
  }, []);

  return (
    <ContactForm
      titlePhrase={{ id: 'contact.addContact.title' }}
      contact={editingContact}
      contactId={null}
      onSubmit={(data: ContactModelType) => {
        contactActions.apiCreateContact(data, attachmentType, parentId)
          .then((newContact: ContactModelType) => {
            let onSuccessRoute = RouteNames.contact(newContact.id);
            if (!attachmentType) {
              uiEditActions.clearContact();
              navActions.replace(onSuccessRoute, null, [{ pathname: RouteNames.contactAdd() }]);
              return;
            }

            switch (attachmentType) {
              case attachmentTypes.healthEntry:
                onSuccessRoute = RouteNames.healthEntry(parentId);
                break;
              case attachmentTypes.healthEntryValue:
                onSuccessRoute = RouteNames.healthEntryValue(heValueToHeLookup[parentId], parentId);
                break;
              case attachmentTypes.trackable:
                onSuccessRoute = RouteNames.trackableObservationList(parentId);
                break;
              case attachmentTypes.trackableObservation:
                onSuccessRoute = RouteNames.trackableObservation(observationToTrackableId[parentId], parentId);
                break;
              default:
                break;
            }
            navActions.goBackTo(onSuccessRoute, null, [{ pathname: RouteNames.contactAdd() }]);

            uiEditActions.clearContact();
          }).catch((apiError) => {
            uiActions.showNotification(apiError.messageKey());
          });
      }}
      onCancel={() => {
        navActions.goBack(RouteNames.contactList());
        uiEditActions.clearContact();
      }}
    />
  );
};

export default ViewContactEditNew;
