
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ShareCardSection from '../components/ShareCardSection';
import { ShareCardColorModel } from '../../../appAuthorized/dataTransformComponents/ColorShareCard';

import RegisteredJewelry from '../components/RegisteredJewelry';
import RegisterNewJewelry from '../components/RegisterNewJewelry';
import DeleteModal from '../../../components/modal/DeleteModal';
import { useActiveUser } from '../../../hooks/useUser';

interface Props {
  shareCardColorModel: ShareCardColorModel;
  allowsJewelry: boolean;
  jewelryCollection: any[];
  shareCardId: number;
  onRemove(id): void;
}

const ShareCardEditJewelry: React.FC<Props> = (props) => {
  const { shareCardColorModel, jewelryCollection, shareCardId, onRemove, allowsJewelry } = props;
  const [{ preferredLanguageCode }] = useActiveUser();

  const [removeJewelryId, setRemoveJewelryId] = React.useState(null);

  if (!allowsJewelry) { return null; }

  return (
    <ShareCardSection
      shareCardColorModel={shareCardColorModel}
    >
      <ShareCardSection.Title>
        <FormattedMessage id="shareCard.medicalJewelry.title.label" />
      </ShareCardSection.Title>

      <ShareCardSection.Content>

        {
          jewelryCollection.map((j, i) => (
            <RegisteredJewelry
              key={i}
              data={j}
              onRemove={(id) => setRemoveJewelryId(id)}
              shareCardColorModel={shareCardColorModel}
            />
          ))
        }

        <RegisterNewJewelry
          shareCardId={shareCardId}
          languageCode={preferredLanguageCode}
          noExistingJewelry={!jewelryCollection.length}
          color={shareCardColorModel.darkAccentColor()}
        />

      </ShareCardSection.Content>

      <DeleteModal
        isVisible={removeJewelryId !== null}
        onConfirmAction={() => { setRemoveJewelryId(null); onRemove(removeJewelryId); }}
        onCancelAction={() => { setRemoveJewelryId(null); }}
        isAutoDismissDisabled={true}
      />
    </ShareCardSection>
  );
};

export default ShareCardEditJewelry;
