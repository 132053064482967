import { connect } from 'react-redux';
import * as _ from 'lodash';

import Unsubscribe from './Unsubscribe.controller';
import { actions as notificationActions } from '../../../redux/ducks/systemNotification';

function mapStateToProps(state, routerProps) {
  return {
    cuid: _.get(routerProps, 'match.params.cuid', ''),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: (messageId) => {
      dispatch(notificationActions.showNotification(messageId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);
