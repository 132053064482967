import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { SecondaryButton, CloseButton } from '../buttons/Buttons';
import FlexRow from '../FlexRow';
import Spacer from '../Spacer';
import IconWarningIcons from '../icons/IconWarning';
import { actions as systemModalActions } from '../../redux/ducks/systemModal';
import { getActiveProfileId } from '../../appAuthorized/redux/reduxSelectors';

interface Props {
  reject(): void;
  dismiss(): void;
}

interface State {
  selectedReason: string;
}

const rejectReasons = [
  'pendingDataRejectConfirm.reasons.incorrect-info',
  'pendingDataRejectConfirm.reasons.for-another-family-member',
  'pendingDataRejectConfirm.reasons.dont-want-in-backpack',
  'pendingDataRejectConfirm.reasons.other-reason',
];

const mapStateToProps = (state, routerProps) => {
  const profileId = getActiveProfileId(state);
  const { healthEntryIds, healthEntryValueIds, history } = routerProps;

  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dismiss: () => {
      dispatch(systemModalActions.dismiss());
    },
  };
};

const RejectPendingDataConfirmModal: React.FC<Props> = (props) => {
  return (
    <div className="cmp-warningmodal" style={{ padding: 0 }}>
      <div style={{ padding: '24px 24px 0 24px' }}>
        <FlexRow style={{ alignItems: 'flex-start' }}>
          <div>
            <IconWarningIcons.XLarge strokeClassName="stroke-color-profile-accent" />
          </div>
          <CloseButton align="right" verticalAlign="top" className="bkgd-color-gsMiddleDT" onClick={props.dismiss} />
        </FlexRow>

        <Spacer size="12" />

        <div className="font-title-2 font-color-profile">
          <FormattedMessage id="pendingDataRejectConfirm.heading" />
        </div>

        <Spacer size="9" />

        <div className="font-headline font-color-gsDark">
          <FormattedMessage id="pendingDataRejectConfirm.subheading" />
        </div>

        <Spacer size="12" />

        <div className="font-subhead font-color-gsDark">
          <FormattedMessage id="pendingDataRejectConfirm.message" />
        </div>
      </div>

      <div style={{ padding: '24px 24px 12px' }}>
        <SecondaryButton
          isDisabled={false}
          style={{ paddingTop: 12, paddingBottom: 12, margin: 0 }}
          className="font-title-2 font-color-profile-accent border-profile-accent"
          onClick={() => {
            props.dismiss();
            props.reject();
          }}
        >
          <FormattedMessage id="pendingDataRejectConfirm.confirm.button.label" />
        </SecondaryButton>
      </div>

      <div style={{ padding: '0 24px 24px 24px' }} className="cmp-alternate-control font-subhead font-color-gsDark" onClick={props.dismiss}>
        <FormattedMessage id="pendingDataRejectConfirm.cancel.button.label" />
      </div>
    </div>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RejectPendingDataConfirmModal));
