import React from 'react';
import { withRouter } from 'react-router';

import store from '../redux/reduxStore_authorizedApp';
import actionNames from '../appAuthorized/redux/actionNames';

const getHistory = (state) => {
  return state.navigation.pageHistory;
};

function navigationGoTo(pageData) {
  return {
    type: actionNames.navigationGoTo,
    payload: pageData,
  };
}

function navigationSetHistory(pageHistory) {
  return {
    type: actionNames.navigationSetHistory,
    payload: pageHistory,
  };
}

// function savePageState(newState) {
//   const state = store.getState();
//   const currentHistory = getHistory(state);
//   const currentPage = currentHistory.pop();
//   currentPage.state = newState;

//   currentHistory.push(currentPage);
//   store.dispatch(navigationSetHistory(currentHistory));
// }

function goTo(history, pathname, pState, scrollAtTop) {
  if (scrollAtTop) {
    window.scrollTo(0, 0);
  }
  const pushState = pState || {};
  history.push(pathname, pushState);
  store.dispatch(navigationGoTo({
    pathname,
    state: pushState,
  }));
}

function goBackTo(history, desiredPathname, desiredPageState = {}, pagesToSkip = []) {
  const state = store.getState();
  const currentHistory = getHistory(state);
  let goBackCount = 0;
  let foundPage = false;
  const matchPageState = Object.keys(desiredPageState || {}).length > 0;

  while (currentHistory.length > 0) {
    const page = currentHistory.pop();
    const mainPathName = page.pathname.split('?')[0];
    goBackCount--;
    if (mainPathName === desiredPathname) {
      foundPage = matchPageState ? JSON.stringify(page.state) === JSON.stringify(desiredPageState) : true;

      const matches = pagesToSkip.filter((p) => {
        if (p.pathname && p.pathname === page.pathname) {
          return true;
        } else if (p.state && JSON.stringify(p.state) === JSON.stringify(page.state)) {
          return true;
        } else {
          return false;
        }
      });

      if (matches.length === 0 && foundPage) {
        currentHistory.push(page);
        goBackCount++;
        break;
      }

      foundPage = false;
    }
  }

  if (foundPage && goBackCount === 0) {
    return;
  } else if (foundPage) {
    history.go(goBackCount);
    store.dispatch(navigationSetHistory(currentHistory));
  } else {
    history.push(desiredPathname, desiredPageState);
    store.dispatch(navigationGoTo({
      pathname: desiredPathname,
      state: desiredPageState,
    }));
  }
}

function goBack(history, defaultPathname, defaultPageState, pagesToSkip = []) {
  const state = store.getState();
  const currentHistory = getHistory(state);
  currentHistory.pop();
  let goBackCount = -1;

  if (pagesToSkip.length > 0) {
    while (currentHistory.length > 0) {
      const page = currentHistory.pop();
      const matches = pagesToSkip.filter((p) => {
        if (p.pathname && p.pathname === page.pathname) {
          return true;
        } else if (p.state && JSON.stringify(p.state) === JSON.stringify(page.state)) {
          return true;
        } else {
          return false;
        }
      });

      if (matches.length === 0) {
        currentHistory.push(page);
        break;
      }

      goBackCount--;
    }
  }

  if (currentHistory.length > 0) {
    store.dispatch(navigationSetHistory(currentHistory));
    history.go(goBackCount);
  } else if (defaultPathname) {
    history.push(defaultPathname);
    store.dispatch(navigationGoTo({
      pathname: defaultPathname,
      state: defaultPageState,
    }));
  } else {
    history.push('/');
    store.dispatch(navigationGoTo({
      pathname: '/',
    }));
  }
}

// function goPrevious(history) {
//   history.goBack();
// }

function replace(history, pathname, pState, scrollAtTop) {
  const state = store.getState();
  const currentHistory = getHistory(state);
  const pageState = pState || {};
  history.replace(pathname, pageState);

  if (scrollAtTop) {
    window.scrollTo(0, 0);
  }

  currentHistory.pop();
  currentHistory.push({
    pathname,
    state: pageState,
  });
  store.dispatch(navigationSetHistory(currentHistory));
}

const NavigationHistoryContext = React.createContext(null);

const NavigationHistoryProviderWithRouter = (props) => {
  const { history } = props;
  const navHistory = {
    history,
  };
  const navActions = {
    goTo: (pathname, pageState, scrollAtTop) => {
      goTo(history, pathname, pageState, scrollAtTop);
    },
    goBackTo: (pathname, desiredPageState = {}, pagesToSkip = []) => {
      goBackTo(history, pathname, desiredPageState, pagesToSkip);
    },
    goBack: (defaultPathname, defaultPageState, pagesToSkip) => {
      goBack(history, defaultPathname, defaultPageState, pagesToSkip);
    },
    replace: (pathname, pageState, scrollAtTop) => {
      replace(history, pathname, pageState, scrollAtTop);
    },
  };

  return (
    <NavigationHistoryContext.Provider value={[navHistory, navActions]}>
      {props.children}
    </NavigationHistoryContext.Provider>
  );
};

export const NavigationHistoryProvider = withRouter(NavigationHistoryProviderWithRouter);

export const useNavigate = () => {
  return React.useContext(NavigationHistoryContext);
};
