import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ContentList } from '../../contentViews/ContentList';

import { ProfileFileListItemFlaggedRight } from '../../components/FileListItem';

import { filesZeroState } from '../../components/healthEntriesList/ZeroState';

import withExtendedProps from '../../components/wrappers/withExtendedProps';
import { GrayTextMarkdown } from '../../components/Markdown';

import { Card } from '../../../bphComponents/bundle';
import RouteNames from '../../appAuthorized/RouteNames';
import { useFilesForActiveProfile } from '../../hooks/useFile';
import { useUIState } from '../../hooks/useUIState';
import { useAttachmentsForActiveProfile } from '../../hooks/useAttachments';
import { useHealthEntriesForProfile } from '../../hooks/useHealthEntry';
import { useActiveProfile } from '../../hooks/useProfile';
import { useNavigate } from '../../hooks/useNavigate';

const BackpackFiles: React.FC = () => {
  const [, uiStateActions] = useUIState();
  const [{ profileId }] = useActiveProfile();

  const [attachmentListData] = useAttachmentsForActiveProfile();
  const [heState] = useHealthEntriesForProfile(profileId);
  const [, navActions] = useNavigate();

  const [{ fileList, ...fileListData }] = useFilesForActiveProfile();
  const files = fileList.map(n => ({ file: n, widthOffset: 0, displayKind: 'list' }));
  const isLoaded = attachmentListData.isLoaded && fileListData.isLoaded && heState.isLoaded;

  React.useEffect(() => {
    if (!isLoaded && fileList.length === 0) {
      uiStateActions.setStartLoading();
    } else {
      uiStateActions.setStopLoading();
    }
  }, [isLoaded]);

  if (!isLoaded) { return null; }

  return (
    <ContentList
      collection={files}
      zeroState={filesZeroState}
      onClickItem={(f) => {
        navActions.goTo(RouteNames.file(f.file.id));
      }}
      itemComponent={withExtendedProps({ hideFileSize: true })(ProfileFileListItemFlaggedRight)}
      helpContent={(
        <Card.InfoCollapsibleCard
          isFlat={true}
          title={<FormattedMessage id="bpFiles.filesHelp.heading" />}
          subTitle={<FormattedMessage id="bpFiles.filesHelp.subheading" />}
          collapsedContent={<GrayTextMarkdown phraseId="bpFiles.filesHelp.message.md" />}
        />
      )}
    />
  );
};

export default BackpackFiles;
