import DateUtil from '../utilities/DateUtil';


export interface iDateTime {
  precision: string;
  date: string;
  time?: string;
  timezone_offset?: string;
}


function calculateTimeZoneOffsetFormat() {
  const timezoneOffsetMin = new Date().getTimezoneOffset();
  const offsetHrs = Number(Math.abs(timezoneOffsetMin / 60));
  const offsetMin = Math.abs(timezoneOffsetMin % 60);
  let timezoneStandard;
  let offsetHrsString = `${offsetHrs}`;
  let offsetMinString = `${offsetMin}`;
  if (offsetHrs < 10) {
    offsetHrsString = `0${offsetHrsString}`;
  }
  if (offsetMin < 10) {
    offsetMinString = `0${offsetMinString}`;
  }
  // Add an opposite sign to the offset
  // If offset is 0, it means timezone is UTC
  if (timezoneOffsetMin < 0) {
    timezoneStandard = `+${offsetHrsString}:${offsetMinString}`;
  } else if (timezoneOffsetMin > 0) {
    timezoneStandard = `-${offsetHrsString}:${offsetMinString}`;
  } else if (timezoneOffsetMin === 0) {
    timezoneStandard = 'Z';
  }

  // Timezone difference in hours and minutes
  // String such as +5:30 or -6:00 or Z
  return timezoneStandard;
}


export class DateTimeModel {

  static createNew() {
    return {
      precision: 'P1DT',
      date: DateUtil.today(),
      time: DateUtil.getNowTime(),
      // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezone_offset: calculateTimeZoneOffsetFormat(),
    };
  }

  public data: iDateTime;
  private intl: any;

  constructor(d: iDateTime, intl: any) {
    this.data = {
      ...d,
      time: d.time || '',
    };

    this.intl = intl;
  }

  hasDate(): boolean {
    return Boolean(this.data.date && this.data.precision);
  }

  hasTime(): boolean {
    return Boolean(this.data.time);
  }

  formatTime(): string {
    const parts = this.getTimeParts();
    const period = parts.isAM ? 'ui-data.time.am.text' : 'ui-data.time.pm.text';
    const hours = parts.hours;
    const minutes = `00${parts.minutes}`.substr(-2);

    return `${hours}:${minutes} ${this.intl.formatMessage({ id: period })}`;
  }

  getTimeParts() {
    const timeParts = this.data.time.split(':');
    const numHours =  Number(timeParts[0]);
    const hours = numHours === 0
    ? 12
    : numHours > 12
      ? numHours - 12
      : numHours;

    return {
      hours,
      minutes: Number(timeParts[1]) || 0,
      seconds: Number(timeParts[2]) || 0,
      isAM: numHours < 12,
    };
  }
}
