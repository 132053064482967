
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { DateTimeModel } from '../../models/DateTimeModel';
import { RelativeDate } from '../localization/LocalizedDate';

export const DateTimeSummary = injectIntl((props) => {
  const { date, intl } = props;

  /*
    "precision": "P1DT",
		"date": "2020-08-10",
		"time": "12:13:14",
		"timezone": "America/New_York"
  */
  const dateTime = new DateTimeModel(date, intl);

  if (dateTime.hasDate() && dateTime.hasTime()) {
    return (
      <div>
        <RelativeDate date={date.date} precision={date.precision} dateKind="medium" /> – <TimeSummary date={date} />
      </div>
    );
  } else if (dateTime.hasDate()) {
    return (
      <div>
        <RelativeDate date={date.date} precision={date.precision} dateKind="medium" />
      </div>
    );
  } else if (dateTime.hasTime()) {
    return (
      <div>
        <TimeSummary date={date} />
      </div>
    );
  } else {
    return (
      <div>
        <FormattedMessage id="ui-data.date.not-specified.text" />
      </div>
    );
  }
});

export const TimeSummary = injectIntl((props) => {
  const { date, intl } = props;

  /*
    "precision": "P1DT",
		"date": "2020-08-10",
		"time": "12:13:14",
		"timezone": "America/New_York"
  */
  const dateTime = new DateTimeModel(date, intl);

  if (!dateTime.hasTime()) { return null; }

  const timeParts = dateTime.getTimeParts();
  const minutes = `00${timeParts.minutes}`.slice(-2);
  const periodPhrase = timeParts.isAM ? 'ui-data.time.am.text' : 'ui-data.time.pm.text';

  return (
    <>
      {timeParts.hours}:{minutes} <FormattedMessage id={periodPhrase} />
    </>
  );
});
