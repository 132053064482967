import { request } from './request';

export interface I18nText {
  default?: string;
  i18n: { [locale: string]: string };
}

export enum ContentCategory {
  ShareCard = 'share_card',
  Resource = 'resource',
}

export interface Content {
  id: number;
  category: ContentCategory;
  title: I18nText;
  short_description: I18nText;
  body: I18nText;
}

export const contentService = {
  getContents(profileId, groupId, category): Promise<Content[]> {
    return request('get', `/profiles/${profileId}/groups/${groupId}/content?category=${category}`);
  },
};
