import React from 'react';
import classnames from 'classnames';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  size?: string;
  color?: string;
  className?: string;
}

const DEFAULT_SIZE = '24px';
const DEFAULT_COLOR = 'gsDarkest';
const getClassName = (props: Props) => {
  const { color = DEFAULT_COLOR, className } = props;
  return className || classnames(colorClassNames.fill(color));
};

const IconDataConnections = (props: Props) => (
  <svg width={props.size || DEFAULT_SIZE} height={props.size || DEFAULT_SIZE} viewBox="0 0 24 24">
    <title>st-icon-dataconnections</title>
    <g id="icon-data-connections">
      <path className={getClassName(props)} fillRule="evenodd" d="M21.48,11.56H17.91a3.66,3.66,0,0,0-.1-1.36,3.33,3.33,0,0,0-.53-1.29,3.46,3.46,0,0,0-2.36-1.47,3.4,3.4,0,0,0-1.42.09h0a3.56,3.56,0,0,0-1.5.85,3.45,3.45,0,0,0-1.48-.83A3.41,3.41,0,0,0,9.1,7.46,3.51,3.51,0,0,0,6.74,8.93a3.58,3.58,0,0,0-.54,1.29,3.94,3.94,0,0,0,0,1.9,3.82,3.82,0,0,0,.91,1.69,23.22,23.22,0,0,0,3.19,2.92c.2.16.46.35.72.53a1.55,1.55,0,0,0,1,.3,1.66,1.66,0,0,0,1-.32h0l.71-.53a23.22,23.22,0,0,0,3.19-2.92,3.91,3.91,0,0,0,.59-.85h3a.06.06,0,0,1,0,.06A8.63,8.63,0,0,1,4.23,15.79,8.63,8.63,0,0,1,16.29,4.54a2.09,2.09,0,0,0,3.63,1.83,2.08,2.08,0,0,0-2.84-3,10.12,10.12,0,0,0-11.46.88,10.1,10.1,0,0,0-.85,14.65,10.12,10.12,0,0,0,14.69-.27,10,10,0,0,0,2.51-6v-.65A.5.5,0,0,0,21.48,11.56Zm-5,.2a2.56,2.56,0,0,1-.59,1.1h0a22.37,22.37,0,0,1-3,2.75h0l-.67.49a.3.3,0,0,1-.2.05.25.25,0,0,1-.17-.05,5.32,5.32,0,0,1-.66-.47h0a22.37,22.37,0,0,1-3-2.75,2.46,2.46,0,0,1-.59-1.1,2.37,2.37,0,0,1,0-1.24v0a2,2,0,0,1,.32-.78,2.17,2.17,0,0,1,.62-.59,2.1,2.1,0,0,1,.8-.3,2,2,0,0,1,.85.06h0a2.13,2.13,0,0,1,1.27.95l.59,1,.59-1a2.13,2.13,0,0,1,1.27-1,2,2,0,0,1,.85-.06,2,2,0,0,1,.8.3,2.17,2.17,0,0,1,.62.59,2,2,0,0,1,.32.78v0A2.48,2.48,0,0,1,16.47,11.76ZM18.29,4.4a.69.69,0,0,1,0,1.38A.69.69,0,0,1,18.29,4.4Z"/>
    </g>
  </svg>
);

export default IconDataConnections;
