
import React, { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import './PublicShareCardFooter.scss';

import { ExternalLink } from '../../../components/TextLink';
import PreformattedText from '../../../components/PreformattedText';
import LogoBackpackRegistered from '../../../components/icons/LogoBackpackRegistered';

const PublicShareCardFooter = ({ className = 'default-padding', white = false, style = undefined as CSSProperties }) => (
    <footer className={cx(`cmp-publicsharecard-footer ${className}`, { white })} style={style}>
      <div className="cmp-publicsharecard-footer_content">
        <div className="cmp-publicsharecard-footer_copyright-side font-caption-1 font-color-meddark-gray">
          <FormattedMessage id="backpackHealth.url.url">
            {
              (url: string) => (
                <a href={url}>
                  <LogoBackpackRegistered colorName={white ? 'white' : 'red'} size="large" imageType="png" />
                </a>
              )
            }
          </FormattedMessage>

          <div style={{ marginTop: 10 }}>
            <PreformattedText>
              <FormattedMessage id="backpack.copyright.message.text" />
            </PreformattedText>
          </div>
        </div>

        <div className="cmp-publicsharecard-footer_messaging-side font-subhead font-color-meddark-gray">
          <PreformattedText>
            <FormattedMessage id="backpackHealth.url.url">
              {
                (url) => (
                  <FormattedMessage
                    id="shareCardURL.html.tagline.text"
                    values={{
                      string1:
                        <ExternalLink href={url} className="font-color-backpack-theme-base" style={{ verticalAlign: 'baseline' }}>
                          <FormattedMessage id="backpackHealth.url.text" />
                        </ExternalLink>,
                    }}
                  />
                )
              }
            </FormattedMessage>
          </PreformattedText>
        </div>
      </div>
    </footer>
);

export default PublicShareCardFooter;
