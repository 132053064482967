import React from 'react';

import { DeviceNotifications } from '../../models/DeviceNotifications';
import { PermissionsRequiredModal, requiredPermissionTypes } from '../components/modal/WarningModal';
import { useActiveProfile } from './useProfile';
import { useTrackableListForActiveProfile } from './useTrackable';
import { useUIState } from './useUIState';

export function useTrackableReminders(trackableId, intl) {
  const [{ isActiveProfileMainProfile }] = useUIState();
  const [{ greetingName, profileId }] = useActiveProfile();
  const [reminderState, setReminderData] = React.useState(DeviceNotifications.defaultObservationReminderData(profileId, trackableId)) ;
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [{ trackableLookup, trackableTermLookup }] = useTrackableListForActiveProfile();
  const [, uiStateActions] = useUIState();
  const trackable = trackableLookup[trackableId];
  const term = trackable ? trackableTermLookup[trackable.trackable_term_id] : null;
  const termName = term ? term.name : '';

  const reminderText = isActiveProfileMainProfile
    ? intl.formatMessage({ id: 'appNotification.android.trackableReminder.user.message' }, { string1: termName })
    : intl.formatMessage({ id: 'appNotification.android.trackableReminder.notUser.message' }, { string1: termName, string2: greetingName });
  const reminderTitle = intl.formatMessage({ id: 'appNotification.android.trackableReminder.heading' });

  const notification = DeviceNotifications.createObservation(profileId, trackableId, reminderState, reminderText, reminderTitle);

  React.useEffect(() => {
    DeviceNotifications.requestObservationNotifications(profileId, trackableId)
      .then((res: any) => {
        setReminderData(res);
        setIsLoaded(true);
      });
  }, []);

  return [
    {
      reminderData: reminderState,
      isLoaded: isLoaded && Boolean(term),
    },
    {
      instantiate: (data) => {
        return DeviceNotifications.createObservation(profileId, trackableId, data, reminderText, reminderTitle);
      },
      updateReminder: (newReminderData) => {
        const newReminder = DeviceNotifications.createObservation(profileId, trackableId, newReminderData, reminderText, reminderTitle);
        return newReminder.schedule();
      },
      setDuration: (startDate, dayCount) => {
        return new Promise((res) => {
          notification.setDuration(startDate, dayCount);
          notification.schedule();
        });
      },
      setFrequency: () => {

      },
      createDefaultTime: () => {
        return DeviceNotifications.getNextTimeIncrement();
      },
      getTimezone: () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      },
      deleteReminder: () => {
        return new Promise((res) => {
          notification.cancel().then(res);
        });
      },
      checkAndRequestPermission: () => {
        return new Promise((res, rej) => {
          DeviceNotifications.checkAndRequestPermission()
            .then(res)
            .catch(() => {
              rej();

              uiStateActions.pushSystemModal(
                <PermissionsRequiredModal
                  onDismiss={() => {}}
                  permissions={[requiredPermissionTypes.notifications]}
                />,
              );
            });
        });
      },
    },
  ];
}
