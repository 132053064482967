import _ from 'lodash';

import Comment, { CommentCollection } from '../../models/Comment';
import { DateTimeModel } from '../../models/DateTimeModel';
import DateUtil from '../../utilities/DateUtil';
import { isValidTrackableQuantity } from '../../utilities/Validators';

export interface iAttachmentCount {
  file: number;
  note: number;
  contact: number;
}

export interface iLocalizedString {
  string: {
    [lang: string]: string;
  };
}

export interface iTrackableTerm {
  cui: string;
  id: string;
  name: iLocalizedString;
}

export interface iObservationDate {
  precision: string;
  date: string;
  time: string;
  timezone_offset: string;
}

export interface iObservationAttributeDefinitionGeneric {
  id: string;
  name_trackable_term_id: number;
  hint_text: {
    string: {
      [lang: string]: string;
    };
  };
  help_text: iTrackableTerm;
}
export interface iObservationAttributeListDefinition extends iObservationAttributeDefinitionGeneric {
  category: 'list';
  list: {
    multi_select: boolean;
    options: {
      trackable_term_id: number[];
    };
  };
}

export interface iObservationAttributeListValue {
  attribute_definition_id: string;
  category: 'list';
  list: {
    selected_option_ids: number[];
  };
  summary: iLocalizedString;
}

export interface iObservationAttributeScaleDefinition extends iObservationAttributeDefinitionGeneric {
  category: 'scale';
  scale: {
    options: {
      slider_label_trackable_term_id: number;
      track_label_trackable_term_id: number;
      value: string;
    }[];
  };
}

export interface iObservationAttributeScaleValue {
  attribute_definition_id: string;
  category: 'scale';
  scale: {
    selected_option_value: number;
  };
  summary: iLocalizedString;
}

export interface iObservationAttributeQuantityDefinition extends iObservationAttributeDefinitionGeneric {
  category: 'quantity';
  quantity: {
    units: {
      trackable_term_id: number[];
    };
  };
}

export interface iObservationAttributeQuantityValue {
  attribute_definition_id: string;
  category: 'quantity';
  quantity: {
    value: string;
    unit_trackable_term_id: number;
  };
  summary: iLocalizedString;
}

export interface iTrackableAttributeDefinition {
  category: 'scale' | 'list' | 'quantity';
  help_text?: iLocalizedString;
  hint_text?: iLocalizedString;
  id: string;
  name_trackable_term_id: number;
  scale?: {
    options: {
      slider_label_trackable_term_id: number;
      track_label: string;
      value: string;
    }[];
  };
  list?: {
    multi_select: boolean;
    options: {
      trackable_term_id: number[];
    };
  };
  quantity?: {
    units: {
      trackable_term_id: number[];
    };
  };
}
export interface iTrackableDefinition {
  id: number;
  name_trackable_term_id: number;
  attributes: iTrackableAttributeDefinition[];
  primary_attribute_id?: string;
  hint_text?: {
    string: {
      [lang: string]: string;
    };
  };
}

export interface iMetadataTrackable {
  all: { id: number; name: string; }[];
  latest: { id: number; name: string; }[];
}
export interface iComment {
  content: string;
  include_on_share_card: boolean;
}
export interface iTrackable {
  attachment_count: iAttachmentCount;
  comments: iComment[];
  current_definition_id: number;
  flagged: boolean;
  has_attachments: boolean;
  id: number;
  observations: iObservation[];
  shortcut_enabled: boolean;
  trackable_term_id: number;
}

export interface iObservationsAttributeValue {
  attribute_definition_id: string;
  category: string;
  quantity?: {
    unit_trackable_term_id: number;
    value: string;
  };
  list?: {
    selected_option_ids: number[];
  };
  scale?: {
    selected_option_value: string;
  };
}

export interface iEditableObservation {
  attributes: iObservationsAttributeValue[];
  comment: iComment;
  date: iObservationDate;
  flagged: boolean;
  trackable_definition_id: number;
}
export interface iObservation extends iEditableObservation {
  attachment_count: iAttachmentCount;
  created_at: string;
  has_attachments: boolean;
  id: number;
  summary: string;
  updated_at: string;
}

export const emptyObservation: iObservation = {
  attributes: [],
  comment: {
    include_on_share_card: false,
    content: '',
  },
  date: null,
  flagged: false,
  trackable_definition_id: null,
  id: null,
  created_at: null,
  updated_at: null,
  summary: '',
  has_attachments: null,
  attachment_count: null,
};
export interface iTrackableShortcut {
  latest_observation: {
    date: iObservationDate;
    summary: string;
  };
  observation_count: number;
  trackable_id: number;
  trackable_term_id: number;
}

export interface iHealthEntryTrackable {
  health_entry_id: number;
  id: number;
  observation_count: number;
  profile_id: number;
  trackable_id: number;
  trackable_term_id: number;
}

export class HealthEntryTrackables {

  public data: iHealthEntryTrackable[];

  constructor(enabledTrackables: iHealthEntryTrackable[]) {
    this.data = enabledTrackables.map(t => ({ ...t }));
  }

  isEnabled(termId) {
    return Boolean(this.data.find(t => t.trackable_term_id === termId));
  }

  disable(termId) {
    this.data = this.data.filter(t => t.trackable_term_id !== termId);
  }

  enable(terms) {
    let newData = [];
    if (Array.isArray(terms)) {
      newData = this.data.filter(d => {
        const item = terms.find(t => t.trackable_term_id === d.trackable_term_id);
        return !item;
      });
      newData = newData.concat(terms);
    } else {
      newData = this.data.filter(t => t.trackable_term_id !== terms.trackable_term_id);
      newData.push(terms);
    }

    this.data = [...newData];
  }

  heIdsForTrackable(trackableId) {
    return this.data.filter(d => d.trackable_id === trackableId).map(d => d.health_entry_id);
  }
}

export class TrackableModel {

  public static getLatestObservation(d: iTrackable, languageCode): ObservationModel {
    return d.observations.length > 0
      ? new ObservationModel(d.observations[d.observations.length - 1], languageCode)
      : null;
  }

  public static getLastObservationUnit(d: iTrackable): any {
    for (let i = 0; i < d.observations.length; i++) {
      const o = d.observations[i];
      const att = o.attributes.find(a => a.quantity);
      if (att) {
        return att.quantity.unit_trackable_term_id;
      }
    }

    return null;
  }

  public static emptyTrackable(d) {
    return {
      attachment_count: 0,
      comments: [],
      current_definition_id: null,
      flagged: false,
      has_attachments: false,
      id: null,
      observations: [],
      shortcut_enabled: false,
      trackable_term_id: null,
      ...d,
    };
  }

  public data: iTrackable;
  public definition: iTrackableDefinition;
  private terms: any;

  constructor(d: iTrackable, def: iTrackableDefinition, terms?: any) {
    const comments = d ? d.comments : null;
    this.data = {
      ...d,
      comments: comments || [],
      observations: d.observations || [],
    };
    this.definition = { ...def };
    this.terms = { ...terms };
  }

  updateComment(comment) {
    const commentCollection = new CommentCollection(this.data.comments);
    commentCollection.update(comment);

    this.data.comments = commentCollection.data;
  }

  removeComment(comment) {
    const commentCollection = new CommentCollection(this.data.comments);
    commentCollection.remove(comment);

    this.data.comments = commentCollection.data;
  }

  createNewObservation() {
    return {
      ...emptyObservation,
      date: DateTimeModel.createNew(),
      trackable_definition_id: this.data.current_definition_id || this.definition.id,
    };
  }

  updateObservation(newObservation) {
    let addedObs = false;
    const observations = this.data.observations.map(o => {
      const isNew = o.id === newObservation.id;
      if (isNew) {
        addedObs = true;
      }
      return isNew ? {...newObservation} : o;
    });
    if (!addedObs) {
      observations.push({...newObservation});
    }

    this.data.observations = observations;
  }

  removeObservation(observationId) {
    this.data.observations = this.data.observations.filter(o => o.id !== observationId);
  }

  allowChart() {
    return false;
  }

  isShortcutEnabled() {
    return this.data.shortcut_enabled;
  }

  toggleShortcut() {
    this.data.shortcut_enabled = !this.data.shortcut_enabled;
  }

  toggleFlagged() {
    this.data.flagged = !this.data.flagged;
  }

  get name() {
    const t = this.terms[this.data.trackable_term_id];
    return t ? t.name : '';
  }

  get observationCount() {
    return this.data.observations.length;
  }

  get attributeSummary() {
    return 'MISSING';
  }

  get latestDate() {
    return _.get(this, 'data.observations[0].date', null) || null;
  }

  get latestObservation() {
    return this.data.observations[0];
  }

  get isFlagged() {
    return this.data.flagged;
  }

  get hasAnyFlagged() {
    return !!this.data.observations.find(o => o.flagged);
  }

  get hasComments() {
    return this.data.comments.length > 0;
  }

  get commentText() {
    return this.hasComments ? this.data.comments[0].content : '';
  }

  get isCommentsIncludedOnShareCard() {
    const comments = this.data.comments || [];
    return Boolean(comments.find(c => c.include_on_share_card));
  }
}

class QuantityModel {
  public data: iObservationAttributeQuantityValue;
  public def: iObservationAttributeQuantityDefinition;

  constructor(d: iObservationAttributeQuantityValue, def: iObservationAttributeQuantityDefinition) {
    this.data = d;
    this.def = def;
  }

}

export class ObservationModel {

  public data: iObservation;
  private terms: any;
  private languageCode: string;

  constructor(d: iObservation, languageCode: string, terms?: any) {
    const comment = d ? d.comment : null;
    this.data = {
      ...d,
      comment: comment || Comment.empty(),
    };

    this.languageCode = languageCode;
    this.terms = { ...terms };
  }

  setAmount(attributeDefId, amountData) {
    const atts = [...this.data.attributes];
    const i = atts.findIndex(a => a.attribute_definition_id === attributeDefId);
    atts[i] = amountData;
    this.data.attributes = atts;
  }

  setAttribute(attributeDefId, data) {
    const atts = [...this.data.attributes];
    const i = atts.findIndex(a => a.attribute_definition_id === attributeDefId);
    if (i !== -1) {
      atts[i] = data;
    } else {
      atts.push(data);
    }
    this.data.attributes = atts;
  }

  setCommentValue(content) {
    this.data.comment.content = content;
  }

  setDateTime(d: iObservationDate) {
    this.data.date = d;
  }

  compareDate(d: iObservation) {
    const thisDate = _.get(this, 'data.date.date', '');
    const otherDate = _.get(d, 'date.date', '');
    if (otherDate && thisDate) {
      return DateUtil.compareDatesDescending(thisDate, otherDate);
    } else if (d.date.date) {
      return -1;
    } else if (thisDate) {
      return 1;
    } else {
      return 0;
    }
  }

  getAttributeValue(def: iTrackableAttributeDefinition, languageCode:string, trackable?: iTrackable): iObservationsAttributeValue {
    const value = this.data.attributes.find(a => a.attribute_definition_id === def.id);
    return value || this.createInitialAttributeValue(def, languageCode, trackable);
  }

  createInitialAttributeValue(def: iTrackableAttributeDefinition, languageCode: string, trackable?: iTrackable): iObservationsAttributeValue {
    switch (def.category) {
      case 'quantity':
        let defaultUnitId = null;
        if (trackable) {
          const obs = TrackableModel.getLatestObservation(trackable, languageCode);
          if (obs) {
            const q = obs.getAttributeValue(def, languageCode);
            defaultUnitId = q.quantity.unit_trackable_term_id;
          }
        }

        return {
          attribute_definition_id: def.id,
          category: 'quantity',
          quantity: {
            value: '',
            unit_trackable_term_id: defaultUnitId,
          },
        };
      case 'list':
        return {
          attribute_definition_id: def.id,
          category: 'list',
          list: {
            selected_option_ids: [],
          },
        };
      default:
        return null;
    }
  }

  getDataForApiUpdate() {
    const attributes = this.data.attributes.filter((a => {
      switch (a.category) {
        case 'quantity':
          return Boolean(a.quantity.value) && Boolean(a.quantity.unit_trackable_term_id);
        case 'list':
          return _.get(a, 'list.selected_option_ids', []).length > 0;
        case 'scale':
          return Boolean(a.scale.selected_option_value);
        default:
          return false;
      }
    }));

    return {
      ...this.data,
      attributes,
    };
  }

  toggleIsFlagged() {
    this.data.flagged = !this.data.flagged;
  }

  get name() {
    const t = this.terms[this.data.id];
    return t ? t.name : '';
  }

  get attributeSummary() {
    return this.data.summary ? this.data.summary[this.languageCode] : '';
    // const atts = ObservationAttributeModel.instantiateAttributes(this.data, null, this.terms, this.languageCode);
    // return atts.map(a => a.summarizedValue).filter(a => a).join(', ');
  }

  get hasAttachments() {
    return this.data.has_attachments;
  }

  get latestDate() {
    return this.data.date;
  }

  get isFlagged() {
    return this.data.flagged;
  }

  get hasComments() {
    return Boolean(this.data.comment.content);
  }

  get commentText() {
    return _.get(this, 'data.comment.content', '');
  }

  get isValid() {
    return ObservationAttributeModel.instantiateAttributes(this.data, null, this.terms, this.languageCode)
      .reduce((acc, att) => {
        return acc ? att.isValid : acc;
      }, true);
  }

  get validationErrorMessages() {
    return ObservationAttributeModel.instantiateAttributes(this.data, null, this.terms, this.languageCode)
      .reduce((acc, att) => {
        return att.isValid ? acc : [...acc, att.isInvalidMessage];
      }, []);
  }

  get viewLevelValidationErrorMessages() {
    return ObservationAttributeModel.instantiateAttributes(this.data, null, this.terms, this.languageCode)
      .reduce((acc, att) => {
        return att.isViewLevelValid ? acc : [...acc, att.isViewlLevelInvalidMessage];
      }, []);
  }
}

export class TrackableDefinitionModel {

  public data: iTrackableDefinition;

  constructor(d: iTrackableDefinition) {
    this.data = { ...d };
  }

  attributeCount() {
    return this.data.attributes.length;
  }

  primaryAttributes() {
    const primaryId = this.data.primary_attribute_id;
    return primaryId
      ? this.data.attributes.filter(a => a.id === primaryId)
      : [];
  }

  secondaryAttributes() {
    const primaryId = this.data.primary_attribute_id;
    return primaryId
      ? this.data.attributes.filter(a => a.id !== primaryId)
      : this.data.attributes;
  }
}


class AttributeScale {

  public value: iObservationAttributeScaleValue;
  public def: iObservationAttributeScaleDefinition;
  private terms: any;
  private languageCode: string;

  constructor(value, def, terms, languageCode) {
    this.languageCode = languageCode;
    this.value = { ...value };
    this.def = { ...def };
    this.terms = { ...terms };
  }

  get addLabelPhrase() {
    return {
      id: 'observation.scale.add.label',
    };
  }

  get summaryText() {
    if (!this.value) { return ''; }
    if (this.value.summary && this.value.summary[this.languageCode]) {
      return this.value.summary[this.languageCode];
    }
  }

  get summarizedValue() {
    const optionValue = _.get(this, 'value.scale.selected_option_value', null);

    if (optionValue && this.def.scale) {
      const selectedOption = this.def.scale.options.find(o => o.value === optionValue);
      const termId = selectedOption.slider_label_trackable_term_id || selectedOption.track_label_trackable_term_id;

      if (termId) {
        return this.terms[termId].name;
      } else {
        return selectedOption.value;
      }
    }
    return '';
  }

  get name() {
    return _.get(this, `terms[${this.def.name_trackable_term_id}].name`, '');
  }

  get isValid() {
    return true;
  }

  get isInvalidMessage() {
    return '';
  }

  get isViewLevelValid() {
    return true;
  }

  get isViewlLevelInvalidMessage() {
    return '';
  }

  get hasValue() {
    return Boolean(_.get(this, 'value.scale.selected_option_value', null));
  }

  get hasUnit() {
    return false;
  }

  hasDifferentUnit() {
    return false;
  }

}

class AttributeList {

  public value: iObservationAttributeListValue;
  public def: iObservationAttributeListDefinition;
  private terms: any;
  private languageCode: string;

  constructor(value, def, terms, languageCode) {
    this.value = {
      list: {
        selected_option_ids: [],
      },
      ...value,
    };
    this.value.list.selected_option_ids = this.value.list.selected_option_ids || [];
    this.def = { ...def };
    this.terms = { ...terms };
    this.languageCode = languageCode;
  }

  get name() {
    return _.get(this, `terms[${this.def.name_trackable_term_id}].name`, '');
  }

  get addLabelPhrase() {
    return {
      id: 'observation.list.add.label',
    };
  }

  get summaryText() {
    if (!this.value) { return ''; }
    if (this.value.summary && this.value.summary[this.languageCode]) {
      return this.value.summary[this.languageCode];
    }
  }

  get summarizedValue() {
    return this.value.list.selected_option_ids.map(id => {
      const t = this.terms[id];
      return t ? t.name : '';
    }).join(', ');
  }

  get isValid() {
    return true;
  }

  get isInvalidMessage() {
    return '';
  }

  get isViewLevelValid() {
    return true;
  }

  get isViewlLevelInvalidMessage() {
    return '';
  }

  get hasValue() {
    return this.value.list.selected_option_ids.length > 0;
  }

  get hasUnit() {
    return false;
  }

  hasDifferentUnit() {
    return false;
  }

}

export class AttributeQuantity {

  public value: iObservationAttributeQuantityValue;
  public def: iObservationAttributeQuantityDefinition;
  private terms: any;
  private languageCode: string;

  constructor(value, def, terms, languageCode) {
    const qty = value && value.quantity ? value.quantity : {};
    this.value = {
      ...value,
      quantity: {
        value: '',
        unit_trackable_term_id: null,
        ...qty,
      },
    };
    this.def = { ...def };
    this.terms = { ...terms };
    this.languageCode = languageCode;
  }

  get addLabelPhrase() {
    return {
      id: 'heValue.unit.addUnit.label',
    };
  }

  get summaryText() {
    if (!this.value) { return ''; }
    if (this.value.summary && this.value.summary[this.languageCode]) {
      return this.value.summary[this.languageCode];
    }
  }

  get summarizedValue() {
    const qty = this.value.quantity;
    const v = qty.value;
    const unit = this.terms[qty.unit_trackable_term_id] || {};
    const unitName = unit.name || '';
    return (v && unitName) ? [v, unitName].join(' ') : '';
  }

  get name() {
    return _.get(this, `terms[${this.def.name_trackable_term_id}].name`, '');
  }

  get isValid() {
    const qty = this.value.quantity;
    const hasValue = Boolean(qty.value);
    const hasValidValue = hasValue && isValidTrackableQuantity(qty.value);
    const hasUnit = Boolean(qty.unit_trackable_term_id);
    return !hasValue || (hasValidValue && hasUnit);
  }

  get isInvalidMessage() {
    return 'error.value-validator.missing-unit.message';
  }

  get isViewLevelValid() {
    return this.isValid;
  }

  get isViewlLevelInvalidMessage() {
    const qty = this.value.quantity;
    const hasValue = Boolean(qty.value);
    const hasUnit = Boolean(qty.unit_trackable_term_id);

    if (!hasValue) {
      return '';
    } else if (!hasUnit) {
      return 'error.value-validator.missing-unit.message';
    }

    return '';
  }

  get hasValue() {
    const qty = this.value.quantity;
    return Boolean(qty.value);
  }

  get hasUnit() {
    const qty = this.value.quantity;
    return Boolean(qty.unit_trackable_term_id);
  }

  hasDifferentUnit(otherAttValue) {
    const qty = this.value.quantity.unit_trackable_term_id;
    const otherQty = otherAttValue.quantity.unit_trackable_term_id;
    return qty !== otherQty;
  }
}

interface iAttribute {
  addLabelPhrase: {
    id: string;
  };
  summarizedValue: string;
  name: string;
}
class Attribute {
  public value: any;
  public def: any;
  private terms: any;
  private languageCode: string;

  constructor(value, def, terms, languageCode) {
    this.value = { ...value };
    this.def = { ...def };
    this.terms = { ...terms };
    this.languageCode = languageCode;
  }

  get addLabelPhrase() {
    return { id: '' };
  }

  get summarizedValue() {
    return '';
  }

  get name() {
    return '';
  }

  get isValid() {
    return true;
  }

  get isInvalidMessage() {
    return '';
  }

  get isViewLevelValid() {
    return true;
  }

  get isViewlLevelInvalidMessage() {
    return '';
  }

  get hasValue() {
    return false;
  }

  get hasUnit() {
    return false;
  }

  hasDifferentUnit() {
    return false;
  }
}

export class ObservationAttributeModel {

  public static create(observation: iObservation, attDefinition, terms, languageCode) {
    if (!observation) {
      return new Attribute(null, attDefinition, terms, languageCode);
    }
    const attValue = observation.attributes.find(a => a.attribute_definition_id === attDefinition.id);
    switch (attDefinition.category) {
      case 'scale':
        return new AttributeScale(attValue, attDefinition, terms, languageCode);
      case 'list':
        return new AttributeList(attValue, attDefinition, terms, languageCode);
      case 'quantity':
        return new AttributeQuantity(attValue, attDefinition, terms, languageCode);
      default:
        return new Attribute(attValue, attDefinition, terms, languageCode);
    }
  }

  public static instantiateAttributes(observation, definitions, terms, languageCode) {
    if (!observation) { return []; }
    const atts = observation.attributes || [];
    return atts.map(a => {
      switch (a.category) {
        case 'scale':
          return new AttributeScale(a, null, terms, languageCode);
        case 'list':
          return new AttributeList(a, null, terms, languageCode);
        case 'quantity':
          return new AttributeQuantity(a, null, terms, languageCode);
        default:
          return new Attribute(a, null, terms, languageCode);
      }
    });
  }

  public observation: iObservation;
  public def: iObservationAttributeDefinitionGeneric;
  private terms: any;

  constructor(observation, attDefinition, terms) {
    this.observation = { ...observation };
    this.def = { ...attDefinition };
    this.terms = { ...terms };
  }

  get addLabelPhrase() {
    return '';
  }

  get summarizedValue() {
    return '';
  }
}


export class TrackableShortcutModel {

  public data: iTrackableShortcut;
  private terms: any;

  constructor(d, terms) {
    this.data = { ...d };
    this.terms = { ...terms };
  }

  get name() {
    const t = this.terms[this.data.trackable_term_id];
    return t ? t.name : '';
  }

  get trackableId() {
    return this.data.trackable_id;
  }

  get latestDate() {
    return this.data.latest_observation ? this.data.latest_observation.date : null;
  }

}
