import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SurveyDefinitionModel } from '../../../../models/SurveyDefinitionModel';
import DateUtil from '../../../../utilities/DateUtil';
import { iSurvey } from '../../../../apiServices/SurveyService';

interface Props {
  definition: SurveyDefinitionModel;
  showDelivered: boolean;
  showEndDate: boolean;
  survey: iSurvey;
}
const GroupSurveySummaryDates: React.FC<Props> = (props) => {
  const { definition, showDelivered, showEndDate, survey } = props;

  const endDate = DateUtil.formattedMediumDate(definition.fixed_date_end);
  const deliveredDate = DateUtil.formattedMediumDate(survey.created_at);

  return (
    <div className="font-footnote font-color-gsMedDark">

      {
        (showEndDate && definition.fixed_date_end) && (
          <div>
            <FormattedMessage id="ui-data.surveys.endDate.text" values={{ string1: endDate }} />
          </div>
        )
      }
      {
        showDelivered && (
          <div style={{ marginTop: 16 }}>
            <FormattedMessage id="ui-data.surveys.dateDelivered.text" values={{ string1: deliveredDate }} />
          </div>
        )
      }
    </div>
  );
};

export default GroupSurveySummaryDates;
