import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { CardRowUI } from '../../../../bphComponents/bundle';

import List from '../../../components/List';
import HealthEntryCategoryNames from '../../../../constants/HealthEntryCategoryNames';
import { HealthEntryShortSummary } from '../../../components/HealthEntrySummary';

import HealthEntryModel from '../../../../models/HealthEntry';
import { generalHealthEntry } from '../../../../models/HealthEntryCompose';
import COLORS from '../../../styles/Colors';

const heCategories = {
  [HealthEntryCategoryNames.condition]: {
    name: 'list.healthEntry.conditions.name',
  },
  [HealthEntryCategoryNames.medication]: {
    name: 'list.healthEntry.medications.name',

  },
  [HealthEntryCategoryNames.otherTreatment]: {
    name: 'list.healthEntry.treatments.name',

  },
  [HealthEntryCategoryNames.procedure]: {
    name: 'list.healthEntry.procedures.name',

  },
  [HealthEntryCategoryNames.allergy]: {
    name: 'list.healthEntry.allergies.name',

  },
  [HealthEntryCategoryNames.vaccination]: {
    name: 'list.healthEntry.vaccinations.name',

  },
  [HealthEntryCategoryNames.measurement]: {
    name: 'list.healthEntry.measurements.name',

  },
  [HealthEntryCategoryNames.lab]: {
    name: 'list.healthEntry.lab.name',
  },
  [HealthEntryCategoryNames.geneticResult]: {
    name: 'list.healthEntry.genes.name',
  },
};

export const NewPendingHealthEntryGroup = (props) => {
  const { healthEntries, category, languageCode, onClick, isSelected, exitAnimationTime } = props;
  const { name } = heCategories[category];

  const isAllSelected = healthEntries.reduce((p, v) => { return isSelected(v.id) ? p + 1 : p; }, 0) ;

  return (
    <List hasBorder hasRoundedWrapper>
      <div>
        <li className="font-color-profile-accent profile-background-color-5 profile-border-bottom-color-10">
          <CardRowUI.SelectableRow
            hasBottomBorder={true}
            onClick={() => { onClick(healthEntries.map(he => he.id)); }}
            isSelected={isAllSelected === healthEntries.length}
            selectedClassName="fill-color-profile-highlight"
          >
            <FormattedMessage id={name} values={{ digit1: 2 }} />
          </CardRowUI.SelectableRow>
        </li>
      </div>

      <ul>
        <TransitionGroup>
          {
            healthEntries.map((he, i) => (
              <CSSTransition
                key={he.id}
                classNames="example"
                timeout={exitAnimationTime}
              >
              <li className="" style={{ borderBottom: (i < (healthEntries.length - 1)) ? `1px solid ${COLORS.gsLight}` : null }}>
                <CardRowUI.SelectableRow
                  hasBottomBorder={true}
                  onClick={() => { onClick(he.id); }}
                  isSelected={isSelected(he.id)}
                  selectedClassName="fill-color-profile-highlight"
                >
                  <HealthEntryShortSummary
                    color="red"
                    healthEntry={generalHealthEntry(he)}
                    languageCode={languageCode}
                    showCategory={false}
                    areIconsAttachedToRight={false}
                  />
                </CardRowUI.SelectableRow>
              </li>
              </CSSTransition>
            ))
          }
        </TransitionGroup>
      </ul>
    </List>
  );
};

export const heCategoryOrder = [
  HealthEntryCategoryNames.condition,
  HealthEntryCategoryNames.medication,
  HealthEntryCategoryNames.otherTreatment,
  HealthEntryCategoryNames.procedure,
  HealthEntryCategoryNames.allergy,
  HealthEntryCategoryNames.vaccination,
  HealthEntryCategoryNames.measurement,
  HealthEntryCategoryNames.lab,
  HealthEntryCategoryNames.geneticResult,
];
export const NewPendingHealthEntries = (props) => {
  const { healthEntries, languageCode, onClick, isSelected, exitAnimationTime } = props;
  const groupedHealthEntries = HealthEntryModel.groupByCategory(healthEntries);
  const remainingHeCategory = heCategoryOrder.filter(c => (groupedHealthEntries[c] && groupedHealthEntries[c].length > 0));
  return (
    <div>
      <TransitionGroup>
        {
          remainingHeCategory.map((c) => (
            <CSSTransition
              key={c}
              classNames="example"
              timeout={{ enter: 500, exit: exitAnimationTime }}
            >
              <NewPendingHealthEntryGroup
                languageCode={languageCode}
                category={c}
                healthEntries={groupedHealthEntries[c] || []}
                onClick={onClick}
                isSelected={isSelected}
                exitAnimationTime={exitAnimationTime}
              />
            </CSSTransition>
          ))
        }
      </TransitionGroup>
    </div>
  );
};