import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import GroupTitle from './GroupTitle';
import ContentPanel from '../ContentPanel';
import List, { BasicListItem } from '../../../../components/List';
import { Icons } from '../../../../../bphComponents/bundle';
import ContactModel, { ContactModelType } from '../../../../../models/ContactModel';
import ContactDetails from '../../../../components/ContactDetails';
import { ProviderContactNameSpecialtyText, ContactNameHealthcareProxyText, ProviderContactCurrentText, ContactRelationshipText } from '../../../../components/ContactSummaryText';

const ContactRow: FC = (props) => (
  <div className="font-subhead" style={{ marginBottom: 8 }}>
    {props.children}
  </div>
);

export const ShareCardContactsGroup = ({ contacts, isProvider }) => {
  if (!contacts || !contacts.length) return null;
  const sortedContacts = contacts.sort((a, b) => a.name.localeCompare(b.name));
  const hasHealthcareProxy = !isProvider && !!contacts.find(c => ContactModel.hasHealthcareProxy(c));

  return (
    <div>
      <GroupTitle
        icon={<Icons.IconProvidersContacts.Medium className="fill-color-gsMedDark" />}
        text={<FormattedMessage id={isProvider ? 'shareCard.providers.heading' : 'shareCard.contacts.heading'} />}
      />
      <ContentPanel hasPadding={false} style={{ marginTop: 0 }}>
        <List hasBorder paddingSize="large">
          {
            sortedContacts.map((contact: ContactModelType, i) => (
              <BasicListItem key={i} className="util-inset-listitem-frame-padding">

                <div className="font-headline" style={{ marginBottom: 8 }}>
                  {ContactModel.isProvider(contact) ? <ProviderContactNameSpecialtyText data={contact} /> : <ContactNameHealthcareProxyText data={contact} />}
                </div>

                <ContactRow>
                  {
                    isProvider ? (
                      <ProviderContactCurrentText data={contact} />
                    ) : (
                      <ContactRelationshipText data={contact} />
                    )
                  }
                </ContactRow>

                <ContactDetails details={contact.details} Component={ContactRow} />

                {
                  contact.comment && (
                    <ContactRow>
                      {contact.comment}
                    </ContactRow>
                  )
                }
              </BasicListItem>
            ))
          }
        </List>
        {
          hasHealthcareProxy && (
            <div className="font-footnote util-inset-listitem-frame-padding" style={{ paddingBottom: 24, fontStyle: 'italic' }}>
              <FormattedMessage id="shareCard.healthProxy.disclaimer.message" />
            </div>
          )
        }
      </ContentPanel>
    </div>
  );
};
