import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import BackpackMarkdown from '@backpackhealth/markdown';

import { selectors as colorThemeSelectors } from '../redux/ducks/lists/colorTheme';
import { selectors as groupSelectors } from '../redux/ducks/group';

import COLORS from '../styles/Colors';
import AppUtils from '../../utilities/AppUtils';

interface Props {
  intl?: any;
  phraseId?: string;
  phraseValues?: any;
  text?: string;
  color: any;
  id: string;
  underlineLinks?: boolean;
}

export const Markdown = injectIntl((props: Props) => {
  const { phraseId, text, color, id, intl, phraseValues, underlineLinks } = props;

  if (phraseId ? text : !text) { // xor
    return null;
  }

  const source = text || intl.formatMessage({ id: phraseId }, phraseValues);

  return (
    <BackpackMarkdown
      text={source}
      linkColor={color}
      id={id}
      underlineLinks={underlineLinks}
    />
  );
});

export const GroupThemeMarkdown = connect((state) => {
  const group = groupSelectors.getActiveGroup(state);
  const color = AppUtils.cssColor(group.theme.accent_rgba);
  return {
    color,
    id: 'group',
  };
})(Markdown);

export const BackpackThemeMarkdown = (props) => (
  <Markdown {...props} id="backpack" color={COLORS.bcBackpackRedAccent} />
);

export const ProfileThemeMarkdown = connect((state) => {
  const profileTheme = colorThemeSelectors.getCurrentColorTheme(state);
  const color = AppUtils.cssColor(profileTheme.accent_color);
  return {
    color,
    id: 'profile',
  };
})(Markdown);

export const NoThemeMarkdown = (props) => (
  <Markdown {...props} id="none" color="inherit" />
);

export const GrayTextMarkdown = (props) => (
  <Markdown {...props} id="none" color={COLORS.gsDark} underlineLinks={true} />
);
