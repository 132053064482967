
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TextArea from '../../../components/TextArea';
import COLORS from '../../../styles/Colors';

import { Card, CardRowUI, Icons, RowUI } from '../../../../bphComponents/bundle';
import { ClampedText } from '../../../components/ClampedText';

export const Comment = (props) => {
  const { value, onChange } = props;

  const [isExpanded, setIsExpanded] = React.useState(false);

  return isExpanded
    ? (
      <>
        <Card.CardContentFirst onClick={() => { setIsExpanded(false); }}>
          <RowUI.InsetIcon>
            <Icons.IconComment.Medium color={COLORS.gsDark} />
            <span style={{ marginTop: 5 }}>
              <FormattedMessage id="fileDetails.writeComment.button.label" />
            </span>
          </RowUI.InsetIcon>
        </Card.CardContentFirst>
        <Card.CardContent>
          <TextArea
            value={value}
            className="font-body font-color-gsDark"
            default
            height="120px"
            defaultFocus={true}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            style={{
              borderColor: COLORS.dtDarkMiddle,
              borderWidth: 0.5,
              borderStyle: 'solid',
              borderRadius: 4,
              padding: 8,
            }}
          />
        </Card.CardContent>
      </>
    ) : (
      <CardRowUI.InsetIcon hasBottomBorder={false} onClick={() => { setIsExpanded(true); }}>
        <Icons.IconComment.Medium color={COLORS.gsDark} />

        <div className="font-body font-color-gsDarkest">
        {
          Boolean(value)
          ? (
            <ClampedText
              lines={2}
              defaultExpanded={false}
              moreClassName="font-color-gsDarkest"
              moreMaskWidth={20}
              customMoreText="..."
            >
              {value}
            </ClampedText>
          )
          : <FormattedMessage id="fileDetails.writeComment.button.label" />
        }
        </div>
      </CardRowUI.InsetIcon>
    );
};
