import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { DateTimeModel, iDateTime } from '../../models/DateTimeModel';

interface LocalizedTimeProps {
  dateTime: iDateTime;
  intl?: any;
}
export const LocalizedTime: React.FC<LocalizedTimeProps> = injectIntl((props) => {
  const dateTime = new DateTimeModel(props.dateTime, props.intl);

  return dateTime.hasTime()
    ? (
      <span>
        {dateTime.formatTime()}
      </span>
    ) : (
      <FormattedMessage id="ui-data.data.not-specified.text" />
    );
});
