import React from 'react';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  color?: string;
}

const DEFAULT_COLOR = 'gsMedDark';

const getClassName = (props: Props) => {
  const { color = DEFAULT_COLOR } = props;
  return colorClassNames.fill(color);
};

const Small = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px">
    <g id="Layer_48" data-name="Layer 48" className={getClassName(props)}>
      <path d="M12.81,5.22h-.19V5A4.54,4.54,0,1,0,3.55,5v.22H3.19A2.07,2.07,0,0,0,1.12,7.28v6.2a2.07,2.07,0,0,0,2.07,2.06h9.62a2.07,2.07,0,0,0,2.07-2.06V7.28A2.07,2.07,0,0,0,12.81,5.22ZM4.8,5a3.29,3.29,0,1,1,6.57,0v.22H4.8Zm8.82,8.48a.81.81,0,0,1-.81.81H3.19a.81.81,0,0,1-.81-.81V7.28a.81.81,0,0,1,.81-.81h9.62a.81.81,0,0,1,.81.81Z"/>
      <path d="M10,8.13a1.8,1.8,0,0,0-1.4-.19,1.83,1.83,0,0,0-.55.25,1.72,1.72,0,0,0-.55-.25,1.78,1.78,0,0,0-1.39.19,1.85,1.85,0,0,0-.86,1.19,2,2,0,0,0,.46,1.83,10.06,10.06,0,0,0,1.42,1.31l.32.23a1,1,0,0,0,.6.19,1,1,0,0,0,.61-.19L9,12.45a10,10,0,0,0,1.42-1.29,2.05,2.05,0,0,0,.46-1.84A1.88,1.88,0,0,0,10,8.13Zm-.55,2.21a8.7,8.7,0,0,1-1.24,1.13l-.16.12-.15-.12a8.8,8.8,0,0,1-1.25-1.14.74.74,0,0,1-.18-.72.62.62,0,0,1,.28-.41.51.51,0,0,1,.27-.08l.15,0a.62.62,0,0,1,.36.29l.52.77.52-.77A.69.69,0,0,1,9,9.14a.53.53,0,0,1,.42.06.6.6,0,0,1,.29.41A.77.77,0,0,1,9.48,10.34Z"/>
    </g>
  </svg>
);

const Medium = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <g id="Layer_48" data-name="Layer 48" className={getClassName(props)}>
      <path d="M18.86,8.17h-.41V7.71a6.33,6.33,0,0,0-12.66,0v.46H5.14A2.81,2.81,0,0,0,2.33,11v8.83a2.81,2.81,0,0,0,2.81,2.81H18.86a2.81,2.81,0,0,0,2.81-2.81V11A2.81,2.81,0,0,0,18.86,8.17ZM7.29,7.71a4.83,4.83,0,0,1,9.66,0v.46H7.29Zm12.88,12.1a1.31,1.31,0,0,1-1.31,1.31H5.14a1.31,1.31,0,0,1-1.31-1.31V11A1.31,1.31,0,0,1,5.14,9.67H18.86A1.31,1.31,0,0,1,20.17,11Z"/>
      <path d="M14.82,12.3A2.36,2.36,0,0,0,13,12.05a2.46,2.46,0,0,0-.83.4,2.46,2.46,0,0,0-.83-.4,2.36,2.36,0,0,0-1.87.25,2.52,2.52,0,0,0-1.16,1.62,2.71,2.71,0,0,0,.62,2.49,15.51,15.51,0,0,0,2,1.84l.45.33a1.36,1.36,0,0,0,1.57,0c.16-.11.33-.24.45-.34a14.22,14.22,0,0,0,2-1.83A2.74,2.74,0,0,0,16,13.92,2.52,2.52,0,0,0,14.82,12.3Zm-.6,3.13a13.81,13.81,0,0,1-1.79,1.64l-.31.23-.31-.23A13.89,13.89,0,0,1,10,15.43a1.21,1.21,0,0,1-.29-1.17,1,1,0,0,1,.47-.67.84.84,0,0,1,.46-.13.73.73,0,0,1,.25,0,1,1,0,0,1,.59.46l.63.93.62-.93a1,1,0,0,1,.6-.46.88.88,0,0,1,.71.09,1.07,1.07,0,0,1,.47.67A1.24,1.24,0,0,1,14.22,15.43Z"/>
    </g>
  </svg>
);

const Large = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36px" height="36px">
    <g id="Layer_48" data-name="Layer 48" className={getClassName(props)}>
      <path d="M28.71,12.2H27.9v-.89a9.72,9.72,0,0,0-19.43,0v.89H7.29a4.21,4.21,0,0,0-4.21,4.2V30.19A4.22,4.22,0,0,0,7.29,34.4H28.71a4.22,4.22,0,0,0,4.21-4.21V16.4A4.21,4.21,0,0,0,28.71,12.2Zm-18.24-.89a7.72,7.72,0,0,1,15.43,0v.89H10.47ZM30.92,30.19a2.21,2.21,0,0,1-2.21,2.21H7.29a2.21,2.21,0,0,1-2.21-2.21V16.4a2.21,2.21,0,0,1,2.21-2.2H28.71a2.21,2.21,0,0,1,2.21,2.2Z"/>
      <path d="M22.31,18.62a3.64,3.64,0,0,0-4.13.3,3.62,3.62,0,0,0-4.12-.3A3.71,3.71,0,0,0,12.33,21a4.09,4.09,0,0,0,.93,3.74,22.77,22.77,0,0,0,3.1,2.84l.7.52a1.94,1.94,0,0,0,2.25,0l.7-.52a22.77,22.77,0,0,0,3.1-2.84A4.07,4.07,0,0,0,24,21,3.74,3.74,0,0,0,22.31,18.62Zm-.71,4.84A20.62,20.62,0,0,1,18.77,26c-.17.13-.38.29-.59.43-.2-.14-.42-.3-.58-.43a20,20,0,0,1-2.83-2.58,2.1,2.1,0,0,1-.5-2,1.86,1.86,0,0,1,.81-1.16,1.62,1.62,0,0,1,1.25-.16,1.73,1.73,0,0,1,1,.79l.84,1.24L19,21a1.73,1.73,0,0,1,1-.79,1.61,1.61,0,0,1,1.25.16,1.86,1.86,0,0,1,.81,1.16A2.1,2.1,0,0,1,21.6,23.46Z"/>
    </g>
  </svg>
);

const XLarge = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" width="56px" height="56px">
    <g id="Layer_48" data-name="Layer 48" className={getClassName(props)}>
      <path d="M44.7,19.136H43.245V17.573a14.958,14.958,0,0,0-29.916,0v1.563H11.3a6.381,6.381,0,0,0-6.375,6.375v21.5A6.382,6.382,0,0,0,11.3,53.385H44.7a6.382,6.382,0,0,0,6.375-6.375v-21.5A6.381,6.381,0,0,0,44.7,19.136Zm-28.62-1.563a12.208,12.208,0,0,1,24.416,0v1.563H16.079ZM48.324,47.01A3.629,3.629,0,0,1,44.7,50.635H11.3A3.629,3.629,0,0,1,7.677,47.01v-21.5A3.628,3.628,0,0,1,11.3,21.886H44.7a3.628,3.628,0,0,1,3.625,3.625Z"/>
      <path d="M34.627,29.12a5.458,5.458,0,0,0-6.34.558,5.458,5.458,0,0,0-6.339-.558,5.692,5.692,0,0,0-2.618,3.654,6.2,6.2,0,0,0,1.417,5.658,34.933,34.933,0,0,0,4.809,4.405c.3.24.7.535,1.083.8a2.844,2.844,0,0,0,3.293,0c.384-.27.781-.565,1.085-.8a34.973,34.973,0,0,0,4.811-4.405,6.207,6.207,0,0,0,1.417-5.658A5.7,5.7,0,0,0,34.627,29.12Zm-.876,7.51a31.682,31.682,0,0,1-4.439,4.048c-.271.215-.625.477-.966.718a.1.1,0,0,1-.059.02l-.059-.02c-.34-.241-.7-.5-.966-.718a31.651,31.651,0,0,1-4.438-4.048A3.411,3.411,0,0,1,22.01,33.4a2.967,2.967,0,0,1,1.349-1.915,2.635,2.635,0,0,1,1.356-.375,2.705,2.705,0,0,1,.721.1,2.923,2.923,0,0,1,1.708,1.309l1.143,1.709,1.143-1.709A2.924,2.924,0,0,1,31.137,31.2a2.666,2.666,0,0,1,2.079.277,2.97,2.97,0,0,1,1.349,1.914A3.42,3.42,0,0,1,33.751,36.63Z"/>
    </g>
  </svg>
);

const IconPrivacy = {
  Small,
  Medium,
  Large,
  XLarge,
};

export default IconPrivacy;
