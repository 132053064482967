import { useQuery, queryCache } from 'react-query';
import _ from 'lodash';

import { useDispatch } from 'react-redux';
import { receiveAllAttachmentsAction } from '../appAuthorized/redux/AttachmentsActions';

import SortingUtil from '../../utilities/SortingUtil';
import FileModel from '../../models/FileModel';
import AttachmentsService from '../../apiServices/AttachmentsService';
import healthEntryUtility from '../../models/HealthEntryUtility';
import AttachmentModel, { attachmentTypes } from '../../models/AttachmentModel';

import { useHealthEntriesForProfile, useHealthEntriesForActiveProfile, useHealthEntryValuesForActiveProfile } from './useHealthEntry';
import { useContactsForProfile, useContactsForActiveProfile } from './useContacts';
import { useFilesForActiveProfile } from './useFile';
import { useUIState } from './useUIState';
import { useActiveUser } from './useUser';
import HealthEntryModel from '../../models/HealthEntry';
import { useTrackableListForActiveProfile } from './useTrackable';


const STALE_TIME = 10000;

const DEFAULT_ATTACHMENT_STATE = {
  list: [],
  isLoaded: false,
};

function fetchAllAttachmentsForProfile(dispatch) {
  return function fetchAttachmentsForProfile_API(queryKey, profileId) {
    return new Promise((res, rej) => {
      AttachmentsService.getAll(profileId, (err, data) => {
        if (err) { return rej(err); }

        dispatch(receiveAllAttachmentsAction(profileId, data));
        res({
          list: data,
          isLoaded: true,
        });
      });
    });
  };
}

function fetchAllAttachmentsForParent(queryKey, profileId, parentType, parentId) {
  return new Promise((res, rej) => {
    AttachmentsService.getAllForParent(profileId, parentType, parentId)
      .then((data) => {
        res({
          list: data,
          isLoaded: true,
        });
      });
  });
}

function formatFiles(files = []) {
  return SortingUtil.sortFiles(files.map(f => new FileModel(f)));
}
export function useAttachmentsForActiveProfile() {
  const dispatch = useDispatch();

  const [{ activeProfileId }] = useUIState();

  const attachmentQuery: any = useQuery(['attachments', activeProfileId], fetchAllAttachmentsForProfile(dispatch), { staleTime: STALE_TIME });
  const data = attachmentQuery.data || DEFAULT_ATTACHMENT_STATE;
  const attachmentList = data.list;
  const isLoaded = data.isLoaded;
  const [heData] = useHealthEntriesForProfile(activeProfileId);
  const [contactData] = useContactsForProfile(activeProfileId);

  return [
    {
      attachmentList,
      isLoaded: heData.isLoaded && contactData.isLoaded && isLoaded,
    },
  ];
}
export function useAttachmentsForFile(fileId) {
  const dispatch = useDispatch();

  const [{ activeProfileId }] = useUIState();
  const [{ preferredLanguageCode }] = useActiveUser();

  const [{ healthEntryLookup }] = useHealthEntriesForActiveProfile();
  const [{ heValueLookup, heValueToHeLookup }] = useHealthEntryValuesForActiveProfile();
  const [{ contactLookup }] = useContactsForActiveProfile();
  const [{ trackableLookup, observationLookup, trackableTermLookup, observationToTrackableId }] = useTrackableListForActiveProfile();

  const attachmentQuery: any = useQuery(['attachments', activeProfileId], fetchAllAttachmentsForProfile(dispatch), { staleTime: STALE_TIME });
  const data = attachmentQuery.data || DEFAULT_ATTACHMENT_STATE;
  const isLoaded = data.isLoaded;
  const attachments = data.list
    .filter(a => a.attachment.type === 'file' && a.attachment.id === fileId)
    .sort((a, b) => {
      const heAId = a.parent.type === 'health_entry' ? a.parent.id : heValueToHeLookup[a.parent.id];
      const heBId = b.parent.type === 'health_entry' ? b.parent.id : heValueToHeLookup[b.parent.id];
      const heA =  healthEntryLookup[heAId];
      const heB =  healthEntryLookup[heBId];

      return healthEntryUtility.standardSort(heA, heB, preferredLanguageCode);
    });
  const attachedItems = attachments
    .reduce((acc, v) => {
      switch (v.parent.type) {
        case attachmentTypes.healthEntry:
          const he = healthEntryLookup[v.parent.id];
          return he ? {
            ...acc,
            healthEntries: acc.healthEntries.concat(he),
          } : acc;
        case attachmentTypes.healthEntryValue:
          const hev = heValueLookup[v.parent.id];
          return hev ? {
            ...acc,
            healthEntryValues: acc.healthEntryValues.concat(hev),
          } : acc;
        case attachmentTypes.contact:
          const c = contactLookup[v.parent.id];
          return c ? {
            ...acc,
            contacts: acc.contacts.concat(c),
          } : acc;
        case attachmentTypes.trackable:
          const t = trackableLookup[v.parent.id];
          return t ? {
            ...acc,
            trackables: acc.trackables.concat(t),
          } : acc;
        case attachmentTypes.trackableObservation:
          const o = observationLookup[v.parent.id];
          return o ? {
            ...acc,
            trackableObservations: acc.trackableObservations.concat(o),
          } : acc;
        default:
          return acc;
      }
    }, { files: [], contacts: [], healthEntries: [], healthEntryValues: [], trackables: [], trackableObservations: [] });

  const [heData] = useHealthEntriesForProfile(activeProfileId);
  const [contactData] = useContactsForProfile(activeProfileId);


  return [
    {
      attachments: attachments.sort((a, b) => a.parent.type.localeCompare(b.parent.type)),
      healthEntries: attachedItems.healthEntries,
      healthEntryValues: attachedItems.healthEntryValues,
      contacts: attachedItems.contacts,
      hasAttachments: attachments.length > 0,
      isLoaded: heData.isLoaded && contactData.isLoaded && isLoaded,
    },
    {
      getParentItem: (attachment) => {
        if (attachment.parent.type === 'health_entry_value') {
          return heValueLookup[attachment.parent.id];
        } else {
          return healthEntryLookup[attachment.parent.id];
        }
      },
      getAttachedHealthEntry: (attachment) => {
        if (attachment.parent.type === 'health_entry_value') {
          const heId = heValueToHeLookup[attachment.parent.id];
          return healthEntryLookup[heId];
        } else {
          return healthEntryLookup[attachment.parent.id];
        }
      },
      getSortedAttachments: () => {
        return attachments.map((a) => {
          const parentId = a.parent.id;
          let he;
          let trackable;
          switch (a.parent.type) {
            case attachmentTypes.healthEntryValue:
              const heId = heValueToHeLookup[parentId];
              he = healthEntryLookup[heId];

              return {
                attachment: a,
                category: he.category,
                name: HealthEntryModel.getName(he, preferredLanguageCode),
                healthEntry: he,
                healthEntryValue: heValueLookup[parentId],
              };
            case attachmentTypes.healthEntry:
              he = healthEntryLookup[parentId];
              return {
                attachment: a,
                category: he.category,
                name: HealthEntryModel.getName(he, preferredLanguageCode),
                healthEntry: he,
                healthEntryValue: null,
              };
            case attachmentTypes.trackable:
              trackable = trackableLookup[parentId];
              return {
                attachment: a,
                category: 'condition',
                name: trackableTermLookup[trackable.trackable_term_id].name || '',
                trackable,
              };
            case attachmentTypes.trackableObservation:
              const o = observationLookup[parentId] || {};
              trackable = trackableLookup[observationToTrackableId[parentId]] || {};
              return {
                attachment: a,
                category: 'condition',
                name: _.get(trackableTermLookup, `${trackable.trackable_term_id}.name`, '') || '',
                trackable,
                observation: o,
              };
            default:
              return {
                attachment: a,
                category: '',
                name: '',
              };
          }
        }).sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      },
      getGroupedByHealthEntryCategory: () => {
        return attachments.map((a) => {
          const parentId = a.parent.id;
          let he;
          let trackable;
          switch (a.parent.type) {
            case attachmentTypes.healthEntryValue:
              const heId = heValueToHeLookup[parentId];
              he = healthEntryLookup[heId];

              return {
                attachment: a,
                category: he.category,
                name: HealthEntryModel.getName(he, preferredLanguageCode),
                healthEntry: he,
                healthEntryValue: heValueLookup[parentId],
              };
            case attachmentTypes.healthEntry:
              he = healthEntryLookup[parentId];
              return {
                attachment: a,
                category: he.category,
                name: HealthEntryModel.getName(he, preferredLanguageCode),
                healthEntry: he,
                healthEntryValue: null,
              };
            case attachmentTypes.trackable:
              trackable = trackableLookup[parentId];
              return {
                attachment: a,
                category: 'condition',
                name: trackableTermLookup[trackable.trackable_term_id] || '',
                trackable,
              };
            case attachmentTypes.trackableObservation:
              const o = observationLookup[parentId] || {};
              trackable = trackableLookup[observationToTrackableId[parentId]] || {};
              return {
                attachment: a,
                category: 'condition',
                name: trackableTermLookup[trackable.trackable_term_id] || '',
                trackable,
                observation: o,
              };
            default:
              return {
                attachment: a,
                category: '',
                name: '',
              };
          }
        }).sort((a, b) => {
          return a.category.localeCompare(b.category);
        }).reduce((acc, v) => {
          acc[v.category] = acc[v.category] || [];

          return {
            ...acc,
            [v.category]: [
              ...acc[v.category],
              v,
            ],
          };
        }, {});
      },
    },
  ];
}

export function useAttachmentsForParentItem(attachmentType, parentId) {
  const [{ activeProfileId }] = useUIState();
  const [{ fileLookup }] = useFilesForActiveProfile();
  const [{ contactLookup }] = useContactsForActiveProfile();

  const enabled = activeProfileId && attachmentType && parentId;
  const attachmentQuery: any = useQuery(['attachments', activeProfileId, attachmentType, parentId], fetchAllAttachmentsForParent, { enabled, staleTime: STALE_TIME });
  const data = attachmentQuery.data || DEFAULT_ATTACHMENT_STATE;
  const isLoaded = data.isLoaded;
  const attachments = data.list;

  const attachedItems = attachments
    .reduce((acc, v) => {
      const keyName = v.attachment.type;
      if (keyName === 'file') {
        return {
          ...acc,
          files: acc.files.concat(fileLookup[v.attachment.id]),
        };
      } else if (keyName === 'contact') {
        return {
          ...acc,
          contacts: acc.contacts.concat(contactLookup[v.attachment.id]),
        };
      } else {
        return acc;
      }
    }, { files: [], contacts: [] });

  return [
    {
      contacts: attachedItems.contacts.sort((a, b) => a.name.localeCompare(b.name)),
      files: attachedItems.files.sort((a, b) => {
        const aName = a.name || '';
        const bName = b.name || '';
        return aName.localeCompare(bName);
      }),
      notes: [],
      isLoaded,
    },
    {
      apiDeleteAttachment: (attachment) => {
        return new Promise((res, rej) => {
          AttachmentsService.del(activeProfileId, attachment.id, () => {
            queryCache.invalidateQueries('attachments');
          });
        });
      },
      getFileAttachment: (fileId) => {
        return attachments.find(a => a.attachment.type === 'file' && a.attachment.id === fileId);
      },
      getContactAttachment: (contactId) => {
        return attachments.find(a => a.attachment.type === 'contact' && a.attachment.id === contactId);
      },
    },
  ];
}

export function useAttachments() {
  const dispatch = useDispatch();
  const [heData, heActions] = useHealthEntriesForActiveProfile();
  const [{ heValueToHeLookup }, hevActions] = useHealthEntryValuesForActiveProfile();
  const [{ activeProfileId }] = useUIState();
  const [contactData] = useContactsForProfile(activeProfileId);

  const attachmentQuery: any = useQuery(['attachments', activeProfileId], fetchAllAttachmentsForProfile(dispatch), { staleTime: STALE_TIME });
  const data = attachmentQuery.data || DEFAULT_ATTACHMENT_STATE;
  const attachmentList: any[] = data.list;
  const isLoaded = data.isLoaded;

  return [
    {
      attachmentList,
      contacts: [],
      files: [],
      notes: [],
      isLoaded: heData.isLoaded && contactData.isLoaded && isLoaded,
    },
    {
      instantiate: (attachment) => {
        let healthEntryId = attachment.type === 'health_entry' ? attachment.id : null;
        const healthEntryValueId = attachment.type === 'health_entry_value' ? attachment.id : null;

        let healthEntryValue = null;
        if (healthEntryValueId) {
          healthEntryValue = hevActions.instantiateHealthEntryValueId(healthEntryValueId);
          healthEntryId = heValueToHeLookup[healthEntryValueId];
        }
        const healthEntry = heActions.instantiateHealthEntryId(healthEntryId);

        return {
          healthEntryId,
          healthEntryValueId,
          healthEntry,
          healthEntryValue,
        };
      },
      apiDeleteAttachment: (attachment) => {
        return new Promise((res, rej) => {
          AttachmentsService.del(activeProfileId, attachment.id, () => {
            queryCache.invalidateQueries('attachments');
          });
        });
      },
      apiAttachFile: (fileId, attachmentType, parentId) => {
        return new Promise((res, rej) => {
          const attachment = new AttachmentModel();
          attachment.setParent(parentId, attachmentType);
          attachment.setAttachment(fileId, attachmentTypes.file);

          AttachmentsService.create(activeProfileId, attachment.data, () => {
            queryCache.invalidateQueries('attachments');
            res();
          });
        });
      },
      apiAttachContact: (contactId, attachmentType, parentId) => {
        return new Promise((res, rej) => {
          const attachment = new AttachmentModel();
          attachment.setParent(parentId, attachmentType);
          attachment.setAttachment(contactId, attachmentTypes.contact);

          AttachmentsService.create(activeProfileId, attachment.data, () => {
            queryCache.invalidateQueries('attachments');
            res();
          });
        });
      },
    },
  ];
}

export function useAvailableAttachments(attachmentType, parentId) {
  const [{ attachmentList }, attachmentActions] = useAttachments();
  const [{ fileList }] = useFilesForActiveProfile();

  const attachedFileIds = attachmentList.filter(a => {
    if (a.attachment.type === 'file' && a.parent.type === attachmentType) {
      return a.parent.id === parentId;
    } else {
      return false;
    }
  }).map(a => a.attachment.id);

  const availableFileList = fileList.filter(f => !attachedFileIds.includes(f.id));

  return [
    {
      availableFileList,
      availableNoteList: [],
      availableContactList: [],
    },
    {
      onSelectFile: (file, attachmentType, parentId) => {
        return attachmentActions.apiAttachFile(file.id, attachmentType, parentId).then(() => {
          queryCache.invalidateQueries('attachments');
        });
      },
    },
  ];
}
