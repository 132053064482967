import React from 'react';

const SCHEMA = HealthEntryGeneAttributes.find(g => g.name === 'mutationType');

import HEAttributeFooter from './components/HEAttributeFooter';
import RouteNames from '../../appAuthorized/RouteNames';

import ContentFrame from '../../applicationFrame/ContentFrame';
import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentLoader from '../../components/ContentLoader';

import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import ContentWrapper from '../../components/ContentWrapper';
import HealthEntryGeneAttributes from '../../../constants/HealthEntryGeneAttributes';
import { Card, CardRowUI } from '../../../bphComponents/bundle';
import HealthEntryGeneHelpText from './components/HealthEntryGeneHelpText';

import { useMedliGeneMutationType } from '../../hooks/useMedli';
import { useHealthEntryAndValueForActiveProfile } from '../../hooks/useHealthEntry';
import { useNavigate } from '../../hooks/useNavigate';

interface Props {
  match: any;
}
const HealthEntryGeneMutationType: React.FC<Props> = (props) => {
  const healthEntryId = Number(props.match.params.healthEntryId);
  const healthEntryValueId = Number(props.match.params.valueId);

  const [hevSourceState, hevActions] = useHealthEntryAndValueForActiveProfile(healthEntryId, healthEntryValueId);

  const [, navActions] = useNavigate();
  const [hasChanges, setHasChanges] = React.useState(false);
  const [isDeletable, setIsDeletable] = React.useState(false);

  const [{ geneMutationTypeList }] = useMedliGeneMutationType();

  const pendingHevInstance = hevActions.instantiateEditingHealthEntryValue();
  const selectedMutationTypeCui = pendingHevInstance.geneVariantResult && pendingHevInstance.geneVariantResult.getMutationTypeCui();
  const isLoaded = hevSourceState.isLoaded && geneMutationTypeList.length > 0;

  React.useEffect(() => {
    if (!hevSourceState.isLoaded) { return; }

    const sourceHevInstance = hevActions.instantiateHealthEntryValue();

    setIsDeletable(
      !!(sourceHevInstance.geneVariantResult && sourceHevInstance.geneVariantResult.getMutationTypeCui()),
    );
  }, [hevSourceState.isLoaded]);

  return (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          cancel={() => {
            navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
            hevActions.resetEditing();
          }}
          submit={() => {
            if (!hasChanges) {
              navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
              return;
            }

            hevActions.apiUpdate(pendingHevInstance.data())
              .then(() => {
                navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
              });
          }}
          color="white"
        />

        <ContentLoader isContentLoaded={isLoaded} blockRenderUntilLoaded={false}>
          <ContentFrame>
            <ScrollAwareScroller>
              <div className="cmp-healthentry-genemutationtype">
                <AppHeaderScrollableText
                  id="healthEntry.geneMutationType.title.text"
                />

                <ContentWrapper>
                  <HealthEntryGeneHelpText
                    heading="healthEntry.geneMutationType.helpBlock.heading"
                    subheading="healthEntry.geneMutationType.helpBlock.subheading"
                    content="healthEntry.geneMutationType.helpBlock.body"
                    isCollapsible={true}
                  />

                  <Card.CardShape>
                    {
                      geneMutationTypeList.map(mt => (
                        <CardRowUI.SelectableRow
                          key={mt.cui}
                          isSelected={mt.cui === selectedMutationTypeCui}
                          onClick={() => {
                            const newHevInstance = hevActions.instantiateEditingHealthEntryValue();
                            newHevInstance.geneVariantResult.setMutationType(mt.id);
                            newHevInstance.geneVariantResult.setMutationTypeCui(mt.cui);
                            setHasChanges(true);
                            hevActions.updateEditing(newHevInstance.data());
                            // setHeValueState(newHevInstance.data());
                          }}
                          selectedClassName="fill-color-profile-highlight"
                          hasBottomBorder={true}
                        >
                          {mt.string}
                        </CardRowUI.SelectableRow>
                      ))
                    }
                  </Card.CardShape>

                  {
                    isDeletable && (
                      <HEAttributeFooter
                        isFlagged={null}
                        onDelete={() => {
                          const attributeNames = SCHEMA.values.map(v => v.geneVariantResultKey);
                          const newHevInstance = hevActions.instantiateEditingHealthEntryValue();
                          newHevInstance.geneVariantResult.clearAttributeValues(attributeNames);
                          hevActions.apiUpdate(newHevInstance.data())
                            .then(() => {
                              navActions.goBack(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
                            });
                        }}
                        onToggleFlag={null}
                      />
                    )
                  }
                </ContentWrapper>
              </div>
            </ScrollAwareScroller>
          </ContentFrame>
        </ContentLoader>
      </ScrollAwareContext>
    </div>
  );
};

export default HealthEntryGeneMutationType;
