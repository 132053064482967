

import React from 'react';

import COLORS from '../../../styles/Colors';
import { Flagged } from './Flagged';
import IconAttachment from '../../../components/icons/IconAttachment';
import { Icons } from '../../../../bphComponents/bundle';

interface Props {
  hasAttachments: boolean;
  hasComments: boolean;
  isFlagged: boolean;
  style?: any;
}

const HealthEntryMetaIcons: React.FC<Props> = (props) => {
  const { hasAttachments, hasComments, isFlagged, style } = props;

  return (
    <div className="cmp-hesummary-icons-container" style={{ display: 'flex', flexShrink: 0, ...style }}>
      {hasAttachments && <span style={{ display: 'flex', marginLeft: 4 }}><IconAttachment.Small /></span>}
      {hasComments && <span style={{ display: 'flex', marginLeft: 4 }}><Icons.IconComment.Small color={COLORS.gsMedDark} /></span>}
      {isFlagged && <span style={{ display: 'flex', marginLeft: 4, marginTop: 2 }}><Flagged isFlagged={isFlagged} /></span>}
    </div>
  );
};

export default HealthEntryMetaIcons;
