import { request } from './request';

export enum eSurveyQuestionCategoryName {
  singleAnswer = 'single_answer',
  fuzzyDate = 'fuzzy_date',
  rapidResponse = 'rapid_response',
  amountEntry = 'amount_entry',
  textEntry = 'text_entry',
  composite = 'composite',
}
export enum eUserType {
  Primary = 'primary',
  Dependent = 'dependent',
}

interface iSurveyResponseSingleAnswer {
  id: string;
}
export interface iSurveyQuestionSingleAnswer {
  help_text: string;
  options: {
    source?: {
      provider: string;
    };
    text: string;
    response: {
      id: string;
    }
  }[];
}
interface iSurveyResponseTextEntry {
  content: string;
}
export interface iSurveyQuestionTextEntry {
  format: 'textarea' | 'input';
  help_text?: string;
  hint_text?: string;
}

interface iSurveyResponseFuzzyDate {
  date: string;
  precision: string;
}
interface iSurveyQuestionFuzzyDate {
  source?: {
    provider: string;
  };
  value?: {
    precision: string;
    date: string;
  };
}
interface iSurveyResponseAmountEntry {
  unit_id?: string;
  unsure: boolean;
  values?: string[];
}
export interface iSurveyQuestionAmountEntry {
  help_text?: string;
  hint_text?: string;
  source?: {
    provider: string;
  };
  units: {
    components: string[];
    id: string;
    text: string;
  }[];
}

export interface iSurveySubQuestionResponse {
  uid: string;
  option: {
    id: string;
    category?: eSurveyQuestionCategoryName;
    single_answer?: iSurveyResponseSingleAnswer;
    fuzzy_date?: iSurveyResponseFuzzyDate;
    amount_entry?: iSurveyResponseAmountEntry;
    rapid_response?: iSurveyResponseRapidResponse;
    text_entry?: iSurveyResponseTextEntry;
  };
}
interface iSurveyResponseRapidResponse {
  list: iSurveySubQuestionResponse[];
}
interface iSurveySubQuestion {
  text: string;
  uid: string;
}
interface iSurveyQuestionRapidResponse {
  help_text: string;
  list: iSurveySubQuestion[];
  options: {
    text: string;
    source?: {
      provider: string;
    };
    response: {
      id: string;
    };
    question?: iSurveyFollowupQuestion;
  }[];
}
interface iSurveyFollowupQuestion {
  text: string;
  category?: eSurveyQuestionCategoryName;
  single_answer?: iSurveyResponseSingleAnswer;
  fuzzy_date?: iSurveyResponseFuzzyDate;
  amount_entry?: iSurveyResponseAmountEntry;
  rapid_response?: iSurveyResponseRapidResponse;
  text_entry?: iSurveyResponseTextEntry;
}
interface iSurveyResponseEvaluation {
  id: string;
  value: number;
}
interface iSurveyQuestionEvaluation {
  id: string;
  text: string;
}
export interface iSurveyResponseComposite {
  segments: iSurveyResponse[];
  evaluations: iSurveyResponseEvaluation[];
}
export interface iSurveyQuestionComposite {
  evaluations: iSurveyQuestionEvaluation[];
  help_text: string;
  segments: iSurveyQuestion[];
}

export interface iSurveyResponse {
  category: eSurveyQuestionCategoryName;
  single_answer?: iSurveyResponseSingleAnswer;
  fuzzy_date?: iSurveyResponseFuzzyDate;
  amount_entry?: iSurveyResponseAmountEntry;
  rapid_response?: iSurveyResponseRapidResponse;
  text_entry?: iSurveyResponseTextEntry;
  composite?: iSurveyResponseComposite;
}

export interface iSurveyQuestion {
  category: eSurveyQuestionCategoryName;
  single_answer?: iSurveyQuestionSingleAnswer;
  fuzzy_date?: iSurveyQuestionFuzzyDate;
  amount_entry?: iSurveyQuestionAmountEntry;
  rapid_response?: iSurveyQuestionRapidResponse;
  text_entry?: iSurveyQuestionTextEntry;
  composite?: iSurveyQuestionComposite;
}

export interface iSurveyTopLevelQuestion extends iSurveyQuestion {
  id: number;
  uid: string;
  text: string;
}
export interface iSurveyTopLevelResponse extends iSurveyResponse {
  question_id: number;
  time_start: string;
  time_end: string;
}

export interface iSurvey {
  id: number;
  profile_id: number;
  group_id: number;
  survey_definition_id: number;
  completion_percentage: number;
  submitted: boolean;
  created_at: Date;
  updated_at: Date;
  submitted_at: Date;
  responses: iSurveyTopLevelResponse[];
}
export interface iSurveyDefinition {
  id: number;
  group_id: number;
  estimated_completion_mins: number;
  enabled: boolean;
  informed_consent_required: boolean;
  trigger_event: 'group_join';
  created_at: Date;
  updated_at: Date;
  name: string;
  short_description: string;
  intro_message: string;
  conclusion_message: string;
}
export interface iSurveyQuestionOverview {
  response: iSurveyTopLevelResponse;
  survey_definition: {
    id: number;
    section: iSurveyQuestionSurveyDefinitionSection;
  };
  survey: {
    id: number;
    completion_percentage: number;
  };
}

export interface iSurveyDefinitionQuestions {
  sections: iSurveyDefinitionSection[];
}
export interface iSurveyDefinitionSection {
  title: string;
  questions: iSurveyTopLevelQuestion[];
}
interface iSurveyQuestionSurveyDefinitionSection {
  title: string;
  question: iSurveyTopLevelQuestion[];
}
export const surveyService = {
  getSurveys(profileId): Promise<iSurvey[]> {
    return request('get', `profiles/${profileId}/surveys`);
  },
  getSurvey(profileId, surveyId): Promise<iSurvey> {
    return request('get', `profiles/${profileId}/surveys/${surveyId}`);
  },
  async getFirstQuestion(profileId, surveyId): Promise<iSurveyQuestionOverview> {
    return request('get', `profiles/${profileId}/surveys/${surveyId}/questions/next`);
  },
  async getLastQuestion(profileId, surveyId): Promise<iSurveyQuestionOverview> {
    return request('get', `profiles/${profileId}/surveys/${surveyId}/questions/previous`);
  },
  async getPrevQuestion(profileId, surveyId, questionId): Promise<iSurveyQuestionOverview> {
    return request('get', `/profiles/${profileId}/surveys/${surveyId}/questions/${questionId}/previous`);
  },
  async getNextQuestion(profileId, surveyId, questionId): Promise<iSurveyQuestionOverview> {
    return request('get', `/profiles/${profileId}/surveys/${surveyId}/questions/${questionId}/next`);
  },
  getDefinitions(profileId, groupId, languageCode, userType: eUserType): Promise<iSurveyDefinition[]> {
    return request('get', `profiles/${profileId}/surveyDefinitions?groupId=${groupId}&language_code=${languageCode}&user_type=${userType}`);
  },
  getDefinition(profileId, definitionId): Promise<iSurveyDefinition> {
    return request('get', `profiles/${profileId}/surveyDefinitions/${definitionId}`);
  },
  getDefinitionQuestions(profileId, definitionId): Promise<iSurveyDefinitionQuestions> {
    return request('get', `profiles/${profileId}/surveyDefinitions/${definitionId}/questions`);
  },
  markSurveySubmitted(profileId, surveyId): Promise<void> {
    return request('post', `profiles/${profileId}/surveys/${surveyId}/submitted`);
  },
  submitResponse(profileId, surveyId, questionId, body): Promise<iSurveyQuestionOverview> {
    return request('put', `profiles/${profileId}/surveys/${surveyId}/questions/${questionId}`, body);
  },
};
