import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { ProfileAccentCard } from './card/Card';
import FlexRow from './FlexRow';
import IconDataConnections from './icons/IconDataConnections';
import IconArrowRight from './icons/IconArrowRight';
import RouteNames from '../appAuthorized/RouteNames';
import { useUIState } from '../hooks/useUIState';
import { useHealthEntriesForActiveProfile } from '../hooks/useHealthEntry';
import { useActiveProfile } from '../hooks/useProfile';
import { useNavigate } from '../hooks/useNavigate';

interface Props {
  goToReviewPendingData: () => void;
  profileName: string;
  isUser: boolean;
}

const PendingHealthEntryReminder = (props: Props) => {
  const phraseKeys = props.isUser ? {
    heading: {
      id: 'bpHealth.pendingDataReminder.user.heading',
    },
  } : {
    heading: {
      id: 'bpHealth.pendingDataReminder.notUser.heading',
    },
  };

  return (
    <ProfileAccentCard className="cmp-pendingdata-reminder font-color-white" onClick={props.goToReviewPendingData}>
        <FlexRow>
          <div className="font-headline">
            <FormattedMessage {...phraseKeys.heading} />
          </div>
          <div className="icon-connections">
            <IconDataConnections color="white" />
          </div>
        </FlexRow>
        <div>
          <FlexRow className="button">
            <div className="button-label font-subhead">
              <FormattedMessage id="bpHealth.pendingDataReminder.review.button.label" />
            </div>
            <IconArrowRight.Small color="white" />
          </FlexRow>
        </div>
    </ProfileAccentCard>
  );
};

const ProcessingHealthEntryReminder = (props) => {
  const phraseKeys = props.isUser ? {
    message: {
      id: 'bpHealth.pendingDataProcessing.user.message',
    },
  } : {
    message: {
      id: 'bpHealth.pendingDataProcessing.notUser.message',
      values: { string1: props.profileName },
    },
  };

  return (
    <ProfileAccentCard className="cmp-pendingdata-reminder font-color-white">
      <FlexRow>
        <div className="font-headline">
          <FormattedMessage id="bpHealth.pendingDataProcessing.heading" />
        </div>
        <div className="icon-connections">
          <IconDataConnections color="white" />
        </div>
      </FlexRow>
      <div className="message font-subhead">
        <FormattedMessage {...phraseKeys.message} />
      </div>
    </ProfileAccentCard>
  );
};

const OtherHealthEntryReminder = () => {
  const [{ isActiveProfileMainProfile }] = useUIState();
  const [{ hasPending, hasProcessing }] = useHealthEntriesForActiveProfile();
  const [{ greetingName }] = useActiveProfile();
  const [, navActions] = useNavigate();

  if (hasProcessing) {
    return (
      <ProcessingHealthEntryReminder
        profileName={greetingName}
        isUser={isActiveProfileMainProfile}
      />
    );
  } else if (hasPending) {
    return (
      <PendingHealthEntryReminder
        goToReviewPendingData={() => {
          navActions.goTo(RouteNames.reviewPendingData());
        }}
        profileName={greetingName}
        isUser={isActiveProfileMainProfile}
      />
    );
  }

  return null;
};

export default withRouter(OtherHealthEntryReminder);
