
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../../components/ScrollAware';
import ContentFrame from '../../../applicationFrame/ContentFrame';
import { AppHeaderScrollableText } from '../../../components/appNav/AppHeaderScrollable';
import ContentWrapper from '../../../components/ContentWrapper';
import ContentLoader from '../../../components/ContentLoader';
import { Card } from '../../../../bphComponents/bundle';

import RouteNames from '../../../appAuthorized/RouteNames';
import ContactModel from '../../../../models/ContactModel';

import { ContactSummaryText } from '../../../components/ContactSummaryText';
import { CommentDetail } from './DetailSummary';
import DetailGroup from './DetailGroup';
import AppUtils from '../../../../utilities/AppUtils';
import { useActiveProfile } from '../../../hooks/useProfile';
import { useUIState } from '../../../hooks/useUIState';
import { useContactForActiveProfile } from '../../../hooks/useContacts';
import { useNavigate } from '../../../hooks/useNavigate';

interface ViewContactSummaryProps {
  match: any;
  location: any;
}

const ViewContactSummary: React.FC<ViewContactSummaryProps> = (props) => {
  const contactId = Number(props.match.params.contactId);
  const referrer = AppUtils.getQueryParams(props.location.search).referrer;

  const [{ greetingName }] = useActiveProfile();
  const [{ isMainProfile }] = useUIState();
  const [{ contact, isLoaded }] = useContactForActiveProfile(contactId);
  const [, navActions] = useNavigate();

  const groupedDetails = ContactModel.groupDetails(contact);

  const noDetails = contact.details.length === 0;
  const currentPhrase = isMainProfile ? { id: 'provider.user.currentPastList.current.label' } : { id: 'provider.notUser.currentPastList.current.label', values: { string1: greetingName } } ;
  const pastPhrase = isMainProfile ? { id: 'provider.user.currentPastList.past.label' } : { id: 'provider.notUser.currentPastList.past.label', values: { string1: greetingName } } ;
  const titlePhrase = contact.current ? currentPhrase : pastPhrase;

  const goToEdit = () => {
    navActions.goTo(RouteNames.contactEdit(contact.id, referrer));
  };

  return (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          goBack={() => { navActions.goBack(RouteNames.contactList(), null, [{ pathname: RouteNames.contactAdd() }]); }}
          edit={goToEdit}
          onDisabledClick={() => {}}
          color="white"
          hasDropShadow
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText text={contact.name}>
              <div className="font-subhead" style={{ marginTop: 4 }}>
                <ContactSummaryText data={contact} />
              </div>
            </AppHeaderScrollableText>

            <ContentWrapper>
              <ContentLoader isContentLoaded={isLoaded} blockRenderUntilLoaded={!isLoaded} isFixed={true}>
                {
                  ContactModel.isProvider(contact) && (
                    <Card.ExternalCardTitle>
                      <FormattedMessage {...titlePhrase} />
                    </Card.ExternalCardTitle>
                  )
                }


                {
                  noDetails && (
                    <Card.CardButton alignText="center" className="font-color-gsMedDark" onClick={goToEdit}>
                      <FormattedMessage id="contact.addDetails.button.label" />
                    </Card.CardButton>
                  )
                }

                <Card.CardShape>
                  <DetailGroup
                    linkType="phone"
                    details={groupedDetails.phone}
                  />

                  <DetailGroup
                    linkType="email"
                    details={groupedDetails.email}
                  />

                  <DetailGroup
                    linkType="address"
                    details={groupedDetails.address}
                  />

                  <DetailGroup
                    linkType="website"
                    details={groupedDetails.website}
                  />

                  <CommentDetail
                    data={contact.comment}
                  />
                </Card.CardShape>

              </ContentLoader>
            </ContentWrapper>
          </ScrollAwareScroller>

        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
};

export default ViewContactSummary;
