import React from 'react';

import { healthEntryCategories } from '../../../../models/healthEntryCategories';
import {
  default as ShareCardModel,
  filterHealthEntriesBySubType,
  HealthEntries,
  ShareCard,
} from '../../../../models/ShareCardModel';
import { ShareCardCategory } from './ShareCardCategory';
import ShareCardRowEditHealthEntry from './ShareCardRowEditHealthEntry';
import { ShareCardCategorySection } from './ShareCardCategorySection';
import { ShareCardColorModel } from '../../../appAuthorized/dataTransformComponents/ColorShareCard';

interface Props {
  shareCard: ShareCardModel;
  healthEntries: { [key: string]: HealthEntries };
  shareCardColorModel: ShareCardColorModel;
  languageCode: string;
  intl: any;
  expandedCategory: string;
  toggleCategory(el, categoryName): void;
  updateShareCard(data: ShareCard, cb): void;
}

const ShareCardEditHealthEntries: React.FC<Props> = (props) => {
  const { shareCardColorModel, shareCard, healthEntries, languageCode, intl, expandedCategory, toggleCategory, updateShareCard } = props;
  return (
    <>
      {
        healthEntryCategories.map((category, categoryIdx) => {
          const group = shareCard.createHealthEntryGroup(
            category.kind,
            healthEntries[category.kind],
            category.includedValues,
            category.subType,
            category.latestItemsOnly,
          );

          const entries = filterHealthEntriesBySubType(healthEntries[category.kind], category.subType);

          return (
            <ShareCardCategory
              key={categoryIdx}
              showTotals={true}
              icon={category.icon}
              group={group}
              shareCardColorModel={shareCardColorModel}
              titlePhraseData={category.getTitlePhraseData(intl)}
              isExpanded={expandedCategory === category.identifier}
              toggleExpand={(e) => { toggleCategory(e.target, category.identifier); }}
              toggleAll={() => {
                const updatedShareCard = ShareCardModel.toggleHealthEntryIncludedAll(shareCard.data, shareCard.definition, entries.all);
                updateShareCard(updatedShareCard, () => {});
              }}
            >
              {
                entries.newer.map((entry, entryIdx) => (
                  <ShareCardRowEditHealthEntry
                    key={entryIdx}
                    allowIncludedValuesChange={category.allowIncludedValuesChange}
                    healthEntry={entry}
                    shareCard={shareCard.data}
                    shareCardDefinition={shareCard.definition}
                    languageCode={languageCode}
                    shareCardColorModel={shareCardColorModel}
                    updateShareCard={updateShareCard}
                  />
                ))
              }

              <ShareCardCategorySection
                titleShowHidden={category.getTitleShowHidden(intl, entries.older.length)}
                titleHideRevealed={category.getTitleHideRevealed(intl, entries.older.length)}
                shareCardColorModel={shareCardColorModel}
              >
                {entries.older.map((entry, entryIdx) =>
                  <ShareCardRowEditHealthEntry
                    key={entryIdx}
                    allowIncludedValuesChange={category.allowIncludedValuesChange}
                    healthEntry={entry}
                    shareCard={shareCard.data}
                    shareCardDefinition={shareCard.definition}
                    languageCode={languageCode}
                    shareCardColorModel={shareCardColorModel}
                    updateShareCard={updateShareCard}
                  />)
                }
              </ShareCardCategorySection>
            </ShareCardCategory>
          );
        })
      }
    </>
  );
};

export default ShareCardEditHealthEntries;
