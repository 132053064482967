import DateUtil from '../utilities/DateUtil';

export const REMINDER_DAYS = [
  'list.daysOfWeek.sunday.name',
  'list.daysOfWeek.monday.name',
  'list.daysOfWeek.tuesday.name',
  'list.daysOfWeek.wednesday.name',
  'list.daysOfWeek.thursday.name',
  'list.daysOfWeek.friday.name',
  'list.daysOfWeek.saturday.name',
];

function getBackpackDate(dateValue) {
  const jsDate = new Date(dateValue);
  const year = jsDate.getFullYear();
  const month = `0${jsDate.getMonth() + 1}`.substr(-2);
  const day = `0${jsDate.getDate()}`.substr(-2);

  return [year, month, day].join('-');
}
function getJSDate(bpDate) {
  const parts = bpDate.split('-').map(n => Number(n));

  return new Date(parts[0], parts[1] - 1, parts[2]);
}
function convertDataForSaving(simpleData) {
  const { times, frequencyDays, dayCount, startDate, title, text } = simpleData;

  // -1 indicates an infinitely occurring daily event
  const baseTrigger: any = { count: dayCount || -1 };
  // if startDate is in past, it should not have a firstAt Date
  if (DateUtil.isInFuture(startDate)) {
    baseTrigger.firstAt = getJSDate(startDate);
  }
  const notes = [];

  const days = frequencyDays.length > 0 ? frequencyDays : [-1];

  let startId = +new Date();

  days.forEach(d => {
    const baseEvery = d > -1 ? { weekday: d } : {};

    const tt = times.length > 0 ? times : [''];

    tt.forEach(t => {
      let every = {};
      if (t) {
        const timeParts = t.split(':').map(n => Number(n));
        every = {
          hour: timeParts[0],
          minute: timeParts[1],
        };
      }

      startId += 1;

      notes.push({
        id: startId,
        smallIcon: 'res://bp_mark',
        color: '#D81B51',
        title,
        text,
        data: {
          actionName: simpleData.actionName,
          ...simpleData.options,
        },
        trigger: {
          ...baseTrigger,
          every: {
            ...baseEvery,
            ...every,
          },
        },
      });
    });
  });

  return notes;
}

function convertDataForEditing(actionName, nData) {
  const notifications = nData || [];
  const firstNotification = notifications[0] || {};
  const firstTrigger = firstNotification.trigger || { count: 0, firstAt: new Date() };
  const startDate = firstTrigger.firstAt ? getBackpackDate(firstTrigger.firstAt) : DateUtil.today();

  const times = notifications.reduce((acc, n) => {
    if (!n.trigger || !n.trigger.every || !Number.isInteger(n.trigger.every.hour)) { return acc; }
    const h = `0${n.trigger.every.hour}`.substr(-2);
    const m = `0${n.trigger.every.minute || 0}`.substr(-2);
    const t = `${h}:${m}:00`;
    const exists = acc.find(v => v === t);
    return exists ? acc : [...acc, t];
  }, []);

  const frequencyDays = notifications.reduce((acc, n) => {
    if (!n.trigger || !n.trigger.every || !Number.isInteger(n.trigger.every.weekday)) { return acc; }
    const value = n.trigger.every.weekday;

    const exists = acc.find(v => v === value);
    return exists ? acc : [...acc, value];
  }, []);

  // HACK: high dayCounts means it is triggering everyday, however in the UI we want the count to be zero for this case
  const dayCount = firstTrigger.count && firstTrigger.count < 365 ? firstTrigger.count : 0;

  return {
    actionName,
    startDate,
    options: {
      ...firstNotification.data,
    },
    dayCount,
    isNew: false,
    isDaily: frequencyDays.length === 0,
    frequencyDays,
    times,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
}

export function cleanupNotifications(trackables, profileId) {
  const actionName = 'createObservation';
  const validTrackableMap = trackables.reduce((acc, t) => ({ ...acc, [t.id]: true }), {});

  return new Promise((res) => {
    cordova.plugins.notification.local.getAll((notes) => {
      const correctedNotes = notes.map(n => ({
        ...n,
        data: JSON.parse(n.data),
      }));

      const invalidIds = correctedNotes.filter(n => {
        return n.data.actionName === actionName && n.data.profileId === profileId;
      }).filter(n => {
        return !validTrackableMap[n.data.trackableId];
      }).map(n => {
        return n.id;
      });

      if (invalidIds.length > 0) {
        cordova.plugins.notification.local.cancel(invalidIds, () => {
          res();
        });
      } else {
        res();
      }
    });
  });
}

export class DeviceNotifications {

  public static getNextTimeIncrement() {
    const d = DateUtil.breakNowTimeIntoParts();
    const m = d.minutes % 5;

    d.minutes += 5 - m;

    if (d.minutes > 55) {
      d.minutes = 0;
      d.hours += 1;
    }

    if (d.hours > 24) {
      d.hours = 0;
    }

    return `${d.hours}:${d.minutes}:${d.seconds}`;
  }

  public static defaultObservationReminderData(profileId, trackableId) {
    const actionName = 'createObservation';

    const d = convertDataForEditing(actionName, []);
    d.options = {
      actionName,
      profileId,
      trackableId,
      isNew: true,
    };
    const routeParams = Object.entries(d.options).map((entry) => `${entry[0]}=${entry[1]}`).join('&');
    d.options.routeName = `/actions?${routeParams}`;

    return d;
  }

  public static createObservation(profileId, trackableId, data, text, title) {
    const note =  new DeviceNotifications(
      'createObservation',
      {
        text,
        title,
        ...DeviceNotifications.defaultObservationReminderData(profileId, trackableId),
        ...data,
      });

    return note;
  }

  public static getObservationReminders(trackableId): Promise<any[]> {
    const actionName = 'createObservation';

    return new Promise((res) => {
      cordova.plugins.notification.local.getAll((notes) => {
        const correctedNotes = notes.map(n => ({
          ...n,
          data: JSON.parse(n.data),
        }));

        const results = correctedNotes.filter(n => {
          return n.data.actionName === actionName;
        }).filter(n => {
          return n.data.trackableId === trackableId;
        });
        res(results);
      });
    });
  }

  public static getScheduledObservationReminders(trackableId): Promise<any[]> {
    const actionName = 'createObservation';

    return new Promise((res) => {
      cordova.plugins.notification.local.getScheduled((notes) => {
        const correctedNotes = notes.map(n => ({
          ...n,
          data: JSON.parse(n.data),
        }));

        const results = correctedNotes.filter(n => {
          return n.data.actionName === actionName;
        }).filter(n => {
          return n.data.trackableId === trackableId;
        });
        res(results);
      });
    });
  }

  public static requestObservationNotifications(profileId, trackableId) {
    const actionName = 'createObservation';

    const newResult = DeviceNotifications.defaultObservationReminderData(profileId, trackableId);
    newResult.times.push(DeviceNotifications.getNextTimeIncrement());

    return new Promise((res) => {
      if (!IS_ANDROID_BUILD) {
        return res({ ...newResult, isNew: true });
      }

      DeviceNotifications.getObservationReminders(trackableId)
        .then(results => {
          if (results.length > 0) {
            res({ ...convertDataForEditing(actionName, results), isNew: false });
          } else {
            res({ ...newResult, isNew: true });
          }
        });
    });
  }

  public static requestScheduledObservationNotifications(profileId, trackableId) {
    const actionName = 'createObservation';

    const newResult = DeviceNotifications.defaultObservationReminderData(profileId, trackableId);
    newResult.times.push(DeviceNotifications.getNextTimeIncrement());

    return new Promise((res) => {
      if (!IS_ANDROID_BUILD) {
        return res({ ...newResult, isNew: true });
      }

      DeviceNotifications.getScheduledObservationReminders(trackableId)
        .then(results => {
          if (results.length > 0) {
            res({ ...convertDataForEditing(actionName, results), isNew: false });
          } else {
            res({ ...newResult, isNew: true });
          }
        });
    });
  }

  public static checkAndRequestPermission() {
    return new Promise((res, rej) => {
      if (!IS_ANDROID_BUILD) { return rej(); }

      cordova.plugins.notification.local.hasPermission((granted1) => {
        if (granted1) {
          res();
        } else {
          cordova.plugins.notification.local.requestPermission((granted2) => {
            if (granted2) {
              res();
            } else {
              rej();
            }
          });
        }
      });
    });
  }

  public static cancelAllNotificationsForTrackable(trackableId) {
    return new Promise((res) => {
      if (!IS_ANDROID_BUILD) { return res(); }
      DeviceNotifications.getObservationReminders(trackableId)
        .then(results => {
          const ids = results.map(r => r.id);

          if (ids.length > 0) {
            cordova.plugins.notification.local.cancel(ids, () => {
              res();
            });
          } else {
            res();
          }
        });
    });
  }

  private actionName: string;
  public data: any;

  constructor(actionName: string, data: any) {
    this.actionName = actionName;
    this.data = {
      ...convertDataForEditing(actionName, []),
      ...data,
    };
  }

  get isDailyFrequency() {
    return this.data.isDaily;
  }

  get isSpecificDayFrequency() {
    return !this.data.isDaily;
  }

  get isNew() {
    return Boolean(this.data.isNew);
  }

  cancel() {
    const trackableId = this.data.options.trackableId;
    if (!IS_ANDROID_BUILD) { return; }

    return new Promise((res) => {
      DeviceNotifications.getObservationReminders(trackableId)
        .then(results => {
          const ids = results.map(r => r.id);

          if (ids.length > 0) {
            cordova.plugins.notification.local.cancel(ids, () => {
              res();
            });
          } else {
            res();
          }
        });
    });

  }

  schedule() {
    const d = convertDataForSaving(this.data);
    return new Promise((res) => {
      if (!IS_ANDROID_BUILD) { return res(); }

      this.cancel()
        .then(() => {
          cordova.plugins.notification.local.schedule(d, () => {
            res();
          });
        });
    });
  }

  setDuration(startDate, dayCount) {
    this.data.startDate = startDate;
    this.data.dayCount = dayCount;
  }

  setDailyFrequency() {
    this.data.isDaily = true;
    this.data.frequencyDays = [];
  }

  setSpecificDayFrequency() {
    this.data.isDaily = false;
    this.data.frequencyDays = this.data.frequencyDays || [];
  }

  toogleWeekdayFrequency(weekday) {
    if (this.data.frequencyDays.includes(weekday)) {
      this.data.frequencyDays = this.data.frequencyDays.filter(n => n !== weekday);
    } else {
      this.data.frequencyDays.push(weekday);
    }
  }

  isDayIncluded(weekday) {
    return this.data.frequencyDays.includes(weekday);
  }

  hasValidFrequency() {
    return this.isDailyFrequency || (
      this.isSpecificDayFrequency && this.data.frequencyDays.length > 0
    );
  }

  getTime(index) {
    return this.data.times[index];
  }

  removeTime(index) {
    this.data.times.splice(index, 1);
  }

  getTimezoneOffset() {
    return this.data.timezone_offset;
  }

  addTime(newTime) {
    this.data.times.push(newTime);
  }

  updateTime(timeIndex, newTime) {
    this.data.times[timeIndex] = newTime;
  }

  getDayPhraseIds() {
    const days = [...this.data.frequencyDays.sort()];
    if (days[0] === 0) {
      days.shift();
      days.push(0);
    }
    return days.map(d => REMINDER_DAYS[d]);
  }

}
