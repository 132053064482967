import React from 'react';
import { FormattedMessage } from 'react-intl';

import { RoundedButton } from '../../../components/buttons/Buttons';
import { ObservationSummary } from '../../../components/HealthEntryValueSummary';
import { Card, Buttons, Icons } from '../../../../bphComponents/bundle';
import ObservationSeriesGraph from './ObservationSeriesGraph';
import { useActiveUser } from '../../../hooks/useUser';
import { iTrackable } from '../../../../apiServices/apiModels/Trackable';
import { useUIState } from '../../../hooks/useUIState';
import FlexRow from '../../../components/FlexRow';
import COLORS from '../../../styles/Colors';

const LIST_DISPLAY_LIMIT = 15;

const iconProps = {
  color: 'middle-gray',
  size: '76',
};

interface Props {
  trackable: iTrackable;
  EmptyIcon: any;
  onClickAdd(): void;
  onClickItem(d): void;
  trackableTermName: string;
}

const ObservationItem = (props) => {
  const { trackableId, observation, onClick, languageCode } = props;

  return (
    <Card.CardContent hasBottomBorder={true} onClick={() => { onClick(observation); }}>
      <ObservationSummary
        observation={observation}
        languageCode={languageCode}
      />
    </Card.CardContent>
  );
};

const ToggleRemainingContent = (props) => {
  const { isExpanded, onClick } = props;

  return (
    <Card.CardContent hasBottomBorder={true}>
      <Card.CollapsibleControl isExpanded={isExpanded} onClick={onClick}>
        <div className="font-color-profile-accent">
          {
            isExpanded
            ? <FormattedMessage id="healthentry.hideAllList.button.label" />
            : <FormattedMessage id="healthentry.viewAllList.button.label" />
          }
        </div>
      </Card.CollapsibleControl>
    </Card.CardContent>
  );
};

const ObservationSeries: React.FC<Props> = (props) => {
  const { trackable, onClickAdd, EmptyIcon, onClickItem, trackableTermName } = props;
  const trackableId = trackable.id;
  const observations = trackable.observations || [];

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [{ preferredLanguageCode }] = useActiveUser();
  const [, uiActions] = useUIState();
  const profileColor = uiActions.getProfileColorValue();

  let topObservations = [];
  let remainingObservations = [];

  if (observations.length > LIST_DISPLAY_LIMIT) {
    topObservations = observations.slice(0, 10);
    remainingObservations = observations.slice(10);
  } else {
    topObservations = observations;
  }

  if (observations.length === 0) {
    return (
      <div className="util-application-frame-forcards-padding" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', height: '100%', marginTop: 40, marginBottom: 56 }}>
        <div style={{ marginBottom: 32, marginLeft: 'auto', marginRight: 'auto' }}>
          <EmptyIcon {...iconProps} />
        </div>
        <RoundedButton className="font-title-3 border-color-gsMedLightDT" type="primary" action="profile" onClick={onClickAdd} style={{ borderWidth: 1, borderStyle: 'solid' }}>
          <FormattedMessage id={'heSummaryList.addValue.button.label'} />
        </RoundedButton>
        <div className="font-subhead font-color-gsDark" style={{ textAlign: 'center', margin: '32px auto 0', maxWidth: 260 }}>
          <FormattedMessage id={'heSummaryList.addValue.message.text'} />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Card.ExternalContent>
          <FlexRow style={{ marginRight: 4 }}>
            <Buttons.InlineAddButton
              onClick={onClickAdd}
              className="stroke-color-profile-accent"
              color=""
            >
              <span className="font-footnote font-color-profile-accent">
                <FormattedMessage id="heSummaryList.addNewValue.button.label" />
              </span>
            </Buttons.InlineAddButton>

            <Icons.IconTracking.Medium color={profileColor.accent} />
          </FlexRow>
        </Card.ExternalContent>

        <ObservationSeriesGraph
          observations={observations}
          title={trackableTermName}
        />

        <Card.CardShape>
          {
            topObservations.map((item, index) => (
              <ObservationItem
                key={index}
                trackableId={trackableId}
                observation={item}
                languageCode={preferredLanguageCode}
                onClick={() => onClickItem(item)}
              />
            ))
          }

          {
            remainingObservations.length > 0
            ? (
              <>
                <ToggleRemainingContent isExpanded={isExpanded} onClick={() => { setIsExpanded(!isExpanded); }} />
                <Card.CollapsibleContent isExpanded={isExpanded} background={COLORS.gsLightest}>
                  {
                    remainingObservations.map((item, index) => (
                      <ObservationItem
                        key={index}
                        trackableId={trackableId}
                        observation={item}
                        languageCode={preferredLanguageCode}
                        onClick={() => onClickItem(item)}
                      />
                    ))
                  }
                </Card.CollapsibleContent>
              </>
            ) : null
          }
        </Card.CardShape>
      </>
    );
  }
};

export default ObservationSeries;
