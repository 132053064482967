import React from 'react';
import html2canvas from 'html2canvas';
import qrcode from 'qrcode';
import Croppie from 'croppie';
import { FormattedMessage, injectIntl } from 'react-intl';

import './ViewShareCardWallpaperSetup.scss';

import ImageUtil from '../../../../utilities/ImageUtil';
import AppUtils from '../../../../utilities/AppUtils';
import * as MobileUtil from '../../../../utilities/MobileUtil';
import COLORS from '../../../styles/Colors';
import ShareCardModel, { ShareCard } from '../../../../models/ShareCardModel';
import { ShareCardColorModel } from '../../../appAuthorized/dataTransformComponents/ColorShareCard';

import * as IconEmergency from '../components/IconEmergency';
import { PermissionsRequiredModal, requiredPermissionTypes } from '../../../components/modal/WarningModal';
import { Icons } from '../../../../bphComponents/bundle';
import FlexRow from '../../../components/FlexRow';
import { ProfileThemedInfoModal } from '../../../components/modal/InfoModal';
import { TrackEvent } from '../../../../utilities/UserMetrics';
import { useUIState } from '../../../hooks/useUIState';
import { useShareCardForActiveProfile } from '../../../hooks/useShareCard';

function drawImage(canvasRef, dataUrl) {
  return new Promise((res, rej) => {
    const c = canvasRef.current;
    if (!c) { rej(); }

    const ctx = c.getContext('2d');

    const imageObj = new Image();
    imageObj.onload = function () {
      // convert cropped image to canvas
      canvasRef.current.width = window.innerWidth;
      canvasRef.current.height = window.innerHeight;
      ctx.drawImage(imageObj, 0, 0, imageObj.width, imageObj.height);
      res();
    };

    imageObj.src = dataUrl;
  });
}

function createWallpaperImage(wallpaperRef, albumName, onSuccess) {
  html2canvas(wallpaperRef.current)
  .then((canvas) => {
    const imgCanvasUrl = canvas.toDataURL('image/jpg');
    const uri = encodeURI(imgCanvasUrl);

    MobileUtil.saveImageToPhotoGallery(uri, albumName)
      .then(onSuccess)
      .catch((err) => {
        if (err.startsWith('Permission')) {
          MobileUtil.requestPhotoLibraryPermission()
          .then(() => {
            MobileUtil.saveImageToPhotoGallery(uri, albumName).then(onSuccess);
          });
        }
      });
  });
}

function supportsWallpaper(shareCard) {
  // tablet or landscape devices do not support wallpaper
  if (!ShareCardModel.isEmergencyShareCard(shareCard.definition)) { return false; }
  return IS_ANDROID_BUILD ? !AppUtils.isTablet() : false;
}

function useEditWallpaper(imageFile, intl, onComplete) {
  const mainRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const croppieRef = React.useRef(null);
  const wallpaperRef = React.useRef(null);

  function loadSelectedImage() {
    MobileUtil.showSystemSpinner();
    MobileUtil.hideStatusBar();
    // prevent landscape orientation
    MobileUtil.lockOrientation('portait');

    // need a delay to allow hiding status bar take effect
    setTimeout(() => {
      ImageUtil.imageToDataUrl(imageFile, (dataUrl) => {
        MobileUtil.hideSystemSpinner();

        croppieRef.current = new Croppie(mainRef.current, {
          viewport: { width: window.innerWidth, height: window.innerHeight, type: 'square' },
          boundary: { width: window.innerWidth, height: window.innerHeight },
          mouseWheelZoom: true,
          showZoomer: false,
          url: dataUrl,
          update: () => {
            croppieRef.current.elements.zoomer.max = 1;
          },
        });
      });
    }, 300);
  }

  function handleSaveImage() {
    MobileUtil.showSystemSpinner();
    const albumName = intl.formatMessage({ id: 'shareCard.wallpaper.devicePhotoAlbum.name' });

    croppieRef.current.result('blob').then((blob) => {
      const croppedImageUrl = window.URL.createObjectURL(blob);
      drawImage(canvasRef, croppedImageUrl)
        .then(() => {
          croppieRef.current.destroy();
          createWallpaperImage(
            wallpaperRef,
            albumName,
            () => {
              onComplete();
              MobileUtil.hideSystemSpinner();
              MobileUtil.unlockOrientation();
              MobileUtil.showStatusBar();
            },
          );
        })
        .catch(() => {
          MobileUtil.hideSystemSpinner();
        });
    });
  }

  React.useEffect(() => {
    loadSelectedImage();
  } , []);

  return {
    mainRef,
    canvasRef,
    wallpaperRef,
    handleSaveImage,
  };
}

const QRCode = ({ text }) => {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    qrcode.toCanvas(canvasRef.current, text, { height: 70, width: 70, borderRadius: 4 }, (error) => {
      if (error) {
        console.error(error);
      }
    });
  });

  return (
    <canvas ref={canvasRef} />
  );
};

const WallpaperInstructionsModal = () => {
  return (
    <ProfileThemedInfoModal
      hasCloseButton={true}
      phraseMessages={{
        title: {
          id: 'shareCard.wallpaperInstructions.heading',
        },
        subheading: {
          id: 'shareCard.wallpaperInstructions.subheading',
        },
        body: {
          id: 'shareCard.wallpaperInstructions.message.md',
        },
        button: {
          id: 'shareCard.wallpaperInstructions.button.label',
        },
      }}
      isBodyPreformatted={true}
      onConfirm={null}
    />
  );
};

interface EditWallpaperProps {
  onCancel(): void;
  onComplete(): void;
  imageFile: any;
  shareCard: any;
  intl: any;
}
const EditWallpaper: React.FC<EditWallpaperProps> = ({ onCancel, onComplete, imageFile, shareCard, intl }) => {
  const {
    mainRef,
    canvasRef,
    wallpaperRef,
    handleSaveImage,
  } = useEditWallpaper(imageFile, intl, onComplete);

  const accessCode = shareCard.passcode;
  const uid = shareCard.data.uid;
  const url = intl.formatMessage({ id: 'shareCard.url.fullwithAccessCode.url' }, { string1: AppUtils.getShareUrlSubdomainPrefix(), string2: uid, string3: accessCode });

  return (
    <div className="cmp-viewsharecardwallpapersetup">

      <div className="_background" />

      <div className="_top-controls">
        <button
          className="font-color-gsLightest font-body"
          onClick={() => {
            onCancel();
            MobileUtil.unlockOrientation();
            MobileUtil.showStatusBar();
          }}
        >
          <FormattedMessage id="wallpaperPhoto.cancel.button.label" />
        </button>
        <div
          className="font-color-gsLightest font-headline __center"
        >
          <FormattedMessage id="wallpaperPhoto.title.text" />
        </div>
        <button
          className="font-color-gsLightest font-body"
          onClick={() => handleSaveImage()}
        >
          <FormattedMessage id="wallpaperPhoto.save.button.label" />
        </button>
      </div>

      <div ref={wallpaperRef} className="_image">
        <div ref={mainRef} />
        <canvas ref={canvasRef} />
        <div className="__qr-overlay">
          <div className="__wrapper">
            <div style={{ marginRight: 4 }}>
              <IconEmergency.XLarge color={COLORS.gsLightest} />
            </div>
            <div>
              <div className="font-caption-1 font-color-gsLightLT">
                <FormattedMessage id="shareCard.wallpaper.heading" />
              </div>
              <div style={{ flexGrow: 1, fontWeight: 'bold' }} className="font-title-3 font-color-gsLightest">
                <FormattedMessage id="shareCard.url.display.text" values={{ string1: AppUtils.getShareUrlSubdomainPrefix(), string2: uid }} />
              </div>
              <div className="font-caption-1 font-color-gsLightLT">
                <FormattedMessage id="shareCard.wallpaper.message" values={{ string1: accessCode }} />
              </div>
            </div>
          </div>
          <QRCode text={url} />
        </div>
      </div>
    </div>
  );
};
const EditWallpaperWithIntl = injectIntl(EditWallpaper);

interface Props {
  shareCardData: ShareCard;
  shareCardColorModel: ShareCardColorModel;
  isEditMode: boolean;
  WrapperComponent: any;
  onEnableUrl(): void;
  intl: any;
}
const ViewShareCardWallpaperSetup: React.FC<Props> = (props) => {
  const { shareCardColorModel, shareCardData, isEditMode, WrapperComponent, onEnableUrl, intl } = props;
  const [, scActions] = useShareCardForActiveProfile();
  const [, uiActions] = useUIState();
  const shareCard = scActions.instantiate(shareCardData);

  const [showEditWallpaperImage, setEditWallpaperImage] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);

  const shareCardColor = shareCardColorModel.darkAccentColor();

  if (!supportsWallpaper(shareCard)) { return null; }

  const onCheckPermissions = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const shareCardTemplateUId = shareCard.definitionUid;
    if (isEditMode) {
      TrackEvent.shareCards.openShareCardWallpaperFromEditView(shareCardTemplateUId);
    } else {
      TrackEvent.shareCards.openShareCardWallpaperFromSummaryView(shareCardTemplateUId);
    }
    MobileUtil.checkAndRequestFilePermissions(
      () => {
        MobileUtil.selectFileFromSource(
          intl.formatMessage({ id: 'ui-data.fileName.untitled.text' }),
          'library',
          () => {},
          (fileData) => {
            setImageFile(fileData);
            setEditWallpaperImage(true);
          },
        );
      },
      () => {},
      () => {
        uiActions.pushSystemModal(<PermissionsRequiredModal permissions={[requiredPermissionTypes.library]} />);
      },
    );
  };

  return (
    <>
    <WrapperComponent>
      <FlexRow>
        <button
          className="font-footnote"
          style={{ color: shareCardColor, cursor: 'pointer', zIndex: 1, textAlign: 'left' }}
          onClick={onCheckPermissions}
        >
          <FormattedMessage id="shareCard.wallpaper.button.label" />
        </button>

        <div style={{ marginLeft: 12 }}>
          <Icons.IconFileTypeImage.Medium color={shareCardColorModel.highlightColor()} />
        </div>
      </FlexRow>
    </WrapperComponent>
    {
      showEditWallpaperImage && (
        <EditWallpaperWithIntl
          onComplete={() => {
            onEnableUrl();
            uiActions.pushSystemModal(<WallpaperInstructionsModal />);
            setEditWallpaperImage(false);
          }}
          onCancel={() => {
            setEditWallpaperImage(false);
          }}
          imageFile={imageFile}
          shareCard={shareCard}
        />
      )
    }
    </>
  );
};

export default injectIntl(ViewShareCardWallpaperSetup);
