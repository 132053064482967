import React from 'react';
import classnames from 'classnames';

import ExpandToggleControl from '../../components/ExpandToggleControl';

interface TopSectionWithCollapseControlProps {
  isCollapsible: boolean;
  isExpanded: boolean;
  onToggle(value: boolean): void;
  children: any;
  className?: string;
  hasBorder: boolean;
  title: any;
  style?: any;
}

interface CollapsibleRowState {
  isExpanded: boolean;
}

interface CollapsibleRowProps extends Partial<TopSectionWithCollapseControlProps> {
  collapsedContent?: any;
  isCollapsible: boolean;
  defaultCollapsed: boolean;
  style?: any;
  children: any;
  isExpanded?: boolean;
  onToggle?(value): void;
  topSectionClassName?: string;
}

export const TopSectionWithCollapseControl = (props: TopSectionWithCollapseControlProps) => {
  const { children, isCollapsible, isExpanded, onToggle, className, hasBorder, title, style } = props;
  const onClick = isCollapsible ? () => (onToggle(!isExpanded)) : null;

  return (
    <div
      className={classnames('cmp-collapsiblerow-topsection', className, {  'has-border': hasBorder && isExpanded, 'is-clickable': !!onClick })}
      style={style}
      onClick={onClick}
    >
      <div className="cmp-collapsiblerow-topsection-controlrow">
        {title}
        {
          isCollapsible && (
            <ExpandToggleControl
              isExpanded={isExpanded}
            />
          )
        }
      </div>

      {children}
    </div>
  );
};

export class CollapsibleRow extends React.Component<CollapsibleRowProps, CollapsibleRowState> {

  state: CollapsibleRowState = {
    isExpanded: !this.props.defaultCollapsed,
  };

  collapseList = () => {
    this.setState({ isExpanded: false });
  }

  renderCollapsedContent() {
    const { collapsedContent } = this.props;
    if (!collapsedContent) return null;

    const { isExpanded } = this.state;
    if (isExpanded) return null;

    return (
      <div className="font-body font-color-profile" style={{ marginTop: 6, marginBottom: 6 }}>
        {collapsedContent}
      </div>
    );
  }

  onToggle = (value) => {
    this.setState({ isExpanded: value });
  }

  render() {
    const { children, isCollapsible, collapsedContent, title, hasBorder, style, topSectionClassName } = this.props;

    const isExpanded = this.props.onToggle ? this.props.isExpanded : this.state.isExpanded;
    const onToggle = this.props.onToggle ? this.props.onToggle : this.onToggle;

    return (
      <div className={classnames('cmp-collapsiblerow', { expanded: isExpanded })}>
        <TopSectionWithCollapseControl
          hasBorder={hasBorder}
          title={title}
          isCollapsible={isCollapsible}
          isExpanded={isExpanded}
          onToggle={onToggle}
          className={topSectionClassName}
          style={style}
        >
          {collapsedContent}
        </TopSectionWithCollapseControl>

        {this.renderCollapsedContent()}

        <div className="body">
          {
            typeof children === 'function' ? children({ collapseList: this.collapseList }) : children
          }
        </div>
      </div>
    );
  }
}

export default CollapsibleRow;
