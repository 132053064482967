import React from 'react';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  color?: string;
}

const IconAttachmentSmall = (props: Props) => {
  const color = props.color || 'gsMedDark';

  return (
    <svg height="16px" width="16px" viewBox="0 0 16 16">
      <g id="icon-attach">
        <path className={colorClassNames.stroke(color)} style={{ fill: 'none', strokeMiterlimit: 10, strokeWidth: '1.25px' }} d="M11.49,9.75,7.84,13.41a3.33,3.33,0,0,1-4.69,0h0a3.33,3.33,0,0,1,0-4.69l6.6-6.6a2.38,2.38,0,0,1,3.35,0h0a2.38,2.38,0,0,1,0,3.35L6.51,12.06a1.43,1.43,0,0,1-2,0h0a1.43,1.43,0,0,1,0-2L8.13,6.42" />
      </g>
    </svg>
  );
};

const IconAttachmentMedium = (props: Props) => {
  const color = props.color || 'gsMedDark';

  return (
    <svg height="20px" width="20px" viewBox="0 0 16 16">
      <g id="icon-attach">
        <path className={colorClassNames.stroke(color)} style={{ fill: 'none', strokeMiterlimit: 10, strokeWidth: '1.25px' }} d="M11.49,9.75,7.84,13.41a3.33,3.33,0,0,1-4.69,0h0a3.33,3.33,0,0,1,0-4.69l6.6-6.6a2.38,2.38,0,0,1,3.35,0h0a2.38,2.38,0,0,1,0,3.35L6.51,12.06a1.43,1.43,0,0,1-2,0h0a1.43,1.43,0,0,1,0-2L8.13,6.42" />
      </g>
    </svg>
  );
};

const IconAttachment = {
  Small: IconAttachmentSmall,
  Medium: IconAttachmentMedium,
};

export default IconAttachment;
