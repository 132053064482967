import * as React from 'react';
import { connect } from 'react-redux';
import { selectors as groupSelectors } from '../../redux/ducks/group';
import GroupLogo from './GroupLogo';
import AppUtils from '../../../utilities/AppUtils';
import { LocalText } from '../../../utilities/localizedUtil';
import GroupMetadata from '../../../models/GroupMetadata';
import { injectIntl } from 'react-intl';

interface Props {
  groupId?: number;
  disablePlaceholder?: boolean;
  group: any;
  children: any;
  intl: any;
}
const BasicGroupOrganizationName: React.FC<Props> = (props) => {
  const { group, children, disablePlaceholder, intl } = props;

  const name = group.organization_name || group.name;

  let showLogo = true;

  if (disablePlaceholder) {
    const groupMeta = new GroupMetadata(group);
    showLogo = groupMeta.imagesIcon.hasValue(intl.locale) || groupMeta.imagesIcon.hasValue();
  }

  return (
    <span style={{ display: 'inline-flex' }}>
      {
        showLogo && (
          <GroupLogo
            group={group}
            size="24px"
            backgroundColor={AppUtils.cssColor(group.theme.base_rgba)}
          />
        )
      }

      <span style={{ marginLeft: (showLogo ? 8 : null), marginTop: 1 }}>
        {typeof children === 'function' ? children(name) : <LocalText text={name}/>}
      </span>
    </span>
  );
};

function mapStateToProps(state, props) {
  const { groupId, group } = props;
  return {
    group: groupId ? groupSelectors.getGroup(state, groupId) : group,
  };
}
export const GroupOrganizationName = connect(mapStateToProps)(injectIntl(BasicGroupOrganizationName));
