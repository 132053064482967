
import { GroupMetadataImageType } from './GroupMetadataImageModel';
import _ from 'lodash';

export interface I18nTextType {
  default: string;
  i18n: {
    [lang: string]: string;
  };
}

export interface I18nImageType {
  default: GroupMetadataImageType;
  i18n: {
    [lang: string]: GroupMetadataImageType;
  };
}

export interface I18nImageCollectionType {
  default: GroupMetadataImageType[];
  i18n: {
    [lang: string]: GroupMetadataImageType[];
  };
}

interface RgbaType {
  r: number;
  g: number;
  b: number;
  a: number;
}

export enum GroupMetadataProperty {
  images = 'images',
  imagesBanner = 'banner',
  imagesIcon = 'icon',
  imagesOrgLogo = 'organization_logo',
  imagesGallery = 'gallery',
  research_focused = 'research_focused',
  member_count = 'member_count',
  organization_url = 'organization_url',
  organization_description = 'organization_description',
  id = 'id',
  subdomain = 'subdomain',
  logo = 'logo',
  created_at = 'created_at',
  updated_at = 'updated_at',
  locale_id = 'locale_id',
  discoverable = 'discoverable',
  about = 'about',
  uid = 'uid',
  theme = 'theme',
  contact = 'contact',
  keywords = 'keywords',
  organization_name = 'organization_name',
  eligibility = 'eligibility',
  name = 'name',
  external_link = 'external_link',
  external_link_label = 'label',
  external_link_url = 'url',
}

export interface GroupMetadataType {
  [GroupMetadataProperty.images]: {
    [GroupMetadataProperty.imagesBanner]: I18nImageType;
    [GroupMetadataProperty.imagesIcon]: I18nImageType;
    [GroupMetadataProperty.imagesOrgLogo]: I18nImageType;
    [GroupMetadataProperty.imagesGallery]: I18nImageCollectionType;
  };
  [GroupMetadataProperty.research_focused]: boolean;
  [GroupMetadataProperty.member_count]: {
    enabled: boolean;
    value: number;
  };
  [GroupMetadataProperty.organization_url]: I18nTextType;
  [GroupMetadataProperty.organization_description]: I18nTextType;
  [GroupMetadataProperty.id]: number;
  [GroupMetadataProperty.subdomain]: string;
  [GroupMetadataProperty.logo]: string;
  [GroupMetadataProperty.created_at]: string;
  [GroupMetadataProperty.updated_at]: string;
  [GroupMetadataProperty.locale_id]: number;
  [GroupMetadataProperty.discoverable]: boolean;
  [GroupMetadataProperty.about]: I18nTextType;
  [GroupMetadataProperty.uid]: string;
  [GroupMetadataProperty.theme]: {
    base_rgba: RgbaType;
    accent_rgba: RgbaType;
    highlight_rgba: RgbaType;
  };
  [GroupMetadataProperty.contact]: {
    iso2_code: string;
    email: string;
    phone: string;
    url: string;
    facebook: string;
    twitter: string;
    instagram: string;
    youtube: string;
  };
  [GroupMetadataProperty.keywords]: I18nTextType;
  [GroupMetadataProperty.organization_name]: I18nTextType;
  [GroupMetadataProperty.eligibility]: I18nTextType;
  [GroupMetadataProperty.name]: I18nTextType;
  [GroupMetadataProperty.external_link]: {
    [GroupMetadataProperty.external_link_label]: I18nTextType;
    [GroupMetadataProperty.external_link_url]: I18nTextType;
  };
}

const emptyI18n = { default: '', i18n: {} };

export default class GroupMetadata {

  static empty() {
    return {
      about: { ...emptyI18n },
      eligibility: { ...emptyI18n },
      keywords: null,
      contact: {},
      theme: {
        base_rgba: {
          r: 255,
          g: 255,
          b: 255,
          a: 1,
        },
        accent_rgba: {
          r: 255,
          g: 255,
          b: 255,
          a: 1,
        },
        highlight_rgba: {
          r: 255,
          g: 255,
          b: 255,
          a: 1,
        },
      },
    };
  }

  public data: GroupMetadataType;

  constructor(d) {
    this.data = {
      [GroupMetadataProperty.images]: {
        [GroupMetadataProperty.imagesBanner]: {...emptyI18n},
        [GroupMetadataProperty.imagesIcon]: {...emptyI18n},
        [GroupMetadataProperty.imagesOrgLogo]: {...emptyI18n},
        [GroupMetadataProperty.imagesGallery]: {...emptyI18n},
      },
      [GroupMetadataProperty.research_focused]: null,
      [GroupMetadataProperty.member_count]: {},
      [GroupMetadataProperty.organization_url]: {...emptyI18n},
      [GroupMetadataProperty.organization_description]: {...emptyI18n},
      [GroupMetadataProperty.id]: null,
      [GroupMetadataProperty.subdomain]: '',
      [GroupMetadataProperty.logo]: '',
      [GroupMetadataProperty.created_at]: '',
      [GroupMetadataProperty.updated_at]: '',
      [GroupMetadataProperty.locale_id]: null,
      [GroupMetadataProperty.discoverable]: null,
      [GroupMetadataProperty.about]: {...emptyI18n},
      [GroupMetadataProperty.uid]: '',
      [GroupMetadataProperty.theme]: {
        base_rgba: {},
        accent_rgba: {},
        highlight_rgba: {},
      },
      [GroupMetadataProperty.contact]: {
        iso2_code: '',
        email: '',
        phone: '',
        url: '',
        facebook: '',
        twitter: '',
        instagram: '',
        youtube: '',
      },
      [GroupMetadataProperty.keywords]: {...emptyI18n},
      [GroupMetadataProperty.organization_name]: {...emptyI18n},
      [GroupMetadataProperty.eligibility]: {...emptyI18n},
      [GroupMetadataProperty.name]: {...emptyI18n},
      [GroupMetadataProperty.external_link]: {
        [GroupMetadataProperty.external_link_label]: {...emptyI18n},
        [GroupMetadataProperty.external_link_url]: {...emptyI18n},
      },
      ...JSON.parse(JSON.stringify(d)),
    };
  }

  set(fieldName: GroupMetadataProperty, value: any, lang?: string) {
    switch(fieldName) {
      case GroupMetadataProperty.imagesIcon:
        this.data.images[fieldName] = value;
        break;
      case GroupMetadataProperty.imagesBanner:
      case GroupMetadataProperty.imagesOrgLogo:
      case GroupMetadataProperty.imagesGallery:
        this.data.images[fieldName].i18n[lang] = value;
        break;
      case GroupMetadataProperty.organization_url:
      case GroupMetadataProperty.organization_description:
      case GroupMetadataProperty.about:
      case GroupMetadataProperty.keywords:
      case GroupMetadataProperty.organization_name:
      case GroupMetadataProperty.eligibility:
      case GroupMetadataProperty.name:
        this.data[fieldName].i18n[lang] = value;
        break;
      case GroupMetadataProperty.external_link_label:
      case GroupMetadataProperty.external_link_url:
        this.data[GroupMetadataProperty.external_link][fieldName].i18n[lang] = value;
        break;
      case GroupMetadataProperty.research_focused:
      case GroupMetadataProperty.discoverable:
        this.data[fieldName] = value;
        break;
      case GroupMetadataProperty.member_count:
        this.data[fieldName] = value;
        break;
      case GroupMetadataProperty.locale_id:
      case GroupMetadataProperty.id:
        this.data[fieldName] = value;
        break;
      case GroupMetadataProperty.subdomain:
      case GroupMetadataProperty.logo:
      case GroupMetadataProperty.created_at:
      case GroupMetadataProperty.updated_at:
      case GroupMetadataProperty.uid:
        this.data[fieldName] = value;
        break;
      case GroupMetadataProperty.theme:
        this.data[fieldName] = value;
        break;
      case GroupMetadataProperty.contact:
        this.data[fieldName] = value;
        break;
      case GroupMetadataProperty.external_link:
      case GroupMetadataProperty.images:
      default:
        console.error(`field not defined: ${fieldName}`)
        break;
    }
  }

  get(fieldName: GroupMetadataProperty, lang?: string) {
    switch(fieldName) {
      case GroupMetadataProperty.imagesIcon:
        return this.data.images[fieldName];
      case GroupMetadataProperty.imagesBanner:
      case GroupMetadataProperty.imagesOrgLogo:
      case GroupMetadataProperty.imagesGallery:
        return lang ? this.data.images[fieldName].i18n[lang] : this.data.images[fieldName].default;
      case GroupMetadataProperty.organization_url:
      case GroupMetadataProperty.organization_description:
      case GroupMetadataProperty.about:
      case GroupMetadataProperty.keywords:
      case GroupMetadataProperty.organization_name:
      case GroupMetadataProperty.eligibility:
      case GroupMetadataProperty.name:
        return (lang ? this.data[fieldName].i18n[lang] : this.data[fieldName].default) || '';
      case GroupMetadataProperty.external_link_label:
      case GroupMetadataProperty.external_link_url:
        return lang ? this.data[GroupMetadataProperty.external_link][fieldName].i18n[lang] : this.data[GroupMetadataProperty.external_link][fieldName].default;
      case GroupMetadataProperty.research_focused:
      case GroupMetadataProperty.discoverable:
      case GroupMetadataProperty.member_count:
      case GroupMetadataProperty.locale_id:
      case GroupMetadataProperty.id:
      case GroupMetadataProperty.subdomain:
      case GroupMetadataProperty.created_at:
      case GroupMetadataProperty.updated_at:
      case GroupMetadataProperty.uid:
      case GroupMetadataProperty.theme:
      case GroupMetadataProperty.contact:
      case GroupMetadataProperty.external_link:
      case GroupMetadataProperty.images:
        return this.data[fieldName];
      case GroupMetadataProperty.logo:
        console.error(`field DEPRECATED: ${fieldName}`);
        return '';
      default:
        console.error(`field not defined: ${fieldName}`);
        return '';
    }
  }

  getActiveLanguages(fieldName: GroupMetadataProperty): string[] {
    switch(fieldName) {
      case GroupMetadataProperty.imagesBanner:
      case GroupMetadataProperty.imagesOrgLogo:
      case GroupMetadataProperty.imagesGallery:
      case GroupMetadataProperty.imagesIcon:
        return Object.keys(this.data.images[fieldName].i18n);
      case GroupMetadataProperty.organization_url:
      case GroupMetadataProperty.organization_description:
      case GroupMetadataProperty.about:
      case GroupMetadataProperty.keywords:
      case GroupMetadataProperty.organization_name:
      case GroupMetadataProperty.eligibility:
      case GroupMetadataProperty.name:
        return Object.keys(this.data[fieldName].i18n);
      case GroupMetadataProperty.external_link_label:
      case GroupMetadataProperty.external_link_url:
        return Object.keys(this.data[GroupMetadataProperty.external_link][fieldName].i18n);
      default:
        console.error(`field not defined: ${fieldName}`)
        return [];
    }
  }

  about = {
    get: () => this.data[GroupMetadataProperty.about],
    set: () => {},
    hasValue: (lang?: string): boolean => {
      return lang
      ? !!this.data[GroupMetadataProperty.about].i18n[lang]
      : !!this.data[GroupMetadataProperty.about].default;
    },
    getText: (lang?: string) => {
      const i18nText = _.get(this.data, `${GroupMetadataProperty.about}.i18n${lang}`, '')
      return i18nText || this.data[GroupMetadataProperty.about].default;
    },
  };

  contact = {
    hasValue: () => {
      const contacts = this.data[GroupMetadataProperty.contact];

      return (
        contacts.email ||
        contacts.phone ||
        contacts.url ||
        contacts.facebook ||
        contacts.twitter ||
        contacts.instagram ||
        contacts.youtube
      );
    },
    hasSocialContacts: () => {
      const contacts = this.data[GroupMetadataProperty.contact];

      return (
        contacts.facebook ||
        contacts.twitter ||
        contacts.instagram ||
        contacts.youtube
      );
    },
    hasNonSocialContacts: () => {
      const contacts = this.data[GroupMetadataProperty.contact];

      return (
        contacts.email ||
        contacts.phone ||
        contacts.url
      );
    },
  };

  eligibility = {
    hasValue: (lang?: string): boolean => {
      return lang
      ? !!this.data[GroupMetadataProperty.eligibility].i18n[lang]
      : !!this.data[GroupMetadataProperty.eligibility].default;
    },
    get: () => this.data[GroupMetadataProperty.eligibility],
    getText: (lang?: string) => {
      const i18nText = _.get(this.data, `${GroupMetadataProperty.eligibility}.i18n${lang}`, '')
      return i18nText || this.data[GroupMetadataProperty.eligibility].default;
    },
  };

  externalLink = {
    hasValue: (lang?: string) => {
      const i18nText = _.get(this.data, `${GroupMetadataProperty.external_link}.${GroupMetadataProperty.external_link_url}.i18n.${lang}`, '')
      return !!i18nText || !!this.data[GroupMetadataProperty.external_link][GroupMetadataProperty.external_link_url].default;
    },
  };

  groupName = {
    get: () => {
      return this.data[GroupMetadataProperty.name];
    },
    getText: (lang?: string) => {
      const i18nText = _.get(this.data, `${GroupMetadataProperty.name}.i18n${lang}`, '')
      return i18nText || this.data[GroupMetadataProperty.name].default;
    },
    getInitials: (lang?: string) => {
      const name = this.groupName.getText(lang);
      return name ? name[0] : '';
    },
  };

  images = {
    get: () => {},
    set: () => {},
    _getProperty: (fieldName: GroupMetadataProperty) => {
      return this.data[GroupMetadataProperty.images][fieldName];
    },
    _getLocalizedValue: (fieldName: GroupMetadataProperty, lang?: string): GroupMetadataImageType => {
      const val = lang
      ? this.data[GroupMetadataProperty.images][fieldName].i18n[lang]
      : this.data[GroupMetadataProperty.images][fieldName].default;
      return val || {};
    },
    _getLocalizedValuesAsI18nArray: (fieldName: GroupMetadataProperty, isArray?: boolean) => {
      const images = {};
      const i18nData = this.data[GroupMetadataProperty.images][fieldName].i18n;

      if (isArray) {
        return i18nData;
      } else {
        Object.entries(i18nData).forEach(([lang, data]: any) => {
          images[lang] = (data.id || data.url) ? [data] : [];
        });
      }

      return images;
    },
    _setLocalizedValuesFromI18nArray: (fieldName: GroupMetadataProperty, files: { [lang: string]: GroupMetadataImageType[]}) => {
      const data = {};

      Object.entries(files).forEach(([lang, val]) => {
        data[lang] = val[0] || {};
      });

      this.data[GroupMetadataProperty.images][fieldName].i18n = data;
    },
    _getLocalizedUrl: (fieldName: GroupMetadataProperty, lang?: string): string => {
      const val = lang
      ? this.data[GroupMetadataProperty.images][fieldName].i18n[lang]
      : this.data[GroupMetadataProperty.images][fieldName].default;

      return val ? val.url : '';
    },
    _clearUrls: (fieldName: GroupMetadataProperty) => {
      Object.entries(this.data[GroupMetadataProperty.images][fieldName].i18n).forEach(([lang, val]) => {
        if (Array.isArray(val)) {
          val.forEach((v, i) => {
            val[i] = { ...v, url: '' };
          });
        } else {
          this.data[GroupMetadataProperty.images][fieldName].i18n[lang] = Object.assign({}, val, { url: '' });
        }
      });
    },
    _hasValue: (fieldName: GroupMetadataProperty, lang?: string): boolean => {
      return lang
      ? !!this.data[GroupMetadataProperty.images][fieldName].i18n[lang]
      : !!this.data[GroupMetadataProperty.images][fieldName].default;
    },
  };

  imagesOrgLogo = {
    get: (): I18nImageType => this.images._getProperty(GroupMetadataProperty.imagesOrgLogo),
    getValue: (lang?: string): GroupMetadataImageType => this.images._getLocalizedValue(GroupMetadataProperty.imagesOrgLogo, lang),
    hasValue: (lang?: string): boolean => this.images._hasValue(GroupMetadataProperty.imagesOrgLogo, lang),
    getValuesAsI18nArray: () => this.images._getLocalizedValuesAsI18nArray(GroupMetadataProperty.imagesOrgLogo),
    setValuesFromI18nArray: (files: { [lang: string]: GroupMetadataImageType[]}) => this.images._setLocalizedValuesFromI18nArray(GroupMetadataProperty.imagesOrgLogo, files),
    url: (lang?: string): string => this.images._getLocalizedUrl(GroupMetadataProperty.imagesOrgLogo, lang),
    clearUrls: () => this.images._clearUrls(GroupMetadataProperty.imagesOrgLogo),
  };
  imagesIcon = {
    get: (): I18nImageType => this.images._getProperty(GroupMetadataProperty.imagesIcon),
    getValue: (lang?: string): GroupMetadataImageType => this.images._getLocalizedValue(GroupMetadataProperty.imagesIcon, lang),
    hasValue: (lang?: string): boolean => this.images._hasValue(GroupMetadataProperty.imagesIcon, lang),
    getValuesAsI18nArray: () => this.images._getLocalizedValuesAsI18nArray(GroupMetadataProperty.imagesIcon),
    setValuesFromI18nArray: (files: { [lang: string]: GroupMetadataImageType[]}) => this.images._setLocalizedValuesFromI18nArray(GroupMetadataProperty.imagesIcon, files),
    clearUrls: () => this.images._clearUrls(GroupMetadataProperty.imagesIcon),
    url: (lang?: string): string => this.images._getLocalizedUrl(GroupMetadataProperty.imagesIcon, lang),
  };
  imagesBanner = {
    get: (): I18nImageType => this.images._getProperty(GroupMetadataProperty.imagesBanner),
    getValue: (lang?: string): GroupMetadataImageType => this.images._getLocalizedValue(GroupMetadataProperty.imagesBanner, lang),
    hasValue: (lang?: string): boolean => this.images._hasValue(GroupMetadataProperty.imagesBanner, lang),
    getValuesAsI18nArray: () => this.images._getLocalizedValuesAsI18nArray(GroupMetadataProperty.imagesBanner),
    setValuesFromI18nArray: (files: { [lang: string]: GroupMetadataImageType[]}) => this.images._setLocalizedValuesFromI18nArray(GroupMetadataProperty.imagesBanner, files),
    clearUrls: () => this.images._clearUrls(GroupMetadataProperty.imagesBanner),
    url: (lang?: string): string => this.images._getLocalizedUrl(GroupMetadataProperty.imagesBanner, lang),
  };
  imagesGallery = {
    get: (): I18nImageCollectionType => this.images._getProperty(GroupMetadataProperty.imagesGallery),
    getValue: (lang?: string): (GroupMetadataImageType[]) => {
      const v = lang
        ? this.data[GroupMetadataProperty.images][GroupMetadataProperty.imagesGallery].i18n[lang]
        : this.data[GroupMetadataProperty.images][GroupMetadataProperty.imagesGallery].default;
      return v || [];
    },
    hasValue: (lang?: string): boolean => this.images._hasValue(GroupMetadataProperty.imagesGallery, lang),
    getValuesAsI18nArray: () => this.images._getLocalizedValuesAsI18nArray(GroupMetadataProperty.imagesGallery, true),
    setValuesFromI18nArray: (files: { [lang: string]: GroupMetadataImageType[]}) => {
      const data = {};

      Object.entries(files).forEach(([lang, val]) => {
        data[lang] = val;
      });

      this.data[GroupMetadataProperty.images][GroupMetadataProperty.imagesGallery].i18n = data;
    },
    clearUrls: () => this.images._clearUrls(GroupMetadataProperty.imagesGallery),
    // clearUrls: () => {
    //   Object.entries(this.data[GroupMetadataProperty.images][GroupMetadataProperty.imagesGallery].i18n).forEach(([lang, val]) => {
    //     this.data[GroupMetadataProperty.images][GroupMetadataProperty.imagesGallery].i18n[lang].forEach((v, i) => {
    //       this.data[GroupMetadataProperty.images][GroupMetadataProperty.imagesGallery].i18n[lang][i] = {
    //         ...v,
    //         url: '',
    //       };
    //     });
    //   });
    // },
  };

  memberCount = {
    get: () => {},
    set: () => {},

    enabled: {
      get: (): boolean => {
        return this.data[GroupMetadataProperty.member_count].enabled;
      },
      set: (v: boolean) => {
        this.data[GroupMetadataProperty.member_count].enabled = v;
      },
    },
    value: {
      get: (): number => {
        return this.data[GroupMetadataProperty.member_count].value;
      },
    },
  };

  organizationDescription = {
    hasValue: () => !!this.data[GroupMetadataProperty.organization_description].default,
    get: () => this.data[GroupMetadataProperty.organization_description],
    getText: (lang?: string) => {
      const i18nText = _.get(this.data, `${GroupMetadataProperty.organization_description}.i18n${lang}`, '')
      return i18nText || this.data[GroupMetadataProperty.organization_description].default;
    },
  };

  organizationName = {
    get: () => this.data[GroupMetadataProperty.organization_name],
    set: () => {},
    hasValue: (lang?: string): boolean => {
      return lang
      ? !!this.data[GroupMetadataProperty.organization_name].i18n[lang]
      : !!this.data[GroupMetadataProperty.organization_name].default;
    },
    getText: (lang?: string) => {
      const i18nText = _.get(this.data, `${GroupMetadataProperty.organization_name}.i18n${lang}`, '')
      return i18nText || this.data[GroupMetadataProperty.organization_name].default;
    },
  };

  organizationUrl = {
    hasValue: () => !!this.data[GroupMetadataProperty.organization_url].default,
    get: () => this.data[GroupMetadataProperty.organization_url],
    getText: (lang?: string) => {
      const i18nText = _.get(this.data, `${GroupMetadataProperty.organization_url}.i18n${lang}`, '')
      return i18nText || this.data[GroupMetadataProperty.organization_url].default;
    },
  };

  researchFocused = {
    get: () => this.data[GroupMetadataProperty.research_focused],
  };

  theme = {
    get: () => {},
    set: () => {},
    hasValue: (): boolean => {
      return true;
    },
    isDefault: (): boolean => {
      const data = this.data[GroupMetadataProperty.theme].base_rgba;
      if (!data) { return false; }
      const { r, g, b, a } = data;
      return r === 216 && g === 27 && b === 81 && a === 1;
    },
    getBaseCssColor: () => {
      const data = this.data[GroupMetadataProperty.theme].base_rgba;
      if (!data) { return ''; }
      const { r, g, b, a } = data;
      return `rgba(${r},${g},${b},${a})`;
    },
    getDarkCssColor: () => {
      const data = this.data[GroupMetadataProperty.theme].accent_rgba;
      if (!data) { return ''; }
      const { r, g, b, a } = data;
      return `rgba(${r},${g},${b},${a})`;
    },
    getHighlightCssColor: () => {
      const data = this.data[GroupMetadataProperty.theme].highlight_rgba;
      if (!data) { return ''; }
      const { r, g, b, a } = data;
      return `rgba(${r},${g},${b},${a})`;
    },
  };

}
