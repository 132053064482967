import _ from 'lodash';

import ListsService from '../../../../apiServices/ListsService';
import actionNames from '../../actionNames';

const initialState = {
  isFetching: false,
  collection: [],
  lookup: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case actionNames.lists.REQUEST_LOCALE:
    case actionNames.lists.RECEIVE_LOCALE:
      return {...state, ...action.payload};
    case actionNames.lists.RECEIVE_ALL:
    case actionNames.lists.REQUEST_ALL:
      return {...state, ...action.payload.locale};
    default:
      return state;
  }
}


const helperMethods = {
  isFetching: (state, listName) => state.lists[listName].isFetching,
  isSameAs(state, listName, newData) {
    const { collection } = state.lists[listName];
    return _.isEqual(collection, newData);
  },
  hasData: (state, listName) => state.lists[listName].collection.length > 0,
};

function createCollectionAndLookup(apiError, responseCollection) {
  const lookup = {};
  let collection = [];

  if (!apiError) {
    collection = responseCollection;
    for (let i = 0; i < responseCollection.length; i++) {
      lookup[collection[i].id] = collection[i];
    }
  }

  return {
    collection,
    lookup,
  };
}

function requestLocaleList() {
  return (dispatch, getState) => {
    return new Promise((res) => {
      if (helperMethods.isFetching(getState(), 'locale') || helperMethods.hasData(getState(), 'locale')) { res(); return; }

      dispatch({
        type: actionNames.lists.REQUEST_LOCALE,
        payload: {
          isFetching: true,
          collection: [],
          lookup: {},
        },
      });

      ListsService.locale((apiError, responseCollection) => {
        if (helperMethods.isSameAs(getState(), 'locale', responseCollection)) { res(); return; }
        dispatch({
          type: actionNames.lists.RECEIVE_LOCALE,
          payload: {
            ...createCollectionAndLookup(apiError, responseCollection),
            isFetching: false,
            lastUpdated: new Date().getTime(),
          },
        });
        res();
      });
    });
  };
}


export const actions = {
  requestLocaleList,
};


function getClosestMatchingLocaleId(state, clientRegionalLanguages) {
  let exactMatchIndex = -1;
  let matchedLocale;
  const supportedLocaleList = [...state.lists.locale.collection];
  supportedLocaleList.sort((a, b) => a.id - b.id); // sort by ids so that first defined locale will be default

  const regions = clientRegionalLanguages.map(l => l.split('-')[0]);

  for (let i = 0; i < clientRegionalLanguages.length; i++) {
    const matches = supportedLocaleList.filter((l) => l.language_region === clientRegionalLanguages[i]);
    if (exactMatchIndex < 0 && matches.length > 0) {
      exactMatchIndex = i;
      matchedLocale = matches[0];
      break;
    }
  }

  for (let i = 0; i < regions.length; i++) {
    const matches = supportedLocaleList.filter((l) => l.language_region.split('-')[0] === regions[i]);
    if (matches.length > 0) {
      if (exactMatchIndex < 0 || i < exactMatchIndex) {
        matchedLocale = matches[0];
        break;
      }
    }
  }

  if (matchedLocale) {
    return matchedLocale.id;
  } else {
    return supportedLocaleList[0] ? supportedLocaleList[0].id : null;
  }
}

export const selectors = {
  getLocaleCollection: state => state.lists.locale.collection,
  getLocale: (localeId, state) => (state.lists.locale.lookup[localeId] || {}),
  getClosestMatchingLocaleId,
};
