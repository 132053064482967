import React from 'react';

import ShareCardIncludedValueToggleSwitch from '../../../components/ShareCardIncludedValueToggleSwitch';
import { ShareCardItemRow } from './ShareCardItemRow';
import ShareCardHealthEntrySummary from './ShareCardHealthEntrySummary';
import { HealthEntry } from '../../../../models/HealthEntry';
import ShareCardModel, { ShareCard } from '../../../../models/ShareCardModel';
import { ShareCardColorModel } from '../../../appAuthorized/dataTransformComponents/ColorShareCard';
import { ShareCardDefinition } from '../../../../models/ShareCardTemplateModel';

interface Props {
  allowIncludedValuesChange: boolean;
  healthEntry: HealthEntry;
  shareCardColorModel: ShareCardColorModel;
  languageCode: string;
  shareCardDefinition: ShareCardDefinition;
  shareCard: ShareCard;
  updateShareCard(data: ShareCard, cb): void;
}

const ShareCardRowEditHealthEntry: React.FC<Props> = (props) => {
  const { healthEntry, shareCardColorModel, languageCode, shareCardDefinition, shareCard , updateShareCard, allowIncludedValuesChange } = props;
  const healthEntryId = healthEntry.id;
  const includedValue = ShareCardModel.getIsIncluded(shareCard, healthEntryId);
  const isIncluded = !!includedValue;
  const isExcluded = !!ShareCardModel.getIsExcluded(shareCardDefinition, healthEntry.category, healthEntry.subType);
  const isRequired = !!ShareCardModel.getIsRequired(shareCardDefinition, healthEntry.category, healthEntry.subType);

  return (
    <ShareCardItemRow
      isExcluded={isExcluded}
      isRequired={isRequired}
      isSelected={isIncluded}
      onToggle={() => {
        updateShareCard(ShareCardModel.toggleIncluded(shareCard, shareCardDefinition, healthEntry), () => {});
      }}
      cssColor={shareCardColorModel.cssColor()}
      isSelectedContent={(
        allowIncludedValuesChange && includedValue
        ? <ShareCardIncludedValueToggleSwitch
            uniqueID={`${healthEntry.id}`}
            isDisabled={false}
            onToggle={() => {
              updateShareCard(ShareCardModel.toggleIncludedType(shareCard, healthEntryId), () => {});
            }}
            value={includedValue.included_values}
          />
        : null
      )}
    >
      <ShareCardHealthEntrySummary
        healthEntry={healthEntry}
        includedValues={includedValue ? includedValue.included_values : null}
        shareCardDefinition={shareCardDefinition}
        isSelected={isIncluded}
      />
    </ShareCardItemRow>
  );
};

export default ShareCardRowEditHealthEntry;
