import React from 'react';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  color?: string;
  className?: string;
}

const DEFAULT_COLOR = 'gsDarkest';

const getClassName = (props: Props) => {
  const { color = DEFAULT_COLOR } = props;
  return colorClassNames.stroke(color);
};

const Small = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px">
    <g id="icon-surveys" className={getClassName(props)} fill="none" strokeMiterlimit="10" strokeWidth="1.25px">
      <path d="M11.08,2.42h1.1a1.52,1.52,0,0,1,1.51,1.51v9.22a1.51,1.51,0,0,1-1.51,1.51H3.82a1.51,1.51,0,0,1-1.51-1.51V3.93A1.52,1.52,0,0,1,3.82,2.42h1.1"/>
      <path d="M7,1.34H9a2.2,2.2,0,0,1,2.2,2.2v.32a0,0,0,0,1,0,0H4.75a0,0,0,0,1,0,0V3.54A2.2,2.2,0,0,1,7,1.34Z"/>
      <path d="M8,11.11A.45.45,0,0,1,7.74,11l-.32-.24A10,10,0,0,1,6,9.48a1.51,1.51,0,0,1-.35-1.39,1.28,1.28,0,0,1,1.6-1A1.29,1.29,0,0,1,8,7.68a1.29,1.29,0,0,1,.77-.58,1.28,1.28,0,0,1,1.6,1A1.51,1.51,0,0,1,10,9.48a10,10,0,0,1-1.44,1.31c-.09.07-.2.16-.32.24a.45.45,0,0,1-.26.08Z"/>
    </g>
  </svg>
);

const Medium = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <g id="icon-surveys" className={getClassName(props)} fill="none" strokeMiterlimit="10" strokeWidth="1.5px">
      <path d="M16.7,3.49h1.68a2.31,2.31,0,0,1,2.31,2.3V19.87a2.31,2.31,0,0,1-2.31,2.3H5.62a2.31,2.31,0,0,1-2.31-2.3V5.79a2.31,2.31,0,0,1,2.31-2.3H7.3"/>
      <path d="M10.9,1.83h2.2A3.86,3.86,0,0,1,17,5.68v0a0,0,0,0,1,0,0H7a0,0,0,0,1,0,0v0A3.86,3.86,0,0,1,10.9,1.83Z"/>
      <path d="M12,16.75a.62.62,0,0,1-.4-.13,5.71,5.71,0,0,1-.49-.36,16,16,0,0,1-2.2-2,2.29,2.29,0,0,1-.53-2.12,2,2,0,0,1,2.44-1.52,2,2,0,0,1,1.18.89,2,2,0,0,1,1.18-.89,2,2,0,0,1,2.44,1.52,2.29,2.29,0,0,1-.53,2.12,16,16,0,0,1-2.2,2,5.71,5.71,0,0,1-.49.36.62.62,0,0,1-.4.13Z"/>
    </g>
  </svg>
);

const Large = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36px" height="36px">
    <g id="icon-surveys" className={getClassName(props)} fill="none" strokeMiterlimit="10" strokeWidth="2px">
      <path d="M24.93,5.45h2.48a3.4,3.4,0,0,1,3.4,3.39V29.6a3.4,3.4,0,0,1-3.4,3.4H8.59a3.4,3.4,0,0,1-3.4-3.4V8.84a3.4,3.4,0,0,1,3.4-3.39h2.48"/>
      <path d="M15.77,3h4.46a5.08,5.08,0,0,1,5.08,5.08v.61a0,0,0,0,1,0,0H10.69a0,0,0,0,1,0,0V8.08A5.08,5.08,0,0,1,15.77,3Z"/>
      <path d="M18,25a1.09,1.09,0,0,1-.6-.19l-.72-.54a22.07,22.07,0,0,1-3.23-3,3.35,3.35,0,0,1-.79-3.11A2.92,2.92,0,0,1,16.26,16,3,3,0,0,1,18,17.27,3,3,0,0,1,19.74,16a2.92,2.92,0,0,1,3.6,2.25,3.35,3.35,0,0,1-.79,3.11,22.07,22.07,0,0,1-3.23,3l-.72.54A1.09,1.09,0,0,1,18,25Z"/>
    </g>
  </svg>
);

const XLarge = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" width="56px" height="56px">
    <g id="icon-surveys" className={getClassName(props)} fill="none" strokeMiterlimit="10" strokeWidth="2.75px">
      <path d="M39,8.15h3.93a5.37,5.37,0,0,1,5.37,5.37V46.35a5.38,5.38,0,0,1-5.37,5.37H13.12a5.38,5.38,0,0,1-5.37-5.37V13.52a5.37,5.37,0,0,1,5.37-5.37h3.93"/>
      <path d="M24.81,4.28h6.39a8.37,8.37,0,0,1,8.37,8.37v.63a0,0,0,0,1,0,0H16.44a0,0,0,0,1,0,0v-.63A8.37,8.37,0,0,1,24.81,4.28Z"/>
      <path d="M28,39.08a1.57,1.57,0,0,1-.94-.3c-.4-.28-.82-.59-1.14-.84a37.22,37.22,0,0,1-5.12-4.68,5.33,5.33,0,0,1-1.24-4.93,4.6,4.6,0,0,1,5.68-3.55A4.78,4.78,0,0,1,28,26.85a4.78,4.78,0,0,1,2.76-2.07,4.6,4.6,0,0,1,5.68,3.55,5.33,5.33,0,0,1-1.24,4.93,37.22,37.22,0,0,1-5.12,4.68c-.32.25-.74.56-1.14.84a1.57,1.57,0,0,1-.94.3Z"/>
    </g>
  </svg>
);

const IconSurveys = {
  Small,
  Medium,
  Large,
  XLarge,
};

export default IconSurveys;
