import React from 'react';
import classnames from 'classnames';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  color: string;
  className: string;
}

const DEFAULT_COLOR = 'gsDarkest';

const getClassName = (props: Props) => {
  const { color = DEFAULT_COLOR } = props;
  return props.className || classnames(colorClassNames.fill(color));
};

const Small = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px">
    <g id="icon-arrow-left" className={getClassName(props)}>
      <path d="M13,7.25H5l2-1.77a.75.75,0,0,0,.25-.57.76.76,0,0,0-.18-.49.75.75,0,0,0-1.06-.08L2.51,7.43s0,0,0,0a.83.83,0,0,0-.13.18.43.43,0,0,0,0,.07.68.68,0,0,0,0,.6.43.43,0,0,0,0,.07.83.83,0,0,0,.13.18l0,0,3.55,3.09a.76.76,0,0,0,1.06-.08.74.74,0,0,0,.18-.49.72.72,0,0,0-.25-.56L5,8.75h8a.75.75,0,0,0,0-1.5Z"/>
    </g>
  </svg>
);

const Medium = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <g id="icon-arrow-left" className={getClassName(props)}>
      <path d="M20,11H6.67l3.67-3.19a1,1,0,0,0,.35-.75,1,1,0,0,0-.25-.66A1,1,0,0,0,9,6.3L3.34,11.24l0,0a1,1,0,0,0-.17.24s0,.05-.06.09a.93.93,0,0,0,0,.8s0,.06.06.09a1,1,0,0,0,.17.24l0,0L9,17.7a1,1,0,0,0,.66.25,1,1,0,0,0,.75-.35,1,1,0,0,0,.25-.65,1,1,0,0,0-.35-.76L6.67,13H20a1,1,0,0,0,0-2Z"/>
    </g>
  </svg>
);

const Large = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36px" height="36px">
    <g id="icon-arrow-left" className={getClassName(props)}>
      <path d="M30,16.75H9.343l6.01-5.226a1.25,1.25,0,1,0-1.641-1.886L5.18,17.057a.562.562,0,0,0-.041.044c-.017.015-.03.032-.046.049a1.239,1.239,0,0,0-.237.352c-.006.013-.008.027-.014.041a1.18,1.18,0,0,0,0,.916c0,.013.007.026.012.038a1.255,1.255,0,0,0,.239.354c.015.016.028.033.045.048a.562.562,0,0,0,.041.044l8.532,7.419a1.25,1.25,0,0,0,1.641-1.886L9.343,19.25H30a1.25,1.25,0,0,0,0-2.5Z"/>
    </g>
  </svg>
);

const XLarge = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" width="56px" height="56px">
    <g id="icon-arrow-left" className={getClassName(props)}>
      <path d="M45.84,26.5H14.11l9.68-8.42A1.49,1.49,0,0,0,24.31,17a1.46,1.46,0,0,0-.37-1,1.5,1.5,0,0,0-2.12-.15L9.12,26.87l0,0L9,27l-.08.09-.1.17a.41.41,0,0,1,0,.09,1.87,1.87,0,0,0-.07.22.24.24,0,0,0,0,.08,1.22,1.22,0,0,0,0,.62.24.24,0,0,0,0,.08,1.29,1.29,0,0,0,.07.21.47.47,0,0,1,0,.1l.1.17L9,29l.13.14,0,0,12.7,11.05A1.5,1.5,0,0,0,23.94,40a1.46,1.46,0,0,0,.37-1,1.52,1.52,0,0,0-.52-1.14L14.11,29.5H45.84a1.5,1.5,0,0,0,0-3Z"/>
    </g>
  </svg>
);

const IconArrowLeft = {
  Small,
  Medium,
  Large,
  XLarge,
};

export default IconArrowLeft;
