export enum DateOfBirthPrecision {
  P1D = 'P1D',
  P1Y = 'P1Y',
}

export interface RGB {
  r: number;
  g: number;
  b: number;
}

export interface RGBA extends RGB {
  a: number;
}

export function rgba2rgb({ r, g, b, a }: RGBA): RGB {
  const bg: RGB = { r: 255, g: 255, b: 255 };

  return {
    r: (1 - a) * bg.r + a * r,
    g: (1 - a) * bg.g + a * g,
    b: (1 - a) * bg.b + a * b,
  };
}

export function rgb2css({ r, g, b }: RGB) {
  return `rgb(${r},${g},${b})`;
}
