
export enum PolicyName {
  BACKPACK_EMAIL = 'dataElement.backpackEmail',
  CONTACT_US = 'dataElement.contactUs',
  DATE_OF_BIRTH = 'dataElement.dateOfBirth',
  EMAIL = 'dataElement.email',
  GENDER = 'dataElement.gender',
  HEALTH_INFO = 'dataElement.healthInfo',
  NAME = 'dataElement.name',
  RACE = 'dataElement.race',
  RESIDENCE = 'dataElement.residence',
  THIRD_PARTY_EMAIL = 'dataElement.thirdPartyEmail',
  GROUP_CONSENT = 'document.groupConsent',
  PRIVACY = 'document.privacy',
  TERMS = 'document.terms',
  COOKIE = 'document.cookie',
  ACKNOWLEDGEMENTS = 'document.acknowledgements',
  INFORMED_CONSENT = 'document.informedConsent',
  INFORMED_CONSENT_ADULT_CAN_ASSENT = 'dataElement.informedConsent.adultCanAssent',
  INFORMED_CONSENT_ADULT_CANNOT_ASSENT = 'dataElement.informedConsent.adultCannotAssent',
  INFORMED_CONSENT_CHILD_CAN_ASSENT = 'dataElement.informedConsent.childCanAssent',
  INFORMED_CONSENT_CHILD_CANNOT_ASSENT = 'dataElement.informedConsent.childCannotAssent',
  INFORMED_CONSENT_USER = 'dataElement.informedConsent.user',
  DEPENDENT_AUTHORIZATION = 'dataElement.dependentAuth',
  MFA_PHONE_NUMBER = 'dataElement.2FAPhoneNumber',
}

export enum ConsentType {
  WITHHELD = 'withheld',
  PROVIDED = 'provided',
}

export interface PolicyConsentInterface {
  id: number;
  consent_type: ConsentType;
}

export interface PolicyLocalizationInterface {
  title: string;
  text: string;
  summary: string;
  created_at: Date;
}

export interface PolicyInterface {
  id: number;
  name: string;
  data_element: string;
  version: string;
  created_at: Date;
  i18n: {
    [key: string]: PolicyLocalizationInterface,
  };
}
