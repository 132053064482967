import { request } from './request';

import { ConsentPayloadInterface } from '../models/ConsentModel';

const consentService = {
  getAll(profileId): Promise<ConsentPayloadInterface> {
    return request('get', `/profiles/${profileId}/consent`);
  },
};

export default consentService;
