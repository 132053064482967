
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../../components/ScrollAware';
import ContentFrame from '../../../applicationFrame/ContentFrame';
import { AppHeaderScrollableText } from '../../../components/appNav/AppHeaderScrollable';
import ContentWrapper from '../../../components/ContentWrapper';
import RouteNames from '../../../appAuthorized/RouteNames';

import { Card, CardRowUI, Inputs } from '../../../../bphComponents/bundle';
import ContactModel, { ContactDetailType, ContactModelType } from '../../../../models/ContactModel';
import ContentLoader from '../../../components/ContentLoader';
import ContactLabel from '../../../components/ContactLabel';
import PhoneInput from '../../../components/PhoneInput';
import DeleteOrReconsiderWarningModal from '../../../components/modal/DeleteOrReconsiderWarningModal';
import { ContactHealthcareProxyText } from '../../../components/ContactSummaryText';
import COLORS from '../../../styles/Colors';

import { useUIState } from '../../../hooks/useUIState';
import { useActiveProfile } from '../../../hooks/useProfile';
import { useUIEditContact } from '../../../hooks/useUIEdit';
import { useContactSpecialtyList, useLists } from '../../../hooks/useLists';
import { useNavigate } from '../../../hooks/useNavigate';

interface EditDetailsProps {
  onRemove(): void;
  onChange(data: ContactDetailType): void;
  data: ContactDetailType;
  contactId: number | string;
  autoFocus?: boolean;
}

const EditPhone: React.FC<EditDetailsProps> = (props) => {
  const { data, onChange, onRemove, contactId, autoFocus } = props;
  const phoneNumber = data.phone.number;
  const countryCode = data.phone.country_code;
  const contactLabelId = data.contact_detail_label_id;
  const detailId = data.id;
  const [, navActions] = useNavigate();

  return (
    <CardRowUI.RemovableInlineItem onRemove={onRemove}>
      <PhoneInput
        label={(
          <Inputs.ChangeLabel
            label={<ContactLabel id={contactLabelId} defaultName="phone_phone" />}
            changeLabelText={<FormattedMessage id="contact.changeLabel.button.label" />}
            onChangeLabel={() => { navActions.goTo(RouteNames.contactPhone(contactId.toString(), detailId.toString())); }}
          />
        )}
        countryCode={countryCode}
        phoneNumber={phoneNumber}
        ignoreValidation={true}
        autoFocus={autoFocus}
        preventFloating={true}
        isClearable={true}
        onChange={(newPhoneData) => {
          const d = {
            ...data,
            phone: {
              country_code: newPhoneData.countryCode,
              number: newPhoneData.formattedNumber,
            },
          };
          onChange(d);
        }}
        countryLabel={<FormattedMessage id="contactInfo.phoneCountryCode.field.label" />}
        className="font-color-profile-accent"
      />
    </CardRowUI.RemovableInlineItem>
  );
};

const EditEmail: React.FC<EditDetailsProps> = (props) => {
  const { data, onChange, onRemove, contactId, autoFocus } = props;
  const detailId = data.id;
  const [, navActions] = useNavigate();
  return (
    <CardRowUI.RemovableInlineItem onRemove={onRemove}>
      <Inputs.TextInput
        label={(
          <Inputs.ChangeLabel
            label={<ContactLabel id={data.contact_detail_label_id} defaultName="email_email" />}
            changeLabelText={<FormattedMessage id="contact.changeLabel.button.label" />}
            onChangeLabel={() => { navActions.goTo(RouteNames.contactEmail(contactId.toString(), detailId.toString())); }}
          />
        )}
        value={data.email.address}
        autoCapitalize="none"
        preventFloating={true}
        isClearable={true}
        autoFocus={autoFocus}
        onChange={(value) => {
          const d = {
            ...data,
            email: {
              address: value,
            },
          };
          onChange(d);
        }}
      />
    </CardRowUI.RemovableInlineItem>
  );
};

const EditAddress: React.FC<EditDetailsProps> = (props) => {
  const { data, onChange, onRemove, contactId, autoFocus } = props;
  const detailId = data.id;
  const [, navActions] = useNavigate();
  return (
    <CardRowUI.RemovableInlineItem onRemove={onRemove}>
      <Inputs.TextArea
        label={(
          <Inputs.ChangeLabel
            label={<ContactLabel id={data.contact_detail_label_id} defaultName="address_address" />}
            changeLabelText={<FormattedMessage id="contact.changeLabel.button.label" />}
            onChangeLabel={() => { navActions.goTo(RouteNames.contactAddress(contactId.toString(), detailId.toString())); }}
          />
        )}
        value={data.address.detail}
        autoFocus={autoFocus}
        autoCapitalize="words"
        onChange={(value) => {
          const d = {
            ...data,
            address: {
              detail: value,
            },
          };
          onChange(d);
        }}
      />
    </CardRowUI.RemovableInlineItem>
  );
};

interface CommentProps {
  value: string;
  onChange(comment): void;
}
const EditComment: React.FC<CommentProps> = (props) => {
  return (
    <Card.CardContent hasBottomBorder={true}>
      <Inputs.TextArea
        label={<FormattedMessage id="contact.comments.field.label" />}
        value={props.value}
        onChange={props.onChange}
      />
    </Card.CardContent>
  );
};

const EditWebsite: React.FC<EditDetailsProps> = (props) => {
  const { data, onChange, onRemove, contactId, autoFocus } = props;
  const detailId = data.id;
  const [, navActions] = useNavigate();
  return (
    <CardRowUI.RemovableInlineItem onRemove={onRemove}>
      <Inputs.TextInput
        label={(
          <Inputs.ChangeLabel
            label={<ContactLabel id={data.contact_detail_label_id} defaultName="website_website" />}
            changeLabelText={<FormattedMessage id="contact.changeLabel.button.label" />}
            onChangeLabel={() => { navActions.goTo(RouteNames.contactWebsite(contactId.toString(), detailId.toString())); }}
          />
        )}
        value={data.website.url}
        autoFocus={autoFocus}
        autoCapitalize="none"
        preventFloating={true}
        isClearable={true}
        onChange={(value) => {
          const d = {
            ...data,
            website: {
              url: value,
            },
          };
          onChange(d);
        }}
      />
    </CardRowUI.RemovableInlineItem>
  );
};

interface ContactSpecialtyProps {
  contactId: number | string;
  contactData: ContactModelType;
}
const ContactSpecialty: React.FC<ContactSpecialtyProps> = (props) => {
  const { contactId, contactData } = props;

  const [{ contactSpecialty }] = useContactSpecialtyList(contactData.contact_specialty_id);
  const [, navActions] = useNavigate();
  let value = null;
  if (contactSpecialty) {
    value = contactSpecialty.is_custom
      ? contactData.specialty_custom_text
      : <FormattedMessage id={contactSpecialty.key} />;
  }

  return (
    <NavigationRow
      onClick={() => { navActions.goTo(RouteNames.contactSpecialty(contactId.toString())); }}
      hasValue={!!value}
      value={value}
      noValue={<FormattedMessage id="provider.addSpecialty.button.label" />}
    />
  );
};

interface NavigationRowProps {
  hasValue: boolean;
  value: any;
  noValue: any;
  onClick(): void;
}
const NavigationRow: React.FC<NavigationRowProps> = (props) => {
  return (
    <CardRowUI.Navigation onClick={props.onClick} hasBottomBorder={true}>
      {
        props.hasValue
          ? <div className="font-color-gsDarkest">{props.value}</div>
          : <div className="font-color-gsDark">{props.noValue}</div>
      }
    </CardRowUI.Navigation>
  );
};
interface ContactRelationshipProps {
  contactData: ContactModelType;
}
const ContactRelationship: React.FC<ContactRelationshipProps> = (props) => {
  const { contactData } = props;
  const [{ contactRelationshipLookup }] = useLists();
  const contactRelationshipValue = contactRelationshipLookup[contactData.contact_relationship_id];
  const [, navActions] = useNavigate();

  let value = null;
  if (contactRelationshipValue) {
    value = contactRelationshipValue.is_custom
      ? contactData.relationship_custom_text
      : <FormattedMessage id={contactRelationshipValue.key} />;
  }

  return (
    <NavigationRow
      onClick={() => {
        navActions.goTo(RouteNames.contactRelationship(contactData.id));
      }}
      hasValue={!!value}
      value={value}
      noValue={<FormattedMessage id="contact.addRelationship.button.label" />}
    />
  );
};

interface ContactHealthcareProxyProps {
  contactData: ContactModelType;
}
const ContactHealthcareProxy: React.FC<ContactHealthcareProxyProps> = (props) => {
  const { contactData } = props;
  const [, navActions] = useNavigate();
  return (
    <NavigationRow
      onClick={() => { navActions.goTo(RouteNames.contactHealthcareProxy(contactData.id)); }}
      hasValue={ContactModel.hasHealthcareProxy(contactData)}
      value={<ContactHealthcareProxyText data={contactData} />}
      noValue={<FormattedMessage id="contact.addHealthProxy.button.label" />}
    />
  );
};

interface ContactDetailsProps {
  onAdd(): void;
  data: ContactDetailType[];
  onChange(data): void;
  onRemove(data): void;
  // contactDetailLabel: any[];
  contactId: number | string;
  autoFocusId?: number;
}

// function detailLabelMapStateToProps(state) {
//   return {
//     contactDetailLabel: listSelectors.getContactDetailLabelCollection(state),
//   };
// }
const PhoneSection: React.FC<ContactDetailsProps> = (props) => {

  return (
    <Card.CardSection hasBottomBorder={true}>
      {
        props.data.map((n) => (
          <EditPhone
            key={n.id}
            data={n}
            contactId={props.contactId}
            onRemove={() => { props.onRemove(n); }}
            onChange={props.onChange}
            autoFocus={n.id === props.autoFocusId}
          />
        ))
      }
      <CardRowUI.AddInlineItem onClick={props.onAdd} hasBottomBorder={true}>
        <FormattedMessage id="contact.addPhone.button.label" />
      </CardRowUI.AddInlineItem>
    </Card.CardSection>
  );
};

const EmailSection: React.FC<ContactDetailsProps> = (props) => {
  return (
    <Card.CardSection hasBottomBorder={true}>
      {
        props.data.map((n) => (
          <EditEmail
            key={n.id}
            data={n}
            contactId={props.contactId}
            onRemove={() => { props.onRemove(n); }}
            onChange={props.onChange}
            autoFocus={n.id === props.autoFocusId}
          />
        ))
      }

      <CardRowUI.AddInlineItem onClick={props.onAdd} hasBottomBorder={true}>
        <FormattedMessage id="contact.addEmail.button.label" />
      </CardRowUI.AddInlineItem>
    </Card.CardSection>
  );
};

const WebsiteSection: React.FC<ContactDetailsProps> = (props) => {
  return (
    <Card.CardSection hasBottomBorder={true}>
      {
        props.data.map((n) => (
          <EditWebsite
            key={n.id}
            data={n}
            contactId={props.contactId}
            onRemove={() => { props.onRemove(n); }}
            onChange={props.onChange}
            autoFocus={n.id === props.autoFocusId}
          />
        ))
      }

      <CardRowUI.AddInlineItem onClick={props.onAdd} hasBottomBorder={true}>
        <FormattedMessage id="contact.addWebsite.button.label" />
      </CardRowUI.AddInlineItem>
    </Card.CardSection>
  );
};

const AddressSection: React.FC<ContactDetailsProps> = (props) => {
  return (
    <Card.CardSection hasBottomBorder={true}>
      {
        props.data.map((n) => (
          <EditAddress
            key={n.id}
            data={n}
            contactId={props.contactId}
            onRemove={() => { props.onRemove(n); }}
            onChange={props.onChange}
            autoFocus={n.id === props.autoFocusId}
          />
        ))
      }

      <CardRowUI.AddInlineItem onClick={props.onAdd} hasBottomBorder={true}>
        <FormattedMessage id="contact.addAddress.button.label" />
      </CardRowUI.AddInlineItem>
    </Card.CardSection>
  );
};

interface ContactCurrentPastProps {
  value: boolean;
  onChange(data): void;
  intl: any;
}

const BasicContactCurrentPast: React.FC<ContactCurrentPastProps> = (props) => {
  const { value, onChange, intl } = props;
  return (
    <Inputs.ToggleSwitch.ProfileThemedToggleSwitch
      value={value}
      choices={[
        {
          name: 'current',
          value: true,
          label: intl.formatMessage({ id: 'toggleCurrentPast.current.option.label' }),
        },
        {
          name: 'past',
          value: false,
          label: intl.formatMessage({ id: 'toggleCurrentPast.past.option.label' }),
        },
      ]}
      onChange={() => { onChange(!value); }}
      isOff={!value}
    />
  );
};

const getReconsiderPhrase = (isMainUser, isCurrent, greetingName) => {
  const body = [];
  let button = {};
  let alternateButton = {};

  if (isCurrent) {
    body.push({ id: 'providerRemoveConfirm.makePastProvider.message.pre' });
    button = { id: 'providerRemoveConfirm.makePastProvider.button.label' };
    alternateButton = { id: 'providerRemoveConfirm.delete.button.label' };
  } else {
    button = { id: 'providerRemoveConfirm.delete.button.label' };
    alternateButton = { id: 'providerRemoveConfirm.makePastprovider.button.label' };
  }

  if (isMainUser) {
    body.push({ id: 'providerRemoveConfirm.user.message.pre' });
  } else {
    body.push({ id: 'providerRemoveConfirm.notUser.message.pre', values: { string1: greetingName } });
  }

  return {
    title: { id: 'providerRemoveConfirm.heading' },
    body,
    button,
    alternateButton,
  };
};

const ContactCurrentPast = injectIntl(BasicContactCurrentPast);

interface ContactFormProps {
  contact: any;
  contactId: number;
  onSubmit(data): void;
  onCancel(): void;
  onDelete?(): void;
  titlePhrase: any;
}

const ContactForm: React.FC<ContactFormProps> = (props) => {
  const { contact, contactId, titlePhrase, onCancel, onDelete, onSubmit } = props;
  const internalContactId = contactId || 'add';

  const [{ isActiveProfileMainProfile }] = useUIState();
  const [{ greetingName }, profileActions] = useActiveProfile();
  const [, uiActions] = useUIState();

  const [{ isLoaded }, uiEditActions] = useUIEditContact(contactId);
  const [{ contactDetailLabelList }] = useLists();

  const details = ContactModel.groupDetails(contact);
  const isCurrent = ContactModel.isCurrent(contact);
  const isProvider = ContactModel.isProvider(contact);
  const isRelationshipDefined = ContactModel.isRelationshipDefined(contact);
  const showProvider = isRelationshipDefined && isProvider;
  const showNonProvider = isRelationshipDefined && !isProvider;

  const [autoFocusId, setAutoFocusId] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isSubmittted, setIsSubmitted] = React.useState(false);

  const submitChanges = () => {
    if (isSubmittted) { return; }

    const dataValidity = ContactModel.validateData(contact);

    if (dataValidity.isValid) {
      setIsSubmitted(true);
      onSubmit(contact);
    } else {
      uiActions.showNotification(dataValidity.msg, COLORS.gsMedDark);
    }
  };

  const updateDetail = (newDetail) => {
    uiEditActions.updateContact(ContactModel.replaceDetail(contact, newDetail));
  };

  const addDetail = (detailType) => {
    const extraData: any = {};
    if (detailType === 'phone') {
      extraData.country_code = profileActions.getDefaultCountryISO2();
    }
    const [newData, newDetail] = ContactModel.addDetail(contact, detailType, contactDetailLabelList, extraData);

    uiEditActions.updateContact(newData);
    setAutoFocusId(newDetail.id);
  };

  const removeDetail = (detail) => {
    uiEditActions.updateContact(ContactModel.removeDetail(contact, detail));
  };

  return (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={!ContactModel.validateData(contact).isValid || isSubmittted}
          onDisabledClick={submitChanges}
          cancel={() => { onCancel(); }}
          done={submitChanges}
          color="white"
          hasDropShadow={true}
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText {...titlePhrase} />

            <ContentWrapper>
            <ContentLoader isContentLoaded={isLoaded} blockRenderUntilLoaded={!isLoaded} isFixed={true}>

            <Card.CardShape>
              <Card.CardSection hasBottomBorder={true}>
                <Card.CardContent>
                  <Inputs.TextInput
                    label={<FormattedMessage id="contacts.name.field.label" />}
                    value={contact.name}
                    isClearable={true}
                    autoCapitalize="words"
                    onChange={(name) => {
                      uiEditActions.updateContact({ ...contact, name });
                    }}
                    onClear={() => {
                      uiEditActions.updateContact({ ...contact, name: '' });
                    }}
                  />
                </Card.CardContent>
              </Card.CardSection>

              <Card.CardSection hasBottomBorder={true}>
                <ContactRelationship contactData={contact} />
              </Card.CardSection>
            </Card.CardShape>

            {
              showNonProvider && (
                <Card.CardShape>
                  <Card.CardSection hasBottomBorder={true}>
                    <ContactHealthcareProxy contactData={contact} />
                  </Card.CardSection>
                </Card.CardShape>
              )
            }

            {
              showProvider && (
                <Card.CardShape>
                  <Card.CardSection hasBottomBorder={true}>
                    <Card.CardContent>
                      <ContactCurrentPast
                        value={contact.current}
                        onChange={(current) => {
                          uiEditActions.updateContact({ ...contact, current });
                        }}
                      />

                      <div className="font-footnote font-color-gsMedDark" style={{ marginTop: 16 }}>
                        <FormattedMessage id="contact.currentPast.field.hint" />
                      </div>
                    </Card.CardContent>
                  </Card.CardSection>

                  <Card.CardSection hasBottomBorder={true}>
                    <ContactSpecialty contactId={internalContactId} contactData={contact} />
                  </Card.CardSection>
                </Card.CardShape>
              )
            }

              <Card.CardShape>
                <PhoneSection
                  data={details.phone}
                  contactId={internalContactId}
                  onAdd={() => { addDetail('phone'); }}
                  onChange={updateDetail}
                  onRemove={removeDetail}
                  autoFocusId={autoFocusId}
                />
                <EmailSection
                  data={details.email}
                  contactId={internalContactId}
                  onAdd={() => { addDetail('email'); }}
                  onChange={updateDetail}
                  onRemove={removeDetail}
                  autoFocusId={autoFocusId}
                />
                <AddressSection
                  data={details.address}
                  contactId={internalContactId}
                  onAdd={() => { addDetail('address'); }}
                  onChange={updateDetail}
                  onRemove={removeDetail}
                  autoFocusId={autoFocusId}
                />
                <WebsiteSection
                  data={details.website}
                  contactId={internalContactId}
                  onAdd={() => { addDetail('website'); }}
                  onChange={updateDetail}
                  onRemove={removeDetail}
                  autoFocusId={autoFocusId}
                />
              </Card.CardShape>

              <Card.CardShape>
                <Card.CardSection>
                  <EditComment
                    value={contact.comment || ''}
                    onChange={(comment) => {
                      uiEditActions.updateContact({ ...contact, comment });
                    }}
                  />
                </Card.CardSection>
              </Card.CardShape>

              {
                onDelete && (
                  <>
                  <Card.CardButton className="font-color-csError" onClick={() => { setShowDeleteModal(true); }}>
                    <FormattedMessage id="contact.delete.button.label" />
                  </Card.CardButton>

                  <DeleteOrReconsiderWarningModal
                    isVisible={showDeleteModal}
                    shouldReconsider={isCurrent}
                    deleteModal={{
                      phraseMessages: {},
                      onConfirm: () => {
                        onDelete();
                        setShowDeleteModal(false);
                      },
                      onCancel: () => {
                        setShowDeleteModal(false);
                      },
                    }}
                    reconsiderModal={{
                      phraseMessages: getReconsiderPhrase(isActiveProfileMainProfile, isCurrent, greetingName),
                      onConfirm: () => {
                        setShowDeleteModal(false);
                        onSubmit(ContactModel.setCurrent(contact, false));
                      },
                      onAlternate: () => {
                        onDelete();
                        setShowDeleteModal(false);
                      },
                      onCancel: () => {
                        setShowDeleteModal(false);
                      },
                    }}
                  />
                  </>
                )
              }

            </ContentLoader>
            </ContentWrapper>
          </ScrollAwareScroller>

        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
};

export default ContactForm;
