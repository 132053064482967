import React from 'react';
import { FormattedMessage } from 'react-intl';

import { GreenWhiteToggleSwitch } from '../../../components/ToggleSwitch';
import FlexRow from '../../../components/FlexRow';
import { iTrackable } from '../../../../apiServices/apiModels/Trackable';
import { Card } from '../../../../bphComponents/bundle';

interface Props {
  isEnabled: boolean;
  onToggle(): void;
}
const OccurrenceShortcut: React.FC<Props> = (props) => {
  const { isEnabled, onToggle } = props;

  return (
    <Card.CardContent hasBottomBorder={true}>
      <FlexRow>
        <div>
          <FormattedMessage id="heOccList.trackingShortcut.toggle.label" />
        </div>
        <GreenWhiteToggleSwitch
          choices={[
            {
              name: 'disabled',
              value: false,
            },
            {
              name: 'enabled',
              value: true,
            },
          ]}
          isOff={!isEnabled}
          value={isEnabled}
          onChange={onToggle}
        />
      </FlexRow>
    </Card.CardContent>
  );
};

interface iTrackableShortcutProps {
  trackable: iTrackable;
  onToggleShortcut(): void;
}
export const TrackableShortcut: React.FC<iTrackableShortcutProps> = (props) => {
  const { trackable, onToggleShortcut } = props;

  return (
    <OccurrenceShortcut
      isEnabled={trackable.shortcut_enabled}
      onToggle={onToggleShortcut}
    />
  );
};
