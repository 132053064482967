
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Card, Icons } from '../../../../bphComponents/bundle';

import COLORS from '../../../styles/Colors';
import { HEFileAttachmentCollection } from './HEPropertyCollection';
import RouteNames from '../../../appAuthorized/RouteNames';
import AddMenuAttachments from '../../../components/modal/addMenu/AddMenuAttachments';
import { useUIState } from '../../../hooks/useUIState';
import { useNavigate } from '../../../hooks/useNavigate';
import { useAttachmentsForParentItem } from '../../../hooks/useAttachments';
import { useActiveUser } from '../../../hooks/useUser';
import { iTrackable, iObservation } from '../../../../apiServices/apiModels/Trackable';
import { attachmentTypes } from '../../../../models/AttachmentModel';

interface Props {
  files: any[];
  onSelect(f): void;
  onCreate(): void;
  onDelete(f): void;
}

export const OccurrenceFiles: React.FC<Props> = (props) => {
  const { files, onSelect, onCreate, onDelete } = props;

  const [{ preferredLanguageCode }] = useActiveUser();

  return (
    <>
    <Card.ExternalCardTitle>
      <FormattedMessage id="healthEntry.Files.label" />
      <Icons.IconFile.Small color={COLORS.gsMedDark} />
    </Card.ExternalCardTitle>
    <Card.CardShape>
    {
      files.length === 0
      ? (
        <Card.CardContent className="font-body font-color-gsMedDark" onClick={onCreate}>
          <FormattedMessage id="files.attachFile.button.label" />
        </Card.CardContent>
      ) : (
        <HEFileAttachmentCollection
          icon={<Icons.IconFile.Medium color={COLORS.healthEntryPropertyIconWithValue} />}
          languageCode={preferredLanguageCode}
          collection={files}
          getValue={null}
          getSubTitle={null}
          addAnotherValue={<FormattedMessage id="files.attachAnotherFile.button.label" />}
          onClick={onSelect}
          onCreate={onCreate}
          onRemove={onDelete}
        />
      )
    }
    </Card.CardShape>
    </>
  );

};

interface iHealthEntryFiles {
  healthEntry: any;
  parentHealthEntryId: number;
}
export const HealthEntryFiles: React.FC<iHealthEntryFiles> = (props) => {
  const { healthEntry, parentHealthEntryId } = props;
  const healthEntryId = healthEntry.id;

  const [, uiActions] = useUIState();
  const [, navActions] = useNavigate();
  const attachmentType = parentHealthEntryId ? 'health_entry_value' : 'health_entry';
  const [{ files }, attachmentActions] = useAttachmentsForParentItem(attachmentType, healthEntryId);

  return (
    <OccurrenceFiles
      files={files}
      onSelect={(file) => {
        navActions.goTo(RouteNames.file(file.id));
      }}
      onCreate={() => {
        if (parentHealthEntryId) {
          uiActions.pushDarkBigBlur(
            <AddMenuAttachments
              attachmentType={attachmentTypes.healthEntryValue}
              parentId={healthEntryId}
            />
          );
        } else {
          uiActions.pushDarkBigBlur(
            <AddMenuAttachments
              attachmentType={attachmentTypes.healthEntry}
              parentId={healthEntryId}
            />
          );
        }
      }}
      onDelete={(file) => {
        const attachment = attachmentActions.getFileAttachment(file.id);
        attachmentActions.apiDeleteAttachment(attachment);
      }}
    />
  );
};

interface iTrackableFiles {
  trackable: iTrackable;
}
export const TrackableFiles: React.FC<iTrackableFiles> = (props) => {
  const { trackable } = props;

  const [, uiActions] = useUIState();
  const [, navActions] = useNavigate();
  const [{ files }, attachmentActions] = useAttachmentsForParentItem(attachmentTypes.trackable, trackable.id);

  return (
    <OccurrenceFiles
      files={files}
      onSelect={(file) => {
        navActions.goTo(RouteNames.file(file.id));
      }}
      onCreate={() => {
        uiActions.pushDarkBigBlur(
          <AddMenuAttachments
            attachmentType={attachmentTypes.trackable}
            parentId={trackable.id}
          />
        );
      }}
      onDelete={(file) => {
        const attachment = attachmentActions.getFileAttachment(file.id);
        attachmentActions.apiDeleteAttachment(attachment);
      }}
    />
  );
};

interface iObservationFiles {
  observation: iObservation;
}
export const ObservationFiles: React.FC<iObservationFiles> = (props) => {
  const { observation } = props;

  const [, uiActions] = useUIState();
  const [, navActions] = useNavigate();
  const [{ files }, attachmentActions] = useAttachmentsForParentItem(attachmentTypes.trackableObservation, observation.id);

  return (
    <OccurrenceFiles
      files={files}
      onSelect={(file) => {
        navActions.goTo(RouteNames.file(file.id));
      }}
      onCreate={() => {
        uiActions.pushDarkBigBlur(
          <AddMenuAttachments
            attachmentType={attachmentTypes.trackableObservation}
            parentId={observation.id}
          />
        );
      }}
      onDelete={(file) => {
        const attachment = attachmentActions.getFileAttachment(file.id);
        attachmentActions.apiDeleteAttachment(attachment);
      }}
    />
  );
};
