import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CardRowUI } from '../../../../bphComponents/bundle';

export interface DetailLabelType {
  id: number;
  key: string;
  name: string;
  other: number;
}

interface SelectableDetailLabelProps {
  data: DetailLabelType;
  onClick(id): void;
  isSelected: boolean;
}

const SelectableDetailLabel: React.FC<SelectableDetailLabelProps> = (props) => {
  const { data, isSelected, onClick } = props;
  return (
    <CardRowUI.SelectableRow
      isSelected={isSelected}
      onClick={() => { onClick(data.id); }}
      hasBottomBorder={true}
      selectedClassName="fill-color-profile-highlight"
    >
      <FormattedMessage id={data.key} />
    </CardRowUI.SelectableRow>
  );
};

export default SelectableDetailLabel;
