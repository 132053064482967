
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './RegisteredJewelry.scss';

import SquareImageIcon from '../../../components/SquareImageIcon';
import COLORS from '../../../styles/Colors';
import { IRegisteredJewelry } from '../../../../models/RegisteredJewelry';
import { ShareCardColorModel } from '../../../appAuthorized/dataTransformComponents/ColorShareCard';

interface IRegisteredJewelryProps {
  data: IRegisteredJewelry;
  onRemove?(id): void;
  shareCardColorModel: ShareCardColorModel;
}

const RegisteredJewelry = (props: IRegisteredJewelryProps) => {
  const { data, onRemove, shareCardColorModel } = props;
  const { icon, value } = data;
  const fontColor = shareCardColorModel.darkAccentColor();

  return (
    <div className="cmp-registeredjewelry" style={{ }}>
      <div className="_title">
        <SquareImageIcon
          src={icon}
          size={24}
          noBorder={true}
          hasDropshadow={true}
          backgroundColor={COLORS.bcBackpackRedBase}
          placeholderText={value ? value[0] : ''}
          altText="logo"
        />

        <FormattedMessage id="registerJewelry.stickyJ.baseURLID.text" />  {value}
      </div>

      {
        onRemove && (
          <button className="font-caption-1" style={{ color: fontColor }} onClick={() => { onRemove(data.id); }}>
            <FormattedMessage id="registeredMedicalJewelry.remove.button.label" />
          </button>
        )
      }

    </div>
  );
};

export default RegisteredJewelry;
