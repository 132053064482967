
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import RouteNames from '../../appAuthorized/RouteNames';

import ContactModel from '../../../models/ContactModel';

import SelectableDetailLabel from './components/SelectableDetailLabel';
import { CardRowUI } from '../../../bphComponents/bundle';
import MultiCardListView from '../../components/MultiCardListView';
import { useUIEditContact } from '../../hooks/useUIEdit';
import { useLists } from '../../hooks/useLists';
import { useNavigate } from '../../hooks/useNavigate';

const getData = (props) => {
  const { isFullList, contactData, listData, goToFullList, goToCustom } = props;
  return isFullList ? {
    phrase: {
      title: {
        id: 'contactSpecialty.fullList.title',
      },
    },
    cards: [
      {
        data: listData,
        firstItem: null,
        renderDataItem: (data, onClick, isSelected) => <SelectableDetailLabel key={data.id} data={data} onClick={onClick} isSelected={isSelected} />,
        lastItem: null,
      },
      {
        data: [],
        firstItem: () => (
          <CardRowUI.Navigation onClick={goToCustom}>
            <div className="font-body font-color-profile-accent">
              {
                contactData.specialty_custom_text || <FormattedMessage id="contact.specialty.addCustom.button.label" />
              }
            </div>
          </CardRowUI.Navigation>
        ),
        lastItem: null,
      },
    ],
  } : {
    phrase: {
      title: {
        id: 'contactSpecialty.shortList.title',
      },
    },
    cards: [
      {
        data: listData.filter(d => d.is_extended === 0),
        firstItem: null,
        renderDataItem: (data, onClick, isSelected) => <SelectableDetailLabel key={data.id} data={data} onClick={onClick} isSelected={isSelected} />,
        lastItem: () => (
          <CardRowUI.Navigation onClick={goToFullList}>
            <div className="font-body font-color-profile-accent">
              <FormattedMessage id="contactSpecialty.viewAll.button.label" />
            </div>
          </CardRowUI.Navigation>
        ),
      },
    ],
  };
};

const shouldShowFullList = (listData, valueId) => {
  const value = listData.find(d => d.id === valueId);
  return value ? value.is_extended === 1 : false;
};

interface Props {
  match: any;
  intl: any;
}
const ViewContactSpecialty: React.FC<Props> = (props) => {
  const { intl, match } = props;
  const contactId = Number(match.params.contactId);

  const [{ editingContact, isLoaded }, uiEditActions] = useUIEditContact(contactId);
  const [{ contactSpecialtyList, contactSpecialtyCustomValue }] = useLists();
  const [, navActions] = useNavigate();

  const sortedContactSpecialtyList = contactSpecialtyList.sort((a, b) => {
    if (!a.other && !b.other) {
      const aName = intl.formatMessage({ id: a.key });
      const bName = intl.formatMessage({ id: b.key });
      return aName.localeCompare(bName, intl.locale, { sensitivity: 'base', ignorePunctuation: 'true' });
    } else if (a.other) {
      return 1;
    } else if (b.other) {
      return -1;
    }
  });

  const [isFullList, setIsFullList] = React.useState(shouldShowFullList(sortedContactSpecialtyList, editingContact ? editingContact.contact_specialty_id : null));
  const listDataWithoutCustom = !contactSpecialtyCustomValue ? sortedContactSpecialtyList : sortedContactSpecialtyList.filter(d => d.id !== contactSpecialtyCustomValue.id);

  const setToFullList = () => {
    setIsFullList(true);
    window.scrollTo(0, 0);
  };

  React.useEffect(() => {
    if (isLoaded) {
      setIsFullList(shouldShowFullList(sortedContactSpecialtyList, editingContact.contact_specialty_id));
    }
  }, [isLoaded]);

  const listViewData = getData({
    isFullList,
    contactData: editingContact,
    listData: listDataWithoutCustom,
    goToFullList: () => { setToFullList(); },
    goToCustom: () => {
      navActions.goTo(RouteNames.contactCustomSpecialty(editingContact.id));
    },
  });

  return (
    <div>
      {
        isLoaded
        ? <MultiCardListView
            phrase={{
              title: listViewData.phrase.title,
            }}
            isMultiSelect={false}
            initialValue={editingContact.contact_specialty_id}
            onCancel={() => { navActions.goBack(RouteNames.contactEdit(editingContact.id)); }}
            isValid={(value) => ContactModel.isSpecialtyValid({ ...editingContact, contact_specialty_id: value }, sortedContactSpecialtyList)}
            onSubmit={(currentValue) => {
              uiEditActions.updateSpecialty(currentValue, editingContact.specialty_custom_text, true)
                .then(() => {
                  navActions.goBack(RouteNames.contactEdit(editingContact.id));
                }).catch(() => {});
            }}
            cards={listViewData.cards}
          />
        : null
      }
    </div>
  );
};

export default injectIntl(ViewContactSpecialty);
