import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import AppHeaderScrollable from './AppHeaderScrollable';
import { AppNavShowMoreButton } from './appNavBits/AppNavButtons';
import AppNavEditableTitle from './appNavBits/AppNavEditableTitle';
import AppNavAnatomyTextInput from './appNavBits/AppNavAnatomyTextInput';
import { TitleRow, GeneralRow } from './appNavBits/AppNavRow';
import AppNavContent from './appNavBits/AppNavContent';
import ClickableIcon from '../../components/ClickableIcon';
import IconTrash from '../../components/icons/IconTrash';
import ModalWithButtons from '../modal/ModalWithButtons';
import FlexRow from '../FlexRow';

interface Props {
  showExpandedSection: boolean;
  allowEditTerms: boolean;
  showMore: boolean;
  hasSingleAnatomy: boolean;
  title: string;
  canonicalName: string;
  deletePhraseId: string;
  onDelete(): void;
  editTitle?(): void;
  onToggleExpand?(): void;
  icon?: any;
}

interface State {
  showModal: boolean;
}

export default class AppNavHealthEntryScrollable extends Component {
  props: Props;
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  render() {
    const { icon, showMore, hasSingleAnatomy, allowEditTerms = true, showExpandedSection, title, canonicalName, deletePhraseId = 'delete.deleteConfirm.button.label' } = this.props;
    const hasDelete = !!this.props.onDelete;

    return (
      <AppHeaderScrollable
        title={title}
        ignorePadding
      >
        <AppNavContent>
          <TitleRow alignItems="flex-start">
            <AppNavEditableTitle isEditable={!!showExpandedSection && allowEditTerms} onClick={this.props.editTitle}>
              {title}
            </AppNavEditableTitle>
            {showMore ? <div style={{ width: 30, marginTop: 8 }}><AppNavShowMoreButton onClick={this.props.onToggleExpand} isActive={!showExpandedSection} /></div> : null}
            {icon ? <div style={{ width: 30, marginTop: 8, marginRight: -6 }}>{icon}</div> : null}
          </TitleRow>

          {
            showExpandedSection ? (
              <div className="cmp-healthentry-caption">
                {
                  (hasSingleAnatomy && allowEditTerms) ? (
                    <AppNavAnatomyTextInput isEditable={true} />
                  ) : null
                }

                <GeneralRow>
                  <FlexRow style={{ alignItems: 'flex-start', justifyContent: !canonicalName && 'flex-end' }}>
                    {canonicalName && <div className="font-subhead" style={{ width: 'calc(100% - 70px)', color: 'white' }}>{canonicalName}</div>}

                    {
                      hasDelete ? (
                        <ClickableIcon clickableHeight={40} align="right" onClick={() => { this.setState({ showModal: true }); }} iconSize={16}>
                          <IconTrash.Small color="white" />
                        </ClickableIcon>
                      ) : <div />
                    }
                  </FlexRow>
                </GeneralRow>

              </div>
            ) : null
          }
        </AppNavContent>

        <ModalWithButtons
          isVisible={this.state.showModal}
          buttons={[
            {
              type: 'primary',
              action: 'destructive',
              text: <FormattedMessage id={deletePhraseId} />,
              onClick: () => {
                this.props.onDelete();
                this.setState({
                  showModal: false,
                });
              },
            },
            {
              type: 'secondary',
              action: 'cancel',
              text: <FormattedMessage id="delete.deleteCancel.button.label" />,
              onClick: () => {
                this.setState({
                  showModal: false,
                });
              },
            },
          ]}
        />
      </AppHeaderScrollable>
    );
  }
}
