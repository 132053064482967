import React from 'react';

import HealthEntryGeneAttributes, { HealthEntryGeneAttributeInterface, HealthEntryGeneAttributeValueInterface } from '../../constants/HealthEntryGeneAttributes';
import GeneAttributeResultText from './GeneAttributeResultText';

interface Props {
  className?: string;
  style?: any;
  geneVariantResult: any;
  languageCode?: string;
}

const isAttributeValueDefined = (valueScheme: HealthEntryGeneAttributeValueInterface, geneVariantResult: any): boolean => {
  const { geneVariantResultKey, isMedliTerm } = valueScheme;

  return geneVariantResult.isAdditionalAttributeDefined(geneVariantResultKey, isMedliTerm);
};

const isAttributeDefined = (attribute: HealthEntryGeneAttributeInterface, geneVariantResult: any): boolean => {
  const { values } = attribute;

  return values.some((valueScheme: HealthEntryGeneAttributeValueInterface) => isAttributeValueDefined(valueScheme, geneVariantResult));
};

const getDefinedAttributes = (geneVariantResult: any): any[] => {
  return HealthEntryGeneAttributes.filter((attribute: HealthEntryGeneAttributeInterface) => isAttributeDefined(attribute, geneVariantResult));
};

export const areGeneAttributesDefined = (geneVariantResult: any): boolean => {
  const definedAttributes = getDefinedAttributes(geneVariantResult);

  return definedAttributes && !!definedAttributes.length;
};

const GeneAttributesListResultText = (props: Props) => {
  const { className, style, geneVariantResult, languageCode } = props;
  const definedAttributes = getDefinedAttributes(geneVariantResult);

  if (!definedAttributes.length) return null;

  return (
    <div className={className} style={style}>
      {definedAttributes.map((attribute: HealthEntryGeneAttributeInterface, i) => (
        <span key={attribute.name}>
          {i > 0 && <span>, </span>}
          <GeneAttributeResultText
            attributeScheme={attribute}
            geneVariantResult={geneVariantResult}
            languageCode={languageCode}
          />
        </span>
      ))}
    </div>
  );
};

export default GeneAttributesListResultText;
