

export interface ContactModelType {
  id: number;
  profile_id: number;
  type: 'provider' | 'contact'; // not settable, only returned by server
  name: string;
  organization_name: string;
  contact_specialty_id: number;
  contact_relationship_id: number;
  contact_healthcare_proxy_ids: number[];
  relationship_custom_text: string;
  specialty_custom_text: string;
  comment: string;
  current: boolean;
  details: ContactDetailType[];
}

export interface ContactDetailType {
  id: number;
  type: 'website' | 'email' | 'address' | 'phone';
  contact_id: number;
  contact_detail_label_id: number;
  email?: {
    address: string;
  };
  website?: {
    url: string;
  };
  address?: {
    detail: string;
  };
  phone?: {
    country_code: string;
    number: string;
  };
}

class ContactModel {
  static groupDetails(data: ContactModelType) {
    const { details } = data;

    const groupedData = {
      email: [],
      website: [],
      address: [],
      phone: [],
    };

    details.forEach(d => {
      groupedData[d.type].push(d);
    });

    return groupedData;
  }

  static defaultContact(data, profileId): ContactModelType {
    return {
      id: -1,
      type: null,
      name: '',
      organization_name: '',
      contact_specialty_id: null,
      relationship_custom_text: null,
      specialty_custom_text: null,
      contact_healthcare_proxy_ids: [],
      profile_id: profileId,
      comment: '',
      current: null,
      details: [],
      ...data,
    };
  }

  static defaultProviderContact(data, profileId): ContactModelType {
    return {
      id: -1,
      type: 'provider',
      name: '',
      organization_name: '',
      contact_specialty_id: null,
      relationship_custom_text: null,
      specialty_custom_text: null,
      contact_healthcare_proxy_ids: [],
      profile_id: profileId,
      comment: '',
      current: true,
      details: [],
      ...data,
    };
  }

  static isProvider(data) {
    return data.type === 'provider';
  }

  static isRelationshipDefined(data) {
    return Number.isInteger(data.contact_relationship_id);
  }

  static isCurrent(data) {
    return data.current;
  }

  static setCurrent(data, value) {
    return {
      ...data,
      current: value,
    };
  }

  static hasHealthcareProxy(data) {
    return data.contact_healthcare_proxy_ids.length > 0;
  }

  static hasSpecialty(data) {
    return !!(data.contact_specialty_id || data.specialty_custom_text);
  }

  static hasName(data) {
    return !!data.name;
  }

  static hasRelationship(data) {
    return !!(data.relationship_custom_text || data.contact_relationship_id);
  }

  static addDetail(data, detailType, contactLabels, initialDetailData) {
    const contactId = data.id;
    const newData = { ...data };

    const newDetail: ContactDetailType = {
      id: +new Date(),
      type: detailType,
      contact_id: contactId,
      contact_detail_label_id: null,
    };

    switch (detailType) {
      case 'website':
        newDetail.website = {
          url: '',
          ...initialDetailData,
        };
        newDetail.contact_detail_label_id = contactLabels.find(d => d.name === 'website_website').id;
        break;
      case 'email':
        newDetail.email = {
          address: '',
          ...initialDetailData,
        };
        newDetail.contact_detail_label_id = contactLabels.find(d => d.name === 'email_email').id;
        break;
      case 'address':
        newDetail.address = {
          detail: '',
          ...initialDetailData,
        };
        newDetail.contact_detail_label_id = contactLabels.find(d => d.name === 'address_address').id;
        break;
      case 'phone':
        newDetail.phone = {
          country_code: '',
          number: '',
          ...initialDetailData,
        };
        newDetail.contact_detail_label_id = contactLabels.find(d => d.name === 'phone_phone').id;
        break;
      default:
        break;
    }

    newData.details.push(newDetail);

    return [newData, newDetail];
  }

  static removeDetail(data, detail) {
    const newData = { ...data };
    newData.details = newData.details.filter(d => d.id !== detail.id);
    return newData;
  }

  static replaceDetail(data, detail) {
    const newData = { ...data };
    newData.details = newData.details.map(d => {
      return d.id === detail.id ? detail : d;
    });
    return newData;
  }

  static cleanSpecialty(data, specialtyList) {
    const { contact_specialty_id } = data;
    const specialty = specialtyList.find(r => r.id === contact_specialty_id);

    if (specialty && !specialty.is_custom) {
      return {
        ...data,
        specialty_custom_text: null,
      };
    }

    return data;
  }

  static cleanRelationship(data, relationshipList) {
    const { contact_relationship_id } = data;
    const relationship = relationshipList.find(r => r.id === contact_relationship_id);

    if (!relationship) { return data; }

    const isProvider = relationship.is_provider;
    const isCustom = relationship.is_custom;
    const current = isProvider
      ? (data.current === null ? true : data.current)
      : null;

    return {
      ...data,
      current,
      type: isProvider ? 'provider' : 'contact',
      relationship_custom_text: isCustom ? data.relationship_custom_text : null,
    };
  }

  static cleanDataForSubmit(data, isNew) {
    const newData = { ...data };
    if (isNew) {
      newData.id = -1;
      data.details.forEach(d => {
        d.contact_id = -1;
      });
    }

    if (!newData.comment) {
      delete newData.comment;
    }

    newData.details = newData.details.filter(d => ContactModel.isDetailValid(d));

    return newData;
  }

  static newCopyForProfile(data, profileId) {
    const newData: ContactModelType = { ...data };
    newData.profile_id = profileId;

    if (newData.type === 'provider') {
      newData.current = true;
    } else {
      newData.contact_healthcare_proxy_ids = [];
    }

    return newData;
  }

  static isDetailValid(detail) {
    const detailType = detail.type;

    switch (detailType) {
      case 'website':
        return !!detail.website.url;
      case 'email':
        return !!detail.email.address;
      case 'address':
        return !!detail.address.detail;
      case 'phone':
        return !!detail.phone.number;
      default:
        return false;
    }
  }

  static isSpecialtyValid(data, specialtyList) {
    const { specialty_custom_text, contact_specialty_id } = data;
    const specialty = specialtyList.find(r => r.id === contact_specialty_id);

    if (!specialty) {
      return false;
    } else if (specialty.is_custom) {
      return !!specialty_custom_text;
    }

    return true;
  }

  static isRelationshipValid(data, relationshipList) {
    const { relationship_custom_text, contact_relationship_id } = data;
    const relationship = relationshipList.find(r => r.id === contact_relationship_id);

    if (!relationship) {
      return false;
    } else if (relationship.is_custom) {
      return !!relationship_custom_text;
    }

    return true;
  }

  static validateData(data) {
    let msg = '';

    const hasName = !!data.name;
    const hasRelationship = !!data.contact_relationship_id;

    if (hasName && hasRelationship) {
      return { isValid: true, msg: '' };
    }

    if (!hasName && !hasRelationship) {
      msg = 'error.contact-validator.missing-name-relationship.message';
    } else if (!hasName) {
      msg = 'error.contact-validator.missing-name.message';
    } else {
      msg = 'error.contact-validator.missing-relationship.message';
    }

    return { isValid: false, msg };
  }

  constructor(data) {

  }
}

function createContactInstance(data: ContactModelType) {

  return {

  }
}

export default ContactModel;
