import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Card, Icons } from '../../../../bphComponents/bundle';
import FlexRow from '../../../components/FlexRow';
import HealthEntryCategoryNames, { AllergySubType } from '../../../../constants/HealthEntryCategoryNames';
import COLORS from '../../../styles/Colors';

interface Props {
  titlePhrase: {
    id: string;
    values?: any;
  };
  Icon: any;
}

const CardTitle: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <Card.ExternalContent noMarginBottom={false}>
      <FlexRow className="font-caption-1 font-color-gsMedDark" style={{ justifyContent: 'space-between' }}>
        {children}
      </FlexRow>
    </Card.ExternalContent>
  );
};

const HealthEntryDataLookup = {
  [HealthEntryCategoryNames.condition]: {
    Icon: Icons.IconCondition,
    titlePhrase: {
      id: 'list.healthEntry.conditions.name',
      values: {
        digit1: 1,
      },
    },
  },
  [HealthEntryCategoryNames.medication]: {
    Icon: Icons.IconMedication,
    titlePhrase: {
      id: 'list.healthEntry.medications.name',
      values: {
        digit1: 1,
      },
    },
  },
  [HealthEntryCategoryNames.vaccination]: {
    Icon: Icons.IconVaccinations,
    titlePhrase: {
      id: 'list.healthEntry.vaccinations.name',
      values: {
        digit1: 1,
      },
    },
  },
  [HealthEntryCategoryNames.allergy]: {
    Icon: Icons.IconAllergies,
    titlePhrase: {
      id: 'list.healthEntry.allergy.name',
      values: {
        digit1: 1,
      },
    },
  },

  [HealthEntryCategoryNames.otherTreatment]: {
    Icon: Icons.IconTreatments,
    titlePhrase: {
      id: 'list.healthEntry.treatments.name',
      values: {
        digit1: 1,
      },
    },
  },
  [HealthEntryCategoryNames.procedure]: {
    Icon: Icons.IconProcedures,
    titlePhrase: {
      id: 'list.healthEntry.procedures.name',
      values: {
        digit1: 1,
      },
    },
  },
  [HealthEntryCategoryNames.measurement]: {
    Icon: Icons.IconMeasurements,
    titlePhrase: {
      id: 'list.healthEntry.measurements.name',
      values: {
        digit1: 1,
      },
    },
  },
  [HealthEntryCategoryNames.lab]: {
    Icon: Icons.IconLabs,
    titlePhrase: {
      id: 'list.healthEntry.lab.name',
      values: {
        digit1: 1,
      },
    },
  },
  [HealthEntryCategoryNames.geneticResult]: {
    Icon: Icons.IconLabs,
    titlePhrase: {
      id: 'list.healthEntry.genes.name',
      values: {
        digit1: 1,
      },
    },
  },
};

const AllergyDataLookup = {
  [AllergySubType.medication]: {
    Icon: Icons.IconMedicationAllergy,
    titlePhrase: {
      id: 'list.healthEntry.allergiesMedication.name',
      values: {
        digit1: 1,
      },
    },
  },
  [AllergySubType.food]: {
    Icon: Icons.IconFoodAllergy,
    titlePhrase: {
      id: 'list.healthEntry.allergiesFood.name',
      values: {
        digit1: 1,
      },
    },
  },
  [AllergySubType.other]: {
    Icon: Icons.IconAllergies,
    titlePhrase: {
      id: 'list.healthEntry.allergiesOther.name',
      values: {
        digit1: 1,
      },
    },
  },
};

export const HECardTitle: React.FC = (props) => {
  return (
    <Card.ExternalCardTitle className="font-color-gsMedDark">
      {props.children}
    </Card.ExternalCardTitle>
  );
};

export const HECategoryCardTitle: React.FC<{ category: HealthEntryCategoryNames; type: string; }> = (props) => {
  const { category, type } = props;
  let Icon = null;
  let titlePhrase = null;

  if (!category) { return <div />; }

  switch (category) {
    case HealthEntryCategoryNames.allergy:
      if (AllergyDataLookup[type]) {
        Icon = AllergyDataLookup[type].Icon;
        titlePhrase = AllergyDataLookup[type].titlePhrase;
      } else {
        Icon = AllergyDataLookup[AllergySubType.other].Icon;
        titlePhrase = AllergyDataLookup[AllergySubType.other].titlePhrase;
      }
      break;
    case HealthEntryCategoryNames.missing:
      return <div />;
    default:
      Icon = HealthEntryDataLookup[category].Icon;
      titlePhrase = HealthEntryDataLookup[category].titlePhrase;
      break;
  }

  return (
    <Card.ExternalCardTitle className="font-color-gsMedDark">
      <FormattedMessage {...titlePhrase} />
      <Icon.Small color={COLORS.gsMedDark} />
    </Card.ExternalCardTitle>
  );
};

export default CardTitle;
