import React from 'react';

import RouteNames from '../../appAuthorized/RouteNames';

import SelectableDetailLabel from './components/SelectableDetailLabel';
import MultiCardListView from '../../components/MultiCardListView';
import { Card } from '../../../bphComponents/bundle';
import { NoThemeMarkdown } from '../../components/Markdown';
import COLORS from '../../styles/Colors';
import { useUIEditContact } from '../../hooks/useUIEdit';
import { useLists } from '../../hooks/useLists';
import { useNavigate } from '../../hooks/useNavigate';
import { useActiveProfile } from '../../hooks/useProfile';
import { useUIState } from '../../hooks/useUIState';

interface Props {
  match: any;
}

const ViewContactRelationship: React.FC<Props> = (props) => {
  const { match } = props;
  const contactId = Number(match.params.contactId);

  const [{ editingContact, isLoaded }, uiEditActions] = useUIEditContact(contactId);
  const [{ contactHealthcareProxyList }] = useLists();
  const [, navActions] = useNavigate();
  const [{ greetingName }] = useActiveProfile();
  const [{ isActiveProfileMainProfile }] = useUIState();

  return (
    <div>
      {
        isLoaded
        ? <MultiCardListView
            phrase={{
              title: {
                id: 'contactHealthProxy.title',
              },
              contentTitle: isActiveProfileMainProfile ? {
                id: 'contactHealthProxy.user.label',
              } : {
                id: 'contactHealthProxy.notUser.label',
                values: { string1: greetingName },
              },
            }}
            isMultiSelect={true}
            initialValue={editingContact.contact_healthcare_proxy_ids}
            onCancel={() => { navActions.goBack(RouteNames.contactEdit(String(contactId))); }}
            isValid={() => true}
            onSubmit={(currentValue) => {
              uiEditActions.updateHealthcareProxy(currentValue)
                .then(() => {
                  navActions.goBack(RouteNames.contactEdit(editingContact.id));
                }).catch(() => {});
            }}
            cards={[
              {
                data: contactHealthcareProxyList,
                firstItem: null,
                renderDataItem: (data, onClick, isSelected) => <SelectableDetailLabel key={data.id} data={data} onClick={onClick} isSelected={isSelected} />,
                lastItem: null,
              },
              {
                data: contactHealthcareProxyList,
                cardProps: { isFlat: true, background: COLORS.gsLight },
                firstItem: () => (
                  <Card.CardContent>
                    <NoThemeMarkdown phraseId="contactHealthProxy.help.message.md" />
                  </Card.CardContent>
                ),
                renderDataItem: () => {},
                lastItem: null,
              }
            ]}
          />
        : null
      }
    </div>
  );
};

export default ViewContactRelationship;
