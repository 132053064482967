
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Card, CardRowUI } from '../../../../bphComponents/bundle';
import ContactSummary from '../ViewContactAdd/ContactSummary';
import { PhraseData } from '../../../../utilities/localizedUtil';
import ContactModel from '../../../../models/ContactModel';
import ProfileModel from '../../../../models/ProfileModel';

interface ContactListProps {
  intl: any;
  data: any[];
  isAttaching: boolean;
  selectedContacts: object;
  selectContact(data): void;
  profile: any;
  isMainProfile: boolean;
}

const ContactSelectableList: React.FC<ContactListProps> = (props) => {
  const { data, selectedContacts, selectContact, isAttaching, intl, profile, isMainProfile } = props;
  const sortedContactList = data.sort((a, b) => (a.name < b.name ? -1 : 1));

  const [isPastVisible, setIsPastVisible] = React.useState(false);
  const greetingName = ProfileModel.getDisplayName(profile);

  const currentContacts = [];
  const pastContacts = [];
  sortedContactList.forEach(p => {
    if (p.current || !ContactModel.isProvider(p)) {
      currentContacts.push(p);
    } else {
      pastContacts.push(p);
    }
  });

  const showPastPhrase = isPastVisible ? {
    id: 'bpAll.hidePast.button.label',
    values: {
      string1: intl.formatMessage({ id: 'list.provider.name' }, { digit1: pastContacts.length }).toLowerCase(),
    },
  } : {
    id: 'bpAll.showPast.button.label',
    values: {
      string1: intl.formatMessage({ id: 'list.provider.name' }, { digit1: pastContacts.length }).toLowerCase(),
    },
  };

  let titlePhrase: PhraseData = { id: '' };
  // attaching only allows for providers, so these phrase app keys are slightly different
  if (isAttaching) {
    titlePhrase = isMainProfile ? {
      id: 'providers.user.list.label',
    } : {
      id: 'providers.notUser.list.label',
      values: { string1: greetingName },
    };
  } else {
    titlePhrase = isMainProfile ? {
      id: 'contacts.user.listCopyFrom.label',
    } : {
      id: 'contacts.notUser.listCopyFrom.label',
      values: { string1: greetingName },
    };
  }

  return (
    <div>
      <Card.ExternalCardTitle>
        <FormattedMessage {...titlePhrase} />
      </Card.ExternalCardTitle>

      <Card.CardShape>
        {
          currentContacts.map(d => (
            <ContactSummary
              key={d.id}
              data={d}
              isSelected={selectedContacts[d.id]}
              onClick={() => { selectContact(d); }}
            />
          ))
        }

        {
          pastContacts.length > 0 && (
            <CardRowUI.TextAction hasBottomBorder={true} className="font-caption-1 font-color-profile-accent" onClick={() => { setIsPastVisible(!isPastVisible); }}>
              <Card.CollapsibleControl isExpanded={isPastVisible}>
                <FormattedMessage {...showPastPhrase} />
              </Card.CollapsibleControl>
            </CardRowUI.TextAction>
          )
        }

        {
          isPastVisible && pastContacts.map(d => (
            <ContactSummary
              key={d.id}
              data={d}
              isSelected={selectedContacts[d.id]}
              onClick={() => { selectContact(d); }}
            />
          ))
        }
      </Card.CardShape>
    </div>
  );
};

export default injectIntl(ContactSelectableList);
