import React from 'react';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  color?: string;
}

const DEFAULT_COLOR = 'gsMedDark';

const getClassName = (props: Props) => {
  const { color = DEFAULT_COLOR } = props;
  return colorClassNames.fill(color);
};

const Small = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px">
    <g id="icon-eye-hide" className={getClassName(props)}>
      <path d="M13.09,5.57l-.9.9A6.46,6.46,0,0,1,14,8.75a6.62,6.62,0,0,1-7.68,3.58l-1,1A7.55,7.55,0,0,0,8,13.8,7.89,7.89,0,0,0,15.27,9l.1-.24-.1-.25A7.88,7.88,0,0,0,13.09,5.57Z"/>
      <path d="M14.47,2.43h0a.62.62,0,1,0-.88-.88L10.87,4.26A7.84,7.84,0,0,0,.73,8.5l-.1.25L.73,9a7.75,7.75,0,0,0,2.34,3.06L1.55,13.58a.61.61,0,0,0,0,.88.59.59,0,0,0,.44.19.62.62,0,0,0,.44-.18ZM6.23,8.89c0-.05,0-.09,0-.14A1.78,1.78,0,0,1,8,7h.14ZM2,8.75A6.64,6.64,0,0,1,8,5a6.72,6.72,0,0,1,1.9.28L9.17,6A2.88,2.88,0,0,0,8,5.72a3,3,0,0,0-2.79,4.2L4,11.17A6.66,6.66,0,0,1,2,8.75Z"/>
      <path d="M8.15,10.51l-1.1,1.1A3,3,0,0,0,10.86,7.8L9.76,8.9A1.76,1.76,0,0,1,8.15,10.51Z"/>
    </g>
  </svg>
);

const Medium = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <g id="icon-eye-hide" className={getClassName(props)}>
      <path d="M14.92,12A2.92,2.92,0,0,1,12,14.93l-.27,0-1.24,1.24a4.22,4.22,0,0,0,1.51.28A4.42,4.42,0,0,0,16.42,12a4.22,4.22,0,0,0-.28-1.51l-1.25,1.25A2.06,2.06,0,0,1,14.92,12Z"/>
      <path d="M22.9,11.72a11.81,11.81,0,0,0-3.43-4.55L18.39,8.25a10.18,10.18,0,0,1,3,3.76A10.32,10.32,0,0,1,12,18a10.24,10.24,0,0,1-2.93-.44L7.88,18.76a11.58,11.58,0,0,0,4.12.75,11.81,11.81,0,0,0,10.9-7.2L23,12Z"/>
      <path d="M2.81,21.71,21.18,3.34s0,0,0,0a.75.75,0,0,0,0-1,.74.74,0,0,0-1.06,0L16.86,5.56A11.83,11.83,0,0,0,1.1,11.72L1,12l.12.3a11.82,11.82,0,0,0,4,5L1.76,20.65a.75.75,0,0,0,0,1.06.74.74,0,0,0,.53.22.78.78,0,0,0,.51-.2A0,0,0,0,1,2.81,21.71Zm6.49-8.6A2.88,2.88,0,0,1,9.08,12,2.92,2.92,0,0,1,12,9.1a3.07,3.07,0,0,1,1.1.21ZM2.61,12A10.34,10.34,0,0,1,12,6a10.18,10.18,0,0,1,3.69.71L14.2,8.21A4.32,4.32,0,0,0,12,7.6,4.42,4.42,0,0,0,7.58,12a4.37,4.37,0,0,0,.62,2.21l-2,2A10.37,10.37,0,0,1,2.61,12Z"/>
    </g>
  </svg>
);

const Large = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36px" height="36px">
    <g id="icon-eye-hide" className={getClassName(props)}>
      <path d="M22.71,18A4.71,4.71,0,0,1,18,22.71c-.16,0-.32,0-.47,0l-1.68,1.67a6.43,6.43,0,0,0,2.15.36A6.72,6.72,0,0,0,24.71,18a6.43,6.43,0,0,0-.36-2.15l-1.67,1.68C22.69,17.69,22.71,17.84,22.71,18Z"/>
      <path d="M34.83,17.61a18.29,18.29,0,0,0-5.25-7l-1.42,1.42A16.3,16.3,0,0,1,32.82,18,16.27,16.27,0,0,1,18,27.52a16.09,16.09,0,0,1-4.62-.69l-1.6,1.6a18.29,18.29,0,0,0,23-10L35,18Z"/>
      <path d="M4.43,33,33,4.34s.05,0,.07,0A1,1,0,0,0,31.63,3L26.19,8.42a18.28,18.28,0,0,0-25,9.19L1,18l.17.39A18.26,18.26,0,0,0,8,26.57l-5,5A1,1,0,0,0,3,33a1,1,0,0,0,.71.29,1,1,0,0,0,.63-.25S4.39,33,4.43,33Zm9.62-12.39a4.71,4.71,0,0,1,6.51-6.51ZM3.18,18A16.29,16.29,0,0,1,24.69,9.92L22,12.62a6.64,6.64,0,0,0-4-1.33A6.72,6.72,0,0,0,11.29,18a6.64,6.64,0,0,0,1.33,4L9.49,25.12A16.31,16.31,0,0,1,3.18,18Z"/>
    </g>
  </svg>
);

const XLarge = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" width="56px" height="56px">
    <g id="icon-eye-hide" className={getClassName(props)}>
      <path d="M8.11,49.8,49,9c.07-.06.14-.09.21-.14l.54-.53a1.39,1.39,0,0,0,0-1.95,1.38,1.38,0,0,0-2,0l-7.9,7.9A26.36,26.36,0,0,0,3.73,27.46L3.51,28l.22.54a26.39,26.39,0,0,0,10,11.84L6.38,47.71a1.38,1.38,0,0,0,0,1.95,1.36,1.36,0,0,0,1,.4A1.34,1.34,0,0,0,8.11,49.8Zm14.16-18a6.89,6.89,0,0,1,9.55-9.55ZM6.5,28A23.65,23.65,0,0,1,37.8,16.29l-4,4A9.62,9.62,0,0,0,20.31,33.78l-4.6,4.6A23.61,23.61,0,0,1,6.5,28Z"/>
      <path d="M52.27,27.46a26.34,26.34,0,0,0-7.73-10.19l-2,2A23.47,23.47,0,0,1,49.5,28,23.59,23.59,0,0,1,21,40.77l-2.17,2.17a26.36,26.36,0,0,0,33.41-14.4l.22-.54Z"/>
      <path d="M34.88,28A6.88,6.88,0,0,1,28,34.88a6.77,6.77,0,0,1-1-.07l-2.24,2.25a9.45,9.45,0,0,0,3.25.57A9.64,9.64,0,0,0,37.63,28a9.45,9.45,0,0,0-.57-3.25L34.81,27A6.77,6.77,0,0,1,34.88,28Z"/>
    </g>
  </svg>
);

const IconEyeHide = {
  Small,
  Medium,
  Large,
  XLarge,
};

export default IconEyeHide;
