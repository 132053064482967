
interface ContactRelationshipType {
  id: number;
  name: string;
  key: string;
  is_provider: number;
  is_extended: number;
  is_custom: number;
  order: number;
}

const ContactRelationship = {

  getCustomRelationship: (data: ContactRelationshipType[]): ContactRelationshipType => {
    return data.find(d => d.is_custom === 1);
  },

  getHealthcareProviderRelationship: (data: ContactRelationshipType[]): ContactRelationshipType => {
    return data.find(d => d.is_provider === 1);
  },

  getOtherRelationship: (data: ContactRelationshipType[]): ContactRelationshipType => {
    return data.find(d => d.name === 'Other');
  },

  sortFullList: (intl, data: ContactRelationshipType[], excludeIds: number[]): ContactRelationshipType[] => {
    return data
      .filter(d => !excludeIds.includes(d.id))
      .sort((a, b) => {
        if (a.order === b.order) {
          return intl.formatMessage({ id: a.key }).localeCompare(intl.formatMessage({ id: b.key }));
        } else if (a.order < b.order) {
          return -1;
        } else {
          return 1;
        }
      });
  },

  sortShortList: (intl, data: ContactRelationshipType[], excludeIds: number[]): ContactRelationshipType[] => {
    return data
      .filter(d => !d.is_extended && !excludeIds.includes(d.id))
      .sort((a, b) => {
        if (a.order === b.order) {
          return intl.formatMessage({ id: a.key }).localeCompare(intl.formatMessage({ id: b.key }));
        } else if (a.order < b.order) {
          return -1;
        } else {
          return 1;
        }
      });
  },
};

const ListModel = {
  ContactRelationship,
};

export default ListModel;
