import { useQuery, queryCache } from 'react-query';
import _ from 'lodash';

import { useUIState } from './useUIState';
import TrackablesService from '../../apiServices/TrackablesService';
import { useActiveUser } from './useUser';
import { HealthEntryTrackables, iObservation, iTrackableDefinition, ObservationModel, TrackableModel, TrackableShortcutModel } from '../../apiServices/apiModels/Trackable';
import DateUtil from '../../utilities/DateUtil';
import ShareCardIncludedTypeNames from '../../constants/ShareCardIncludedTypeNames';
import { DeviceNotifications } from '../../models/DeviceNotifications';

export function transformTrackableTermByLanguage(existingLookup, trackableTermLookup) {
  const newLookup = { ...existingLookup };

  Object.entries(trackableTermLookup).forEach((thing: any) => {
    const [id, termData] = thing;
    Object.entries(termData.name.string).forEach(([lang, text]) => {
      newLookup[lang] = newLookup[lang] || {};
      newLookup[lang][id] = newLookup[lang][id] || { id };
      newLookup[lang][id].name = text;
    });
  });

  return newLookup;
}

export function updateTrackableCacheAfterObservationUpdate() {
  queryCache.invalidateQueries('allHealthEntryTrackables');
  queryCache.invalidateQueries('healthEntryTrackables');
  queryCache.invalidateQueries('trackableList');
  queryCache.invalidateQueries('trackable');
}
export function removeTrackableCacheAfterObservationUpdate() {
  queryCache.removeQueries('allHealthEntryTrackables');
  queryCache.removeQueries('healthEntryTrackables');
  queryCache.removeQueries('trackableList');
  queryCache.removeQueries('trackable');
}

function useHealthEntryTrackableList(profileId, healthEntryId, forceLanguageCode?) {
  const languageCode = forceLanguageCode || 'en';

  const heTrackableQuery = new HealthEntryTrackableQuery(profileId, healthEntryId);
  const responseData = heTrackableQuery.responseData();

  return [
    {
      enabledTrackables: responseData.enabledTrackables,
      availableTrackables: responseData.availableTrackables,
      availableTrackableIds: responseData.availableTrackables,
      trackableTermLookup: responseData.trackableTermLookup[languageCode],
      isLoaded: responseData.isLoaded,
    },
    {
      apiToggleTrackable: (trackableTermId) => {
        return heTrackableQuery.apiToggleTrackable(responseData.enabledTrackables, trackableTermId);
      },
    },
  ];
}

export function useHealthEntryTrackableListForActiveProfile(healthEntryId) {
  const [{ activeProfileId }] = useUIState();
  const [{ preferredLanguageCode }] = useActiveUser();
  return useHealthEntryTrackableList(activeProfileId, healthEntryId, preferredLanguageCode);
}

export function useTrackableList(profileId, forceLanguageCode) {
  const languageCode = forceLanguageCode || 'en';

  const trackableListQuery = new TrackableListQuery(profileId);
  const responseData = trackableListQuery.responseData();

  return [
    {
      trackableList: responseData.trackableList,
      trackableLookup: responseData.trackableLookup,
      observationLookup: responseData.observationLookup,
      observationToTrackableId: responseData.observationToTrackableId,
      trackableTermLookup: responseData.trackableTermLookup[languageCode],
      trackableTermLookupByLanguage: responseData.trackableTermLookup,
      isLoaded: responseData.isLoaded,
    },
    {
      getTrackableTermName: (tId, forceLanguageCode?) => {
        const code = forceLanguageCode || languageCode;
        const termId = _.get(responseData, `trackableLookup[${tId}].trackable_term_id`, null);
        const termLookup = responseData.trackableTermLookup[code];
        return _.get(termLookup, `${termId}.name`, '');
      },
      getShareCardSorted: (shareCard, includedValues, newLanguageCode?) => {
        const code = newLanguageCode || languageCode;
        const trackableLookup = responseData.trackableLookup;
        const termLookup = responseData.trackableTermLookup[code];

        const trackableList = shareCard.included_trackables
          .filter(s => (s.included_values === includedValues || s.included_values === ShareCardIncludedTypeNames.all))
          .map(s => trackableLookup[s.trackable_id])
          .filter(s => !!s);

        return trackableList.sort((a, b) => {
          const aTerm = termLookup[a.trackable_term_id].name;
          const bTerm = termLookup[b.trackable_term_id].name;
          return aTerm.localeCompare(bTerm);
        });
      },
      getSortedTrackables: (newLanguageCode?) => {
        const code = newLanguageCode || languageCode;
        const termLookup = responseData.trackableTermLookup[code];

        return responseData.trackableList.sort((a, b) => {
          const aTerm = termLookup[a.trackable_term_id].name;
          const bTerm = termLookup[b.trackable_term_id].name;
          return aTerm.localeCompare(bTerm);
        });
      },
    },
  ];
}
export function useTrackableListForActiveProfile() {
  const [{ activeProfileId }] = useUIState();
  const [{ preferredLanguageCode }] = useActiveUser();
  return useTrackableList(activeProfileId, preferredLanguageCode);
}
function useExistingTrackable(profileId, languageCode, trackableId) {
  const trackableQuery = new TrackableQuery(profileId, trackableId);
  const trackableResponseData = trackableQuery.responseData();

  const trackable = trackableResponseData.trackable;
  const defQuery = new TrackableDefinitionQuery(profileId, trackable, false);
  // const latestDefQuery = new TrackableDefinitionQuery(profileId, trackable, true);
  // const latestDefResponseData = latestDefQuery.responseData();
  const definitionResponseData = defQuery.responseData();
  const trackableDefinition = definitionResponseData.definition;

  const combinedTerms = {
    ...trackableResponseData.trackableTermLookup[languageCode],
    ...definitionResponseData.trackableTermLookup[languageCode],
  };

  return [
    {
      trackable: trackableResponseData.trackable,
      observationLookup: trackableResponseData.observationLookup,
      trackableDefinition,
      trackableTermLookup: combinedTerms,
      isLoaded: trackableResponseData.isLoaded,
      isFetching: trackableResponseData.isFetching,
    },
    {
      instantiate(overrideData?) {
        const d = overrideData || trackable;
        return new TrackableModel(d, trackableDefinition);
      },
      apiCreateObservation(overrideTrackableId?): Promise<iObservation> {
        const tData = overrideTrackableId ? TrackableModel.emptyTrackable({ id: overrideTrackableId }) : trackable;
        const trackableInstance = new TrackableModel(tData, trackableDefinition);
        const observationData = trackableInstance.createNewObservation();
        return trackableQuery.apiCreateObservation(tData, observationData);
      },
      apiCreateCurrentObservation(): Promise<iObservation> {
        return new Promise((res) => {
          TrackablesService.getCurrentDefinition(profileId, trackableId)
            .then(payload => {
              const tempTrackable = TrackableModel.emptyTrackable({ id: trackableId });
              const trackableInstance = new TrackableModel(tempTrackable, payload.data);
              const obsData = trackableInstance.createNewObservation();
              trackableQuery.apiCreateObservation(trackableInstance.data, obsData)
                .then(res);
            });
        });
      },
      apiDeleteObservation: (observationId) => {
        return trackableQuery.apiDeleteObservation(trackableResponseData.trackable, observationId)
          .then((d) => {
            removeTrackableCacheAfterObservationUpdate();
            return d;
          });
      },
      apiUpdateObservation: (observationData) => {

        return trackableQuery.apiUpdateObservation(trackableResponseData.trackable, observationData)
          .then((d) => {
            updateTrackableCacheAfterObservationUpdate();
            return d;
          });
      },
      apiUpdate: (d) => {
        return trackableQuery.apiUpdate(d);
      },
      apiToggleShortcut: () => {
        trackableQuery.apiToggleShortcut(trackableResponseData.trackable, trackableDefinition);
      },
      apiDeleteTrackable: () => {
        return trackableQuery.apiDeleteTrackable();
      },
    },
  ];
}

export function useExistingTrackableForActiveProfile(trackableId) {
  const [{ activeProfileId }] = useUIState();
  const [{ preferredLanguageCode }] = useActiveUser();
  return useExistingTrackable(activeProfileId, preferredLanguageCode, trackableId);
}

export function useAllHealthEntryTrackables(profileId, languageCode) {
  const trackableQuery = new AllHealthEntryTrackablesQuery(profileId);
  const responseData = trackableQuery.responseData();

  return [
    {
      enabledTrackables: responseData.enabledTrackables,
      availableTrackables: responseData.availableTrackables,
      availableTrackableIds: responseData.availableTrackables,
      trackableTermLookup: responseData.trackableTermLookup[languageCode],
      heIdsForTrackableLookup: responseData.heIdsForTrackableLookup,
      trackableIdsForHeLookup: responseData.trackableIdsForHeLookup,
      isLoaded: responseData.isLoaded,
    },
    {
      getHeIdsForTrackable: (trackableId) => {
        const het = new HealthEntryTrackables(responseData.enabledTrackables);
        return het.heIdsForTrackable(trackableId);
      },
    },
  ];
}

export function useAllHealthEntryTrackablesForActiveProfile() {
  const [{ activeProfileId }] = useUIState();
  const [{ preferredLanguageCode }] = useActiveUser();
  return useAllHealthEntryTrackables(activeProfileId, preferredLanguageCode);
}

export function useTrackableForActiveProfile() {
  const [{ activeProfileId }] = useUIState();
  return [
    {},
    {
      apiCreateCurrentObservation(trackableId): Promise<any> {
        return new Promise((res) => {
          TrackablesService.getCurrentDefinition(activeProfileId, trackableId)
            .then(payload => {
              const tempTrackable = TrackableModel.emptyTrackable({ id: trackableId });
              const trackableInstance = new TrackableModel(tempTrackable, payload.data);
              const obsData = trackableInstance.createNewObservation();

              TrackablesService.createObservation(activeProfileId, trackableId, obsData)
                .then((d) => {
                  queryCache.invalidateQueries('trackable');
                  queryCache.invalidateQueries('allHealthEntryTrackables');
                  queryCache.invalidateQueries('healthEntryTrackables');
                  queryCache.invalidateQueries('trackableList');

                  res(d);
                });
            });
        });
      },
    },
  ];
}
export function useTrackableShortcuts(profileId, languageCode) {
  const shortcutQuery = new TrackableShortcutQuery(profileId);
  const shortcutResponseData = shortcutQuery.responseData();

  return [
    {
      shortcutList: shortcutResponseData.shortcutList,
      trackableTermLookup: shortcutResponseData.trackableTermLookup[languageCode],
      isLoaded: shortcutResponseData.isLoaded,
    },
    {
      createObservation: (shortcut) => {
        const trackableId = shortcut.trackable_id;
        return new Promise((res) => {
          TrackablesService
            .get(profileId, trackableId)
            .then((tData) => {
              const t = new TrackableModel(tData.data, null, tData.trackable_terms);
              TrackablesService.createObservation(profileId, trackableId, t.createNewObservation())
                .then((o) => {
                  queryCache.invalidateQueries('trackable');
                  queryCache.invalidateQueries('allHealthEntryTrackables');
                  queryCache.invalidateQueries('healthEntryTrackables');
                  queryCache.invalidateQueries('trackableList');
                  res(o);
                });
            });
        });
      },
      requestTrackable: (trackableId) => {
        return TrackablesService.get(profileId, trackableId);
      },
    },
  ];
}

export function useTrackableShortcutsForActiveProfile() {
  const [{ activeProfileId }] = useUIState();
  const [{ preferredLanguageCode }] = useActiveUser();
  return useTrackableShortcuts(activeProfileId, preferredLanguageCode);
}

interface iHealthEntryTrackableResponse {
  enabledTrackables: any[];
  availableTrackables: any[];
  trackableTermLookup: any;
  isLoaded: boolean;
  heIdsForTrackableLookup: any;
  trackableIdsForHeLookup: any;
}
class AllHealthEntryTrackablesQuery {
  private profileId: number;
  private queryKey: any;
  private staleTime = 10000;
  private queryData: iHealthEntryTrackableResponse;

  constructor(profileId) {
    this.profileId = profileId;

    this.queryApi = this.queryApi.bind(this);

    this.queryKey = ['allHealthEntryTrackables', profileId];

    this.queryData = {
      enabledTrackables: [],
      availableTrackables: [],
      heIdsForTrackableLookup: {},
      trackableIdsForHeLookup: {},
      trackableTermLookup: { en: {} },
      isLoaded: false,
    };
  }

  private updateCache = (data: Partial<iHealthEntryTrackableResponse>) => {
    queryCache.setQueryData(this.queryKey, {
      ...this.queryData,
      ...data,
    });
  }

  private transformApiResponseForCache = (payload): iHealthEntryTrackableResponse => {
    return {
      ...this.queryData,
      enabledTrackables: payload.data,
      trackableTermLookup: transformTrackableTermByLanguage({}, payload.trackable_terms),
      availableTrackables: _.get(payload, 'trackable_options', []),
      heIdsForTrackableLookup: payload.data.reduce((acc, v) => {
        const tId = v.trackable_id;
        const heIds = acc[tId] || [];
        heIds.push(v.health_entry_id);
        return {
          ...acc,
          [tId]: heIds,
        };
      }, {}),
      trackableIdsForHeLookup: payload.data.reduce((acc, v) => {
        const heId = v.health_entry_id;
        const tIds = acc[heId] || [];
        tIds.push(v.trackable_id);
        return {
          ...acc,
          [heId]: tIds,
        };
      }, {}),
    };
  }

  private queryApi = (queryKey, profileId) => {
    return new Promise((res, rej) => {
      TrackablesService.getAllHealthEntryTrackables(profileId)
        .then(payload => {
          res({
            ...this.transformApiResponseForCache(payload),
            isLoaded: true,
          });
        });
    });

  }

  responseData = (): iHealthEntryTrackableResponse => {
    const isEnabled = Boolean(this.profileId);
    const query: any = useQuery(this.queryKey, this.queryApi, { enabled: isEnabled, staleTime: this.staleTime });

    this.queryData = query.data || this.queryData;
    return this.queryData;
  }
}
class HealthEntryTrackableQuery {

  private id: number;
  private profileId: number;
  private queryKey: any;
  private staleTime = 5000;
  private queryData: iHealthEntryTrackableResponse;

  constructor(profileId, healthEntryId) {
    this.profileId = profileId;
    this.id = healthEntryId;

    this.queryApi = this.queryApi.bind(this);

    this.queryKey = ['healthEntryTrackables', profileId, healthEntryId];

    this.queryData = {
      enabledTrackables: [],
      availableTrackables: [],
      heIdsForTrackableLookup: {},
      trackableIdsForHeLookup: {},
      trackableTermLookup: { en: {} },
      isLoaded: false,
    };
  }

  private updateCache = (data: Partial<iHealthEntryTrackableResponse>) => {
    queryCache.setQueryData(this.queryKey, {
      ...this.queryData,
      ...data,
    });
  }

  private transformApiResponseForCache = (payload): iHealthEntryTrackableResponse => {
    return {
      ...this.queryData,
      enabledTrackables: payload.data,
      trackableTermLookup: transformTrackableTermByLanguage({}, payload.trackable_terms),
      availableTrackables: _.get(payload, 'trackable_options', []),
      heIdsForTrackableLookup: payload.data.reduce((acc, v) => {
        const tId = v.trackable_id;
        const heIds = acc[tId] || [];
        heIds.push(v.health_entry_id);
        return {
          ...acc,
          [tId]: heIds,
        };
      }, {}),
      trackableIdsForHeLookup: payload.data.reduce((acc, v) => {
        const heId = v.health_entry_id;
        const tIds = acc[heId] || [];
        tIds.push(v.trackable_id);
        return {
          ...acc,
          [heId]: tIds,
        };
      }, {}),
    };
  }

  private queryApi = (queryKey, profileId, healthEntryId) => {
    return new Promise((res, rej) => {
      TrackablesService.getAllForHealthEntry(profileId, healthEntryId)
        .then(payload => {
          res({
            ...this.transformApiResponseForCache(payload),
            isLoaded: true,
          });
        });
    });

  }

  responseData = (): iHealthEntryTrackableResponse => {
    const isEnabled = this.profileId && this.id;
    const query: any = useQuery(this.queryKey, this.queryApi, { enabled: isEnabled, staleTime: this.staleTime });

    this.queryData = query.data || this.queryData;

    return {
      ...this.queryData,
      isLoaded: !query.isFetching,
    };
  }

  apiToggleTrackable = (heTrackableData, trackableTermId) => {
    const heTrackables = new HealthEntryTrackables(heTrackableData);

    return heTrackables.isEnabled(trackableTermId)
      ? TrackablesService.disable(this.profileId, this.id, trackableTermId)
        .then(() => {
          heTrackables.disable(trackableTermId);
          this.updateCache({
            enabledTrackables: heTrackables.data,
          });
        })
      : TrackablesService.enable(this.profileId, this.id, [trackableTermId])
        .then((payload) => {
          heTrackables.enable(payload.data);
          this.updateCache({
            enabledTrackables: heTrackables.data,
          });
        });
  }
}
interface iTrackableResponse {
  trackable: any;
  observationLookup: any;
  trackableTermLookup: any;
  isLoaded: boolean;
  isFetching: boolean;
}
class TrackableQuery {

  private id: number;
  private profileId: number;
  private queryKey: any;
  private staleTime = 5000;
  private queryData: iTrackableResponse;

  constructor(profileId, id) {
    this.profileId = profileId;
    this.id = id;

    this.queryApi = this.queryApi.bind(this);
    this.updateCache = this.updateCache.bind(this);

    this.queryKey = ['trackable', profileId, id];
    this.queryData = {
      trackable: {},
      observationLookup: {},
      trackableTermLookup: { en: {} },
      isLoaded: false,
      isFetching: false,
    };
  }

  private updateCache = (data: Partial<iTrackableResponse>) => {
    if (!this.id) { return; }
    queryCache.setQueryData(this.queryKey, {
      ...this.queryData,
      ...data,
    });
  }

  private transformTrackableDataForCache = (trackable) => {
    const observations = trackable.observations || [];
    return {
      trackable,
      observationLookup: observations.reduce((acc, v) => ({ ...acc, [v.id]: v }), {}),
    };
  }

  private transformApiResponseForCache = (payload): iTrackableResponse => {
    const trackable = { ...payload.data };

    return {
      ...this.transformTrackableDataForCache(trackable),
      trackableTermLookup: transformTrackableTermByLanguage({}, payload.trackable_terms),
      isLoaded: true,
      isFetching: false,
    };
  }

  private queryApi = (queryKey, profileId, trackableId) => {
    return new Promise((res, rej) => {
      TrackablesService.get(profileId, trackableId)
        .then(payload => {
          res(this.transformApiResponseForCache(payload));
        }).catch((apiError) => {
          rej(apiError);
        });
    });
  }

  responseData = (): iTrackableResponse => {
    const isTrackableEnabled = this.profileId && this.id;
    const query: any = useQuery(this.queryKey, this.queryApi, { enabled: isTrackableEnabled, staleTime: this.staleTime });
    this.queryData = query.data || this.queryData;

    return {
      ...this.queryData,
      isLoaded: this.queryData.isLoaded,
      isFetching: query.isFetching,
    };
  }

  apiToggleShortcut = (trackableData, trackableDefinition): Promise<any> => {
    const t = new TrackableModel(trackableData, trackableDefinition);
    t.toggleShortcut();
    this.updateCache(this.transformTrackableDataForCache(t.data));

    return TrackablesService.update(this.profileId, t.data).then(() => {
      queryCache.removeQueries('trackableShortcutList');
    });
  }

  apiDeleteTrackable = (): Promise<void> => {
    return TrackablesService.deleteTrackable(this.profileId, this.id)
      .then(() => {
        DeviceNotifications.cancelAllNotificationsForTrackable(this.id);
        updateTrackableCacheAfterObservationUpdate();
      });
  }

  apiUpdate = (trackableData): Promise<any> => {
    return new Promise((res) => {

      this.updateCache(this.transformTrackableDataForCache(trackableData));

      TrackablesService.update(this.profileId, trackableData).then(() => {
        updateTrackableCacheAfterObservationUpdate();
      });

      res();
    });
  }

  apiUpdateObservation = (oldTrackableData, observationData): Promise<any> => {
    return new Promise((res) => {
      const cleanData = new ObservationModel(observationData, '').getDataForApiUpdate();
      TrackablesService.updateObservation(this.profileId, oldTrackableData.id, observationData.id, cleanData)
        .then((updatedObservation) => {
          const t = new TrackableModel(oldTrackableData, null);
          t.updateObservation(updatedObservation);
          this.updateCache(this.transformTrackableDataForCache(t.data));
          res(t);
        });
    });
  }

  apiCreateObservation = (trackableData, observationData): Promise<any> => {
    return new Promise((res) => {
      TrackablesService.createObservation(this.profileId, trackableData.id, observationData)
        .then((d) => {
          const t = new TrackableModel(trackableData, null);
          t.updateObservation(d);
          this.updateCache(this.transformTrackableDataForCache(t.data));
          updateTrackableCacheAfterObservationUpdate();
          res(d);
        });
    });
  }

  apiDeleteObservation = (trackableData, observationId): Promise<any> => {
    return new Promise((res) => {
      TrackablesService.deleteObservation(this.profileId, trackableData.id, observationId);

      const t = new TrackableModel(trackableData, null);
      t.removeObservation(observationId);

      this.updateCache(this.transformTrackableDataForCache(t.data));

      // queryCache.invalidateQueries('trackable');
      queryCache.invalidateQueries('allHealthEntryTrackables');
      queryCache.invalidateQueries('healthEntryTrackables');
      queryCache.invalidateQueries('trackableList');

      res();
    });
  }
}

interface iDefinitionResponse {
  definition: iTrackableDefinition;
  trackableTermLookup: any;
  isLoaded: boolean;
}
class TrackableDefinitionQuery {

  private id: number;
  private profileId: number;
  private queryKey: any;
  private staleTime = 500000;
  private queryData: iDefinitionResponse;
  private isCurrent: boolean;

  constructor(profileId, trackable, isCurrent) {
    const trackableId = trackable.id;
    const definitionId = trackable.current_definition_id;
    // console.log('trackableDef query', trackable)
    this.profileId = profileId;
    this.id = definitionId;
    this.isCurrent = isCurrent || false;

    this.queryApi = this.queryApi.bind(this);
    this.queryData = {
      definition: null,
      trackableTermLookup: { en: {} },
      isLoaded: false,
    };

    this.queryKey = ['trackableDefinition', profileId, trackableId, definitionId];
  }

  private updateCache = (data: Partial<iDefinitionResponse>) => {
    queryCache.setQueryData(this.queryKey, {
      ...this.queryData,
      ...data,
    });
  }

  private transformApiResponseForCache = (payload): iDefinitionResponse => {
    return {
      definition: payload.data,
      trackableTermLookup: transformTrackableTermByLanguage({}, payload.trackable_terms),
      isLoaded: true,
    };
  }

  private queryApi(queryKey, profileId, trackableId, definitionId): Promise<iDefinitionResponse> {
    return new Promise((res, rej) => {
      const p = this.isCurrent
        ? TrackablesService.getCurrentDefinition(profileId, trackableId)
        : TrackablesService.getDefinition(profileId, definitionId);

      p.then(payload => {
        res(this.transformApiResponseForCache(payload));
      });
    });
  }

  responseData(): iDefinitionResponse {
    const isTrackableEnabled = this.profileId && this.id;
    const query: any = useQuery(this.queryKey, this.queryApi, { enabled: isTrackableEnabled, staleTime: this.staleTime });

    this.queryData = query.data || this.queryData;

    return this.queryData;
  }

  translatedResponseData(languageCode: string) {
    const d = this.responseData();

    return {
      ...this.queryData,
      definition: {
        ...d.definition,
        hint_text: d.definition.hint_text.string[languageCode] || '',
      },
      trackableTermLookup: d.trackableTermLookup[languageCode],
    };
  }
}

interface iTrackableListResponse {
  trackableList: any[];
  trackableLookup: any;
  observationLookup: any;
  trackableTermLookup: any;
  observationToTrackableId: any;
  isLoaded: boolean;
}
class TrackableListQuery {

  private profileId: number;
  private queryKey: any;
  private staleTime = 500000;
  private queryData: iTrackableListResponse;

  constructor(profileId) {
    this.profileId = profileId;

    this.queryApi = this.queryApi.bind(this);

    this.queryKey = ['trackableList', profileId];
    this.queryData = {
      trackableList: [],
      trackableLookup: {},
      observationLookup: {},
      observationToTrackableId: {},
      trackableTermLookup: { en: {} },
      isLoaded: false,
    };
  }

  private updateCache = (data: Partial<iTrackableListResponse>) => {
    queryCache.setQueryData(this.queryKey, {
      ...this.queryData,
      ...data,
    });
  }

  private transformApiResponseForCache = (payload): iTrackableListResponse => {
    return {
      trackableList: payload.data,
      trackableLookup: payload.data.reduce((acc, v) => ({ ...acc, [v.id]: v }), {}),
      observationLookup: payload.data.reduce((acc1, t) => {
        const obs = t.observations || [];
        return obs.reduce((acc2, o) => ({ ...acc2, [o.id]: o }), acc1);
      }, {}),
      observationToTrackableId: payload.data.reduce((acc1, t) => {
        const obs = t.observations || [];
        return obs.reduce((acc2, o) => ({ ...acc2, [o.id]: t.id }), acc1);
      }, {}),
      trackableTermLookup: transformTrackableTermByLanguage({}, payload.trackable_terms),
      isLoaded: true,
    };
  }

  private queryApi = (queryKey, profileId) => {
    return new Promise((res, rej) => {
      TrackablesService.getAll(profileId)
        .then(payload => {
          res(this.transformApiResponseForCache(payload));
        });
    });
  }

  responseData = (): iTrackableListResponse => {
    const isTrackableEnabled = !!this.profileId;
    const query: any = useQuery(this.queryKey, this.queryApi, { enabled: isTrackableEnabled, staleTime: this.staleTime });

    this.queryData = query.data || this.queryData;
    return this.queryData;
  }

}

interface iTrackableShortcutResponse {
  shortcutList: any[];
  trackableTermLookup: any;
  isLoaded: boolean;
}
class TrackableShortcutQuery {
  private profileId: number;
  private queryKey: any;
  private staleTime = 10000;
  private queryData: iTrackableShortcutResponse;

  constructor(profileId) {
    this.profileId = profileId;

    this.queryApi = this.queryApi.bind(this);

    this.queryKey = ['trackableShortcutList', profileId];
    this.queryData = {
      shortcutList: [],
      trackableTermLookup: {},
      isLoaded: false,
    };
  }

  private updateCache = (data: Partial<iTrackableShortcutResponse>) => {
    queryCache.setQueryData(this.queryKey, {
      ...this.queryData,
      ...data,
    });
  }

  private transformApiResponseForCache = (payload): iTrackableShortcutResponse => {
    return {
      shortcutList: payload.data || [],
      trackableTermLookup: transformTrackableTermByLanguage({}, payload.trackable_terms),
      isLoaded: true,
    };
  }

  private queryApi = (queryKey, profileId) => {
    return new Promise((res, rej) => {
      TrackablesService.getShortcuts(profileId)
        .then(payload => {
          res(this.transformApiResponseForCache(payload));
        });
    });
  }

  responseData = (): iTrackableShortcutResponse => {
    const isTrackableEnabled = !!this.profileId;
    const query: any = useQuery(this.queryKey, this.queryApi, { enabled: isTrackableEnabled, staleTime: this.staleTime });

    this.queryData = query.data || this.queryData;
    return this.queryData;
  }

}
