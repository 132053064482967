import React from 'react';
import { useQuery, queryCache } from 'react-query';

import { useSelector } from 'react-redux';
import { userSelectors as combinedUserSelectors } from '../../app/redux/selectors_authorizedApp';
import { selectors as listSelectors } from '../redux/ducks/lists/lists';

import { isAbleToMakeRequest } from '../../utilities/MobileUtil';

import MedliNames, { eMedliCategoryName, eMedliEquivalentCategoryName } from '../../constants/MedliNames';
import composeAmount from '../../models/HealthEntryCompose/amount';
import GeneVariantPresentModel from '../../models/GeneVariantPresentModel';
import geneVariantResult from '../../models/HealthEntryCompose/geneVariantResult';
import { frequencyPhraseKeys } from '../../models/Frequency';
import { medliTermConversions } from '../../models/HealthEntryCompose/medliTerm';
import HealthEntryCategoryNames from '../../constants/HealthEntryCategoryNames';
import MedicalTermsService from '../../apiServices/MedicalTermsService';
import { TrackEvent } from '../../utilities/UserMetrics';

import { useUIState } from './useUIState';
import { useActiveUser } from './useUser';
import { useLists } from './useLists';

const STALE_TIME = 100000;
const CONSTANTS_STALE_TIME = 10000000;

function createCollectionAndLookup(apiError, responseCollection) {
  const lookup = {};
  let collection = [];

  if (!apiError) {
    collection = responseCollection;
    for (let i = 0; i < responseCollection.length; i++) {
      lookup[collection[i].id] = collection[i];
    }
  }

  return {
    collection,
    lookup,
  };
}

function requestMedliCommonList(queryKey, medliCategory, languageCode, profileId) {
  return new Promise((res, rej) => {
    MedicalTermsService.list(medliCategory, languageCode, profileId, (apiError, payload) => {
      const terms = payload.data.map(term => {
        return {
          ...term,
          transaction_id: payload.transaction_id,
        };
      });

      res({
        list: terms,
        isLoaded: true,
      });
    });
  });
}

function requestMedliConstants(queryKey, medliCategory) {
  return new Promise((res, rej) => {
    MedicalTermsService.constants(medliCategory, null, (apiError, responseCollection) => {
      const responseData = createCollectionAndLookup(apiError, responseCollection);
      const collections = responseCollection.reduce((acc, val) => {
        const vals = acc[val.language_code] || [];
        vals.push(val);
        return {
          ...acc,
          [val.language_code]: vals,
        };
      }, {});

      res({
        list: responseCollection,
        isLoaded: true,
      });
    });
  });
}

function requestMedliRelatedUnitList(queryKey, medliCategory, cui, languageCode) {
  return new Promise((res, rej) => {
    if (medliCategory === eMedliCategoryName.otherTreatment) {
      res({
        list: [],
        isLoaded: true,
      });
      return;
    }

    MedicalTermsService.relatedUnits(cui, medliCategory, languageCode, (apiError, payload) => {
      res({
        list: payload.results,
        isLoaded: true,
      });
    });
  });
}

function requestCompositeUnit(queryKey, medliCategory, languageCode) {
  return new Promise((res, rej) => {
    MedicalTermsService.composite(medliCategory, languageCode, (apiError, payload) => {
      res({
        list: payload,
        isLoaded: true,
      });
    });
  });
}

function requestMedliSearch(queryKey, query, category, languageCode, profileId) {
  return new Promise((res, rej) => {
    if (!isAbleToMakeRequest()) { res([]); return; }

    MedicalTermsService.search(
      query,
      category,
      languageCode,
      profileId,
      (apiError, result) => {
        res({
          list: result.data,
          count: result.count,
          transaction_id: result.transaction_id,
        });
      },
    );
  });
}

const INITIAL_MEDLI_LIST_DATA = {
  list: [],
  isLoaded: false,
};
const INITIAL_MEDLI_CONSTANTS_DATA = {
  list: [],
  isLoaded: false,
};
const INITIAL_MEDLI_SEARCH_DATA = {
  list: [],
  count: 0,
  transaction_id: '',
};
function queryMedliList(listName, languageCode, profileId) {
  const queryData: any = useQuery(['medliList', listName, languageCode, profileId], requestMedliCommonList, { staleTime: STALE_TIME });
  return {
    ...queryData,
    data: queryData.data || INITIAL_MEDLI_LIST_DATA,
  };
}
function queryMedliConstant(listName) {
  const queryData: any = useQuery(['medliConstant', listName], requestMedliConstants, { staleTime: CONSTANTS_STALE_TIME });
  return {
    ...queryData,
    data: queryData.data || INITIAL_MEDLI_CONSTANTS_DATA,
  };
}
function queryMedliCompositeUnit(listName, languageCode) {
  const queryData: any = useQuery(['medliConstant', listName, languageCode], requestCompositeUnit, { staleTime: CONSTANTS_STALE_TIME });
  return {
    ...queryData,
    data: queryData.data || INITIAL_MEDLI_CONSTANTS_DATA,
  };
}
function queryMedliSearch(searchEnabled, query, category, medliLanguageCode, profileId) {
  const searchQuery: any = useQuery(
    ['medliSearch', query, category, medliLanguageCode, profileId],
    requestMedliSearch,
    {
      enabled: searchEnabled && category && query,
    },
  );
  return {
    ...searchQuery,
    data: searchQuery.data || INITIAL_MEDLI_SEARCH_DATA,
  };
}

function queryMedliRelatedUnits(medliCategory, cui, languageCode) {
  const queryData: any = useQuery(
    ['medliRelatedUnitList', medliCategory, cui, languageCode],
    requestMedliRelatedUnitList,
    {
      enabled: Boolean(medliCategory) && Boolean(cui),
      staleTime: STALE_TIME,
    },
  );

  return queryData.data ? queryData : {
    ...queryData,
    data: INITIAL_MEDLI_LIST_DATA,
  };
}

// ===========================

export function useMedliSearch(overrideMedliLangaugeCode?) {
  const [uiState, uiActions] = useUIState();
  const { activeProfileId } = uiState;
  const [{ user, preferredLanguageCode }] = useActiveUser();
  const [{ localeMedliLookup, localeLookup }] = useLists();

  const [state, setState] = React.useState(() => {
    const defaultMedliLocaleId = localeLookup[user.locale_id].default_medli_locale_id;
    const defaultLanguageCode = localeMedliLookup[defaultMedliLocaleId].language_code || preferredLanguageCode;
    return {
      searchEnabled: false,
      query: uiState.search.query || '',
      category: uiState.search.category || '',
      medliLanguageCode: uiState.search.medliLanguageCode || overrideMedliLangaugeCode || defaultLanguageCode,
      searchAttemptCount: 0,
    };
  });

  const searchQuery = queryMedliSearch(state.searchEnabled, state.query, state.category, state.medliLanguageCode, activeProfileId);
  const { isLoading, isFetching, isSuccess } = searchQuery;
  const isSearching = isLoading || isFetching;
  const searchResults = searchQuery.data.list;

  return [
    {
      searchResults: isSearching ? [] : searchResults,
      resultsCount: searchQuery.data.count,
      category: state.category,
      searchQuery: state.query,
      searchAttemptCount: state.searchAttemptCount,
      medliLanguageCode: state.medliLanguageCode,
      isFetching: isSearching,
      isLoaded: state.searchEnabled && !isSearching && isSuccess,
    },
    {
      submitSearch: (query, category, medliLanguageCode) => {
        queryCache.invalidateQueries('medliSearch');

        setState({
          ...state,
          query,
          category,
          medliLanguageCode,
          searchEnabled: true,
          searchAttemptCount: state.searchAttemptCount + 1,
        });
      },
      clearSearch: () => {
        setState({
          ...state,
          query: '',
          category: '',
          searchEnabled: false,
        });
        uiActions.clearSearch();
      },
      cancelSearch: (category) => {
        if (state.searchAttemptCount === 0) {
          TrackEvent.medli.cancelSearchBeforeSearching(category);
        }
        setState({
          ...state,
          query: '',
          category: '',
          searchEnabled: false,
        });
        uiActions.clearSearch();
      },
      setSearchParameters: (category, healthEntryName) => {
        uiActions.prefillSearch({ category, query: healthEntryName });
      },
    },
  ];
}

// CONSTANTS
// =================================================
export function useMedliConstantsStoppingReasons() {
  const [queryData] = useMedliConstants(MedliNames.constant.stoppingReason);
  const stoppingReasonList = queryData.data;
  const isLoaded = queryData.isLoaded;

  return [
    {
      stoppingReasonList,
      isLoaded,
    },
    {
      isReaction: (d) => {
        if (!d || !d.id) { return false; }
        return Boolean(stoppingReasonList.find(a => a.id === d.id));
      },
    },
  ];
}
export function useMedliConstantsUnitNA(forceLanguageCode?) {
  const [queryData] = useMedliConstants(MedliNames.constant.unitNA);
  const naUnitList = queryData.data;
  const isLoaded = queryData.isLoaded;
  return [{ naUnitList, isLoaded }];
}

export function useMedliConstantsAllergy(forceLanguageCode?) {
  const [queryData] = useMedliConstants(MedliNames.constant.allergy);
  const allergyList = queryData.data;
  const isLoaded = queryData.isLoaded;

  return [{ allergyList, isLoaded }];
}

export function useMedliConstantsDescriptor(forceLanguageCode?) {
  const [{ preferredLanguageCode }] = useActiveUser();
  const languageCode = forceLanguageCode || preferredLanguageCode;

  const [queryData] = useMedliConstants(MedliNames.constant.descriptor);
  const descriptorList = queryData.data;
  const isLoaded = queryData.isLoaded;
  const descriptorListForLocale = descriptorList.filter(d => d.language_code === languageCode);

  return [{ descriptorList: descriptorListForLocale, isLoaded }];
}

export function useMedliCompositeUnit(unit?) {
  const [{ preferredLanguageCode }] = useActiveUser();
  const queryData: any = queryMedliCompositeUnit(MedliNames.composite.unit, preferredLanguageCode);
  const compositeUnits = queryData.data.list;
  const isLoaded = queryData.data.isLoaded;
  const compositeUnit = unit ? compositeUnits.find(u => u.id === unit.id) : null;

  return [
    {
      unit: compositeUnit || unit,
      originalUnit: unit,
      isLoaded,
    },
  ];
}


// MEDLI LISTS - UNIT
// =================================================

export function useAmountUnit(amount, allowCompositeUnit, forceLanguageCode?) {
  const [{ preferredLanguageCode }] = useActiveUser();
  const amountInstance = composeAmount({ amount }, preferredLanguageCode);
  const origUnit = amountInstance.getUnit();

  const [compositeUnitData] = useMedliCompositeUnit(origUnit);
  const [{ naUnitList, ...naUnitData }] = useMedliConstantsUnitNA();
  const isLoaded = compositeUnitData.isLoaded && naUnitData.isLoaded;
  const unit = allowCompositeUnit ? compositeUnitData.unit : origUnit;
  amountInstance.setUnit(unit);
  const unitPairs = amountInstance.getValueUnitPairs();

  const unitNaIds = naUnitList.map(a => a.id);
  const isNA = unitNaIds.includes(unit.id);
  const showUnit = !isNA && !!unit.id;

  const isCompositeUnit = unitPairs.length > 1;

  return [
    {
      value: amountInstance.getValue(),
      unit,
      isNA,
      unitPairs,
      isCompositeUnit,
      showUnit,
      isLoaded,
    },
    {
      getUnitFromSearchTerm: (medliSearchTermUnit) => {
        const newUnit = medliTermConversions.convertSearchTermToTerm(medliSearchTermUnit, preferredLanguageCode);
        const newCompositeUnit = useMedliCompositeUnit(newUnit) || {};
        return allowCompositeUnit ? newCompositeUnit : newUnit;
      },
    },
  ];
}

export function useRelatedUnits(heCategory, cui) {
  const [{ preferredLanguageCode }] = useActiveUser();
  const medliEquivalentCategory = eMedliEquivalentCategoryName[heCategory];
  const queryData = queryMedliRelatedUnits(medliEquivalentCategory, cui, preferredLanguageCode);
  const isLoaded = queryData.data.isLoaded;
  const relatedUnitList = queryData.data.list;

  return [
    {
      relatedUnitList,
      isLoaded,
    },
  ];
}

export function useMedliUnits() {
  const [{ preferredLanguageCode }] = useActiveUser();

  const naQuery: any = queryMedliConstant(MedliNames.constant.unitNA);
  const compQuery: any = queryMedliCompositeUnit(MedliNames.composite.unit, preferredLanguageCode);
  const naUnitList = naQuery.data.list;
  const compositeUnitList = compQuery.data.data;

  return [{
    naUnitList,
    compositeUnitList,
    isLoaded: naQuery.data.isLoaded && compQuery.data.isLoaded,
  }];
}

// MEDLI LISTS
// =================================================

export function useMedliList(medliCategoryName, forceLanguageCode?) {
  const [{ activeProfileId }] = useUIState();
  const [{ preferredLanguageCode }] = useActiveUser();
  const languageCode = forceLanguageCode || preferredLanguageCode;
  const queryData = queryMedliList(medliCategoryName, languageCode, activeProfileId);
  const list = queryData.data.list;
  const isLoaded = queryData.data.isLoaded;
  return [{ list, isLoaded }];
}

export function useMedliConstants(medliCategoryName) {
  const queryData = queryMedliConstant(medliCategoryName);
  const data = queryData.data.list;
  const isLoaded = queryData.data.isLoaded;
  return [{ data, isLoaded }];
}

export function useMedliAmountUnitList(heCategory) {
  const languageCode = useSelector(state => combinedUserSelectors.getPreferredLanguageCode(state));

  let medliUnitCategory = '';
  switch (heCategory) {
    case HealthEntryCategoryNames.measurement:
      medliUnitCategory = MedliNames.list.unitMeasurement;
      break;
    case HealthEntryCategoryNames.lab:
      medliUnitCategory = MedliNames.list.unitLab;
      break;
    default:
      break;
  }

  const compQuery: any = queryMedliCompositeUnit(MedliNames.composite.unit, languageCode);
  const responseData = compQuery.data;
  const compositeUnits = responseData.list;
  const [medliListData] = useMedliList(medliUnitCategory);
  const categorySpecificUnitList = medliListData.list;

  const isLoaded = responseData.isLoaded && medliListData.isLoaded;

  return [
    { categorySpecificUnitList, isLoaded },
    {
      getUnitFromSearchTerm: (medliSearchTermUnit, allowCompositeUnit?) => {
        const newUnit = medliTermConversions.convertSearchTermToTerm(medliSearchTermUnit, languageCode);
        const compositeUnit = compositeUnits.find(u => u.id === newUnit.id) || newUnit;

        const newCompositeUnit = compositeUnit || newUnit || {};
        return allowCompositeUnit ? newCompositeUnit : newUnit;
      },
    },
  ];
}

export function useMedliAnatomyList() {
  const [medliListQueryData] = useMedliList(MedliNames.list.anatomy);
  const anatomyList = medliListQueryData.list;
  const isLoaded = medliListQueryData.isLoaded;
  return [{ anatomyList, isLoaded }];
}

export function useMedliDescriptorList() {
  const [medliListQueryData] = useMedliList(MedliNames.list.descriptor);
  const descriptorList = medliListQueryData.list;
  const isLoaded = medliListQueryData.isLoaded;
  return [{ descriptorList, isLoaded }];
}

const originalFrequencyGroups = {
  hours: [
    { key: frequencyPhraseKeys.hours.every.whole, value: '1', id: null },
    { key: frequencyPhraseKeys.hours.every.whole, value: '4', id: null },
    { key: frequencyPhraseKeys.hours.every.whole, value: '6', id: null },
    { key: frequencyPhraseKeys.hours.every.whole, value: '8', id: null },
    { key: frequencyPhraseKeys.hours.every.whole, value: '12', id: null },
  ],
  days: [
    { key: frequencyPhraseKeys.days.times.whole, value: '1', id: null },
    { key: frequencyPhraseKeys.days.times.whole, value: '2', id: null },
    { key: frequencyPhraseKeys.days.every.whole, value: '2', id: null },
    { key: frequencyPhraseKeys.days.every.whole, value: '3', id: null },
  ],
  weeks: [
    { key: frequencyPhraseKeys.weeks.times.whole, value: '1', id: null },
    { key: frequencyPhraseKeys.weeks.times.whole, value: '2', id: null },
    { key: frequencyPhraseKeys.weeks.times.whole, value: '3', id: null },
    { key: frequencyPhraseKeys.weeks.every.whole, value: '2', id: null },
  ],
  months: [
    { key: frequencyPhraseKeys.months.times.whole, value: '1', id: null },
    { key: frequencyPhraseKeys.months.every.whole, value: '2', id: null },
    { key: frequencyPhraseKeys.months.every.whole, value: '3', id: null },
    { key: frequencyPhraseKeys.months.every.whole, value: '6', id: null },
    { key: frequencyPhraseKeys.months.every.whole, value: '12', id: null },
  ],
  other: [
    { key: frequencyPhraseKeys.other.once, value: '0', id: null },
    { key: frequencyPhraseKeys.other.asNeeded, value: '0', id: null },
    { key: frequencyPhraseKeys.other.continuously, value: '0', id: null },
  ],
};

export function useFrequencyList() {
  const frequencyList = useSelector(state => listSelectors.getFrequencyList(state));
  return [
    {
      frequencyList,
      isLoaded: frequencyList.length > 0,
    }, {
      get: (id) => {
        return id ? frequencyList.find(f => f.id === id) : null;
      },
      getFromPhraseKey: (phraseKey) => {
        return frequencyList.find((f) => f.key === phraseKey);
      },
      getGroupedFrequencies: () => {
        const frequencyMap = frequencyList.reduce((acc, f) => ({ ...acc, [f.key]: f }), {});

        const groupNames = Object.keys(originalFrequencyGroups);
        if (Object.keys(frequencyMap).length === 0) { return originalFrequencyGroups; }

        const newFrequencyGroups = {};
        groupNames.forEach((name) => {
          newFrequencyGroups[name] = originalFrequencyGroups[name].map((f) => ({
            key: f.key,
            value: f.value,
            id: frequencyMap[f.key].id,
            group: frequencyMap[f.key].group,
          }));
        });

        return newFrequencyGroups;
      },
    },
  ];
}

export function useMedliMedicationUnit() {
  const [medliListQueryData] = useMedliList(MedliNames.list.unitMedication);
  const medicationUnitList = medliListQueryData.list;
  const isLoaded = medliListQueryData.isLoaded;
  return [{ medicationUnitList, isLoaded }];
}

export function useMedliReactionList() {
  const [medliListQueryData] = useMedliList(MedliNames.list.reaction);
  const reactionList = medliListQueryData.list;
  const isLoaded = medliListQueryData.isLoaded;
  return [{ reactionList, isLoaded }];
}

export function useMedliStoppingReasonList() {
  const [medliListQueryData] = useMedliList(MedliNames.list.stoppingReason);
  const stoppingReasonList = medliListQueryData.list;
  const isLoaded = medliListQueryData.isLoaded;

  return [{ stoppingReasonList, isLoaded }];
}

export function useGeneVariantPresent(heData) {
  const gvResult = geneVariantResult(heData);
  const variantId = gvResult.getVariantPresentId();
  const [{ geneVariantPresentLookup, isLoaded }] = useLists();
  const geneVariantPresentData = geneVariantPresentLookup[variantId] || {};
  const geneVariantPresent = new GeneVariantPresentModel(geneVariantPresentData);

  return [
    {
      hasGeneVariant: geneVariantPresent.hasVariant(),
      geneVariant: geneVariantPresent,
      isLoaded,
    },
  ];
}

export function useMedliGeneExonFrameType() {
  const [medliListQueryData] = useMedliList(MedliNames.list.geneExonFrameType);
  const geneExonFrameTypeList = medliListQueryData.list;
  const isLoaded = medliListQueryData.isLoaded;
  return [{ geneExonFrameTypeList, isLoaded }];
}
export function useMedliGeneMutationType() {
  const [medliListQueryData] = useMedliList(MedliNames.list.geneMutationType);
  const geneMutationTypeList = medliListQueryData.list;
  const isLoaded = medliListQueryData.isLoaded;
  return [{ geneMutationTypeList, isLoaded }];
}
export function useMedliGeneVariantClassification() {
  const [medliListQueryData] = useMedliList(MedliNames.list.geneVariantClassification);
  const geneVariantClassificationList = medliListQueryData.list;
  const isLoaded = medliListQueryData.isLoaded;
  return [{ geneVariantClassificationList, isLoaded }];
}
export function useMedliGeneZygosityType() {
  const [medliListQueryData] = useMedliList(MedliNames.list.geneZygosityType);
  const geneZygosityTypeList = medliListQueryData.list;
  const isLoaded = medliListQueryData.isLoaded;
  return [{ geneZygosityTypeList, isLoaded }];
}

export function useMedliVaccinationList() {
  const [medliListQueryData] = useMedliList(MedliNames.list.vaccination);
  const vaccinationList = medliListQueryData.list;
  const isLoaded = medliListQueryData.isLoaded;
  return [{ vaccinationList, isLoaded }];
}

export function useAllMedliSuggestions(languageCode, profileId) {
  // these can only be called if user has an active profile
  queryMedliList(MedliNames.list.foodAllergy, languageCode, profileId);
  queryMedliList(MedliNames.list.medicationAllergy, languageCode, profileId);
  queryMedliList(MedliNames.list.allergy, languageCode, profileId);
  queryMedliList(MedliNames.list.vaccination, languageCode, profileId);
  queryMedliList(MedliNames.list.unitMedication, languageCode, profileId);
  queryMedliList(MedliNames.list.reaction, languageCode, profileId);
  queryMedliList(MedliNames.list.stoppingReason, languageCode, profileId);
  queryMedliList(MedliNames.list.measurement, languageCode, profileId);
  queryMedliList(MedliNames.list.unitMeasurement, languageCode, profileId);
  queryMedliList(MedliNames.list.unitLab, languageCode, profileId);
  queryMedliList(MedliNames.list.descriptor, languageCode, profileId);
  queryMedliList(MedliNames.list.condition, languageCode, profileId);
  queryMedliList(MedliNames.list.lab, languageCode, profileId);
  queryMedliList(MedliNames.list.medication, languageCode, profileId);
  queryMedliList(MedliNames.list.otherTreatment, languageCode, profileId);
  queryMedliList(MedliNames.list.anatomy, languageCode, profileId);
  queryMedliList(MedliNames.list.gene, languageCode, profileId);
  queryMedliList(MedliNames.list.geneVariantClassification, languageCode, profileId);
  queryMedliList(MedliNames.list.geneMutationType, languageCode, profileId);
  queryMedliList(MedliNames.list.geneExonFrameType, languageCode, profileId);
  queryMedliList(MedliNames.list.geneZygosityType, languageCode, profileId);
}

export function useAllMedliConstants(languageCode) {
  queryMedliConstant(MedliNames.constant.allergy);
  queryMedliConstant(MedliNames.constant.stoppingReason);
  queryMedliConstant(MedliNames.constant.unitNA);
  queryMedliConstant(MedliNames.constant.descriptor);
  queryMedliCompositeUnit(MedliNames.composite.unit, languageCode);
}
