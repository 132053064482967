import React from 'react';
import RouteNames from '../app/appAuthorized/RouteNames';

export interface HealthEntryGeneAttributeValueInterface {
  geneVariantResultKey: string;
  isMedliTerm: boolean;
  getFormattedValue?: Function;
}

export interface HealthEntryGeneAttributeInterface {
  name: string;
  buttonLabelId: string;
  formatLabelId?: string;
  routeName: Function;
  values: HealthEntryGeneAttributeValueInterface[];
}

const HealthEntryGeneAttributes: HealthEntryGeneAttributeInterface[] = [
  {
    name: 'mutationType',
    buttonLabelId: 'healthEntry.addGeneMutationType.button.label',
    routeName: RouteNames.healthEntryValueGeneMutationType,
    values: [{
      geneVariantResultKey: 'gene_mutation_type',
      isMedliTerm: true,
    }],
  },
  {
    name: 'dnaChange',
    buttonLabelId: 'healthEntry.addGeneDNAChange.button.label',
    routeName: RouteNames.healthEntryValueGeneDNAChange,
    values: [{
      geneVariantResultKey: 'gene_dna_change',
      isMedliTerm: false,
    }],
  },
  {
    name: 'proteinChange',
    buttonLabelId: 'healthEntry.addGeneProteinChange.button.label',
    routeName: RouteNames.healthEntryValueGeneProteinChange,
    values: [{
      geneVariantResultKey: 'gene_protein_change',
      isMedliTerm: false,
    }],
  },
  {
    name: 'exon',
    buttonLabelId: 'healthEntry.addGeneExon.button.label',
    formatLabelId: 'healthEntry.geneExon.label',
    routeName: RouteNames.healthEntryValueGeneExon,
    values: [{
      geneVariantResultKey: 'gene_exon_range',
      isMedliTerm: false,
    }, {
      geneVariantResultKey: 'gene_exon_frame_type',
      isMedliTerm: true,
    }],
  },
  {
    name: 'zygosity',
    buttonLabelId: 'healthEntry.addGeneZygosity.button.label',
    routeName: RouteNames.healthEntryValueGeneZygosity,
    values: [{
      geneVariantResultKey: 'gene_zygosity_type',
      isMedliTerm: true,
    }],
  },
];

export default HealthEntryGeneAttributes;
