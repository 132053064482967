import React, { FC } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import GroupMetadata, { I18nTextType, I18nImageType, I18nImageCollectionType } from '../../models/GroupMetadata';
import { GroupMetadataImageType } from '../../models/GroupMetadataImageModel';

interface Props {
  intl: any;
  data: I18nTextType;
  className?: string;
  style?: object;
  children?: any;
}
const BasicI18nText: FC<Props> = (props) => {
  const locale = props.intl.locale;
  if (!props.data) { return null; }

  const text = props.data.i18n[locale] || props.data.default || '';

  if (typeof props.children === 'function') {
    return props.children(text);
  }

  if (!text) { return null; }

  if (props.className || props.style) {
    return (
      <div className={props.className} style={props.style}>{text}</div>
    );
  }

  return (
    <>
      {text}
    </>
  );
};
const I18nText = injectIntl(BasicI18nText);

interface I18nImageProps {
  intl: any;
  data: I18nImageType;
  height?: string;
  width?: string;
  style?: object;
  className?: string;
  children?: any;
}
const BasicI18nImage: FC<I18nImageProps> = (props) => {
  const locale = props.intl.locale;
  let imageData = (props.data.i18n && props.data.i18n[locale]) ? props.data.i18n[locale] : props.data.default;
  if (!imageData) {
    imageData = { url: '', alt_text: '' };
  }
  if (typeof props.children === 'function') {
    return props.children(imageData);
  }

  if (!imageData.url) { return null; }
  return (
    <img className={props.className} src={imageData.url} alt={imageData.alt_text} width={props.width} height={props.height} style={props.style} />
  );
};
export const I18nImage = injectIntl(BasicI18nImage);

interface I18nImageCollectionProps {
  intl: any;
  data: I18nImageCollectionType;
  height?: string;
  width?: string;
  children?: any;
}
const BasicI18nImageCollection: FC<I18nImageCollectionProps> = (props) => {
  const locale = props.intl.locale;
  const images = props.data.i18n[locale] || props.data.default || [];

  if (typeof props.children === 'function') {
    return props.children(images);
  }

  if (images.length === 0) { return null; }

  return images.map((im) => {
    return <img key={im.id} src={im.url} alt={im.alt_text} height={props.height} width={props.width} />;
  });
};
export const I18nImageCollection = injectIntl(BasicI18nImageCollection);

interface FormattedMessageWithI18nValuesProps {
  phraseId: string;
  i18nValues: {
    [key: string]: I18nTextType;
  };
}

export const FormattedMessageWithI18nValues: FC<FormattedMessageWithI18nValuesProps> = (props) => {
  const values = {};
  Object.entries(props.i18nValues).forEach(([key, val]) => {
    values[key] = <I18nText data={val} />;
  });
  return (
    <FormattedMessage id={props.phraseId} values={values} />
  );
};

export default I18nText;
