
import React from 'react';

import ContactModel from '../../models/ContactModel';

import { useContactsForActiveProfile } from './useContacts';
import { useLists } from './useLists';


const UIEDIT_ACTIONS = {
  HEALTH_ENTRY: 'uiEdit/HEALTH_ENTRY',
  HEALTH_ENTRY_VALUE: 'uiEdit/HEALTH_ENTRY_VALUE',
  DEPENDENT_PROFILE: 'uiEdit/DEPENDENT_PROFILE',
  CONTACT: 'uiEdit/CONTACT',
  ATTACHMENT: 'uiEdit/ATTACHMENT',
  OBSERVATION: 'uiEdit/OBSERVATION',
  REMINDER: 'uiEdit/REMINDER',
};

const initialState = {
  healthEntry: {},
  healthEntryValue: {},
  dependentProfile: {},
  contact: {},
  attachment: {},
  observation: {},
  reminder: {},
};

function uiEditReducer(state, action) {
  switch (action.type) {
    case UIEDIT_ACTIONS.HEALTH_ENTRY:
      return { ...state, healthEntry: action.payload };
    case UIEDIT_ACTIONS.HEALTH_ENTRY_VALUE:
      return { ...state, healthEntryValue: action.payload };
    case UIEDIT_ACTIONS.DEPENDENT_PROFILE:
      return { ...state, dependentProfile: action.payload };
    case UIEDIT_ACTIONS.CONTACT:
      return { ...state, contact: action.payload };
    case UIEDIT_ACTIONS.ATTACHMENT:
      return { ...state, attachment: action.payload };
    case UIEDIT_ACTIONS.OBSERVATION:
      return { ...state, observation: action.payload };
    case UIEDIT_ACTIONS.REMINDER:
      return { ...state, reminder: action.payload };
    default:
      throw new Error(`Unhandled redux action: ${action.type}`);
  }
}

function useUIEditReducer() {
  const [state, dispatch] = React.useReducer(uiEditReducer, initialState);

  return [
    state, {
      dispatch,
      clearHealthEntry: () => {
        dispatch({
          type: UIEDIT_ACTIONS.HEALTH_ENTRY,
          payload: {},
        });
      },
      updateHealthEntry: (payload) => {
        dispatch({
          type: UIEDIT_ACTIONS.HEALTH_ENTRY,
          payload,
        });
      },
      updateContact: (payload) => {
        dispatch({
          type: UIEDIT_ACTIONS.CONTACT,
          payload,
        });
      },
      clearContact: () => {
        dispatch({
          type: UIEDIT_ACTIONS.CONTACT,
          payload: {},
        });
      },
      updateAttachment: (payload) => {
        dispatch({
          type: UIEDIT_ACTIONS.ATTACHMENT,
          payload,
        });
      },
      clearAttachment: () => {
        dispatch({
          type: UIEDIT_ACTIONS.ATTACHMENT,
          payload: {},
        });
      },
    },
  ];
}
const UIEditContext = React.createContext(null);

export const UIEditProvider = (props) => {
  const [state, actions] = useUIEditReducer();

  return (
    <UIEditContext.Provider value={[state, actions]}>
      {props.children}
    </UIEditContext.Provider>
  );
};

export function useUIEdit() {
  const [uiEditState, uiEditActions] = React.useContext(UIEditContext);

  return [{
    ...uiEditState,
  }, uiEditActions];
}

export function useUIEditHealthEntry() {
  const [uiEditState, uiEditActions] = React.useContext(UIEditContext);

  const editingHealthEntry = uiEditState.healthEntry;

  return [
    {
      editingHealthEntry,
    },
    {
      updateHealthEntry: (heData) => {
        uiEditActions.dispatch({
          type: UIEDIT_ACTIONS.HEALTH_ENTRY,
          payload: { ...heData },
        });
      },
      clearHealthEntry: () => {
        uiEditActions.dispatch({
          type: UIEDIT_ACTIONS.HEALTH_ENTRY,
          payload: {},
        });
      },
    },
  ];
}

export function useUIEditHealthEntryValue() {
  const [uiEditState, uiEditActions] = React.useContext(UIEditContext);

  const editingHealthEntryValue = uiEditState.healthEntryValue;

  return [
    {
      editingHealthEntryValue,
    },
    {
      updateHealthEntryValue: (heValueData) => {
        uiEditActions.dispatch({
          type: UIEDIT_ACTIONS.HEALTH_ENTRY_VALUE,
          payload: { ...heValueData },
        });
      },
      clearHealthEntryValue: () => {
        uiEditActions.dispatch({
          type: UIEDIT_ACTIONS.HEALTH_ENTRY_VALUE,
          payload: {},
        });
      },
    },
  ];
}

export function useUIEditContact(contactId, profileId?, isDefaultProvider?) {
  const [uiEditState, uiEditActions] = React.useContext(UIEditContext);

  const [{ contact, isLoaded }] = useContactsForActiveProfile(contactId);
  const [{ contactRelationshipList, contactSpecialtyList, contactSpecialtyCustomValue, contactRelationshipHealthcareProviderValue }] = useLists();

  let editingContact;
  if (uiEditState.contact.id) {
    editingContact = uiEditState.contact;
  } else if (Boolean(contactId)) {
    editingContact = contact;
  } else if (isDefaultProvider) {
    editingContact = ContactModel.defaultProviderContact({ id: 'add', contact_relationship_id: contactRelationshipHealthcareProviderValue.id }, profileId);
  } else if (profileId) {
    editingContact = ContactModel.defaultContact({ id: 'add' }, profileId);
  } else {
    editingContact = ContactModel.defaultContact(null, null);
  }

  return [
    {
      editingContact,
      isLoaded,
    },
    {
      updateContact: (newData) => {
        uiEditActions.updateContact(newData);
      },
      clearContact: () => {
        uiEditActions.clearContact();
      },
      updateRelationship: (relId, customText?) => {
        return new Promise((res, rej) => {
          const newContactData = ContactModel.defaultContact(editingContact, null);
          newContactData.contact_relationship_id = relId;
          newContactData.relationship_custom_text = customText || null;

          if (ContactModel.isRelationshipValid(newContactData, contactRelationshipList)) {
            const cleanedData = ContactModel.cleanRelationship(newContactData, contactRelationshipList);
            uiEditActions.updateContact(cleanedData);
            res(cleanedData);
          } else {
            rej();
          }
        });
      },
      updateSpecialty: (specialtyId, customText, enableValidation) => {
        return new Promise((res, rej) => {
          const newContactData = ContactModel.defaultContact(editingContact, null);

          if (contactSpecialtyCustomValue.id === specialtyId) {
            if (customText) {
              newContactData.contact_specialty_id = specialtyId;
              newContactData.specialty_custom_text = customText;
            } else {
              newContactData.contact_specialty_id = null;
              newContactData.specialty_custom_text = null;
            }
          } else {
            newContactData.contact_specialty_id = specialtyId;
            newContactData.specialty_custom_text = null;
          }

          if (!enableValidation || ContactModel.isSpecialtyValid(newContactData, contactSpecialtyList)) {
            const cleanedData = ContactModel.cleanSpecialty(newContactData, contactSpecialtyList);
            uiEditActions.updateContact(cleanedData);
            res();
          } else {
            rej();
          }
        });
      },
      updateHealthcareProxy: (healthCareProxyIds) => {
        return new Promise((res, rej) => {
          const newContactData = ContactModel.defaultContact(editingContact, null);
          newContactData.contact_healthcare_proxy_ids = healthCareProxyIds;
          uiEditActions.updateContact(newContactData);
          res();
        });
      },
      updateDetailLabel: (detailId, detailLabelId) => {
        return new Promise((res, rej) => {
          const newContactData = ContactModel.defaultContact(editingContact, null);
          newContactData.details = newContactData.details.map(d => {
            return d.id === detailId ? { ...d, contact_detail_label_id: detailLabelId } : d;
          });

          uiEditActions.updateContact(newContactData);
          res();
        });
      },
    },
  ];
}

export function useUIEditObservation() {
  const [uiEditState, uiEditActions] = React.useContext(UIEditContext);
  const editingObservation = uiEditState.observation || {};

  return [
    {
      editingObservation,
    },
    {
      updateObservation: (newData) => {
        uiEditActions.dispatch({
          type: UIEDIT_ACTIONS.OBSERVATION,
          payload: { ...newData },
        });
      },
      clear: () => {
        uiEditActions.dispatch({
          type: UIEDIT_ACTIONS.OBSERVATION,
          payload: {},
        });
      },
    },
  ];
}

export function useUIEditReminder() {
  const [uiEditState, uiEditActions] = React.useContext(UIEditContext);
  const editingReminder = uiEditState.reminder || {};

  return [
    {
      editingReminder,
      isLoaded: Object.keys(editingReminder).length > 0,
    },
    {
      updateReminder: (newData) => {
        uiEditActions.dispatch({
          type: UIEDIT_ACTIONS.REMINDER,
          payload: { ...newData },
        });
      },
      clear: () => {
        uiEditActions.dispatch({
          type: UIEDIT_ACTIONS.REMINDER,
          payload: {},
        });
      },
    },
  ];
}
