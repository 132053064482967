import React from 'react';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  colorName?: string;
}

const DEFAULT_COLOR = 'gsMedLight';

const getClassName = (colorName = DEFAULT_COLOR) => {
  return colorClassNames.stroke(colorName);
};

const stylesSmall = {
  fill: 'none',
  strokeMiterlimit: 10,
  strokeWidth: 1.25,
};

const Small = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g>
      <circle className={getClassName(props.colorName)} style={stylesSmall} cx="8" cy="8" r="1.23"/>
      <circle className={getClassName(props.colorName)} style={stylesSmall} cx="2.23" cy="8" r="1.23"/>
      <circle className={getClassName(props.colorName)} style={stylesSmall} cx="13.77" cy="8" r="1.23"/>
    </g>
  </svg>
);

const stylesMedium = {
  fill: 'none',
  strokeMiterlimit: 10,
  strokeWidth: 1.5,
};

const Medium = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g>
      <circle className={getClassName(props.colorName)} style={stylesMedium} cx="12" cy="12" r="1.75"/>
      <circle className={getClassName(props.colorName)} style={stylesMedium} cx="3.82" cy="12" r="1.75"/>
      <circle className={getClassName(props.colorName)} style={stylesMedium} cx="20.18" cy="12" r="1.75"/>
    </g>
  </svg>
);

const stylesLarge = {
  fill: 'none',
  strokeMiterlimit: 10,
  strokeWidth: 2,
};

const Large = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <g>
      <circle className={getClassName(props.colorName)} style={stylesLarge} cx="18" cy="18" r="2.64"/>
      <circle className={getClassName(props.colorName)} style={stylesLarge} cx="5.64" cy="18" r="2.64"/>
      <circle className={getClassName(props.colorName)} style={stylesLarge} cx="30.36" cy="18" r="2.64"/>
    </g>
  </svg>
);

const stylesXLarge = {
  fill: 'none',
  strokeMiterlimit: 10,
  strokeWidth: 2.75,
};

const XLarge = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
    <g>
      <circle className={getClassName(props.colorName)} style={stylesXLarge} cx="28" cy="28" r="4"/>
      <circle className={getClassName(props.colorName)} style={stylesXLarge} cx="9.31" cy="28" r="4"/>
      <circle className={getClassName(props.colorName)} style={stylesXLarge} cx="46.69" cy="28" r="4"/>
    </g>
  </svg>
);

export default {
  Small,
  Medium,
  Large,
  XLarge,
};
