
import React from 'react';
// import { useQuery } from 'react-query';

import { useDispatch, useSelector } from 'react-redux';
import { selectors as notificationSelectors, actions as notificationActions } from '../redux/ducks/notifications';

// import { receivedNotifications } from '../redux/ducks/notifications';
import AppUtils from '../../utilities/AppUtils';


// import NotificationService from '../../apiServices/NotificationService';

// function fetchNotifications() {
//   return new Promise((res, rej) => {
//     NotificationService.getAll((err, data) => {
//       if (err) {
//         rej(err);
//         return;
//       }
//       res(data);
//     });
//   });
// }

// export function useNotifications() {
//   const dispatch = useDispatch();
//   const notificationQuery = useQuery(['notifications'], fetchNotifications);

//   const isLoaded = Boolean(notificationQuery.data) && notificationQuery.status === 'success';
//   React.useEffect(() => {
//     if (isLoaded) {
//       dispatch(receivedNotifications(notificationQuery.data));
//     }
//   }, [isLoaded]);

//   return notificationQuery;
// }

function clearNotificationTimer() {
  if (window.NOTIFICATION_TIMER) {
    clearTimeout(window.NOTIFICATION_TIMER);
    window.NOTIFICATION_TIMER = null;
  }
}

function startNotificationTimer(dispatch, failureCount) {
  window.NOTIFICATION_TIMER = setTimeout(() => {
    dispatch(notificationActions.requestCount())
    .then(() => {
      clearNotificationTimer();
      startNotificationTimer(dispatch, 4);
    })
    .catch((apiError) => {
      clearNotificationTimer();
      const newFailureCount = apiError.isUnauthorized() ? (failureCount - 1) : failureCount;

      if (newFailureCount < 1) {
        AppUtils.logout({}, () => { AppUtils.reloadApp(); });
      } else {
        startNotificationTimer(dispatch, newFailureCount);
      }
    });
  }, 5000);
}

function normalCountInterval(dispatch) {
  const failureCount = 4;

  clearNotificationTimer();
  startNotificationTimer(dispatch, failureCount);
}

export function useNotificationInterval() {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    dispatch(notificationActions.requestCount())
    .then(() => {
      setIsLoaded(true);
    });
    normalCountInterval(dispatch);
  }, []);

  return [{ isLoaded }];
}

export function useNotifications() {
  const notificationCount = useSelector(state => notificationSelectors.getCount(state));

  return [
    {
      notificationCount,
    },
  ];
}
