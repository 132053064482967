import { externalApi } from '../apiServices/ApiRequest';

declare const APP_ROOT: string;

export async function loadAppManifest() {
  const response = await externalApi('get', `${APP_ROOT}manifest.json`).responseType('json');
  Object.assign(appManifest, response.body);
}

export const appManifest = {
  branch: '',
  sha: '',
  build_number: '',
  app_version: '',
};
