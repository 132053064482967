import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { HealthEntryDateSummaryDate, HealthEntryDateSummaryContext } from '../../../../localization/LocalizedDate';
import { LocalizedTime } from '../../../../localization/LocalizedTime';

import { Card, Inputs } from '../../../../../bphComponents/bundle';
import { useUIState } from '../../../../hooks/useUIState';
import { DatePicker, TimePicker } from './DateTimePicker';
import { DateTimeModel, iDateTime } from '../../../../../models/DateTimeModel';

interface TimeSummaryProps {
  dateTime: iDateTime;
  onChange(t): void;
  allowNull: boolean;
  allowNow: boolean;
}
export const TimeSummary: React.FC<TimeSummaryProps> = (props) => {
  const { dateTime, onChange, allowNull, allowNow } = props;
  const [, uiActions] = useUIState();

  const elRef = React.useRef(null);
  const [posStyles, setPosStyles] = React.useState({});

  React.useEffect(() => {
    const { left, width, top } = elRef.current.getBoundingClientRect();

    setPosStyles({
      position: 'fixed',
      left,
      width,
      top,
    });
  }, []);

  return (
    <Card.CardContent
      onClick={() => {
        uiActions.showModal(
          <div style={posStyles}>
            <TimePicker
              allowNull={allowNull}
              allowNow={allowNow}
              dateTime={dateTime}
              onSubmit={(t) => {
                onChange(t);
                uiActions.dismissModal();
              }}
              onCancel={() => {
                uiActions.dismissModal();
              }}
            />
          </div>,
        );
      }}
    >
      <div ref={elRef}>
        <LocalizedTime dateTime={dateTime} />
      </div>
    </Card.CardContent>
  );
};

interface DateSummaryProps {
  startDate: any;
  endDate: any;
  isCurrent: any;
  isPeriod: any;
  defaultPrecision: string;
  onToggleIsCurrent?(): void;
  onSelectWhen(): void;
  onChange(d): void;
  onCancel(): void;
}
export const DateSummary: React.FC<DateSummaryProps> = (props) => {
  const { startDate, endDate, isCurrent, isPeriod, onChange, onCancel, defaultPrecision } = props;

  const hasStart = startDate && startDate.date;
  const hasEnd = endDate && endDate.date;
  const hasDate = hasStart || hasEnd;
  const [, uiActions] = useUIState();

  const elRef = React.useRef(null);
  const [posStyles, setPosStyles] = React.useState({});

  React.useEffect(() => {
    const { left, width, top } = elRef.current.getBoundingClientRect();

    setPosStyles({
      position: 'fixed',
      left,
      width,
      top,
    });
  }, []);

  return (
    <>
      <Card.CardContent
        onClick={() => {
          uiActions.showModal(
            <div style={posStyles}>
              <DatePicker
                phraseLabel="heReminder.startDate.field.label"
                date={startDate.date}
                precision={startDate.precision}
                onDateSubmit={(dateData) => {
                  if (dateData) {
                    onChange(dateData);
                  } else if (onCancel) {
                    onCancel();
                  }

                  uiActions.dismissModal();
                }}
                defaultDate=""
                defaultPrecision={defaultPrecision}
                allowFuzzy={false}
                allowToday={true}
                allowNull={false}
                earliestDateLimit={null}
                latestDateLimit={null}
              />
            </div>
          );
        }}
      >
        {
          hasDate ? (
            <>
              <div ref={elRef} className="font-body font-color-gsDarkest" style={{ marginBottom: 4 }}>
                <HealthEntryDateSummaryDate
                  startDateData={startDate}
                  endDateData={endDate}
                  isCurrent={isCurrent}
                  isPeriod={isPeriod}
                />
              </div>

              <div className="font-caption-1 font-color-gsDarkest">
                <HealthEntryDateSummaryContext
                  startDateData={startDate}
                  endDateData={endDate}
                  isCurrent={isCurrent}
                  isPeriod={isPeriod}
                />
              </div>
            </>
          ) : (
            <div className="font-body font-color-gsDarkest">
              <FormattedMessage id="healthEntryDates.addStartDate.button.label" />
            </div>
          )
        }
      </Card.CardContent>
    </>
  );
};

interface DateTimeProps {
  dateData: iDateTime;
  onChange(d): void;
  allowNull: boolean;
  allowNow: boolean;
  intl?: any;
}
export const DateTime: React.FC<DateTimeProps> = (props) => {
  const { dateData, onChange, allowNull, allowNow } = props;

  const { time, timezone_offset, date, precision } = dateData;

  return (
    <div>
      <Card.CardSection hasBottomBorder={true}>
        <DateSummary
          startDate={{
            date,
            precision,
          }}
          defaultPrecision="P1DT"
          endDate={null}
          isCurrent={false}
          isPeriod={false}
          onToggleIsCurrent={null}
          onSelectWhen={() => {

          }}
          onChange={(d) => {
            onChange({
              time,
              timezone_offset,
              ...d,
            });
          }}
          onCancel={null}
        />
      </Card.CardSection>

      <Card.CardSection hasBottomBorder={false}>
        <TimeSummary
          dateTime={dateData}
          allowNull={allowNull}
          allowNow={allowNow}
          onChange={(t) => {
            onChange({
              date,
              precision,
              timezone_offset,
              time: t,
            });
          }}
        />
      </Card.CardSection>
    </div>
  );
};

export const TimeInput: React.FC<DateTimeProps> = injectIntl((props) => {
  const { dateData, onChange, allowNull, allowNow, intl } = props;
  const elRef = React.useRef(null);
  const [, uiActions] = useUIState();

  const dateTime = new DateTimeModel(dateData, intl);
  return (
    <div style={{ width: '100%' }} ref={elRef}>
      <Inputs.TextInput
        value={dateTime.formatTime()}
        label={<FormattedMessage id="heReminder.time.field.label" />}
        onFocus={() => {
          const { left, width, top } = elRef.current.getBoundingClientRect();
          uiActions.showModal(
            <TimePicker
              dateTime={dateData}
              style={{ left, width, top, position: 'fixed' }}
              minuteInterval={5}
              allowNull={allowNull}
              allowNow={allowNow}
              onSubmit={(t) => {
                onChange({
                  ...dateData,
                  time: t,
                });
                uiActions.dismissModal();
              }}
              onCancel={() => {
                uiActions.dismissModal();
              }}
            />
          );
        }}
        onChange={() => {}}
        isInvalid={false}
        invalidHint={null}
        onClear={() => {

        }}
        regularHint={null}
        noForcedHeight={true}
        preventFloating={true}
        color={null}
        readOnly={true}
      >
        {props.children}
      </Inputs.TextInput>
    </div>
  );
});
