import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import RouteNames from '../../appAuthorized/RouteNames';

import ContactModel from '../../../models/ContactModel';

import SelectableDetailLabel from './components/SelectableDetailLabel';
import { CardRowUI } from '../../../bphComponents/bundle';
import MultiCardListView from '../../components/MultiCardListView';
import ListModel from '../../../models/ListModel';

import { useActiveProfile } from '../../hooks/useProfile';
import { useUIState } from '../../hooks/useUIState';
import { useLists } from '../../hooks/useLists';
import { useNavigate } from '../../hooks/useNavigate';
import { useUIEditContact } from '../../hooks/useUIEdit';

const getData = (props) => {
  const { isFullList, editingContact, contactRelationshipList, contactRelationshipHealthcareProviderValue, contactRelationshipCustomValue, goToFullList, goToCustom, intl } = props;
  const excludeIds = [contactRelationshipHealthcareProviderValue.id, contactRelationshipCustomValue.id];
  return isFullList ? {
    phrase: {
      title: {
        id: 'contactRelationship.fullList.title',
      },
    },
    cards: [
      {
        data: [contactRelationshipHealthcareProviderValue],
        firstItem: null,
        renderDataItem: (data, onClick, isSelected) => <SelectableDetailLabel key={data.id} data={data} onClick={onClick} isSelected={isSelected} />,
        lastItem: null,
      },
      {
        data: ListModel.ContactRelationship.sortFullList(intl, contactRelationshipList, excludeIds), // .filter(d => (d.id !== contactRelationshipHealthcareProviderValue.id)),
        firstItem: null,
        renderDataItem: (data, onClick, isSelected) => <SelectableDetailLabel key={data.id} data={data} onClick={onClick} isSelected={isSelected} />,
        lastItem: null,
      },
      {
        data: [],
        firstItem: () => (
          <CardRowUI.Navigation onClick={goToCustom}>
            <div className="font-body font-color-profile-accent">
              {
                editingContact.relationship_custom_text || <FormattedMessage id="contact.relationship.addCustom.button.label" />
              }
            </div>
          </CardRowUI.Navigation>
        ),
        lastItem: null,
      },
    ],
  } : {
    phrase: {
      title: {
        id: 'contactRelationship.shortList.title',
      },
    },
    cards: [
      {
        data: [contactRelationshipHealthcareProviderValue],
        firstItem: null,
        renderDataItem: (data, onClick, isSelected) => <SelectableDetailLabel key={data.id} data={data} onClick={onClick} isSelected={isSelected} />,
        lastItem: null,
      },
      {
        data: ListModel.ContactRelationship.sortShortList(intl, contactRelationshipList, excludeIds),
        firstItem: null,
        renderDataItem: (data, onClick, isSelected) => <SelectableDetailLabel key={data.id} data={data} onClick={onClick} isSelected={isSelected} />,
        lastItem: () => (
          <CardRowUI.Navigation onClick={goToFullList}>
            <div className="font-body font-color-profile-accent">
              <FormattedMessage id="contactRelationship.viewAll.button.label" />
            </div>
          </CardRowUI.Navigation>
        ),
      },
    ],
  };
};

const shouldShowFullList = (listData, valueId) => {
  const value = listData.find(d => d.id === valueId);
  return value ? value.is_extended : false;
};

interface ViewContactRelationshipProps {
  intl: any;
  match: any;
}
const ViewContactRelationship: React.FC<ViewContactRelationshipProps> = (props) => {
  const contactId = Number(props.match.params.contactId);
  const { intl } = props;

  const [{ greetingName }] = useActiveProfile();
  const [{ isActiveProfileMainProfile }] = useUIState();
  const [{ contactRelationshipHealthcareProviderValue, contactRelationshipCustomValue, contactRelationshipList }] = useLists();
  const [, navActions] = useNavigate();
  const [{ editingContact, isLoaded }, editContactActions] = useUIEditContact(contactId);

  const [isFullList, setIsFullList] = React.useState(shouldShowFullList(contactRelationshipList, editingContact ? editingContact.contact_relationship_id : null));

  const setToFullList = () => {
    setIsFullList(true);
    window.scrollTo(0, 0);
  };

  React.useEffect(() => {
    if (isLoaded) {
      setIsFullList(shouldShowFullList(contactRelationshipList, editingContact.contact_relationship_id));
    }
  }, [isLoaded]);

  const listViewData = getData({
    isFullList,
    editingContact,
    contactRelationshipList,
    contactRelationshipHealthcareProviderValue,
    contactRelationshipCustomValue,
    intl,
    goToFullList: () => { setToFullList(); },
    goToCustom: () => {
      editContactActions.updateRelationship(contactRelationshipCustomValue.id);
      navActions.goTo(RouteNames.contactCustomRelationship(editingContact.id));
    },
  });

  return (
    <div>
      {
        isLoaded
        ? <MultiCardListView
            phrase={{
              title: listViewData.phrase.title,
              contentTitle: isActiveProfileMainProfile ? {
                id: 'contactRelationship.list.user.label',
              } : {
                id: 'contactRelationship.list.notUser.label',
                values: { string1: greetingName },
              },
            }}
            isMultiSelect={false}
            initialValue={editingContact.contact_relationship_id}
            onCancel={() => { navActions.goBack(RouteNames.contactEdit(editingContact.id)); }}
            isValid={(value) => ContactModel.isRelationshipValid({ ...editingContact, contact_relationship_id: value }, contactRelationshipList)}
            onSubmit={(currentValue) => {
              editContactActions.updateRelationship(currentValue)
                .then((data: any) => {
                  navActions.goBack(RouteNames.contactEdit(data.id));
                });
            }}
            cards={listViewData.cards}
          />
        : null
      }
    </div>
  );
};

export default injectIntl(ViewContactRelationship);
