
import React, { ReactNode } from 'react';

import { usePolicy } from '../hooks/usePolicy';

interface Props {
  name?: string;
  id?: number;
  languageCode: string;
  children(data): ReactNode;
}

const PolicyText = (props) => {
  const { name, id, languageCode, children } = props;

  const [, policyActions] = usePolicy();
  const policyParts = policyActions.getPolicy(name, id, languageCode);

  if (!policyParts) { return null; }

  const { title = '', table_of_contents = '', summary = '', text = '', version = '', created_at = '' } = policyParts || {};

  return children({ title, table_of_contents, summary, text, version, created_at });
};

export default PolicyText;
