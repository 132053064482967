
import React from 'react';
import { FormattedMessag, injectIntl } from 'react-intl';

import { ScrollAwareScroller, ScrollAwareContext, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentWrapper from '../../components/ContentWrapper';
import ContentFrame from '../../applicationFrame/ContentFrame';

import { useNavigate } from '../../hooks/useNavigate';
import { Buttons, Card } from '../../../bphComponents/bundle';
import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import { TimeInput, TimeSummary } from '../occurrences/components/AttributePrimitives/DateTime';
import RouteNames from '../../appAuthorized/RouteNames';
import { useTrackableReminders } from '../../hooks/useTrackableReminders';
import { Redirect } from 'react-router';
import { useUIEditReminder } from '../../hooks/useUIEdit';

interface Props {
  match: any;
  intl: any;
}
const TrackableReminderTime: React.FC<Props> = injectIntl((props) => {
  const trackableId = Number(props.match.params.trackableId);
  const timeIndex = Number(props.match.params.timeId);
  const isNew = props.match.params.timeId === 'new';

  const { intl } = props;

  const [, navActions] = useNavigate();
  const [, reminderActions] = useTrackableReminders(trackableId, intl);
  const [timeState, setTimeState] = React.useState({ time: '', timezone: '', date: '', precision: '' });
  const [{ editingReminder, isLoaded }, editReminderActions] = useUIEditReminder();

  React.useEffect(() => {
    if (isLoaded) {
      const tempReminder = reminderActions.instantiate(editingReminder);
      if (isNew) {
        setTimeState({
          time: reminderActions.createDefaultTime(),
          timezone: reminderActions.getTimezone(),
          date: '',
          precision: '',
        });
      } else {
        setTimeState({
          time: tempReminder.getTime(timeIndex),
          timezone: reminderActions.getTimezone(),
          date: '',
          precision: '',
        });
      }
    }
  }, [isLoaded]);

  if (!isLoaded) {
    return <Redirect to={RouteNames.trackableReminderSettings(trackableId)} />;
  }

  return (

    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          goBack={() => {
            navActions.goBackTo(RouteNames.trackableReminderSettings(trackableId));
          }}
          submit={() => {
            const tempReminder = reminderActions.instantiate(editingReminder);
            if (isNew) {
              tempReminder.addTime(timeState.time);
            } else {
              tempReminder.updateTime(timeIndex, timeState.time);
            }
            editReminderActions.updateReminder(tempReminder.data);
            navActions.goBackTo(RouteNames.trackableReminderSettings(trackableId));
          }}
          color="white"
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText id="heReminderTime.title.text" />

            <ContentWrapper>
              <Card.CardShape>
                <Card.CardContent>
                  <TimeInput
                    allowNull={false}
                    allowNow={false}
                    dateData={timeState}
                    onChange={(d) => {
                      setTimeState(d);
                    }}
                  />
                </Card.CardContent>
              </Card.CardShape>
            </ContentWrapper>
          </ScrollAwareScroller>
        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
});

export default TrackableReminderTime;
