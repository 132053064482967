import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import IconPlus from '../icons/IconPlus';
import IconArrowRight from '../icons/IconArrowRight';
import IconDataConnections from '../icons/IconDataConnections';
import { IconArrowUp } from '../icons/IconArrowUp';
import { IconLightPencil } from '../icons/IconLightPencil';
import { GrayCard } from '../card/Card';
import GroupSunset from '../../components/GroupSunset';
import { getActiveProfileId } from '../../appAuthorized/redux/reduxSelectors';
import { selectors as integrationSelectors } from '../../redux/ducks/integrations';
import NavigationActions from '../../appAuthorized/redux/NavigationActions';
import RouteNames from '../../appAuthorized/RouteNames';
import Spacer from '../Spacer';

import './ZeroState.scss';

interface Props {
  intl: any;
  total: number;
  heading: string;
  items: string[];
  hasIntegrations: boolean;
  showRemindSection?: boolean;
  icon(): React.ReactFragment;
  goToDataConnections: () => {};
}

const mapStateToProps = (state: any, props: any) => {
  const profileId = getActiveProfileId(state);
  const history = props.history;

  return {
    hasIntegrations: integrationSelectors.hasIntegrations(state, profileId),
    goToDataConnections: () => {
      NavigationActions.goTo(history, RouteNames.dataConnections());
    },
  };
};

export const ZeroState = withRouter(connect(mapStateToProps)(injectIntl(({ total, heading, items, hasIntegrations, showRemindSection, icon, goToDataConnections }: Props) => {
  return (
    <div className="cmp-zerostate" style={{ minHeight: total ? undefined : '100%' }}>
      {!total &&
        <div className="no-items">
          <div className="heading">
            <FormattedMessage id={heading}/>
          </div>

          {!hasIntegrations && showRemindSection && <GrayCard className="remind-section" style={{ margin: 0 }}>
              <div>
                <IconDataConnections className="fill-color-profile-accent" />
              </div>
              <div className="message">
                <div className="font-body">
                  <FormattedMessage id="bpHealth.zero.setupDataConnections.message" />
                </div>
                <div
                  className="setup-data-connections-btn font-subhead font-color-profile-accent"
                  onClick={goToDataConnections}
                >
                  <FormattedMessage id="bpHealth.zero.setupDataConnections.button.label" />
                  <div className="icon">
                    <IconArrowRight.Small className="fill-color-profile-accent" />
                  </div>
                </div>
              </div>
            </GrayCard>}

          <ul className="cmp-content-item-list">
            {items.map((item, idx) =>
              <li key={idx}>
                <div className="item-icon">
                  {icon()}
                </div>

                <div className="item-text">
                  <FormattedMessage id={item}/>
                </div>
              </li>)
            }
          </ul>
        </div>
      }
    </div>
  );
})));

export function healthEntriesZeroState(count) {
  return (
    <ZeroState
      total={count}
      heading="bpHealth.zero.heading.text"
      items={[
        'bpHealth.zero.point1.text',
        'bpHealth.zero.point2.text',
        'bpHealth.zero.point3.text',
      ]}
      icon={() => <IconPlus color="profile" size="12" />}
    />
  );
}

export function filesZeroState(count) {
  return (
    <ZeroState
      total={count}
      heading="bpFiles.zero.heading.text"
      items={[
        'bpFiles.zero.point1.text',
        'bpFiles.zero.point2.text',
        'bpFiles.zero.point3.text',
      ]}
      icon={() => <IconArrowUp color="profile" size="14"/>}
    />
  );
}

export function notesZeroState(count) {
  return (
    <ZeroState
      total={count}
      heading="bpNotes.zero.heading.text"
      items={[
        'bpNotes.zero.point1.text',
        'bpNotes.zero.point2.text',
        'bpNotes.zero.point3.text',
      ]}
      icon={() => <IconLightPencil color="profile" size="14"/>}
    />
  );
}

export function groupsZeroState(count) {
  return (
    <>
      <ZeroState
        total={count}
        heading="groups.zero.heading.text"
        items={[
          'groups.zero.point1.text',
          'groups.zero.point2.text',
          'groups.zero.point3.text',
        ]}
        icon={() => <IconPlus color="profile" size="12" />}
      />
      <GroupSunset/>
      <Spacer size="40"/>
    </>
  );
}
