import { authorizedApiRequest } from './ApiRequest';
import ApiErrorModel from '../models/ApiErrorModel';

export function request(method: 'get' | 'post' | 'put', url, body = undefined): Promise<any> {
  return new Promise((resolve, reject) => {
    const req = authorizedApiRequest(method, url);

    if (body) {
      req.send(body);
    }

    req.end((err, res) => {
      if (err) {
        reject(new ApiErrorModel(err));
      } else {
        const result = (res && res.body && res.body.data) || {};
        resolve(result);
      }
    });
  });
}
