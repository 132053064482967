import React from 'react';
import { FormattedMessage } from 'react-intl';

import Spacer from './Spacer';

interface Props {
  title: string;
  children: any;
}

const FieldValue = (props: Props) => (
  <div>
    <div className="font-footnote font-color-gsDark">
      <FormattedMessage id={props.title} />
    </div>

    <Spacer size="2" />

    <div className="font-body font-color-gsDarkest">
      {props.children}
    </div>
  </div>
);

export default FieldValue;
