
import React from 'react';

import { CardRowUI } from '../../../../bphComponents/bundle';

import { FileSize } from '../../../localization/LocalizedFile';
import { RelativeDate } from '../../../localization/LocalizedDate';
import COLORS from '../../../styles/Colors';
import { useFilesForActiveProfile } from '../../../hooks/useFile';

interface NestedPropertiesProps {
  icon: any;
  collection: any[];
  getValue(data): any;
  getSubTitle?(data): any;
  addAnotherValue: any;
  languageCode: string;
  onCreate(): void;
  onClick(data, index): void;
  onRemove?(data, index): void;
}

const HEPropertyCollection: React.FC<NestedPropertiesProps> = (props) => {
  const { icon, collection, getValue, getSubTitle, addAnotherValue, onClick, onCreate, onRemove } = props;

  return (
    <>
      <CardRowUI.HealthEntryProperty
        hasBottomBorder={true}
        borderType={collection.length > 0 ? 'indented' : 'full'}
        icon={icon}
        color={COLORS.healthEntryPropertyWithValue}
        value={getValue(collection[0])}
        onClick={onClick ? (e) => {
          e.preventDefault();
          onClick(collection[0], 0);
        } : null}
        onCreate={onCreate}
        onRemove={onRemove ? () => { onRemove(collection[0], 0); } : null}
        subTitle={getSubTitle ? getSubTitle(collection[0]) : null}
      />

      {
        collection.map((a, i) => {
          if (i === 0) { return null; }

          return (
            <CardRowUI.HealthEntryProperty
              key={i}
              hasBottomBorder={true}
              borderType="indented"
              value={getValue(collection[i])}
              color={COLORS.gsDarkest}
              onRemove={onRemove ? () => { onRemove(a, i); } : null}
              onClick={onClick ? (e) => {
                e.preventDefault();
                onClick(a, i);
              } : null}
              subTitle={getSubTitle ? getSubTitle(a) : null}
            />
          );
        })
      }

      <CardRowUI.AddHealthEntryProperty hasBottomBorder onClick={onCreate}>
        {addAnotherValue}
      </CardRowUI.AddHealthEntryProperty>
    </>
  );
};

const getFileProps = (file, languageCode) => {
  return {
    title: file.getName(),
    mimeType: file.getMimeType(),
    date: (
      <RelativeDate
        date={file.getObservedDate().date}
        precision={file.getObservedDate().precision}
        dateKind="medium"
        languageCode={languageCode}
      />
    ),
    size: <FileSize fileSizeData={file.fileSizeData()} />,
    isFlagged: file.isFlagged(),
  };
};

const HEFileAttachmentCollection: React.FC<NestedPropertiesProps> = (props) => {
  const { collection, addAnotherValue, onClick, onCreate, onRemove, languageCode } = props;
  const [, fileActions] = useFilesForActiveProfile();

  return (
    <>
      <CardRowUI.File
        hasBottomBorder={true}
        borderType="inset"
        {...getFileProps(fileActions.instantiateFile(collection[0]), languageCode)}
        onRemove={() => { onRemove(collection[0], 0); }}
        onClick={onClick ? (e) => {
          e.preventDefault();
          onClick(collection[0], 0);
        } : null}
        color="transparent"
        flaggedClassName="bkgd-color-profile-highlight"
      />

      {
        collection.map((a, i) => {
          if (i === 0) { return null; }

          return (
            <CardRowUI.File
              key={i}
              hasBottomBorder={true}
              borderType="inset"
              {...getFileProps(fileActions.instantiateFile(a), languageCode)}
              onClick={onClick ? (e) => {
                e.preventDefault();
                onClick(a, i);
              } : null}
              onRemove={() => { onRemove(a, i); }}
              flaggedClassName="bkgd-color-profile-highlight"
              color="transparent"
            />
          );
        })
      }

      <CardRowUI.AddHealthEntryProperty hasBottomBorder onClick={onCreate}>
        {addAnotherValue}
      </CardRowUI.AddHealthEntryProperty>
    </>
  );
};

export {
  HEPropertyCollection,
  HEFileAttachmentCollection,
};
