import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import DatePicker from '../../../components/DatePicker';
import DateUtil from '../../../../utilities/DateUtil';
import { TimeFromNow } from '../../../localization/LocalizedDate';
import InputWithLabel from '../../../components/InputWithLabel';
import { useUIState } from '../../../hooks/useUIState';

interface Props {
  dateData: {
    date: string;
    precision: string;
    date_time?: string;
  };
  intl: any;
  label: any;
  isFuzzy: boolean;
  warningHint: any;
  earliestDateWarningLimit: string;
  invalidHint: any;
  regularHint: any;
  onChange(d): void;
  defaultDate: string;
  defaultPrecision: string;
  hasToday: boolean;
  earliestDateLimit: string;
  latestDateLimit: string;
  allowNull: boolean;
  onFocus(): void;
  onCancel(): void;
  initialFocus: boolean;
}
const IntlDateControl: React.FC<Props> = (props) => {
  const {
    dateData,
    intl,
    label,
    isFuzzy,
    warningHint,
    earliestDateWarningLimit,
    invalidHint,
    regularHint,
    onChange,
    defaultDate,
    defaultPrecision,
    hasToday,
    earliestDateLimit,
    latestDateLimit,
    allowNull,
    onFocus,
    onCancel,
    initialFocus,
  } = props;

  const elRef = React.useRef(null);
  const [, uiActions] = useUIState();

  const date = dateData.date;
  const datePrecision = isFuzzy ? dateData.precision : 'P1D';

  const hint = (warningHint && date && DateUtil.isBefore(date, earliestDateWarningLimit))
    ? (
      <>
        {regularHint}
        <span style={{ marginLeft: '5px' }} className="font-color-warning">
          <FormattedMessage id={warningHint} />
        </span>
      </>
    ) : regularHint;

  const showPicker = () => {
    const { left, width, top } = elRef.current.getBoundingClientRect();
    const styleData = Math.abs(top - window.innerHeight) < 180 ? { top: top - 154 } : { top };

    uiActions.showModal(
      <div style={{ position: 'fixed', width, left, ...styleData }}>
        <DatePicker
          label={label}
          date={date}
          precision={datePrecision}
          onDateSubmit={(dateData) => {
            if (dateData) {
              onChange(dateData);
            } else if (onCancel) {
              onCancel();
            }
            uiActions.dismissModal();
          }}
          defaultDate={defaultDate}
          defaultPrecision={defaultPrecision}
          isFuzzy={isFuzzy}
          hasToday={hasToday}
          earliestDateLimit={earliestDateLimit}
          latestDateLimit={latestDateLimit}
          allowNull={allowNull}
        />
      </div>,
    );

    if (onFocus) {
      onFocus();
    }
  };

  React.useEffect(() => {
    if (initialFocus) {
      showPicker();
    }
  }, []);

  return (
    <div style={{ width: '100%' }} ref={elRef}>
      <InputWithLabel
        label={label ? intl.formatMessage({ id: label }) : ''}
        value={DateUtil.formattedMediumDate(date, datePrecision)}
        onChange={() => {}}
        hideLabel={true}
        colorTheme="default"
        style={{ marginTop: 0, height: 'initial' }}
        onFocus={showPicker}
        isInvalid={false}
        invalidHint={invalidHint}
        onClear={() => { onChange({ date: '', precision: '' }); }}
        regularHint={hint}
        noForcedHeight={true}
        preventFloating={true}
        readOnly={true}
      >
        <div className="font-footnote font-color-gsDarkest">
          <TimeFromNow
            date={date}
          />
        </div>
      </InputWithLabel>
    </div>
  );
};

export const DateControl = injectIntl(IntlDateControl);
