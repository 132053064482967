import React from 'react';
import { injectIntl } from 'react-intl';
import BPGraphs from '@backpackhealth/graphs';

import { Card } from '../../../../bphComponents/bundle';
import DateUtil from '../../../../utilities/DateUtil';

interface Props {
  observations: any[];
  title?: string;
  // intl: any;
}
const ObservationSeriesGraph: React.FC<Props> = injectIntl((props) => {
  const { observations, intl, title } = props;
  const { locale } = intl;
  const graphRef = React.useRef(null);

  const [dimensions, setDimensions] = React.useState({ height: 300, width: 700 });

  React.useEffect(() => {
    const handleResize = () => {
      if (graphRef.current) {
        setDimensions({
          height: 300,
          width: graphRef.current.offsetWidth,
        });
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Card.CardShape>
      <Card.CardContent>
        <div className="font-caption-1 font-color-gsDark">{title}</div>
        <div ref={graphRef}>
          <BPGraphs.TrackableObservationGraph
            width={dimensions.width}
            observations={observations}
            locale={locale}
            generalErrorMessage={intl.formatMessage({ id: 'error.heGraph.unexpected-error.message' })}
            p1dFormatter={(d) => { return DateUtil.formattedMediumDate(d, 'P1D', locale); }}
            p1mFormatter={(d) => { return DateUtil.formattedMediumDate(d, 'P1M', locale); }}
            p1yFormatter={(d) => { return DateUtil.formattedMediumDate(d, 'P1Y', locale); }}
          />
        </div>
      </Card.CardContent>
    </Card.CardShape>
  );
});

export default ObservationSeriesGraph;
