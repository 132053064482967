import React from 'react';
import { Redirect } from 'react-router';

import JewelryProviderList from './JewelryProviderList';
import RegisterJewelryStickyJ from './RegisterJewelryStickyJ';

import RouteNames from '../../../appAuthorized/RouteNames';
import { useNavigate } from '../../../hooks/useNavigate';
import { useShareCardListForActiveProfile } from '../../../hooks/useShareCard';

interface Props {
  shareCardId: number;
}

const RegisterNewJewelryController: React.FC<Props> = (props) => {
  const { shareCardId } = props;

  const [, navActions] = useNavigate();
  const [{ isLoaded }, scActions] = useShareCardListForActiveProfile();

  const [provider, setProvider] = React.useState(null);

  if (!isLoaded) { return null; }

  const shareCard = scActions.instantiate(shareCardId);
  const shareCardColorModel = scActions.getShareCardColor(shareCard.data);

  if (!shareCardId) {
    return <Redirect to={RouteNames.share()} />;
  }

  return provider
    ? (
      <RegisterJewelryStickyJ
        shareCardId={shareCardId}
        provider={provider}
        onSubmitSuccessful={() => {
          navActions.goBackTo(RouteNames.shareCard(shareCardId));
        }}
        onCancel={() => {
          setProvider(null);
        }}
        shareCardColorModel={shareCardColorModel}
      />
    ) : (
      <JewelryProviderList
        onSelectProvider={(provider) => {
          setProvider(provider);
        }}
        shareCardId={shareCardId}
        shareCardTemplateUid={shareCard.definitionUid}
        onCancel={() => {
          navActions.goBackTo(RouteNames.shareCardView(shareCardId));
        }}
        shareCardColorModel={shareCardColorModel}
      />
    );
};

export default RegisterNewJewelryController;
