import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import AppHeader from '../../appAuthorized/presenters/AppHeader.redux';
import { ScrollAwareItem, ScrollAwareFade } from '../ScrollAware';
import { TitleRow } from '../appNav/appNavBits/AppNavRow';
import GroupTitle from '../../features/GroupTitle/GroupTitle.presenter';
import { PreferredLanguageDropdownList } from '../LanguageDropdownList';
import { LocalText } from '../../../utilities/localizedUtil';

interface Props {
  children: any;
  title: React.ReactNode;
  colorThemeName?: string;
  colorTheme?: any;
  ignorePadding?: boolean;
  className?: string;
  style?: any;
  fadeHeight?: string;
  isSticky?: boolean;
}

interface GroupProps {
  group: any;
  groupId: number;
  size?: string;
}

interface TextProps {
  intl: any;
  id?: string;
  values?: any;
  text?: string;
  children?: any;
  isLoaded?: boolean;
}

interface TextWithLanguageProps extends TextProps {
  allowLanguageToggle: boolean;
}

const AppHeaderScrollable = (props: Props) => (
  <ScrollAwareItem title={props.title} isSticky={props.isSticky}>
    <AppHeader colorThemeName={props.colorThemeName} colorTheme={props.colorTheme} className={props.className} style={props.style || {}}>
      <ScrollAwareItem isSticky>
        <ScrollAwareFade
          colorTheme={props.colorTheme}
          height={props.fadeHeight}
        />
      </ScrollAwareItem>
      {
        props.ignorePadding
          ? props.children
          : (
            <TitleRow>
              {props.children}
            </TitleRow>
          )
      }
    </AppHeader>
  </ScrollAwareItem>
);

export const AppHeaderScrollableGroup = (props: GroupProps) => {
  const { size = '32px' } = props;
  return (
    <AppHeaderScrollable
      title={<LocalText text={props.group.name} />}
      colorThemeName="group"
      colorTheme={props.group.theme}
    >
      <GroupTitle size={size} groupId={props.groupId} />
    </AppHeaderScrollable>
  );
};

export const AppHeaderScrollableText = injectIntl((props: TextProps) => {
  let title = '';
  if (props.id) {
    title = props.intl.formatMessage({ id: props.id }, props.values);
  } else {
    title = props.text;
  }
  return (
    <AppHeaderScrollable
      title={title}
    >
      {props.isLoaded === false ? (
        null
      ) : (
        <div>
          {title}
          {props.children}
        </div>
      )}
    </AppHeaderScrollable>
  );
});

export const AppHeaderScrollableTextWithLanguage = injectIntl((props: TextWithLanguageProps) => (
  <AppHeaderScrollable
    ignorePadding
    title={props.id ? props.intl.formatMessage({ id: props.id }, props.values) : ''}
  >
    <TitleRow alignItems="flex-end">
      {props.id && <FormattedMessage id={props.id} values={props.values} />}
      {props.allowLanguageToggle && <PreferredLanguageDropdownList htmlId="accountLanguage" color="white" />}
    </TitleRow>
  </AppHeaderScrollable>
));

export default AppHeaderScrollable;
