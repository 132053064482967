
enum CommentType {
  userSpecified = 'user_specified',
}

export interface CommentModel {
  id: number;
  type: CommentType;
  include_on_share_card: boolean;
  content: string;
  created_at?: string;
  updated_at?: string;
}

export class CommentCollection {

  data: CommentModel[];

  constructor(data) {
    this.data = data ? [...data] : [];
  }

  remove = (comment) => {
    const id = comment.id;
    this.data = this.data.filter(d => d.id !== id);
  }

  add = (comment) => {
    this.data.push(comment);
  }

  get = (id) => {
    return this.data.find(d => d.id === id);
  }

  update = (comment) => {
    const id = comment.id;
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].id === id) {
        this.data[i] = comment;
        return;
      }
    }

    this.data.push(comment);
  }
}

class Comment {

  static empty(): CommentModel {
    return {
      id: -1,
      type: CommentType.userSpecified,
      include_on_share_card: false,
      content: '',
    };
  }

  data: CommentModel;

  constructor(data = {}) {
    this.data = {
      id: -1,
      type: CommentType.userSpecified,
      include_on_share_card: false,
      content: '',
      ...data,
    };
  }

  toggleIncludeOnShareCard = () => {
    this.data.include_on_share_card = !this.data.include_on_share_card;
  }

  setContent = (value) => {
    this.data.content = value;
  }


}

export default Comment;
