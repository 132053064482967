
import { useSelector } from 'react-redux';
import { selectors as ownerSelectors } from '../redux/ducks/owners';

import { useUserProfile } from './useProfile';

export function useOtherOwners(profileId) {
  const [userProfileData] = useUserProfile();
  const userProfileId = userProfileData.data.id;

  const activeOwners = useSelector(state => ownerSelectors.getActiveOwnersForProfile(profileId, state));
  const otherOwners = activeOwners.filter(p => p.data.id !== userProfileId);

  return [{
    data: otherOwners,
    isShared: otherOwners.length > 0,
  }];
}
