import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useActiveProfile } from '../../hooks/useProfile';
import { useUIState } from '../../hooks/useUIState';

interface Props {
  notUser: { id: string; values?: any };
  user: { id: string; values?: any };
  notUserNameKeys?: string[];
  userNameKeys?: string[];
}
export const UserNotUserFormattedMessage: React.FC<Props> = (props) => {
  const { notUser, user, notUserNameKeys, userNameKeys } = props;

  const [{ greetingName }] = useActiveProfile();
  const [uiState] = useUIState();

  const messageData = uiState.isActiveProfileMainProfile
    ? {
      values: (userNameKeys || ['string1']).reduce((acc, v) => ({
        ...acc,
        [v]: greetingName,
      }), {}),
      ...user,
    } : {
      id: notUser.id,
      values: (notUserNameKeys || ['string1']).reduce((acc, v) => ({
        ...acc,
        [v]: greetingName,
      }), {}),
      ...notUser,
    };

  return (
    <FormattedMessage {...messageData} />
  );
};
