import React from 'react';

interface Props {
  color?: string;
  colorClassName?: string;
}

const IconNoteSmall = (props: Props) => (
  <svg height="16px" width="16px" viewBox="0 0 16 16">
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeLinejoin: 'round', strokeWidth: 1.25 }}
      d="M4.21,14l7.46-7.46a.28.28,0,0,0,0-.38L9.84,4.33a.28.28,0,0,0-.38,0L2,11.79a.81.81,0,0,0-.24.53L1.6,14.4l2.08-.16A.81.81,0,0,0,4.21,14Z"
    />
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeLinejoin: 'round', strokeWidth: 1.25 }}
      d="M12.25,1.54a.7.7,0,0,1,1,0l1.21,1.21a.7.7,0,0,1,0,1l-1.1,1.1L11.15,2.64Z"
    />
  </svg>
);

const IconNoteMedium = (props: Props) => (
  <svg height="24px" width="24px" viewBox="0 0 24 24">
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeLinejoin: 'round', strokeWidth: 1.5 }}
      d="M6.31,21,18.09,9.22a.41.41,0,0,0,0-.58L15.36,5.91a.41.41,0,0,0-.58,0L3,17.69a1.23,1.23,0,0,0-.36.79l-.23,3.11,3.11-.23A1.23,1.23,0,0,0,6.31,21Z"
    />
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeLinejoin: 'round', strokeWidth: 1.5 }}
      d="M18.38,2.31a1.07,1.07,0,0,1,1.5,0l1.81,1.81a1.07,1.07,0,0,1,0,1.5L20,7.27,16.73,4Z"
    />
  </svg>
);

const IconNoteLarge = (props: Props) => (
  <svg height="36px" width="36px" viewBox="0 0 36 36">
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeLinejoin: 'round', strokeWidth: 2 }}
      d="M9.46,31.5,27.14,13.83a.62.62,0,0,0,0-.87L23,8.86a.62.62,0,0,0-.87,0L4.5,26.54A1.8,1.8,0,0,0,4,27.71l-.35,4.68L8.29,32A1.8,1.8,0,0,0,9.46,31.5Z"
    />
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeLinejoin: 'round', strokeWidth: 2 }}
      d="M27.57,3.46a1.6,1.6,0,0,1,2.24,0l2.73,2.73a1.6,1.6,0,0,1,0,2.24l-2.49,2.48-5-5Z"
    />
  </svg>
);

const IconNoteXLarge = (props: Props) => (
  <svg height="56px" width="56px" viewBox="0 0 56 56">
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeLinejoin: 'round', strokeWidth: 2.75 }}
      d="M14.72,49,42.21,21.51a1,1,0,0,0,0-1.34l-6.38-6.38a1,1,0,0,0-1.34,0L7,41.28a2.89,2.89,0,0,0-.84,1.83l-.54,7.27,7.27-.54A2.89,2.89,0,0,0,14.72,49Z"
    />
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeLinejoin: 'round', strokeWidth: 2.75 }}
      d="M42.89,5.39a2.47,2.47,0,0,1,3.49,0l4.23,4.23a2.47,2.47,0,0,1,0,3.49L46.75,17,39,9.25Z"
    />
  </svg>
);

const IconNote = {
  Small: IconNoteSmall,
  Medium: IconNoteMedium,
  Large: IconNoteLarge,
  xLarge: IconNoteXLarge,
};

export default IconNote;
