import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AppNavTopLevel from '../components/AppHeader';

import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import { ProfileThemedSecondaryNav } from '../../components/SecondaryNav';
import ContentFrame from '../../applicationFrame/ContentFrame';
import ContentLoader from '../../components/ContentLoader';
import AppNavPhrases from '../../../phraseKeys/AppNav.phrase';

import RouteNames from '../../appAuthorized/RouteNames';

import { ScrollAwareContext, ScrollAwareItem } from '../../components/ScrollAware';
import ContentWrapper from '../../components/ContentWrapper';

import TopLevelAddButton from '../../appAuthorized/presenters/TopLevelAddButton';

import BackpackHealthEntries from './BackpackHealthEntries';
import BackpackFiles from './BackpackFiles';
import BackpackNotes from './BackpackNotes';
import { useUIState } from '../../hooks/useUIState';
import { useActiveProfile } from '../../hooks/useProfile';
import { useNavigate } from '../../hooks/useNavigate';

function secondaryNav(routeName) {
  const nav = [
    { labelData: { id: 'list.backpackSecondaryNav.all.name' }, value: '/mybackpack', isActive: false },
    { labelData: { id: 'list.backpackSecondaryNav.notes.name' }, value: '/mybackpack/notes', isActive: false },
    { labelData: { id: 'list.backpackSecondaryNav.files.name' }, value: '/mybackpack/files', isActive: false },
  ];

  switch (routeName) {
    case RouteNames.myBackpackFiles():
      nav[2].isActive = true;
      break;
    case RouteNames.myBackpackNotes():
      nav[1].isActive = true;
      break;
    default:
      nav[0].isActive = true;
      break;
  }
  return nav;
}

const HealthInfoView: React.FC = () => {
  const [, profileActions] = useActiveProfile();
  const [, navActions] = useNavigate();
  const profile = profileActions.instantiateProfile();
  const [uiState, uiStateActions] = useUIState();
  const titleValues = AppNavPhrases.backpack.title(uiStateActions.isMainProfile(), profile.greetingName());

  const isDataLoaded = !uiState.isLoading;
  const selectSecondaryNav = (val) => {
    navActions.goTo(val);
  };
  const routeName = window.location.pathname;

  return (
    <div className="cmp-mybackpack">
      <ScrollAwareContext>
        <AppNavTopLevel />

        <ContentFrame className="bkgd-color-light-neutral">
          <ContentLoader isContentLoaded={isDataLoaded} withSideNavOffset>
            <div>
              <AppHeaderScrollableText {...titleValues} />

              <ScrollAwareItem
                isSticky
                hasDropShadow
              >
                <ProfileThemedSecondaryNav
                  selectSecondaryNav={selectSecondaryNav}
                  secondaryNav={secondaryNav(routeName)}
                />
              </ScrollAwareItem>

              <ContentWrapper>
                <Switch>
                  <Route exact path={RouteNames.myBackpackFiles()}>
                    <BackpackFiles />
                  </Route>

                  <Route exact path={RouteNames.myBackpackNotes()}>
                    <BackpackNotes />
                  </Route>

                  <Route path="/">
                    <BackpackHealthEntries />
                  </Route>
                </Switch>
              </ContentWrapper>
            </div>
          </ContentLoader>

          <TopLevelAddButton.MyBackpack />

        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
};

export default HealthInfoView;
