import React from 'react';

import { Icons } from '../../../../../bphComponents/bundle';
import COLORS from '../../../../styles/Colors';
import { iSurveyQuestion, iSurveyResponse } from '../../../../../apiServices/SurveyService';

import { useScrollContext } from '../../../../components/ScrollAware';
import SurveyQuestionResponseModel from '../../../../../models/SurveyQuestionResponseModel';
import { useSurveyQuestion } from '../../../../hooks/useSurveyQuestion';
import { useUIState } from '../../../../hooks/useUIState';

function getTopVisibleQuestionData(isWideDesktop, question, response) {
  const questionClassNameSelector = 'survey-question';
  const scrollerItems = document.querySelectorAll(`.${questionClassNameSelector}`);
  let questionIndex = 0;
  const threshold = isWideDesktop ? 200 : 90;
  for (let i = 0; i < scrollerItems.length; i++) {
    const scrollerItem = scrollerItems[i];

    if (!scrollerItem) { break; }
    const pos = scrollerItem.getBoundingClientRect().bottom;
    if (pos > threshold) {
      questionIndex = i;
      break;
    }
  }

  const questionEl = scrollerItems[questionIndex];

  let isCurrentAnswerComplete = false;
  let segmentIndex = 0;
  let subQuestionIndex = 0;

  if (questionEl) {
    segmentIndex = Number(questionEl.getAttribute('data-segment'));
    subQuestionIndex = Number(questionEl.getAttribute('data-index'));
    isCurrentAnswerComplete = SurveyQuestionResponseModel.isSubQuestionComplete(question, response, segmentIndex, subQuestionIndex);
  }

  const hasPrev = segmentIndex > 0 || subQuestionIndex > 0;
  const hasNext = isCurrentAnswerComplete && questionIndex < scrollerItems.length - 1;

  return {
    el: questionEl,
    segmentIndex,
    subQuestionIndex,
    hasPrev,
    hasNext,
  };
}

function useQuestionFromTopScrollPos(question, response, answeredQuestionUids, idPrefix) {
  useScrollContext();
  const [{ isWideDesktop }] = useUIState();
  const topVisibleQuestionData = getTopVisibleQuestionData(isWideDesktop, question, response);

  return {
    isPrevDisabled: !topVisibleQuestionData.hasPrev,
    isNextDisabled: !topVisibleQuestionData.hasNext,
    segmentIndex: topVisibleQuestionData.segmentIndex,
    subQuestionIndex: topVisibleQuestionData.subQuestionIndex,
  };
}

interface iQuestionNavControlsRefProps {
  question: iSurveyQuestion;
  response: iSurveyResponse;
  idPrefix: string;
  questionClassSelector: string;
}
const QuestionNavControlsRef = (props: iQuestionNavControlsRefProps, ref) => {
  const { question, response, idPrefix } = props;
  const { segmentIndex, subQuestionIndex, isPrevDisabled, isNextDisabled } = useQuestionFromTopScrollPos(question, response, null, idPrefix);

  const [{ contentScrollBuffer }, surveyQuestionActions] = useSurveyQuestion();

  React.useImperativeHandle(ref, () => ({
    scrollToFirstUncheckedItem: () => {
      surveyQuestionActions.goToFirstUncheckedItem(response);
    },
  }));

  return (
    <>
    <div style={{ marginTop: contentScrollBuffer }} />
    <div className="cmp-rapidresponsecontrols">
      <div className="util-application-content-width-with-margin">
        <div className="__mask" />
        <div className="__controls util-h-margin-16-32">
          <button
            disabled={isPrevDisabled}
            onClick={isPrevDisabled ? null : () => surveyQuestionActions.goPrev(segmentIndex, subQuestionIndex)}
          >
            <Icons.IconDivotArrowUp.Medium color={isPrevDisabled ? COLORS.gsMiddleLT : COLORS.gsLightest} />
          </button>
          <button
            disabled={isNextDisabled}
            onClick={isNextDisabled ? null : () => surveyQuestionActions.goNext(segmentIndex, subQuestionIndex)}><Icons.IconDivotArrowDown.Medium color={isNextDisabled ? COLORS.gsMiddleLT : COLORS.gsLightest} />
          </button>
        </div>
      </div>
    </div>
    </>
  );
};
const QuestionNavControls = React.forwardRef(QuestionNavControlsRef);

export default QuestionNavControls;
