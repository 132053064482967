import React from 'react';
import classnames from 'classnames';

import CollapsibleRow, { TopSectionWithCollapseControl } from './CollapsibleRow';

interface TopSectionWithCollapseControlProps {
  isCollapsible: boolean;
  isExpanded: boolean;
  onToggle(value: boolean): void;
  children: any;
  className?: string;
  hasBorder: boolean;
  title: any;
  style?: any;
}

const CollapsibleCardInputTitle = (props: TopSectionWithCollapseControlProps) => {
  const { isExpanded, children } = props;
  const className = classnames(
    props.className,
    'font-color-gsMedDark',
    'font-caption-1',
    'util-application-frame-forcards-padding',
  )

  return (
    <TopSectionWithCollapseControl
      {...props}
      hasBorder={isExpanded}
      className={className}
    >
      {children}
    </TopSectionWithCollapseControl>
  );
};

export const CollapsibleCardInput = (props) => {
  const { isExpanded, children, collapsedContent } = props;

  return (
    <div className={classnames('cmp-collapsiblerow', { expanded: isExpanded })}>
      <CollapsibleCardInputTitle {...props}>
        {
          isExpanded ? null : (
            <div className="font-body font-color-profile" style={{ marginTop: 12 }}>
              {collapsedContent}
            </div>
          )
        }
      </CollapsibleCardInputTitle>

      <div className="body">
        {children}
      </div>
    </div>
  );
};

interface ManagedCollapsibleCardInputState {
  isExpanded: boolean;
}

interface ManagedCollapsibleCardInputProps extends Partial<TopSectionWithCollapseControlProps> {
  collapsedContent?: any;
  isCollapsible: boolean;
  defaultCollapsed: boolean;
  style?: any;
  children: any;
  isExpanded?: boolean;
  onToggle?(value): void;
}

export class ManagedCollapsibleCardInput extends React.Component<ManagedCollapsibleCardInputProps, ManagedCollapsibleCardInputState> {

  state: ManagedCollapsibleCardInputState = {
    isExpanded: !this.props.defaultCollapsed,
  };

  collapseList = () => {
    this.setState({ isExpanded: false });
  }

  render() {
    const { isExpanded } = this.state;
    const { children } = this.props;

    return (
      <CollapsibleCardInput
        {...this.props}
        isExpanded={isExpanded}
        onToggle={(value) => { this.setState({ isExpanded: value }); }}
      >
        {
          typeof children === 'function' ? children({ collapseList: this.collapseList }) : children
        }
      </CollapsibleCardInput>
    );
  }
}

export const CollapsibleCard = (props) => (
  <CollapsibleRow {...props} topSectionClassName="util-application-frame-forcards-padding" />
);

export default CollapsibleCard;
