import { useQuery, queryCache } from 'react-query';

import { useDispatch } from 'react-redux';
import { actions as userActions } from '../redux/ducks/user';
import { actions as localeActions } from '../redux/ducks/locale';

import UserService from '../../apiServices/UserService';
import UserModel from '../../models/UserModel';
import { useLists } from './useLists';
import { useUIState } from './useUIState';

const STALE_TIME = 100000;

const INITIAL_USER = {
  data: {
    locale_id: 0,
  },
  isLoaded: false,
};

function fetchUser(dispatch) {
  return function fetchUser_API(key, userId) {
    return new Promise((res, rej) => {
      UserService.get(
        userId,
        (apiError1, user) => {
          dispatch({
            type: 'RECEIVE_MAIN_USER',
            payload: user.data,
          });

          res({
            data: user.data,
            isLoaded: true,
          });
        },
      );
    });
  };
}

export function useUser(userId?) {
  const dispatch = useDispatch();

  const [{ localeLookup, ...listData }] = useLists();

  const queryData: any = useQuery(['users', userId], fetchUser(dispatch), { enabled: Boolean(userId), staleTime: STALE_TIME });
  const data = queryData.data || INITIAL_USER;
  const userData = data.data;
  const isLoaded = data.isLoaded;
  const localeId = userData.locale_id;

  let preferredLocale: any = {};
  let preferredLanguageCode = '';

  if (localeId && listData.isLoaded) {
    const locale: any = localeLookup[localeId];
    preferredLocale = locale;
    preferredLanguageCode = locale.language_code || 'en';
  }

  return [
    {
      status: queryData.status,
      error: queryData.error,
      user: userData,
      isLoaded,
      localeId,
      preferredLocale,
      preferredLanguageCode,
    },
    {
      apiUpdate: (userData) => {
        return new Promise((res, rej) => {
          dispatch(userActions.submitUserUpdate(userData, () => {
            queryCache.setQueryData(['users', userId], {
              data: userData,
              isLoaded: true,
            });
            queryCache.invalidateQueries('users');
            res();
          }));
        });
      },
      setClientLocale: (locale) => {
        INITIAL_USER.data.locale_id = locale.id;
        dispatch(localeActions.addLocale(locale, locale.language_code, () => {}));
      },
      setLocale: (localeData) => {
        dispatch(localeActions.setCurrentLocale(localeData))
        .then(() => {
          queryCache.setQueryData(['users', userId], {
            data: {
              ...userData,
              locale_id: localeData.id,
            },
            isLoaded: true,
          });
          // dispatch(medliActions.requestCompositeUnit(localeCode));
          // dispatch(medliActions.requestNaUnitConstants(localeCode));
        });
      },
      addLocale: (newLocale) => {
        return new Promise((res, rej) => {
          dispatch(localeActions.addLocale(newLocale, newLocale.language_code, () => {
            res();
          }));
        });
      },
      instantiateUser: () => {
        return new UserModel(userData);
      },
      verifyEmail: () => {
        return new Promise((res, rej) => {
          UserService.verifyEmail(userData.id).then(() => {
            res();
          });
        });
      },
      cancelPendingEmail: () => {
        UserService.deletePendingEmailChange(userData.id, () => {
          queryCache.invalidateQueries('users');
        });
      },
      requestEmailChange: (email) => {
        return new Promise((res, rej) => {
          const userId = userData.id;
          UserService.requestEmailChange(userId, email, (apiError) => {
            if (apiError) { rej(apiError); return; }
            UserService.verifyEmail(userId);
            queryCache.invalidateQueries('users');
            res();
          });
        });
      },
    },
  ];
}

export function useActiveUser() {
  const [{ userId }] = useUIState();
  return useUser(userId);
}
