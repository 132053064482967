import React from 'react';
import { FormattedMessage } from 'react-intl';

import HelpView from './HelpView';
import PreformattedText from '../../../components/PreformattedText';
import InfoModal from '../../../components/modal/InfoModal';
import COLORS from '../../../styles/Colors';

const GeneVariantHelpModal = (props: { onDismiss?(): void; }) => (
  <InfoModal isFullHeight={true} onDismiss={props.onDismiss}>
    <HelpView
      title={<FormattedMessage id="healthEntry.GeneVariant.help.title.text" />}
      htmlContent={<PreformattedText text="healthEntry.GeneVariant.help.body.text" textClassName="font-body" />}
      linkColor={COLORS.brandDarkAccent}
    />
  </InfoModal>
);

export default GeneVariantHelpModal;
