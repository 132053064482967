import React from 'react';
import { FormattedMessage } from 'react-intl';

import { EmptyCard } from '../../../components/card/Card';

const PointListItem: React.FC<{ phraseId: string; }> = (props) => (
  <li style={{ marginBottom: 12, display: 'flex', alignItems: 'flex-start' }}>
    <span className="border-color-profile-highlight" style={{ marginRight: 16, borderRadius: 4, borderWidth: 4, borderStyle: 'solid', marginTop: 8 }} />
    <FormattedMessage id={props.phraseId} />
  </li>
);

const ZeroStateContacts: React.FC = () => {
  return (
    <div>
      <EmptyCard style={{ marginTop: 24 }}>
        <div className="font-headline font-color-gsMedDark" style={{ paddingTop: 16, marginBottom: 30 }}>
          <FormattedMessage id="contacts.zero.heading" />
        </div>

        <ul className="font-body font-color-gsMedDark">
          <PointListItem phraseId="contacts.zero.point1" />
          <PointListItem phraseId="contacts.zero.point2" />
          <PointListItem phraseId="contacts.zero.point3" />
          <PointListItem phraseId="contacts.zero.point4" />
        </ul>
      </EmptyCard>
    </div>
  );
};

export default ZeroStateContacts;
