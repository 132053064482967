import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ContactDetailType } from '../../../../models/ContactModel';
import { Card, CardRowUI, Links } from '../../../../bphComponents/bundle';
import ContactLabel from '../../../components/ContactLabel';
import { ClampedText } from '../../../components/ClampedText';

interface DetailProps {
  detail: ContactDetailType;
}

const PhoneDetail: React.FC<DetailProps> = (props) => {
  const { detail } = props;
  const { phone } = detail;

  return (
    <CardRowUI.SummaryInputLinkable
      label={<ContactLabel id={detail.contact_detail_label_id} />}
    >
      <Links.PhoneLink
        className="font-color-profile-accent"
        iso2Code={phone.country_code}
        phone={phone.number}
        showCountryCallingCode={false}
      />
    </CardRowUI.SummaryInputLinkable>
  );
};

const AddressDetail: React.FC<DetailProps> = (props) => {
  const { detail } = props;
  const { address } = detail;
  return (
    <CardRowUI.SummaryInputLinkable
      label={<ContactLabel id={detail.contact_detail_label_id} />}
    >
      <Links.AddressLink
        className="font-color-profile-accent"
        address={address.detail}
      />
    </CardRowUI.SummaryInputLinkable>
  );
};

const EmailDetail: React.FC<DetailProps> = (props) => {
  const { detail } = props;
  const { email } = detail;
  return (
    <CardRowUI.SummaryInputLinkable
      label={<ContactLabel id={detail.contact_detail_label_id} />}
    >
      <Links.EmailLink
        className="font-color-profile-accent"
        email={email.address}
      />
    </CardRowUI.SummaryInputLinkable>
  );
};

const WebsiteDetail: React.FC<DetailProps> = (props) => {
  const { detail } = props;
  const { website } = detail;
  return (
    <CardRowUI.SummaryInputLinkable
      label={<ContactLabel id={detail.contact_detail_label_id} />}
    >
      <Links.UrlLink
        className="font-color-profile-accent"
        href={website.url}
      />
    </CardRowUI.SummaryInputLinkable>
  );
};

interface CommentDetailProps {
  data?: string;
}
const CommentDetail: React.FC<CommentDetailProps> = (props) => {
  const { data } = props;
  if (!data) { return null; }

  return (
    <Card.CardSection>
      <CardRowUI.SummaryInput
        label={<FormattedMessage id="contact.comments.field.label" />}
      >
        <ClampedText
          lines={4}
          defaultExpanded={false}
          moreClassName="font-color-profile"
        >
          <div className="font-body" style={{ whiteSpace: 'pre-wrap' }}>
            {data}
          </div>
        </ClampedText>
      </CardRowUI.SummaryInput>
    </Card.CardSection>
  );
};

export {
  AddressDetail,
  CommentDetail,
  EmailDetail,
  PhoneDetail,
  WebsiteDetail,
};
