import { useQuery, queryCache } from 'react-query';

import { useDispatch } from 'react-redux';

import ShareCardService from '../../apiServices/ShareCardService';
import ShareCardModel, { ShareCard } from '../../models/ShareCardModel';
import { ShareCardDefinitionModel } from '../../models/ShareCardDefinitionModel';
import { RGBA } from '../../utilities/types';
import { ShareCardColorModel } from '../appAuthorized/dataTransformComponents/ColorShareCard';
import { TrackEvent } from '../../utilities/UserMetrics';

import { useGroupShareCardContentForActiveProfile } from './useGroup';
import { useHealthEntriesForProfile } from './useHealthEntry';
import { useLists } from './useLists';
import { useUIState } from './useUIState';

const STALE_TIME = 1000;
const INITIAL_SC_DEF_DATA = {
  definitions: [],
  definionLookup: {},
  isLoaded: false,
};
const INITIAL_SC_DATA = {
  list: [],
  lookup: {},
  isLoaded: false,
};

function updateShareCardDefinitionQueryStore(definitions) {
  return {
    definitions,
    definionLookup: definitions.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val,
      };
    }, {}),
  };
}

function updateShareCardListQueryStore(shareCardCollection) {
  return {
    list: shareCardCollection,
    lookup: shareCardCollection.reduce((acc, v) => ({ ...acc, [v.id]: v }), {}),
  };
}

export function requestShareCards(dispatch) {
  return function requestShareCards_API(queryKey, profileId) {
    return new Promise((res, rej) => {
      ShareCardService.all(profileId, (apiError, shareCardCollection) => {
        if (apiError) { rej(); return; }

        dispatch({
          type: 'RECEIVE_ALL_SHARE_CARDS',
          payload: {
            profileId,
            collection: shareCardCollection,
            lastUpdated: new Date().getTime(),
            isFetching: false,
          },
        });

        res({
          ...updateShareCardListQueryStore(shareCardCollection),
          isLoaded: true,
        });
      });
    });
  };
}

function requestShareCardDefinitions(dispatch) {
  return function requestShareCardDefinitions_API(queryKey, profileId) {
    return new Promise((res, rej) => {
      ShareCardService.getDefinitions(profileId)
        .then((definitions) => {
          dispatch({
            type: 'RECEIVE_SHARE_CARD_DEFINITIONS',
            payload: {
              profileId,
              collection: definitions,
              lastUpdated: new Date().getTime(),
              isFetching: false,
            },
          });

          res({
            ...updateShareCardDefinitionQueryStore(definitions),
            isLoaded: true,
          });
        });
    });
  };
}

export function useShareCardDefinitionList(profileId) {
  const dispatch = useDispatch();
  const queryData: any = useQuery(['scDefinitions', profileId], requestShareCardDefinitions(dispatch), { enabled: Boolean(profileId), staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_SC_DEF_DATA;
  const shareCardDefinionList = responseData.definitions;
  const shareCardDefinitionLookup = responseData.definionLookup;
  const isLoaded = responseData.isLoaded;

  return [
    {
      shareCardDefinionList,
      shareCardDefinitionLookup,
      isLoaded,
    }, {
      refresh: () => {
        queryData.refetch();
      },
      sort: () => {
        const defsById = shareCardDefinionList.reduce((r, def) => {
          r[def.id] = def;
          return r;
        }, {});

        const orderUids = [
          'backpack.emergency',
          'backpack.health_history',
          'backpack.food_allergies',
        ];

        return (a: ShareCard, b: ShareCard) => {
          const defA = defsById[a.definition_id];
          const defB = defsById[b.definition_id];

          if (!defA || !defB) return 1;

          if (defA.group_accessible && !defB.group_accessible) return 1;
          if (!defA.group_accessible && defB.group_accessible) return -1;

          const orderA = orderUids.indexOf(defA.uid);
          const orderB = orderUids.indexOf(defB.uid);

          if (orderA !== orderB) {
            if (orderA < 0) return 1;
            if (orderB < 0) return -1;

            if (orderA < orderB) return -1;
            if (orderA > orderB) return 1;
          }

          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        };
      },
      apiGetShareCardColor: () => {
        return new Promise((res, rej) => {
          ShareCardService.getShareCardColor(profileId)
            .then(payload => {
              res(payload.data);
            });
        });
      },
    },
  ];
}

const instantiateNewShareCard = (definitionId, colorId, shareCardDefinionList, groupContentsList, groupedHealthEntryList) => {
  const card = ShareCardModel.ofData(
    {
      definition_id: definitionId,
      color_id: colorId ? Number(colorId) : undefined,
    },
    shareCardDefinionList,
    { groupContents: groupContentsList },
  );

  if (card) {
    card.init(groupedHealthEntryList);
  }

  return card;
};
const instantiateShareCard = (shareCardId, options = {}, shareCardList, shareCardDefinionList, groupContentsList) => {
  const foundCard = shareCardList.find((s) => s.id === shareCardId);

  if (!foundCard) {
    return ShareCardModel.ofData({}, shareCardDefinionList, { groupContents: groupContentsList, ...options });
  } else if (shareCardList.length === 0 || shareCardDefinionList.length === 0) {
    return ShareCardModel.ofData({}, shareCardDefinionList, { groupContents: groupContentsList, ...options });
  } else {
    return ShareCardModel.ofData(foundCard, shareCardDefinionList, { groupContents: groupContentsList, ...options });
  }
};

export function useShareCardList(profileId) {
  const dispatch = useDispatch();
  const scQuery: any = useQuery(['shareCards', profileId], requestShareCards(dispatch), { enabled: Boolean(profileId), staleTime: STALE_TIME });
  const responseData = scQuery.data || INITIAL_SC_DATA;
  const shareCardList = responseData.list;
  const shareCardLookup = responseData.lookup;
  const isLoaded = responseData.isLoaded;
  const [{ shareCardDefinitionLookup, shareCardDefinionList, ...scDefState }] = useShareCardDefinitionList(profileId);
  const[{ groupContentsList }] = useGroupShareCardContentForActiveProfile();
  const [{ groupedHealthEntryList }] = useHealthEntriesForProfile(profileId);
  const [{ colorShareCardList }] = useLists();

  return [
    {
      shareCardList,
      shareCardLookup,
      shareCardDefinionList,
      shareCardDefinitionLookup,
      isLoaded: scDefState.isLoaded && isLoaded,
    },
    {
      getDefaultShareCards: () => {
        // returns emergency and health_history share cards only
        const defaultShareCardIds = ['backpack.emergency', 'backpack.health_history'];

        return shareCardList.filter(sc => {
          const def = shareCardDefinitionLookup[sc.definition_id] || {};
          return defaultShareCardIds.includes(def.uid);
        });
      },
      shareCardsAvailableForFiles: () => {
        return shareCardList.filter(sc => !ShareCardDefinitionModel.isFilesExcluded(shareCardDefinitionLookup[sc.definition_id]));
      },
      apiCreate: (newData) => {
        return new Promise((res, rej) => {
          ShareCardService.create(profileId, newData, (apiError, newShareCard: ShareCard) => {
            if (apiError) {
              rej(apiError);
            } else {
              dispatch({
                type: 'ADD_SHARE_CARD',
                payload: {
                  profileId,
                  cardData: newShareCard,
                },
              });
            }

            const newSCList = [...shareCardList, newData];
            queryCache.setQueryData(['shareCards', profileId], {
              ...scQuery.data,
              ...updateShareCardListQueryStore(newSCList),
            });

            queryCache.invalidateQueries('shareCards');
            queryCache.invalidateQueries('scDefinitions');

            res(newShareCard);
          });
        });
      },
      apiUpdate: (newData) => {
        // optimistic client-side updating
        const newSCList = shareCardList.map(sc => (sc.id === newData.id ? newData : sc));
        queryCache.setQueryData(['shareCards', profileId], {
          ...scQuery.data,
          ...updateShareCardListQueryStore(newSCList),
        });

        return new Promise((res, rej) => {
          ShareCardService.update(profileId, newData, (apiError) => {
            if (apiError) {
              rej(apiError);
            } else {
              dispatch({
                type: 'UPDATE_SHARE_CARD',
                payload: {
                  profileId,
                  id: newData.id,
                  cardData: newData,
                },
              });
            }

            queryCache.invalidateQueries('shareCards');

            res(newData);
          });
        });
      },
      apiDelete: (shareCardId) => {

        return new Promise((res, rej) => {
          ShareCardService.del(profileId, shareCardId, (apiError) => {
            if (apiError) {
              rej(apiError);
              return;
            }

            dispatch({
              type: 'DELETE_SHARE_CARD',
              payload: {
                profileId,
                id: shareCardId,
              },
            });

            const newSCList = shareCardList.filter(sc => (sc.id !== shareCardId));
            queryCache.setQueryData(['shareCards', profileId], {
              ...scQuery.data,
              ...updateShareCardListQueryStore(newSCList),
            });

            queryCache.invalidateQueries('shareCards');

            res();
          });
        });
      },
      instantiateNewShareCard: (definitionId, colorId) => {
        return instantiateNewShareCard(definitionId, colorId, shareCardDefinionList, groupContentsList, groupedHealthEntryList);
      },
      instantiate: (shareCardId, options = {}) => {
        return instantiateShareCard(shareCardId, options, shareCardList, shareCardDefinionList, groupContentsList);
      },
      instantiateShareCard: (shareCardData, options = {}) => {
        return ShareCardModel.ofData(shareCardData, shareCardDefinionList, { groupContents: groupContentsList, ...options });
      },
      getShareCardColor: (shareCardData) => {
        const scModel = ShareCardModel.ofData(shareCardData, shareCardDefinionList, { groupContents: groupContentsList });
        const color: RGBA = scModel ? scModel.getColor(colorShareCardList) : { a: 1, r: 0, g: 0, b: 0 };
        return new ShareCardColorModel(color);
      },
    },
  ];
}

export function useShareCardListForActiveProfile() {
  const [{ activeProfileId }] = useUIState();
  return useShareCardList(activeProfileId);
}

export function useShareCardForProfile(profileId, shareCardId?) {
  const dispatch = useDispatch();
  const scQuery: any = useQuery(['shareCards', profileId], requestShareCards(dispatch), { enabled: Boolean(profileId), staleTime: STALE_TIME });
  const responseData = scQuery.data || INITIAL_SC_DATA;
  const shareCardList = responseData.list;
  const shareCardLookup = responseData.lookup;
  const [{ shareCardDefinitionLookup, shareCardDefinionList, ...scDefState }] = useShareCardDefinitionList(profileId);
  const[{ groupContentsList }] = useGroupShareCardContentForActiveProfile();
  const [{ groupedHealthEntryList }] = useHealthEntriesForProfile(profileId);
  const [{ colorShareCardList, colorShareCardLookup, ...listState }] = useLists();

  const shareCard = shareCardList.find(sc => sc.id === shareCardId);

  const isLoaded = responseData.isLoaded && scDefState.isLoaded && listState.isLoaded;

  return [
    {
      shareCardId,
      shareCard,
      isLoaded,
    },
    {
      instantiate: (scData?, options = {}) => {
        const data = scData || shareCard;
        return ShareCardModel.ofData(data, shareCardDefinionList, { groupContents: groupContentsList, ...options });
      },
      instantiateNewShareCard: (definitionId, colorId) => {
        return instantiateNewShareCard(definitionId, colorId, shareCardDefinionList, groupContentsList, groupedHealthEntryList);
      },
      getShareCardColor: (overrideShareCardData?, newColorId?) => {
        const data = overrideShareCardData || shareCard;
        let color = null;
        if (newColorId) {
          color = colorShareCardLookup[newColorId].color;
        } else if (data) {
          const scDef = shareCardDefinitionLookup[data.definition_id];
          color = ShareCardModel.getColor(data, scDef, colorShareCardList);
        } else if (colorShareCardList.length > 0) {
          console.log('error');
          color = colorShareCardList[0].color;
        }
        return new ShareCardColorModel(color);
      },
      sharePdf: (preferredLanguageCode, isDependent, cardData, targetLanguageCode, includeUrlOnPdf) => {
        const def = shareCardDefinitionLookup[cardData.definition_id];
        const uid = def.uid;
        const isUrlAccessible = cardData.url_accessible;
        TrackEvent.shareCards.sharePdf(
          preferredLanguageCode,
          targetLanguageCode,
          uid,
          isDependent,
          isUrlAccessible,
          includeUrlOnPdf,
        );
      },
      apiUpdateShareCardPasscode: (profileId, isDependent, shareCardId, passcode) => {
        return new Promise((res, rej) => {
          ShareCardService.setPasscode(profileId, shareCardId, passcode, (apiError, payload) => {
            if (apiError) { rej(apiError); return; }
            const sc = shareCardLookup[shareCardId];
            const def = shareCardDefinitionLookup[sc.definition_id];
            const uid = def.uid;

            TrackEvent.shareCards.regeneratedAccessCode(uid, isDependent);

            res(payload);
          });
        });
      },
      apiUpdate: (newData) => {
        // optimistic client-side updating
        const newSCList = shareCardList.map(sc => (sc.id === newData.id ? newData : sc));
        queryCache.setQueryData(['shareCards', profileId], {
          ...scQuery.data,
          ...updateShareCardListQueryStore(newSCList),
        });

        return new Promise((res, rej) => {
          ShareCardService.update(profileId, newData, (apiError) => {
            if (apiError) {
              rej(apiError);
            } else {
              dispatch({
                type: 'UPDATE_SHARE_CARD',
                payload: {
                  profileId,
                  id: newData.id,
                  cardData: newData,
                },
              });
            }

            queryCache.invalidateQueries('shareCards');

            res(newData);
          });
        });
      },
    },
  ];
}
export function useShareCardForActiveProfile(shareCardId?) {
  const [{ activeProfileId }] = useUIState();
  return useShareCardForProfile(activeProfileId, shareCardId);
}

export function useShareCardDefinitionListForActiveProfile() {
  const [{ activeProfileId }] = useUIState();
  return useShareCardDefinitionList(activeProfileId);
}
