import React from 'react';

import PreformattedText from '../../../components/PreformattedText';

import './Footer.styles.scss';
import LogoBackpackRegistered from '../../../components/icons/LogoBackpackRegistered';

const UnsubscribeFooter = () => (
  <div className="cmp-unsubscribe-footer">
    <div className="content">
      <div>
        <LogoBackpackRegistered colorName="white" imageType="png" size="large" />
      </div>
      <PreformattedText textClassName="font-caption-2 font-color-gsLightest" text="backpack.copyright.message.text" />
    </div>
  </div>
);

export default UnsubscribeFooter;
