import React from 'react';

import MedliLongDefinitionView from '../../views/componentViews/components/MedliLongDefinitionView';
import InfoModal from './InfoModal';

interface Props {
  searchResult: any;
  onDismiss?(): void;
}

const SearchInfoModal = (props: Props) => (
  <InfoModal isFullHeight={true} onDismiss={props.onDismiss}>
    {
      (triggerAnimation) => (
        <MedliLongDefinitionView searchResult={props.searchResult} onLongDefinitionLoaded={triggerAnimation} />
      )
    }
  </InfoModal>
);

export default SearchInfoModal;
