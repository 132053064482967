import React from 'react';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  colorName?: string;
}

const DEFAULT_COLOR = 'gsMedLight';

const getClassName = (colorName = DEFAULT_COLOR) => {
  return colorClassNames.fill(colorName);
};

const Small = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g>
      <path className={getClassName(props.colorName)} d="M8,10A1.86,1.86,0,1,1,9.86,8.17,1.86,1.86,0,0,1,8,10Z"/>
      <path className={getClassName(props.colorName)} d="M2.23,10A1.86,1.86,0,1,1,4.09,8.17,1.86,1.86,0,0,1,2.23,10Z"/>
      <path className={getClassName(props.colorName)} d="M13.77,10a1.86,1.86,0,1,1,1.85-1.86A1.86,1.86,0,0,1,13.77,10Z"/>
    </g>
  </svg>
);

const Medium = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g>
      <path className={getClassName(props.colorName)} d="M12,14.67a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,12,14.67Z"/>
      <path className={getClassName(props.colorName)} d="M3.82,14.67a2.5,2.5,0,1,1,2.5-2.5A2.51,2.51,0,0,1,3.82,14.67Z"/>
      <path className={getClassName(props.colorName)} d="M20.18,14.67a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,20.18,14.67Z"/>
    </g>
  </svg>
);

const Large = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <g>
      <path className={getClassName(props.colorName)} d="M18,21.81a3.65,3.65,0,1,1,3.64-3.64A3.64,3.64,0,0,1,18,21.81Z"/>
      <path className={getClassName(props.colorName)} d="M5.64,21.81a3.65,3.65,0,1,1,3.65-3.64A3.64,3.64,0,0,1,5.64,21.81Z"/>
      <path className={getClassName(props.colorName)} d="M30.36,21.81A3.65,3.65,0,1,1,34,18.17,3.65,3.65,0,0,1,30.36,21.81Z"/>
    </g>
  </svg>
);

const XLarge = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
    <g>
      <path className={getClassName(props.colorName)} d="M28,33.38A5.38,5.38,0,1,1,33.38,28,5.39,5.39,0,0,1,28,33.38Z"/>
      <path className={getClassName(props.colorName)} d="M9.31,33.38A5.38,5.38,0,1,1,14.69,28,5.39,5.39,0,0,1,9.31,33.38Z"/>
      <path className={getClassName(props.colorName)} d="M46.69,33.38A5.38,5.38,0,1,1,52.06,28,5.39,5.39,0,0,1,46.69,33.38Z"/>
    </g>
  </svg>
);

export default {
  Small,
  Medium,
  Large,
  XLarge,
};
