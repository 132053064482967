import React from 'react';

import { useDispatch } from 'react-redux';
import { actions as sessionActions } from '../redux/ducks/session';

export const useSession = () => {
  const dispatch = useDispatch();

  return [{}, {
    checkVersion: (): Promise<any> => {
      return dispatch(sessionActions.versionCheck());
    },
  }];
};
