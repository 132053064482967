import React from 'react';
import { useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import { actions as systemModalActions, OverlayBlur } from '../../redux/ducks/systemModal';
import { useUIState } from '../../hooks/useUIState';

export const ModalContext = React.createContext({ data: { blurSize: '' }, setData: (v) => {} });

interface Props1 {
  isVisible: boolean;
  blurSize: string;
  onBackgroundDismiss?(): void;
}

export const ModalPortal: React.FC<Props1> = (props) => {
  const { children, isVisible, blurSize, onBackgroundDismiss } = props;
  const { setData } = React.useContext(ModalContext);
  const dispatch = useDispatch();
  const el = React.useRef(document.createElement('div'));
  const blurClassName = blurSize ? `_${blurSize}` : '';

  const [, uiActions] = useUIState();
  React.useEffect(() => {
    const modalRoot = document.getElementById('modal-root');

    if (modalRoot) {
      modalRoot.appendChild(el.current);
    }

    return () => {
      if (modalRoot) {
        modalRoot.removeChild(el.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (isVisible) {
      el.current.className = 'cmp-systemmodal';
      uiActions.setBlur(blurSize);
    } else {
      el.current.className = '';
      uiActions.removeBlur();
    }

    setData({
      blurSize: isVisible ? blurSize : '',
      onBackgroundDismiss,
      isModalVisible: isVisible,
    });
    dispatch(systemModalActions.update({ blurSize, isVisible, isLoaded: isVisible, isBackgroundClickDisabled: !Boolean(onBackgroundDismiss) }));
  }, [blurSize, isVisible]);

  return isVisible
    ? ReactDOM.createPortal(
      <>
        <div
          className={classnames('cmp-systemmodal-background', blurClassName)}
          onClick={() => {
            if (onBackgroundDismiss) {
              onBackgroundDismiss();
            }
          }}
        />
        <div className="cmp-systemmodal-wrapper util-application-width">
          <div className="cmp-systemmodal-content _active">
            {children}
          </div>
        </div>
      </>,
      el.current,
    )
  : null;
};
