
import { useQuery, queryCache } from 'react-query';

import { useDispatch } from 'react-redux';

import ContactService from '../../apiServices/ContactService';
import ContactModel from '../../models/ContactModel';
import { useAttachments } from './useAttachments';
import { useUIState } from './useUIState';
import { attachmentTypes } from '../../models/AttachmentModel';

const STALE_TIME = 10000;

const INITIAL_CONTACT_LIST_STATE = {
  list: [],
  lookup: {},
  isLoaded: false,
};
function requestAllContactList(dispatch) {
  return function requestAllContactList_API(queryKey) {
    return new Promise((res, rej) => {
      return ContactService.allContacts()
        .then(payload => {

          const collection = {};
          const lookup = {};
          payload.forEach(d => {
            collection[d.profile_id] = collection[d.profile_id] || [];
            collection[d.profile_id].push(d);
            lookup[d.profile_id] = collection[d.profile_id];
          });

          dispatch({
            type: 'app/contacts/RECEIVE_ALL_FOR_PROFILE',
            payload: {
              collection,
              lookup,
            },
          });
          res({
            list: collection,
            lookup,
            isLoaded: true,
          });
        }).catch(rej);
    });
  };
}
function requestContactListForProfile(dispatch) {
  return function requestContactsForProfile_API(queryKey, profileId) {
    return new Promise((res, rej) => {
      return ContactService.allContactsForProfile(profileId)
        .then(payload => {
          dispatch({
            type: 'app/contacts/RECEIVE_ALL_FOR_PROFILE',
            payload: {
              collection: {
                [profileId]: payload,
              },
              lookup: {
                [profileId]: payload.reduce((acc, p) => { acc[p.id] = p; return acc; }, {}),
              },
            },
          });
          res({
            list: payload,
            lookup: payload.reduce((acc, v) => ({ ...acc, [v.id]: v }), {}),
            isLoaded: true,
          });
        }).catch(rej);
    });
  };
}

const INITIAL_CONTACT_STATE = {
  contact: ContactModel.defaultContact(null, null),
  isLoaded: false,
};
function requestContactForProfile(queryKey, profileId, contactId) {
  return new Promise((res, rej) => {
    return ContactService.getContact(profileId, contactId)
      .then(payload => {
        res({
          contact: payload,
          isLoaded: true,
        });
      }).catch(rej);
  });
}

export function useContactsForProfile(profileId, contactId?) {
  const dispatch = useDispatch();
  const queryData: any = useQuery(['contacts', profileId], requestContactListForProfile(dispatch), { staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_CONTACT_LIST_STATE;
  const contactList = responseData.list;
  const contactLookup = responseData.lookup;

  const contact = (contactId && contactLookup[contactId]) ? contactLookup[contactId] : ContactModel.defaultContact(null, null);

  return [
    {
      contact,
      contactList,
      contactLookup,
      isLoaded: responseData.isLoaded && !queryData.isFetching,
    },
    {
      groupContactsByProvider: () => {
        return contactList.reduce((acc, c) => {
          const keyName = ContactModel.isProvider(c) ? 'providerContacts' : 'nonProviderContacts';
          return {
            ...acc,
            [keyName]: acc[keyName].concat(c),
          };
        }, {
          nonProviderContacts: [],
          providerContacts: [],
        });
      },
    },
  ];
}

export function useContactsForActiveProfile(contactId?) {
  const [{ activeProfileId }] = useUIState();
  return useContactsForProfile(activeProfileId, contactId);
}

export function useContactForProfile(profileId, contactId?) {
  const queryData: any = useQuery(['contacts', profileId, contactId], requestContactForProfile, { enabled: Boolean(contactId), staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_CONTACT_STATE;
  const contact = responseData.contact;
  const isLoaded = responseData.isLoaded && !queryData.isFetching;
  const [, attachmentActions] = useAttachments();
  return [
    {
      contact,
      isLoaded,
    },
    {
      apiCreateContact: (newData, attachmentType, parentId) => {
        const cleanedData = ContactModel.cleanDataForSubmit(newData, true);

        return new Promise((res, rej) => {
          ContactService.createContact(cleanedData)
            .then((newContact) => {
              queryCache.invalidateQueries('contacts');
              queryCache.invalidateQueries('attachments');

              if (attachmentType && parentId) {
                attachmentActions.apiAttachContact(newContact.id, attachmentType, parentId)
                  .then(() => res(newContact));
              } else {
                res(newContact);
              }
            }).catch(rej);
        });
      },
      apiUpdateContact: (newData) => {
        const cleanedData = ContactModel.cleanDataForSubmit(newData, false);
        return ContactService.updateContact(cleanedData)
          .then(() => {
            queryCache.invalidateQueries('contacts');
            queryCache.invalidateQueries('attachments');

            // const contactId = data.id;
            // const profileId = data.profile_id;
            // const state = getState();
            // const contacts = state.contacts.collection[profileId] || [];
            // const index = contacts.indexOf(f => f.id !== contactId);
            // if (index >= 0) {
            //   contacts[index] = data;
            // } else {
            //   contacts.push(data);
            // }
            // dispatch(receiveContacts(profileId, contacts));
            return newData;
          });
      },
      apiDeleteContact: (newId?) => {
        const id = newId || contactId;
        return ContactService.deleteContact(profileId, id).then(() => {
          queryCache.invalidateQueries('contacts');
          queryCache.invalidateQueries('attachments');
        });
      },
    },
  ];
}

export function useContactForActiveProfile(contactId?) {
  const [{ activeProfileId }] = useUIState();
  return useContactForProfile(activeProfileId, contactId);
}

export function useContactsForAllProfiles() {
  const dispatch = useDispatch();
  const queryData: any = useQuery(['contacts'], requestAllContactList(dispatch), { staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_CONTACT_LIST_STATE;
  const contactListByProfile = responseData.list;
  const contactLookupByProfile = responseData.lookup;

  return [
    {
      contactListByProfile,
      contactLookupByProfile,
      isLoaded: responseData.isLoaded,
    },
  ];
}
