
import { combineReducers } from 'redux';
import * as _ from 'lodash';

import ListsService from '../../../../apiServices/ListsService';

import actionNames from '../../actionNames';
import listLocaleReducer, { selectors as localeSelectors } from './listLocale';
import listLocaleMedliReducer, { selectors as localeMedliSelectors } from './listLocaleMedli';
import colorThemeReducer, { selectors as colorThemeSelectors } from './colorTheme';
import ListModel from '../../../../models/ListModel';

function composeListReducer(listName, requestAction, receiveAction) {
  return function singleListReducer(state = {
    isFetching: false,
    collection: [],
    lookup: {},
    lastUpdated: null,
  }, action) {
    switch (action.type) {
      case receiveAction:
      case requestAction:
        return _.assign({}, state, action.payload);
      case actionNames.lists.RECEIVE_ALL:
      case actionNames.lists.REQUEST_ALL:
        return _.assign({}, state, action.payload[listName]);
      default:
        return state;
    }
  };
}


const allReducer = combineReducers({
  birthSex: composeListReducer('birthSex', actionNames.lists.REQUEST_BIRTHSEX, actionNames.lists.RECEIVE_BIRTHSEX),
  bloodType: composeListReducer('bloodType', actionNames.lists.REQUEST_BLOODTYPE, actionNames.lists.RECEIVE_BLOODTYPE),
  cancelReason: composeListReducer('cancelReason', actionNames.lists.REQUEST_CANCEL_REASON, actionNames.lists.RECEIVE_CANCEL_REASON),
  category: composeListReducer('category', actionNames.lists.REQUEST_CATEGORY, actionNames.lists.RECEIVE_CATEGORY),
  colorShareCard: composeListReducer('colorShareCard', actionNames.lists.REQUEST_SHARECARDCOLORS, actionNames.lists.RECEIVE_SHARECARDCOLORS),
  colorTheme: colorThemeReducer,

  contactDetailLabel: composeListReducer('contactDetailLabel', actionNames.lists.REQUEST_CONTACT_DETAIL_LABEL, actionNames.lists.RECEIVE_CONTACT_DETAIL_LABEL),
  contactSpecialty: composeListReducer('contactSpecialty', actionNames.lists.REQUEST_CONTACT_SPECIALTY, actionNames.lists.RECEIVE_CONTACT_SPECIALTY),
  contactRelationship: composeListReducer('contactRelationship', actionNames.lists.REQUEST_CONTACT_RELATIONSHIP, actionNames.lists.RECEIVE_CONTACT_RELATIONSHIP),
  contactHealthcareProxy: composeListReducer('contactHealthcareProxy', actionNames.lists.REQUEST_CONTACT_HEALTHCARE_PROXY, actionNames.lists.RECEIVE_CONTACT_HEALTHCARE_PROXY),

  country: composeListReducer('country', actionNames.lists.REQUEST_COUNTRY, actionNames.lists.RECEIVE_COUNTRY),
  diagnosis: composeListReducer('diagnosis', actionNames.lists.REQUEST_DIAGNOSIS, actionNames.lists.RECEIVE_DIAGNOSIS),
  frequency: composeListReducer('frequency', actionNames.lists.REQUEST_FREQUENCY, actionNames.lists.RECEIVE_FREQUENCY),
  gender: composeListReducer('gender', actionNames.lists.REQUEST_GENDER, actionNames.lists.RECEIVE_GENDER),
  geneVariantPresent: composeListReducer('geneVariantPresent', actionNames.lists.REQUEST_GENE_VARIANT_PRESENT, actionNames.lists.RECEIVE_GENE_VARIANT_PRESENT),

  locale: listLocaleReducer,
  localeMedli: listLocaleMedliReducer,

  profileState: composeListReducer('profileState', actionNames.lists.REQUEST_PROFILE_STATE, actionNames.lists.RECEIVE_PROFILE_STATE),
  race: composeListReducer('race', actionNames.lists.REQUEST_RACE, actionNames.lists.RECEIVE_RACE),
  residenceCountry: composeListReducer('residenceCountry', actionNames.lists.REQUEST_RESIDENCE_COUNTRY, actionNames.lists.RECEIVE_RESIDENCE_COUNTRY),
  residenceState: composeListReducer('residenceState', actionNames.lists.REQUEST_RESIDENCE_STATE, actionNames.lists.RECEIVE_RESIDENCE_STATE),
});

export default allReducer;

function receiveAllLists(responseCollection) {
  const responseData = {};
  _.keys(responseCollection).forEach((key) => {
    const collection = responseCollection[key];
    const lookup = {};
    collection.forEach(item => {
      lookup[item.id] = item;
    });

    responseData[key] = {
      lookup,
      collection,
    };
  });

  return {
    type: actionNames.lists.RECEIVE_ALL,
    payload: _.assign({}, responseData, {
      isFetching: false,
      lastUpdated: new Date().getTime(),
    }),
  };
}

function requestAllLists() {
  return {
    type: actionNames.lists.REQUEST_ALL,
    payload: {},
  };
}

export const actions = {
  loadAll: () => {
    return (dispatch) => {
      return new Promise((res) => {
        dispatch(requestAllLists());

        ListsService.all((apiError, responseCollection) => {
          dispatch(receiveAllLists(responseCollection));
          res();
        });
      });
    };
  },
};

export const selectors = {
  getGenderCollection: (state) => {
    return state.lists.gender.collection;
  },

  getBloodTypeCollection: (state) => {
    return state.lists.bloodType.collection;
  },

  getResidenceCountryCollection: (state) => {
    return state.lists.residenceCountry.collection;
  },

  getResidenceCountry: (state, residenceId) => {
    return state.lists.residenceCountry.collection.filter(c => c.id === residenceId)[0];
  },

  isResidenceStateRequired: (countryId, state) => {
    const countries = state.lists.residenceCountry.collection.filter(c => c.id === countryId);
    if (countries.length === 0) { return null; }
    return countries[0].code === 'USA';
  },

  isIntegrationsSupportedByCountry: (state, countryId) => {
    const countries = state.lists.residenceCountry.collection.filter(c => c.id === countryId);
    if (countries.length === 0) { return null; }
    return countries[0].code === 'USA';
  },

  getResidenceStateCollection: (state) => {
    return state.lists.residenceState.collection;
  },

  getRaceCollection: (state) => {
    return state.lists.race.collection;
  },

  getFrequencyList: (state) => {
    return state.lists.frequency.collection;
  },

  getFrequencyFromPhraseKey: (phraseKey, state) => {
    const foundFrequency = state.lists.frequency.collection.filter((f) => f.key === phraseKey);
    return foundFrequency.length > 0 ? foundFrequency[0] : {};
  },

  getFrequencyFromId: (frequencyId, state) => {
    const foundFrequency = state.lists.frequency.collection.filter((f) => f.id === frequencyId);
    return foundFrequency.length > 0 ? foundFrequency[0] : {};
  },

  getBirthSexCollection: (state) => {
    return state.lists.birthSex.collection;
  },

  getGeneVariantPresentCollection: (state) => {
    return state.lists.geneVariantPresent.collection;
  },

  getGeneVariantPresent: (id, state) => {
    return state.lists.geneVariantPresent.lookup[id] || null;
  },

  getColorThemeCollection: (state) => {
    return state.lists.colorTheme.collection;
  },

  getProfileStateCollection: (state) => {
    return state.lists.profileState.collection;
  },

  getCountryCollection: (state) => {
    return state.lists.country.collection;
  },

  getCancelReasonList: (state) => {
    return state.lists.cancelReason.collection;
  },

  getDiagnosisList: (state) => {
    return state.lists.diagnosis.collection;
  },

  getShareCardColors: (state) => {
    return state.lists.colorShareCard.collection;
  },

  getContactDetailLabelCollection: (state) => {
    return state.lists.contactDetailLabel.collection;
  },

  getContactSpecialtyCollection: (state, intl) => {
    const list = state.lists.contactSpecialty.collection;
    return list.sort((a, b) => {
      if (!a.other && !b.other) {
        const aName = intl.formatMessage({ id: a.key });
        const bName = intl.formatMessage({ id: b.key });
        return aName.localeCompare(bName, intl.locale, { sensitivity: 'base', ignorePunctuation: 'true' });
      } else if (a.other) {
        return 1;
      } else if (b.other) {
        return -1;
      }
    });
  },

  getContactSpecialtyCustom: (state) => {
    return state.lists.contactSpecialty.collection.find(c => c.is_custom === 1);
  },

  getContactRelationshipCollection: (state) => {
    return state.lists.contactRelationship.collection;
  },

  getContactRelationshipHealthcareProvider: (state) => {
    return ListModel.ContactRelationship.getHealthcareProviderRelationship(state.lists.contactRelationship.collection);
  },

  getContactRelationshipOtherProvider: (state) => {
    return ListModel.ContactRelationship.getOtherRelationship(state.lists.contactRelationship.collection);
  },

  getContactRelationshipCustom: (state) => {
    return ListModel.ContactRelationship.getCustomRelationship(state.lists.contactRelationship.collection);
  },

  getContactHealthcareProxyCollection: (state) => {
    return state.lists.contactHealthcareProxy.collection;
  },

  getContactDetailLabel: (state, id, defaultName) => {
    const detailLabel = state.lists.contactDetailLabel.collection.find(c => c.id === id);
    if (detailLabel) {
      return detailLabel;
    } else if (defaultName) {
      return state.lists.contactDetailLabel.collection.find(c => c.name === defaultName);
    }
  },

  getContactSpecialty: (state, id) => {
    return state.lists.contactSpecialty.collection.find(c => c.id === id);
  },

  getContactRelationship: (state, id) => {
    return state.lists.contactRelationship.collection.find(c => c.id === id);
  },

  getContactHealthcareProxy: (state, ids) => {
    if (Array.isArray(ids)) {
      return ids.map(id => state.lists.contactHealthcareProxy.collection.find(c => c.id === id)).filter(a => !!a);
    } else {
      return state.lists.contactHealthcareProxy.collection.find(c => c.id === ids);
    }
  },

  ...colorThemeSelectors,
  ...localeMedliSelectors,
  ...localeSelectors,

};
