
import React from 'react';
import { FormattedMessage } from 'react-intl';

import RouteNames from '../../../appAuthorized/RouteNames';
import { HEPropertyCollection } from './HEPropertyCollection';
import { Icons } from '../../../../bphComponents/bundle';
import COLORS from '../../../styles/Colors';
import { useNavigate } from '../../../hooks/useNavigate';
import MultilineTruncatedText from '../../../components/MultilineTruncatedText';
import PreformattedText from '../../../components/PreformattedText';
import { iTrackable, iObservation, iComment } from '../../../../apiServices/apiModels/Trackable';
import { useActiveUser } from '../../../hooks/useUser';
import OccurrenceEmptyProperty from './OccurrenceEmptyProperty';
import TextArea from '../../../components/TextArea';
import Comment from '../../../../models/Comment';


const getComment = (c) => {
  if (!c) { return null; }

  return (
    <MultilineTruncatedText lines={2}>
      <PreformattedText>
        {c.content.trim().split('\n').find(a => !!a)}
      </PreformattedText>
    </MultilineTruncatedText>
  );
};

interface Props {
  comments: any[];
  onCreate(): void;
  onSelect(comment): void;
  languageCode: string;
  hasBottomBorder?: boolean;
}
export const Comments: React.FC<Props> = (props) => {
  const { comments, onCreate, onSelect, languageCode, hasBottomBorder } = props;
  const hasComments = comments.length > 0;

  if (!hasComments) {
    return (
      <OccurrenceEmptyProperty
        hasBottomBorder={hasBottomBorder}
        languageCode={languageCode}
        icon={<Icons.IconComment.Medium color={COLORS.healthEntryPropertyIconEmpty} />}
        emptyValue={<FormattedMessage id="healthEntry.addComment.button.label" />}
        onCreate={onCreate}
      />
    );
  }

  return (
    <HEPropertyCollection
      languageCode={languageCode}
      icon={<Icons.IconComment.Medium color={COLORS.healthEntryPropertyIconWithValue} />}
      collection={comments}
      getValue={getComment}
      addAnotherValue={<FormattedMessage id="healthEnty.addAnotherComment.button.label" />}
      onClick={onSelect}
      onCreate={onCreate}
      onRemove={null}
    />
  );
};

interface HECommentsProps {
  healthEntry: any;
  parentHealthEntryId?: number;
}

export const HealthEntryComments: React.FC<HECommentsProps> = (props) => {
  const { healthEntry, parentHealthEntryId } = props;
  const comments = healthEntry.data().comments;
  const healthEntryId = healthEntry.id;

  const [, navActions] = useNavigate();
  const [{ preferredLanguageCode }] = useActiveUser();

  return (
    <Comments
      comments={comments}
      languageCode={preferredLanguageCode}
      onCreate={() => {
        if (parentHealthEntryId) {
          navActions.goTo(RouteNames.createHEValueComment(parentHealthEntryId, healthEntryId));
        } else {
          navActions.goTo(RouteNames.createHEComment(healthEntryId));
        }
      }}
      onSelect={(comment) => {
        if (parentHealthEntryId) {
          navActions.goTo(RouteNames.healthEntryValueComment(String(parentHealthEntryId), healthEntryId, comment.id));
        } else {
          navActions.goTo(RouteNames.healthEntryComment(healthEntryId, comment.id));
        }
      }}
    />
  );
};

interface iTrackableCommentsProps {
  trackable: iTrackable;
}
export const TrackableComments: React.FC<iTrackableCommentsProps> = (props) => {
  const { trackable } = props;
  const trackableId = trackable.id;

  const [{ preferredLanguageCode }] = useActiveUser();
  const [, navActions] = useNavigate();

  return (
    <Comments
      comments={trackable.comments}
      hasBottomBorder={true}
      languageCode={preferredLanguageCode}
      onCreate={() => {
        navActions.goTo(RouteNames.createTrackableComment(trackableId));
      }}
      onSelect={(comment) => {
        navActions.goTo(RouteNames.trackableComment(trackableId, comment.id));
      }}
    />
  );
};



interface InlineCommentProps {
  comment: any;
  onCreate(): void;
  onSelect(c): void;
  onChange(t): void;
}
const InlineComment: React.FC<InlineCommentProps> = (props) => {
  const { comment, onChange } = props;

  const value = comment.content;

  return (
    <div>
      <div className="font-caption-1 font-color-gsDark" style={{ marginBottom: 8 }}>
        <FormattedMessage id="healthEntry.comments.label" />
      </div>
      <div>
        <TextArea
          value={value}
          className="font-body font-color-gsDark"
          default
          height="120px"
          defaultFocus={false}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          style={{
            borderColor: COLORS.dtDarkMiddle,
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderRadius: 4,
            padding: 8,
          }}
        />
      </div>
    </div>
  );
};

interface iObservationCommentsProps {
  comment: iComment;
  onChange(c): void;
}
export const ObservationComments: React.FC<iObservationCommentsProps> = (props) => {
  const { onChange } = props;
  const comment = props.comment || Comment.empty();

  return (
    <InlineComment
      comment={comment}
      onCreate={() => {}}
      onSelect={(comment) => {}}
      onChange={(v) => {
        onChange(v);
      }}
    />
  );
};
