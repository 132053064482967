import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import DeleteModal from './DeleteModal';
import { ProfileThemeedWarningModal } from './WarningModal';

import { actions as systemModalActions } from '../../redux/ducks/systemModal';
import { getActiveProfile, getIsMainProfile } from '../../appAuthorized/redux/reduxSelectors';

function mapStateToProps(state) {
  const profile = getActiveProfile(state);

  return {
    isMainProfile: getIsMainProfile(state),
    profileName: profile.greetingName(),
    profileId: profile.data.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: (content, options) => {
      dispatch(systemModalActions.push(content, options));
    },
  };
}

const ReconsiderDeleteModal = (props) => {
  const { onConfirm, onAlternate, onCancel, phraseMessages } = props;

  return (
    <ProfileThemeedWarningModal
      phraseMessages={phraseMessages}
      hasCloseButton={true}
      onConfirm={onConfirm}
      onAlternate={onAlternate}
      onDismiss={onCancel}
    />
  );
}

interface Props {
  isVisible: boolean;
  shouldReconsider: boolean;
  showModal(c, o): void;
  deleteModal: {
    phraseMessages: any;
    onConfirm(): void;
    onCancel(): void;
  };
  reconsiderModal: {
    phraseMessages: any;
    onConfirm(): void;
    onAlternate?(): void;
    onCancel(): void;
  };
}

const DeleteOrReconsiderWarningModal: FC<Props> = (props) => {
  const { isVisible, shouldReconsider, reconsiderModal, deleteModal, showModal } = props;
  useEffect(() => {
    if (shouldReconsider && isVisible) {
      showModal(<ReconsiderDeleteModal {...reconsiderModal} />, {
        onDismiss: reconsiderModal.onCancel,
      });
    }
  }, [isVisible, shouldReconsider]);

  return (
    <DeleteModal
      isVisible={isVisible && !shouldReconsider}
      primaryButtonPhrase={deleteModal.phraseMessages.primaryButton}
      onConfirmAction={deleteModal.onConfirm}
      onCancelAction={deleteModal.onCancel}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteOrReconsiderWarningModal);
