import React from 'react';
import { useQuery, queryCache } from 'react-query';

import { useDispatch, useSelector } from 'react-redux';
import { actions as groupActions, selectors as groupSelectors } from '../redux/ducks/group';

import GroupService from '../../apiServices/GroupService';
import { useUIState } from './useUIState';

const STALE_TIME = 10000;

const INITIAL_SC_GROUP_STATE = {
  list: [],
  lookup: {},
  isLoaded:false,
};

export function useActiveGroupsForProfile(profileId) {
  const groupData = useSelector(state =>  groupSelectors.getActiveGroupIdsForProfile(state, profileId));
  const isLoaded = useSelector(state =>  Boolean(state.groups.memberships[profileId]));

  return {
    data: groupData,
    isLoaded,
  };
}

export function useGroupForProfile(profileId, groupId, forceRefresh?) {
  const dispatch = useDispatch();
  const groupData = useSelector(state => groupSelectors.getGroup(state, groupId));
  const isLoaded = true;

  React.useEffect(() => {
    if (forceRefresh) {
      dispatch(groupActions.requestGroupById(groupId));
    }
  }, []);

  return [
    {
      group: groupData,
      isLoaded,
    },
    {
      joinGroup: () => {
        return new Promise((res, rej) => {
          dispatch(groupActions.joinGroup(profileId, groupData, (err) => {
            if (err) {
              rej(err);
              return;
            }
            res();
          }));
        });
      },
      clearGroupSearch: () => {
        dispatch(groupActions.clearSearchResults());
      },
    },
  ];
}

export function useGroupForActiveProfile(groupId, forceRefresh?) {
  const [{ activeProfileId }] = useUIState();
  return useGroupForProfile(activeProfileId, groupId, forceRefresh);
}

export function useGroupsForProfile(profileId) {
  const dispatch = useDispatch();
  const groupData = useSelector(state =>  groupSelectors.getActiveGroupIdsForProfile(state, profileId));

  return [
    groupData,
    {
      fetch: () => {
        if (profileId) {
          dispatch(groupActions.requestGroups(profileId));
        }
      },
      joinGroup: () => {
        return new Promise((res, rej) => {
          dispatch(groupActions.joinGroup(profileId, groupData, (err) => {
            if (err) {
              rej(err);
              return;
            }
            res();
          }));
        });
      },
      clearGroupSearch: () => {
        dispatch(groupActions.clearSearchResults());
      },
    },
  ];
}

export function useGroupIntent(user) {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    if (user.intent) {
      dispatch(groupActions.requestGroupById(user.intent.join_group.group_id))
      .then(() => {
        setIsLoaded(true);
      });
    } else if (user.email) {
      setIsLoaded(true);
    }
  }, [user.intent]);

  return [isLoaded];
}

function requestShareCardGroupContents(dispatch) {
  return function requestShareCardGroupContents_API(queryKey, profileId) {
    return new Promise(async (res, rej) => {

      GroupService.getShareCardGroupContents(profileId)
      .then(groupContents => {
        dispatch({
          type: 'RECEIVE_SHARE_CARD_GROUP_CONTENTS',
          payload: {
            profileId,
            collection: groupContents,
            lastUpdated: new Date().getTime(),
            isFetching: false,
          },
        });
        res({
          list: groupContents,
          lookup: groupContents.reduce((acc, gc) => {
            return { ...acc, [gc.id]: gc };
          }, {}),
          isLoaded: true,
        });
      });
    });
  };
}

export function useGroupShareCardContent(profileId) {
  const dispatch = useDispatch();
  const queryData: any = useQuery(['scGroupContents', profileId], requestShareCardGroupContents(dispatch), { staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_SC_GROUP_STATE;

  return [
    {
      groupContentsList: responseData.list,
      groupContentsLookup: responseData.lookup,
      isLoaded: responseData.isLoaded,
    },
  ];
}

export function useGroupShareCardContentForActiveProfile() {
  const [{ activeProfileId }] = useUIState();
  return useGroupShareCardContent(activeProfileId);
}
