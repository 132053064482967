
import React from 'react';

import { Card } from '../../../../../bphComponents/bundle';

interface Props {
  value?: any;
  onClick?(): void;
  label?: any;
  isExpanded?: boolean;
}

const AddTitleRow: React.FC<Props> = (props) => {
  const { onClick, label, isExpanded } = props;

  return (
    <Card.CollapsibleControl
      isExpanded={isExpanded}
      isMultiLine={false}
      onClick={onClick}
    >
      {label}
    </Card.CollapsibleControl>
  );
};

const ValueSummaryRow: React.FC<Props> = (props) => {
  const { value, onClick, label } = props;

  return value ? (
    <Card.CollapsibleControl
      isExpanded={false}
      isMultiLine={true}
      onClick={onClick}
    >
      <div
        className="font-footnote font-color-gsDark"
        style={{
          marginBottom: 8,
        }}
      >
        {label}
      </div>
      <div className="font-body font-color-gsDarkest">
        {value}
      </div>
    </Card.CollapsibleControl>
  ) : (
    <AddTitleRow
      label={label}
      onClick={onClick}
      isExpanded={false}
    />
  );
};


interface PrimitiveTitleRowProps {
  valueSummary?: any;
  onClick?(b): void;
  label?: any;
  isExpanded?: boolean;
}
const PrimitiveTitleRow: React.FC<PrimitiveTitleRowProps> = (props) => {
  const { label, valueSummary, onClick, isExpanded } = props;

  return (
    <Card.CardContent
      onClick={() => {
        onClick(!isExpanded);
      }}
    >
      <div style={{ marginRight: 4 }}>
      {
        isExpanded
        ? (
          <AddTitleRow
            label={label}
            isExpanded={isExpanded}
          />
        ) : (
          <ValueSummaryRow
            value={valueSummary}
            label={label}
          />
        )
      }
      </div>
    </Card.CardContent>
  );
};

export default PrimitiveTitleRow;
