
import React from 'react';

import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentFrame from '../../applicationFrame/ContentFrame';
import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import ContentWrapper from '../../components/ContentWrapper';
import RouteNames from '../../appAuthorized/RouteNames';

import AppUtils from '../../../utilities/AppUtils';

import SelectableDetailLabel from './components/SelectableDetailLabel';
import { Card } from '../../../bphComponents/bundle';
import { useUIEditContact } from '../../hooks/useUIEdit';
import { useLists } from '../../hooks/useLists';
import { useNavigate } from '../../hooks/useNavigate';

interface Props {
  match: any;
  location: any;
}

const ViewContactDetailLabel: React.FC<Props> = (props) => {
  const { match } = props;
  const contactId = Number(match.params.contactId);
  const detailId = Number(match.params.detailId);
  const detailType = AppUtils.getQueryParams(props.location.search).type;

  let titlePhrase = {};
  switch (detailType) {
    case 'email':
      titlePhrase = {
        id: 'contactEmailLabel.title',
      };
      break;
    case 'phone':
      titlePhrase = {
        id: 'contactPhoneLabel.title',
      };
      break;
    case 'address':
      titlePhrase = {
        id: 'contactAddressLabel.title',
      };
      break;
    case 'website':
      titlePhrase = {
        id: 'contactWebsiteLabel.title',
      };
      break;
    default:
      break;
  }


  const [{ editingContact, isLoaded }, uiEditActions] = useUIEditContact(contactId);
  const [{ contactDetailLabelList }] = useLists();
  const [, navActions] = useNavigate();

  const [detailLabelId, setDetailLabelId] = React.useState(null);
  const labelList = contactDetailLabelList.filter(d => d.type === detailType);

  React.useEffect(() => {
    if (!isLoaded) { return; }

    const detailData = editingContact.details.find(d => d.id === detailId) || {};
    setDetailLabelId(detailData.contact_detail_label_id);
  }, [isLoaded]);

  return (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          cancel={() => { navActions.goBack(RouteNames.contactEdit(contactId.toString())); }}
          submit={() => {
            uiEditActions.updateDetailLabel(detailId, detailLabelId)
              .then(() => {
                navActions.goBack(RouteNames.contactEdit(contactId.toString()));
              });
          }}
          onDisabledClick={() => {}}
          color="white"
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText {...titlePhrase} />
            <ContentWrapper>
              <Card.CardShape>
                {
                  labelList.map(s => (
                    <SelectableDetailLabel key={s.id} data={s} isSelected={s.id === detailLabelId} onClick={setDetailLabelId} />
                  ))
                }
              </Card.CardShape>
            </ContentWrapper>
          </ScrollAwareScroller>

        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
};

export default ViewContactDetailLabel;
