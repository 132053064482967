
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './PublicShareCardControls.scss';

import { LargePreferredLanguageDropdownList } from '../../../components/LanguageDropdownList';
import IconFileDownload from '../../../components/icons/IconFileDownload';
import ShareCardService from '../../../../apiServices/ShareCardService';
import { publicPasscode } from '../../../../utilities/LocalStorage';
import ContentLoader from '../../../components/ContentLoader';

class PublicShareCardControls extends React.Component<{ hash, languageCode, requiresPasscode }, { progress }> {
  state = {
    progress: false,
  };

  render() {
    const { progress } = this.state;

    return (
      <div className="cmp-publicsharecard-controls util-public-share-card-frame-padding">
        <a className="download" href="" onClick={this.downloadPdf}>
          <div style={{ position: 'relative', width: 50, left: progress ? -8 : 0 }}>
            <ContentLoader isContentLoaded={!progress} blockRenderUntilLoaded={true}>
              <div className="icon">
                <IconFileDownload color="white" style={{ margin: -4 }}/>
              </div>
            </ContentLoader>
          </div>

          <div className="long-label">
            <FormattedMessage id="shareCardURL.downloadPDF.button.long.label"/>
          </div>

          <div className="short-label">
            <FormattedMessage id="shareCardURL.downloadPDF.button.short.label"/>
          </div>
        </a>

        <div>
          <LargePreferredLanguageDropdownList htmlId="pageLanguage" color="gsMedDark" />
        </div>
      </div>
    );
  }

  downloadPdf = async (e) => {
    e.preventDefault();

    this.setState({ progress: true });

    const passCode = this.props.requiresPasscode ? publicPasscode.get() : null;

    try {
      const { blob, filename } = await ShareCardService.getPublicPdf(
        this.props.hash,
        passCode,
        this.props.languageCode,
      );

      this.downloadFile(blob, filename);
    } finally {
      this.setState({ progress: false });
    }
  }

  private downloadFile(blob, fileName) {
    const fileUrl: any = navigator.msSaveBlob
      ? navigator.msSaveBlob(blob, fileName)
      : window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);
    link.click();
  }
}

export default PublicShareCardControls;
