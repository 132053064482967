import React from 'react';

import { CardRowUI } from '../../../../bphComponents/bundle';
import COLORS from '../../../styles/Colors';


const OccurrenceEmptyProperty = (props) => {
  return (
    <CardRowUI.HealthEntryProperty
      {...props}
      onClick={props.onCreate}
      color={COLORS.gsDark}
      hasBottomBorder={true}
      value={props.emptyValue}
      subTitle={null}
    />
  );
};

export default OccurrenceEmptyProperty;
