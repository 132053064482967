import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Card, CardRowUI } from '../../../../bphComponents/bundle';

import { HealthEntryShortSummary } from '../../../components/HealthEntrySummary';

import { generalHealthEntry } from '../../../../models/HealthEntryCompose';
import COLORS from '../../../styles/Colors';


interface Props {
  healthEntries: any[];
  intl: any;
  onClick(id): void;
  isSelected(id): boolean;
  exitAnimationTime: number;
}

const RemainingPendingHealthEntries: React.FC<Props> = (props) => {
  const { healthEntries, intl, onClick, isSelected, exitAnimationTime } = props;
  const languageCode = intl.locale;

  return (
    <ul className="">
      <TransitionGroup>

      {
        healthEntries.map((he, i) => (
          <CSSTransition
            key={he.id}
            classNames="example"
            timeout={exitAnimationTime}
          >

          <li className="" style={{ borderBottom: (i < (healthEntries.length - 1)) ? `1px solid ${COLORS.gsMedDarkLT}` : null }}>
            <CardRowUI.SelectableRow
              onClick={() => { onClick(he.id); }}
              isSelected={isSelected(he.id)}
              selectedClassName="fill-color-profile-highlight"
            >
              <HealthEntryShortSummary
                color="red"
                healthEntry={generalHealthEntry(he)}
                languageCode={languageCode}
                showCategory={true}
                areIconsAttachedToRight={false}
              />
            </CardRowUI.SelectableRow>
          </li>
          </CSSTransition>
        ))
      }
      </TransitionGroup>
    </ul>
  );
};

const IntlRemainingPendingHealthEntries = injectIntl(RemainingPendingHealthEntries);

export const ReviewedPendingHealthEntries = (props) => {
  const { healthEntries, onClick , isSelected, exitAnimationTime, isDefaultExpanded } = props;
  if (healthEntries.length === 0) { return null; }

  const [isExpanded, setIsExpanded] = React.useState(isDefaultExpanded);

  return (
    <Card.CardShape isFlat={true} background={COLORS.gsLight}>
      <Card.CollapsibleSummaryWithControl
        className="font-color-gsMedDark"
        label={<FormattedMessage id="pendingData.reviewedDataList.label" />}
        hasBottomBorder={false}
        isExpanded={isExpanded}
        onClick={() => { setIsExpanded(!isExpanded); }}
        background={COLORS.gsLight}
        style={{ borderBottom: `1px solid ${COLORS.gsMedDarkLT}` }}
      >
        <IntlRemainingPendingHealthEntries
          healthEntries={healthEntries}
          onClick={onClick}
          isSelected={isSelected}
          exitAnimationTime={exitAnimationTime}
        />
      </Card.CollapsibleSummaryWithControl>
    </Card.CardShape>
  );
};
