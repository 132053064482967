import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import RouteNames from '../../../appAuthorized/RouteNames';
import { TrackEvent } from '../../../../utilities/UserMetrics';

import IconShare from '../../../components/icons/IconShare';
import Card from '../../../components/card/Card';
import { CollapsibleCard } from '../../../components/card/CollapsibleCard';
import { I18nImage } from '../../../components/I18nTranslation';

import { useActiveUser } from '../../../hooks/useUser';
import { useNavigate } from '../../../hooks/useNavigate';
import { useHealthEntriesForActiveProfile } from '../../../hooks/useHealthEntry';
import { useShareCardListForActiveProfile } from '../../../hooks/useShareCard';

const ShareCardQuicklinks: React.FC = () => {

  const [{ preferredLocale }] = useActiveUser();
  const [, navActions] = useNavigate();
  const [{ healthEntryList }] = useHealthEntriesForActiveProfile();
  const [, scActions] = useShareCardListForActiveProfile();

  const defaultShareCards = scActions.getDefaultShareCards();

  if (healthEntryList.length === 0) {
    return null;
  }

  return (
    <Card className="cmp-sharecard-quick-links bkgd-color-gsLight" hasMargins={false} hasDropshadow={false}>
      <CollapsibleCard
        title={(
          <div className="title font-caption-1 font-color-gsDark">
            <IconShare.Medium color="gsMedDark" />
            <FormattedMessage id="health.shareQuickLinks.heading.text" />
          </div>
        )}
        isCollapsible
        defaultCollapsed
      >
        <div className="cmp-sharecard-quick-links-content">
          <div className="sharecard-list">
            {
              defaultShareCards.map((sc) => {
                const shareCardColorModel = scActions.getShareCardColor(sc);
                const shareCard = scActions.instantiateShareCard(sc);
                if (!shareCard) { return null; }

                return (
                  <div
                    key={sc.id}
                    className="sharecard"
                    style={{
                      backgroundColor: shareCardColorModel.cssColor(1),
                    }}
                    onClick={() => {
                      const url = RouteNames.shareCardView(sc.id);
                      const shareCardTemplateUid = shareCard.definitionUid;
                      TrackEvent.shareCards.shareCardQuicklinkSelectedShareCard(shareCardTemplateUid);
                      navActions.goTo(url);
                    }}
                  >
                    <div className="sharecard-title font-body">
                      {shareCard.title.get(preferredLocale.language_code)}
                    </div>
                    {!!shareCard.lightIcon &&
                      <div className={`sharecard-icon ${shareCard.iconClass}`}>
                        <I18nImage data={shareCard.lightIcon} />
                      </div>
                    }
                  </div>
                );
              })
            }
          </div>
          <div
            className="show-more util-application-card-padding font-caption-1 font-color-gsMiddle"
            onClick={() => {
              TrackEvent.shareCards.shareCardQuicklinkSelectedMore();
              navActions.goTo(RouteNames.share());
            }}
          >
            <FormattedMessage id="health.shareQuickLinks.more.button.label" />
          </div>
        </div>
      </CollapsibleCard>
    </Card>
  );
};

export default withRouter(ShareCardQuicklinks);
