import React from 'react';
import { FormattedMessage } from 'react-intl';

import ShareCardSummaryGroup from './ShareCardSummaryGroup';
import { Markdown } from '../../../components/Markdown';
import ShareCardModel from '../../../../models/ShareCardModel';
import { ShareCardColorModel } from '../../../appAuthorized/dataTransformComponents/ColorShareCard';
import { GroupOrganizationName } from '../../../features/GroupTitle/GroupOrganizationName';
import GroupContentModel, { sortGroupContent } from '../../../../models/GroupContentModel';
import { LocalText } from '../../../../utilities/localizedUtil';
import Spacer from '../../../components/Spacer';
import { useGroupShareCardContentForActiveProfile } from '../../../hooks/useGroup';
import LocalizeI18nText from '../../../components/LocalizeI18nText';

interface Props {
  shareCard: ShareCardModel;
  languageCode: string;
  shareCardColorModel: ShareCardColorModel;
}

const ShareCardSummaryGroupContents = (props: Props) => {
  const [{ groupContentsLookup }] = useGroupShareCardContentForActiveProfile();
  const includedGroupContentIds = props.shareCard.data.included_group_content;

  return (
    <div style={{ backgroundColor: 'white' }}>
      {
        includedGroupContentIds
        .filter(id => groupContentsLookup[id])
        .map(id => new GroupContentModel(groupContentsLookup[id]))
        .sort(sortGroupContent(props.languageCode))
        .map(content => (
          <ShareCardSummaryGroup key={content.data.id}>
            <div style={{ marginBottom: '16px' }} className="font-body">
              <LocalizeI18nText text={content.data.title} languageCode={props.languageCode} />
            </div>
            <LocalizeI18nText text={content.data.body} languageCode={props.languageCode}>
              {
                ({ text }) => (
                  <Markdown id={`groupcontent-${content.data.id}`} text={text} color={props.shareCardColorModel.darkAccentColor()} />
                )
              }
            </LocalizeI18nText>
            <Spacer size="9" />
            <div className="font-subhead font-color-gsMedDark">
              <GroupOrganizationName groupId={content.groupId}>
                {
                  (groupName) => (
                    <FormattedMessage id="shareCard.orgName.providedBy.label" values={{ string1: <LocalText text={groupName} /> }} />
                  )
                }
              </GroupOrganizationName>
            </div>
          </ShareCardSummaryGroup>
        ))
      }
    </div>
  );
};

export default ShareCardSummaryGroupContents;
