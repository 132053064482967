import React from 'react';
import { FormattedMessage } from 'react-intl';

import { fixedMediumDate } from '../../../localization/LocalizedDate';
import { SelectableCardRowWithProfileTheme } from '../../../components/SelectableRow';

const OwnProfileInvitation = (props) => {
  const { notification, isSelected, onClick } = props;
  const shareProfile = notification.sharedProfile;
  const requestorProfile = notification.requestorProfile;
  const additionalOwners = notification.additionalOwners;
  const healthEntryCount = notification.healthEntryCount;

  return (
    <SelectableCardRowWithProfileTheme
      onClick={onClick}
      isSelected={isSelected}
    >
      <div className="font-body font-color-gsDarkest">{shareProfile.fullName()}</div>
      <div className="font-footnote font-color-gsMedDark">
        {healthEntryCount} <FormattedMessage id="list.healthEntry.name" values={{ digit1: healthEntryCount }} />
        {
          healthEntryCount > 0 && (
            <FormattedMessage id="acceptOwnProfile.inviteList.heUpdatedDate.label" values={{ string1: fixedMediumDate(notification.healthEntryLastUpdated, 'P1D') }} />
          )
        }
      </div>
      <div className="font-footnote font-color-gsMedDark">
        <FormattedMessage id="acceptOwnProfile.inviteList.invitee.label" /> {requestorProfile.formalName()}
      </div>

      {
        additionalOwners.length > 0 && (
          <div className="font-footnote font-color-gsMedDark">
            <FormattedMessage id="acceptOwnProfile.inviteList.sharedWith.label" /> {additionalOwners.join(', ')}
          </div>
        )
      }

    </SelectableCardRowWithProfileTheme>
  );
};

export default OwnProfileInvitation;
