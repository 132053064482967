import React from 'react';

import {
  default as ShareCardModel,
  ShareCard,
} from '../../../../models/ShareCardModel';
import { ShareCardCategory } from './ShareCardCategory';
import { ShareCardColorModel } from '../../../appAuthorized/dataTransformComponents/ColorShareCard';
import { Icons } from '../../../../bphComponents/bundle';
import { useTrackableListForActiveProfile } from '../../../hooks/useTrackable';
import COLORS from '../../../styles/Colors';
import { ShareCardItemRow } from './ShareCardItemRow';
import ShareCardIncludedValueToggleSwitch from '../../../components/ShareCardIncludedValueToggleSwitch';
import { TrackableShareCardSummary } from '../../components/attributes/OccurrenceSummary';
import { ShareCardDefinitionBackpackCategory } from '../../../../models/ShareCardTemplateModel';

interface Props {
  shareCard: ShareCardModel;
  shareCardColorModel: ShareCardColorModel;
  languageCode: string;
  intl: any;
  expandedCategory: string;
  toggleCategory(el, categoryName): void;
  updateShareCard(data: ShareCard, cb): void;
}
const IDENTIFIER = 'trackables';

const ShareCardEditTrackables: React.FC<Props> = (props) => {
  const [{ trackableTermLookup }, { getSortedTrackables }] = useTrackableListForActiveProfile();
  const { shareCardColorModel, expandedCategory, toggleCategory, updateShareCard, languageCode } = props;
  const shareCard = props.shareCard.data;
  const shareCardDefinition = props.shareCard.definition;
  const sortedTrackables = getSortedTrackables(languageCode);

  return (
    <ShareCardCategory
      showTotals={true}
      icon={() => <Icons.IconTracking.Medium color={COLORS.gsMedDark} />}
      group={null}
      shareCardItemProps={{
        isExcluded: false,
        isRequired: false,
        countIncluded: sortedTrackables.filter(t => ShareCardModel.isIncludedTrackable(shareCard, t.id)).length,
        countTotal: sortedTrackables.length,
      }}
      shareCardColorModel={shareCardColorModel}
      titlePhraseData={{ id: 'list.healthEntry.trackables.name', values: { digit1: 2 } }}
      isExpanded={expandedCategory === IDENTIFIER}
      toggleExpand={(e) => { toggleCategory(e.target, IDENTIFIER); }}
      toggleAll={() => {
        const updatedShareCard = ShareCardModel.toggleTrackablesIncludedAll(shareCard, shareCardDefinition, sortedTrackables);
        updateShareCard(updatedShareCard, () => {});
      }}
    >
      {
        sortedTrackables.map((trackable) => {
          const trackableId = trackable.id;
          const isExcluded = false;
          const isRequired = false;
          const isIncluded = ShareCardModel.isIncludedTrackable(shareCard, trackableId);

          const includedTypeData = ShareCardModel.getIsTrackableIncluded(shareCard, trackableId);
          const defaultInclusion = ShareCardModel.getDefaultInclusionValue(ShareCardDefinitionBackpackCategory.trackablesOccurrence, shareCardDefinition);
          const includedValue = includedTypeData ? includedTypeData.included_values : null;

          return (
            <ShareCardItemRow
              key={trackableId}
              isExcluded={isExcluded}
              isRequired={isRequired}
              isSelected={isIncluded}
              onToggle={() => {
                updateShareCard(ShareCardModel.toggleTrackableIncluded(shareCard, shareCardDefinition, trackableId), () => {});
              }}
              cssColor={shareCardColorModel.cssColor()}
              isSelectedContent={(
                includedValue
                ? <ShareCardIncludedValueToggleSwitch
                    uniqueID={`${trackableId}`}
                    isDisabled={false}
                    onToggle={() => {
                      updateShareCard(ShareCardModel.toggleTrackableIncludedType(shareCard, trackableId), () => {});
                    }}
                    value={includedValue}
                  />
                : null
              )}
            >
              <TrackableShareCardSummary
                trackable={trackable}
                showComments={true}
                showFlagged={true}
                terms={trackableTermLookup}
                includedTypeValue={includedValue || defaultInclusion}
                languageCode={languageCode}
              />
            </ShareCardItemRow>
          );
        })
      }
    </ShareCardCategory>
  );
};

export default ShareCardEditTrackables;
