import { useQuery } from 'react-query';

import { useDispatch } from 'react-redux';

import SortingUtil from '../../utilities/SortingUtil';
import FileService from '../../apiServices/FileService';
import FileModel from '../../models/FileModel';
import { useUIState } from './useUIState';

const STALE_TIME = 1000;

const INITIAL_FILE_LIST_STATE = {
  list: [],
  lookup: {},
  isLoaded: false,
};
const INITIAL_FILE_STATE = {
  data: {},
  isLoaded: false,
};
function formatFiles(files = []) {
  return SortingUtil.sortFiles(files);
}

function createCollectionAndLookup(apiError, responseCollection) {
  const lookup = {};
  let collection = [];

  if (!apiError) {
    collection = responseCollection;
    for (let i = 0; i < responseCollection.length; i++) {
      lookup[collection[i].id] = collection[i];
    }
  }

  return {
    collection,
    lookup,
  };
}

function fetchFilesForProfile(dispatch) {
  return function fetchFilesForProfile_API(queryKey, profileId) {
    return new Promise((res, rej) => {
      FileService.getAll(profileId, (err, data) => {
        if (err) { return rej(err); }
        dispatch({
          type: 'RECEIVE_ALL_FILES',
          payload: {
            profileId,
            isFetching: false,
            lastUpdated: new Date().getTime(),
            ...createCollectionAndLookup(null, data),
          },
        });

        const fileModels = data.map(f => new FileModel(f));
        res({
          list: formatFiles(fileModels).map(f => f.data),
          lookup: data.reduce((acc, f) => ({ ...acc, [f.id]: f }), {}),
          isLoaded: true,
        });
      });
    });
  };
}

function fetchFileForProfile(dispatch) {
  return function fetchFileForProfile_API(queryKey, profileId, fileId) {
    return new Promise((res, rej) => {
      FileService.get(profileId, fileId, (err, fileData) => {
        if (err) { return rej(err); }

        dispatch({
          type: 'RECEIVE_FILE',
          payload: {
            profileId,
            id: fileData.id,
            fileData,
          },
        });

        res({
          data: fileData,
          isLoaded: true,
        });
      });
    });
  };
}

export function useFilesForActiveProfile() {
  const dispatch = useDispatch();
  const [{ activeProfileId }] = useUIState();

  const fileQuery: any = useQuery(['files', activeProfileId], fetchFilesForProfile(dispatch), { staleTime: STALE_TIME });
  const responseData = fileQuery.data || INITIAL_FILE_LIST_STATE;
  const isLoaded = responseData.isLoaded;
  const fileList = responseData.list;
  const fileLookup = responseData.lookup;

  return [
    {
      fileList,
      fileLookup,
      isLoaded,
    },
    {
      instantiateList: () => {
        return fileList.map(f => new FileModel(f));
      },
      instantiateFile: (fData) => {
        return new FileModel(fData);
      },
    },
  ];
}

export function useFileForActiveProfile(fileId?) {
  const dispatch = useDispatch();
  const [{ activeProfileId }] = useUIState();

  const fileQuery: any = useQuery(['files', activeProfileId, fileId], fetchFileForProfile(dispatch), { enabled: Boolean(fileId), staleTime: STALE_TIME });
  const responseData = fileQuery.data || INITIAL_FILE_STATE;
  const fileData = responseData.data;
  const isLoaded = responseData.isLoaded;

  return [
    {
      file: fileData,
      isLoaded,
    },
    {
      instantiateFile: (newFileData?) => {
        const d = newFileData || fileData;
        return new FileModel(d);
      },
    },
  ];
}
