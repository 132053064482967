import React from 'react';
import classnames from 'classnames';

import { ShareCardCategory } from './ShareCardCategory';
import { ShareCardItemRow } from './ShareCardItemRow';
import IconGroups from '../../../components/icons/IconGroups';
import { GroupOrganizationName } from '../../../features/GroupTitle/GroupOrganizationName';
import GroupContentModel, { sortGroupContent } from '../../../../models/GroupContentModel';
import ShareCardModel, { ShareCard } from '../../../../models/ShareCardModel';
import { useShareCardForActiveProfile, useShareCardListForActiveProfile } from '../../../hooks/useShareCard';
import { useActiveUser } from '../../../hooks/useUser';
import { useGroupShareCardContentForActiveProfile } from '../../../hooks/useGroup';

interface Props {
  shareCardData: ShareCard;
  onUpdateShareCard(d): void;
  toggleExpand(e): void;
  shareCardColorModel: any;
  isExpanded: boolean;
}
const ShareCardGroupContents: React.FC<Props> = (props) => {
  const { shareCardData, onUpdateShareCard, shareCardColorModel, isExpanded, toggleExpand } = props;

  const [, scActions] = useShareCardForActiveProfile();
  const [{ preferredLanguageCode }] = useActiveUser();
  const [{ groupContentsList }] = useGroupShareCardContentForActiveProfile();
  const [{ shareCardDefinitionLookup }] = useShareCardListForActiveProfile();

  const shareCard = scActions.instantiate(shareCardData);
  const group = shareCard.createGroupContentsGroup();
  const cssColor = shareCardColorModel.cssColor( );

  return (
    <ShareCardCategory
      group={group}
      showTotals={true}
      shareCardColorModel={shareCardColorModel}
      icon={() => (
        <div style={{ position: 'relative', left: '-5px' }}>
          <IconGroups color="dark-neutral" size="36" />
        </div>
      )}
      titlePhraseData={{ id: 'shareCard.groupShareCardContent.heading', values: {} }}
      isExpanded={isExpanded}
      toggleExpand={toggleExpand}
      toggleAll={() => {
        group.toggleAll();
        onUpdateShareCard(shareCard.data);
      }}
    >
      {groupContentsList
        .map(g => new GroupContentModel(g))
        .sort(sortGroupContent(preferredLanguageCode))
        .map((content) => {
          const contentId = content.data.id;
          const def = shareCardDefinitionLookup[shareCardData.definition_id];
          const isSelected = ShareCardModel.isIncludedGroupContent(shareCardData, contentId)
          const titleColor = isSelected ? 'font-color-gsDarkest' : 'font-color-gsDark';
          const bodyColor = isSelected ? 'font-color-gsDark' : 'font-color-gsMedDark';
          const isExcluded = !def.group_content_allowed;
          const isRequired = false;

          return (
            <ShareCardItemRow
              key={contentId}
              cssColor={cssColor}
              isExcluded={isExcluded}
              isRequired={isRequired}
              isSelected={isSelected}
              onToggle={() => {
                const data = ShareCardModel.setGroupContent(shareCard.data, contentId, !isSelected);
                onUpdateShareCard(data);
              }}
            >
              <div>
                <div style={{ marginBottom: '8px' }} className={classnames('font-body', titleColor)}>
                  {content.title.get(preferredLanguageCode)}
                </div>
                <div style={{ marginBottom: '8px' }} className={classnames('font-footnote', bodyColor)}>
                  {content.description.get(preferredLanguageCode)}
                </div>
                <div className={classnames('font-footnote', bodyColor)}>
                  <GroupOrganizationName groupId={content.groupId}/>
                </div>
              </div>
            </ShareCardItemRow>
          );
        })}
    </ShareCardCategory>
  );
};

export default ShareCardGroupContents;
