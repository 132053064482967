import * as _ from 'lodash';

import NotificationService from '../../../apiServices/NotificationService';
import ProfileService from '../../../apiServices/ProfileService';
import ProfileModel from '../../../models/ProfileModel';
import SharedOwnerNotificationModel from '../../../models/SharedOwnerNotificationModel';

export const types = {
  REQUEST_ALL: 'app/owners/REQUEST_ALL',
  RECEIVE_ALL: 'app/owners/RECEIVE_ALL',
  REMOVE: 'app/owners/REMOVE',
};

const initialState = {
  '-1': { active: [], notified: [] },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.REQUEST_ALL:
      return state;
    case types.RECEIVE_ALL:
      return _.assign({}, state, action.payload);
    default:
      return state;
  }
}

function receivedOwners(profileId, profiles) {
  return {
    type: types.RECEIVE_ALL,
    payload: {
      [profileId]: profiles,
    },
  };
}

function requestOwnersForProfile(profileId) {
  return (dispatch) => {
    ProfileService.getOwnersForProfile(profileId, (err, profiles) => {
      dispatch(receivedOwners(profileId, profiles));
    });
  };
}

function removeOwner(profileId, ownerProfileId, callback) {
  return () => {
    ProfileService.removeOwnerFromProfile(profileId, ownerProfileId, () => {
      callback();
    });
  };
}

function inviteOwner(email, profileData, isPrimary) {
  const shared_owner = {
    email,
    shared_profile: profileData,
  };
  if (isPrimary) {
    shared_owner.primary = 1;
  }

  return (dispatch) => {
    return new Promise((res, rej) => {
      NotificationService.create({
        type: 'shared_owner',
        shared_owner,
      }, (err) => {
        if (err) {
          rej(err);
          return;
        }
        ProfileService.getOwnersForProfile(profileData.id, (err1, profiles) => {
          dispatch(receivedOwners(profileData.id, profiles));
        });
        res();
      });
    });
  };
}

function cancelOwnerInvite(profileId, notificationId) {
  return (dispatch) => {
    NotificationService.delNotification(notificationId, () => {
      ProfileService.getOwnersForProfile(profileId, (err, profiles) => {
        dispatch(receivedOwners(profileId, profiles));
      });
    });
  };
}

function resendOwnerInvite(notificationId, callback) {
  return () => {
    NotificationService.sharedOwnerResend(notificationId, callback);
  };
}

export const actions = {
  requestOwnersForProfile,
  removeOwner,
  inviteOwner,
  cancelOwnerInvite,
  resendOwnerInvite,
};

export const selectors = {
  getActiveOwnersForProfile: (profileId, state) => {
    if (state.owners[profileId]) {
      return state.owners[profileId].active.map(p => new ProfileModel(p));
    } else {
      return state.owners['-1'].active.map(p => new ProfileModel(p));
    }
  },
  getPendingOwnerNotificationsForProfile: (profileId, state) => {
    if (state.owners[profileId]) {
      return state.owners[profileId].notified.map(p => new SharedOwnerNotificationModel(p));
    } else {
      return state.owners['-1'].notified.map(p => new SharedOwnerNotificationModel(p));
    }
  },
  getActiveOwnerForProfile: (profileId, ownerId, state) => {
    const owners = _.get(state, `owners.${profileId}.active`, []);
    const ownerProfile = owners.find((o) => o.id === ownerId);
    return new ProfileModel(ownerProfile);
  },
};
