
import React, { CSSProperties } from 'react';
import classnames from 'classnames';

import './ContentPanel.scss';


interface Props {
  header?: JSX.Element;
  hasPadding: boolean;
  style?: CSSProperties;
}

class ContentPanel extends React.Component<Props, {}> {

  render() {
    return (
      <div className="cmp-contentpanel util-inset-content-margin" style={this.props.style}>
        {
          this.props.header &&
          <div className={classnames('cmp-contentpanel-header', { 'cmp-contentpanel-padding': this.props.hasPadding })}>
            {this.props.header}
          </div>
        }
        <div className={classnames('cmp-contentpanel-content', { 'cmp-contentpanel-padding': this.props.hasPadding })}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ContentPanel;
