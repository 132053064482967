import { AttributeState } from "./ShareCardTemplateModel";
import AppUtils from "../utilities/AppUtils";

export class ShareCardDefinitionModel {

  static isFilesRequired(scDefData): boolean {
    const files = scDefData && scDefData.backpack ? scDefData.backpack.files : '';
    return files === AttributeState.required;
  }

  static isFilesExcluded(scDefData): boolean {
    const files = scDefData && scDefData.backpack ? scDefData.backpack.files : '';
    return files === AttributeState.excluded;
  }

  static isContactsExcluded(scDefData): boolean {
    return scDefData.backpack.contacts === AttributeState.excluded;
  }

  static isNotesExcluded(scDefData): boolean {
    return scDefData.backpack.notes === AttributeState.excluded;
  }

  static isProvidersExcluded(scDefData): boolean {
    return scDefData.backpack.providers === AttributeState.excluded;
  }

  static baseColor(scDefData): string {
    if (!scDefData.color) { return ''; }
    switch (scDefData.color.type) {
      case 'user-defined':
      case 'group-defined':
      case 'group-color':
        return AppUtils.cssColor(scDefData.color.rgba);
      default:
        return '';
    }
  }
}
