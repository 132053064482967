
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentFrame from '../../applicationFrame/ContentFrame';
import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import ContentWrapper from '../../components/ContentWrapper';
import RouteNames from '../../appAuthorized/RouteNames';

import { Card, Inputs } from '../../../bphComponents/bundle';
import { useLists } from '../../hooks/useLists';
import { useNavigate } from '../../hooks/useNavigate';
import { useUIEditContact } from '../../hooks/useUIEdit';

interface Props {
  match: any;
}

const ViewContactCustomSpecialty: React.FC<Props> = (props) => {
  const contactId = Number(props.match.params.contactId);

  const [{ editingContact, isLoaded }, editContactActions] = useUIEditContact(contactId);
  const [currentValue, setCurrentValue] = React.useState(() => {
    return editingContact ? editingContact.specialty_custom_text : '';
  });
  const [{ contactSpecialtyCustomValue }] = useLists();
  const [, navActions] = useNavigate();

  React.useEffect(() => {
    if (isLoaded && editingContact.specialty_custom_text) {
      setCurrentValue(editingContact.specialty_custom_text);
    }
  }, [isLoaded]);

  return isLoaded ? (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          cancel={() => { navActions.goBack(RouteNames.contactEdit(contactId)); }}
          submit={() => {
            editContactActions.updateSpecialty(contactSpecialtyCustomValue.id, currentValue, false)
              .then(() => {
                navActions.goBack(RouteNames.contactEdit(contactId));
              });
          }}
          color="white"
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText id="contactSpecialty.custom.title" />
            <ContentWrapper>
              <Card.CardShape>
                <Card.CardContent>
                  <Inputs.TextInput
                    label={<FormattedMessage id="contactSpecialty.custom.field.label" />}
                    value={currentValue || ''}
                    autoCapitalize="none"
                    preventFloating={true}
                    isClearable={true}
                    autoFocus={!currentValue}
                    onChange={setCurrentValue}
                  />
                </Card.CardContent>
              </Card.CardShape>
            </ContentWrapper>
          </ScrollAwareScroller>

        </ContentFrame>
      </ScrollAwareContext>
    </div>
  ) : null;
};

// function mapStateToProps(state, routerProps) {
//   const contactId = Number(routerProps.match.params.contactId);
//   return {
//     contactId,
//     contactData: contactSelectors.getEditingContact(state),
//     contactSpecialtyCustomValue: listSelectors.getContactSpecialtyCustom(state),
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     updateContact: (data) => {
//       const contactData = ContactModel.defaultContact(data, null);
//       dispatch(contactActions.updateEditingContact(contactData));
//     },
//     onSubmit: async (data, customValueId, value) => {
//       const contactData = ContactModel.defaultContact(data, null);
//       if (!value) {
//         contactData.contact_specialty_id = null;
//         contactData.specialty_custom_text = null;
//       } else {
//         contactData.contact_specialty_id = customValueId;
//         contactData.specialty_custom_text = value;
//       }
//       dispatch(contactActions.updateEditingContact(contactData));
//       navActions.goBack(RouteNames.contactEdit(`${contactData.id}`));
//     },
//     loadData: (profileId, contactId) => {
//       return ContactService.getContact(profileId, contactId);
//     },
//   };
// }

export default ViewContactCustomSpecialty;
