import React, { FC } from 'react';

import './GroupImageGallery.scss';

import GroupMetadata, { GroupMetadataType } from '../../../../../models/GroupMetadata';
import { I18nImageCollection } from '../../../../components/I18nTranslation';

interface GroupImageGalleryProps {
  groupData: GroupMetadataType;
}
const GroupImageGallery: FC<GroupImageGalleryProps> = (props) => {
  const group = new GroupMetadata(props.groupData);
  const images = group.imagesGallery.getValue();
  const className = `cmp-groupimagegallery __gallerysize-${images.length}`;
  return (
    <div className={className}>
      <I18nImageCollection data={group.imagesGallery.get()} />
    </div>
  );
};

export default GroupImageGallery;
