
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './PolicyDocumentTemplate.scss';

import { BackpackThemeMarkdown } from '../../../components/Markdown';
import PolicyConnected from '../../../components/PolicyText.connected';
import { fixedLongDate } from '../../../localization/LocalizedDate';

interface Props {
  documentName: string;
  languageCode: string;
}

class PolicyDocumentTemplate extends React.Component<Props, {}> {
  render() {
    const { documentName, languageCode } = this.props;

    return (
      <PolicyConnected name={documentName} languageCode={languageCode}>
        {
          ({ title, table_of_contents, summary, text, version, created_at }) => !title ? null : (
            <div className="cmp-policytemplate util-inset-listitem-frame-padding">
              <h1 className="font-display-1 font-color-gsDarkest">{title}</h1>

              <section className="version font-subhead">
                <FormattedMessage id="legal.version.message.text" values={{ string1: version }}/>
                <FormattedMessage id="legal.updatedDate.message.text" values={{ string1: fixedLongDate(created_at, 'P1D', languageCode) }} />
              </section>
              {
                table_of_contents && (
                  <section>
                    <BackpackThemeMarkdown text={table_of_contents} />
                  </section>
                )
              }

              {
                summary && (
                  <section>
                    <h2 className="font-display-2 font-color-gsDarkest"><FormattedMessage id="legal.summary.heading.text" /></h2>
                    <BackpackThemeMarkdown text={summary} />
                  </section>
                )
              }

              {
                text && (
                  <section>
                    <h2 className="font-display-2 font-color-gsDarkest"><FormattedMessage id="legal.detail.heading.text" /></h2>
                    <BackpackThemeMarkdown text={text} />
                  </section>
                )
              }
            </div>
          )
        }
      </PolicyConnected>
    );
  }
}

export default PolicyDocumentTemplate;
