import React from 'react';

interface Props {
  children: any;
}

export default function withExtendedProps (extendedProps: any) {
  return function (Component) {
    return function ExtendedProps (props: Props) {
      return <Component {...props} {...extendedProps}>{props.children}</Component>
    }
  }
}
