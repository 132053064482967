import React from 'react';
import classnames from 'classnames';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  color?: string;
  className?: string;
  style?: any;
}

const DEFAULT_COLOR = 'gsDarkest';

const getClassName = (props: Props) => {
  const { color = DEFAULT_COLOR, className } = props;
  return className || classnames(colorClassNames.fill(color));
};

const Small = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px" style={props.style}>
    <g id="icon-arrow-right" className={getClassName(props)}>
      <path d="M9.45,11.84a.76.76,0,0,1-.57-.26.74.74,0,0,1-.18-.49A.72.72,0,0,1,9,10.53L11.86,8,9,5.48a.75.75,0,0,1-.25-.57.76.76,0,0,1,.18-.49.75.75,0,0,1,1.06-.08l3.55,3.09a.75.75,0,0,1,0,1.14L9.94,11.66A.76.76,0,0,1,9.45,11.84Z"/>
      <path d="M13,8.75H3a.75.75,0,0,1,0-1.5H13a.75.75,0,0,1,0,1.5Z"/>
    </g>
  </svg>
);

const Medium = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <g id="icon-arrow-right" className={getClassName(props)}>
      <path d="M14.31,18a1,1,0,0,1-.75-.35,1,1,0,0,1-.25-.65,1,1,0,0,1,.35-.76L18.48,12,13.66,7.81a1,1,0,0,1-.35-.75,1,1,0,0,1,.25-.66A1,1,0,0,1,15,6.3l5.69,4.94A1.05,1.05,0,0,1,21,12a1,1,0,0,1-.34.75L15,17.7A1,1,0,0,1,14.31,18Z"/>
      <path d="M20,13H4a1,1,0,0,1,0-2H20a1,1,0,0,1,0,2Z"/>
    </g>
  </svg>
);

const Large = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36px" height="36px">
    <g id="icon-arrow-right" className={getClassName(props)}>
      <path d="M6,19.25H26.66l-6,5.23a1.21,1.21,0,0,0-.43.94,1.28,1.28,0,0,0,.3.82,1.25,1.25,0,0,0,1.77.12l8.53-7.42,0,0,.05,0a1,1,0,0,0,.23-.35s0,0,0,0a1.22,1.22,0,0,0,0-.92s0,0,0,0a1,1,0,0,0-.23-.35l-.05,0,0,0L22.29,9.64a1.24,1.24,0,0,0-.82-.31,1.24,1.24,0,0,0-1.25,1.25,1.21,1.21,0,0,0,.43.94l6,5.23H6a1.25,1.25,0,0,0,0,2.5Z"/>
    </g>
  </svg>
);

const XLarge = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" width="56px" height="56px">
    <g id="icon-arrow-right" className={getClassName(props)}>
      <path d="M47,29l.08-.09a1.62,1.62,0,0,0,.1-.18.35.35,0,0,0,.05-.08l.08-.23a.25.25,0,0,1,0-.07,1.62,1.62,0,0,0,0-.62l0-.07a2,2,0,0,0-.07-.23.35.35,0,0,0-.05-.08,1.62,1.62,0,0,0-.1-.18L47,27l-.12-.14,0,0-12.7-11A1.49,1.49,0,0,0,31.64,17a1.48,1.48,0,0,0,.51,1.13l9.68,8.42H10.1a1.5,1.5,0,0,0,0,3H41.83l-9.68,8.41a1.5,1.5,0,0,0,1,2.64,1.46,1.46,0,0,0,1-.37l12.7-11,0,0Z"/>
    </g>
  </svg>
);

const IconArrowRight = {
  Small,
  Medium,
  Large,
  XLarge,
};

export default IconArrowRight;
