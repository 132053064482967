import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Inputs } from '../../bphComponents/bundle';
import { selectors as listSelectors } from '../redux/ducks/lists/lists';

function mapStateToProps(state, props) {
  const { intl } = props;

  return {
    countryOptions: listSelectors.getCountryCollection(state)
      .map((c) => ({ text: intl.formatMessage({ id: c.key }), value: c.iso2 }))
      .sort((a, b) => (a.text < b.text ? -1 : 1)),
  };
}

export default injectIntl(connect(mapStateToProps)(Inputs.PhoneInput));
