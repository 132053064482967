import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HealthEntryGeneAttributeInterface, HealthEntryGeneAttributeValueInterface } from '../../constants/HealthEntryGeneAttributes';
import MedliTerm from './MedliTerm';

interface Props {
  attributeScheme: HealthEntryGeneAttributeInterface;
  geneVariantResult: any;
  className?: string;
  style?: object;
  languageCode?: string;
}

class GeneAttributeResultText extends Component<Props> {
  renderMedliTerm(valueScheme: HealthEntryGeneAttributeValueInterface) {
    const { geneVariantResult, languageCode } = this.props;
    const { geneVariantResultKey, getFormattedValue } = valueScheme;
    const isAttributeDefined = geneVariantResult.isAdditionalAttributeDefined(geneVariantResultKey, true);

    if (!isAttributeDefined) return null;

    const geneVariantResultData = geneVariantResult.get();
    const renderedValue = (
      <MedliTerm
        medliTerm={geneVariantResultData[geneVariantResultKey]}
        languageCode={languageCode}
      />
    );

    return (
      <span key={valueScheme.geneVariantResultKey}>
        {getFormattedValue ? getFormattedValue(renderedValue) : renderedValue}
      </span>
    );
  }

  renderSimpleValue(valueScheme: HealthEntryGeneAttributeValueInterface) {
    const { geneVariantResult } = this.props;
    const { geneVariantResultKey } = valueScheme;
    const isAttributeDefined = geneVariantResult.isAdditionalAttributeDefined(geneVariantResultKey, false);

    if (!isAttributeDefined) return null;

    const geneVariantResultData = geneVariantResult.get();

    return <span key={valueScheme.geneVariantResultKey}>{geneVariantResultData[geneVariantResultKey]}</span>;
  }

  renderValue(valueScheme: HealthEntryGeneAttributeValueInterface, i: number) {
    const { isMedliTerm } = valueScheme;
    const withIndent = i > 0;

    return (
      <span key={i}>
        {withIndent && ' '}
        {isMedliTerm ? this.renderMedliTerm(valueScheme) : this.renderSimpleValue(valueScheme)}
      </span>
    );
  }

  renderValues() {
    const { attributeScheme } = this.props;
    const { values, formatLabelId } = attributeScheme;
    const renderedValues = values
      .map((valueScheme: HealthEntryGeneAttributeValueInterface, i: number) => this.renderValue(valueScheme, i));

    return formatLabelId ? <FormattedMessage id={formatLabelId} values={{ string1: <span>{renderedValues}</span> }} /> : renderedValues;
  }

  render() {
    return (
      <span className={this.props.className} style={this.props.style}>
        {this.renderValues()}
      </span>
    );
  }
}

export default GeneAttributeResultText;
