import React from 'react';
import classnames from 'classnames';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  color?: string;
  className?: string;
}

const DEFAULT_COLOR = 'gsMedDark';

const getClassName = (props: Props) => {
  const { color = DEFAULT_COLOR } = props;
  return classnames(colorClassNames.fill(color), props.className);
};

const Small = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px">
    <g id="icon-healthinfo" className={getClassName(props)}>
      <path d="M13.57,2.79H10.09L9,1.68A2.5,2.5,0,0,0,7.23.92H4.49a2.5,2.5,0,0,0-2.42,1.9,2.06,2.06,0,0,0-1.69,2V12a2.06,2.06,0,0,0,2.05,2.06H13.57a2.06,2.06,0,0,0,2-2.06V4.85A2.05,2.05,0,0,0,13.57,2.79ZM14.38,12a.81.81,0,0,1-.81.81H2.43A.81.81,0,0,1,1.62,12V4.85A.81.81,0,0,1,2.43,4h.82V3.42A1.25,1.25,0,0,1,4.49,2.17H7.23a1.23,1.23,0,0,1,.89.38L9.56,4h4a.81.81,0,0,1,.81.81Z"/>
      <path d="M10,5.91a1.77,1.77,0,0,0-1.42-.19A1.64,1.64,0,0,0,8,6a1.64,1.64,0,0,0-.57-.26A1.77,1.77,0,0,0,6,5.91a1.9,1.9,0,0,0-.87,1.22A2,2,0,0,0,5.61,9a10.58,10.58,0,0,0,1.45,1.33l.33.24a1,1,0,0,0,.61.2,1.1,1.1,0,0,0,.61-.19l.33-.25A10.58,10.58,0,0,0,10.39,9a2,2,0,0,0,.47-1.86A1.9,1.9,0,0,0,10,5.91ZM9.45,8.17A9.23,9.23,0,0,1,8.17,9.34L8,9.47l-.17-.13A9.23,9.23,0,0,1,6.55,8.17a.79.79,0,0,1-.19-.76A.65.65,0,0,1,6.66,7a.52.52,0,0,1,.28-.08l.16,0a.66.66,0,0,1,.38.3L8,8l.52-.78a.66.66,0,0,1,.38-.3A.55.55,0,0,1,9.34,7a.65.65,0,0,1,.3.43A.79.79,0,0,1,9.45,8.17Z"/>
    </g>
  </svg>
);

const Medium = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <g id="icon-healthinfo" className={getClassName(props)}>
      <path d="M20.24,4.2H15L13.39,2.51a3.54,3.54,0,0,0-2.53-1.07h-4A3.52,3.52,0,0,0,3.37,4.23,2.87,2.87,0,0,0,.89,7.08V17.69a2.88,2.88,0,0,0,2.87,2.87H20.24a2.88,2.88,0,0,0,2.87-2.87V7.08A2.88,2.88,0,0,0,20.24,4.2Zm1.37,13.49a1.37,1.37,0,0,1-1.37,1.37H3.76a1.37,1.37,0,0,1-1.37-1.37V7.08A1.37,1.37,0,0,1,3.76,5.7h1V5a2,2,0,0,1,2-2h4.05a2,2,0,0,1,1.45.61L14.39,5.7h5.85a1.37,1.37,0,0,1,1.37,1.38Z"/>
      <path d="M14.85,8.79a2.53,2.53,0,0,0-2-.26A2.65,2.65,0,0,0,12,9a2.56,2.56,0,0,0-.89-.44,2.53,2.53,0,0,0-2,.26,2.66,2.66,0,0,0-1.21,1.7,2.85,2.85,0,0,0,.65,2.6A16,16,0,0,0,10.71,15l.48.35a1.39,1.39,0,0,0,1.62,0l.48-.35a16,16,0,0,0,2.12-1.95,2.85,2.85,0,0,0,.65-2.6A2.66,2.66,0,0,0,14.85,8.79Zm-.57,3.32a13.81,13.81,0,0,1-1.93,1.75c-.1.08-.22.18-.35.27l-.36-.27a13.74,13.74,0,0,1-1.92-1.75,1.33,1.33,0,0,1-.32-1.29,1.15,1.15,0,0,1,.52-.74,1.07,1.07,0,0,1,.52-.14.84.84,0,0,1,.27,0,1.13,1.13,0,0,1,.67.51l.62.93.62-.93a1.13,1.13,0,0,1,.67-.51,1,1,0,0,1,.79.1,1.15,1.15,0,0,1,.52.74A1.33,1.33,0,0,1,14.28,12.11Z"/>
    </g>
  </svg>
);

const Large = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36px" height="36px">
    <g id="icon-healthinfo" className={getClassName(props)}>
      <path d="M30.33,7H22.47L20,4.39A5.15,5.15,0,0,0,16.3,2.82H10.24A5.14,5.14,0,0,0,5.19,7,4.19,4.19,0,0,0,1.5,11.13V27a4.17,4.17,0,0,0,4.17,4.17H30.33A4.17,4.17,0,0,0,34.5,27V11.13A4.17,4.17,0,0,0,30.33,7ZM32.5,27a2.17,2.17,0,0,1-2.17,2.17H5.67A2.17,2.17,0,0,1,3.5,27V11.13A2.17,2.17,0,0,1,5.67,9H7.1V8a3.14,3.14,0,0,1,3.14-3.14H16.3a3.15,3.15,0,0,1,2.25,1L21.62,9h8.71a2.17,2.17,0,0,1,2.17,2.17Z"/>
      <path d="M22.2,13.81a3.6,3.6,0,0,0-2.83-.39,3.64,3.64,0,0,0-1.37.7,3.64,3.64,0,0,0-1.37-.7,3.6,3.6,0,0,0-2.83.39A3.84,3.84,0,0,0,12,16.26a4.17,4.17,0,0,0,1,3.79,23,23,0,0,0,3.16,2.89c.2.16.46.35.72.53a1.9,1.9,0,0,0,1.13.36,2,2,0,0,0,1.14-.36c.25-.18.51-.37.71-.53A23,23,0,0,0,23,20.05,4.17,4.17,0,0,0,24,16.26,3.84,3.84,0,0,0,22.2,13.81Zm-.7,4.93a20.28,20.28,0,0,1-2.89,2.63c-.17.14-.4.3-.61.46-.21-.16-.44-.32-.61-.46a20.28,20.28,0,0,1-2.89-2.63,2.14,2.14,0,0,1-.51-2,1.84,1.84,0,0,1,.84-1.19,1.62,1.62,0,0,1,.83-.23,1.74,1.74,0,0,1,.45.06,1.86,1.86,0,0,1,1.06.82L18,17.41l.83-1.24a1.83,1.83,0,0,1,1.06-.82,1.63,1.63,0,0,1,1.28.17A1.84,1.84,0,0,1,22,16.71,2.14,2.14,0,0,1,21.5,18.74Z"/>
    </g>
  </svg>
);

const XLarge = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" width="56px" height="56px">
    <g id="icon-healthinfo" className={getClassName(props)}>
      <path d="M47.41,10.38H35L31,6.27a7.94,7.94,0,0,0-5.67-2.41H15.77A7.91,7.91,0,0,0,8,10.41a6.38,6.38,0,0,0-5.78,6.34v25a6.38,6.38,0,0,0,6.37,6.38H47.41a6.38,6.38,0,0,0,6.38-6.38v-25A6.38,6.38,0,0,0,47.41,10.38ZM51,41.76a3.64,3.64,0,0,1-3.63,3.63H8.58A3.63,3.63,0,0,1,5,41.76v-25a3.63,3.63,0,0,1,3.62-3.62h2.05V11.75a5.14,5.14,0,0,1,5.14-5.14h9.55A5.15,5.15,0,0,1,29,8.18l4.78,5H47.41A3.63,3.63,0,0,1,51,16.75Z"/>
      <path d="M34.51,21.14a5.61,5.61,0,0,0-6.51.59,5.61,5.61,0,0,0-6.51-.59,5.82,5.82,0,0,0-2.68,3.74,6.26,6.26,0,0,0,1.45,5.79,35.41,35.41,0,0,0,4.95,4.53c.32.25.72.55,1.12.83a2.9,2.9,0,0,0,3.34,0c.4-.28.8-.58,1.12-.83a35.41,35.41,0,0,0,5-4.53,6.26,6.26,0,0,0,1.45-5.79A5.82,5.82,0,0,0,34.51,21.14Zm-.85,7.73a33.14,33.14,0,0,1-4.58,4.18c-.28.22-.64.49-1,.74a.11.11,0,0,1-.08,0l-.08,0c-.36-.25-.72-.52-1-.74a32.57,32.57,0,0,1-4.58-4.18,3.55,3.55,0,0,1-.85-3.37,3.08,3.08,0,0,1,1.41-2,2.74,2.74,0,0,1,1.42-.39,2.76,2.76,0,0,1,.75.1,3,3,0,0,1,1.79,1.37L28,26.28l1.14-1.7a3.06,3.06,0,0,1,1.78-1.37,2.81,2.81,0,0,1,2.18.29,3.08,3.08,0,0,1,1.41,2A3.55,3.55,0,0,1,33.66,28.87Z"/>
    </g>
  </svg>
);

const IconHealthInfo = {
  Small,
  Medium,
  Large,
  XLarge,
};

export default IconHealthInfo;
