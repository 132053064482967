/* CopFyright (c) 2017 Backpack Health LLC */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import trunkata from 'trunkata';

import AppUtils from '../../utilities/AppUtils';

function truncateContentByLines(root, options = { lines: 0, lineHeight: null }) {
  const simpleLineHeight = options.lineHeight || parseFloat(AppUtils.getStyle(root, 'lineHeight'));
  const expandedHeight = root.clientHeight;
  const tempNode = document.createElement('div');
  root.classList.add('__lineHeight-text');
  root.insertBefore(tempNode, root.children[0]);
  const s = document.createElement('style');
  s.innerHTML = `
    .__lineHeight-text * {
      margin: 0 !important;
      margin-bottom: 0 !important;
    }
  `;
  tempNode.appendChild(s);

  const isMarkdown = root.children[0].classList.contains('cmp-markdown');
  const theNodeContent = isMarkdown ? root.children[0].children[1] : root;
  const theNode = theNodeContent.cloneNode(true);
  tempNode.appendChild(theNode);

  // just using trunkata here to determine the height of content
  // trunkata actually alters the node
  // trunkata(theNode, { lines: this.props.lines });
  trunkata(theNode, { lines: options.lines });
  const collapsedHeight = theNode.clientHeight;

  root.classList.remove('__lineHeight-text');
  root.removeChild(tempNode);

  return {
    expandedHeight,
    collapsedHeight,
    lineHeight: simpleLineHeight,
  };
}

interface Props {
  lines: number;
  lineHeight?: number;
  defaultExpanded?: boolean;
  backgroundColor?: string;
  moreClassName?: string;
  newLineMore?(props): React.ReactFragment;
  alternateCollapsedContent?: any;
  customMoreText?: any;
  moreMaskWidth?: number;
  forceRemount?: string;
}

export const ClampedText: React.FC<Props> = (props) => {
  const { children, forceRemount, customMoreText, newLineMore, alternateCollapsedContent, lines, lineHeight, defaultExpanded } = props;
  const backgroundColor = props.backgroundColor || 'white';
  const moreClassName = props.moreClassName || 'font-color-profile';
  const moreMaskWidth = props.moreMaskWidth || 120;

  const contentRef = React.useRef(null);

  const [state, setState] = React.useState({
    expanded: defaultExpanded || false,
    calculatedLineHeight: 0,
    isMounted: false,
    isAnimated: false,
    isOverfilled: false,
    collapsedHeight: 0,
    remountKey: forceRemount,
  });

  const { expanded, isMounted, collapsedHeight, isOverfilled, isAnimated, remountKey  } = state;
  const useLineHeight = lineHeight || state.calculatedLineHeight;
  const showAlternateCollapsedContent = isMounted && !expanded && alternateCollapsedContent;

  const expand = (e) => {
    e.preventDefault();
    setState({ ...state, expanded: true, isAnimated: true });
  };

  React.useEffect(() => {
    // if content is smaller than specified number of lines, need to release height constraint to remove extra gap
    // after clamped text. No easy way to find content height before rendering, so we'll do it when component is mounted
    window.requestAnimationFrame(() => {
      const contentNode = contentRef.current;

      const truncateData  = truncateContentByLines(contentNode, { lines, lineHeight });
      const collapsedLines = Math.round(truncateData.collapsedHeight / truncateData.lineHeight);
      const isOverfilled = collapsedLines >= lines ? truncateData.expandedHeight > truncateData.collapsedHeight : false;

      setState({
        ...state,
        isMounted: true,
        isOverfilled,
        collapsedHeight: truncateData.collapsedHeight,
        calculatedLineHeight: truncateData.lineHeight,
        expanded: defaultExpanded || !isOverfilled,
      });
    });
  }, []);


  return (
    <div>
      <div
        className={classnames('cmp-clamped-text', { animated: isAnimated })}
        style={{
          height: expanded ? null : collapsedHeight,
          maxHeight: expanded ? 10000 : collapsedHeight,
        }}
      >
        <div className="content" ref={contentRef}>
          {showAlternateCollapsedContent || children}
        </div>
        {
          !expanded && !newLineMore && isOverfilled &&
          <a
            className={classnames('more', moreClassName)}
            style={{
              backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0), ${backgroundColor} 30%, ${backgroundColor})`,
              lineHeight: `${useLineHeight}px`,
              width: moreMaskWidth,
            }}
            href="#"
            onClick={expand}
          >
            {customMoreText || <FormattedMessage id="nav.more.button.text"/>}
          </a>
        }
      </div>
      {!expanded && isOverfilled && newLineMore && newLineMore({ expand })}
    </div>
  );
};

export const NewLineMore = ({ expand, color }) => (
  <a
    style={{ marginTop: 8, color, display: 'block' }}
    href="#"
    onClick={expand}
    className="font-subhead"
  >
    <FormattedMessage id="share.more.button.label"/>
  </a>
);

export const RemountClampedText: React.FC<Props> = (props) => {
  const { forceRemount } = props;
  const [remountKey, setRemountKey] = React.useState(forceRemount);

  React.useEffect(() => {
    if (remountKey === forceRemount) {
      setRemountKey(forceRemount);
      return;
    }
  }, [remountKey, forceRemount]);

  return remountKey !== forceRemount ? null : (
    <ClampedText {...props} />
  );
};
