import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileActions from '../appAuthorized/redux/ProfileActions';
import { actions as consentActions } from '../redux/ducks/consent';
import { actions as integrationsActions } from '../redux/ducks/integrations';
import { actions as groupActions } from '../redux/ducks/group';

import ProfileService from '../../apiServices/ProfileService';
import ProfileModel from '../../models/ProfileModel';
import { useUIState } from './useUIState';
import { useQuery } from 'react-query';
import { useLists } from './useLists';
import { useActiveUser } from './useUser';

const STALE_TIME = 500000;
const INITIAL_PROFILE_STATE = {
  data: {},
  isLoaded: false,
};

function fetchAllProfiles() {
  return new Promise((res, rej) => {
    ProfileService.getAll((apiError, profileCollection) => {
      if (apiError) {
        rej(apiError);
        return;
      }
      res(profileCollection);
    });
  });
}

function fetchProfile(dispatch) {
  return function fetchProfile_API(key, profileId) {
    return new Promise((res, rej) => {
      ProfileService.get(profileId, (apiError, profile) => {
        dispatch(ProfileActions.receiveProfile(profile.data));

        res({
          data: profile.data,
          isLoaded: true,
        });
      });
    });
  };
}

export function useProfile(profileId, forceFetch?) {
  const dispatch = useDispatch();
  const queryData: any = useQuery(['profile', profileId], fetchProfile(dispatch), { enabled: Boolean(profileId), staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_PROFILE_STATE;
  const profileData = responseData.data;
  const isLoaded = responseData.isLoaded;

  const [{ residenceCountryList, countryList }] = useLists();

  return [
    {
      profileId,
      isLoaded,
      data: profileData,
      profile: profileData,
      error: queryData.error,
      greetingName: profileData.preferred_name || '',
    },
    {
      instantiateProfile: () => {
        return new ProfileModel(profileData);
      },
      provideGroupConsent: (groupId, policyIds) => {
        return ProfileService.provideGroupConsent(profileId, groupId, policyIds);
      },
      getDefaultCountryISO2: () => {
        const residenceCountryId = profileData.residence_country_id;
        const residentCountry = residenceCountryList.find(c => c.id === residenceCountryId);
        const iso2 = residentCountry.iso2;
        const defaultCountry = countryList.find(a => a.iso2 === iso2);
        return defaultCountry ? defaultCountry.iso2 : 'US';
      },
    },
  ];
}

export function useAllProfiles() {
  const profilesList = useSelector(state => state.data.profiles.collection);
  const profileLookup = profilesList.reduce((acc, v) => ({ ...acc, [v.id]: v }), {});
  React.useEffect(() => {
    ProfileActions.requestAllProfiles();
  } , []);

  return [
    {
      profilesList,
      profileLookup,
    },
  ];
}

export function useNextProfileColor() {
  React.useEffect(() => {
    ProfileActions.requestNextProfileColor();
  }, []);
}

export function setActiveProfile(profileData) {
  // const dispatch = useDispatch();
  // const profileId = profileData.id;

  // React.useEffect(() => {
  //   if (profileId) {
  //     // const profile = useSelector(state => getProfileFromRedux(profileId, state));
  //     // dispatch(setActiveProfile(profileData));
  //     // dispatch(ProfileActions.setCurrentColorTheme({ id: profile.data.color_theme_id }));
  //     // dispatch(actionItemActions.requestActionItems(null, profileId));
  //     // dispatch(consentActions.requestAllConsents(profileId));
  //     // ShareCardDefinitionsActions.load(profileId);
  //     // dispatch(groupActions.requestGroups(profileId));
  //     // dispatch(integrationsActions.requestIntegrations(profileId));
  //   }
  // }, [profileId]);
}

export function useSwitchActiveProfileQuery() {
  const dispatch = useDispatch();
  const [{ profileId, profile }] = useActiveProfile();

  React.useEffect(() => {
    if (profileId > 0 && profile.id) {
      dispatch(ProfileActions.setCurrentColorTheme({ id: profile.color_theme_id }));
      // dispatch(actionItemActions.requestProfileActionItems(profileId));
      dispatch(consentActions.requestAllConsents(profileId));
      // ShareCardDefinitionsActions.load(profileId);
      dispatch(groupActions.requestGroups(profileId));
      dispatch(integrationsActions.requestIntegrations(profileId));
      // HealthEntryActions.api.getAll(profile.id);
    }
  }, [profile.id]);
}

export function useActiveProfile(forceFetch?) {
  const [{ activeProfileId }] = useUIState();
  return useProfile(activeProfileId, forceFetch);
}

export function useUserProfile() {
  const dispatch = useDispatch();
  const [{ user }] = useActiveUser();
  const profileId = user.profile_id;
  const queryData: any = useQuery(['profile', profileId], fetchProfile(dispatch), { enabled: Boolean(profileId), staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_PROFILE_STATE;
  const profile = responseData.data;

  return [
    {
      status: '',
      profileId,
      data: profile,
      profile,
      error: '',
      isLoaded: responseData.isLoaded,
    },
    {
      instantiateProfile: () => {
        return new ProfileModel(profile);
      },
      provideGroupConsent: (groupId, policyIds) => {
        return ProfileService.provideGroupConsent(profileId, groupId, policyIds);
      },
    },
  ];
}
