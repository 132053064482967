import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Icons } from '../../../../bphComponents/bundle';
import COLORS from '../../../styles/Colors';

import { HEPropertyCollection } from './HEPropertyCollection';
import OccurrenceEmptyProperty from './OccurrenceEmptyProperty';
import RouteNames from '../../../appAuthorized/RouteNames';
import { useNavigate } from '../../../hooks/useNavigate';
import { useAttachmentsForParentItem } from '../../../hooks/useAttachments';
import { useActiveUser } from '../../../hooks/useUser';
import ContactModel from '../../../../models/ContactModel';
import { ContactSpecialtyText, ContactSummaryText } from '../../../components/ContactSummaryText';
import { iTrackable, iObservation } from '../../../../apiServices/apiModels/Trackable';
import { attachmentTypes } from '../../../../models/AttachmentModel';


const getContactSubTitle = (contact) => {
  if (!contact || !ContactModel.isProvider(contact)) { return null; }

  if (contact.contact_specialty_id) {
    return <ContactSpecialtyText data={contact} isSingleLine />;
  }

  return <ContactSummaryText data={contact} isSingleLine />;
};

interface Props {
  onSelect(c): void;
  onDelete(c): void;
  onAttach(): void;
  contacts: any[];
}
const OccurrenceContacts: React.FC<Props> = (props) => {
  const { onSelect, onDelete, onAttach, contacts } = props;
  const [{ preferredLanguageCode }] = useActiveUser();

  if (contacts.length === 0) {
    return (
      <OccurrenceEmptyProperty
        languageCode={preferredLanguageCode}
        icon={<Icons.IconProvidersContacts.Medium color={COLORS.healthEntryPropertyIconEmpty} />}
        emptyValue={<FormattedMessage id="providers.attachProvider.button.label" />}
        onCreate={onAttach}
      />
    );
  }

  return (
    <HEPropertyCollection
      languageCode={preferredLanguageCode}
      icon={<Icons.IconProvidersContacts.Medium color={COLORS.healthEntryPropertyIconWithValue} />}
      collection={contacts}
      getValue={(c) => (c ? (c.name || '') : '')}
      getSubTitle={getContactSubTitle}
      addAnotherValue={<FormattedMessage id="providers.attachAnotherProvider.button.label" />}
      onClick={onSelect}
      onCreate={onAttach}
      onRemove={onDelete}
    />
  );
};

interface HEProps {
  healthEntry: any;
  parentHealthEntryId?: number;
}
export const HealthEntryContacts: React.FC<HEProps> = (props) => {
  const { healthEntry, parentHealthEntryId } = props;
  const [, navActions] = useNavigate();
  const healthEntryId = healthEntry.id;
  const attachmentType = parentHealthEntryId ? 'health_entry_value' : 'health_entry';
  const [{ contacts }, attachmentActions] = useAttachmentsForParentItem(attachmentType, healthEntryId);

  return (
    <OccurrenceContacts
      contacts={contacts}
      onAttach={() => {
        if (parentHealthEntryId) {
          navActions.goTo(RouteNames.contactAttach(attachmentTypes.healthEntryValue, healthEntryId));
        } else {
          navActions.goTo(RouteNames.contactAttach(attachmentTypes.healthEntry, healthEntryId));
        }
      }}
      onSelect={(contact) => {
        navActions.goTo(RouteNames.contact(contact.id, window.location.pathname));
      }}
      onDelete={(contact) => {
        const attachment = attachmentActions.getContactAttachment(contact.id);
        attachmentActions.apiDeleteAttachment(attachment);
      }}
    />
  );
};

interface TrackableProps {
  trackable: iTrackable;
}
export const TrackableContacts: React.FC<TrackableProps> = (props) => {
  const { trackable } = props;
  const [{ contacts }, attachmentActions] = useAttachmentsForParentItem(attachmentTypes.trackable, trackable.id);
  const [, navActions] = useNavigate();

  return (
    <OccurrenceContacts
      contacts={contacts}
      onAttach={() => {
        navActions.goTo(RouteNames.contactAttach(attachmentTypes.trackable, trackable.id));
      }}
      onSelect={(contact) => {
        navActions.goTo(RouteNames.contact(contact.id, window.location.pathname));
      }}
      onDelete={(contact) => {
        const attachment = attachmentActions.getContactAttachment(contact.id);
        attachmentActions.apiDeleteAttachment(attachment);
      }}
    />
  );
};

interface ObservationProps {
  observation: iObservation;
}
export const ObservationContacts: React.FC<ObservationProps> = (props) => {
  const { observation } = props;
  const [{ contacts }, attachmentActions] = useAttachmentsForParentItem(attachmentTypes.trackableObservation, observation.id);
  const [, navActions] = useNavigate();

  return (
    <OccurrenceContacts
      contacts={contacts}
      onAttach={() => {
        navActions.goTo(RouteNames.contactAttach(attachmentTypes.trackableObservation, observation.id));
      }}
      onSelect={(contact) => {
        navActions.goTo(RouteNames.contact(contact.id, window.location.pathname));
      }}
      onDelete={(contact) => {
        const attachment = attachmentActions.getContactAttachment(contact.id);
        attachmentActions.apiDeleteAttachment(attachment);
      }}
    />
  );
};
