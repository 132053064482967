import React from 'react';
import ManageSession from '../../../utilities/ManageSession';

import SessionModel from '../../../models/SessionModel';
import { authToken, deepLink } from '../../../utilities/LocalStorage';
import ActiveProfile from './ActiveProfile';
import Layout from '../../applicationFrame/Layout';
import RoutesWithValidProfile from '../../appAuthorized/RoutesWithValidProfile';
import { Switch, Route } from 'react-router';
import RouteNames from '../../appAuthorized/RouteNames';
import TokenViews from '../../appUnauthorized/presenters/TokenViews.controller';
import AppInitialization from './AppInitialization';
import ProfileSetup from '../../appAuthorized/views/profile/ProfileSetup.redux';
import UserSetup from '../../appAuthorized/views/user/UserSetup';
import { useNavigate } from '../../hooks/useNavigate';

interface Props {
  userData: any;
  store: any;
}
const AuthenticatedApp = (props: Props) => {
  const { userData, store } = props;
  const [, navActions] = useNavigate();

  React.useEffect(() => {
    const tokenData = authToken.decode();
    const session = new SessionModel(tokenData);
    ManageSession.start(session, store);

    const d = deepLink.get();
    deepLink.remove();
    if (d) { navActions.goTo(d); }
  }, []);

  return (
    <AppInitialization userId={userData.id}>
      <Layout>
        <Switch>
          <Route path={RouteNames.token()} component={TokenViews} />
          <Route exact path={RouteNames.newProfileSetup()} component={ProfileSetup} />
          <Route exact path={RouteNames.profileSetup()} component={ProfileSetup} />
          <Route exact path={RouteNames.profileSetupIntro()} component={ProfileSetup} />
          <Route exact path={RouteNames.userSetup()} component={UserSetup} />
          <Route exact path={RouteNames.newUserSetup()} component={UserSetup} />
          <Route exact path="/signup" component={UserSetup} />

          <Route path="/">
            <ActiveProfile userId={userData.id}>
              <RoutesWithValidProfile />
            </ActiveProfile>
          </Route>
        </Switch>
      </Layout>
    </AppInitialization>
  );
};

export default AuthenticatedApp;
