import React, { SFC } from 'react';
import * as _ from 'lodash';

import { ContactDetailType } from '../../models/ContactModel';
import { FormatText } from '../../bphComponents/bundle';
import ContactLabel from './ContactLabel';

const DETAIL_ORDER = [
  'phone',
  'email',
  'address',
  'website',
];

interface ContactDetailTextProps {
  detail: ContactDetailType;
}

const ContactDetailText: SFC<ContactDetailTextProps> = (props) => {
  const { detail } = props;

  switch (detail.type) {
    case 'phone':
      return (
        <FormatText.PhoneNumber iso2Code={detail.phone.country_code} phone={detail.phone.number} />
      );
    case 'address':
      return (
        <span>{detail.address.detail}</span>
      );
    case 'email':
      return (
        <span>{detail.email.address}</span>
      );
    case 'website':
      return (
        <span>{detail.website.url}</span>
      );
    default:
      return (
        <span />
      );
  }

};

interface Props {
  details: ContactDetailType[];
  Component: any;
}

const ContactDetails: SFC<Props> = (props) => {
  const { details, Component } = props;
  const groupedDetails = _.groupBy(details, (d) => d.type);

  return (
    <>
      {
        DETAIL_ORDER.map(typeName => {
          const group = groupedDetails[typeName];
          if (!group) { return null; }

          return group.map(d => (
            <Component key={d.id}>
              <ContactLabel id={d.contact_detail_label_id} /> – <ContactDetailText detail={d} />
            </Component>
          ));
        })
      }
    </>
  );
};

export default ContactDetails;
