import React from 'react';

import './SurveyHint.scss';

const SurveyHint: React.FC = (props) => {
  const { children } = props;

  if (!children) { return null; }

  return (
    <div className="cmp-surveyhint font-footnote font-color-gsMedDark">
      {children}
    </div>
  );
};

export default SurveyHint;
