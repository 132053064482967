
import React from 'react';

import UI_CONSTANTS from '../../constants/uiConstants';
import { cleanupNotifications } from '../../models/DeviceNotifications';
import { hideSplashScreen, isAbleToMakeRequest } from '../../utilities/MobileUtil';

import { useSession } from './useSession';
import { useUIState } from './useUIState';
import { useUserActionItems } from './useActionItems';
import { useTrackableList } from './useTrackable';

function onNetworkOnline() {
  window.forceCache.loadDefault(
    status => {
      console.log(status);
    },
    status => {
      console.log(status);
    },
  );
}

function onNetworkOffline() {
  window.forceCache.loadCache(
    status => {
      console.log(status);
    },
    status => {
      console.log(status);
    },
  );
}

function showKeyboard() {
  updateIntercomHeight();
}

function updateIntercomHeight() {
  const intercomElements = document.getElementsByClassName(
    'intercom-messenger-frame',
  ) as HTMLCollectionOf<HTMLElement>;
  const intercom = intercomElements[0];
  if (intercom) {
    intercom.style.cssText = `height: ${window.keyboardHeight}px !important`;
  }
}

function toggleKeyboard() {
  updateIntercomHeight();
}

export function useAndroidInitialization(userId) {
  if (!IS_ANDROID_BUILD) { return null; }

  const [{ sliding, activeProfileId, isLoggedIn }, uiActions] = useUIState();
  const [, sessionActions] = useSession();
  const [userActionItems] = useUserActionItems(userId);
  const [trackableData] = useTrackableList(activeProfileId, null);

  const isUserActionsComplete = userActionItems.isComplete;

  React.useEffect(() => {
    const checkVersion = () => {
      if (!isAbleToMakeRequest()) { return; }

      sessionActions.checkVersion()
        .then((res) => {
          if (res.requiresUserReview) {
            uiActions.showBreakingChangeModal(res);
          }
        });
    };

    const onResume = () => {
      if (!userId || !isUserActionsComplete) { return; }
      checkVersion();
      if (isLoggedIn && trackableData.isLoaded) {
        cleanupNotifications(trackableData.trackableList, activeProfileId);
      }
    };

    document.addEventListener('resume', onResume, false);
    return () => {
      document.removeEventListener('resume', onResume, false);
    };
  }, [isUserActionsComplete, activeProfileId, trackableData.isLoaded, isLoggedIn, userId]);

  React.useEffect(() => {
    const onOrientationChange = () => {
      if (sliding) {
        uiActions.hideAppMenu();
      }
    };

    window.addEventListener('orientationchange', onOrientationChange);
    return () => {
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, [sliding]);

  React.useEffect(() => {
    hideSplashScreen();

    if (window.screen.availWidth >= UI_CONSTANTS.applicationContentWidth) {
      window.screen.orientation.unlock();
    }

    document.addEventListener('online', onNetworkOnline, false);
    document.addEventListener('offline', onNetworkOffline, false);
    window.addEventListener('native.keyboardshow', showKeyboard);
    window.addEventListener('native.keyboardhide', toggleKeyboard);

    return () => {
      document.removeEventListener('online', onNetworkOnline, false);
      document.removeEventListener('offline', onNetworkOffline, false);
      window.removeEventListener('native.keyboardshow', showKeyboard);
      window.removeEventListener('native.keyboardhide', toggleKeyboard);
    };
  }, []);
}
