import * as _ from 'lodash';

import { iSurveyQuestion, eSurveyQuestionCategoryName, iSurveyResponse, iSurveyTopLevelQuestion, iSurveySubQuestionResponse } from './../apiServices/SurveyService';
import { isValidHealthEntryValueAmount } from '../utilities/Validators';

// NOTE
// Survey questions are made up of text_entyr, single_answer, rapid_response, etc.
// rapid_response and composite questions have subQuestions
// rapid_response subQuestions can have followupQuestions based on the subQuestion answer

class SurveyQuestionResponseModel {
  static isValid = (response: iSurveyResponse, question: iSurveyQuestion): boolean => {
    switch (response.category) {
      case eSurveyQuestionCategoryName.amountEntry:
        const requiresUnits = _.get(question, 'amount_entry.units.length', 0) > 0;
        const hasUnits = !requiresUnits || !!response.amount_entry.unit_id;
        const hasValue = _.compact(_.get(response, 'amount_entry.values', [])).length > 0;
        const hasNumericValues = _.compact(_.get(response, 'amount_entry.values', [])).reduce((total, v) => {
          return total ? isValidHealthEntryValueAmount(v) : false;
        }, true);

        return (hasValue && hasUnits && hasNumericValues) || response.amount_entry.unsure;
      case eSurveyQuestionCategoryName.fuzzyDate:
        const fuzzyDate = response.fuzzy_date || { date: null, precision: null };
        return !!(fuzzyDate.date && fuzzyDate.precision);
      case eSurveyQuestionCategoryName.rapidResponse:
        const subQuestionLookup = {};
        question.rapid_response.options.forEach(o => {
          subQuestionLookup[o.response.id] = !!o.question;
        });
        const total = response.rapid_response.list.filter(l => {
          const hasAnswer = !!l.option.id;
          if (!hasAnswer) { return false; }

          const hasSubquestion = !!subQuestionLookup[l.option.id];
          const hasSubQuestionAnswer = l.option.category;
          return hasSubquestion ? (hasSubQuestionAnswer && l.option[l.option.category].id) : true;
        }).length;
        return total === question.rapid_response.list.length;
      case eSurveyQuestionCategoryName.singleAnswer:
        return !!response.single_answer.id;
      case eSurveyQuestionCategoryName.textEntry:
        return !!response.text_entry.content;
      case eSurveyQuestionCategoryName.composite:
        return question.composite.segments.reduce((acc, qVal: iSurveyQuestion, i) => {
          const val: iSurveyResponse = _.get(response, `composite.segments[${i}]`, null);
          if (!val) { return false; }
          return SurveyQuestionResponseModel.isValid(val, qVal) ? acc : false;
        }, true);
      default:
        return false;
    }
  }

  static hasAnswer = (response: iSurveyResponse): boolean => {
    switch (response.category) {
      case eSurveyQuestionCategoryName.amountEntry:
        return _.compact(_.get(response, 'amount_entry.values', [])).length > 0;
      case eSurveyQuestionCategoryName.fuzzyDate:
        const fuzzyDate = response.fuzzy_date || { date: null, precision: null };
        return !!fuzzyDate.date;
      case eSurveyQuestionCategoryName.rapidResponse:
        return response.rapid_response.list.filter(l => !!l.option.id).length > 0;
      case eSurveyQuestionCategoryName.singleAnswer:
        return !!response.single_answer.id;
      default:
        return false;
    }
  }

  static hasSubAnswer = (question, answerResponse) => {
    const q = question[question.category];

    switch (question.category) {
      case eSurveyQuestionCategoryName.rapidResponse:
        const { options } = q;
        const selected = options.find(o => o.response.id === answerResponse.option.id);
        const questionHasSubQuestion = Boolean(selected.question);
        return questionHasSubQuestion ? Boolean(_.get(answerResponse, `option[${answerResponse.option.category}].id`, '')) : false;
      case eSurveyQuestionCategoryName.amountEntry:
      case eSurveyQuestionCategoryName.fuzzyDate:
      case eSurveyQuestionCategoryName.singleAnswer:
      default:
        return false;
    }
  }

  static hasSubQuestion = (question) => {
    switch (question.category) {
      case eSurveyQuestionCategoryName.rapidResponse:
      case eSurveyQuestionCategoryName.composite:
        return true;
      case eSurveyQuestionCategoryName.amountEntry:
      case eSurveyQuestionCategoryName.fuzzyDate:
      case eSurveyQuestionCategoryName.singleAnswer:
      default:
        return false;
    }
  }

  static hasResponseForSubQuestion = (response) => {
    // this might only be for rapid_response?
    return (response.option && response.option.category) ? Boolean(response[response.category].id) : false;
  }

  static isRapidResponseSingleQuestionAnswerComplete = (fullQuestion, singleResponse) => {
    const questionData = fullQuestion[fullQuestion.category];
    const selectedAnswer = questionData.options.find(o => o.response.id === singleResponse.option.id);
    const selectedAnswerSubQuestion = selectedAnswer.question;
    const doesResponseAnswerHaveAnotherQuestion = Boolean(selectedAnswerSubQuestion);

    if (doesResponseAnswerHaveAnotherQuestion) {
      return Boolean(_.get(singleResponse, `option[${singleResponse.option.category}].id`, ''));
    } else {
      return true;
    }
  }

  static getFirstUnansweredQuestionData(question, response, segmentIndex) {
    const questionData = {
      segmentIndex,
      subQuestionIndex: 0,
    };

    switch (question.category) {
      case eSurveyQuestionCategoryName.composite:
        for (let i = 0; i < question[question.category].segments.length; i++) {
          const rSegment = _.get(response, `composite.segments[${i}]`, null);
          const a = SurveyQuestionResponseModel.getFirstUnansweredQuestionData(question[question.category].segments[i], rSegment, i);
          if (a) {
            return a;
          }
        }
        return null;
      case eSurveyQuestionCategoryName.rapidResponse:
        for (let i = 0; i < question[question.category].list.length; i++) {
          if (!_.get(response, `${question.category}.list[${i}]`, null)) {
            return {
              ...questionData,
              subQuestionIndex: i,
            };
          }
        }
        return null;
      default:
        return response ? null : questionData;
    }
  }

  static getSubQuestionCount(question, segmentIndex) {
    const segmentQuestion = SurveyQuestionResponseModel.getSegmentQuestion(question, null, segmentIndex);

    switch (segmentQuestion.category) {
      case eSurveyQuestionCategoryName.rapidResponse:
        return segmentQuestion[segmentQuestion.category].list.length;
      default:
        return 1;
    }
  }

  static isLastSubQuestionInSegment(question, segmentIndex, subQuestionIndex) {
    const segmentQuestionData = SurveyQuestionResponseModel.getSegmentQuestion(question, null, segmentIndex);
    const segmentQuestion = segmentQuestionData.question;

    switch (segmentQuestion.category) {
      case eSurveyQuestionCategoryName.rapidResponse:
        return subQuestionIndex >= segmentQuestion[segmentQuestion.category].list.length - 1;
      default:
        return true;
    }
  }

  static getSegmentQuestion(question, response, segmentIndex) {
    switch (question.category) {
      case eSurveyQuestionCategoryName.composite:
        return SurveyQuestionResponseModel.getSegmentQuestion(
          question[question.category].segments[segmentIndex],
           _.get(response, `${question.category}.segments[${segmentIndex}]`, null),
          0,
        );
      default:
        return { question, response };
    }
  }

  static getSubQuestion(question, response, segmentIndex, subQuestionIndex) {
    switch (question.category) {
      case eSurveyQuestionCategoryName.composite:
        return SurveyQuestionResponseModel.getSubQuestion(
          question[question.category].segments[segmentIndex],
           _.get(response, `${question.category}.segments[${segmentIndex}]`, null),
          0,
          subQuestionIndex,
        );
      case eSurveyQuestionCategoryName.rapidResponse:
        return {
          question: question[question.category].list[subQuestionIndex],
          response: _.get(response, `${question.category}.list[${subQuestionIndex}]`, null),
        };
      default:
        return { question, response };
    }
  }

  static isSubQuestionComplete(question, response, segmentIndex, subQuestionIndex) {
    const subQuestionData = SurveyQuestionResponseModel.getSubQuestion(
      question,
      response,
      segmentIndex,
      subQuestionIndex,
    );

    if (!subQuestionData) { return false; }

    if (subQuestionData.question && subQuestionData.response) {
      return true;
    } else if (subQuestionData.response) {
      return true;
    }

    return false;
  }

  static isSubQuestionAnswerComplete = (question, response, subQuestionUid) => {
    switch (question.category) {
      case eSurveyQuestionCategoryName.rapidResponse:
        if (!response || !response.rapid_response) { return false; }
        const subQuestionResponse = response.rapid_response.list.find((rq) => rq.uid === subQuestionUid);
        const subQuestionAnswerId =  subQuestionResponse ? subQuestionResponse.option.id : null;
        const isSubQuestionAnswered = Boolean(subQuestionAnswerId);
        if (isSubQuestionAnswered) {
          const answerDefinition = question.rapid_response.options.find((rq) => rq.response.id === subQuestionAnswerId);
          const hasFollowupQuestion = answerDefinition ? Boolean(answerDefinition.question) : false;
          if (!hasFollowupQuestion) {
            return true;
          }
          const followupResponse = _.get(subQuestionResponse, `option[${subQuestionResponse.option.category}].id`, null);
          return Boolean(followupResponse);
        }
        return false;
      case eSurveyQuestionCategoryName.composite:
        let segmentQuestion = null;
        let segmentResponse = null;

        for (let i = 0; i < question.composite.segments.length; i++) {
          const sq = question.composite.segments[i];
          const sr = response && response.composite ? response.composite.segments[i] : response;
          const qd = sq[sq.category];

          switch (sq.category) {
            case eSurveyQuestionCategoryName.rapidResponse:
              if (qd.list.find(qdli => qdli.uid === subQuestionUid)) {
                segmentQuestion = sq;
                segmentResponse = sr;
              }
              break;
            default:
              if (qd.uid === subQuestionUid) {
                segmentQuestion = sq;
                segmentResponse = sr;
              }
              break;
          }
          if (segmentQuestion) { break; }
        }

        return SurveyQuestionResponseModel.isSubQuestionAnswerComplete(segmentQuestion, segmentResponse, subQuestionUid);
      case eSurveyQuestionCategoryName.amountEntry:
      case eSurveyQuestionCategoryName.fuzzyDate:
      case eSurveyQuestionCategoryName.singleAnswer:
      default:
        return true;
    }
  }

  static updateRapidResponseAnswer = (uid, existingResponse, newResponse) =>  {
    const rapidResponseAnswerList = _.get(existingResponse, `${eSurveyQuestionCategoryName.rapidResponse}.list`, []).map(a => a);

    const index = rapidResponseAnswerList.findIndex((a) => a.uid === uid);

    if (index === -1) {
      rapidResponseAnswerList.push(newResponse);
    } else {
      rapidResponseAnswerList[index] = newResponse;
    }
    return {
      category: eSurveyQuestionCategoryName.rapidResponse,
      [eSurveyQuestionCategoryName.rapidResponse]: {
        list: rapidResponseAnswerList,
      },
    };
  }

  static updateSingleRapidResponseAnswer = (uid, id, subResponse) => {
    const res = subResponse || {};
    const singleResponse = {
      uid,
      option: {
        id,
        ...res,
      },
    };

    return singleResponse;
  }

  static getQuestionOptionText = (question, response) => {
    const category = response.category;
    if (!category) { return ''; }
    const optionId = response[category].id;
    const options = question[category].options;
    return (_.find(options, o => o.response.id === optionId) || { text: '' }).text;
  }

  private question;
  private response;

  constructor(question: iSurveyQuestion, response: iSurveyResponse) {
    this.question = question;
    this.response = response;
  }

  getSubQuestion(uid, segmentIndexOffset = 0) {
    let subQuestionIndex = -1;
    let subQuestion: iSurveyQuestion;
    let subResponse: iSurveySubQuestionResponse;

    switch (this.question.category) {
      case eSurveyQuestionCategoryName.rapidResponse:
        subQuestionIndex = this.question[this.question.category].list.findIndex(q => q.uid === uid);
        subQuestion = subQuestionIndex > -1 ? this.question[this.question.category].list[subQuestionIndex] : null;
        subResponse = _.get(this, `response[${this.question.category}].list`, []).find(q => q.uid === uid);
        const selectedQuestionOption = subResponse ? this.question.rapid_response.options.find(o => o.response.id === subResponse.option.id) : null;

        if (subQuestion) {
          return {
            subQuestion,
            subResponse,
            status: {
              isLast: subQuestionIndex >= this.question[this.question.category].list.length -1,
              isComplete: selectedQuestionOption.question ? Boolean(subResponse.option.category) : true,
              absoluteIndex: subQuestionIndex + segmentIndexOffset,
            },
          };
        }
        return null;
      case eSurveyQuestionCategoryName.composite:
        let runningIndex = 0;
        for (let i = 0; i < this.question.composite.segments.length; i++) {
          const segmentQuestion = this.question.composite.segments[i];
          const seqmentResponse = _.get(this, `response.composite.segments[${i}]`, null);
          const qq = new SurveyQuestionResponseModel(segmentQuestion, seqmentResponse);
          const found = qq.getSubQuestion(uid, runningIndex);

          if (found) {
            if (i < this.question.composite.segments.length - 1) {
              found.status.isLast = false;
            }
            return found;
          } else {
            runningIndex += segmentQuestion.category === eSurveyQuestionCategoryName.rapidResponse ? segmentQuestion[segmentQuestion.category].list.length : 1;
          }
        }
        return null;
      default:
        return {
          subQuestion: null,
          subResponse: null,
          status: {
            isLast: true,
            isComplete: true,
            absoluteIndex: segmentIndexOffset,
          },
        };
    }
  }

  static getResponseEvaluations(response: iSurveyResponse) {
    switch (response.category) {
      case eSurveyQuestionCategoryName.composite:
        return response[response.category].evaluations;
      default:
        return [];
    }
  }
  static geQuestionEvaluations(question: iSurveyTopLevelQuestion) {
    switch (question.category) {
      case eSurveyQuestionCategoryName.composite:
        return question[question.category].evaluations;
      default:
        return [];
    }
  }
}

class SubQuestion {

  get isLast() {
    return '';
  }

  get isComplete() {
    return '';
  }

  get absoluteIndex() {
    // const totalIndex = question[question.category].segments.reduce((acc, v, i) => {
    //   return i < segmentIndex ? acc + v.rapid_response.options.length - 1 : acc;
    // }, 0);
    return '';
  }
}
export default SurveyQuestionResponseModel;
