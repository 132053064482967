
import React from 'react';

import { Buttons } from '../../../bphComponents/bundle';

export const ProfileThemedBasePrimaryButton = (props) => (
  <Buttons.StandardButtonVariants.PrimaryButton {...props} className="profile-background-color font-color-white" />
);

export const ProfileThemedAccentPrimaryButton = (props) => (
  <Buttons.StandardButtonVariants.PrimaryButton {...props} className="profile-background-color-accent font-color-white" />
);
