import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../../components/ScrollAware';
import { AppHeaderScrollableText } from '../../../components/appNav/AppHeaderScrollable';
import Card, { CardButton, CardSection, GrayCard, ExternalCardTitle } from '../../../components/card/Card';
import ContentWrapper from '../../../components/ContentWrapper';
import ContentFrame from '../../../applicationFrame/ContentFrame';
import ContentLoader from '../../../components/ContentLoader';
import OwnProfileInvitation from './OwnProfileInvitation';

import NotificationService from '../../../../apiServices/NotificationService';
import SharedOwnerNotificationModel from '../../../../models/SharedOwnerNotificationModel';
import NotificationModel from '../../../../models/NotificationModel';
import { navigate } from '../../redux/NavigationActions';
import RouteNames from '../../RouteNames';

import { actions as actionItemActions, selectors as actionItemSelectors } from '../../../redux/ducks/actionItems';
import { actions as userActions, selectors as userSelectors } from '../../../redux/ducks/user';
import { actions as notificationActions, selectors as notificationSelectors } from '../../../redux/ducks/notifications';
import ProfileActions from '../../redux/ProfileActions';
import UserModel from '../../../../models/UserModel';

interface Props {
  userId: number;
  profileId: number;
  userActionItems: any[];
  selfInvitations: SharedOwnerNotificationModel[];
  dispatch(action): Promise<any>;
}

interface State {
  selectedInvitationId: number;
  inProgress: boolean;
}

class ViewAcceptOwnProfile extends React.Component<Props, State> {

  state: State = {
    selectedInvitationId: null,
    inProgress: true,
  };

  componentWillMount() {
    this.props.dispatch(notificationActions.requestAll()).then(() => {
      this.setState({ inProgress: false });
    });
  }

  rejectInvitation = (notificationId) => {
    return NotificationService.sharedOwnerReject(notificationId);
  }

  acceptInvitation = (notificationId) => {
    return NotificationService.sharedOwnerAccept(notificationId);
  }

  createOwnProfile = () => {
    const { userId, profileId, selfInvitations } = this.props;

    const rejectAll = selfInvitations.map(n => this.rejectInvitation(n.id));
    this.setState({ inProgress: true }, () => {
      Promise.all(rejectAll)
        .then(() => {
          return this.props.dispatch(actionItemActions.requestActionItems(userId, profileId));
        })
        .then(() => {
          this.setState({ inProgress: false });
          navigate(RouteNames.home());
        });
    });
  }

  useProfileFromInvitation = () => {
    const { userId, selfInvitations } = this.props;
    const { selectedInvitationId } = this.state;

    const notification = selfInvitations.filter(n => n.id === selectedInvitationId)[0];
    const sharedProfile = notification.sharedProfile;

    const rejectedInvitations = selfInvitations
      .filter(n => n.id !== selectedInvitationId)
      .map(n => this.rejectInvitation(n.id));

    const accepted = this.acceptInvitation(selectedInvitationId);

    this.setState({ inProgress: true }, () => {
      Promise.all([...rejectedInvitations, accepted])
        .then(() => {
          return this.props.dispatch(userActions.requestUser(userId));
        })
        .then((user: UserModel) => {
          return this.props.dispatch(actionItemActions.requestActionItems(userId, user.data.profile_id));
        })
        .then(() => {
          return ProfileActions.requestAllProfiles();
        })
        .then(() => {
          return this.props.dispatch(ProfileActions.switchToProfile(sharedProfile.data));
        })
        .then(() => {
          this.setState({ inProgress: false });
          navigate(RouteNames.profileAccess());
        });
    });
  }

  render() {
    const { selfInvitations } = this.props;
    const { selectedInvitationId, inProgress } = this.state;

    const manyInvites = selfInvitations.length > 1;
    const descMsg = manyInvites ? 'acceptOwnProfile.multipleInvites.message' : 'acceptOwnProfile.singleInvite.message';
    const inviteCount = 1;

    return (
      <div>
        <ScrollAwareContext>
          <ScrollAwareAppNavStep
            accept={this.useProfileFromInvitation}
            submitDisabled={!selectedInvitationId}
            color="white"
          />

          <ContentLoader isContentLoaded={!inProgress} blockRenderUntilLoaded={false}>
          <ContentFrame>
            <ScrollAwareScroller>
              <AppHeaderScrollableText id="acceptOwnProfile.title.text" />

              <ContentWrapper>

                <GrayCard>
                  <CardSection
                    hasBorder={false}
                    className="font-footnote font-color-gsMedDark"
                  >
                    <FormattedMessage id={descMsg} />
                  </CardSection>
                </GrayCard>

                <ExternalCardTitle>
                  <FormattedMessage id="acceptOwnProfile.inviteList.label" values={{ digit1: inviteCount }} />
                </ExternalCardTitle>

                <Card>
                  {
                    selfInvitations.map(s => (
                      <OwnProfileInvitation
                        key={s.data.id}
                        notification={s}
                        isSelected={s.data.id === selectedInvitationId}
                        onClick={() => { this.setState({ selectedInvitationId: s.data.id }); }}
                      />
                    ))
                  }
                </Card>

                <CardButton
                  className="font-color-profile-accent"
                  onClick={this.createOwnProfile}
                >
                  <FormattedMessage id="acceptOwnProfile.decline.button.label" />
                </CardButton>

              </ContentWrapper>
            </ScrollAwareScroller>
          </ContentFrame>
            </ContentLoader>
        </ScrollAwareContext>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const userId = userSelectors.getLoggedInUser(state).data.id;
  const profileId = userSelectors.getMainProfileId(state);

  return {
    userId,
    profileId,
    userActionItems: actionItemSelectors.getUserActionItems(state, userId),
    selfInvitations: notificationSelectors.getAll(state).filter(n => NotificationModel.isSelfInvitation(n)).map(n => new SharedOwnerNotificationModel(n)),
  };
}

export default connect(mapStateToProps)(ViewAcceptOwnProfile);
