import React from 'react';

import { BasicListItem } from '../../../../components/List';
import FlexRow from '../../../../components/FlexRow';

interface GroupTitleProps {
  Icon?: any;
  icon?: any;
  text: any;
}

class GroupTitle extends React.Component<GroupTitleProps, {}> {

  render() {
    const { Icon, icon, text, children } = this.props;
    return (
      <BasicListItem className="util-public-share-card-frame-padding" style={{ marginBottom: 14, marginTop: 24 }}>
        <FlexRow style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <div className="font-title-3 font-color-gsMedDark" style={{ marginRight: 16 }}>{text}</div>
          {Icon && <Icon size="24" color="gsMedDark" />}
          {icon}
        </FlexRow>
        {children}
      </BasicListItem>
    );
  }
}

export default GroupTitle;
