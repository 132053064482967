import React from 'react';
import { FormattedMessage } from 'react-intl';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PreformattedText from '../../../components/PreformattedText';
import { Button } from '../../../components/buttons/Buttons';

import './UnsubscribeSuccess.styles.scss';

const UnsubscribeSuccess = () => (
  <div className="cmp-unsubscribe-success bkgd-color-gsLightest">
    <Header />
    <div className="content util-inset-listitem-frame-padding">
      <div className="font-title-1 font-color-gsDark">
        <FormattedMessage id="unsubscribeSuccess.title.text" />
      </div>
      <PreformattedText textClassName="font-body" text="unsubscribeSuccess.message.text" />
      <Button
        action="backpack"
        className="font-body"
        size="wide"
        onClick={() => { window.location.href = '/'; }}
      >
        <FormattedMessage id="unsubscribeSuccess.goToBackpack.button.label" />
      </Button>
    </div>
    <Footer />
  </div>
);

export default UnsubscribeSuccess;
