
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Card, Icons } from '../../../../bphComponents/bundle';
import RouteNames from '../../../appAuthorized/RouteNames';
import FlexRow from '../../../components/FlexRow';
import { useNavigate } from '../../../hooks/useNavigate';
import COLORS from '../../../styles/Colors';
import { useTrackableReminders } from '../../../hooks/useTrackableReminders';
import { useUIState } from '../../../hooks/useUIState';
import { PermissionsRequiredModal, requiredPermissionTypes } from '../../../components/modal/WarningModal';

interface Props {
  isEnabled: boolean;
  onClick(): void;
  trackableTermName: string;
}
const TrackableReminders: React.FC<Props> = (props) => {
  const { trackableTermName, isEnabled, onClick } = props;

  return (
    <>
    <Card.CardContent
      onClick={onClick}
      hasBottomBorder={true}
    >
      <FlexRow>

        <div className="font-body font-color-gsDark">
          <FormattedMessage id="heOccList.trackingReminder.button.label" />
        </div>

        <FlexRow className="font-footnote font-color-gsMiddle">
          <FormattedMessage id={isEnabled ? 'heOccList.trackingReminder.on.label' : 'heOccList.trackingReminder.off.label'} />
          <Icons.IconDivotArrowRight.Medium color={COLORS.gsMedDark} />
        </FlexRow>
      </FlexRow>
    </Card.CardContent>
    <Card.CardContent>
      <div className="font-footnote font-color-gsDark">
        <FormattedMessage id={isEnabled ? 'heOccList.trackingReminder.on.message' : 'heOccList.trackingReminder.off.message'} values={{ string1: trackableTermName }} />
      </div>
    </Card.CardContent>
    </>
  );
};

interface ObsProps {
  trackableId: number;
  trackable: any;
  trackableTermLookup: any;
  intl?: any;
}
export const CreateObservationNotifications: React.FC<ObsProps> = injectIntl((props) => {
  const { trackableId, trackableTermLookup, trackable, intl } = props;
  if (!IS_ANDROID_BUILD) { return null; }
  const [{ reminderData }, reminderActions] = useTrackableReminders(trackableId, intl);
  const [, navActions] = useNavigate();
  const [, uiActions] = useUIState();
  const trackableTerm = trackableTermLookup[trackable.trackable_term_id];
  const trackableTermName = trackableTerm ? trackableTerm.name : '';
  const goNext = () => {
    navActions.goTo(RouteNames.trackableReminderSettings(trackableId));
  };

  return (
    <TrackableReminders
      isEnabled={!reminderData.isNew}
      trackableTermName={trackableTermName}
      onClick={() => {
        reminderActions.checkAndRequestPermission()
        .then(() => {
          if (reminderData.isNew) {
            goNext();
          } else {
            navActions.goTo(RouteNames.trackableReminderSettings(trackableId));
          }
        })
        .catch(() => {
          uiActions.pushSystemModal(
            <PermissionsRequiredModal
              permissions={[requiredPermissionTypes.notifications]}
              onDismiss={() => {  }}
            />,
          );
        });
      }}
    />
  );
});

export default TrackableReminders;
