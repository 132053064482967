import React from 'react';

import { CardRowUI } from '../../../../bphComponents/bundle';
import { ContactSummaryText } from '../../../components/ContactSummaryText';
import { ContactModelType } from '../../../../models/ContactModel';

interface ContactSummaryProps {
  onClick(): void;
  data: ContactModelType;
}

const ContactSummary: React.FC<ContactSummaryProps> = (props) => {
  const { onClick, data } = props;

  return (
    <CardRowUI.Summary
      onClick={onClick}
      hasBottomBorder={true}
      text={[
        data.name,
        <ContactSummaryText key={1} data={data} isSingleLine={true} />,
      ]}
    />
  );
};

export default ContactSummary;
