
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { navigate } from '../../../appAuthorized/redux/NavigationActions';

import { ScrollAwareContext, ScrollAwareScroller } from '../../../components/ScrollAware';
import AppNavTopLevel from '../../../components/appNav/AppNavTopLevel';
import ContentFrame from '../../../applicationFrame/ContentFrame';
import { AppHeaderScrollableText } from '../../../components/appNav/AppHeaderScrollable';
import TopLevelAddButton from '../../../appAuthorized/presenters/TopLevelAddButton';
import ContentWrapper from '../../../components/ContentWrapper';

import ContentLoader from '../../../components/ContentLoader';

import ZeroStateContacts from './ZeroStateContacts';

import { Card, CardRowUI } from '../../../../bphComponents/bundle';
import ContactSummary from './ContactSummary';
import RouteNames from '../../../appAuthorized/RouteNames';
import { useUIState } from '../../../hooks/useUIState';
import { useActiveProfile } from '../../../hooks/useProfile';
import { useContactsForActiveProfile } from '../../../hooks/useContacts';

interface ContactListProps {
  data: any[];
}
const ContactList: React.FC<ContactListProps> = (props) => {
  const { data } = props;
  if (data.length === 0) { return null; }

  return (
    <Card.CardShape>
      {
        data.map(d => (
          <ContactSummary key={d.id} data={d} onClick={() => { navigate(RouteNames.contact(d.id)); }} />
        ))
      }
    </Card.CardShape>
  );
};
const ProviderContactList: React.FC<ContactListProps> = (props) => {
  const { data } = props;
  if (data.length === 0) { return null; }

  const [showPast, setShowPast] = React.useState(false);

  const currentContacts = data.filter(p => p.current);
  const pastContacts = data.filter(p => !p.current);
  const hasPast = pastContacts.length > 0;

  return (
    <div>
      <Card.ExternalCardTitle>
        <FormattedMessage id="contacts.providerList.label" />
      </Card.ExternalCardTitle>

      <Card.CardShape>
        {
          currentContacts.map(d => (
            <ContactSummary key={d.id} data={d} onClick={() => { navigate(RouteNames.contact(d.id)); }} />
          ))
        }

        {
          hasPast && (
            <>
            <CardRowUI.TextAction className="font-caption-1 font-color-profile-accent" onClick={() => { setShowPast(!showPast); }} hasBottomBorder={true}>
              <Card.CollapsibleControl isExpanded={showPast}>
                <FormattedMessage id="contacts.providerList.label">
                  {
                    (string1: string) => (
                      <FormattedMessage id={showPast ? 'bpAll.hidePast.button.label' : 'bpAll.showPast.button.label'} values={{ string1: string1.toLowerCase() }} />
                    )
                  }
                </FormattedMessage>
              </Card.CollapsibleControl>
            </CardRowUI.TextAction>
            {
              showPast && pastContacts.map(d => (
                <ContactSummary key={d.id} data={d} onClick={() => { navigate(RouteNames.contact(d.id)); }} />
              ))
            }
            </>
          )
        }
      </Card.CardShape>
    </div>
  );
};

const ViewContactList: React.FC = (props) => {
  const [{ isMainProfile }] = useUIState();
  const [{ greetingName }] = useActiveProfile();
  const [{ contactList, isLoaded }] = useContactsForActiveProfile();

  const hasContacts = contactList.length > 0;
  const sortedContactList = contactList.sort((a, b) => a.name.localeCompare(b.name));
  const contacts = sortedContactList.filter(c => c.type === 'contact');
  const providerContacts = sortedContactList.filter(c => c.type === 'provider');

  const titlePhrase = isMainProfile ? {
    id: 'contacts.user.title',
  } : {
    id: 'contacts.notUser.title',
    values: {
      string1: greetingName,
    },
  };

  return (
    <div>
      <ScrollAwareContext>
        <AppNavTopLevel hasDropShadow />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText {...titlePhrase} />

            <ContentWrapper>
              <ContentLoader isContentLoaded={isLoaded} blockRenderUntilLoaded={false} isFixed={true}>
                {
                  hasContacts
                    ? (
                      <>
                        <ContactList data={contacts} />
                        <ProviderContactList data={providerContacts} />
                      </>
                    ) : <ZeroStateContacts />
                }
              </ContentLoader>
            </ContentWrapper>
          </ScrollAwareScroller>

          <TopLevelAddButton.MyContacts />

        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
};

export default ViewContactList;
