import React from 'react';
import classnames from 'classnames';

import { SelectableCardRowWithProfileTheme } from '../SelectableRow';
import ExpandToggleControl from '../ExpandToggleControl';

interface CardProps {
  className?: string;
  children: any;
  hasDropshadow?: boolean;
  hasMargins?: boolean;
  style?: object;
  onClick?(): void;
}
class Card extends React.Component<CardProps, {}> {

  static defaultProps: Partial<CardProps> = {
    className: 'bkgd-color-white',
    hasDropshadow: true,
    hasMargins: true,
  };

  render() {
    const { children, className, hasDropshadow, hasMargins, ...otherProps } = this.props;
    return (
      <div className={classnames('cmp-card', className, { dropshadow: hasDropshadow, margins: hasMargins })} {...otherProps}>
        {children}
      </div>
    );
  }
}

const defaultToHasMargins = (hasMargins) => {
  return (hasMargins === true || hasMargins === false) ? hasMargins : true;
};

export const GrayCard = (props) => (
  <div className={classnames('cmp-card bkgd-color-gsLight', props.className, {  margins: defaultToHasMargins(props.hasMargins) })} style={props.style}>
    {props.children}
  </div>
);

export const MiddleGrayCard = (props) => (
  <div className={classnames('cmp-card bkgd-color-gsMiddle', props.className, {  margins: defaultToHasMargins(props.hasMargins) })} style={props.style}>
    {props.children}
  </div>
);

export const ProfileAccentCard = ({ className = '', hasMargins = true, ...props }) => (
  <CardButton className={classnames('bkgd-color-profile-accent', className, {  margins: defaultToHasMargins(hasMargins) })} {...props} />
);

export const EmptyCard = (props) => (
  <div className={classnames('cmp-emptycard', props.className, {  margins: defaultToHasMargins(props.hasMargins) })} style={props.style}>
    {props.children}
  </div>
);

interface CardSectionProps {
  className?: string;
  hasBorder: boolean;
  onClick?(): void;
  style?: object;
  isBottomCard?: boolean;
  isTopCard?: boolean;
}

export class CardSection extends React.Component<CardSectionProps, {}> {
  render() {
    const { children, className, hasBorder, isBottomCard, isTopCard, onClick, style } = this.props;

    return (
      <section className={classnames('cmp-cardsection util-application-frame-forcards-padding', className, { 'has-border': hasBorder, 'is-clickable': !!onClick, 'bottom-radius': isBottomCard, 'top-radius': isTopCard })} onClick={onClick} style={style}>
        {children}
      </section>
    );
  }
}

const SelectableRow = (props) => {
  const { isSelected, children, onClick, isNested, hideBorder = false } = props;

  return (
    <SelectableCardRowWithProfileTheme
      className={classnames('cmp-card-selectablerow font-body color-gsDarkest', { nested: isNested })}
      isSelected={isSelected}
      onClick={onClick}
      hideBorder={hideBorder}
    >
      {children}
    </SelectableCardRowWithProfileTheme>
  );
};

const ExpandableRow = (props) => {
  const { children, expanded, onToggle, className } = props;
  return (
    <div className={classnames('cmp-cardrow-expandable', className)} onClick={onToggle}>
      <div className="font-body color-gsDarkest">
        {children}
      </div>

      <ExpandToggleControl isExpanded={expanded} />
    </div>
  );
};

interface ExpandableListState {
  expanded: boolean;
}

interface ExpandableListProps {
  isSelected: boolean;
  onSelectHeader?(): void;
  isHeaderSelectable: boolean;
  header: any;
  forceExpanded: boolean;
}

class ExpandableList extends React.Component<ExpandableListProps, ExpandableListState> {

  state: ExpandableListState = {
    expanded: this.props.forceExpanded,
  };

  UNSAFE_componentWillReceiveProps(nextProps: ExpandableListProps) {
    // this.setState({ expanded: nextProps.forceExpanded });
  }

  toggleCollapse = () => {
    this.setState({ expanded: !this.state.expanded });
    if (this.props.onSelectHeader) { this.props.onSelectHeader(); }
  }

  render() {
    const { expanded } = this.state;
    const { isSelected, children, isHeaderSelectable, header } = this.props;

    return (
      <div className={classnames('cmp-cardlist-expandable', { expanded })}>
        {
          isHeaderSelectable ? (
            <SelectableRow
              isSelected={isSelected}
              onClick={this.toggleCollapse}
              isNested={false}
            >
              {header}
            </SelectableRow>
          ) : (
            <ExpandableRow
              expanded={expanded}
              onToggle={this.toggleCollapse}
              className="util-application-card-padding util-row-vertical-padding util-list-bottom-border-dark"
            >
              {header}
            </ExpandableRow>
          )
        }

        <div className="body">
          {children}
        </div>
      </div>
    );
  }
}

const LabelRow = (props) => {
  const { children, onClick } = props;

  return (
    <CardSection className="font-body font-color-profile" hasBorder onClick={onClick}>
      {children}
    </CardSection>
  );
};

export const ExternalCardTitle = (props) => (
  <EmptyCard className="font-footnote font-color-gsMedDark" style={{ marginTop: 24 }}>
    {props.children}
  </EmptyCard>
);

export const CardButton = ({ className = '', ...props }) => (
  <button className={classnames('cmp-card-button font-body', className)} {...props} />
);

export const CardDestructiveButton = (props) => (
  <CardButton className="font-body font-color-csError" onClick={props.onClick}>
    {props.children}
  </CardButton>
);

export const CardList = {
  SelectableRow,
  ExpandableRow,
  LabelRow,
  ExpandableList,
};

export default Card;
