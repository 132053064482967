
import { useQuery, queryCache } from 'react-query';
import { useDispatch } from 'react-redux';

import MedicalJewelryService from '../../apiServices/MedicalJewelryService';
import { useUIState } from './useUIState';
import { requestShareCards } from './useShareCard';

const STALE_TIME = 100000;
const INITIAL_STATE = {
  list: [],
  isLoaded: false,
};

function requestMedicalJewelryProviders(queryKey, profileId) {
  return new Promise((res, rej) => {
    MedicalJewelryService.getAllProviders(profileId, (apiError, payload) => {
      if (apiError) { rej(apiError); return; }

      res({
        list: payload,
        isLoaded: true,
      });
    });
  });
}

export function useMedicalJewelry() {
  const [{ activeProfileId }] = useUIState();
  const dispatch = useDispatch();

  const queryData: any = useQuery(['medicalJewelryProviders', activeProfileId], requestMedicalJewelryProviders, { staleTime: STALE_TIME });
  const data = queryData.data || INITIAL_STATE;
  const medicalJewelryProviderList = data.list;

  return [
    {
      medicalJewelryProviderList,
      supportedJewelryList: medicalJewelryProviderList.filter(m => m.supported_country),
      unupportedJewelryList: medicalJewelryProviderList.filter(m => !m.supported_country),
      isLoaded: data.isLoaded,
    },
    {
      apiAddToShareCard: (shareCardId, orderValue, idValue) => {
        return new Promise((res, rej) => {
          MedicalJewelryService.addToShareCard(activeProfileId, shareCardId, orderValue, idValue, (apiError, data) => {
            if (apiError) {
              rej(apiError);
            } else {
              requestShareCards(dispatch)('', activeProfileId).then((qData) => {
                queryCache.setQueryData(['shareCards' , activeProfileId], qData);
                res();
              });
            }
          });
        });
      },
    },
  ];
}
