import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ContentPanel from '../ContentPanel';
import List, { BasicListItem } from '../../../../components/List';
import { Markdown } from '../../../../components/Markdown';
import { ClampedText, NewLineMore } from '../../../../components/ClampedText';
import GroupContentModel, { GroupContentDataInterface, sortGroupContent } from '../../../../../models/GroupContentModel';
import { ShareCardColorModel } from '../../../../appAuthorized/dataTransformComponents/ColorShareCard';
import { getLocalText, LocalText } from '../../../../../utilities/localizedUtil';

import './ShareCardGroupContents.scss';
import { GroupOrganizationName } from '../../../../features/GroupTitle/GroupOrganizationName';

const SCREEN_WIDTH_THRESHOLD = 375;
const WIDE_SCREEN_LINES_LIMIT = 2;
const NARROW_SCREEN_LINES_LIMIT = 6;

interface Props {
  languageCode: string;
  groupContents: GroupContentDataInterface[];
  groups: any[];
  colorModel: ShareCardColorModel;
}

interface Data {
  default: string;
  i18n: any;
}

class ShareCardGroupContents extends Component<Props> {
  getLocalText(data: Data) {
    const { languageCode } = this.props;
    return getLocalText(data, languageCode) || data.default;
  }

  getGroup(groupId: number) {
    const { groups } = this.props;

    return groups.find(group => group.id === groupId);
  }

  renderGroupTitle(groupId: number) {
    const group = this.getGroup(groupId);

    return (
      <div className="group-name font-subhead font-color-gsMedDark">
        <GroupOrganizationName group={group} disablePlaceholder={true}>
          {
            (groupName) => (
              <FormattedMessage id="shareCard.orgName.providedBy.label" values={{ string1: <LocalText text={groupName} /> }} />
            )
          }
        </GroupOrganizationName>
      </div>
    );
  }

  renderContent(content: GroupContentModel) {
    const { colorModel, languageCode } = this.props;
    const linesLimit = window.innerWidth > SCREEN_WIDTH_THRESHOLD ? WIDE_SCREEN_LINES_LIMIT : NARROW_SCREEN_LINES_LIMIT;

    return (
      <BasicListItem key={content.data.id} className="util-inset-listitem-frame-padding">
        <div className="title-group-name">
          <div className="title font-headline">
            {content.title.get(languageCode)}
          </div>
        </div>
        <ClampedText
          lines={linesLimit}
          newLineMore={(props) => <NewLineMore {...props} color={colorModel.darkAccentColor()}/>}
        >
          <Markdown id="group-content" text={content.body.get(languageCode)} color={colorModel.darkAccentColor()} />
        </ClampedText>


        <div className="title-group-name">
          {this.renderGroupTitle(content.groupId)}
        </div>
      </BasicListItem>
    );
  }

  renderList() {
    const { groupContents, languageCode } = this.props;

    return groupContents
      .map((data: GroupContentDataInterface) => new GroupContentModel(data))
      .sort(sortGroupContent(languageCode))
      .map(content => this.renderContent(content));
  }

  render() {
    return (
      <div className="cmp-sharecard-group-contents">
        <ContentPanel hasPadding={false}>
          <List hasBorder paddingSize="large">
            {this.renderList()}
          </List>
        </ContentPanel>
      </div>
    );
  }
}

export default ShareCardGroupContents;
