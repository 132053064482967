import React from 'react';
import { FormattedMessage } from 'react-intl';

import HealthEntryNameText from '../../../components/HealthEntryNameText';
import HealthEntrySubTitleText from '../../../components/HealthEntrySubTitleText';

import ShareCardIncludedTypeNames from '../../../../constants/ShareCardIncludedTypeNames';
import { isGeneticResultCategory, getHealthEntryCategoryData } from '../../../../constants/HealthEntryCategoryNames';
import GeneAttributesListResultText from '../../../components/GeneAttributesListResultText';

import {
  listDateFormatHeValue,
} from '../../../../models/HealthEntryTextFormatting';
import { useHealthEntriesForActiveProfile, useHealthEntryValueForActiveProfile } from '../../../hooks/useHealthEntry';
import MedliAmountText from '../../../components/MedliAmountText';
import GeneVariantResultText from '../../../components/GeneVariantResultText';
import { useActiveUser } from '../../../hooks/useUser';
import { generalHealthEntry, generalHealthEntryValue } from '../../../../models/HealthEntryCompose';
import { ObservationModel, TrackableModel } from '../../../../apiServices/apiModels/Trackable';
import { DateTimeSummary } from '../../../components/DateTimeSummary';
import HealthEntryMetaIcons from './HealthEntryMetaIcons';

const hasComments = (healthEntry) => {
  if (healthEntry.values && healthEntry.values.hasValues()) {
    return healthEntry.values.hasAnyComments();
  } else {
    return healthEntry.comments.hasComments();
  }
};

const heIsFlagged = (healthEntry) => {
  if (healthEntry.values && healthEntry.values.hasValues()) {
    return healthEntry.values.mostRecent().flag.isFlagged();
  } else {
    return healthEntry.flag.isFlagged();
  }
};

const OccurrenceSummaryTemplate = (props) => {
  const { isFlagged, title, additionalTitle, subTitle, subTitle2, hasComments } = props;

  return (
    <div className="">
      <div className="font-body" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
        {title}

        <div style={{ marginRight: 4 }}>
          <HealthEntryMetaIcons
            hasAttachments={false}
            hasComments={hasComments}
            isFlagged={isFlagged}
          />
        </div>
      </div>

      {additionalTitle}

      <div className="font-caption-1">
        {subTitle}
      </div>
      {
        subTitle2
        ? (
          <div className="font-caption-1">
            {subTitle2}
          </div>
        ) : null
      }
    </div>
  );
};

const GeneAttributesSummary = (props) => {
  const { healthEntry, languageCode } = props;
  const { category, values } = healthEntry;

  if (!values) return null;
  if (!isGeneticResultCategory(category)) return null;

  const mostRecentValue = values.mostRecent();

  if (!mostRecentValue) return null;

  const { geneVariantResult } = mostRecentValue;

  return (
    <GeneAttributesListResultText
      className="font-footnote cmp-hesummary-gene-attributes"
      geneVariantResult={geneVariantResult}
      languageCode={languageCode}
    />
  );
};

interface FileViewHealthEntrySummaryProps {
  healthEntryData: any;
  languageCode: string;
}
export const FileViewHealthEntrySummary: React.FC<FileViewHealthEntrySummaryProps> = (props) => {
  const { healthEntryData, languageCode } = props;

  const [, heActions] = useHealthEntriesForActiveProfile();
  const healthEntry = heActions.instantiateHealthEntry(healthEntryData, languageCode);

  const showCategory = false;
  const categoryData  = showCategory ? getHealthEntryCategoryData(healthEntry.data()) : null;
  // if (!categoryData) { return null; }

  const showHeValues = healthEntry.values && healthEntry.values.count() !== 1;
  const olderHEValuesCount = (showHeValues && healthEntry.values.count() > 0) ? healthEntry.values.count() - 1 : 0;
  const olderCountPhrase = olderHEValuesCount > 0 ? 'healthEntry.value.count.text' : 'healthEntry.occurrenceCount.message.text';

  return (
    <OccurrenceSummaryTemplate
      title={
        <HealthEntryNameText healthEntry={healthEntry} includedValues={ShareCardIncludedTypeNames.latest} className="util-text-truncate" />
      }
      additionalTitle={<GeneAttributesSummary healthEntry={healthEntry} languageCode={languageCode} />}
      subTitle={(
        <>
          <HealthEntrySubTitleText
            healthEntry={healthEntry}
            showAttachments={false}
          />
          <div>
            {
              showHeValues
              ? <FormattedMessage id={olderCountPhrase} values={{ digit1: olderHEValuesCount }} />
              : null
            }
          </div>
        </>
      )}
      subTitle2={(
        categoryData
        ? <FormattedMessage {...categoryData.titlePhrase} />
        : null
      )}
      isFlagged={heIsFlagged(healthEntry)}
      hasComments={hasComments(healthEntry)}
    />
  );
};

export const FileViewHealthEntryValueSummary = (props) => {
  const { heValueId, languageCode } = props;
  const [, hevActions] = useHealthEntryValueForActiveProfile(heValueId);
  const healthEntry = hevActions.instantiateHealthEntry();
  const healthEntryValue = hevActions.instantiateHealthEntryValue();
  const amount = healthEntryValue.amount ? healthEntryValue.amount.get() : null;
  const geneVariantResult = healthEntryValue.geneVariantResult ? healthEntryValue.geneVariantResult.get() : null;
  const description = healthEntryValue.description ? healthEntryValue.description.get() : null;
  const flagged = healthEntryValue.flag.isFlagged();
  const hasAmount = Boolean(amount);

  return (
    <OccurrenceSummaryTemplate
      title={
        <div>
          <HealthEntryNameText healthEntry={healthEntry} includedValues={ShareCardIncludedTypeNames.all} className="util-text-truncate" />
          {
            hasAmount ? (
              <>
                {': '}
                <MedliAmountText amount={amount} description={description} />
              </>
            ) : null
          }
          {
            geneVariantResult ? (
              <>
                {': '}
                <GeneVariantResultText geneVariantResult={geneVariantResult} />
              </>
            ) : null
          }
        </div>
      }
      additionalTitle={<GeneAttributesSummary healthEntry={healthEntryValue} languageCode={languageCode} />}
      subTitle={(
        <div style={{ display: 'flex' }}>
          {listDateFormatHeValue(healthEntryValue)}
        </div>
      )}
      isFlagged={flagged}
      hasComments={hasComments(healthEntryValue)}
    />
  );
};

interface HealthEntrySummaryProps {
  healthEntryData: any;
  healthEntryValueData: any;
  languageCode: string;
}
export const SingleLineHealthEntrySummary: React.FC<HealthEntrySummaryProps> = (props) => {
  const { healthEntryData, healthEntryValueData, languageCode } = props;

  if (!healthEntryData) { return null; }

  const [{ preferredLanguageCode }] = useActiveUser();

  const category = healthEntryData.category;
  const healthEntry = generalHealthEntry({ ...healthEntryData }, languageCode);
  const healthEntryValue = healthEntryValueData && generalHealthEntryValue(category, (healthEntryValueData || {}));

  const amount = healthEntryValue && healthEntryValue.amount && healthEntryValue.amount.get();
  const geneVariantResult = healthEntryValue && healthEntryValue.geneVariantResult;

  return (
    <div>
      <HealthEntryNameText healthEntry={healthEntry} languageCode={preferredLanguageCode} includedValues={ShareCardIncludedTypeNames.all} />
      { amount && ': ' }
      { amount && <MedliAmountText amount={amount} description={healthEntryValue.description.get()} /> }
      { geneVariantResult && ': ' }
      { geneVariantResult && <GeneVariantResultText geneVariantResult={geneVariantResult.get()} /> }
    </div>
  );
};

export const SingleLineObservationSummary = (props) => {
  const { observation, trackable, languageCode, terms } = props;
  const o = new ObservationModel(observation, languageCode, terms);
  const t = new TrackableModel(trackable, null, terms);

  return (
    <div>
      {t.name}: {o.attributeSummary ? o.attributeSummary : <FormattedMessage id="ui-data.attributes.not-specified.text" /> }
    </div>
  );
};
export const SingleLineTrackableSummary = (props) => {
  const { trackable, terms, languageCode } = props;
  if (!trackable) { return null; }

  const t = new TrackableModel(trackable, null, terms);

  return t.observationCount === 1 ? (
    <SingleLineObservationSummary
      observation={t.latestObservation}
      trackable={trackable}
      terms={terms}
      languageCode={languageCode}
      showDate={false}
    />
  ) : (
    <div>
      {t.name} (<FormattedMessage id="healthEntry.occurrenceCount.message.text" values={{ digit1: t.observationCount }} />)
    </div>
  );
};

export const ObservationSummary = (props) => {
  const { observation, trackable, languageCode, terms, showDate } = props;
  const o = new ObservationModel(observation, languageCode, terms);
  const t = new TrackableModel(trackable, null, terms);

  return (
    <OccurrenceSummaryTemplate
      title={
        <span>
          {t.name}: {o.attributeSummary || <FormattedMessage id="ui-data.attributes.not-specified.text" />}
        </span>
      }
      additionalTitle={null}
      subTitle={(
        showDate
        ? (
          <div>
            <DateTimeSummary date={o.latestDate} />
          </div>
        ) : null
      )}
      subTitle2={null}
      isFlagged={o.isFlagged}
      hasComments={t.hasComments}
    />
  );
};

export const TrackableSummary = (props) => {
  const { trackable, terms } = props;
  if (!trackable) { return null; }

  const t = new TrackableModel(trackable, null, terms);

  return (
    <OccurrenceSummaryTemplate
      title={
        <span>
          {t.name} (<FormattedMessage id="healthEntry.occurrenceCount.message.text" values={{ digit1: t.observationCount }} />)
        </span>
      }
      additionalTitle={null}
      subTitle={null}
      subTitle2={null}
      isFlagged={t.isFlagged}
      hasComments={t.hasComments}
    />
  );
};

export const TrackableShareCardSummary = (props) => {
  const { trackable, terms, languageCode, includedTypeValue, showComments, showFlagged } = props;
  const t = new TrackableModel(trackable, null, terms);
  const o = t.latestObservation;

  const obs = new ObservationModel(o, languageCode, terms);

  return (includedTypeValue === ShareCardIncludedTypeNames.all || !o)
    ? (
      <div>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <div className="font-body font-color-gsDarkest" style={{ marginBottom: 4 }}>
            {t.name}
          </div>
          <div style={{ marginTop: 4 }}>
            <HealthEntryMetaIcons
              hasAttachments={false}
              hasComments={showComments && t.hasComments && t.isCommentsIncludedOnShareCard}
              isFlagged={showFlagged && (t.isFlagged || t.hasAnyFlagged)}
            />
          </div>
        </div>
        <div className="font-footnote font-color-gsDark">
          <FormattedMessage id="healthEntry.occurrenceCount.message.text" values={{ digit1: t.observationCount }} />
        </div>
      </div>
    ) : (
      <div>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <div className="font-body font-color-gsDarkest" style={{ marginBottom: 4 }}>
            {t.name}
          </div>
          <div style={{ marginTop: 4 }}>
            <HealthEntryMetaIcons
              hasAttachments={false}
              hasComments={showComments && t.hasComments && t.isCommentsIncludedOnShareCard}
              isFlagged={showFlagged && obs.isFlagged}
            />
          </div>
        </div>

        <div className="font-footnote font-color-gsDark" style={{ marginBottom: 4 }}>
          {obs.attributeSummary ? obs.attributeSummary : <FormattedMessage id="ui-data.attributes.not-specified.text" />}
        </div>

        <div className="font-footnote font-color-gsDark">
          <DateTimeSummary date={obs.latestDate} />
        </div>
      </div>
    );
};
