import React from 'react';
import { Spinner } from 'spin.js';

import CheckIconAnimated from './CheckIconAnimated';

const AppLoadingModal = (props) => {
  const { isComplete, onComplete } = props;

  const modalRef = React.useRef(null);
  const spinnerRef = React.useRef(null);
  const exitTimeoutRef = React.useRef(null);

  React.useEffect(() => {
    if (isComplete) {
      spinnerRef.current.stop();
      spinnerRef.current = null;
      exitTimeoutRef.current = setTimeout(() => {
        onComplete();
        exitTimeoutRef.current = null;
      }, 1200);
      return;
    }

    spinnerRef.current = new Spinner({
      width: 2,
      radius: 10,
      length: 7,
      color: 'white',
      top: '50%',
    });

    spinnerRef.current.spin(modalRef.current);

    return () => {
      if (exitTimeoutRef.current) {
        clearTimeout(exitTimeoutRef.current);
        exitTimeoutRef.current = null;
      }
    };
  }, [isComplete]);

  return (
    <div ref={modalRef} className="cmp-apploadingmodal">
      {
        isComplete
        ? <CheckIconAnimated />
        : null
      }
    </div>
  );
};

export default AppLoadingModal;
