import React from 'react';

import HEAttributeFooter from './components/HEAttributeFooter';
import RouteNames from '../../appAuthorized/RouteNames';

import ContentFrame from '../../applicationFrame/ContentFrame';
import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentLoader from '../../components/ContentLoader';

import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import ContentWrapper from '../../components/ContentWrapper';
import HealthEntryGeneAttributes from '../../../constants/HealthEntryGeneAttributes';
import { Card, CardRowUI } from '../../../bphComponents/bundle';
import HealthEntryGeneHelpText from './components/HealthEntryGeneHelpText';

import { useMedliGeneZygosityType } from '../../hooks/useMedli';
import { useHealthEntryAndValueForActiveProfile } from '../../hooks/useHealthEntry';
import { useNavigate } from '../../hooks/useNavigate';

const SCHEMA = HealthEntryGeneAttributes.find(g => g.name === 'zygosity');

const SelectableList = (props) => {
  const { items, onSelect, getId, isSelected, getValue } = props;

  return (
    <Card.CardShape>
      {
        items.map(zt => (
          <CardRowUI.SelectableRow
            key={getId(zt)}
            onClick={() => { onSelect(zt); }}
            selectedClassName="fill-color-profile-highlight"
            isSelected={isSelected(zt)}
            hasBottomBorder={true}
          >
            {getValue(zt)}
          </CardRowUI.SelectableRow>
        ))
      }
    </Card.CardShape>
  );
};

interface Props {
  match: any;
}
const GeneZyogisty: React.FC<Props> = (props) => {
  const healthEntryId = Number(props.match.params.healthEntryId);
  const healthEntryValueId = Number(props.match.params.valueId);

  const [hevState, hevActions] = useHealthEntryAndValueForActiveProfile(healthEntryId, healthEntryValueId);
  const sourceHevInstance = hevActions.instantiateHealthEntryValue();
  const pendingHevInstance = hevActions.instantiateEditingHealthEntryValue();
  const isDeletable = !!(sourceHevInstance.geneVariantResult && (sourceHevInstance.geneVariantResult.getZygosityTypeCui()));
  const isLoaded = hevState.isLoaded;

  const [, navActions] = useNavigate();
  const [hasChanges, setHasChanges] = React.useState(false);
  const [{ geneZygosityTypeList }] = useMedliGeneZygosityType();
  const selectedZygosityTypeCui = pendingHevInstance.geneVariantResult && pendingHevInstance.geneVariantResult.getZygosityTypeCui();

  return (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          cancel={() => {
            navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
            hevActions.resetEditing();
          }}
          submit={() => {
            if (!hasChanges) {
              navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
              return;
            }
            const newHevInstance = hevActions.instantiateEditingHealthEntryValue();
            hevActions.apiUpdate(newHevInstance.data())
              .then(() => {
                navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
                hevActions.resetEditing();
              });
          }}
          color="white"
        />

        <ContentLoader isContentLoaded={isLoaded} blockRenderUntilLoaded>
          <ContentFrame>
            <ScrollAwareScroller>

              <div>
                <AppHeaderScrollableText
                  id="healthEntry.geneZygosity.title.text"
                />
                <ContentWrapper>
                  <HealthEntryGeneHelpText
                    heading="healthEntry.geneZygosity.helpBlock.heading"
                    content="healthEntry.geneZygosity.helpBlock.body"
                  />

                  <SelectableList
                    items={geneZygosityTypeList}
                    getId={(zt) => zt.cui}
                    getValue={(zt) => zt.string}
                    onSelect={(zt) => {
                      setHasChanges(true);
                      const newHevInstance = hevActions.instantiateEditingHealthEntryValue();
                      newHevInstance.geneVariantResult.setZygosityType(zt.id);
                      newHevInstance.geneVariantResult.setZygosityTypeCui(zt.cui);
                      hevActions.updateEditing(newHevInstance.data());
                    }}
                    isSelected={(zt) => {
                      return zt.cui === selectedZygosityTypeCui;
                    }}
                  />

                  {
                    isDeletable && (
                      <HEAttributeFooter
                        isFlagged={null}
                        onDelete={() => {
                          const attributeNames = SCHEMA.values.map(v => v.geneVariantResultKey);
                          const newHevInstance = hevActions.instantiateEditingHealthEntryValue();
                          newHevInstance.geneVariantResult.clearAttributeValues(attributeNames);
                          hevActions.apiUpdate(newHevInstance.data())
                            .then(() => {
                              navActions.goBack(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
                            });
                        }}
                        onToggleFlag={null}
                      />
                    )
                  }
                </ContentWrapper>
              </div>
            </ScrollAwareScroller>
          </ContentFrame>
        </ContentLoader>
      </ScrollAwareContext>
    </div>
  );
};

export default GeneZyogisty;
