
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import _ from 'lodash';

import { ScrollAwareScroller, ScrollAwareContext, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentWrapper from '../../components/ContentWrapper';
import ContentFrame from '../../applicationFrame/ContentFrame';

import { useNavigate } from '../../hooks/useNavigate';
import { Buttons, Card, Icons } from '../../../bphComponents/bundle';
import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import RouteNames from '../../appAuthorized/RouteNames';
import DatePickerToggle from '../../components/DatePickerToggle';
import { fixedMediumDate, fixedShortDate, TimeFromNow } from '../../localization/LocalizedDate';
import DateUtil from '../../../utilities/DateUtil';
import COLORS from '../../styles/Colors';
import FlexRow from '../../components/FlexRow';
import { useUIEditReminder } from '../../hooks/useUIEdit';
import { Redirect } from 'react-router';
import { useActiveUser } from '../../hooks/useUser';

interface Props {
  match: any;
  intl: any;
}
const TrackableReminderDuration: React.FC<Props> = (props) => {
  const trackableId = Number(props.match.params.trackableId);

  const dropdownRef = React.useRef(null);
  const [, navActions] = useNavigate();
  const [{ editingReminder, isLoaded }, editReminderActions] = useUIEditReminder();
  const [reminderState, setReminderState] = React.useState({ ...editingReminder });
  const [{ preferredLanguageCode }] = useActiveUser();

  const [isMounted, setIsMounted] = React.useState(false);

  const earliestDateLimit = DateUtil.today();
  const latestDateLimit = DateUtil.add(earliestDateLimit, 100, 'year');
  const hasDuration = reminderState.dayCount > 0;
  const endDate = DateUtil.getDateFromDaysAway(reminderState.startDate, reminderState.dayCount);
  const endDateText = fixedMediumDate(endDate, 'P1D', preferredLanguageCode);

  if (!isLoaded) {
    return <Redirect to={RouteNames.trackableReminderSettings(trackableId)} />;
  }

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          goBack={() => {
            navActions.goBackTo(RouteNames.trackableReminderSettings(trackableId));
          }}
          submit={() => {
            editReminderActions.updateReminder(reminderState);
            navActions.goBackTo(RouteNames.trackableReminderSettings(trackableId));
          }}
          color="white"
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText id="heReminderDuration.title.text" />

            <ContentWrapper>
              <Card.CardShape>
                <Card.CardContent
                  hasBottomBorder={true}
                >
                  <DatePickerToggle
                    label="currentDates.startDate.field.label"
                    date={reminderState.startDate}
                    precision="P1D"
                    name="start_date"
                    onChange={(v) => {
                      setReminderState({
                        ...reminderState,
                        startDate: v.date,
                      });
                    }}
                    isFuzzy={false}
                    hasToday
                    allowNull={false}
                    initialFocus={false}
                    onFocus={null}
                    onCancel={null}
                    earliestDateLimit={earliestDateLimit}
                    latestDateLimit={latestDateLimit}
                    warningHint={null}
                    earliestDateWarningLimit={null}
                    regularHint={(
                      <TimeFromNow
                        dateData={{
                          date: reminderState.startDate,
                          precision: 'P1D',
                        }}
                      />
                    )}
                  />
                </Card.CardContent>
              </Card.CardShape>

              <Card.CardShape style={{ position: 'relative' }}>
                {
                  hasDuration ? (
                    <label htmlFor="durationDays">
                      <Card.CardContent>
                        <FlexRow>
                          <div className="font-body font-color-gsDark">
                            <FormattedMessage id="heReminderDuration.days.field.message" values={{ digit1: '__XXX__' }}>
                              {
                                (msg) => {
                                  const parts = msg.split(' NaN ');

                                  return parts.length > 1
                                    ? (
                                      <span>
                                        {parts[0]}<span style={{ fontWeight: 'bold' }}> <FormattedNumber value={reminderState.dayCount} /> </span>{parts[1]}
                                      </span>
                                    ) : msg;
                                }
                              }
                            </FormattedMessage>
                          </div>
                          <button
                            onClick={() => {
                              setReminderState({
                                ...reminderState,
                                dayCount: 0,
                              });
                            }}
                            style={{  }}
                          >
                            <Icons.IconRemoveCircle.Small color={COLORS.csHotRed} />
                          </button>
                        </FlexRow>
                        <div className="font-footnote font-color-gsDark" style={{ marginTop: 4 }}>
                          <FormattedMessage id="heReminder.endDate.field.message" values={{ string1: endDateText }} />
                        </div>
                        <select
                          name="durationDays"
                          ref={dropdownRef}
                          id="durationDays"
                          style={{
                            position: 'absolute',
                            height: '100%',
                            width: '90%',
                            left: 0,
                            top: 0,
                            opacity: 0,
                          }}
                          onChange={(e) => {
                            setReminderState({
                              ...reminderState,
                              dayCount: Number(e.target.value),
                            });
                          }}
                        >
                          {
                            _.times(365).map(v => (
                              <option key={v} value={v + 1}>{v + 1}</option>
                            ))
                          }
                        </select>
                      </Card.CardContent>
                    </label>
                  ) : (
                    <Card.CardContent>
                      <Buttons.InlineAddButton
                        onClick={() => {
                          setReminderState({
                            ...reminderState,
                            dayCount: 1,
                          });
                        }}
                        className="stroke-color-profile-accent"
                        color=""
                      >
                        <span className="font-body font-color-profile-accent">
                          <FormattedMessage id="heReminderDuration.endDate.button.label" />
                        </span>
                      </Buttons.InlineAddButton>

                      <div className="font-footnote font-color-gsMedDark" style={{ marginTop: 4, marginLeft: 36 }}>
                        <FormattedMessage id="heReminderDuration.endDate.message" />
                      </div>
                    </Card.CardContent>
                  )
                }

              </Card.CardShape>
            </ContentWrapper>
          </ScrollAwareScroller>
        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
};

export default TrackableReminderDuration;
