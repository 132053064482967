import * as _ from 'lodash';

import { selectors as localeSelectors } from './ducks/locale.js';
import { selectors as notificationSelectors } from './ducks/notifications.js';
import { selectors as ownersSelectors } from './ducks/owners.js';
import { selectors as sessionSelectors } from './ducks/session.js';
import { selectors as shareCardSelectors } from './ducks/shareCard.js';
import { selectors as systemNotificationSelectors } from './ducks/systemNotification.js';
import { selectors as userSelectorsOriginal } from './ducks/user.js';
import { selectors as listSelectors } from './ducks/lists/lists';

const userSelectors = _.assign({}, userSelectorsOriginal, {
  getPreferredLocale: state => {
    const localeId = userSelectorsOriginal.getLocaleId(state);
    return listSelectors.getLocale(localeId, state);
  },
  getPreferredLanguageCode: state => {
    const localeId = userSelectorsOriginal.getLocaleId(state);
    const locale = listSelectors.getLocale(localeId, state);
    return locale.language_code || 'en';
  },
  getDefaultMedliLocale: state => {
    const localeId = userSelectors.getLocaleId(state);
    const locale = listSelectors.getLocale(localeId, state);
    const medliLocaleId = locale.default_medli_locale_id || 1;
    const medliLocale = listSelectors.getLocaleMedli(medliLocaleId, state);
    return medliLocale;
  },
  getDefaultMedliCode: state => {
    const localeId = userSelectors.getLocaleId(state);
    const locale = listSelectors.getLocale(localeId, state);
    const medliLocaleId = locale.default_medli_locale_id || 1;
    const medliLocale = listSelectors.getLocaleMedli(medliLocaleId, state);
    return medliLocale ? medliLocale.language_code : null;
  },
});

export {
  localeSelectors,
  notificationSelectors,
  ownersSelectors,
  sessionSelectors,
  shareCardSelectors,
  systemNotificationSelectors,

  userSelectors,
};
