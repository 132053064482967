import React from 'react';
import cx from 'classnames';

import { BirthDateWithAge } from '../../../../localization/LocalizedDate';
import ProfileModel from '../../../../../models/ProfileModel';
import { BodyMain, Title2, Heading } from '../../../../components/Text';
import ContentPanel from '../ContentPanel';
import DateUtil from '../../../../../utilities/DateUtil';
import LocalizedGender from '../../../../appAuthorized/presenters/LocalizedGender.redux';
import { LocalizedResidence } from '../../../../localization/LocalizedResidence';
import List, { BasicListItem } from '../../../../components/List';
import LocalizedRace from '../../../../appAuthorized/presenters/LocalizedRace.redux';
import LocalizedBloodType from '../../../../appAuthorized/presenters/LocalizedBloodType.redux';
import { FormattedMessage } from 'react-intl';

import './ShareCardProfileGroup.scss';
import ProfileAvatar from '../../../../components/ProfileAvatar';

interface ProfileData {
  given_names: string;
  family_names: string;
  preferred_name: string;
  date_of_birth: string;
  date_of_birth_precision: string;
  gender_id: number;
  other_gender: string;
  birth_sex_id: number;
  race: {
    race_id: number[];
    identity: string;
  };
  race_selection: string;
  blood_type_id: number;
  residence_country_id: number;
  residence_state_id: number;
  avatar_photo: string;
}

interface CardProfileItem {
  visible: boolean;
  render(): React.ReactFragment;
}

const Group = ({ items, spacing }: { items: CardProfileItem[], spacing: number }) => {
  const someVisible = items.some(i => i.visible);
  if (!someVisible) return null;

  let rendered = 0;

  return (
    <BasicListItem className="util-inset-listitem-frame-padding">
      {items.map((i, idx) => !i.visible ? null :
        <div
          key={idx}
          style={{ marginTop: rendered ++ > 0 ? spacing : 0 }}
        >
          {i.render()}
        </div>)
      }
    </BasicListItem>
  );
};

class ShareCardProfileGroup extends React.Component<{ profile: ProfileData }, {}> {
  render() {
    const data = this.props.profile;
    const profile = new ProfileModel(data);
    const hasAvatar = !!data.avatar_photo;

    const group1Items = [
      {
        visible: hasAvatar,
        render: () =>
          <div className="avatar">
            <ProfileAvatar profile={profile} size={56}/>
          </div>,
      },
      {
        visible: profile.fullName(),
        render: () =>
          <Title2 className="font-color-dark-gray">
            {profile.fullName()}
          </Title2>,
      },
      {
        visible: data.date_of_birth,
        render: () =>
          <BodyMain>
            <BirthDateWithAge
              profile={data}
              today={DateUtil.today()}
              precision={data.date_of_birth_precision}
            />
          </BodyMain>,
      },
      {
        visible: data.gender_id,
        render: () =>
          <BodyMain>
            <LocalizedGender profile={profile} showBorn={!!data.birth_sex_id} />
          </BodyMain>,
      },
      {
        visible: !data.gender_id && !!data.birth_sex_id,
        render: () =>
          <BodyMain>
            <LocalizedGender profile={profile} showBorn={true} onlyBorn={true} />
          </BodyMain>,
      },
      {
        visible: data.residence_country_id,
        render: () =>
          <BodyMain>
            <LocalizedResidence profile={profile} />
          </BodyMain>,
      },
    ];

    const group2Items = [
      {
        visible: data.race_selection !== undefined,
        render: () =>
          <>
            <div>
              <Heading className="font-color-dark-gray">
                <FormattedMessage id="profile.race.field.label"/>
              </Heading>
            </div>
            <div style={{ marginTop: 8 }}>
              <BodyMain>
                {profile.hasValidRace() ?
                  <LocalizedRace
                    profile={profile}
                    primaryClass="cmp-text-body-main"
                    secondaryClass="cmp-text-footnote race-secondary font-color-black"
                  />
                  :
                  <FormattedMessage id="ui-data.data.not-specified.text"/>
                }
              </BodyMain>
            </div>
          </>,
      },
      {
        visible: data.blood_type_id !== undefined,
        render: () =>
          <>
            <div>
              <Heading className="font-color-dark-gray">
                <FormattedMessage id="profile.bloodType.field.label"/>
              </Heading>
            </div>
            <div style={{ marginTop: 8 }}>
              <BodyMain>
                {profile.hasValidBloodType() ?
                  <LocalizedBloodType profile={profile}/>
                  :
                  <FormattedMessage id="ui-data.data.not-specified.text"/>
                }
              </BodyMain>
            </div>
          </>,
      },
    ];

    return (
      <div className={cx('cmp-sharecard-profile-group', { hasAvatar })}>
        <ContentPanel hasPadding={false}>
          <List hasBorder paddingSize="large">
            <Group items={group1Items} spacing={8}/>
            <Group items={group2Items} spacing={16}/>
          </List>
        </ContentPanel>
      </div>
    );
  }
}

export default ShareCardProfileGroup;
