import * as _ from 'lodash';

import ContactService from '../../../apiServices/ContactService';
import ContactModel, { ContactDetailType, ContactModelType } from '../../../models/ContactModel';

export const types = {
  UPDATE_EDITING: 'app/contacts/UPDATE_EDITING',
  CREATE_SUCCESS: 'app/contacts/CREATE_SUCCESS',
  UPDATE_SUCCESS: 'app/contacts/UPDATE_SUCCESS',
  REQUEST_ALL: 'app/contacts/REQUEST_ALL',
  REQUEST_ALL_FOR_PROFILE: 'app/contacts/REQUEST_ALL_FOR_PROFILE',
  RECEIVE_ALL: 'app/contacts/RECEIVE_ALL',
  RECEIVE_ALL_FOR_PROFILE: 'app/contacts/RECEIVE_ALL_FOR_PROFILE',
  DELETE: 'app/contacts/DELETE',
};

const initialState = {
  collection: {
    '-1': [],
  },
  lookup: {
    '-1': {},
  },
  editing: null,
};
interface ReduxAction {
  type: string;
  payload: any;
}

function collectionReducer(state: any = initialState, action: ReduxAction) {
  return { ...state, ...action.payload.collection };
}

export default function reducer(state = initialState, action: ReduxAction) {
  switch (action.type) {
    case types.RECEIVE_ALL_FOR_PROFILE:
    case types.RECEIVE_ALL:
      return _.assign({}, state, {
        collection: collectionReducer(state.collection, action),
        lookup: { ...state.lookup, ...action.payload.lookup },
      });
    case types.UPDATE_EDITING:
      return _.assign({}, state, {
        editing: action.payload,
      });
    case types.DELETE:
    case types.UPDATE_SUCCESS:
    case types.CREATE_SUCCESS:
    case types.REQUEST_ALL:
    case types.REQUEST_ALL_FOR_PROFILE:
    default:
      return state;
  }
}

function receiveContacts(profileId, payload) {
  const lookup = {};
  payload.forEach(p => { lookup[p.id] = p; });
  return {
    type: types.RECEIVE_ALL_FOR_PROFILE,
    payload: {
      collection: {
        [profileId]: payload,
      },
      lookup: {
        [profileId]: lookup,
      },
    },
  };
}

function receiveAllContacts(data) {
  const collection = {};
  const lookup = {};
  data.forEach(d => {
    collection[d.profile_id] = collection[d.profile_id] || [];
    collection[d.profile_id].push(d);
    lookup[d.profile_id] = collection[d.profile_id];
  });
  return {
    type: types.RECEIVE_ALL_FOR_PROFILE,
    payload: {
      collection,
      lookup,
    },
  };
}

function requestContactsForProfile(profileId) {
  return (dispatch) => {
    return ContactService.allContactsForProfile(profileId)
      .then(d => {
        dispatch(receiveContacts(profileId, d));
      });
  };
}

function requestAllContacts() {
  return (dispatch) => {
    return ContactService.allContacts()
      .then(d => {
        dispatch(receiveAllContacts(d));
      })
      .catch(() => {});
  };
}

function submitContactChanges(data) {
  return (dispatch, getState) => {
    return ContactService.updateContact(data)
      .then(() => {
        const contactId = data.id;
        const profileId = data.profile_id;
        const state = getState();
        const contacts = state.contacts.collection[profileId] || [];
        const index = contacts.indexOf(f => f.id !== contactId);
        if (index >= 0) {
          contacts[index] = data;
        } else {
          contacts.push(data);
        }
        dispatch(receiveContacts(profileId, contacts));
        return data;
      });
  };
}

function updateEditingContact(data) {
  return {
    type: types.UPDATE_EDITING,
    payload: data,
  };
}

function clearEditingChanges() {
  return {
    type: types.UPDATE_EDITING,
    payload: null,
  };
}

export const actions = {
  requestContactsForProfile,
  requestAllContacts,
  updateEditingContact,
  submitContactChanges,
  clearEditingChanges,
};

export const selectors = {
  getContact: (state, profileId, contactId) => {
    const data = state.contacts.collection[profileId] || [];
    return data.find(d => d.id === contactId);
  },
  getAllContacts: (state) => {
    return state.contacts.collection;
  },
  getContactCollectionForProfile: (state, profileId) => {
    return state.contacts.collection[profileId] || [];
  },
  getProviderContactCollectionForProfile: (state, profileId) => {
    return (state.contacts.collection[profileId] || []).filter(c => c.type === 'provider');
  },
  getContactLookupForProfile: (state, profileId) => {
    return state.contacts.lookup[profileId] || {};
  },
  getEditingContact: (state) => {
    return state.contacts.editing;
  },
  getIsEditingInProgress: (state) => {
    return !!state.contacts.editing;
  },
};
