
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ScrollAwareScroller, ScrollAwareContext, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentWrapper from '../../components/ContentWrapper';
import ContentFrame from '../../applicationFrame/ContentFrame';

import { useNavigate } from '../../hooks/useNavigate';
import { Card, CardRowUI } from '../../../bphComponents/bundle';
import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import RouteNames from '../../appAuthorized/RouteNames';
import { useTrackableReminders } from '../../hooks/useTrackableReminders';
import { useUIEditReminder } from '../../hooks/useUIEdit';
import { Redirect } from 'react-router';
import { REMINDER_DAYS } from '../../../models/DeviceNotifications';
import { useUIState } from '../../hooks/useUIState';

const TEMP_DAYS = REMINDER_DAYS.map((d, i) => ({ phraseKey: d, value: i }));
const SORTED_REMINDER_DAYS = [...TEMP_DAYS.slice(1), TEMP_DAYS[0]];

interface Props {
  match: any;
  intl: any;
}
const TrackableReminderFrequency: React.FC<Props> = injectIntl((props) => {
  const trackableId = Number(props.match.params.trackableId);
  const { intl } = props;

  const [, navActions] = useNavigate();
  const [, reminderActions] = useTrackableReminders(trackableId, intl);
  const [editReminderState, editReminderActions] = useUIEditReminder();
  const [reminderState, setReminderState] = React.useState({ ...editReminderState.editingReminder });
  const [, uiActions] = useUIState();

  const reminder = reminderActions.instantiate(reminderState);

  if (!editReminderState.isLoaded) {
    return <Redirect to={RouteNames.trackableReminderSettings(trackableId)} />;
  }

  return (

    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          goBack={() => {
            navActions.goBackTo(RouteNames.trackableReminderSettings(trackableId));
          }}
          submit={() => {
            if (!reminder.hasValidFrequency()) {
              uiActions.showHintNotification('error.frequency.noDaysSelected.message');
              return;
            }
            editReminderActions.updateReminder(reminderState);
            navActions.goBackTo(RouteNames.trackableReminderSettings(trackableId));
          }}
          color="white"
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText id="heReminderFrequency.title.text" />

            <ContentWrapper>
              <Card.ExternalCardTitle>
                <FormattedMessage id="heReminderFrequency.intro.message" />
              </Card.ExternalCardTitle>
              <Card.CardShape>
                <CardRowUI.SelectableRow
                  onClick={() => {
                    const tempReminder = reminderActions.instantiate(reminderState);
                    tempReminder.setDailyFrequency();
                    setReminderState(tempReminder.data);
                  }}
                  selectedColor=""
                  notSelectedColor=""
                  selectedClassName="fill-color-profile-highlight"
                  hasBottomBorder={true}
                  isSelected={reminder.isDailyFrequency}
                >
                  <FormattedMessage id="frequency.daily.field.label" />
                </CardRowUI.SelectableRow>
                <CardRowUI.SelectableRow
                  onClick={() => {
                    const tempReminder = reminderActions.instantiate(reminderState);
                    tempReminder.setSpecificDayFrequency();
                    setReminderState(tempReminder.data);
                  }}
                  selectedColor=""
                  notSelectedColor=""
                  selectedClassName="fill-color-profile-highlight"
                  isSelected={reminder.isSpecificDayFrequency}
                >
                  <FormattedMessage id="frequency.daysOfWeek.field.label" />
                </CardRowUI.SelectableRow>
              </Card.CardShape>

              {
                reminder.isSpecificDayFrequency ? (
                  <>
                  <Card.ExternalCardTitle>
                    <FormattedMessage id="frequency.daysOfWeek.field.label" />
                  </Card.ExternalCardTitle>
                  <Card.CardShape>
                    {
                      SORTED_REMINDER_DAYS.map(({ phraseKey, value }) => (
                        <CardRowUI.SelectableRow
                          key={value}
                          onClick={() => {
                            const tempReminder = reminderActions.instantiate(reminderState);
                            tempReminder.toogleWeekdayFrequency(value);
                            setReminderState(tempReminder.data);
                          }}
                          hasBottomBorder={true}
                          selectedColor=""
                          notSelectedColor=""
                          selectedClassName="fill-color-profile-highlight"
                          isSelected={reminder.isDayIncluded(value)}
                        >
                          <FormattedMessage id={phraseKey} />
                        </CardRowUI.SelectableRow>
                      ))
                    }
                  </Card.CardShape>
                  </>
                ) : null
              }
            </ContentWrapper>
          </ScrollAwareScroller>
        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
});

export default TrackableReminderFrequency;
