import React from 'react';
import * as _ from 'lodash';

import { eSurveyQuestionCategoryName } from '../../apiServices/SurveyService';
import SurveyQuestionResponseModel from '../../models/SurveyQuestionResponseModel';
import { useUIState } from './useUIState';

let scrollToInterval = null;

function checkHeight(classSelector, itemIndex, posName) {
  const scrollerItems = document.querySelectorAll(classSelector);
  const scrollerItem = scrollerItems[itemIndex];

  if (!scrollerItem) {
    clearInterval(scrollToInterval);
    scrollToInterval = null;
    return;
  }

  const scrollerItemParent = scrollerItem;
  let scrollPos = scrollerItemParent.getBoundingClientRect()[posName];
  const startTime = +new Date();

  return function () {
    const timeDiff = +new Date() - startTime;
    const newScrollPos = scrollerItemParent.getBoundingClientRect()[posName];
    if (scrollPos === newScrollPos || timeDiff > 100) {
      clearInterval(scrollToInterval);
      scrollToInterval = null;
      const isWide = window.innerWidth > 833;
      const scrollBorderItems = document.querySelectorAll('.cmp-survey-page-layout-sticky-border');
      const headerItem: any = scrollBorderItems[scrollBorderItems.length - 1];
      let headerBottom = 0;

      if (headerItem && isWide) {
        headerBottom = headerItem.getBoundingClientRect().top;
      } else if (headerItem) {
        headerBottom = Number(headerItem.style.top.split('px')[0]);
      }

      const top = newScrollPos - headerBottom - 5;
      window.scrollBy({
        left: 0,
        top,
        behavior: 'smooth',
      });
    } else {
      scrollPos = newScrollPos;
    }
  };
}

function scrollToSegment(segmentIndex, subQuestionIndex) {
  const classSelector = `.question-segment-${segmentIndex}`;
  scrollToInterval = setInterval(checkHeight(classSelector, subQuestionIndex, 'top'), 20);
}
function scrollToSubQuestion(segmentIndex, subQuestionIndex, idPrefix) {
  const classSelector = `.question-segment-${segmentIndex} .survey-question`;
  scrollToInterval = setInterval(checkHeight(classSelector, subQuestionIndex, 'top'), 20);
}
function scrollToFollowupQuestion(segmentIndex, subQuestionIndex, idPrefix) {
  const classSelector = `.question-segment-${segmentIndex} .survey-question > div > section`;
  scrollToInterval = setInterval(checkHeight(classSelector, subQuestionIndex, 'bottom'), 20);
}

function goPrevSegment(currentSegmentIndex, lastIndex) {
  scrollToSegment(currentSegmentIndex - 1, lastIndex);
}

export function goPrev(currentSegmentIndex, currentSubQuestionIndex) {
  scrollToSubQuestion(currentSegmentIndex, currentSubQuestionIndex - 1, null);
}

function goNextSegment(currentSegmentIndex) {
  scrollToSegment(currentSegmentIndex + 1, 0);
}

export function goNext(currentSegmentIndex, currentSubQuestionIndex) {
  scrollToSubQuestion(currentSegmentIndex, currentSubQuestionIndex + 1, null);
}

export function goNextFollowupQuestion(currentSegmentIndex, currentSubQuestionIndex) {
  scrollToFollowupQuestion(currentSegmentIndex, currentSubQuestionIndex, null);
}

const SurveyQuestionContext = React.createContext(null);

export const SurveyQuestionProvider = (props) => {
  const { question, response } = props;
  const [surveyQuestionState, setSurveyQuestionState] = React.useState({
    question,
    response,
    lastActiveIndex: -1,
  });

  React.useEffect(() => {

  }, []);

  return (
    <SurveyQuestionContext.Provider value={[surveyQuestionState, setSurveyQuestionState]}>
      {props.children}
    </SurveyQuestionContext.Provider>
  );
};

function getBottomPositionOfHeader() {
  const headerBorder: any = document.querySelector('.cmp-survey-page-layout-sticky-border');
  const hHeight = headerBorder.style.top ? Number(headerBorder.style.top.split('px')[0]) : headerBorder.getBoundingClientRect().bottom;
  return hHeight + 1;
}

export function useSurveyQuestion() {
  const [surveyQuestionState, setSurveyQuestionState] = React.useContext(SurveyQuestionContext);
  const [contentScrollBuffer, setContentScrollBuffer] = React.useState(0);
  const [{ isWideDesktop }] = useUIState();

  const triggerNewLayout = () => {
    const allQuestionEls = document.querySelectorAll('.survey-question');
    const lastQuestionEl = allQuestionEls[allQuestionEls.length - 1];
    const lastHeight = lastQuestionEl.clientHeight;
    const hHeight = getBottomPositionOfHeader();
    const extraBuffer = 14;
    const contentAreaHeight =  window.innerHeight - hHeight - extraBuffer;

    if (lastHeight > contentAreaHeight) {
      setContentScrollBuffer(100);
    } else {
      setContentScrollBuffer(contentAreaHeight - lastHeight);
    }
  };

  React.useEffect(() => {
    triggerNewLayout();
  }, [window.innerWidth, isWideDesktop]);

  return [
    {
      ...surveyQuestionState,
      contentScrollBuffer,
    },
    {
      updateRapidResponseSubQuestion: (segmentIndex, subQuestionIndex, subQuestion, rrqResponse) => {
        const question = surveyQuestionState.question.composite ? surveyQuestionState.question.composite : surveyQuestionState.question;
        const segmentQuestion = question.segments ? question.segments[segmentIndex] : question;

        const rapidResponseQuestion = segmentQuestion[eSurveyQuestionCategoryName.rapidResponse];
        const { options } = rapidResponseQuestion;
        const optionsLookup = _.keyBy(options, 'response.id');
        const selectedResponse = rrqResponse.option;

        const selectedOption = optionsLookup[rrqResponse.option.id];

        setSurveyQuestionState({
          ...surveyQuestionState,
          lastActiveSegmentIndex: segmentIndex,
          lastActiveSubQuestionIndex: subQuestionIndex,
        });

        const hasFollowupQuestion = selectedOption && Boolean(selectedOption.question);
        const isFollowupResponse = Boolean(selectedResponse[selectedResponse.category]);
        const isLastInSegment = subQuestionIndex >= rapidResponseQuestion.list.length - 1;

        if (hasFollowupQuestion) {
          if (isFollowupResponse) {
            setTimeout(() => {
              goNext(segmentIndex, subQuestionIndex);
            }, 330);
          } else {
            goNextFollowupQuestion(segmentIndex, subQuestionIndex);
          }
        } else if (isLastInSegment) {
          goNextSegment(segmentIndex);
        } else {
          goNext(segmentIndex, subQuestionIndex);
        }
      },
      updateSingleAnswerQuestion: (segmentIndex) => {
        setSurveyQuestionState({
          ...surveyQuestionState,
          lastActiveSegmentIndex: segmentIndex,
          lastActiveSubQuestionIndex: 0,
        });

        goNextSegment(segmentIndex);
      },
      goNext: (segmentIndex, subQuestionIndex) => {
        const { question } = surveyQuestionState;

        const isLastInSegment = SurveyQuestionResponseModel.isLastSubQuestionInSegment(question, segmentIndex, subQuestionIndex);

        if (isLastInSegment) {
          goNextSegment(segmentIndex);
        } else {
          goNext(segmentIndex, subQuestionIndex);
        }
      },
      goPrev: (segmentIndex, subQuestionIndex) => {
        const { question } = surveyQuestionState;
        const isFirstInSegment = subQuestionIndex === 0;
        let lastIndex = 0;
        if (segmentIndex > 0) {
          lastIndex = SurveyQuestionResponseModel.getSubQuestionCount(question, segmentIndex - 1) - 1;
        }

        if (isFirstInSegment) {
          goPrevSegment(segmentIndex, lastIndex);
        } else {
          goPrev(segmentIndex, subQuestionIndex);
        }
      },
      triggerNewLayout: () => {
        triggerNewLayout();
      },
      goToFirstUncheckedItem: (response) => {
        const { question } = surveyQuestionState;
        const d = SurveyQuestionResponseModel.getFirstUnansweredQuestionData(question, response, 0);
        if (d) {
          goNext(d.segmentIndex, d.subQuestionIndex - 1);
        }
      },
    },
  ];
}
