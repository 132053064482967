import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { Card, CardRowUI } from '../../../../../bphComponents/bundle';
import AppUtils from '../../../../../utilities/AppUtils';

import FlexRow from '../../../../components/FlexRow';
import InputWithLabel from '../../../../components/InputWithLabel';
import InputErrorText from '../../../../components/InputErrorText';
import InputHintText from '../../../../components/InputHintText';

import InputAlignedText from '../../../../components/InputAlignedText';
import { isValidObservationQuantity } from '../../../../../utilities/Validators';
import { iObservationAttributeQuantityDefinition, iObservationsAttributeValue, iTrackable, TrackableModel } from '../../../../../apiServices/apiModels/Trackable';


interface Props {
  quantityValue: iObservationsAttributeValue;
  definition: iObservationAttributeQuantityDefinition;
  trackable: iTrackable;
  terms: any;
  languageCode: string;
  onChange(data: iObservationsAttributeValue): void;
  onFocus(): void;
  haveInitialFocus: boolean;
  textPhrase: {
    inputLabel: any;
  };
}
export const Quantity: React.FC<Props> = (props) => {
  const { quantityValue, definition, terms, onChange, onFocus, haveInitialFocus, languageCode, trackable } = props;

  const quantity = quantityValue.quantity;
  const quantityDef = definition.quantity;
  const availableUnitIds = quantityDef.units.trackable_term_id || [];
  const availableUnitTerms = availableUnitIds.map(id => terms[id]);
  const selectedUnitId = quantity.unit_trackable_term_id || TrackableModel.getLastObservationUnit(trackable);
  const unitTerm = terms[selectedUnitId] || '';
  const labelText = _.get(definition, `hint_text.string.${languageCode}`, '');

  const hintText = '';
  const helpText = '';
  const isCompositeUnit = false;
  const showUnit = true;
  const unitPairs = [{
    value: quantity.value,
    unit: unitTerm,
  }];
  const fieldWidth = isCompositeUnit ? 60 : 140;

  const [isUnitListExpanded, setIsUnitListExpanded] = React.useState(!unitTerm);
  const [errorMessage, setErrorMessage] = React.useState(null);

  function onChangeAmountValue(index, valueData) {
    onChange({
      ...quantityValue,
      quantity: {
        ...quantity,
        value: valueData,
        unit_trackable_term_id: selectedUnitId,
      },
    });
  }

  function onChangeAmountUnit(newUnit) {
    onChange({
      ...quantityValue,
      quantity: {
        ...quantity,
        unit_trackable_term_id: Number(newUnit.id),
      },
    });
  }

  function onBlur() {
    const validData = {
      isValid: !quantity.value || isValidObservationQuantity(quantity.value),
      errorMessage: {
        id: 'error.number-validator.pos-number-or-range.message',
      },
    };

    setErrorMessage(!validData.isValid ? <FormattedMessage {...validData.errorMessage} /> : null);
  }

  return (
    <Card.CardContent disabledPadding={['top', 'bottom']}>
    <div>
      <Card.CardSection hasVerticalPadding={true} hasBottomBorder={true}>
        <FlexRow style={{ justifyContent: 'flex-start', alignItems: 'flex-end' }}>
          {
            unitPairs.map((pair, index) => (
              <React.Fragment key={index}>
                <div style={{ width: fieldWidth, marginRight: 12 }}>
                  <InputWithLabel
                    value={pair.value}
                    onChange={(event) => { onChangeAmountValue(index, event.target.value); }}
                    onBlur={onBlur}
                    isClearable={!isCompositeUnit}
                    clearValue={() => { onChangeAmountValue(index, ''); }}
                    onFocus={() => { onFocus(); setErrorMessage(null); }}
                    isInvalid={!!errorMessage}
                    type={AppUtils.isIosDevice() ? 'text' : 'tel'}
                    label={index === 0 && <span className="font-color-gsDark">{labelText}</span>}
                    name={`amount_value_${index}`}
                    initialFocus={haveInitialFocus && !pair.value && index === 0}
                    noForcedHeight
                    alwaysFloating
                    labelStyles={{ overflow: 'initial' }}
                  />
                </div>
                <div>
                  {
                    showUnit && (
                      <div className="font-subhead" style={{ marginRight: 12 }}>
                        <InputAlignedText>
                          {pair.unit.name}
                        </InputAlignedText>
                      </div>
                    )
                  }
                </div>
              </React.Fragment>
            ))
          }
          </FlexRow>

          {
            errorMessage
            ? (
              <InputErrorText>
                {errorMessage}
              </InputErrorText>
            ) : hintText
              ? (
                <InputHintText>
                  {hintText}
                </InputHintText>
              ) : null
          }
      </Card.CardSection>

      <Card.CardSection hasVerticalPadding={true} hasBottomBorder={true}>
        <Card.CollapsibleControl
          isExpanded={isUnitListExpanded}
          isMultiLine={false}
          hasBottomBorder={true}
          onClick={() => {
            setIsUnitListExpanded(!isUnitListExpanded);
          }}
        >
          <span className="font-color-profile-accent font-footnote">
            {
              selectedUnitId
              ? <FormattedMessage id="heValue.unit.changeUnit.label" />
              : <FormattedMessage id="heValue.unit.addUnit.label" />
            }

          </span>
        </Card.CollapsibleControl>
      </Card.CardSection>

      {
        isUnitListExpanded ? (
          <Card.CardSection hasVerticalPadding={false} hasBottomBorder={true}>
            {
              availableUnitTerms.map((u, i) => {
                const termId = Number(u.id);
                return (
                  <CardRowUI.SelectableRow
                    key={termId}
                    disabledPadding={['left', 'right']}
                    borderType="inset"
                    isSelected={selectedUnitId === termId}
                    selectedClassName="fill-color-profile-highlight"
                    hasBottomBorder={i !== availableUnitTerms.length - 1}
                    onClick={() => {
                      onChangeAmountUnit(u);
                    }}
                  >
                    {u.name}
                  </CardRowUI.SelectableRow>
                );
              })
            }
          </Card.CardSection>
        ) : null
      }
      {
        helpText ? (
          <Card.CardSection hasVerticalPadding={true} hasBottomBorder={true}>
            {helpText}
          </Card.CardSection>
        ) : null
      }
    </div>
    </Card.CardContent>
  );
};
