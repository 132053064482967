import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { queryCache } from 'react-query';

import './ReviewPendingData.styles.scss';

import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep, ScrollAwareItem } from '../../components/ScrollAware';
import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import { GrayCard } from '../../components/card/Card';
import ContentWrapper from '../../components/ContentWrapper';
import { TextLink } from '../../components/TextLink';
import ContentLoader from '../../components/ContentLoader';
import ContentFrame from '../../applicationFrame/ContentFrame';
import RejectPendingDataConfirmModal from '../../components/modal/RejectPendingDataConfirmModal';

import { Card, Buttons, Spacer } from '../../../bphComponents/bundle';
import { GrayTextMarkdown } from '../../components/Markdown';
import HealthEntryService from '../../../apiServices/HealthEntryService';
import RouteNames from '../../appAuthorized/RouteNames';
import { ProfileLoadingBar } from '../../components/ProgressIndicator';

import { NewPendingHealthEntries } from './components/NewPendingHealthEntries';
import { ReviewedPendingHealthEntries } from './components/ReviewedPendingHealthEntries';
import { useUIState } from '../../hooks/useUIState';
import { useActiveProfile } from '../../hooks/useProfile';
import { useActiveUser } from '../../hooks/useUser';
import { usePendingHealthEntryData } from '../../hooks/useHealthEntry';
import { useNavigate } from '../../hooks/useNavigate';

const DELAY_BETWEEN_ITEM_REMOVALS = 300;
const REMOVAL_TIMEOUT = 600;

function toggleAll(selectedIds, newIds) {
  if (!Array.isArray(newIds)) {
    return {
      ...selectedIds,
      [newIds]: !Boolean(selectedIds[newIds]),
    };
  }

  const allTrue = newIds.reduce((p, id) => {
    return selectedIds[id] ? p : false;
  }, true);

  const toggledIds = newIds.reduce((p, id, c) => {
    p[id] = !allTrue;
    return p;
  }, {});

  return {
    ...selectedIds,
    ...toggledIds,
  };
}

function getIds(allHealthEntries, selectedIdsMap, filterFn) {
  const selectedIds = Object.keys(selectedIdsMap).filter(filterFn).map(a => Number(a));
  const healthEntries = allHealthEntries.filter(he => selectedIds.includes(he.id));
  const healthEntryIds = healthEntries.map(he => he.id);
  const healthEntryValueIds = healthEntries.reduce((state, he) => {

    return (he[he.category].values)
      ? [...state, ...he[he.category].values.map(v => v.id)]
      : state;
  }, []);

  return {
    health_entry_ids: healthEntryIds,
    health_entry_value_ids: healthEntryValueIds,
  };
}
function getIdsForSubmit(allHealthEntries, selectedIdsMap) {
  return getIds(allHealthEntries, selectedIdsMap, id => selectedIdsMap[id]);
}
function getIdsForReview(allHealthEntries, selectedIdsMap) {
  return getIds(allHealthEntries, selectedIdsMap, id => !selectedIdsMap[id]);
}

function usePendingHE(props) {
  const { locale } = props.intl;

  const [{ profileId }] = useActiveProfile();
  const [, sourcePendingActions] = usePendingHealthEntryData(locale);
  const [, navActions] = useNavigate();
  const [, uiActions] = useUIState();

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [selectedIds, setSelectedIds] = React.useState({});
  const [pendingHealthEntries, setPendingHealthEntries] = React.useState([]);
  const [origPendingHealthEntries, setOrigPendingHealthEntries] = React.useState([]);
  const [progress, setProgress] = React.useState({ value: 0, status: 'idle', time: 0 });

  const selectedCount = Object.keys(selectedIds).reduce((p, v) => { return selectedIds[v] ? p + 1 : p; }, 0);
  const noSelection = selectedCount === 0;
  const isInProgress = progress.status === 'active';

  const healthEntries = pendingHealthEntries.reduce((p, v) => {
    if (v.reviewed) {
      p.reviewed.push(v);
    } else {
      p.current.push(v);
    }
    return p;
  }, { current: [], reviewed: [] });


  React.useEffect(() => {
    sourcePendingActions.fetch().then((d: any) => {
      setSelectedIds(d.list.reduce((p, v) => {
        if (!v.reviewed) {
          p[v.id] = true;
        }
        return p;
      }, {}));
      setPendingHealthEntries(d.list);
      setOrigPendingHealthEntries(d.list);
      setIsLoaded(true);
    });
  }, []);

  React.useEffect(() => {
    if (progress.status === 'complete') {
      if (pendingHealthEntries.length === 0) {
        queryCache.invalidateQueries('pendingHealthEntries');
        navActions.goBack(RouteNames.healthInfo());
      }
    } else {
      removeSingleItem();
    }
  }, [progress.value]);

  function getFirstSelectedId() {
    const sortedIds = getSelectedIds().sort((a, b) => {
      const idA = Number(a);
      const idB = Number(b);
      const indexA = pendingHealthEntries.findIndex(c => c.id === idA);
      const indexB = pendingHealthEntries.findIndex(c => c.id === idB);
      return indexA < indexB ? -1 : 1;
    });

    return sortedIds[0];
  }

  function removeSingleItem() {
    const sIds = getSelectedIds();
    const nId = Number(getFirstSelectedId());

    if (sIds.length === 0) { return; }

    const newHE = pendingHealthEntries.filter((he) => he.id !== nId);
    setPendingHealthEntries(newHE);
    const newSelectedIds = { ...selectedIds };
    delete newSelectedIds[nId];
    setSelectedIds(newSelectedIds);

    setTimeout(() => {
      const v = sIds.length - 1;
      const status = v === 0 ? 'complete' : 'active';
      setProgress({ ...progress, value: v, status });
    }, DELAY_BETWEEN_ITEM_REMOVALS);
  }

  function getSelectedIds() {
    return Object.keys(selectedIds).filter((id) => selectedIds[id]);
  }

  function acceptSelection() {
    if (noSelection) { return; }
    if (isInProgress) { return; }

    const sIds = getSelectedIds();
    setProgress({ value: sIds.length, status: 'active', time: sIds.length * DELAY_BETWEEN_ITEM_REMOVALS + 50 });

    const reviewed = getIdsForReview(origPendingHealthEntries, selectedIds);
    const accepted = getIdsForSubmit(origPendingHealthEntries, selectedIds);
    HealthEntryService.reviewPending(profileId, reviewed)
    .then(() => {
      HealthEntryService.acceptPending(profileId, accepted)
      .then(() => {});
    });
  }

  function rejectSelection() {
    if (noSelection) { return; }
    if (isInProgress) { return; }

    uiActions.showModal(<RejectPendingDataConfirmModal
      reject={() => {
        const sIds = getSelectedIds();
        setProgress({ value: sIds.length, status: 'active', time: sIds.length * DELAY_BETWEEN_ITEM_REMOVALS + 50 });

        const reviewed = getIdsForReview(origPendingHealthEntries, selectedIds);
        const rejected = getIdsForSubmit(origPendingHealthEntries, selectedIds);
        HealthEntryService.reviewPending(profileId, reviewed)
        .then(() => {
          HealthEntryService.rejectPending(profileId, rejected)
          .then(() => {});
        });
      }}
    />);
  }

  return [
    {
      healthEntries,
      isInProgress,
      selectedIds,
      progressValue: progress.value,
      progressTime: progress.time,
      isPendingDataLoaded: isLoaded,
    }, {
      acceptSelection,
      rejectSelection,
      setSelectedIds,
    },
  ];
}

const ReviewPendingData: React.FC = (props) => {
  const [{ healthEntries, selectedIds, isInProgress, progressTime, isPendingDataLoaded }, { acceptSelection, rejectSelection, setSelectedIds }] = usePendingHE(props);

  const [{ isActiveProfileMainProfile }] = useUIState();
  const [{ greetingName }] = useActiveProfile();
  const [{ preferredLanguageCode }] = useActiveUser();
  const [, navActions] = useNavigate();

  const selectedCount = Object.keys(selectedIds).reduce((p, v) => { return selectedIds[v] ? p + 1 : p; }, 0);
  const noSelection = selectedCount === 0;
  const controlsDisabled = isInProgress;

  const messages = isActiveProfileMainProfile ? {
    intro: {
      id: 'pendingData.intro.user.message.md',
    },
  } : {
    intro: {
      id: 'pendingData.intro.notUser.message.md',
      values: {
        string1: greetingName,
      },
    },
  };

  return (
    <div className="cmp-reviewpendingdata">
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          done={() => {
            queryCache.invalidateQueries('pendingHealthEntries');
            navActions.goBackTo(RouteNames.myBackpack());
          }}
          color="white"
        />

        <ContentFrame>
          <ScrollAwareScroller>
            <ContentLoader isContentLoaded={isPendingDataLoaded} isFixed blockRenderUntilLoaded>
              <AppHeaderScrollableText
                id="pendingData.title.text"
              />
              {
                isInProgress && (
                  <ScrollAwareItem isSticky>
                    <div style={{ marginTop: -6 }}>
                      <ProfileLoadingBar totalTime={progressTime} />
                    </div>
                  </ScrollAwareItem>
                )
              }

              <ContentWrapper>
                <GrayCard>
                  <Card.CardContent>
                    <GrayTextMarkdown phraseId={messages.intro.id} phraseValues={messages.intro.values} />
                  </Card.CardContent>
                </GrayCard>

                <NewPendingHealthEntries
                  healthEntries={healthEntries.current}
                  languageCode={preferredLanguageCode}
                  isSelected={(id) => Boolean(selectedIds[id])}
                  onClick={(id) => {
                    setSelectedIds(toggleAll(selectedIds, id));
                  }}
                  exitAnimationTime={REMOVAL_TIMEOUT}
                />

                <ReviewedPendingHealthEntries
                  healthEntries={healthEntries.reviewed}
                  isDefaultExpanded={healthEntries.current.length === 0}
                  isSelected={(id) => Boolean(selectedIds[id])}
                  onClick={(id) => {
                    setSelectedIds(toggleAll(selectedIds, id));
                  }}
                  exitAnimationTime={REMOVAL_TIMEOUT}
                />

                  <Buttons.PrimaryButton
                    alignText="center"
                    className="bkgd-color-profile"
                    onClick={acceptSelection}
                    isDisabled={noSelection || controlsDisabled}
                  >
                    <FormattedMessage id="pendingData.accept.button.label" values={{ digit1: selectedCount }} />
                  </Buttons.PrimaryButton>

                <Spacer.Height16 />

                <Card.ExternalCardTitle>
                  <TextLink
                    className="font-color-gsMedDark font-underline"
                    onClick={rejectSelection}
                    isDisabled={noSelection || controlsDisabled}
                  >
                    <FormattedMessage id="pendingData.reject.button.label" values={{ digit1: selectedCount }} />
                  </TextLink>
                </Card.ExternalCardTitle>

              </ContentWrapper>
            </ContentLoader>
          </ScrollAwareScroller>
        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
};

export default injectIntl(ReviewPendingData);
