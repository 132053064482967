
import React from 'react';

import { MedliStaticListView } from '../componentViews/StaticListView';

import RouteNames from '../../appAuthorized/RouteNames';
import { eMedliCategoryName } from '../../../constants/MedliNames';
import { useHealthEntryForActiveProfile } from '../../hooks/useHealthEntry';
import { useMedliStoppingReasonList } from '../../hooks/useMedli';
import { useNavigate } from '../../hooks/useNavigate';

export const HealthEntryStoppingReason = (props) => {
  const healthEntryId = Number(props.match.params.healthEntryId);

  const [, navActions] = useNavigate();
  const [{ stoppingReasonList }] = useMedliStoppingReasonList();
  const [, heActions] = useHealthEntryForActiveProfile(healthEntryId);

  return (
    <MedliStaticListView
      suggestedResults={stoppingReasonList}
      title="stoppingReasonSearch.search.title.text"
      medliCategory={eMedliCategoryName.stoppingReason}
      onGoBack={() => {
        navActions.goBackTo(RouteNames.healthEntry(healthEntryId));
      }}
      selectSearchResult={(medliTermData) => {
        const newHE = heActions.instantiateHealthEntry();
        newHE.stoppingReason.replaceWithSearchTerm(medliTermData);

        heActions.apiUpdate(newHE.data())
          .then(() => {
            navActions.goBackTo(RouteNames.healthEntry(healthEntryId));
          });
      }}
    />
  );
};
