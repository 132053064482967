import { useQuery, queryCache } from 'react-query';

import { useDispatch } from 'react-redux';

import NoteModel from '../../models/NoteModel';
import SortingUtil from '../../utilities/SortingUtil';
import NoteService from '../../apiServices/NoteService';
import { useUIState } from './useUIState';


function createCollectionAndLookup(apiError, responseCollection) {
  const lookup = {};
  let collection = [];

  if (!apiError) {
    collection = responseCollection;
    for (let i = 0; i < responseCollection.length; i++) {
      lookup[collection[i].id] = collection[i];
    }
  }

  return {
    collection,
    lookup,
  };
}

const STALE_TIME = 10000;
const INITIAL_NOTE_LIST_STATE = {
  list: [],
  isLoaded: false,
};
const INITIAL_NOTE_STATE = {
  data: { content: '', flagged: false },
  isLoaded: false,
};
function fetchNotesForProfile(dispatch) {
  return function fetchNotesForProfile_API(queryKey, profileId) {
    return new Promise((res, rej) => {
      NoteService.getAll(profileId, (err, data) => {
        if (err) { return rej(err); }

        dispatch({
          type: 'RECEIVE_ALL_NOTES',
          payload: {
            profileId,
            isFetching: false,
            lastUpdated: new Date().getTime(),
            ...createCollectionAndLookup(null, data),
          },
        });

        res({
          list: data,
          isLoaded: true,
        });
      });
    });
  };
}

function formatNotes(notes) {
  return SortingUtil.sortNotes(notes.map(n => new NoteModel(n)));
}
export function useNotesForActiveProfile() {
  const dispatch = useDispatch();
  const [{ activeProfileId }] = useUIState();
  const noteListQuery: any = useQuery(['notes', activeProfileId], fetchNotesForProfile(dispatch), { staleTime: STALE_TIME });
  const responseData = noteListQuery.data || INITIAL_NOTE_LIST_STATE;
  const noteList = responseData.list;
  const isLoaded = responseData.isLoaded;

  return [
    {
      noteList: formatNotes(noteList),
      isLoaded,
    },
  ];
}

function requestNote(queryKey, profileId, noteId) {
  return new Promise((res, rej) => {
    NoteService.get(profileId, noteId, (apiError, noteData) => {
      if (apiError) {
        rej(apiError);
        return;
      }
      res({
        data: noteData,
        isLoaded: true,
      });
    });
  });
}

function apiCreateNote(profileId, noteData) {
  return new Promise((res, rej) => {
    NoteService.create(profileId, noteData, (apiError, createdNote) => {
      if (apiError) {
        rej(apiError);
        return;
      }
      res(createdNote);
    });
  });
}

function apiUpdateNote(profileId, noteData) {
  return new Promise((res, rej) => {
    NoteService.update(profileId, noteData, (apiError) => {
      if (apiError) {
        rej(apiError);
        return;
      }
      res();
    });
  });
}

export function useNoteForProfile(noteId, profileId) {
  const noteQuery: any = useQuery(['note', profileId, noteId], requestNote, { enabled: Boolean(noteId) });
  const responseData = noteQuery.data || INITIAL_NOTE_STATE;
  const noteData = responseData.data;

  return [
    {
      note: noteData,
      isLoaded: responseData.isLoaded,
    },
    {
      instantiateNote: (newNoteData?) => {
        const data = newNoteData || newNoteData;
        return new NoteModel(data);
      },
      hasChanges: (otherNoteData) => {
        const currentNote = new NoteModel(noteData);
        const isFlaggedDifferent = noteData.flagged !== otherNoteData.flagged;
        const hasChanges = currentNote.isDifferentFrom(otherNoteData);

        return hasChanges || isFlaggedDifferent;
      },
      apiCreate: (newNoteData) => {
        return apiCreateNote(profileId, newNoteData)
          .then(() => {
            queryCache.invalidateQueries('note');
            queryCache.invalidateQueries('notes');
          });
        // return new Promise((res, rej) => {
        //   NoteActions.createNote(profileId, newNoteData, () => {
        //     res();
        //   });
        // });
      },
      // apiFlagged: () => {
      //   return new Promise((res, rej) => {
      //     NoteActions.flagNote(profileId, noteData);
      //     res();
      //   });
      // },
      apiUpdate: (newNoteData) => {
        return apiUpdateNote(profileId, newNoteData)
          .then(() => {
            queryCache.setQueryData(['note', profileId, noteId], {
              data: newNoteData,
              isLoaded: true,
            });
            queryCache.invalidateQueries('note');
            queryCache.invalidateQueries('notes');
          });
      },
      apiDelete: () => {
        return new Promise((res, rej) => {
          NoteService.del(profileId, noteId, () => {
            queryCache.invalidateQueries('notes');
            res();
          });
        });
      },
    },
  ];
}

export function useNoteForActiveProfile(noteId) {
  const [{ activeProfileId }] = useUIState();
  return useNoteForProfile(noteId, activeProfileId);
}
