import React from 'react';


import { AddressDetail, EmailDetail, PhoneDetail, WebsiteDetail } from './DetailSummary';
import { Card } from '../../../../bphComponents/bundle';
import { ContactDetailType } from '../../../../models/ContactModel';

interface DetailGroupProps {
  details: ContactDetailType[];
  linkType: string;
}

const detailComponentLookup = {
  website: WebsiteDetail,
  email: EmailDetail,
  address: AddressDetail,
  phone: PhoneDetail,
};

const DetailGroup: React.FC<DetailGroupProps> = (props) => {
  const { details, linkType } = props;
  const lastIndex = details.length - 1;
  const RowComponent = detailComponentLookup[linkType];

  if (details.length === 0) { return null; }

  return (
    <Card.CardSection hasBottomBorder={true}>
      {
        details.map((d, i) => (
          <RowComponent
            key={d.id}
            detail={d}
            isLast={lastIndex === i}
          />
        ))
      }
    </Card.CardSection>
  );
};

export default DetailGroup;
