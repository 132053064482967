import React from 'react';
import _ from 'lodash';

import { iObservationAttributeScaleDefinition, iObservationsAttributeValue } from '../../../../../apiServices/apiModels/Trackable';
import { Card } from '../../../../../bphComponents/bundle';

import './Scale.scss';


interface Props {
  isDefaultExpanded: boolean;
  value: iObservationsAttributeValue;
  definition: iObservationAttributeScaleDefinition;
  terms: any[];
  onChange(d): void;
  languageCode: string;
}
export const Scale: React.FC<Props> = (props) => {
  const { definition, terms, onChange, value, languageCode } = props;
  const scalarValue = value ? value.scale.selected_option_value : '';

  const { options } = definition.scale;
  const hintText = _.get(definition, `hint_text.string.${languageCode}`, '');

  const identifier = 'scale-range';
  const hasValue = Boolean(scalarValue);

  const minValue = options[0].value;
  const maxValue = options[options.length - 1].value;
  const selectedIndex = options.findIndex(o => o.value === scalarValue);
  const width = selectedIndex > -1 ? `calc(${100 * ((selectedIndex) / (options.length - 1))}% - 10px)` : 0;

  const currentValue = scalarValue || minValue;

  return (
    <Card.CardContent hasBottomBorder={true}>
      <div className={`cmp-scale input-range-theme-profile ${hasValue ? '__has-value' : ''}`}>
        {
          hintText ? (
            <div className="font-color-gsMedDark font-caption-1" style={{ marginBottom: 12 }}>
              {hintText}
            </div>
          ) : null
        }
        <div className="__value-label font-color-profile-accent font-body" style={{ marginBottom: 26 }}>
          {
            options.map((o, i) => {
              return o.value === currentValue
                ? (
                  <div key={i} className="__item __selected" style={{ opacity: o.value === scalarValue ? 1 : 0.5 }}>
                    {
                      terms[o.slider_label_trackable_term_id]
                      ? <p>{terms[o.slider_label_trackable_term_id].name}</p>
                      : null
                    }
                  </div>
                )
                : (
                  <div key={i} className="__item" style={{  }} />
                );
            })
          }
        </div>
        <input
          className="slider"
          type="range"
          min={minValue}
          max={maxValue}
          value={currentValue}
          list={identifier}
          style={{
            width: '100%',
          }}
          onFocus={() => {
            // only set value on focus, if there is no value
            if (scalarValue) { return; }
            onChange({
              attribute_definition_id: definition.id,
              category: 'scale',
              scale: {
                selected_option_value: currentValue,
              },
            });
          }}
          onChange={(e) => {
            const v = e.target.value;
            onChange({
              attribute_definition_id: definition.id,
              category: 'scale',
              scale: {
                selected_option_value: v,
              },
            });
          }}
        />
        <div className="__track-fill bkgd-color-profile-accent" style={{ width }} />
        <div className="__track">
          {
            options.map((o, i) => (
              <span key={i} />
            ))
          }
        </div>
        <datalist
          id={identifier}
          className="font-caption-1 font-color-gsMedDark"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {
            options.map(o => (
              <option key={o.value} value={o.value} label={terms[o.track_label_trackable_term_id].name} />
            ))
          }
        </datalist>
      </div>
    </Card.CardContent>
  );
};
