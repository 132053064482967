
const actionNames = {
  lists: {
    REQUEST_ALL: 'app/lists/all/REQUEST',
    RECEIVE_ALL: 'app/lists/all/RECEIVE',

    REQUEST_GENDER: 'REQUEST_GENDER',
    RECEIVE_GENDER: 'RECEIVE_GENDER',

    REQUEST_BLOODTYPE: 'REQUEST_BLOOD_TYPE',
    RECEIVE_BLOODTYPE: 'RECEIVE_BLOOD_TYPE',

    REQUEST_RESIDENCE_COUNTRY: 'REQUEST_RESIDENCE_COUNTRY',
    RECEIVE_RESIDENCE_COUNTRY: 'RECEIVE_RESIDENCE_COUNTRY',
    REQUEST_RESIDENCE_STATE: 'REQUEST_RESIDENCE_STATE',
    RECEIVE_RESIDENCE_STATE: 'RECEIVE_RESIDENCE_STATE',

    REQUEST_RACE: 'REQUEST_RACE',
    RECEIVE_RACE: 'RECEIVE_RACE',

    REQUEST_BIRTHSEX: 'REQUEST_BIRTHSEX',
    RECEIVE_BIRTHSEX: 'RECEIVE_BIRTHSEX',

    REQUEST_PROFILE_STATE: 'REQUEST_PROFILESTATE',
    RECEIVE_PROFILE_STATE: 'RECEIVE_PROFILESTATE',
    REQUEST_COUNTRY: 'REQUEST_COUNTRY',
    RECEIVE_COUNTRY: 'RECEIVE_COUNTRY',
    REQUEST_FREQUENCY: 'REQUEST_FREQUENCY',
    RECEIVE_FREQUENCY: 'RECEIVE_FREQUENCY',
    REQUEST_COLOR_THEME: 'REQUEST_COLOR_THEME',
    RECEIVE_COLOR_THEME: 'RECEIVE_COLOR_THEME',
    REQUEST_SHARECARDCOLORS: 'REQUEST_SHARE_CARD_COLORS',
    RECEIVE_SHARECARDCOLORS: 'RECEIVE_SHARE_CARD_COLORS',
    REQUEST_CANCEL_REASON: 'REQUEST_CANCEL_REASON',
    RECEIVE_CANCEL_REASON: 'RECEIVE_CANCEL_REASON',
    REQUEST_DIAGNOSIS: 'REQUEST_DIAGNOSIS',
    RECEIVE_DIAGNOSIS: 'RECEIVE_DIAGNOSIS',
    REQUEST_GENE_VARIANT_PRESENT: 'REQUEST_GENE_VARIANT_PRESENT',
    RECEIVE_GENE_VARIANT_PRESENT: 'RECEIVE_GENE_VARIANT_PRESENT',
    REQUEST_CATEGORY: 'app/lists/category/REQUEST',
    RECEIVE_CATEGORY: 'app/lists/category/RECEIVE',

    REQUEST_CONTACT_DETAIL_LABEL: 'app/lists/contactDetailLabel/REQUEST',
    RECEIVE_CONTACT_DETAIL_LABEL: 'app/lists/contactDetailLabel/RECEIVE',

    REQUEST_CONTACT_SPECIALTY: 'app/lists/contactSpecialty/REQUEST',
    RECEIVE_CONTACT_SPECIALTY: 'app/lists/contactSpecialty/RECEIVE',
    REQUEST_CONTACT_RELATIONSHIP: 'app/lists/contactRelationship/REQUEST',
    RECEIVE_CONTACT_RELATIONSHIP: 'app/lists/contactRelationship/RECEIVE',
    REQUEST_CONTACT_HEALTHCARE: 'app/lists/contactHealthcare/REQUEST',
    RECEIVE_CONTACT_HEALTHCARE: 'app/lists/contactHealthcare/RECEIVE',

    RECEIVE_LOCALE: 'app/lists/locale/RECEIVE',
    REQUEST_LOCALE: 'app/lists/locale/REQUEST',

    RECEIVE_LOCALE_MEDLI: 'app/lists/localeMedli/RECEIVE',
    REQUEST_LOCALE_MEDLI: 'app/lists/localeMedli/REQUEST',
  },
};

export default actionNames;
