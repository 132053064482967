import _ from 'lodash';

import HealthEntryCategoryNames from '../constants/HealthEntryCategoryNames';
import { Diagnosis } from './HealthEntryCompose/diagnosis';
import { generalHealthEntry, generalHealthEntryValue } from './HealthEntryCompose';
import DateUtil from '../utilities/DateUtil';

export interface HealthEntry {
  id: number;
  category: HealthEntryCategoryNames;
  subType?: {
    get(): string;
  };
  diagnosis: Diagnosis;
  data(): any;
  flag: any;
}

export interface HealthEntryData {
  id: number;
  flagged: boolean;
  created_at: string;
  updated_at: string;
  attachment_count: { file: number; contact: number; note?: number; };
  comments: any[];
  profile_id: number;
  category: string;
}


class HealthEntryModel {

  static subCategoryName(healthEntryData) {
    return healthEntryData.category ? healthEntryData[healthEntryData.category].type : null;
  }

  static toggleIsCurrent(healthEntryData, options?) {
    const isStoppingReasonReaction = options ? options.isStoppingReasonReaction : false;

    const healthEntry = generalHealthEntry({ ...healthEntryData }, 'en');

    healthEntry.current.toggle();
    if (healthEntry.current.isCurrent()) {
      healthEntry.endDate.clear();
      healthEntry.period.set(false);
      if (healthEntry.stoppingReason) {
        healthEntry.stoppingReason.remove();
      }
    } else {
      healthEntry.period.set(true);
    }

    if (healthEntry.reaction && isStoppingReasonReaction) {
      healthEntry.reaction.clear();
    }

    return healthEntry.data();
  }

  static allowCurrent(category) {
    switch (category) {
      case HealthEntryCategoryNames.geneticResult:
      case HealthEntryCategoryNames.measurement:
      case HealthEntryCategoryNames.lab:
      case HealthEntryCategoryNames.procedure:
      case HealthEntryCategoryNames.vaccination:
        return false;
      default:
        return true;
    }
  }

  static isCurrent(healthEntryData) {
    return healthEntryData[healthEntryData.category].current;
  }

  static getName(healthEntryData, languageCode) {
    return healthEntryData[healthEntryData.category].term.strings[languageCode];
  }

  static getDate(healthEntryData) {

  }

  static compareName(a, b, languageCode) {
    return HealthEntryModel.getName(a, languageCode).localeCompare(HealthEntryModel.getName(b, languageCode));
  }

  static getDateForSort(healthEntryData) {
    const kindData = healthEntryData[healthEntryData.category];

    if (kindData.values) {
      return kindData.values.reduce((p, v) => {
        const d = v.date.date || '';
        if (!p) {
          return d;
        } else {
          return p < d ? d : p;
        }
      }, '');
    }

    if (healthEntryData.date && healthEntryData.date.date) {
      return healthEntryData.date;
    } else if (kindData.current) {
      return DateUtil.today();
    } else if (kindData.ended && kindData.ended.date) {
      return kindData.ended.date;
    } else if (kindData.started && kindData.started.date) {
      return kindData.started.date;
    }
  }

  static compareDates(a, b) {
    const dateA = HealthEntryModel.getDateForSort(a);
    const dateB = HealthEntryModel.getDateForSort(b);

    if (dateA === dateB) {
      return 0;
    } else if (!dateA) {
      return -1;
    } else if (!dateB) {
      return 1;
    } else if (DateUtil.isBefore(dateA, dateB)) {
      return 1;
    } else {
      return -1;
    }
  }

  static sortByNameAndDate(healthEntries, languageCode) {
    return healthEntries.sort((a, b) =>  {
      const sortValue = HealthEntryModel.compareName(a, b, languageCode);
      if (sortValue === 0) {
        return HealthEntryModel.compareDates(a, b);
      } else {
        return sortValue;
      }
    });
  }

  static groupByCategory(healthEntries) {
    return healthEntries.reduce((p, v) => {
      p[v.category] = p[v.category] || [];
      p[v.category].push(v);
      return p;
    }, {});
  }
  static isRecent(healthEntryData, earlyDate) {
    // single occurrence
    const he = generalHealthEntry(healthEntryData, 'en');
    return he.dateUtil.isRecent(earlyDate);
  }

  static getCUI(healthEntryData) {
    return (healthEntryData && healthEntryData.category) ? healthEntryData[healthEntryData.category].term.cui : null;
  }

  static isFlagged(healthEntryData) {
    return healthEntryData ? healthEntryData.flagged : false;
  }

  static hasStoppingReason(healthEntryData) {
    const categoryData = healthEntryData[healthEntryData.category];
    return categoryData.stopping_reason && categoryData.stopping_reason.id ? true : false;
  }

  static getFrequency(healthEntryData) {
    return healthEntryData[healthEntryData.category].frequency || { id: null, value: '' };
  }

  static addHealthEntryValue(healthEntryData, hevData) {
    const category = healthEntryData.category;
    const values = healthEntryData[category].values || [];
    const newValues = values.map(v => (v.id === hevData.id ? hevData : v));

    return {
      ...healthEntryData,
      [category]: {
        ...healthEntryData[category],
        values: newValues,
      },
    };
  }

  static removeHealthEntryValue(healthEntryData, hevId) {
    const category = healthEntryData.category;
    const values = healthEntryData[category].values || [];
    const newValues = values.filter(v => (v.id !== hevId));

    return {
      ...healthEntryData,
      [category]: {
        ...healthEntryData[category],
        values: newValues,
      },
    };
  }

  static allowHEValues(healthEntryData) {
    const category = healthEntryData.category;
    const values = healthEntryData[category].values;
    return Boolean(values);
  }
}

export default HealthEntryModel;
