import React from 'react';
import { GrayCard, CardSection } from '../../../components/card/Card';
import { NoThemeMarkdown } from '../../../components/Markdown';

interface Props {
  help_text: string;
}
const SurveyHelpText: React.FC<Props> = (props) => {
  const { help_text } = props;

  if (!help_text) { return null; }

  return (
    <GrayCard hasMargins={false}>
      <CardSection hasBorder={false} className="font-color-gsMedDark font-footnote">
        <NoThemeMarkdown text={help_text} />
      </CardSection>
    </GrayCard>
  );
};

export default SurveyHelpText;
