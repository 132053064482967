
import React from 'react';
import { Redirect } from 'react-router';

import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../../components/ScrollAware';
import ContentFrame from '../../../applicationFrame/ContentFrame';
import { AppHeaderScrollableText } from '../../../components/appNav/AppHeaderScrollable';
import ContentWrapper from '../../../components/ContentWrapper';

import ContentLoader from '../../../components/ContentLoader';
import RouteNames from '../../../appAuthorized/RouteNames';

import NewContactCard from './NewContactCard';
import ContactSelectableList from './ContactSelectableList';
import { useNavigate } from '../../../hooks/useNavigate';
import { useAllProfiles } from '../../../hooks/useProfile';
import { useUIState } from '../../../hooks/useUIState';
import { useActiveUser } from '../../../hooks/useUser';

interface ViewContactListProps {
  contactListByProfile: {
    [profileId: string]: any[];
  };
  isLoaded: boolean;

  onSubmit(data): Promise<any>;
  titlePhrase: any;
  onCreate(): void;
  noContactRoute: string;
  isAttaching: boolean;
}

const ViewContactSelect: React.FC<ViewContactListProps> = (props) => {
  const { contactListByProfile, onSubmit, titlePhrase, onCreate, noContactRoute, isAttaching, isLoaded } = props;
  const contactCount = Object.keys(contactListByProfile).reduce((final, key) => {
    return contactListByProfile[key].length + final;
  }, 0);
  const hasContacts = contactCount > 0;

  const [, navActions] = useNavigate();
  const [{ profileLookup }] = useAllProfiles();
  const [{ user }] = useActiveUser();
  const mainProfileId = user.profile_id;
  const [selectedContacts, selectContact] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSelectContact = (data) => {
    const { id } = data;

    selectContact(prev => {
      const next = { ...prev };
      if (next[id]) {
        delete next[id];
      } else {
        next[id] = { ...data };
      }

      return next;
    });
  };

  if (isLoaded && !hasContacts) {
    return <Redirect to={noContactRoute} push={false} />;
  }

  const handleOnSubmit = async () => {
    await setIsSubmitting(true);
    onSubmit(selectedContacts).catch(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={Object.keys(selectedContacts).length === 0 || isSubmitting}
          cancel={() => { navActions.goBack(RouteNames.contactList()); }}
          submit={handleOnSubmit}
          color="white"
          hasDropShadow
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText {...titlePhrase} isLoaded={isLoaded} />

            <ContentWrapper>
              <ContentLoader isContentLoaded={isLoaded} blockRenderUntilLoaded={!isLoaded} isFixed={true}>
                <NewContactCard onCreate={onCreate} />

                {
                  Object.keys(contactListByProfile).map(k => {
                    const profileId = Number(k);
                    const contacts = contactListByProfile[k];

                    return contacts.length === 0 ? null : (
                      <ContactSelectableList
                        key={k}
                        data={contacts}
                        profile={profileLookup[profileId]}
                        isMainProfile={profileId === mainProfileId}
                        selectedContacts={selectedContacts}
                        selectContact={handleSelectContact}
                        isAttaching={isAttaching}
                      />
                    );
                  })
                }

              </ContentLoader>
            </ContentWrapper>
          </ScrollAwareScroller>
        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
};

export default ViewContactSelect;
