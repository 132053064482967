import React from 'react';
import GroupTitle from './GroupTitle';
import IconFile from '../../../../components/icons/IconFile';
import { FormattedMessage } from 'react-intl';
import ContentPanel from '../ContentPanel';
import List, { BasicListItem } from '../../../../components/List';
import { PublicShareCardFileListItem } from '../../../../components/FileListItem';

import './ShareCardFilesGroup.scss';

export const ShareCardFilesGroup = ({ files, hash, colorModel, requiresPasscode, accessCode, isAccessCodeInUrl }) => {
  if (!files || !files.length) return null;

  const accessCodeParam = isAccessCodeInUrl ? `?access_code=${accessCode}` : '';
  const renderFileName = (file) =>
    <a
      href={`/public/${hash}/file/${file.identifier()}${accessCodeParam}`}
      target="_blank"
      style={{ color: colorModel.darkAccentColor(), borderColor: colorModel.darkAccentColor() }}
    >
      {file.getName()}
    </a>;

  return (
    <div className="stroke-color-gsMedDark cmp-sharecard-files-group">
      <GroupTitle
        Icon={IconFile.Medium}
        text={
          <FormattedMessage id="list.backpackSecondaryNav.files.name"/>
        }
      >
        {
          (requiresPasscode && !isAccessCodeInUrl) && (
            <div className="access-code font-footnote font-color-gsMedDark">
              <FormattedMessage id="shareCardPDF.files.accessCodePrompt.text" values={{ string1: accessCode }}/>
            </div>
          )
        }
      </GroupTitle>

      <ContentPanel hasPadding={false} style={{ marginTop: 0 }}>
        <List hasBorder paddingSize="large">
          {files.map((file, i) => (
            <BasicListItem key={i} className="util-inset-listitem-frame-padding">
              <PublicShareCardFileListItem
                file={file}
                renderFileName={renderFileName}
                passcode={accessCode}
              />
            </BasicListItem>
          ))}
        </List>
      </ContentPanel>
    </div>
  );
};
