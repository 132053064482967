import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Card, Icons } from '../../../../bphComponents/bundle';

interface Props {
  onCreate(): void;
}

const NewContactCard: React.FC<Props> = (props) => {
  return (
    <Card.CardButton onClick={props.onCreate} className="bkgd-color-profile font-color-gsLightest">
      <FormattedMessage id="contactsAdd.addNew.button.label" />
      <Icons.IconAddOutline.Medium color="white" />
    </Card.CardButton>
  );
};

export default NewContactCard;
