
import React, { FC, useState } from 'react';

import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from './ScrollAware';
import ContentFrame from '../applicationFrame/ContentFrame';
import { AppHeaderScrollableText } from './appNav/AppHeaderScrollable';
import ContentWrapper from './ContentWrapper';

import { Card } from '../../bphComponents/bundle';
import { FormattedMessage } from 'react-intl';

interface Props {
  phrase: {
    title: {
      id: string;
      values?: any;
    };
    contentTitle?: {
      id: string;
      values?: any;
    };
  };
  initialValue: any;
  isMultiSelect: boolean;
  isValid(v): boolean;
  onCancel(): void;
  onSubmit(v): void;
  cards: {
    data: any[];
    cardProps?: any;
    firstItem?(onClick?): any;
    lastItem?(onClick?): any;
    renderDataItem?(data, onClick, isSelected): any;
  }[];
}

const MultiCardListView: FC<Props> = (props) => {
  const { cards, initialValue, onSubmit, phrase, onCancel, isValid, isMultiSelect } = props;
  const [currentValue, setValue] = useState(initialValue);

  const setListValue = (v) => {
    let newValue = [];
    if (isMultiSelect) {
      const found = currentValue.indexOf(v);
      if (found > -1) {
        newValue = [...currentValue];
        newValue.splice(found, 1);
      } else {
        newValue = [...currentValue, v];
      }
    } else {
      newValue = v;
    }

    setValue(newValue);
  };

  const isSelected = (v) => {
    return isMultiSelect ? currentValue.includes(v) : currentValue === v;
  };

  return (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={!isValid(currentValue)}
          cancel={onCancel}
          submit={() => { onSubmit(currentValue); }}
          onDisabledClick={() => {}}
          color="white"
          hasDropShadow
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText {...phrase.title} />

            <ContentWrapper>
              {
                phrase.contentTitle && (
                  <Card.ExternalCardTitle>
                    <FormattedMessage {...phrase.contentTitle} />
                  </Card.ExternalCardTitle>
                )
              }

              {
                cards.map((c, i) => {
                  const { renderDataItem, firstItem, lastItem, data, cardProps } = c;
                  return (
                    <Card.CardShape key={i} {...cardProps}>
                      {firstItem && firstItem(setListValue)}
                      {
                        data.length > 0 && data.map((cc, ii) => {
                          return renderDataItem(cc, setListValue, isSelected(cc.id));
                        })
                      }
                      {lastItem && lastItem(setListValue)}
                    </Card.CardShape>
                  );
                })
              }
            </ContentWrapper>
          </ScrollAwareScroller>
        </ContentFrame>
      </ScrollAwareContext>
    </div>
  );
};

export default MultiCardListView;
