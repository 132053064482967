
import React from 'react';

import RouteNames from '../../appAuthorized/RouteNames';

import ViewContactSelect from './components/ViewContactSelect';

import { useAttachments } from '../../hooks/useAttachments';
import { useNavigate } from '../../hooks/useNavigate';
import { useContactsForActiveProfile } from '../../hooks/useContacts';
import { useUIState } from '../../hooks/useUIState';
import AppUtils from '../../../utilities/AppUtils';
import { attachmentTypes } from '../../../models/AttachmentModel';
import { useHealthEntryValuesForActiveProfile } from '../../hooks/useHealthEntry';

function getFinalRoute(heValueToHeLookup, attachmentType, parentId) {
  switch (attachmentType) {
    case attachmentTypes.healthEntryValue:
      return RouteNames.healthEntryValue(heValueToHeLookup[parentId], parentId);
    case attachmentTypes.healthEntry:
      return RouteNames.healthEntry(parentId);
    case attachmentTypes.trackable:
      return RouteNames.trackableObservationList(parentId);
    case attachmentTypes.trackableObservation:
      return RouteNames.trackableObservation(parentId);
    default:
      return RouteNames.home();
  }
}

interface Props {
  match: any;
  location: any;
}
const ViewContactAttach: React.FC<Props> = (props) => {
  const queryParams = AppUtils.getQueryParams(props.location.search);
  const { attachmentType } = queryParams;
  const parentId = Number(queryParams.parentId);

  const [{ activeProfileId }] = useUIState();
  const [, attachmentActions] = useAttachments();
  const [, navActions] = useNavigate();
  const [{ isLoaded }, { groupContactsByProvider }] = useContactsForActiveProfile();
  const [{ heValueToHeLookup }] = useHealthEntryValuesForActiveProfile();

  const groupContacts = groupContactsByProvider();

  return (
    <ViewContactSelect
      contactListByProfile={{
        [activeProfileId]: groupContacts.providerContacts,
      }}
      isLoaded={isLoaded}
      titlePhrase={{
        id: 'healthProvidersSelect.title',
      }}
      isAttaching={true}
      noContactRoute={RouteNames.contactAttachNew(attachmentType, parentId)}
      onCreate={() => {
        navActions.replace(RouteNames.contactAttachNew(attachmentType, parentId));
      }}
      onSubmit={(selectedContacts) => {
        const attachPromises = Object.keys(selectedContacts).map(contactId => {
          return attachmentActions.apiAttachContact(Number(contactId), attachmentType, parentId);
        });

        return Promise.all(attachPromises).then(() => {
          navActions.goBackTo(getFinalRoute(heValueToHeLookup, attachmentType, parentId));
        });
      }}
    />
  );
};

export default ViewContactAttach;
