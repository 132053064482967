import React, { SFC } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { selectors as listSelectors } from '../redux/ducks/lists/lists';

interface BasicContactSpecialtyLabelProps {
  contactLabel: any;
  defaultName: string;
}

const ContactLabel: SFC<BasicContactSpecialtyLabelProps> = (props) => {
  if (!props.contactLabel) { return null; }
  return (
    <FormattedMessage id={props.contactLabel.key} />
  );
};

function mapStateToProps(state, props) {
  return {
    contactLabel: listSelectors.getContactDetailLabel(state, props.id, props.defaultName),
  };
}

export default connect(mapStateToProps)(ContactLabel);
