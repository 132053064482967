import R from '../RouteNames';

const id = '\\d+';

const toBeHidden = [
  R.newProfileSetup(),
  R.newUserSetup(),
  R.token(''),
  R.joinGroupInstructions(''),
];

const toBeShielded = [
  R.profileSetup(),
  R.createDependentProfile(),

  R.changeEmail(),
  R.changePassword(),
  R.mfaEnabled(),

  R.file(''),

  R.findGroup(),
  R.leaveGroup(id),
  R.joinGroup(id),
  R.groupInfo(id),
  R.surveys(id),
  R.groupResources(id),

  R.surveyIntro(id, id),

  R.createHealthEntry(''),
  R.healthEntry(id),
  R.healthEntryChangeSearch(id),
  R.healthEntryChangeList(id),
  R.healthEntryWhen(id),
  R.healthEntryDiagnosis(id),
  R.healthEntryAnatomy(id),
  R.healthEntryStoppingReason(id),
  R.healthEntryReaction(id),
  R.healthEntryAmountAndFrequency(id),
  R.healthEntryFrequencyUnit(id, id),
  R.healthEntryAmountUnit(id),
  R.reviewPendingData(),

  R.trackableObservationList(id),

  R.dataConnectionsSettings(),

  R.healthEntryValue(id, id),

  R.myBackpackSelectionFiles(),

  R.intendedGroup(),

  R.profileName(),
  R.profileDateOfBirth(),
  R.profileGender(),
  R.profileBloodType(),
  R.profileRace(),
  R.profileResidence(),
  R.profileRaceSelfIdentity(),
  R.profilePhotoCrop(),
  R.profilePhotoError(),
  R.profileAccessPermissions(id),
  R.profileInviteOwner(),
  R.profileInviteSelf(),
  R.profileDelete(),

  R.contact(id),
  R.contactAttach(),
  R.contactAttachNew(),
  R.contactAdd(),
  R.contactEdit(id),
  R.contactEditNew(),
  R.contactDetail(id, id),
  R.contactSpecialty(id),
  R.contactCustomSpecialty(id),
  R.contactRelationship(id),
  R.contactCustomRelationship(id),
  R.contactHealthcareProxy(id),

  R.setPreferredLanguage(),
  R.deleteAccountSurvey(),
  R.deleteAccountConfirm(),
  R.deleteAccountSuccess(),
  R.communicationOptions(),

  R.profileSetup(),
  R.userSetup(),

  R.changePasswordMfa(),
  R.mfaSetMobileNumber(),
  R.mfaVerifyMobileNumber(),

  R.shareCardView(id),
  R.shareCard(id),
];

export function isMenuVisible(data, location): boolean {
  return !!data && !toBeHidden.find(route => (location.pathname + location.search).match('^' + route));
}

export function isMenuShielded(data, location): boolean {
  return !!toBeShielded.find(route => (location.pathname + location.search).match('^' + route));
}
