import React from 'react';
import { FormattedMessage } from 'react-intl';

import { eUserType } from '../../../../apiServices/SurveyService';
import { useActiveProfile } from '../../../hooks/useProfile';
import ProfileModel from '../../../../models/ProfileModel';

export const UserTypedMessage = (props) => {
  const [activeProfileData] = useActiveProfile();
  const profile = new ProfileModel(activeProfileData.data);
  const userType = profile.isDependent() ? eUserType.Dependent : eUserType.Primary;
  const name = profile.greetingName();

  const { user, notUser } = props;
  const typedText = userType === eUserType.Primary ? user : notUser;

  return <FormattedMessage id={typedText} values={{ string1: name }}/>;
};
