import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ViewProfileAccess from './ViewProfileAccess';
import ViewAccessPermissions from './ViewAccessPermissions';
import ViewProfileInviteOwner from './ViewProfileInviteOwner';

import RouteNames from '../../../RouteNames';

class ProfileAccessRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={RouteNames.profileInviteOwner()} component={ViewProfileInviteOwner} />
        <Route
          exact
          path={RouteNames.profileInviteSelf()}
          render={(props) => <ViewProfileInviteOwner {...props} inviteSelf={true} />}
        />
        <Route exact path={RouteNames.profileAccessPermissions(':ownerProfileId')} component={ViewAccessPermissions} />
        <Route path="/" component={ViewProfileAccess} />
      </Switch>
    );
  }
}

export default ProfileAccessRoutes;
