
import React from 'react';

import ContactForm from './components/ContactForm';

import RouteNames from '../../appAuthorized/RouteNames';
import AppUtils from '../../../utilities/AppUtils';

import { useContactForActiveProfile } from '../../hooks/useContacts';
import { useNavigate } from '../../hooks/useNavigate';
import { useUIEditContact } from '../../hooks/useUIEdit';
import { useUIState } from '../../hooks/useUIState';

const ViewContactEdit = (props) => {
  const contactId = Number(props.match.params.contactId);
  const referrer = AppUtils.getQueryParams(window.location.search).referrer;

  const [, contactActions] = useContactForActiveProfile(contactId);
  const [, uiActions] = useUIState();
  const [{ editingContact }, uiEditActions] = useUIEditContact(contactId);
  const [, navActions] = useNavigate();

  return (
    <ContactForm
      contactId={contactId}
      contact={editingContact}
      titlePhrase={{ id: 'contact.editContact.title' }}

      onSubmit={(data) => {
        contactActions.apiUpdateContact(data)
          .then(() => {
            const targetUrl = referrer ? referrer : RouteNames.contact(`${contactId}`);
            navActions.goBackTo(targetUrl);
            uiEditActions.clearContact();
          }).catch((apiError) => {
            uiActions.showNotification(apiError.messageKey());
          });
      }}
      onCancel={() => {
        const targetUrl = referrer ? referrer : RouteNames.contact(`${contactId}`);
        navActions.goBackTo(targetUrl);
        uiEditActions.clearContact();
      }}
      onDelete={() => {
        contactActions.apiDeleteContact()
          .then(() => {
            referrer
              ? navActions.goBackTo(referrer)
              : navActions.goBack(RouteNames.contactList(), null, [{ pathname: RouteNames.contact(contactId) }, { pathname: RouteNames.contactAdd() }]);
            uiEditActions.clearContact();
          });
      }}
    />
  );
};

export default ViewContactEdit;
