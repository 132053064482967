import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import ShareCardIncludedTypeNames from '../../../../../constants/ShareCardIncludedTypeNames';
import healthEntryTextFormatter from '../../../../../models/HealthEntryTextFormatting';

import ContentPanel from '../ContentPanel';
import GroupTitle from './GroupTitle';
import { generalHealthEntry } from '../../../../../models/HealthEntryCompose';
import HealthEntryCategoryNames from '../../../../../constants/HealthEntryCategoryNames';
import List, { BasicListItem } from '../../../../components/List';
import HealthEntryNameText from '../../../../components/HealthEntryNameText';
import healthEntryUtility from '../../../../../models/HealthEntryUtility';
import { getIconFromHealthEntryCategory } from '../../../../components/icons/HealthEntryIcons';
import { HealthEntryData } from '../../../../../models/HealthEntry'

interface HealthEntrySummaryProps {
  healthEntry: any;
  includedValues: ShareCardIncludedTypeNames;
  languageCode: string;
  className?: string;
  style?: object;
}


const titles = {
  [HealthEntryCategoryNames.allergy]: 'list.healthEntry.allergies.name',
  [HealthEntryCategoryNames.condition]: 'list.healthEntry.conditions.name',
  [HealthEntryCategoryNames.medication]: 'list.healthEntry.medications.name',
  [HealthEntryCategoryNames.otherTreatment]: 'list.healthEntry.treatments.name',
  [HealthEntryCategoryNames.procedure]: 'list.healthEntry.procedures.name',
  [HealthEntryCategoryNames.vaccination]: 'list.healthEntry.vaccinations.name',
  [HealthEntryCategoryNames.lab]: 'list.healthEntry.lab.name',
  [HealthEntryCategoryNames.measurement]: 'list.healthEntry.measurements.name',
  [HealthEntryCategoryNames.geneticResult]: 'list.healthEntry.genes.name',
  [HealthEntryCategoryNames.geneVariantResult]: 'list.healthEntry.geneticVariantResults.name',
};


function categoryTitleLatestValues(category) {
  switch (category) {
    case HealthEntryCategoryNames.allergy:
    case HealthEntryCategoryNames.condition:
    case HealthEntryCategoryNames.medication:
    case HealthEntryCategoryNames.otherTreatment:
    case HealthEntryCategoryNames.procedure:
    case HealthEntryCategoryNames.vaccination:
    case HealthEntryCategoryNames.geneVariantResult:
      return <FormattedMessage id={titles[category]} values={{ digit1: 2 }} />;
    case HealthEntryCategoryNames.lab:
    case HealthEntryCategoryNames.measurement:
    case HealthEntryCategoryNames.geneticResult:
      return (
        <FormattedMessage id={titles[category]} values={{ digit1: 2 }}>
          {
            (string1) => (
              <FormattedMessage id="share.shareCardCategory.mostRecentValue.text" values={{ string1 }} />
            )
          }
        </FormattedMessage>
      );
    default:
      return null;
  }
}

class HealthEntrySummary extends React.Component<HealthEntrySummaryProps, {}> {
  render() {
    const { healthEntry, includedValues, languageCode, className } = this.props;
    const he = generalHealthEntry(healthEntry, languageCode);
    const heA = healthEntryTextFormatter(he, includedValues);

    const fullSummaryList = heA.fullSummaryList(languageCode);
    fullSummaryList.shift();

    return (
      <div>
        {
          fullSummaryList.map((s, index) => (
            <div className={className} style={{ marginBottom: (index < fullSummaryList.length - 1) ? 8 : 0 }} key={index}>
              {s}
            </div>
          ))
        }
      </div>
    );
  }
}

const RelatedTracking = (props) => {
  const { healthEntry, trackablesLookup, entryTrackables } = props;
  if (entryTrackables.length === 0) { return null; }

  const relatedEntries = entryTrackables.filter(et => et.health_entry_id === healthEntry.id);
  if (relatedEntries.length === 0) { return null; }

  const trackableNames = relatedEntries
    .map(et => {
      const t = trackablesLookup[et.trackable_id];
      return t ? t.name : '';
    }).sort().join(', ');

  return (
    <div className="font-subhead font-color-gsMedDark" style={{ marginTop: 8 }}>
      <FormattedMessage id="share.relatedToTrackable.label" values={{ string1: trackableNames }} />
    </div>
  );
};

interface HealthEntryProps {
  healthEntry: HealthEntryData;
  languageCode: string;
  includedValues: ShareCardIncludedTypeNames;
  trackablesLookup: any;
  entryTrackables: any[];
}

const HealthEntry: React.FC<HealthEntryProps> = (props) => {
  const { healthEntry, languageCode, includedValues, trackablesLookup, entryTrackables } = props;
  const { category } = healthEntry;
  const heInstance = generalHealthEntry(healthEntry, languageCode);

  switch (category) {
    case HealthEntryCategoryNames.condition:
    case HealthEntryCategoryNames.allergy:
    case HealthEntryCategoryNames.medication:
    case HealthEntryCategoryNames.otherTreatment:
    case HealthEntryCategoryNames.procedure:
    case HealthEntryCategoryNames.vaccination:
    case HealthEntryCategoryNames.lab:
    case HealthEntryCategoryNames.measurement:
    case HealthEntryCategoryNames.geneticResult:
    case HealthEntryCategoryNames.geneVariantResult:
      return (
        <BasicListItem className="util-inset-listitem-frame-padding">
          <HealthEntryNameText
            className="font-headline font-color-gsDarkest util-text-break-word"
            style={{ marginBottom: 8, display: 'inline-block' }}
            healthEntry={heInstance}
            languageCode={languageCode}
            includedValues={includedValues}
          />
          <HealthEntrySummary
            className="font-subhead font-color-gsDarkest util-text-break-word"
            style={{ marginBottom: 8 }}
            healthEntry={healthEntry}
            languageCode={languageCode}
            includedValues={includedValues}
          />
          <RelatedTracking
            healthEntry={healthEntry}
            includedValues={includedValues}
            trackablesLookup={trackablesLookup}
            entryTrackables={entryTrackables}
          />
        </BasicListItem>
      );
    default:
      return <div />;
  }
};

interface ShareCardHealthEntryGroupProps {
  category: string;
  healthEntries: HealthEntryData[];
  languageCode: string;
  hasPeriodTitle: boolean;
  trackablesLookup: any;
  entryTrackables: any[];
  newer?: any[];
  older?: any[];
}
const SortedShareCardHealthEntryGroup: React.FC<ShareCardHealthEntryGroupProps> = (props) => {
  const { newer, older, category, trackablesLookup, entryTrackables, languageCode, hasPeriodTitle } = props;

  return (
    <div>
      <GroupTitle Icon={getIconFromHealthEntryCategory(category)} text={categoryTitleLatestValues(category)} />

      <ContentPanel hasPadding={false} style={{ marginTop: 0 }}>
        <List hasBorder paddingSize="large">
          {
            (hasPeriodTitle && newer.length > 0) && (
              <BasicListItem className="util-inset-listitem-frame-padding font-subhead font-color-gsMedDark">
                <FormattedMessage id="ui-data.healthentryperiod.current.text" />
              </BasicListItem>
            )
          }
          {
            newer.map(he => (
              <HealthEntry
                key={he.id}
                healthEntry={he.data()}
                languageCode={languageCode}
                includedValues={ShareCardIncludedTypeNames.latest}
                trackablesLookup={trackablesLookup}
                entryTrackables={entryTrackables}
              />
            ))
          }
          {
            (hasPeriodTitle && older.length) > 0 && (
              <BasicListItem className="util-inset-listitem-frame-padding font-subhead font-color-gsMedDark">
                <FormattedMessage id="ui-data.healthentryperiod.past.text" />
              </BasicListItem>
            )
          }
          {
            older.map(he => (
              <HealthEntry
                key={he.id}
                healthEntry={he.data()}
                languageCode={languageCode}
                includedValues={ShareCardIncludedTypeNames.latest}
                trackablesLookup={trackablesLookup}
                entryTrackables={entryTrackables}
              />
            ))
          }
        </List>
      </ContentPanel>
    </div>
  );
};

const ShareCardHealthEntryGroup: React.FC<ShareCardHealthEntryGroupProps> = (props) => {
  const { category, healthEntries, languageCode } = props;

  if (!healthEntries || healthEntries.length === 0) { return null; }

  const hes = healthEntries.map(a => generalHealthEntry(a, languageCode));
  const sortedByName = healthEntryUtility.sort(hes, languageCode);
  const { newer, older } = healthEntryUtility.sortHealthEntriesByTime(category, sortedByName);

  return (
    <SortedShareCardHealthEntryGroup
      {...props}
      newer={newer}
      older={older}
    />
  );
};

export const ShareCardHealthEntryAllergyGroup: React.FC<ShareCardHealthEntryGroupProps> = injectIntl((props) => {
  const { category, healthEntries, languageCode, intl } = props;

  if (!healthEntries || healthEntries.length === 0) { return null; }

  const hes = healthEntries.map(a => generalHealthEntry(a, languageCode));
  const sortedByName = healthEntryUtility.sortAllergies(hes, languageCode, intl);
  const { newer, older } = healthEntryUtility.sortHealthEntriesByTime(category, sortedByName);

  return (
    <SortedShareCardHealthEntryGroup
      {...props}
      newer={newer}
      older={older}
    />
  );
});

export default ShareCardHealthEntryGroup;
