import React from 'react';

import { ContentList } from '../../contentViews/ContentList';

import { ProfileNoteListItemFlaggedRight } from '../../components/NoteListItem';

import { notesZeroState } from '../../components/healthEntriesList/ZeroState';
import RouteNames from '../../appAuthorized/RouteNames';

import { useNotesForActiveProfile } from '../../hooks/useNote';
import { useUIState } from '../../hooks/useUIState';
import { useNavigate } from '../../hooks/useNavigate';

const BackackNotes: React.FC = () => {
  const [, uiStateActions] = useUIState();
  const [, navActions] = useNavigate();
  const [{ noteList, isLoaded }] = useNotesForActiveProfile();
  const notes = noteList.map(n => ({ note: n, widthOffset: 0 }));

  React.useEffect(() => {
    if (!isLoaded && noteList.length === 0) {
      uiStateActions.setStartLoading();
    } else {
      uiStateActions.setStopLoading();
    }
  }, [isLoaded]);

  if (!isLoaded) { return null; }

  return (
    <ContentList
      collection={notes}
      zeroState={notesZeroState}
      onClickItem={(n) => {
        navActions.goTo(RouteNames.note(n.note.data.id));
      }}
      itemComponent={ProfileNoteListItemFlaggedRight}
      helpContent={null}
    />
  );
};

export default BackackNotes;
