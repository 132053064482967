import React from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { Card, CardRowUI } from '../../../../bphComponents/bundle';
import ShareCardModel from '../../../../models/ShareCardModel';
import { ShareCardDefinitionModel } from '../../../../models/ShareCardDefinitionModel';
import ShareCardIncludedValueToggleSwitch from '../../../components/ShareCardIncludedValueToggleSwitch';
import { useShareCardListForActiveProfile } from '../../../hooks/useShareCard';
import { useLists } from '../../../hooks/useLists';
import AppUtils from '../../../../utilities/AppUtils';

interface Props {
  fileId: number;
  updateShareCard(d): void;
  shareCardList: any[];
  intl: any;
}
const IntlFileShareCards: React.FC<Props> = (props) => {
  const { fileId, shareCardList, updateShareCard, intl } = props;
  const languageCode = intl.locale;
  const [{ colorShareCardList }] = useLists();

  const [{ shareCardDefinitionLookup, isLoaded }, scActions] = useShareCardListForActiveProfile();

  const toggleShareCardInclusion = (d) => {
    const newData = ShareCardModel.toggleIncludedFile(d, fileId);
    updateShareCard(newData);
  };

  return (
    <Card.CardShape>
      {
        shareCardList.map((scData: any) => {
          const definition = shareCardDefinitionLookup[scData.definition_id];
          if (!definition) { return null; }

          const isIncluded = ShareCardModel.includesFile(scData, fileId);
          const isRequired = ShareCardDefinitionModel.isFilesRequired(definition);
          // files do not have multi-occurence values, so there is never a subtoggle
          const includedValue = null;
          const isExpanded = false;
          const activeColor = isIncluded ? 'font-color-gsDarkest' : 'font-color-gsDark';

          const title = ShareCardModel.getTitle(scData, definition, languageCode);
          const colorData = ShareCardModel.getColor(scData, definition, colorShareCardList);
          const color = AppUtils.cssColor(colorData);

          return (
            <Card.CardSection key={scData.id} hasBottomBorder>
              <CardRowUI.SelectableRow
                isSelected={isIncluded}
                hasBottomBorder={false}
                disabledPadding={includedValue ? ['bottom'] : null}
                selectedColor={color}
                notSelectedColor={color}
                style={{}}
                onClick={(
                  isRequired
                  ? null
                  : isExpanded
                  ? null
                  : () => { toggleShareCardInclusion(scData); }
                )}
                onCircleClick={(
                  isRequired
                  ? null
                  : isExpanded
                  ? () => { toggleShareCardInclusion(scData); }
                  : null
                )}
              >
                <div className={classnames('font-body', activeColor)}>{title}</div>
              </CardRowUI.SelectableRow>

              {
                isExpanded && (
                  <>
                    <div style={{ marginTop: 24 }} />
                    <Card.CardContent disabledPadding={['top']}>
                      <ShareCardIncludedValueToggleSwitch
                        uniqueID={`${scData.id}`}
                        value={includedValue.included_values}
                        isDisabled={isRequired}
                        onToggle={() => {
                          // files never allow for toggling included values
                        }}
                      />
                    </Card.CardContent>
                  </>
                )
              }
            </Card.CardSection>
          );
        })
      }
    </Card.CardShape>
  );
};

export const FileShareCards = injectIntl(IntlFileShareCards);
