import { useSelector } from 'react-redux';

import FileUploadBundle from '../../models/FileUploadBundle';
import FileModel from '../../models/FileModel';

export function usePendingDataFileUpload(fileId) {
  const pendingData = useSelector(state => state.pendingEdit);
  let rawFile = null;
  let newFile = null;

  if (!fileId) {
    const fileBundle = new FileUploadBundle(pendingData.data);
    newFile = new FileModel(fileBundle.data.file);
    rawFile = fileBundle.data.rawFile;
  }

  return [{
    rawFile,
    newFile,
  }];
}
