import * as _ from 'lodash';

import NotificationService from '../../../apiServices/NotificationService';

export const types = {
  CREATE: 'app/notifications/CREATE',
  REMOVE: 'app/notifications/REMOVE',
  REQUEST_ALL: 'app/notifications/REQUEST_ALL',
  RECEIVE_ALL: 'app/notifications/RECEIVE_ALL',
  ACCEPT: 'app/notifications/ACCEPT',
  REJECT: 'app/notifications/REJECT',
  REQUEST_COUNT: 'app/notifications/REQUEST_COUNT',
  RECEIVE_COUNT: 'app/notifications/RECEIVE_COUNT',
};

const initialState = {
  collection: null,
  count: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.REQUEST_ALL:
      return state;
    case types.RECEIVE_ALL:
      return _.assign({}, state, action.payload);
    case types.RECEIVE_COUNT:
      return _.assign({}, state, action.payload);
    default:
      return state;
  }
}

export const selectors = {
  getAll: (state) => (state.notifications.collection || []),
  getCount: state => state.notifications.count,
  isLoaded: (state) => state.notifications.collection !== null,
};

function receivedNotifications(collection) {
  return {
    type: types.RECEIVE_ALL,
    payload: {
      collection,
    },
  };
}

function receivedCount({ count }) {
  return {
    type: types.RECEIVE_COUNT,
    payload: {
      count,
    },
  };
}

function requestAll() {
  return (dispatch) => {
    return new Promise((res, rej) => {
      NotificationService.getAll((err, notifications) => {
        if (err) {
          rej(err);
          return;
        }

        dispatch(receivedNotifications(notifications));
        res(notifications);
      });
    });
  };
}

function requestCount() {
  return (dispatch, getState) => {
    return new Promise((res, rej) => {
      NotificationService.count((err, countData) => {
        if (err) {
          rej(err);
          return;
        }

        if (countData.count !== selectors.getCount(getState())) {
          dispatch(receivedCount(countData));
        }
        res();
      });
    });
  };
}

function acceptOwnerInvite(notificationId) {
  return (dispatch) => {
    return NotificationService.sharedOwnerAccept(notificationId)
      .then(() => {
        dispatch(requestAll());
      });
  };
}

function rejectOwnerInvite(notificationId) {
  return (dispatch) => {
    return NotificationService.sharedOwnerReject(notificationId)
      .then(() => {
        dispatch(requestAll());
      });
  };
}

export const actions = {
  requestAll,
  requestCount,
  acceptOwnerInvite,
  rejectOwnerInvite,
};
