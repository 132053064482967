import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { SecondaryButton, CloseButton } from '../buttons/Buttons';
import { actions as systemModalActions } from '../../redux/ducks/systemModal';
import FlexRow from '../FlexRow';
import PreformattedText from '../PreformattedText';
import Spacer from '../Spacer';

interface Props {
  triggerAnimation: Function;
  dismissModal: Function;
  children: any;
  isFullHeight: boolean;
  onDismiss?(): void;
}

const mapDispatchToProps = (dispatch) => {
  return {
    triggerAnimation: () => {
      dispatch(systemModalActions.load());
    },
    dismissModal: () => {
      dispatch(systemModalActions.dismiss());
    },
    dismiss: () => {
      dispatch(systemModalActions.dismiss());
    },
  };
};


interface PhraseType {
  id: string;
  values?: { [key: string]: any; };
}

interface Props {
  intl: any;
  dismiss(): void;
  phraseMessages: {
    title: PhraseType;
    subheading: PhraseType;
    body: PhraseType | PhraseType[];
    button: PhraseType;
    alternateButton?: PhraseType;
  };
  onDismiss?(): void;
  onConfirm(): void;
  onAlternate?(): void;
  hasCloseButton: boolean;
  titleClassName: string;
  buttonClassName: string;
  isBodyPreformatted: boolean;
  onView?(): void;
}

const InfoModal = (props: Props) => (
  <div className={classnames('cmp-infomodal', { 'is-fullheight': props.isFullHeight })}>
    <div className="_controls">
      <CloseButton
        align="right"
        verticalAlign="top"
        className="bkgd-color-gsMiddleDT"
        onClick={() => {
          if (props.onDismiss) {
            props.onDismiss();
          } else {
            props.dismissModal();
          }
        }}
      />
    </div>
    <div className="_content">
      {
        typeof props.children === 'function'
          ? props.children(props.triggerAnimation)
          : props.children
        }
    </div>
  </div>
);

export const GeneralInfoModal = (props) => {
  const { isBodyPreformatted, phraseMessages, hasCloseButton, titleClassName, buttonClassName } = props;
  const bodyPhrases = (Array.isArray(phraseMessages.body) ? phraseMessages.body : [phraseMessages.body]).filter(p => !!p);

  React.useEffect(() => {
    if (props.onView) {
      props.onView();
    }
  }, []);

  function onDismiss() {
    props.dismiss();

    if (props.onDismiss) { props.onDismiss(); }
  }

  function onConfirm() {
    props.dismiss();
    if (props.onConfirm) { props.onConfirm(); }
  }

  async function onAlternate() {
    await props.onAlternate();
    props.dismiss();
  }

  return (
    <div className="cmp-warningmodal">
      <FlexRow style={{ alignItems: 'flex-start' }}>
        <div />
        {
          hasCloseButton && <CloseButton align="right" verticalAlign="top" className="bkgd-color-gsMiddleDT" onClick={onDismiss} />
        }
      </FlexRow>

      <div className={`font-title-2 ${titleClassName}`}>
        <FormattedMessage {...phraseMessages.title} />
      </div>

      {
        phraseMessages.subheading ? (
          <>
            <Spacer size="4" />

            <div className="font-headline font-color-gsDark">
              <FormattedMessage {...phraseMessages.subheading} />
            </div>
          </>
        ) : null
      }

      <Spacer size="12" />

      {
        isBodyPreformatted ? (
          <div className="__body">
            {
              bodyPhrases.map((p, i) => (
                <PreformattedText key={i} className="font-subhead font-color-gsDark">
                  <FormattedMessage {...p} />
                </PreformattedText>
              ))
            }
            {
              props.children ? (
                <div style={{ marginTop: 16 }}>
                  {props.children}
                </div>
              ) : null
            }
          </div>
        ) : (
          <div className="__body">
            {
              bodyPhrases.map((p, i) => (
                <PreformattedText key={i} className="font-subhead font-color-gsDark">
                  <FormattedMessage {...p} />
                </PreformattedText>
              ))
            }
            {
              props.children ? (
                <div style={{ marginTop: 16 }}>
                  {props.children}
                </div>
              ) : null
            }
          </div>
        )
      }

      <Spacer size="16" />

      <div className="control">
        <SecondaryButton className={`font-title-2 ${buttonClassName}`} onClick={onConfirm} style={{ wordBreak: 'break-word' }}>
          <FormattedMessage {...phraseMessages.button} />
        </SecondaryButton>
      </div>

      {
        phraseMessages.alternateButton && (
          <div className="cmp-alternate-control font-subhead font-color-gsDark" onClick={onAlternate}>
            <FormattedMessage {...phraseMessages.alternateButton} />
          </div>
        )
      }
    </div>
  );
};

function mapStateToProps() {
  return {};
}

export const ProfileThemedInfoModal = connect(mapStateToProps, mapDispatchToProps)((props) => (
  <GeneralInfoModal
    {...props}
    titleClassName="font-color-profile"
    buttonClassName="font-color-profile-accent border-profile-accent"
  />
));

export const GroupThemedInfoModal = connect(mapStateToProps, mapDispatchToProps)((props) => (
  <GeneralInfoModal
    {...props}
    titleClassName="theme-group base color"
    buttonClassName="theme-group accent color border"
  />
));

export default connect(() => ({}), mapDispatchToProps)(InfoModal);
