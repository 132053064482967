
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconDiagnosis } from '../../../components/icons/IconDiagnosis';
import { TimeFromNow, HealthEntryDateSummaryDate, HealthEntryDateSummaryContext } from '../../../localization/LocalizedDate';
import { LocalizedDiagnosis } from '../../../localization/LocalizedDiagnosis';
import { Card, Icons, CardRowUI } from '../../../../bphComponents/bundle';

import SortingUtil from '../../../../utilities/SortingUtil';
import COLORS from '../../../styles/Colors';

import { HEPropertyCollection } from './HEPropertyCollection';
import HECurrentPastToggle from './HECurrentPastToggle';
import RouteNames from '../../../appAuthorized/RouteNames';
import OldIcons from '../../../components/icons/Icons';
import MedliAmountText from '../../../components/MedliAmountText';
import FrequencyText from '../../../components/FrequencyText';
import GeneVariantResultText from '../../../components/GeneVariantResultText';
import GeneAttributeResultText from '../../../components/GeneAttributeResultText';
import { HealthEntryGeneAttributeInterface, HealthEntryGeneAttributeValueInterface } from '../../../../constants/HealthEntryGeneAttributes';
import { useNavigate } from '../../../hooks/useNavigate';
import { useHealthEntryForActiveProfile, useHealthEntryAndValueForActiveProfile } from '../../../hooks/useHealthEntry';
import { useMedliConstantsStoppingReasons } from '../../../hooks/useMedli';

interface Props {
  isChild?: boolean;
  languageCode: string;
  healthEntry: any;
  parentHealthEntryId?: number;
}

const EmptyRow = (props) => {
  return (
    <CardRowUI.HealthEntryProperty
      {...props}
      onClick={props.onCreate}
      color={COLORS.healthEntryPropertyEmpty}
      hasBottomBorder={true}
      value={props.emptyValue}
      subTitle={null}
    />
  );
};

const getAnatomy = (c) => {
  return c ? c.name : '';
};

export const Anatomy: React.FC<Props> = (props) => {
  const { healthEntry, languageCode } = props;
  const healthEntryId = healthEntry.id;
  const anatomy = SortingUtil.sortOptionListAlphabetically(healthEntry.anatomy.getUnique().map((a) => ({ name: a.strings[languageCode], id: a.id })));
  const hasAnatomy = anatomy.length > 0;

  const [, navActions] = useNavigate();
  const [, heActions] = useHealthEntryForActiveProfile(healthEntryId);

  const handleOnSelectAnatomy = () => {
    navActions.goTo(RouteNames.healthEntryAnatomy(healthEntryId));
  };

  const handleOnRemoveAnatomy = (item) => {
    healthEntry.anatomy.remove(item.id);
    heActions.apiUpdate(healthEntry.data());
  };

  if (!hasAnatomy) {
    return (
      <EmptyRow
        languageCode={languageCode}
        icon={<Icons.IconAnatomy.Medium color={COLORS.healthEntryPropertyIconEmpty} lineHeight="18px" />}
        emptyValue={<FormattedMessage id="anatomy.addAnatomy.button.label" />}
        onCreate={handleOnSelectAnatomy}
      />
    );
  }

  return (
    <HEPropertyCollection
      languageCode={languageCode}
      icon={<Icons.IconAnatomy.Medium color={COLORS.healthEntryPropertyIconWithValue} lineHeight="18px" />}
      collection={anatomy}
      getValue={getAnatomy}
      addAnotherValue={<FormattedMessage id="anatomy.addAnotherAnatomy.button.label" />}
      onClick={null}
      onCreate={handleOnSelectAnatomy}
      onRemove={handleOnRemoveAnatomy}
    />
  );
};

export const Diagnosis: React.FC<Props> = (props) => {
  const { healthEntry, languageCode } = props;
  const healthEntryId = healthEntry.id;

  const [, navActions] = useNavigate();

  const value = healthEntry.diagnosis.getValue();
  const diagnosisDate = healthEntry.diagnosis.getDate() || null;

  const handleOnSelectDianosis = () => {
    navActions.goTo(RouteNames.healthEntryDiagnosis(healthEntryId));
  };

  if (!value) {
    return (
      <EmptyRow
        languageCode={languageCode}
        icon={<Icons.IconDiagnosis.Medium color={COLORS.healthEntryPropertyIconEmpty} />}
        emptyValue={<FormattedMessage id="healthEntry.addDiagnosis.button.label"/>}
        onCreate={handleOnSelectDianosis}
      />
    );
  }

  return (
    <CardRowUI.HealthEntryProperty
      hasBottomBorder={true}
      borderType="full"
      icon={<IconDiagnosis color={COLORS.healthEntryPropertyIconWithValue} />}
      color={COLORS.healthEntryPropertyWithValue}
      value={value ? <LocalizedDiagnosis healthEntry={healthEntry} /> : null}
      onClick={handleOnSelectDianosis}
      onRemove={null}
      subTitle={diagnosisDate ? <TimeFromNow dateData={diagnosisDate} /> : null}
    />
  );
};

export const DateSummary: React.FC<Props> = (props) => {
  const { healthEntry, languageCode, parentHealthEntryId, isChild } = props;
  const healthEntryId = healthEntry.id;
  const allowCurrentPastToggle = healthEntry.current && healthEntry.current.hasCurrent();
  const [, navActions] = useNavigate();
  const [, heActions] = isChild ? useHealthEntryAndValueForActiveProfile(parentHealthEntryId, healthEntryId) : useHealthEntryForActiveProfile(healthEntryId);
  const [, stopReasonActions] = useMedliConstantsStoppingReasons();

  let endDateData = {};
  const isCurrent = healthEntry.current ? healthEntry.current.isCurrent() : false;
  const isPeriod = healthEntry.period ? healthEntry.period.isPeriod() : false;
  let hasStart = false;
  let hasEnd = false;
  let startDateData = {};

  if (healthEntry.date) {
    // Multi Occurrence HE Values have date property
    hasStart = healthEntry.date.hasDate();
    startDateData = healthEntry.date.data();
  } else if (healthEntry.startDate) {
    // Single Occurence HE have startDate property
    hasStart = healthEntry.startDate.hasDate();
    startDateData = healthEntry.startDate.data();
  }
  if (healthEntry.endDate) {
    hasEnd = healthEntry.endDate.hasDate();
    endDateData = healthEntry.endDate.data();
  }

  const hasDate = hasStart || hasEnd;
  const addDateMessage = (isCurrent || !isPeriod)
    ? null
    : hasStart
    ? (hasEnd ? null : 'healthEntryDates.addEndDate.button.label')
    : 'healthEntryDates.addStartDate.button.label';

  const onSelectWhen = () => {
    if (parentHealthEntryId) {
      navActions.goTo(RouteNames.healthEntryValueWhen(parentHealthEntryId, healthEntryId));
    } else {
      navActions.goTo(RouteNames.healthEntryWhen(healthEntryId));
    }
  };

  const handleOnToggleIsCurrent = () => {
    healthEntry.current.toggle();
    if (healthEntry.current.isCurrent()) {
      healthEntry.endDate.clear();
      healthEntry.period.set(false);
      if (healthEntry.stoppingReason) {
        healthEntry.stoppingReason.remove();
      }
    } else {
      healthEntry.period.set(true);
    }
    const stopReason = healthEntry.stoppingReason ? healthEntry.stoppingReason.get() : null;

    if (healthEntry.reaction && stopReasonActions.isReaction(stopReason)) {
      healthEntry.reaction.clear();
    }

    heActions.apiUpdate(healthEntry.data());
  };

  return (
    <Card.CardSection hasBottomBorder={true}>
      {
        allowCurrentPastToggle && (
          <Card.CardContentFirst>
            <HECurrentPastToggle
              healthEntry={healthEntry}
              onToggle={handleOnToggleIsCurrent}
            />
          </Card.CardContentFirst>
        )
      }

      {
        hasDate ? (
          <CardRowUI.HealthEntryProperty
            hasBottomBorder={true}
            borderType="full"
            icon={<Icons.IconCalendar.Medium color={COLORS.healthEntryPropertyIconWithValue} />}
            value={(
              <HealthEntryDateSummaryDate
                startDateData={startDateData}
                endDateData={endDateData}
                isCurrent={isCurrent}
                isPeriod={isPeriod}
              />
            )}
            subTitle={(
              <HealthEntryDateSummaryContext
                startDateData={startDateData}
                endDateData={endDateData}
                isCurrent={isCurrent}
                isPeriod={isPeriod}
              />
            )}
            onClick={onSelectWhen}
          >
            {
              addDateMessage && (
                <div className="font-color-profile-accent font-body">
                  <FormattedMessage id={addDateMessage} />
                </div>
              )
            }

          </CardRowUI.HealthEntryProperty>
        ) : (
          <EmptyRow
            languageCode={languageCode}
            icon={<Icons.IconCalendar.Medium color={COLORS.healthEntryPropertyIconEmpty} />}
            emptyValue={isCurrent ? <FormattedMessage id="currentDates.title.text" /> : <FormattedMessage id="healthEntryDates.addDates.button.label" />}
            onCreate={onSelectWhen}
          />
        )
      }
    </Card.CardSection>
  );
};

const formatAmount = (healthEntry, languageCode) => (
  <MedliAmountText
    amount={healthEntry.amount.get()}
    description={healthEntry.description ? healthEntry.description.get() : null}
    languageCode={languageCode}
  />
);

const formatFrequency = (healthEntry) => (
  <FrequencyText
    frequencyData={healthEntry.frequency.get()}
  />
);

const getAmountFrequencyValue = (healthEntry, languageCode) => {
  const hasAmount = healthEntry.amount && healthEntry.amount.hasAmount();
  const hasFrequency = healthEntry.frequency && healthEntry.frequency.hasFrequency();

  if (hasAmount && hasFrequency) {
    return (
      <span>
        {formatAmount(healthEntry, languageCode)}, <span style={{ textTransform: 'lowercase' }}>{formatFrequency(healthEntry)}</span>
      </span>
    );
  } else if (hasAmount) {
    return formatAmount(healthEntry, languageCode);
  } else if (hasFrequency) {
    return formatFrequency(healthEntry);
  } else {
    return null;
  }
};

export const AmountAndFrequency: React.FC<Props> = (props) => {
  const { healthEntry, languageCode } = props;
  const healthEntryId = healthEntry.id;
  const hasAmount = healthEntry.amount && healthEntry.amount.hasAmount();
  const hasFrequency = healthEntry.frequency && healthEntry.frequency.hasFrequency();
  const phraseKey = healthEntry.amount ? 'healthEntry.addAmtFreq.button.label' : 'healthEntry.addFreq.button.label';
  const hasValue = hasAmount || hasFrequency;

  const [, navActions] = useNavigate();

  const handleOnSelectAmountFreq = () => {
    navActions.goTo(RouteNames.healthEntryAmountAndFrequency(healthEntryId));
  };

  if (!hasValue) {
    return (
      <EmptyRow
        languageCode={languageCode}
        icon={<Icons.IconClockFrequency.Medium color={COLORS.healthEntryPropertyIconEmpty} />}
        emptyValue={<FormattedMessage id={phraseKey} />}
        onCreate={handleOnSelectAmountFreq}
      />
    );
  }

  return (
    <CardRowUI.HealthEntryProperty
      hasBottomBorder={true}
      borderType="full"
      icon={<Icons.IconClockFrequency.Medium color={COLORS.healthEntryPropertyIconWithValue} />}
      color={COLORS.healthEntryPropertyWithValue}
      value={getAmountFrequencyValue(healthEntry, languageCode)}
      onClick={handleOnSelectAmountFreq}
      onRemove={null}
      subTitle={null}
    />
  );
};

export const StoppingReason: React.FC<Props> = (props) => {
  const { healthEntry, languageCode } = props;
  const healthEntryId = healthEntry.id;

  const hasValue = healthEntry.stoppingReason.hasStoppingReason();
  const [, navActions] = useNavigate();
  const [, heActions] = useHealthEntryForActiveProfile(healthEntryId);
  const [, stopReasonActions] = useMedliConstantsStoppingReasons();

  const handleOnSelectStoppingReason = () => {
    navActions.goTo(RouteNames.healthEntryStoppingReason(healthEntryId));
  };

  const handleOnRemoveStoppingReason = () => {
    healthEntry.stoppingReason.remove();

    if (healthEntry.reaction && !stopReasonActions.isReaction(healthEntry.stoppingReason.get())) {
      healthEntry.reaction.clear();
    }
    heActions.apiUpdate(healthEntry.data());
  };

  if (!hasValue) {
    return (
      <EmptyRow
        languageCode={languageCode}
        icon={<Icons.IconStoppingReason.Medium color={COLORS.healthEntryPropertyIconEmpty} />}
        emptyValue={<FormattedMessage id="healthEntry.addStoppingReason.button.label"/>}
        onCreate={handleOnSelectStoppingReason}
      />
    );
  }

  return (
    <CardRowUI.HealthEntryProperty
      hasBottomBorder={true}
      borderType="full"
      icon={<Icons.IconStoppingReason.Medium color={COLORS.healthEntryPropertyIconWithValue} />}
      color={COLORS.healthEntryPropertyWithValue}
      value={healthEntry.stoppingReason.text()}
      onClick={handleOnSelectStoppingReason}
      onRemove={handleOnRemoveStoppingReason}
      subTitle={null}
    />
  );
};

interface ReactionProps extends Props {
  isForAllergy: boolean;
}

const getReaction = (r) => {
  return r ? r.name : '';
};

export const Reaction: React.FC<ReactionProps> = (props) => {
  const { healthEntry, languageCode, isForAllergy } = props;
  const healthEntryId = healthEntry.id;
  const reactions = SortingUtil.sortOptionListAlphabetically(healthEntry.reaction.getUnique().map((a) => ({ name: a.strings[languageCode], id: a.id })));

  const [, navActions] = useNavigate();
  const [, heActions] = useHealthEntryForActiveProfile(healthEntryId);

  const phrases = isForAllergy ? {
    another: {
      id: 'healthEntry.addAnotherReaction.button.label',
    },
    none: {
      id: 'healthEntry.addReaction.button.label',
    },
  } : {
    another: {
      id: 'healthEntry.addAnotherReactionSideEffect.button.label',
    },
    none: {
      id: 'healthEntry.addReactionSideEffect.button.label',
    },
  };

  const handleOnSelectReaction = () => {
    navActions.goTo(RouteNames.healthEntryReaction(healthEntryId));
  };

  const handleOnRemoveReaction = (reactionData) => {
    healthEntry.reaction.remove(reactionData.id);
    heActions.apiUpdate(healthEntry.data());
  };

  if (reactions.length === 0) {
    return (
      <EmptyRow
        languageCode={languageCode}
        icon={<OldIcons.Frown color="gsMedDark" />}
        emptyValue={<FormattedMessage {...phrases.none} />}
        onCreate={handleOnSelectReaction}
      />
    );
  }

  return (
    <HEPropertyCollection
      languageCode={languageCode}
      icon={<OldIcons.Frown color="gsDarkest" />}
      collection={reactions}
      getValue={getReaction}
      addAnotherValue={<FormattedMessage {...phrases.another} />}
      onClick={handleOnSelectReaction}
      onCreate={handleOnSelectReaction}
      onRemove={handleOnRemoveReaction}
    />
  );
};

export const HealthEntryValue: React.FC<Props> = (props) => {
  const { healthEntry, languageCode, parentHealthEntryId } = props;

  const healthEntryId = healthEntry.id;
  const hasAmount = healthEntry.amount.hasAmount();
  const hasDescription = healthEntry.description && healthEntry.description.hasDescription();
  const hasFrequency = healthEntry.frequency && healthEntry.frequency.hasFrequency();
  const hasValue = hasAmount || hasFrequency || hasDescription;

  const [, navActions] = useNavigate();

  const handleOnSelectValue = () => {
    navActions.goTo(RouteNames.healthEntryValueAmount(parentHealthEntryId, healthEntryId));
  };

  if (!hasValue) {
    return (
      <EmptyRow
        languageCode={languageCode}
        icon={<Icons.IconValue.Medium color={COLORS.healthEntryPropertyIconEmpty} />}
        emptyValue={<FormattedMessage id="healthEntry.addValue.button.label"/>}
        onCreate={handleOnSelectValue}
      />
    );
  }

  return (
    <CardRowUI.HealthEntryProperty
      hasBottomBorder={true}
      borderType="full"
      icon={<Icons.IconValue.Medium color={COLORS.healthEntryPropertyIconWithValue} />}
      color={COLORS.healthEntryPropertyWithValue}
      value={<MedliAmountText amount={healthEntry.amount.get()} description={healthEntry.description ? healthEntry.description.get() : null} />}
      onClick={handleOnSelectValue}
      onRemove={null}
      subTitle={null}
    />
  );
};

export const HealthEntryValueGeneVariant: React.FC<Props> = (props) => {
  const { healthEntry, languageCode, parentHealthEntryId } = props;
  const healthEntryId = healthEntry.id;
  const isDefined = healthEntry.geneVariantResult.isDefined();

  const [, navActions] = useNavigate();

  const handleOnSelectValue = () => {
    navActions.goTo(RouteNames.healthEntryValueGeneVariant(parentHealthEntryId, healthEntryId));
  };

  if (!isDefined) {
    return (
      <EmptyRow
        languageCode={languageCode}
        icon={<Icons.IconMutation.Medium color={COLORS.healthEntryPropertyIconEmpty} />}
        emptyValue={<FormattedMessage id="healthEntry.addGeneVariant.button.label"/>}
        onCreate={handleOnSelectValue}
      />
    );
  }

  return (
    <CardRowUI.HealthEntryProperty
      hasBottomBorder={true}
      borderType="full"
      icon={<Icons.IconMutation.Medium color={COLORS.healthEntryPropertyIconWithValue} />}
      color={COLORS.healthEntryPropertyWithValue}
      value={<GeneVariantResultText geneVariantResult={healthEntry.geneVariantResult.get()} />}
      onClick={handleOnSelectValue}
      onRemove={null}
      subTitle={null}
    />
  );
};

interface GeneAttributeProps extends Props {
  attributeScheme: HealthEntryGeneAttributeInterface;
}
export const HealthEntryValueGeneAttribute: React.FC<GeneAttributeProps> = (props) => {
  const { healthEntry, languageCode, attributeScheme, parentHealthEntryId } = props;
  const { geneVariantResult } = healthEntry;
  const { buttonLabelId, routeName } = attributeScheme;
  const healthEntryId = healthEntry.id;

  const [, navActions] = useNavigate();

  const handleOnSelectValue = () => {
    navActions.goTo(routeName(parentHealthEntryId, healthEntryId));
  };

  const isDefined = () => {
    const { values } = props.attributeScheme;
    return values.some((valueScheme: HealthEntryGeneAttributeValueInterface) => isAttributeValueDefined(valueScheme));
  };

  const isAttributeValueDefined = (valueScheme) => {
    const { geneVariantResultKey, isMedliTerm } = valueScheme;
    return geneVariantResult.isAdditionalAttributeDefined(geneVariantResultKey, isMedliTerm);
  };

  if (!isDefined()) {
    return (
      <EmptyRow
        languageCode={languageCode}
        icon={null}
        emptyValue={<FormattedMessage id={buttonLabelId} />}
        onCreate={handleOnSelectValue}
      />
    );
  }

  return (
    <CardRowUI.HealthEntryProperty
      hasBottomBorder={true}
      borderType="full"
      icon={null}
      color={COLORS.healthEntryPropertyWithValue}
      value={(
        <GeneAttributeResultText
          attributeScheme={attributeScheme}
          geneVariantResult={geneVariantResult}
        />
      )}
      onClick={handleOnSelectValue}
      onRemove={null}
      subTitle={null}
    />
  );
};
