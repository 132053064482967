import React from 'react';

import { PreferredLanguageDropdownList } from '../../../components/LanguageDropdownList';

import './Header.styles.scss';
import LogoBackpackRegistered from '../../../components/icons/LogoBackpackRegistered';

const UnsubscribeHeader = () => (
  <div className="cmp-unsubscribe-header util-inset-listitem-frame-padding">
    <div className="content">
      <LogoBackpackRegistered imageType="png" colorName="white" size="large" />

      <PreferredLanguageDropdownList
        size="24px"
        htmlId="privacy-lang"
        color="white"
      />
    </div>
  </div>
);

export default UnsubscribeHeader;
