
import React from 'react';

import { CardRowUI } from '../../../../bphComponents/bundle';
import { ContactSummaryText } from '../../../components/ContactSummaryText';

interface ContactSummaryProps {
  onClick(): void;
  data: any;
  isSelected: boolean;
}

const ContactSummary: React.FC<ContactSummaryProps> = (props) => {
  const { onClick, data, isSelected } = props;
  if (!data) { return null; }

  return (
    <CardRowUI.SelectableRow isSelected={isSelected} selectedClassName="fill-color-profile-highlight" onClick={onClick} hasBottomBorder={true}>
      <div className="font-body">{data.name}</div>
      <div className="font-footnote">
        <ContactSummaryText data={data} />
      </div>
    </CardRowUI.SelectableRow>
  );
};

export default ContactSummary;
