import { useQuery, queryCache } from 'react-query';
import _ from 'lodash';

import { useDispatch } from 'react-redux';
import { actions as healthEntryValueActions } from '../redux/ducks/healthEntryValue';

import HealthEntryService from '../../apiServices/HealthEntryService';
import HealthEntryCategoryNames from '../../constants/HealthEntryCategoryNames';

import DateUtil from '../../utilities/DateUtil';
import { isAbleToMakeRequest } from '../../utilities/MobileUtil';
import HealthEntryModel, { HealthEntryData } from '../../models/HealthEntry';
import { generalHealthEntry, generalHealthEntryValue, cleaners } from '../../models/HealthEntryCompose';
import healthEntryUtility from '../../models/HealthEntryUtility';

import { useActiveUser } from './useUser';
import { useMedliConstantsAllergy } from './useMedli';
import { useUIState } from './useUIState';
import { useUIEditHealthEntry, useUIEditHealthEntryValue } from './useUIEdit';
import { eMedliCategoryName, eMedliCategorySubtype } from '../../constants/MedliNames';

const STALE_TIME = 10000;

const INITIAL_HE_STATE = {
  data: {},
  isLoaded: false,
};
const INITIAL_PENDING_HE_STATE = {
  list: [],
  isLoaded: false,
};
const INITIAL_HEALTH_ENTRY_LIST_STATE = {
  list: [],
  lookup: {},
  groupedCategoryAndDate: groupByCategory([], 'en'),
  pendingCount: 0,
  processingCount: 0,
  heValueToHeLookup: {},
  heValueLookup: {},
  isLoaded: false,
};

// function updateHealthEntryValueQueryStore(profileId, languageCode, heData, hevData, heList, queryData) {
//   const healthEntryId = heData.id;
//   const newHEData = {
//     ...heData,
//     [heData.category]: {
//       ...heData[heData.category],
//       values: heData[heData.category].values.map(v => (v.id === hevData.id ? hevData : v)),
//     },
//   };
//   const newHEListData = heList.map(v => (v.id === healthEntryId ? newHEData : v));

//   queryCache.setQueryData(['healthEntry', profileId, healthEntryId], {
//     data: newHEData,
//     isLoaded: true,
//   });

//   queryCache.setQueryData(['healthEntryList', profileId], {
//     ...queryData,
//     ...createHealthEntryListPayload(newHEListData, languageCode),
//   });
// }

function createHealthEntryListPayload(heCollection, languageCode) {
  const heValueLookup = {};
  const heValueToHeLookup = {};
  heCollection.forEach((healthEntry) => {
    const values = healthEntry[healthEntry.category].values || [];
    values.forEach(heValue => {
      heValueLookup[heValue.id] = heValue;
      heValueToHeLookup[heValue.id] = healthEntry.id;
    });
  });

  return {
    list: heCollection,
    lookup: heCollection.reduce((acc, v) => ({ ...acc, [v.id]: v }), {}),
    groupedCategoryAndDate: groupByCategory(heCollection, languageCode),
    heValueLookup,
    heValueToHeLookup,
  };
}
function updateHealthEntryInListPayload(heListState, healthEntry, languageCode) {
  const newCollection = heListState.list.map(h => (h.id === healthEntry.id ? healthEntry : h));
  return createHealthEntryListPayload(newCollection, languageCode);
}

function updateHealthEntryCache(heListResponseData, profileId, healthEntry, preferredLanguageCode, invalidate) {
  if (invalidate) {
    queryCache.invalidateQueries('healthEntry');
    queryCache.invalidateQueries('healthEntryList');
  }

  queryCache.setQueryData(['healthEntry', profileId, healthEntry.id], {
    data: healthEntry,
    isLoaded: true,
  });
  queryCache.setQueryData(['healthEntryList', profileId], {
    ...updateHealthEntryInListPayload(heListResponseData, healthEntry, preferredLanguageCode),
    isLoaded: true,
  });
}

function createCollectionAndLookup(responseCollection) {
  const lookup = {};
  const collection = responseCollection;
  for (let i = 0; i < responseCollection.length; i++) {
    lookup[collection[i].id] = collection[i];
  }

  return {
    collection,
    lookup,
  };
}

// API

const heCategoryOrder = [
  HealthEntryCategoryNames.condition,
  HealthEntryCategoryNames.medication,
  HealthEntryCategoryNames.otherTreatment,
  HealthEntryCategoryNames.procedure,
  HealthEntryCategoryNames.allergy,
  HealthEntryCategoryNames.vaccination,
  HealthEntryCategoryNames.measurement,
  HealthEntryCategoryNames.lab,
  HealthEntryCategoryNames.geneticResult,
];
function requestPendingHealthEntries(dispatch) {
  return function requestPendingHealthEntries_API(queryKey, profileId, locale) {
    return new Promise((res, rej) => {
      HealthEntryService.allPending(profileId, (apiError, response) => {
        if (apiError) {
          rej(apiError);
          return;
        }

        dispatch({
          type: 'RECEIVE_PENDING_HEALTH_ENTRIES',
          payload: {
            profileId,
            isFetching: false,
            pendingCount: response.pending_count,
            processingCount: response.processing_count,
            changes: true,
          },
        });

        const sorted = HealthEntryModel.sortByNameAndDate(response.data, locale).sort((a, b) => {
          if (a.reviewed !== b.reviewed) {
            return a.reviewed ? -1 : 1;
          } else if (a.reviewed) {
            return 0;
          } else {
            const indexA = heCategoryOrder.indexOf(a.category);
            const indexB = heCategoryOrder.indexOf(b.category);
            if (indexA === indexB) {
              return 0;
            } else {
              return indexA < indexB ? -1 : 1;
            }
          }
        });

        res({
          list: sorted,
          isLoaded: true,
        });
      });
    });
  };
}

function apiCreateHealthEntry(profileId, healthEntry) {
  return new Promise((res, rej) => {
    if (!isAbleToMakeRequest()) { rej(); return; }

    const cleanedData = cleaners.generalHealthEntry(healthEntry.data());
    HealthEntryService.create(
      profileId,
      cleanedData,
      (apiError, newHealthEntry) => {
        if (apiError) { rej(apiError); return; }
        queryCache.invalidateQueries('healthEntryList');
        res(newHealthEntry);
      },
    );
  });
}

function apiUpdateHealthEntry(profileId, healthEntryData) {
  return new Promise((res, rej) => {
    if (!isAbleToMakeRequest()) { rej(); return; }

    const cleanedData = cleaners.generalHealthEntry(healthEntryData);

    HealthEntryService.update(profileId, cleanedData, (apiError) => {
      if (apiError) { rej(apiError); return; }
      queryCache.invalidateQueries('healthEntryList');
      res(healthEntryData);
    });
  });
}

function apiDeleteHealthEntry(profileId, healthEntryId) {
  return new Promise((res, rej) => {
    if (!isAbleToMakeRequest()) { rej(); return; }

    HealthEntryService.del(profileId, healthEntryId, () => {
      res();
      queryCache.invalidateQueries('healthEntryList');
    });
  });
}

function apiSetFlaggedHealthEntry(profileId, healthEntryId, isFlagged) {
  return new Promise((res, rej) => {
    if (!isAbleToMakeRequest()) { rej(); return; }

    HealthEntryService.flagged(
      profileId,
      healthEntryId,
      isFlagged,
      () => {
        queryCache.invalidateQueries('healthEntryList');
        res();
      },
    );
  });
}

function apiCreateHealthEntryValue(profileId, healthEntryId, value) {
  return new Promise((res, rej) => {
    if (!isAbleToMakeRequest()) { rej(); return; }

    const cleanedData = cleaners.generalHealthEntryValue(value.data());
    HealthEntryService.createValue(
      profileId,
      healthEntryId,
      cleanedData,
      (apiError, newHealthEntryValue) => {
        if (apiError) { rej(apiError); return; }

        res(newHealthEntryValue);
        queryCache.invalidateQueries('healthEntryList');
      },
    );
  });
}

function apiUpdateHealthEntryValue(profileId, healthEntryId, healthEntryValueData) {
  return new Promise((res, rej) => {
    if (!isAbleToMakeRequest()) { rej(); return; }

    const cleanedData = cleaners.generalHealthEntryValue(healthEntryValueData);

    HealthEntryService.updateValue(profileId, healthEntryId, cleanedData, (apiError) => {
      if (apiError) {
        rej(apiError);
      } else {
        queryCache.invalidateQueries('healthEntryList');
        res();
      }
    });
  });
}

function apiSetFlaggedHealthEntryValue(profileId, healthEntryId, hevId, isFlagged) {
  return new Promise((res, rej) => {
    if (isAbleToMakeRequest()) {
      HealthEntryService.flaggedValue(
        profileId,
        healthEntryId,
        hevId,
        isFlagged,
        () => {
          queryCache.invalidateQueries('healthEntryList');
          res();
        },
      );
    }
  });
}

function apiDeleteHealthEntryValue(profileId, healthEntryId, valueId) {
  return new Promise((res, rej) => {
    if (isAbleToMakeRequest()) {
      HealthEntryService.delValue(profileId, healthEntryId, valueId, () => {
        queryCache.invalidateQueries('healthEntry');
        queryCache.invalidateQueries('healthEntryList');
        res();
      });
    } else {
      rej();
    }
  });
}

function groupByCategory(data, languageCode) {
  return healthEntryUtility.groupByCategoryAndDate(healthEntryUtility.sort(data.map(d => generalHealthEntry(d)), languageCode));
}

function fetchHealthEnriesForProfile(dispatch) {
  return function fetchHealthEnriesForProfileApi(queryKey, profileId, languageCode) {
    return new Promise((res, rej) => {
      HealthEntryService.allActive(profileId, (err, payload) => {
        if (err) { return rej(err); }

        const heData = payload.data;
        dispatch({
          type: 'RECEIVE_ALL_HEALTH_ENTRIES',
          payload: {
            profileId,
            isFetching: false,
            pendingCount: payload.pending_count,
            processingCount: payload.processing_count,
            lastUpdated: new Date().getTime(),
            ...createCollectionAndLookup(payload.data),
          },
        });

        res({
          ...createHealthEntryListPayload(heData, languageCode),
          pendingCount: payload.pending_count,
          processingCount: payload.processing_count,
          isLoaded: true,
        });
      });
    });
  };
}

function requestHealthEntry(dispatch) {
  return function requestHealthEntry_API(queryKey, profileId, healthEntryId) {
    return new Promise((res, rej) => {
      HealthEntryService.get(profileId, healthEntryId, (apiError, healthEntryData) => {
        if (apiError) {
          return rej(apiError);
        }

        dispatch({
          type: 'RECEIVE_HEALTH_ENTRY',
          payload: {
            profileId,
            id: healthEntryId,
            healthEntryData,
          },
        });
        return res({
          data: healthEntryData,
          isLoaded: true,
        });
      });
    });
  };
}

// ===============

// function groupData(data = []) {
//   return {
//     [HealthEntryCategoryNames.allergy]: [],
//     [HealthEntryCategoryNames.condition]: [],
//     [HealthEntryCategoryNames.medication]: [],
//     [HealthEntryCategoryNames.otherTreatment]: [],
//     [HealthEntryCategoryNames.procedure]: [],
//     [HealthEntryCategoryNames.vaccination]: [],
//     [HealthEntryCategoryNames.lab]: [],
//     [HealthEntryCategoryNames.measurement]: [],
//     [HealthEntryCategoryNames.geneticResult]: [],
//     ...data.reduce((r, a) => {
//       r[a.category] = r[a.category] || [];
//       r[a.category].push(a);
//       return r;
//     }, {}),
//   };
// }

// function currentAndPast(healthEntryCollection = []) {
//   return {
//     newer: [],
//     older: [],
//     all: healthEntryCollection || [],
//     ...healthEntryCollection.reduce((r, a) => {
//       const keyName = HealthEntryModel.isCurrent(a) ? 'newer' : 'older';
//       r[keyName] = r[keyName] || [];
//       r[keyName].push(a);
//       return r;
//     }, {}),
//   };
// }

// function recentAndOld(healthEntryCollection = [], recentDeadlineDate = '') {
//   return {
//     newer: [],
//     older: [],
//     all: healthEntryCollection || [],
//     ...healthEntryCollection.reduce((r, a) => {
//       const keyName = HealthEntryModel.isRecent(a, recentDeadlineDate) ? 'newer' : 'older';
//       r[keyName] = r[keyName] || [];
//       r[keyName].push(a);
//       return r;
//     }, {}),
//   };
// }

// function sortGroupedHealthEntriesByTime(groupedHealthEntries) {
//   const recentDeadlineDate = DateUtil.today();

//   const allLabs = groupedHealthEntries[HealthEntryCategoryNames.lab] || [];
//   const allMeasurements = groupedHealthEntries[HealthEntryCategoryNames.measurement] || [];
//   const allGeneticResults = groupedHealthEntries[HealthEntryCategoryNames.geneticResult] || [];

//   return {
//     [HealthEntryCategoryNames.allergy]: currentAndPast(groupedHealthEntries[HealthEntryCategoryNames.allergy]),
//     [HealthEntryCategoryNames.condition]: currentAndPast(groupedHealthEntries[HealthEntryCategoryNames.condition]),
//     [HealthEntryCategoryNames.medication]: currentAndPast(groupedHealthEntries[HealthEntryCategoryNames.medication]),
//     [HealthEntryCategoryNames.otherTreatment]: currentAndPast(groupedHealthEntries[HealthEntryCategoryNames.otherTreatment]),
//     [HealthEntryCategoryNames.procedure]: recentAndOld(groupedHealthEntries[HealthEntryCategoryNames.procedure], DateUtil.subtract(recentDeadlineDate, 1, 'year')),
//     [HealthEntryCategoryNames.vaccination]: recentAndOld(groupedHealthEntries[HealthEntryCategoryNames.vaccination], DateUtil.subtract(recentDeadlineDate, 1, 'year')),
//     [HealthEntryCategoryNames.lab]: { newer: allLabs, older: [], all: allLabs },
//     [HealthEntryCategoryNames.measurement]: { newer: allMeasurements, older: [], all: allMeasurements },
//     [HealthEntryCategoryNames.geneticResult]: { newer: allGeneticResults, older: [], all: allGeneticResults },
//   };
// }

// export function useGroupedHealthEntryByDateForProfile(profileId) {
//   const [{ groupedCategoryAndDate, isLoaded }] = useHealthEntriesForProfile(profileId);

//   return [
//     {
//       groupedHealthEntryList: groupedCategoryAndDate,
//       isLoaded,
//     },
//   ];
// }
// export function useGroupedHealthEntryByDateForActiveProfile() {
//   const [{ activeProfileId }] = useUIState();
//   return useGroupedHealthEntryByDateForProfile(activeProfileId);
// }

export function useHealthEntriesForProfile(profileId) {
  const dispatch = useDispatch();
  const [{ preferredLanguageCode }] = useActiveUser();
  const compQuery: any = useQuery(['healthEntryList', profileId, preferredLanguageCode], fetchHealthEnriesForProfile(dispatch), { enabled: Boolean(profileId), staleTime: STALE_TIME });
  const responseData = compQuery.data || INITIAL_HEALTH_ENTRY_LIST_STATE;
  const isLoaded = responseData.isLoaded;
  const heData = responseData.list;
  const heDataLookup = responseData.lookup;
  const groupedCategoryAndDate = responseData.groupedCategoryAndDate;

  return [
    {
      healthEntryList: heData,
      healthEntryLookup: heDataLookup,
      heValueLookup: responseData.heValueLookup,
      heValueToHeLookup: responseData.heValueToHeLookup,
      groupedCategoryAndDate,
      groupedHealthEntryList: groupedCategoryAndDate,
      hasPending: Boolean(responseData.pendingCount),
      hasProcessing: Boolean(responseData.processingCount),
      isLoaded,
    },
    {
      instantiateHealthEntry: (heData, forceLanguageCode?) => {
        const languageCode = forceLanguageCode || preferredLanguageCode;
        return generalHealthEntry({ ...heData }, languageCode);
      },
      instantiateHealthEntryId: (heId, forceLanguageCode?) => {
        const heData = heDataLookup[heId] || {};
        const languageCode = forceLanguageCode || preferredLanguageCode;
        return generalHealthEntry({ ...heData }, languageCode);
      },
    },
  ];
}

export function useHealthEntriesForActiveProfile() {
  const [{ activeProfileId }] = useUIState();
  return useHealthEntriesForProfile(activeProfileId);
}

export function useHealthEntryForProfile(profileId, healthEntryId?) {
  const dispatch = useDispatch();
  const [{ preferredLanguageCode }] = useActiveUser();
  const [{ allergyList }] = useMedliConstantsAllergy();

  const heQuery: any = useQuery(['healthEntry', profileId, healthEntryId], requestHealthEntry(dispatch), { enabled: Boolean(healthEntryId), staleTime: STALE_TIME });
  const heListQuery: any = useQuery(['healthEntryList', profileId, preferredLanguageCode], fetchHealthEnriesForProfile(dispatch), { enabled: Boolean(profileId), staleTime: STALE_TIME });
  const responseData = heQuery.data || INITIAL_HE_STATE;
  const heListResponseData = heListQuery.data || INITIAL_HEALTH_ENTRY_LIST_STATE;
  const healthEntryData = responseData.data;
  const isLoaded = responseData.isLoaded;

  const category = healthEntryData.category;
  const [{ editingHealthEntry }, editingHEActions] = useUIEditHealthEntry();

  return [
    {
      category,
      subCategory: healthEntryData[healthEntryData.category] ? healthEntryData[healthEntryData.category].type : null,
      healthEntry: healthEntryData,
      isLoaded,
    },
    {
      instantiateHealthEntry: (customHeData?, forceLanguageCode?) => {
        const heData = customHeData || healthEntryData;
        const languageCode = forceLanguageCode || preferredLanguageCode;
        return generalHealthEntry({ ...heData }, languageCode);
      },
      instantiateNewHealthEntryFromSearch: (forceCategory, subCategory, medliSearchTerm, forceLanguageCode?) => {
        const languageCode = forceLanguageCode || preferredLanguageCode;
        const newHealthEntry = generalHealthEntry({ category: forceCategory }, languageCode);
        // if selected from an actual search result, term will have category
        // otherwise it would have come from some curated list

        if (forceCategory === HealthEntryCategoryNames.allergy) {
          let allergyType = '';
          switch (subCategory) {
            case eMedliCategoryName.food:
              allergyType = eMedliCategorySubtype.food;
              break;
            case eMedliCategoryName.medicationName:
              allergyType = eMedliCategorySubtype.medication;
              break;
            case eMedliCategoryName.allergy:
            default:
              allergyType = eMedliCategorySubtype.other;
              break;
          }
          if (allergyType === eMedliCategorySubtype.other) {
            newHealthEntry.medliTerm.set(medliSearchTerm);
          } else {
            const otherMedliSearchTermData = allergyList.find((c) => c.group === allergyType) || { group: 'other' };
            newHealthEntry.medliTerm.replaceWithSearchTerm(otherMedliSearchTermData);
            newHealthEntry.otherMedliTerm.replaceWithSearchTerm(medliSearchTerm);
          }

          newHealthEntry.subType.set(allergyType);
        } else {
          newHealthEntry.medliTerm.replaceWithSearchTerm(medliSearchTerm);
        }

        return newHealthEntry;
      },
      apiCreate: (heData) => {
        return new Promise((res, rej) => {
          const heInstance = generalHealthEntry(heData);
          apiCreateHealthEntry(profileId, heInstance)
            .then((createdHealthEntry: HealthEntryData) => {
              updateHealthEntryCache(heListResponseData, profileId, createdHealthEntry, preferredLanguageCode, false);

              res(createdHealthEntry);
            }).catch(apiError => rej(apiError));
        });
      },
      apiUpdate: (heData, forceRefresh?) => {
        return new Promise((res, rej) => {
          apiUpdateHealthEntry(profileId, heData).then(() => {
            if (forceRefresh) {
              queryCache.removeQueries('healthEntry');
              queryCache.removeQueries('healthEntryList');
            } else {
              updateHealthEntryCache(heListResponseData, profileId, heData, preferredLanguageCode, false);
            }
            res();
          }).catch(err => {
            rej(err);
          });
        });
      },
      apiToggleFlagged: () => {
        const he = generalHealthEntry(healthEntryData, preferredLanguageCode);
        he.flag.toggle();
        updateHealthEntryCache(heListResponseData, profileId, he.data(), preferredLanguageCode, false);

        return apiSetFlaggedHealthEntry(profileId, he.id, he.flag.isFlagged())
          .then(() => {
            queryCache.invalidateQueries('healthEntry');
            queryCache.invalidateQueries('healthEntryList');
          });
      },
      apiDeleteHealthEntry: () => {
        return apiDeleteHealthEntry(profileId, healthEntryId)
          .then(() => {
            queryCache.invalidateQueries('healthEntryList');
          });
      },

      instantiateEditingHealthEntry: (forceLanguageCode?) => {
        const languageCode = forceLanguageCode || preferredLanguageCode;
        const heData = editingHealthEntry.category ? editingHealthEntry : healthEntryData;
        return generalHealthEntry({ ...heData }, languageCode);
      },
      getEditing: () => {
        const heData = editingHealthEntry.category ? editingHealthEntry : healthEntryData;
        return heData;
      },
      updateEditing: (heData) => {
        return new Promise((res, rej) => {
          editingHEActions.updateHealthEntry(heData);
          dispatch({
            type: 'UPDATE_PENDING_HEALTH_ENTRY',
            payload: {
              profileId,
              id: healthEntryData.id,
              healthEntryData,
            },
          });
          res();
        });
      },
      clearEditing: () => {
        editingHEActions.clearHealthEntry();
        dispatch({
          type: 'CLEAR_PENDING_HEALTH_ENTRY',
          payload: {},
        });
      },

      apiAddHealthEntryValue: (heData?, hevData?, forceLanguageCode?) => {
        const languageCode = forceLanguageCode || preferredLanguageCode;

        return new Promise((res, rej) => {
          const he = heData ? generalHealthEntry(heData, languageCode) : generalHealthEntry(healthEntryData, languageCode);
          const hasValues = he.values.hasValues();
          const healthEntryValue = generalHealthEntryValue(category, (hevData || {}));
          const mostRecentValue = he.values.mostRecent();
          const healthEntryId = he.id;

          if (hasValues) {
            healthEntryValue.date.set({ date: DateUtil.today(), precision: 'P1D' });
          }

          apiCreateHealthEntryValue(profileId, healthEntryId, healthEntryValue)
            .then((createdValue: any) => {
              requestHealthEntry(dispatch)('', profileId, healthEntryId)
                .then(() => {
                  // TODO: could use queryCache.setQueryData to avoid network requests here
                  // queryCache.setQueryData(['healthEntry', profileId, healthEntryId], newHEData)
                  // queryCache.setQueryData(['healthEntryList', profileId], {});
                  queryCache.invalidateQueries('healthEntry');
                  queryCache.invalidateQueries('healthEntryList');
                  const pendingHeValue = generalHealthEntryValue(category, createdValue, preferredLanguageCode);
                  if (hasValues && mostRecentValue.amount && mostRecentValue.amount.hasAmount()) {
                    const unit = mostRecentValue.amount.getUnit();
                    pendingHeValue.amount.setUnit(unit);
                  }
                  dispatch(healthEntryValueActions.updateEditing(pendingHeValue.data()));

                  res({ newId: createdValue.id, pendingHeValue });
                });
            });
        });
      },

      getName: (forceLanguageCode?) => {
        const languageCode = forceLanguageCode || preferredLanguageCode;
        const he = generalHealthEntry(healthEntryData, languageCode);
        return (he.otherMedliTerm && he.otherMedliTerm.hasOther()) ? he.otherMedliTerm.formattedTermName(languageCode) : he.medliTerm.formattedTermName(languageCode);
      },

      localizationData: () => {
        const he = generalHealthEntry(healthEntryData, preferredLanguageCode);
        return he.medliTerm.needsLocalization() ? he.medliTerm.localizationData(preferredLanguageCode) : null;
      },
    },
  ];
}
export function useHealthEntryForActiveProfile(healthEntryId?) {
  const [{ activeProfileId }] = useUIState();
  return useHealthEntryForProfile(activeProfileId, healthEntryId);
}

export function useHealthEntryValuesForActiveProfile() {
  const dispatch = useDispatch();

  const [{ preferredLanguageCode }] = useActiveUser();
  const [{ activeProfileId }] = useUIState();
  const heListQuery: any = useQuery(['healthEntryList', activeProfileId, preferredLanguageCode], fetchHealthEnriesForProfile(dispatch), { enabled: Boolean(activeProfileId), staleTime: STALE_TIME });
  const responseData = heListQuery.data || INITIAL_HEALTH_ENTRY_LIST_STATE;
  const { heValueLookup, heValueToHeLookup, lookup, isLoaded } = responseData;
  const [, editHEVActions] = useUIEditHealthEntryValue();

  return [
    {
      heValueLookup,
      heValueToHeLookup,
      isLoaded,
    }, {
      updateEditing: (newData) => {
        editHEVActions.updateHealthEntryValue(newData);
        dispatch(healthEntryValueActions.updateEditing(newData));
      },
      getHealthEntryForHeValue: (heValueId) => {
        return heValueToHeLookup[heValueId];
      },
      instantiateHealthEntryValueId: (hevId, forceLanguageCode?) => {
        const hevData = heValueLookup[hevId];
        const heData =  lookup[heValueToHeLookup[hevId]];
        if (!heData) { return null; }
        const category = heData.category;
        const languageCode = forceLanguageCode || preferredLanguageCode;
        return generalHealthEntryValue(category, hevData, languageCode);
      },
    },
  ];
}

function useHealthEntryValueFromHealthEntry(profileId, healthEntryId, heValueId) {
  const dispatch = useDispatch();

  const [{ preferredLanguageCode }] = useActiveUser();
  const [{ activeProfileId }] = useUIState();

  const heQuery: any = useQuery(['healthEntry', activeProfileId, healthEntryId], requestHealthEntry(dispatch), { enabled: Boolean(healthEntryId), staleTime: STALE_TIME });
  const heResponseData = heQuery.data || INITIAL_HE_STATE;
  const parentHealthEntry = heResponseData.data;
  const category = parentHealthEntry ? parentHealthEntry.category : null;
  const values = _.get(parentHealthEntry, `${category}.values`, []);
  const healthEntryValue = values.find(v => v.id === heValueId);

  const [{ editingHealthEntryValue }, editHEVActions] = useUIEditHealthEntryValue();

  return [
    {
      category,
      parentHealthEntry,
      healthEntryValue,
      isLoaded: heResponseData.isLoaded,
    },
    {
      instantiateHealthEntry: (customHeData?, forceLanguageCode?) => {
        const heData = customHeData || parentHealthEntry || {};
        const languageCode = forceLanguageCode || preferredLanguageCode;
        return generalHealthEntry(heData, languageCode);
      },
      instantiateHealthEntryValue: (customHevData?, forceLanguageCode?) => {
        const cat = category || 'missing';
        const hevData = customHevData || healthEntryValue;
        const languageCode = forceLanguageCode || preferredLanguageCode;
        return generalHealthEntryValue(cat, hevData, languageCode);
      },
      instantiateEditingHealthEntryValue: (forceLanguageCode?) => {
        const cat = category || 'missing';
        const languageCode = forceLanguageCode || preferredLanguageCode;
        const hev = editingHealthEntryValue.id ? editingHealthEntryValue : healthEntryValue;
        return generalHealthEntryValue(cat, hev, languageCode);
      },
      apiUpdate: (hevData) => {
        const cleanedData = cleaners.generalHealthEntryValue({ id: heValueId, ...hevData });
        return apiUpdateHealthEntryValue(profileId, healthEntryId, cleanedData)
        .then(() => {
          queryCache.invalidateQueries('healthEntry');
          queryCache.setQueryData(['healthEntry', activeProfileId, healthEntryId], {
            data: HealthEntryModel.addHealthEntryValue(parentHealthEntry, cleanedData),
            isLoaded: true,
          });
          queryCache.invalidateQueries('healthEntryList');
        });
      },
      getEditing: () => {
        const hev = editingHealthEntryValue.id ? editingHealthEntryValue : healthEntryValue;
        return hev || {};
      },
      updateEditing: (heValueData) => {
        editHEVActions.updateHealthEntryValue(heValueData);
        dispatch(healthEntryValueActions.updateEditing(heValueData));
      },
      resetEditing: () => {
        editHEVActions.clearHealthEntryValue();
        dispatch(healthEntryValueActions.clearEditing());
      },
      apiToggleFlagged: () => {
        const hev = generalHealthEntryValue(category, healthEntryValue, preferredLanguageCode);
        hev.flag.toggle();
        return apiSetFlaggedHealthEntryValue(profileId, healthEntryId, heValueId, hev.flag.isFlagged())
          .then(() => {
            queryCache.setQueryData(['healthEntry', activeProfileId, healthEntryId], {
              data: HealthEntryModel.addHealthEntryValue(parentHealthEntry, hev.data()),
              isLoaded: true,
            });
            queryCache.invalidateQueries('healthEntryList');
          });
      },
      apiDeleteValue: () => {
        return apiDeleteHealthEntryValue(profileId, healthEntryId, heValueId)
          .then(() => {
            queryCache.setQueryData(['healthEntry', activeProfileId, healthEntryId], {
              data: HealthEntryModel.removeHealthEntryValue(parentHealthEntry, heValueId),
              isLoaded: true,
            });
            queryCache.invalidateQueries('healthEntryList');
          });
      },
    },
  ];
}

export function useHealthEntryValueForActiveProfile(heValueId) {
  const dispatch = useDispatch();

  const [{ preferredLanguageCode }] = useActiveUser();
  const [{ activeProfileId }] = useUIState();
  const heQuery: any = useQuery(['healthEntryList', activeProfileId, preferredLanguageCode], fetchHealthEnriesForProfile(dispatch), { enabled: Boolean(activeProfileId), staleTime: STALE_TIME });
  const responseData = heQuery.data || INITIAL_HEALTH_ENTRY_LIST_STATE;
  const { lookup, heValueLookup, heValueToHeLookup, isLoaded } = responseData;

  const healthEntryId = heValueToHeLookup[heValueId];
  const parentHealthEntry = lookup[healthEntryId];
  const healthEntryValue = heValueLookup[heValueId];
  const category = parentHealthEntry ? parentHealthEntry.category : null;

  return [
    {
      healthEntryId,
      parentHealthEntry,
      healthEntryValue,
      isLoaded,
    },
    {
      instantiateHealthEntry: (customHeData?, forceLanguageCode?) => {
        const heData = customHeData || parentHealthEntry || {};
        const languageCode = forceLanguageCode || preferredLanguageCode;
        return generalHealthEntry(heData, languageCode);
      },
      instantiateHealthEntryValue: (customHevData?, forceLanguageCode?) => {
        const cat = category || 'missing';
        const hevData = customHevData || healthEntryValue;
        const languageCode = forceLanguageCode || preferredLanguageCode;
        return generalHealthEntryValue(cat, hevData, languageCode);
      },
    },
  ];
}

export function useHealthEntryAndValueForActiveProfile(healthEntryId, heValueId) {
  const [{ activeProfileId }] = useUIState();
  return useHealthEntryValueFromHealthEntry(activeProfileId, healthEntryId, heValueId);
}

export function usePendingHealthEntryData(locale) {
  const dispatch = useDispatch();
  const [{ activeProfileId }] = useUIState();

  return [
    {}, {
      fetch: () => {
        return requestPendingHealthEntries(dispatch)('', activeProfileId, locale);
      },
    },
  ];
}
