
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentFrame from '../../applicationFrame/ContentFrame';
import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import ContentWrapper from '../../components/ContentWrapper';
import RouteNames from '../../appAuthorized/RouteNames';

import { Card, Inputs } from '../../../bphComponents/bundle';
import { useUIState } from '../../hooks/useUIState';
import { useActiveProfile } from '../../hooks/useProfile';
import { useUIEditContact } from '../../hooks/useUIEdit';
import { useLists } from '../../hooks/useLists';
import { useNavigate } from '../../hooks/useNavigate';

interface Props {
  match: any;
}

const ViewContactCustomRelationship: React.FC<Props> = (props) => {
  const contactId = Number(props.match.params.contactId);

  const [{ isActiveProfileMainProfile }] = useUIState();
  const [{ greetingName }] = useActiveProfile();
  const [{ editingContact, isLoaded }, editContactActions] = useUIEditContact(contactId);
  const [{ contactRelationshipCustomValue }] = useLists();
  const [, navActions] = useNavigate();

  const [currentValue, setCurrentValue] = React.useState(editingContact ? editingContact.relationship_custom_text : '');

  const labelMsg = isActiveProfileMainProfile ? { id: 'contactRelationship.custom.user.field.label' } : { id: 'contactRelationship.custom.notUser.field.label', values: { string1: greetingName }};

  React.useEffect(() => {
    if (isLoaded) {
      setCurrentValue(editingContact.relationship_custom_text);
    }
  }, [isLoaded]);

  return isLoaded ? (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          cancel={() => { navActions.goBack(RouteNames.contactEdit(String(editingContact.id))); }}
          submit={() => {
            if (currentValue) {
              editContactActions.updateRelationship(contactRelationshipCustomValue.id, currentValue);
            } else {
              editContactActions.updateRelationship(null, null);
            }
            navActions.goBack(RouteNames.contactEdit(contactId));
          }}
          color="white"
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText id="contactRelationship.custom.title" />
            <ContentWrapper>
              <Card.CardShape>
                <Card.CardContent>
                  <Inputs.TextInput
                    label={<FormattedMessage {...labelMsg} />}
                    value={currentValue || ''}
                    autoCapitalize="none"
                    preventFloating={true}
                    isClearable={true}
                    autoFocus={!currentValue}
                    onChange={setCurrentValue}
                  />
                </Card.CardContent>
              </Card.CardShape>
            </ContentWrapper>
          </ScrollAwareScroller>

        </ContentFrame>
      </ScrollAwareContext>
    </div>
  ) : null;
};

export default ViewContactCustomRelationship;
