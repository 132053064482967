import React from 'react';
import _ from 'lodash';

import { eSurveyQuestionCategoryName, iSurveyQuestion, iSurveyResponseComposite, iSurveyResponse, iSurveySubQuestionResponse } from '../../../../apiServices/SurveyService';
import TextEntryQuestion from './TextEntryQuestion';
import AmountEntryQuestion from './AmountEntryQuestion';
import FuzzyDateQuestion from './FuzzyDateQuestion';
import RapidResponseQuestion from './RapidResponseQuestion';
import { CompositeSingleAnswerQuestion } from './SingleAnswerQuestion';
import QuestionNavControls from './components/QuestionNavControls';

import { SurveyQuestionProvider } from '../../../hooks/useSurveyQuestion';

interface CompositeSegmentProps {
  questionSegment: iSurveyQuestion;
  onUpdate(newFullResponse, newPartialResponse): void;
  isLastSegment: boolean;
  isFirstSegment: boolean;
  segmentIndex: number;
  responseSegment: iSurveyResponseComposite;
}
const CompositeSegment = (props: CompositeSegmentProps) => {
  const { questionSegment, responseSegment, onUpdate, isLastSegment, segmentIndex } = props;
  const isFirstSegment = segmentIndex === 0;

  switch (questionSegment.category) {
    case eSurveyQuestionCategoryName.amountEntry:
      return (
        <AmountEntryQuestion
          onUpdate={onUpdate}
          question={questionSegment}
          response={responseSegment}
        />
      );
    case eSurveyQuestionCategoryName.fuzzyDate:
      return (
        <FuzzyDateQuestion
          onUpdate={onUpdate}
          question={questionSegment}
          response={responseSegment}
        />
      );
    case eSurveyQuestionCategoryName.rapidResponse:
      return (
        <RapidResponseQuestion
          idPrefix="survey-question-"
          questionClassSelector="survey-question"
          onUpdate={onUpdate}
          question={questionSegment}
          response={responseSegment}
          showHint={isFirstSegment}
          hasLastItem={isLastSegment}
          segmentIndex={segmentIndex}
        />
      );
    case eSurveyQuestionCategoryName.singleAnswer:
      return (
        <CompositeSingleAnswerQuestion
          onUpdate={onUpdate}
          question={questionSegment}
          response={responseSegment}
          segmentIndex={segmentIndex}
          questionClassSelector="survey-question"
          isFirst={isFirstSegment}
        />
      );
    case eSurveyQuestionCategoryName.textEntry:
      return (
        <TextEntryQuestion
          onUpdate={onUpdate}
          onSubmit={null}
          question={questionSegment}
          response={responseSegment}
        />
      );
    default:
      return null;
  }
};

const CompositeQuestionRef = (props, ref) => {
  const { onUpdate, question, response } = props;
  const compositeQuestion = question[eSurveyQuestionCategoryName.composite];
  const compositeResponse = response[eSurveyQuestionCategoryName.composite] || { segments: [] };
  const questionNavControlRef = React.useRef(null);
  const lastSegmentIndex = compositeQuestion.segments.length - 1;

  function handleOnUpdate(segmentIndex: number, segmentResponse: iSurveyResponse, subQuestionResponse: iSurveySubQuestionResponse) {
    const compositeData = response[eSurveyQuestionCategoryName.composite] || {};
    const segments = compositeData.segments ? [...compositeData.segments] :  [];
    segments[segmentIndex] = segmentResponse;
    const newCompositeResponse = {
      ...response,
      category: eSurveyQuestionCategoryName.composite,
      [eSurveyQuestionCategoryName.composite]: {
        ...compositeData,
        segments,
      },
    };
    onUpdate(newCompositeResponse);
  }

  React.useImperativeHandle(ref, () => ({
    onDisabledClick: () => {
      questionNavControlRef.current.scrollToFirstUncheckedItem();
    },
    scrollToFirstUncheckedItem: () => {
      questionNavControlRef.current.scrollToFirstUncheckedItem();
    },
  }));

  return (
    <div>
      <SurveyQuestionProvider question={question} response={response}>

      {
        compositeQuestion.segments.map((c, i) => (
          <CompositeSegment
            key={i}
            segmentIndex={i}
            isFirstSegment={i === 0}
            isLastSegment={i === lastSegmentIndex}
            questionSegment={c}
            responseSegment={compositeResponse.segments[i] || {}}
            onUpdate={function (newFullResponse, newPartialResponse) {
              handleOnUpdate(i, newFullResponse, newPartialResponse);
            }}
          />
        ))
      }

      <QuestionNavControls
        ref={questionNavControlRef}
        question={question}
        response={response}
        idPrefix="survey-question-"
        questionClassSelector="survey-question"
      />
      </SurveyQuestionProvider>
    </div>
  );
};

const CompositeQuestion = React.forwardRef(CompositeQuestionRef);

export default CompositeQuestion;
