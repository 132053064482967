import React from 'react';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  color?: string;
}

const DEFAULT_COLOR = 'gsMedDark';

const getClassName = (props: Props) => {
  const { color = DEFAULT_COLOR } = props;
  return colorClassNames.fill(color);
};

const Small = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px">
    <g id="icon-eye-show" className={getClassName(props)}>
      <path d="M15.36,7.75a8,8,0,0,0-14.72,0L.53,8l.11.25a8,8,0,0,0,14.72,0L15.47,8ZM8,11.86A6.73,6.73,0,0,1,1.9,8,6.75,6.75,0,0,1,14.1,8,6.73,6.73,0,0,1,8,11.86Z"/>
      <path d="M8,4.94A3.06,3.06,0,1,0,11.06,8,3.06,3.06,0,0,0,8,4.94ZM8,9.81A1.81,1.81,0,1,1,9.81,8,1.81,1.81,0,0,1,8,9.81Z"/>
    </g>
  </svg>
);

const Medium = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <g id="icon-eye-show" className={getClassName(props)}>
      <path d="M22.19,11.71a11.07,11.07,0,0,0-20.38,0L1.68,12l.13.29a11.07,11.07,0,0,0,20.38,0l.13-.29ZM12,17.53A9.56,9.56,0,0,1,3.32,12a9.58,9.58,0,0,1,17.36,0A9.56,9.56,0,0,1,12,17.53Z"/>
      <path d="M12,7.84A4.16,4.16,0,1,0,16.16,12,4.16,4.16,0,0,0,12,7.84Zm0,6.82A2.66,2.66,0,1,1,14.66,12,2.66,2.66,0,0,1,12,14.66Z"/>
    </g>
  </svg>
);

const Large = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36px" height="36px">
    <g id="icon-eye-show" className={getClassName(props)}>
      <path d="M34,17.61A17.43,17.43,0,0,0,2,17.61L1.79,18l.17.39a17.43,17.43,0,0,0,32.08,0l.17-.39ZM18,27A15.41,15.41,0,0,1,4,18,15.44,15.44,0,0,1,32,18,15.41,15.41,0,0,1,18,27Z"/>
      <path d="M18,11.57A6.43,6.43,0,1,0,24.43,18,6.43,6.43,0,0,0,18,11.57Zm0,10.86A4.43,4.43,0,1,1,22.43,18,4.44,4.44,0,0,1,18,22.43Z"/>
    </g>
  </svg>
);

const XLarge = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" width="56px" height="56px">
    <g id="icon-eye-show" className={getClassName(props)}>
      <path d="M52.27,27.46a26.38,26.38,0,0,0-48.54,0L3.51,28l.22.54a26.38,26.38,0,0,0,48.54,0l.22-.54ZM28,41.83A23.59,23.59,0,0,1,6.5,28a23.63,23.63,0,0,1,43,0A23.6,23.6,0,0,1,28,41.83Z"/>
      <path d="M28,18.37A9.63,9.63,0,1,0,37.63,28,9.64,9.64,0,0,0,28,18.37Zm0,16.51A6.88,6.88,0,1,1,34.88,28,6.88,6.88,0,0,1,28,34.88Z"/>
    </g>
  </svg>
);

const IconEyeShow = {
  Small,
  Medium,
  Large,
  XLarge,
};

export default IconEyeShow;
