import React from 'react';

import { colorClassNames } from '../../styles/Colors';

const smallAttributes = {
  fill: 'none',
  strokeWidth: '1.25px',
};

const Small = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={colorClassNames.stroke(props.color)} style={{ width: '16px' }}>
    <g id="icon-trash-2" data-name="icon-trash">
      <path {...smallAttributes} strokeLinecap="round" strokeLinejoin="round" d="M12.57,4V13a1.36,1.36,0,0,1-1.21,1.48H4.66A1.36,1.36,0,0,1,3.45,13V4"/>
      <path {...smallAttributes} strokeLinecap="round" strokeLinejoin="round" d="M7.31,1.49H8.72A2.47,2.47,0,0,1,11.19,4V4a0,0,0,0,1,0,0H4.84a0,0,0,0,1,0,0V4A2.47,2.47,0,0,1,7.31,1.49Z"/>
      <line {...smallAttributes} strokeLinecap="round" strokeLinejoin="round" x1="2.5" y1="3.96" x2="13.5" y2="3.96"/>
      <line {...smallAttributes} strokeLinecap="round" strokeLinejoin="round" x1="5.82" y1="6.31" x2="5.82" y2="11.88"/>
      <line {...smallAttributes} strokeLinecap="round" strokeLinejoin="round" x1="8.01" y1="6.31" x2="8.01" y2="11.88"/>
      <line {...smallAttributes} strokeLinecap="round" strokeLinejoin="round" x1="10.21" y1="6.31" x2="10.21" y2="11.88"/>
    </g>
  </svg>
);

const mediumAttributes = {
  fill: 'none',
  strokeWidth: '1.5px',
};

const Medium = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={colorClassNames.stroke(props.color)} style={{ width: '24px' }}>
    <g id="icon-trash-2" data-name="icon-trash">
      <path {...mediumAttributes} strokeLinecap="round" strokeLinejoin="round" d="M19,5.79V19.73A2.09,2.09,0,0,1,17.17,22H6.87A2.09,2.09,0,0,1,5,19.73V5.79"/>
      <path {...mediumAttributes} strokeLinecap="round" strokeLinejoin="round" d="M10,2h4.13A2.81,2.81,0,0,1,16.9,4.81v1a0,0,0,0,1,0,0H7.15a0,0,0,0,1,0,0v-1A2.81,2.81,0,0,1,10,2Z"/>
      <line {...mediumAttributes} strokeLinecap="round" strokeLinejoin="round" x1="3.55" y1="5.79" x2="20.45" y2="5.79"/>
      <line {...mediumAttributes} strokeLinecap="round" strokeLinejoin="round" x1="8.65" y1="8.7" x2="8.65" y2="18.68"/>
      <line {...mediumAttributes} strokeLinecap="round" strokeLinejoin="round" x1="12.02" y1="8.7" x2="12.02" y2="18.68"/>
      <line {...mediumAttributes} strokeLinecap="round" strokeLinejoin="round" x1="15.4" y1="8.7" x2="15.4" y2="18.68"/>
    </g>
  </svg>
);

const largeAttributes = {
  fill: 'none',
  strokeWidth: '2px',
};

const Large = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" className={colorClassNames.stroke(props.color)} style={{ width: '36px' }}>
    <g id="icon-trash-2" data-name="icon-trash">
      <path {...largeAttributes} strokeLinecap="round" strokeLinejoin="round" d="M28.39,8.82V29.43c0,1.85-1.23,3.35-2.75,3.35H10.41c-1.52,0-2.75-1.5-2.75-3.35V8.82"/>
      <path {...largeAttributes} strokeLinecap="round" strokeLinejoin="round" d="M16.43,3.22h3.2a5.61,5.61,0,0,1,5.61,5.61v0a0,0,0,0,1,0,0H10.83a0,0,0,0,1,0,0v0a5.61,5.61,0,0,1,5.61-5.61Z"/>
      <line {...largeAttributes} strokeLinecap="round" strokeLinejoin="round" x1="5.5" y1="8.82" x2="30.5" y2="8.82"/>
      <line {...largeAttributes} strokeLinecap="round" strokeLinejoin="round" x1="13.05" y1="13.12" x2="13.05" y2="27.87"/>
      <line {...largeAttributes} strokeLinecap="round" strokeLinejoin="round" x1="18.03" y1="13.12" x2="18.03" y2="27.87"/>
      <line {...largeAttributes} strokeLinecap="round" strokeLinejoin="round" x1="23.02" y1="13.12" x2="23.02" y2="27.87"/>
    </g>
  </svg>
);

const extraLargeAttributes = {
  fill: 'none',
  strokeWidth: '2.75px',
};

const ExtraLarge = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" className={colorClassNames.stroke(props.color)} style={{ width: '56px' }}>
    <g id="icon-trash-2" data-name="icon-trash">
      <path {...extraLargeAttributes} strokeLinecap="round" strokeMiterlimit="10" d="M44.12,13.72V45.79c0,2.88-1.92,5.21-4.28,5.21H16.14c-2.36,0-4.28-2.33-4.28-5.21V13.72"/>
      <path {...extraLargeAttributes} strokeLinejoin="round" d="M25.16,5h5.69a8.37,8.37,0,0,1,8.37,8.37v.35a0,0,0,0,1,0,0H16.79a0,0,0,0,1,0,0v-.35A8.37,8.37,0,0,1,25.16,5Z"/>
      <line {...extraLargeAttributes} strokeLinecap="round" strokeMiterlimit="10" x1="8.5" y1="13.72" x2="47.39" y2="13.72"/>
      <line {...extraLargeAttributes} strokeLinecap="round" strokeMiterlimit="10" x1="20.24" y1="20.4" x2="20.24" y2="43.35"/>
      <line {...extraLargeAttributes} strokeLinecap="round" strokeMiterlimit="10" x1="28" y1="20.4" x2="28" y2="43.35"/>
      <line {...extraLargeAttributes} strokeLinecap="round" strokeMiterlimit="10" x1="35.76" y1="20.4" x2="35.76" y2="43.35"/>
    </g>
  </svg>
);

const IconTrash = {
  Small,
  Medium,
  Large,
  ExtraLarge,
};

export default IconTrash;
