import React from 'react';

const IconFileSmall = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 16 16">
    <g id="icon-file-image">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="Canvas">
        <g id="icon_doc-kind-img" data-name="icon/doc-kind-img">
          <g id="icon-img">
            <path id="Vector_3" data-name="Vector 3" style={{ fill: '#b7b7b7' }} d="M17,16H7.42a.33.33,0,0,1-.25-.56l2.3-2.56a.34.34,0,0,1,.44-.05l.64.42a.15.15,0,0,0,.21,0l2.62-2.91a.33.33,0,0,1,.52,0l3.42,5.12A.33.33,0,0,1,17,16Z" />
            <circle id="Ellipse_3" data-name="Ellipse 3" style={{ fill: '#b7b7b7' }} cx="9" cy="9" r="1.67" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const IconFileMedium = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 24 24">
    <g id="icon-file-image">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="Canvas">
        <g id="icon_doc-kind-img" data-name="icon/doc-kind-img">
          <g id="icon-img">
            <path id="Vector_3" data-name="Vector 3" style={{ fill: '#b7b7b7' }} d="M17,16H7.42a.33.33,0,0,1-.25-.56l2.3-2.56a.34.34,0,0,1,.44-.05l.64.42a.15.15,0,0,0,.21,0l2.62-2.91a.33.33,0,0,1,.52,0l3.42,5.12A.33.33,0,0,1,17,16Z" />
            <circle id="Ellipse_3" data-name="Ellipse 3" style={{ fill: '#b7b7b7' }} cx="9" cy="9" r="1.67" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const IconFileLarge = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="36px" width="36px" viewBox="0 0 36 36">
    <g id="icon-file-image">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="Canvas">
        <g id="icon_doc-kind-img" data-name="icon/doc-kind-img">
          <g id="icon-img">
            <path id="Vector_3" data-name="Vector 3" style={{ fill: '#b7b7b7' }} d="M17,16H7.42a.33.33,0,0,1-.25-.56l2.3-2.56a.34.34,0,0,1,.44-.05l.64.42a.15.15,0,0,0,.21,0l2.62-2.91a.33.33,0,0,1,.52,0l3.42,5.12A.33.33,0,0,1,17,16Z" />
            <circle id="Ellipse_3" data-name="Ellipse 3" style={{ fill: '#b7b7b7' }} cx="9" cy="9" r="1.67" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const IconFileXLarge = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="56px" width="56px" viewBox="0 0 56 56">
    <g id="icon-file-image">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="Canvas">
        <g id="icon_doc-kind-img" data-name="icon/doc-kind-img">
          <g id="icon-img">
            <path id="Vector_3" data-name="Vector 3" style={{ fill: '#b7b7b7' }} d="M17,16H7.42a.33.33,0,0,1-.25-.56l2.3-2.56a.34.34,0,0,1,.44-.05l.64.42a.15.15,0,0,0,.21,0l2.62-2.91a.33.33,0,0,1,.52,0l3.42,5.12A.33.33,0,0,1,17,16Z" />
            <circle id="Ellipse_3" data-name="Ellipse 3" style={{ fill: '#b7b7b7' }} cx="9" cy="9" r="1.67" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const IconFileTypeImage = {
  Small: IconFileSmall,
  Medium: IconFileMedium,
  Large: IconFileLarge,
  xLarge: IconFileXLarge,
};

export default IconFileTypeImage;
