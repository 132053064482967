import * as _ from 'lodash';

import ListsService from '../../../../apiServices/ListsService';
import actionNames from '../../actionNames';


const initialState = {
  isFetching: false,
  collection: [],
  lookup: {},
  lastUpdated: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case actionNames.lists.REQUEST_LOCALE_MEDLI:
    case actionNames.lists.RECEIVE_LOCALE_MEDLI:
      return _.assign({}, state, action.payload);
    case actionNames.lists.RECEIVE_ALL:
    case actionNames.lists.REQUEST_ALL:
      return _.assign({}, state, action.payload.localeMedli);
    default:
      return state;
  }
}


const helperMethods = {
  isFetching: (state, listName) => state.lists[listName].isFetching,
  isSameAs(state, listName, newData) {
    const { collection } = state.lists[listName];
    return _.isEqual(collection, newData);
  },
  hasData: (state, listName) => state.lists[listName].collection.length > 0,
};

function createCollectionAndLookup(apiError, responseCollection) {
  const lookup = {};
  let collection = [];

  if (!apiError) {
    collection = responseCollection;
    for (let i = 0; i < responseCollection.length; i++) {
      lookup[collection[i].id] = collection[i];
    }
  }

  return {
    collection,
    lookup,
  };
}

function requestLocaleMedliList() {
  return (dispatch, getState) => {
    return new Promise((res) => {
      if (helperMethods.isFetching(getState(), 'localeMedli') || helperMethods.hasData(getState(), 'localeMedli')) { res(); return; }

      dispatch({
        type: actionNames.lists.REQUEST_LOCALE_MEDLI,
        payload: {
          isFetching: true,
          collection: [],
          lookup: {},
        },
      });

      ListsService.localeMedli((apiError, responseCollection) => {
        if (helperMethods.isSameAs(getState(), 'localeMedli', responseCollection)) { res(); return; }
        dispatch({
          type: actionNames.lists.RECEIVE_LOCALE_MEDLI,
          payload: _.assign({}, createCollectionAndLookup(apiError, responseCollection), {
            isFetching: false,
            lastUpdated: new Date().getTime(),
          }),
        });
        res();
      });
    });
  };
}

export const actions = {
  requestLocaleMedliList,
};

export const selectors = {
  getLocaleMedliCollection: state => state.lists.localeMedli.collection,
  getLocaleMedli: (localeId, state) => (state.lists.localeMedli.lookup[localeId] || {}),
};
