
export enum ActionItemViewName {
  policy_personal_info = 'policy_personal_info',
  profile_name = 'profile_name',
  profile_dob = 'profile_dob',
  profile_gender = 'profile_gender',
  policy_group_info = 'policy_group_info',
  profile_residence = 'profile_residence',
  policy_health_info = 'policy_health_info',
  policy_user_account = 'policy_user_account',
  policy_user_email = 'policy_user_email',
  policy_communication = 'policy_communication',
  user_signup = 'user_signup',
  email_verification = 'email_verification',
  policy_dependent_authorization = 'policy_dependent_authorization',
  self_owner_selection = 'self_owner_selection',
}

export enum ActionItemStepType {
  policy = 'policy',
  data_element = 'data_element',
}

export interface ActionItem {
  view: string;
  policy_id: number[];
  type: string;
}

export interface ActionItems {
  steps: ActionItem[];
}
