import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CloseButton, ModalButtons } from '../../../components/buttons/Buttons';

import './ShareCardIncludeUrl.styles.scss';

interface Props {
  onClickYes(): void;
  onClickNo(): void;
  onClose(): void;
}

const ShareCardIncludeUrl = (props: Props) => (
  <div className="cmp-sharecard-include-url">
    <div className="cmp-sharecard-include-url-close-button">
      <CloseButton onClick={props.onClose} />
    </div>
    <div className="cmp-sharecard-include-url-heading font-color-profile font-title-2">
      <FormattedMessage id="shareCardPDFPreview.includeURL.heading" />
    </div>
    <div className="cmp-sharecard-include-url-text font-subhead font-color-gsDark">
      <FormattedMessage id="shareCardPDFPreview.includeURL.text" />
    </div>
    <div className="cmp-sharecard-include-url-buttons">
      <ModalButtons.Primary className="profile-background-color-accent" onClick={props.onClickYes}>
        <FormattedMessage id="shareCardPDFPreview.includeURL.yes.button.label" />
      </ModalButtons.Primary>
      <ModalButtons.Secondary className="" onClick={props.onClickNo}>
        <FormattedMessage id="shareCardPDFPreview.includeURL.no.button.label" />
      </ModalButtons.Secondary>
    </div>
  </div>
);

export default ShareCardIncludeUrl;
