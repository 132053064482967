import React from 'react';

import { colorClassNames } from '../../styles/Colors';

interface Props {
  color?: string;
}

const DEFAULT_COLOR = 'gsMedDark';

const getClassName = (props: Props) => {
  const { color = DEFAULT_COLOR } = props;
  return colorClassNames.fill(color);
};

const Small = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px">
    <g id="Layer_41" data-name="Layer 41" className={getClassName(props)}>
      <path d="M14.05,8c0-.2,0-.41,0-.61l1.19-.88L15.1,6.1a7.26,7.26,0,0,0-.73-1.78L14.15,4l-1.46.23a6,6,0,0,0-.87-.87l.23-1.46-.37-.22A7.26,7.26,0,0,0,9.9.9L9.49.79,8.61,2A6.22,6.22,0,0,0,7.39,2L6.51.79,6.1.9a7.26,7.26,0,0,0-1.78.73L4,1.85l.23,1.46a6,6,0,0,0-.87.87L1.85,4l-.22.37A7.26,7.26,0,0,0,.9,6.1l-.11.41L2,7.39C2,7.59,2,7.8,2,8s0,.41,0,.61L.79,9.49.9,9.9a7.26,7.26,0,0,0,.73,1.78l.22.37,1.46-.23a6,6,0,0,0,.87.87L4,14.15l.37.22a7.26,7.26,0,0,0,1.78.73l.41.11L7.39,14a6.22,6.22,0,0,0,1.22,0l.88,1.19.41-.11a7.26,7.26,0,0,0,1.78-.73l.37-.22-.23-1.46a6,6,0,0,0,.87-.87l1.46.23.22-.37A7.26,7.26,0,0,0,15.1,9.9l.11-.41L14,8.61C14,8.41,14.05,8.2,14.05,8Zm-1.32-.79a4.49,4.49,0,0,1,0,1.58l-.06.38,1.1.81a6.07,6.07,0,0,1-.29.7l-1.35-.21-.22.31a4.66,4.66,0,0,1-1.13,1.13l-.31.22.21,1.35a6.07,6.07,0,0,1-.7.29l-.81-1.1-.38.06a4.66,4.66,0,0,1-1.58,0l-.39-.06L6,13.77a6.07,6.07,0,0,1-.7-.29l.21-1.35-.31-.22a4.66,4.66,0,0,1-1.13-1.13l-.22-.31-1.35.21a6.07,6.07,0,0,1-.29-.7l1.1-.81-.06-.38a4.49,4.49,0,0,1,0-1.58l.06-.38L2.23,6a6.07,6.07,0,0,1,.29-.7l1.35.21.22-.31A4.66,4.66,0,0,1,5.22,4.09l.31-.22L5.32,2.52A6.07,6.07,0,0,1,6,2.23l.8,1.1.39-.06a4.66,4.66,0,0,1,1.58,0l.38.06L10,2.23a6.07,6.07,0,0,1,.7.29l-.21,1.35.31.22a4.66,4.66,0,0,1,1.13,1.13l.22.31,1.35-.21a6.07,6.07,0,0,1,.29.7l-1.1.81Z"/>
      <path d="M8,5a3,3,0,1,0,3,3A3,3,0,0,0,8,5ZM8,9.71A1.71,1.71,0,1,1,9.71,8,1.72,1.72,0,0,1,8,9.71Z"/>
    </g>
  </svg>
);

const Medium = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
    <g id="Layer_41" data-name="Layer 41" className={getClassName(props)}>
      <path d="M20.68,12a8,8,0,0,0-.06-1l1.74-1.27-.14-.5A10.19,10.19,0,0,0,21.16,6.7l-.26-.44-2.12.33a8.07,8.07,0,0,0-1.37-1.37l.33-2.12-.44-.26a10.19,10.19,0,0,0-2.56-1.06l-.5-.14L13,3.38a8.39,8.39,0,0,0-1.94,0L9.76,1.64l-.5.14A10.19,10.19,0,0,0,6.7,2.84l-.44.26.33,2.12A8.07,8.07,0,0,0,5.22,6.59L3.1,6.26l-.26.44A10.19,10.19,0,0,0,1.78,9.26l-.14.5L3.38,11a7.87,7.87,0,0,0,0,1.94L1.64,14.24l.14.5A10.19,10.19,0,0,0,2.84,17.3l.26.44,2.12-.33a8.07,8.07,0,0,0,1.37,1.37L6.26,20.9l.44.26a10.19,10.19,0,0,0,2.56,1.06l.5.14L11,20.62a7.87,7.87,0,0,0,1.94,0l1.27,1.74.5-.14a10.19,10.19,0,0,0,2.56-1.06l.44-.26-.33-2.12a8.07,8.07,0,0,0,1.37-1.37l2.12.33.26-.44a10.19,10.19,0,0,0,1.06-2.56l.14-.5L20.62,13A8,8,0,0,0,20.68,12Zm-.05,2.84a9.83,9.83,0,0,1-.52,1.26l-2-.31-.27.37a6.87,6.87,0,0,1-1.68,1.68l-.37.27.31,2a9.83,9.83,0,0,1-1.26.52L13.65,19l-.46.08a7.31,7.31,0,0,1-2.38,0L10.35,19,9.16,20.63a9.83,9.83,0,0,1-1.26-.52l.31-2-.37-.27a7.05,7.05,0,0,1-1.68-1.68l-.27-.37-2,.31a9.83,9.83,0,0,1-.52-1.26L5,13.65l-.08-.46A8.07,8.07,0,0,1,4.82,12a8.07,8.07,0,0,1,.1-1.19L5,10.35,3.37,9.16A9.83,9.83,0,0,1,3.89,7.9l2,.31.27-.37A7.05,7.05,0,0,1,7.84,6.16l.37-.27-.31-2a9.83,9.83,0,0,1,1.26-.52L10.35,5l.46-.08a7.31,7.31,0,0,1,2.38,0l.46.08,1.19-1.63a9.83,9.83,0,0,1,1.26.52l-.31,2,.37.27a6.87,6.87,0,0,1,1.68,1.68l.27.37,2-.31a9.83,9.83,0,0,1,.52,1.26L19,10.35l.08.46a8.07,8.07,0,0,1,.1,1.19,8.07,8.07,0,0,1-.1,1.19l-.08.46Z"/>
      <path d="M12,7.84A4.16,4.16,0,1,0,16.16,12,4.16,4.16,0,0,0,12,7.84Zm0,6.82A2.66,2.66,0,1,1,14.66,12,2.66,2.66,0,0,1,12,14.66Z"/>
    </g>
  </svg>
);

const Large = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36px" height="36px">
    <g id="Layer_41" data-name="Layer 41" className={getClassName(props)}>
      <path d="M31.1,18A13.56,13.56,0,0,0,31,16.45l2.63-1.93-.17-.66A16.58,16.58,0,0,0,31.86,10l-.34-.59-3.23.5A13,13,0,0,0,26.1,7.71l.5-3.23L26,4.14a16.58,16.58,0,0,0-3.87-1.61l-.66-.17L19.55,5a13.39,13.39,0,0,0-3.1,0L14.52,2.36l-.66.17A16.58,16.58,0,0,0,10,4.14l-.59.34.5,3.23A12.61,12.61,0,0,0,7.71,9.9L4.48,9.4,4.14,10a16.58,16.58,0,0,0-1.61,3.87l-.17.66L5,16.45a13.39,13.39,0,0,0,0,3.1L2.36,21.48l.17.66A16.58,16.58,0,0,0,4.14,26l.34.59,3.23-.5A12.61,12.61,0,0,0,9.9,28.29l-.5,3.23.59.34a16.58,16.58,0,0,0,3.87,1.61l.66.17L16.45,31a13.39,13.39,0,0,0,3.1,0l1.93,2.63.66-.17A16.58,16.58,0,0,0,26,31.86l.59-.34-.5-3.23a13,13,0,0,0,2.19-2.19l3.23.5.34-.59a16.58,16.58,0,0,0,1.61-3.87l.17-.66L31,19.55A13.56,13.56,0,0,0,31.1,18Zm.24,4.27a12.89,12.89,0,0,1-.89,2.14l-3.05-.47-.36.5a11.46,11.46,0,0,1-2.6,2.6l-.5.36.47,3.05a12.89,12.89,0,0,1-2.14.89l-1.82-2.5-.61.1a10.83,10.83,0,0,1-3.68,0l-.61-.1-1.82,2.5a12.89,12.89,0,0,1-2.14-.89l.47-3.05-.5-.36A11.26,11.26,0,0,1,9,24.44l-.36-.5-3,.47a12.89,12.89,0,0,1-.89-2.14l2.5-1.82-.1-.61a10.66,10.66,0,0,1,0-3.68l.1-.61-2.5-1.82a12.89,12.89,0,0,1,.89-2.14l3.05.47.36-.5A11.26,11.26,0,0,1,11.56,9l.5-.36-.47-3a12.89,12.89,0,0,1,2.14-.89l1.82,2.5.61-.1a10.83,10.83,0,0,1,3.68,0l.61.1,1.82-2.5a12.89,12.89,0,0,1,2.14.89L23.94,8.6l.5.36a11.46,11.46,0,0,1,2.6,2.6l.36.5,3.05-.47a12.89,12.89,0,0,1,.89,2.14l-2.5,1.82.1.61a10.66,10.66,0,0,1,0,3.68l-.1.61Z"/>
      <path d="M18,11.79A6.21,6.21,0,1,0,24.21,18,6.22,6.22,0,0,0,18,11.79Zm0,10.42A4.21,4.21,0,1,1,22.21,18,4.22,4.22,0,0,1,18,22.21Z"/>
    </g>
  </svg>
);

const XLarge = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" width="56px" height="56px">
    <g id="Layer_41" data-name="Layer 41">
      <path d="M48.57,28a22.27,22.27,0,0,0-.16-2.56l4.16-3-.24-.91A25.3,25.3,0,0,0,49.8,15.4l-.47-.82-5.1.8a20.56,20.56,0,0,0-3.61-3.61l.8-5.1L40.6,6.2a25.3,25.3,0,0,0-6.09-2.53l-.91-.24-3,4.16a20.88,20.88,0,0,0-5.12,0l-3-4.16-.91.24A25.3,25.3,0,0,0,15.4,6.2l-.82.47.8,5.1a20.56,20.56,0,0,0-3.61,3.61l-5.1-.8-.47.82a25.3,25.3,0,0,0-2.53,6.09l-.24.91,4.16,3a20.56,20.56,0,0,0,0,5.12l-4.16,3,.24.91A25.3,25.3,0,0,0,6.2,40.6l.47.82,5.1-.8a20.56,20.56,0,0,0,3.61,3.61l-.8,5.1.82.47a25.3,25.3,0,0,0,6.09,2.53l.91.24,3-4.16a20.88,20.88,0,0,0,5.12,0l3,4.16.91-.24A25.3,25.3,0,0,0,40.6,49.8l.82-.47-.8-5.1a20.56,20.56,0,0,0,3.61-3.61l5.1.8.47-.82a25.3,25.3,0,0,0,2.53-6.09l.24-.91-4.16-3A22.13,22.13,0,0,0,48.57,28Zm.84,6.7a22.07,22.07,0,0,1-1.54,3.7L43,37.65l-.49.69a18.34,18.34,0,0,1-4.17,4.17l-.69.49.76,4.87a22.52,22.52,0,0,1-3.71,1.54l-2.91-4-.84.14a17.7,17.7,0,0,1-5.9,0l-.84-.14-2.91,4a22.07,22.07,0,0,1-3.7-1.54L18.35,43l-.69-.49a18.34,18.34,0,0,1-4.17-4.17L13,37.65l-4.87.75a22.07,22.07,0,0,1-1.54-3.7l4-2.91L10.43,31a17.53,17.53,0,0,1,0-5.9l.14-.84-4-2.91a22.07,22.07,0,0,1,1.54-3.7l4.87.75.49-.69a18.34,18.34,0,0,1,4.17-4.17l.69-.49L17.6,8.13a22.07,22.07,0,0,1,3.7-1.54l2.91,4,.84-.14a17.7,17.7,0,0,1,5.9,0l.84.14,2.91-4a22.52,22.52,0,0,1,3.71,1.54L37.65,13l.69.49a18.34,18.34,0,0,1,4.17,4.17l.49.69,4.87-.75a22.07,22.07,0,0,1,1.54,3.7l-4,2.91.14.84a17.53,17.53,0,0,1,0,5.9l-.14.84Z"/>
      <path d="M28,18.37A9.63,9.63,0,1,0,37.63,28,9.64,9.64,0,0,0,28,18.37Zm0,16.51A6.88,6.88,0,1,1,34.88,28,6.88,6.88,0,0,1,28,34.88Z"/>
    </g>
  </svg>
);

const IconSettings = {
  Small,
  Medium,
  Large,
  XLarge,
};

export default IconSettings;
