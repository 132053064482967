import React from 'react';
import { FormattedMessage } from 'react-intl';

import { HealthEntry } from '../../../../models/HealthEntry';

import { useHealthEntryTrackableListForActiveProfile, useTrackableForActiveProfile } from '../../../hooks/useTrackable';
import { Card, CardRowUI, Icons } from '../../../../bphComponents/bundle';
import COLORS from '../../../styles/Colors';
import { CloseButton } from '../../../components/buttons/Buttons';
import FlexRow from '../../../components/FlexRow';
import ContentWrapper from '../../../components/ContentWrapper';
import { ModalPortal } from '../../../components/modal/ModalContext';
import { OverlayBlur } from '../../../redux/ducks/systemModal';
import RouteNames from '../../../appAuthorized/RouteNames';
import { useNavigate } from '../../../hooks/useNavigate';
import { iTrackableTerm } from '../../../../apiServices/apiModels/Trackable';
import { useUIState } from '../../../hooks/useUIState';

const EnableTrackablesModal = (props) => {
  const { availableTrackables, trackableTermLookup, enabledTrackableIds, onSelect, onClose } = props;

  return (
    <div className="util-application-content-width-with-margin" style={{ position: 'absolute', bottom: 0, marginBottom: 8 }}>
      <ContentWrapper>

      <Card.ExternalContent noMarginBottom={true}>
        <FlexRow>
          <div />
          <div style={{ marginRight: -10 }}>
            <CloseButton onClick={onClose} />
          </div>
        </FlexRow>
      </Card.ExternalContent>
      <Card.CardShape>
        <Card.CardContentFirst>
          <div className="font-caption-1 font-color-gsDark">
            <FormattedMessage id="healthEntry.viewTrackingList.message" />
          </div>
        </Card.CardContentFirst>

        {
          availableTrackables.map((at, i) => {
            const tId = at.trackable_term_id;
            const count = at.observation_count || 0;
            const t = trackableTermLookup[tId];
            const isSelected = enabledTrackableIds.includes(tId);
            return (
              <CardRowUI.SelectableRow
                key={tId}
                isSelected={isSelected}
                selectedClassName="fill-color-profile-highlight"
                disabledPadding={i === 0 ? ['top'] : null}
                notSelectedColor=""
                hasBottomBorder={true}
                onClick={() => { onSelect(tId); }}
              >
                <div className="font-body">
                  {t.name}{count > 0 ? <span className="font-color-gsMedDark">{` (${count})`}</span> : null}
                </div>
              </CardRowUI.SelectableRow>
            );
          })
        }
      </Card.CardShape>
      </ContentWrapper>
    </div>
  );
};

interface Props {
  healthEntry: HealthEntry;
  parentHealthEntryId?: number;
  profileId: number;
  languageCode: string;
  isNew: boolean;
}

interface iSingleTrackableProps {
  trackableId: number;
  observationCount: number;
  trackableTerm: iTrackableTerm;
}
const SingleTrackable: React.FC<iSingleTrackableProps> = (props) => {
  const { trackableTerm, trackableId, observationCount } = props;
  const [, navActions] = useNavigate();
  const [, trackableActions] = useTrackableForActiveProfile();
  const [, uiActions] = useUIState();

  if (observationCount === 0) {
    return (
      <Card.CardShape>
        <Card.CardContent
          onClick={() => {
            uiActions.setStartLoading();
            trackableActions.apiCreateCurrentObservation(trackableId).then((newObservation) => {
              uiActions.setStopLoading();
              navActions.goTo(RouteNames.trackableObservation(trackableId, newObservation.id));
            });
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icons.IconAddOutline.Medium color={COLORS.gsMedDark} />
            <div className="font-color-gsDark" style={{ marginLeft: 12 }}>
              <FormattedMessage id="healthEntry.addTrackingItem.button.label" values={{ string1: trackableTerm.name }} />
            </div>
          </div>
        </Card.CardContent>
      </Card.CardShape>
    );
  } else {
    return (
      <Card.CardShape>
        <CardRowUI.Summary
          text={[
            <span key="0">
              <span className="font-color-gsDark">
                <FormattedMessage id="healthEntry.viewOccurrenceList.button.label" values={{ string1: trackableTerm.name }} />
              </span>
              <span className="font-color-gsMedDark">{` (${observationCount})`}</span>
            </span>,
          ]}
          onClick={() => {
            navActions.goTo(RouteNames.trackableObservationList(trackableId));
          }}
        />
      </Card.CardShape>
    );
  }
};

const EnabledTrackables = (props) => {
  const { enabledTrackables, trackableTermLookup } = props;

  if (enabledTrackables.length === 0) { return null; }

  return enabledTrackables.map(t => (
    <SingleTrackable
      key={t.id}
      trackableId={t.trackable_id}
      trackableTerm={trackableTermLookup[t.trackable_term_id]}
      observationCount={t.observation_count}
    />
  ));
};

const NotEnabledTrackables = (props) => {
  const { trackableTermLookup, notEnabledTrackableIds, availableTrackables, onSelect, onSelectSingle } = props;

  // const onlyOne = notEnabledTrackableIds.length === 1;
  // if (onlyOne) { return null; }

  const availableTrackablesCount = availableTrackables.length;
  const allDisabled = notEnabledTrackableIds.length === availableTrackablesCount;
  const isSingle = availableTrackablesCount === 1;
  const allEnabled = notEnabledTrackableIds.length === 0;

  if (isSingle && allEnabled) { return null; }

  const firstTrackable = trackableTermLookup[notEnabledTrackableIds[0]];

  const cardShapeProps = isSingle ? {} : {
    isFlat: !allDisabled,
    background: allDisabled ? null : COLORS.gsLight,
  };

  const text = isSingle ? [
    <span key="0" className="font-color-gsDark">
      <FormattedMessage id="healthEntry.startTrackingSingle.button.label" values={{ string1: firstTrackable.name }} />
    </span>,
  ] : [
    <span key="0" className="font-color-gsDark">
      <FormattedMessage id="healthEntry.startTrackingMultiple.button.label" />
    </span>,
    <span key="1" className="font-color-gsMedDark">
      {availableTrackables.map(t => trackableTermLookup[t.trackable_term_id].name).join(', ')}
    </span>,
  ];

  return (
    <Card.CardShape {...cardShapeProps}>
      <CardRowUI.Summary
        text={text}
        onClick={isSingle ? () => onSelectSingle(notEnabledTrackableIds[0]) : onSelect}
      />
    </Card.CardShape>
  );
};

const HETrackables: React.FC<Props> = (props) => {
  const { healthEntry } = props;

  const [{ trackableTermLookup, enabledTrackables, availableTrackables, isLoaded }, trackableActions] = useHealthEntryTrackableListForActiveProfile(healthEntry.id);
  const [isVisible, setIsVisible] = React.useState(false);
  const [, uiActions] = useUIState();

  const enabledIds = enabledTrackables.map(t => t.trackable_term_id);
  const notEnabledTrackableIds = availableTrackables.filter(t => !enabledIds.includes(t.trackable_term_id)).map(t => t.trackable_term_id);

  if (availableTrackables.length === 0) { return null; }
  if (!isLoaded) { return null; }

  return (
    <>
    <Card.ExternalCardTitle>
      <FormattedMessage id="healthEntry.relatedTracking.label" />
      <Icons.IconTracking.Small />
    </Card.ExternalCardTitle>

    <EnabledTrackables
      enabledTrackables={enabledTrackables}
      trackableTermLookup={trackableTermLookup}
    />
    <NotEnabledTrackables
      availableTrackables={availableTrackables}
      notEnabledTrackableIds={notEnabledTrackableIds}
      trackableTermLookup={trackableTermLookup}
      onSelectSingle={(trackableId) => {
        uiActions.setStartLoading();
        trackableActions.apiToggleTrackable(trackableId)
          .then(() => {
            uiActions.setStopLoading();
          });
      }}
      onSelect={() => {
        setIsVisible(true);
      }}
    />

    <ModalPortal isVisible={isVisible} blurSize={OverlayBlur.lightSmallBlur} onBackgroundDismiss={() => { setIsVisible(false); }}>
      <EnableTrackablesModal
        trackableTermLookup={trackableTermLookup}
        availableTrackables={availableTrackables}
        enabledTrackableIds={enabledIds}
        onClose={() => {
          setIsVisible(false);
        }}
        onSelect={(trackableId) => {
          uiActions.setStartLoading();
          trackableActions.apiToggleTrackable(trackableId)
            .then(() => {
              uiActions.setStopLoading();
            });
        }}
      />
    </ModalPortal>
    </>
  );
};

export default HETrackables;
