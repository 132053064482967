import React, { FC, useEffect, useState } from 'react';
import Dotdotdot from 'react-dotdotdot';

interface Props {
  lines: number;
}

const MultilineTruncatedText: FC<Props> = (props) => {
  const { lines, children } = props;
  const [forceRender, setForceRender] = useState(1);

  const handleOnResize = () => {
    setForceRender(+Date.now());
  };

  useEffect(() => {
    window.addEventListener('resize', handleOnResize);
    return () => {
      window.removeEventListener('resize', handleOnResize);
    };
  }, []);

  return (
    <Dotdotdot key={forceRender} clamp={lines} useNativeClamp={false}>
      {children}
    </Dotdotdot>
  );
};

export default MultilineTruncatedText;
