export interface GeneVariantPresentData {
  id: number;
  cui: string;
  name: string;
  key: string;
  list_type?: string;
}

export default class GeneVariantPresentModel {

  public data: GeneVariantPresentData;

  constructor(d: GeneVariantPresentData) {
    this.data = {
      id: null,
      cui: '',
      name: '',
      key: '',
      list_type: null,
    };
    this.data = {...d};
  }

  hasVariant() {
    return this.data.name === 'yes';
  }

  textKey() {
    return `${this.data.key}.text`;
  }

  nameKey() {
    return `${this.data.key}.name`;
  }

}