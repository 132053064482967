import React from 'react';
import { Redirect } from 'react-router';
import { TrackableModel } from '../../apiServices/apiModels/Trackable';
import TrackablesService from '../../apiServices/TrackablesService';
import AppUtils from '../../utilities/AppUtils';
import RouteNames from '../appAuthorized/RouteNames';

import ContentLoader from '../components/ContentLoader';
import { useUIState } from '../hooks/useUIState';
import { updateTrackableCacheAfterObservationUpdate } from '../hooks/useTrackable';

function createObservation(profileId, trackableId): Promise<string> {
  return new Promise((res, rej) => {
    TrackablesService
      .get(profileId, trackableId)
      .then((tData) => {
        const t = new TrackableModel(tData.data, null, tData.trackable_terms);
        TrackablesService.createObservation(profileId, trackableId, t.createNewObservation())
          .then((o: any) => {
            updateTrackableCacheAfterObservationUpdate();
            res(RouteNames.trackableObservation(trackableId, o.id));
          }).catch(rej);
      }).catch(rej);
  });
}

interface Props {
  location: any;
}

const ActionsView: React.FC<Props> = (props) => {
  const queryParams = AppUtils.getQueryParams(props.location.search);
  const actionProfileId = Number(queryParams.profileId);
  const [destination, setDestination] = React.useState('');
  const [{ activeProfileId }, uiActions] = useUIState();

  React.useEffect(() => {
    if (!actionProfileId) {
      setDestination(RouteNames.home());
      return;
    }

    if (actionProfileId !== activeProfileId) {
      uiActions.setActiveProfile(actionProfileId);
      return;
    }

    switch (queryParams.actionName) {
      case 'createObservation':
        createObservation(actionProfileId, Number(queryParams.trackableId))
          .then((route) => {
            setDestination(route);
          }).catch(() => {
            setDestination(RouteNames.myBackpack());
          });
        break;
      default:
        setDestination(RouteNames.home());
        break;
    }
  }, [activeProfileId]);

  if (destination) {
    return <Redirect to={destination} />;
  }

  return (
    <div>
      <ContentLoader isContentLoaded={false} blockRenderUntilLoaded={true} isFixed={true} />
    </div>
  );
};

export default ActionsView;
