

import React from 'react';



export interface MedliTermData {
  id: string;
  strings: {
    en: string;
  },
  canonical_strings: {
    en: string;
  },
}


interface MedliTermProps {
  medliTerm: MedliTermData;
  languageCode: string;
  className?: string;
  style?: object;
}

class MedliTermText extends React.Component<MedliTermProps, {}> {
  render() {
    const { languageCode, medliTerm, className, style } = this.props;
    const text = medliTerm.strings[languageCode] || '';
    return (
      <span className={className} style={style}>
        {text}
      </span>
    );
  }
}

interface MedliTermCollectionTextProps {
  medliTerms: MedliTermData[];
  languageCode: string;
  className?: string;
  style?: object;
}

export class MedliTermCollectionText extends React.Component<MedliTermCollectionTextProps, {}> {
  render() {
    const { languageCode, medliTerms, className, style } = this.props;
    const text = medliTerms.map(m => (m.strings[languageCode] || '')).join(',');

    return (
      <span className={className} style={style}>
        {text}
      </span>
    );
  }
}

export default MedliTermText;
