
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ScrollAwareScroller, ScrollAwareContext, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentWrapper from '../../components/ContentWrapper';
import ContentFrame from '../../applicationFrame/ContentFrame';

import { useNavigate } from '../../hooks/useNavigate';
import { Buttons, Card, Icons } from '../../../bphComponents/bundle';
import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import RouteNames from '../../appAuthorized/RouteNames';
import { useTrackableReminders } from '../../hooks/useTrackableReminders';
import { useUIEditReminder } from '../../hooks/useUIEdit';
import { fixedMediumDate } from '../../localization/LocalizedDate';
import { useActiveUser } from '../../hooks/useUser';
import DateUtil from '../../../utilities/DateUtil';
import COLORS from '../../styles/Colors';
import FlexRow from '../../components/FlexRow';
import { LocalizedTime } from '../../localization/LocalizedTime';
import DeleteModal from '../../components/modal/DeleteModal';
import ContentLoader from '../../components/ContentLoader';

interface Props {
  match: any;
  intl: any;
}
const TrackableReminderSettings: React.FC<Props> = injectIntl((props) => {
  const trackableId = Number(props.match.params.trackableId);
  const { intl } = props;

  const [, navActions] = useNavigate();
  const [{ preferredLanguageCode }] = useActiveUser();
  const [initialReminderState, reminderActions] = useTrackableReminders(trackableId, intl);
  const [editingReminderState, editReminderActions] = useUIEditReminder();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);

  const reminder = reminderActions.instantiate(editingReminderState.editingReminder);
  const { startDate, dayCount } = editingReminderState.editingReminder;

  const endDate = editingReminderState.editingReminder.dayCount > 0
    ? fixedMediumDate(DateUtil.getDateFromDaysAway(startDate, dayCount), 'P1D')
    : '';

  React.useEffect(() => {
    if (!initialReminderState.isLoaded) { return; }

    if (!editingReminderState.isLoaded) {
      editReminderActions.updateReminder(initialReminderState.reminderData);
    }
  }, [initialReminderState.isLoaded]);

  if (!initialReminderState.isLoaded) { return null; }

  return (

    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          cancel={() => {
            editReminderActions.clear();
            navActions.goBack(RouteNames.trackableObservationList(trackableId));
          }}
          done={() => {
            if (isBusy) { return; }

            setIsBusy(true);
            reminderActions.checkAndRequestPermission()
              .then(() => {
                reminderActions.updateReminder(editingReminderState.editingReminder)
                  .then(() => {
                    navActions.goBackTo(RouteNames.trackableObservationList(trackableId));
                    editReminderActions.clear();
                  });
              }).catch(() => {
                setIsBusy(false);
              });
          }}
          color="white"
        />

        <ContentFrame className="bkgd-color-light-neutral">
          <ScrollAwareScroller>
            <AppHeaderScrollableText id="heReminder.title.text" />

            <ContentWrapper>
            <ContentLoader isContentLoaded={!isBusy}>

              <Card.CardShape>
                <Card.CardContent
                  hasBottomBorder={true}
                  onClick={() => {
                    navActions.goTo(RouteNames.trackableReminderDuration(trackableId));
                  }}
                >
                  <div className="font-caption-1 font-color-gsDarkest">
                    <FormattedMessage id="heReminder.startDate.field.label" />
                  </div>
                  <div className="font-body font-color-gsDarkest" style={{ marginTop: 4 }}>
                    {fixedMediumDate(startDate, 'P1D', preferredLanguageCode)}
                  </div>
                  {
                    endDate ? (
                      <div className="font-footnote font-color-gsDark" style={{ marginTop: 4 }}>
                        <FormattedMessage id="heReminder.endDate.field.message" values={{ string1: endDate }} />
                      </div>
                    ) : null
                  }
                </Card.CardContent>
                <Card.CardContent
                  hasBottomBorder={false}
                  onClick={() => {
                    navActions.goTo(RouteNames.trackableReminderFrequency(trackableId));
                  }}
                >
                  <FlexRow>
                    {
                      reminder.isDailyFrequency ? (
                        <div className="font-body font-color-gsDarkest">
                          <FormattedMessage id="frequency.daily.field.label" />
                        </div>
                      ) : (
                        <div>
                        <div className="font-body font-color-gsDarkest">
                          <FormattedMessage id="frequency.daysOfWeek.field.label" />
                        </div>
                        <div className="font-footnote font-color-gsDark" style={{ marginTop: 4 }}>
                          {reminder.getDayPhraseIds().map(id => intl.formatMessage({ id })).join(', ')}
                        </div>
                        </div>
                      )
                    }
                    <Icons.IconDivotArrowRight.Medium color={COLORS.gsMedDark} />
                  </FlexRow>
                </Card.CardContent>
              </Card.CardShape>
              <Card.CardShape>
                <Card.CardContent  disabledPadding={['top', 'bottom']}>
                  {
                    reminder.data.times.map((t, i) => (
                      <Card.CardContent
                        key={i}
                        disabledPadding={['left', 'right']}
                        hasBottomBorder={true}
                        onClick={() => {
                          navActions.goTo(RouteNames.trackableReminderTime(trackableId, i));
                        }}
                      >
                        <FlexRow>
                          <div className="font-body font-color-gsDarkest">
                            <LocalizedTime
                              dateTime={{
                                time: t,
                                timezone_offset: reminder.getTimezoneOffset(),
                                date: startDate,
                                precision: 'P1D',
                              }}
                            />
                          </div>
                          {
                            i === 0 ? null : (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const tempReminder = reminderActions.instantiate(editingReminderState.editingReminder);
                                  tempReminder.removeTime(i);
                                  editReminderActions.updateReminder(tempReminder.data);
                                }}
                                style={{ marginRight: 4 }}
                              >
                                <Icons.IconRemoveCircle.Small color={COLORS.csHotRed} />
                              </button>
                            )
                          }
                        </FlexRow>
                      </Card.CardContent>
                    ))
                  }

                  <Card.CardContent
                    hasBottomBorder={false}
                    disabledPadding={['left', 'right']}
                    onClick={() => {
                      navActions.goTo(RouteNames.trackableReminderTimeNew(trackableId));
                    }}
                  >
                    <Buttons.InlineAddButton
                      onClick={() => {}}
                      className="stroke-color-profile-accent"
                      color=""
                    >
                      <span className="font-body font-color-profile-accent" style={{ display: 'flex', textAlign: 'left' }}>
                        <FormattedMessage id="heReminder.addTime.button.label" />
                      </span>
                    </Buttons.InlineAddButton>

                  </Card.CardContent>
                </Card.CardContent>
              </Card.CardShape>

              {
                reminder.isNew ? null : (
                  <Card.ExternalContent>
                    <FlexRow>
                      <div />

                      <button
                        onClick={() => {
                          setShowDeleteModal(true);
                        }}
                      >
                        <Icons.IconTrash.Medium color={COLORS.gsMiddle} />
                      </button>
                    </FlexRow>
                  </Card.ExternalContent>
                )
              }

              <DeleteModal
                isVisible={showDeleteModal}
                primaryButtonPhrase={{
                  id: 'heReminder.deleteConfirm.button.label',
                }}
                onConfirmAction={() => {
                  if (isBusy) { return; }
                  setIsBusy(true);

                  reminderActions.deleteReminder()
                    .then(() => {
                      setShowDeleteModal(false);
                      editReminderActions.clear();
                      navActions.goBack(RouteNames.trackableObservationList(trackableId));
                    });
                }}
                onCancelAction={() => {
                  setShowDeleteModal(false);
                }}
              />
            </ContentLoader>
            </ContentWrapper>
          </ScrollAwareScroller>
        </ContentFrame>

      </ScrollAwareContext>
    </div>
  );
});

export default TrackableReminderSettings;
