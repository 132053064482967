import React from 'react';

import GroupTitle from './GroupTitle';
import { FormattedMessage } from 'react-intl';
import ContentPanel from '../ContentPanel';
import List, { BasicListItem } from '../../../../components/List';
import { ClampedText, NewLineMore } from '../../../../components/ClampedText';

export const ShareCardCommentGroup = ({ comment, colorModel }) => {
  if (!comment) return null;

  return (
    <div className="stroke-color-gsMedDark">
      <GroupTitle
        text={<FormattedMessage id="shareCard.comments.heading.text"/>}
      />
      <ContentPanel hasPadding={false} style={{ marginTop: 0 }}>
        <List hasBorder paddingSize="large">
          <BasicListItem className="util-inset-listitem-frame-padding">
            <div className="font-body">
              <ClampedText
                lines={2}
                newLineMore={(props) => <NewLineMore {...props} color={colorModel.darkAccentColor()}/>}
              >
                <div>{comment}</div>
              </ClampedText>
            </div>
          </BasicListItem>
        </List>
      </ContentPanel>
    </div>
  );
};
