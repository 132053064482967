import { ProfileData } from './ProfileModel';

export enum ConsentPolicyName {
  healthInfo = 'healthInfo',
  informedConsent = 'informedConsent',
  dependentAuth = 'dependentAuth',
}

export enum ConsentPolicyState {
  withheld = 'withheld',
  provided = 'provided',
  notConsented = 'not_consented',
}

export interface InformedConsentDetailInterface {
  age_of_informed_consent: number;
  age_of_research_assent: number;
  consent_flow: string;
}

export interface ConsentInterface {
  consent_date: string;
  consent_detail?: InformedConsentDetailInterface;
  consent_policy_name: ConsentPolicyName;
  consent_policy_version: string;
  consenting_profile?: ProfileData;
  state: ConsentPolicyState;
}

export interface ConsentPayloadInterface {
  [ConsentPolicyName.healthInfo]: ConsentInterface;
  [ConsentPolicyName.informedConsent]: ConsentInterface;
  [ConsentPolicyName.dependentAuth]: ConsentInterface;
}
