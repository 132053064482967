import React from 'react';

interface Props {
  color?: string;
  size?: string;
}

const IconSVG: React.FC<Props> = (props) => (
  <svg height={props.size} width={props.size} style={{ display: 'flex' }} viewBox="0 0 24 24">
    <path fill={props.color} d="M14.2 2.9C14.4 2.9 14.5 3 14.5 3.2V7.2C14.5 7.4 14.6 7.5 14.8 7.5H14.9C16.1 6.8 17.2 6.1 18.4 5.5H18.5C18.6 5.5 18.7 5.6 18.7 5.6C19.4 6.9 20.2 8.2 20.9 9.5C21 9.6 20.9 9.8 20.8 9.9C19.7 10.6 18.5 11.2 17.4 11.9C17.2 12 17.2 12.3 17.4 12.4C18.5 13.1 19.7 13.7 20.8 14.4C20.9 14.5 21 14.6 20.9 14.8C20.2 16.1 19.4 17.4 18.7 18.6C18.6 18.7 18.6 18.7 18.5 18.7H18.4C17.2 18 16.1 17.4 14.9 16.7H14.8C14.7 16.7 14.5 16.8 14.5 17V21C14.5 21.2 14.4 21.3 14.2 21.3H9.8C9.6 21.3 9.5 21.2 9.5 21V17C9.5 16.8 9.4 16.7 9.2 16.7H9.1C7.9 17.4 6.8 18 5.7 18.7H5.6C5.5 18.7 5.4 18.7 5.4 18.6C4.7 17.3 3.9 16 3.2 14.7C3.1 14.6 3.2 14.4 3.3 14.3C4.4 13.6 5.6 13 6.7 12.3C6.9 12.2 6.9 11.9 6.7 11.8C5.6 11.1 4.4 10.5 3.3 9.8C3.1 9.7 3 9.5 3.1 9.4C3.8 8.1 4.6 6.8 5.3 5.6C5.4 5.5 5.4 5.5 5.5 5.5H5.6L9 7.5H9.1C9.2 7.5 9.4 7.4 9.4 7.2V3.2C9.4 3 9.5 2.9 9.7 2.9H14.2ZM14.2 1.7H9.8C9 1.7 8.3 2.4 8.3 3.2V5.5L6.3 4.4C6.1 4.2 5.8 4.2 5.5 4.2C5 4.2 4.5 4.5 4.2 4.9L2 8.8C1.6 9.5 1.8 10.5 2.6 10.9L4.6 12.1L3.4 12.8L2.6 13.3C1.9 13.7 1.6 14.6 2 15.4L3 17L4.2 19.1C4.5 19.6 5 19.9 5.5 19.9C5.8 19.9 6 19.8 6.3 19.7L8.3 18.5V20.8C8.3 21.6 9 22.3 9.8 22.3H14.2C15 22.3 15.7 21.6 15.7 20.8V18.5L17.7 19.7C17.9 19.8 18.2 19.9 18.5 19.9C19 19.9 19.6 19.6 19.8 19.1L21 17L22 15.2C22.4 14.5 22.2 13.5 21.4 13.1L19.4 11.9L20 11.6L21.4 10.8C22.1 10.4 22.4 9.5 22 8.7L21 7L19.8 4.9C19.5 4.4 19 4.1 18.5 4.1C18.2 4.1 18 4.2 17.7 4.3L15.7 5.5V3.2C15.7 2.4 15 1.7 14.2 1.7Z" />
    <path fill={props.color} d="M11.7 5V4.9C11.7 4.7 11.7 4.6 11.7 4.4C11.7 4.1 11.9 3.8 12.2 3.8C12.5 3.7 12.9 4 12.9 4.3C12.9 4.5 12.9 4.6 12.9 4.8C12.9 5.1 12.9 5.3 12.9 5.6C12.9 6.1 12.9 6.6 12.9 7.1C12.9 7.5 12.9 7.9 12.9 8.3C12.9 8.7 12.9 9.2 12.9 9.6C12.9 9.7 12.9 9.9 12.9 10C12.9 10.2 12.9 10.3 12.7 10.5C12.5 10.7 12.3 10.9 12.1 11.1C12.1 11.1 12.1 11.1 12 11.2C11.9 9.6 11.9 7.9 11.8 6.3C11.9 6.3 12 6.3 12.1 6.3C12.3 6.3 12.4 6.2 12.5 6.1C12.5 6.1 12.5 6.1 12.6 6.1C12.8 6.1 12.9 5.8 12.9 5.6C12.9 5.5 12.8 5.5 12.8 5.5C12.7 5.5 12.6 5.4 12.6 5.4C12.5 5.4 12.5 5.4 12.4 5.3C12.1 5.1 11.9 5.1 11.7 5Z" />
    <path fill={props.color} d="M13.1 9C13.3 9.1 13.5 9.2 13.6 9.3C13.7 9.5 13.8 9.8 13.8 10C13.8 10.4 13.6 10.7 13.4 11.1C13.2 11.4 13 11.6 12.7 11.9C12.5 12.1 12.3 12.3 12.1 12.5C11.9 12.8 11.8 13.1 11.8 13.5C11.8 13.6 11.8 13.8 11.8 13.9C11.8 13.9 11.8 13.9 11.8 14C11.7 14 11.6 13.9 11.6 13.9C11.4 13.7 11.3 13.5 11.3 13.3C11.2 13 11.2 12.6 11.4 12.3C11.5 12.1 11.7 11.9 11.8 11.7C12.1 11.4 12.3 11.2 12.6 10.9C12.7 10.8 12.8 10.7 12.9 10.6C13 10.5 13 10.4 13 10.3C13 10 13 9.7 13 9.4C13.1 9.2 13.1 9.1 13.1 9Z" />
    <path fill={props.color} d="M11.7 9.2C11.6 9.1 11.6 9.1 11.7 9.2C11.2 8.89999 10.9 8.5 10.6 8C10.3 7.39999 10.2 6.79999 10.5 6.2C10.6 6 10.8 5.79999 11 5.6C11.1 5.5 11.3 5.5 11.4 5.4C11.6 5.3 11.8 5.3 12.1 5.4C12.2 5.5 12.4 5.5 12.5 5.6H12.6C12.6 5.6 12.7 5.6 12.7 5.7C12.7 5.79999 12.7 5.8 12.6 5.8C12.5 5.8 12.5 5.8 12.4 5.8C12.3 5.8 12.2 5.8 12.1 5.8C12.1 5.8 12.1 5.8 12 5.8C12 5.9 12 5.9 12.1 5.9C12.2 5.9 12.2 5.9 12.3 5.9C12.3 5.9 12.3 5.9 12.4 5.9C12.4 5.9 12.3 5.9 12.3 6C12.2 6 12.1 6 12 6C11.8 6 11.7 6 11.5 6C11.4 6 11.3 6 11.3 6.2C11.2 6.39999 11.2 6.6 11.2 6.8C11.2 7.2 11.3 7.5 11.5 7.8C11.5 7.9 11.5 8 11.6 8V8.1C11.6 8.5 11.6 8.79999 11.7 9.2C11.7 9.1 11.7 9.1 11.7 9.2ZM11.9 5.6C12 5.6 12 5.6 11.9 5.6C12 5.6 12.1 5.6 12.1 5.6C12.1 5.5 12 5.4 11.9 5.5V5.6Z" />
    <path fill={props.color} d="M12.9 14.3C13.1 14.4 13.2 14.6 13.3 14.8C13.4 15.1 13.3 15.4 13.1 15.6C12.9 15.9 12.7 16.1 12.5 16.4C12.4 16.6 12.2 16.8 12 16.9C11.9 17 11.8 17.2 11.9 17.3C11.9 17.4 11.9 17.6 11.9 17.7C11.9 17.7 11.9 17.7 11.9 17.8H11.8C11.6 17.6 11.5 17.4 11.5 17.2C11.5 16.7 11.6 16.4 11.9 16C12.2 15.7 12.5 15.3 12.7 15V14.9C12.9 14.7 12.9 14.5 12.9 14.3Z" />
    <path fill={props.color} d="M12.1 15.5C12.1 15.2 12.1 15 12.1 14.8C12.1 14.4 12.1 13.9 12.1 13.5C12.1 13.1 12.2 12.8 12.4 12.6C12.5 12.5 12.6 12.3 12.8 12.2C12.8 12.3 12.8 12.4 12.8 12.5C12.8 12.9 12.8 13.4 12.7 13.8C12.7 14.1 12.7 14.4 12.7 14.7C12.7 14.8 12.7 14.8 12.7 14.9C12.5 15.1 12.3 15.3 12.1 15.5Z" />
    <path fill={props.color} d="M12.6 16.7C12.6 16.9 12.6 17.1 12.6 17.3C12.6 17.5 12.6 17.8 12.6 18C12.6 18.3 12.6 18.6 12.6 18.8C12.6 18.9 12.6 19 12.5 19.2C12.5 19.3 12.4 19.4 12.4 19.4C12.4 19.5 12.4 19.6 12.5 19.6C12.6 19.6 12.6 19.7 12.6 19.7C12.6 19.8 12.6 19.9 12.6 20C12.6 20.1 12.5 20.1 12.4 20.1C12.3 20.1 12.3 20 12.3 19.9C12.3 19.7 12.3 19.6 12.3 19.4C12.3 18.9 12.3 18.4 12.2 17.9C12.2 17.7 12.2 17.5 12.2 17.3C12.2 17.2 12.2 17.1 12.3 17C12.4 17 12.5 16.8 12.6 16.7Z" />
    <path fill={props.color} d="M12.7 18.8C12.9 19 12.9 19.3 12.8 19.5L12.7 19.6C12.7 19.6 12.6 19.5 12.7 19.5C12.7 19.4 12.8 19.3 12.8 19.3C12.7 19.1 12.8 19 12.7 18.8Z" />
  </svg>
);

const Small: React.FC<Props> = (props) => (
  <IconSVG {...props} size="16px" />
);

const Medium: React.FC<Props> = (props) => (
  <IconSVG {...props} size="24px" />
);

const Large: React.FC<Props> = (props) => (
  <IconSVG {...props} size="36px" />
);

const XLarge: React.FC<Props> = (props) => (
  <IconSVG {...props} size="56px" />
);

export {
  Small,
  Medium,
  Large,
  XLarge,
};
