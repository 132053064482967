
import React from 'react';
import Dotdotdot from 'react-dotdotdot';

import GroupTitle from './GroupTitle';
import IconNote from '../../../../components/icons/IconNote';
import { FormattedMessage } from 'react-intl';
import ContentPanel from '../ContentPanel';
import List, { BasicListItem } from '../../../../components/List';
import { RelativeDate } from '../../../../localization/LocalizedDate';
import { ClampedText, NewLineMore } from '../../../../components/ClampedText';

interface NoteData {
  updated_at: string;
  content: string;
}

export const ShareCardNotesGroup = ({ notes, languageCode, colorModel }) => {
  if (!notes || !notes.length) return null;

  return (
    <div className="stroke-color-gsMedDark">
      <GroupTitle
        Icon={IconNote.Medium}
        text={<FormattedMessage id="list.backpackSecondaryNav.notes.name"/>}
      />
      <ContentPanel hasPadding={false} style={{ marginTop: 0 }}>
        <List hasBorder paddingSize="large">
          {
            notes.map((note: NoteData, i) => (
              <BasicListItem key={i} className="util-inset-listitem-frame-padding">
                <div className="font-subhead" style={{ marginBottom: 8 }}>
                  <RelativeDate
                    date={note.updated_at}
                    precision="P1D"
                    dateKind="medium"
                    languageCode={languageCode}
                  />
                </div>

                <div className="font-body">
                  <ClampedText
                    lines={2}
                    newLineMore={(props) => <NewLineMore {...props} color={colorModel.darkAccentColor()}/>}
                    alternateCollapsedContent={
                      <Dotdotdot clamp={2} useNativeClamp={false}>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                          {note.content.split('\n')[0]}
                        </pre>
                      </Dotdotdot>
                    }
                  >
                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                      {note.content}
                    </pre>
                  </ClampedText>
                </div>
              </BasicListItem>
            ))
          }
        </List>
      </ContentPanel>
    </div>
  );
};
