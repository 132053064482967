
import React from 'react';


interface State {
  fontSize: number;
  fontClass: string;
}

interface BaseProps {
  text: string;
}

interface PropsWithFontSizes extends BaseProps {
  minFontSize: number;
  maxFontSize: number;
  minFontClass?: string;
  maxFontClass?: string;
}

interface PropsWithFontClasses extends BaseProps {
  minFontClass: string;
  maxFontClass: string;
  minFontSize?: number;
  maxFontSize?: number;
}

type Props = PropsWithFontSizes | PropsWithFontClasses;

class DynamicFontSize extends React.Component<Props, State> {

  thisRef: HTMLElement;

  state: State = {
    fontSize: this.props.minFontSize,
    fontClass: this.props.minFontClass,
  };

  componentDidMount() {
    const container = this.thisRef;
    const { minFontSize, maxFontSize, minFontClass, maxFontClass } = this.props;

    const bounds = container.getBoundingClientRect();
    const maxWidth = bounds.width;

    let tempText = document.createElement('div');
    document.body.appendChild(tempText);

    if (minFontClass && maxFontClass) {
      tempText.className = maxFontClass;
    } else {
      tempText.style.fontSize = `${maxFontSize}px`;
    }

    tempText.style.display = 'inline-block';
    tempText.innerText = this.props.text;

    const fullWidth = container.scrollWidth;

    document.body.removeChild(tempText);
    tempText = null;

    if (minFontClass && maxFontClass) {
      const optimalFontClass = fullWidth > maxWidth ? minFontClass : maxFontClass;

      this.setState({
        fontClass: optimalFontClass,
      });
      return;
    }

    const optimalFontSize = fullWidth > maxWidth ? minFontSize : maxFontSize;

    this.setState({
      fontSize: optimalFontSize,
    });
  }

  render() {
    const { fontSize, fontClass } = this.state;
    return (
      <div ref={(r) => { this.thisRef = r; }} className={fontClass} style={{ fontSize, lineHeight: `${fontSize + 8}px` }}>
        {this.props.text}
      </div>
    );
  }
}


export default DynamicFontSize;
