import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Card } from '../../../../bphComponents/bundle';
import COLORS from '../../../styles/Colors';
import { ProfileThemeMarkdown } from '../../../components/Markdown';

interface Props {
  heading: string;
  content: string;
  subheading?: string;
  isCollapsible?: boolean;
}

const HealthEntryGeneHelpText: React.FC<Props> = (props) => {
  const { isCollapsible } = props;
  const [isHelpBlockExpanded, setIsHelpBlockExpanded] = React.useState(false);

  return isCollapsible ? (
    <Card.CardShape isFlat={true} background={COLORS.gsLight}>
      <Card.CardContent>
        <Card.CollapsibleControl
          isMultiLine={false}
          isExpanded={isHelpBlockExpanded}
          onClick={() => { setIsHelpBlockExpanded(!isHelpBlockExpanded); }}
        >
          <div>
            <div className="font-color-profile-accent font-subhead">
              <FormattedMessage id={props.heading} />
            </div>
            <div className="font-color-gsMiddle font-footnote">
              <FormattedMessage id={props.subheading} />
            </div>
          </div>
        </Card.CollapsibleControl>
      </Card.CardContent>
      <Card.CollapsibleContent isExpanded={isHelpBlockExpanded} background={COLORS.gsLight}>
        <Card.CardContent>
          <ProfileThemeMarkdown phraseId={props.content} />
        </Card.CardContent>
      </Card.CollapsibleContent>
    </Card.CardShape>
  ) : (
    <Card.CardShape isFlat={true} background={COLORS.gsLight}>
      <Card.CardContent>
        <div className="font-color-profile-accent font-subhead" style={{ marginBottom: 8 }}>
          <FormattedMessage id={props.heading} />
        </div>
        <ProfileThemeMarkdown phraseId={props.content} />
      </Card.CardContent>
    </Card.CardShape>
  );
};

export default HealthEntryGeneHelpText;
