import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getActiveProfile, getIsMainProfile } from '../../redux/reduxSelectors';

import { AppHeaderScrollableText } from '../../../components/appNav/AppHeaderScrollable';
import ContentFrame from '../../../applicationFrame/ContentFrame';

import { ScrollAwareContext, ScrollAwareAppNavStep } from '../../../components/ScrollAware';
import ContentWrapper from '../../../components/ContentWrapper';
import ContentLoader from '../../../components/ContentLoader';

import { Card, CardRowUI } from '../../../../bphComponents/bundle';
import COLORS from '../../../styles/Colors';
import { GrayTextMarkdown } from '../../../components/Markdown';
import ProfileService from '../../../../apiServices/ProfileService';
import { OverlayBlur } from '../../../redux/ducks/systemModal';
import AppLoadingModal from '../../../components/modal/AppLoadingModal';
import { ModalPortal } from '../../../components/modal/ModalContext';
import { navigateBack } from '../../redux/NavigationActions';
import RouteNames from '../../RouteNames';
import FlexRow from '../../../components/FlexRow';
import { GreenWhiteToggleSwitch } from '../../../components/ToggleSwitch';
import HealthEntryCategoryNames from '../../../../constants/HealthEntryCategoryNames';

const CATEGORY_LABELS = {
  [HealthEntryCategoryNames.condition]: 'list.healthEntry.conditions.name',
  [HealthEntryCategoryNames.medication]: 'list.healthEntry.medications.name',
  [HealthEntryCategoryNames.otherTreatment]: 'list.healthEntry.treatments.name',
  [HealthEntryCategoryNames.procedure]: 'list.healthEntry.procedures.name',
  [HealthEntryCategoryNames.allergy]: 'list.healthEntry.allergies.name',
  [HealthEntryCategoryNames.vaccination]: 'list.healthEntry.vaccinations.name',
  [HealthEntryCategoryNames.measurement]: 'list.healthEntry.measurements.name',
  [HealthEntryCategoryNames.lab]: 'list.healthEntry.lab.name',
};

const CATEGORY_ORDER = [
  HealthEntryCategoryNames.condition,
  HealthEntryCategoryNames.medication,
  HealthEntryCategoryNames.procedure,
  HealthEntryCategoryNames.allergy,
  HealthEntryCategoryNames.vaccination,
  HealthEntryCategoryNames.measurement,
  HealthEntryCategoryNames.lab,
];

const REQUEST_STATUS = {
  initializing: 'initializing',
  idle: 'idle',
  success: 'success',
  waiting: 'waiting',
  error: 'error',
};

interface Props {
  profileName: string;
  profileId: number;
  isMainProfile: boolean;
  startLoading(): void;
}
const DataConnectionsSettings: React.FC<Props> = (props) => {
  const { profileId, profileName, isMainProfile, startLoading } = props;

  const [categories, setCategories] = React.useState({});
  const [sendEmailNotification, setSendEmailNotification] = React.useState(false);
  const [submitStatus, setSubmitStatus] = React.useState(REQUEST_STATUS.initializing);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const isLoaded = submitStatus !== REQUEST_STATUS.initializing;
  const titlePhrase = isMainProfile ? { id: 'dataConnectionSettings.user.title' } : { id: 'dataConnectionSettings.notUser.title', values: { string1: profileName } };

  React.useEffect(() => {
    ProfileService.getGreenlightSettings(profileId)
    .then(d => {
      setSubmitStatus(REQUEST_STATUS.idle);
      setCategories(d.collect);
      setSendEmailNotification(d.send_email_notification);
    });
  }, []);

  return (
    <div className="cmp-mybackpack">
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={submitStatus === REQUEST_STATUS.waiting}
          cancel={() => { navigateBack(RouteNames.dataConnections()); }}
          done={() => {
            setIsModalVisible(true);
            setSubmitStatus(REQUEST_STATUS.waiting);
            ProfileService.updateGreenlightSettings(profileId, { collect: categories, send_email_notification: sendEmailNotification })
            .then(d => {
              setSubmitStatus(REQUEST_STATUS.success);
            })
            .catch(err => {
              setSubmitStatus(REQUEST_STATUS.error);
            });
          }}
          onDisabledClick={() => {}}
          color="white"
          hasDropShadow
        />

        <ContentFrame>
          <div>
            <AppHeaderScrollableText {...titlePhrase} />

            <ContentWrapper isFlatContent={false}>
              <ContentLoader isContentLoaded={isLoaded} blockRenderUntilLoaded={false} isFixed={false}>
                <Card.CardShape isFlat background={COLORS.gsLight}>
                  <Card.CardContent>
                    <GrayTextMarkdown phraseId="dataConnectionSettings.intro.md" />
                  </Card.CardContent>
                </Card.CardShape>

                <Card.ExternalCardTitle>
                  <FormattedMessage id="dataConnectionSettings.heCategory.list.label" />
                </Card.ExternalCardTitle>
                <Card.CardShape>
                  {
                    CATEGORY_ORDER.map(c => (
                      <CardRowUI.SelectableRow
                        key={c}
                        hasBottomBorder={true}
                        isSelected={categories[c]}
                        isDisabled={false}
                        onClick={() => {
                          setCategories({
                            ...categories,
                            [c]: !categories[c],
                          });
                        }}
                        selectedColor=""
                        notSelectedColor=""
                        selectedClassName="fill-color-profile-highlight"
                        onCircleClick={() => {}}
                      >
                        <FormattedMessage id={CATEGORY_LABELS[c]} values={{ digit1: 2 }} />
                      </CardRowUI.SelectableRow>
                    ))
                  }

                </Card.CardShape>

                <Card.CardShape>
                  <Card.CardSection hasBottomBorder>
                    <Card.CardContent>
                      <FlexRow>
                        <span className="font-subhead">
                          <FormattedMessage id="dataConnectionSettings.commOptions.title" />
                        </span>
                        <GreenWhiteToggleSwitch
                          choices={[
                            {
                              name: 'disabled',
                              value: false,
                            },
                            {
                              name: 'enabled',
                              value: true,
                            },
                          ]}
                          isOff={!sendEmailNotification}
                          value={sendEmailNotification}
                          onChange={() => { setSendEmailNotification(!sendEmailNotification); }}
                        />
                      </FlexRow>
                    </Card.CardContent>
                  </Card.CardSection>
                  <Card.CardContent className="font-footnote">
                    <FormattedMessage id="dataConnectionSettings.commOptions.text" />
                  </Card.CardContent>
                </Card.CardShape>
              </ContentLoader>
            </ContentWrapper>
          </div>
        </ContentFrame>
      </ScrollAwareContext>
      <ModalPortal
        isVisible={isModalVisible}
        blurSize={OverlayBlur.lightSmallBlur}
        onBackgroundDismiss={() => { setSubmitStatus(REQUEST_STATUS.idle); }}
      >
        <AppLoadingModal
          isComplete={submitStatus === REQUEST_STATUS.success}
          onComplete={() => {
            setIsModalVisible(false);
            navigateBack(RouteNames.dataConnections());
          }}
        />
      </ModalPortal>
    </div>
  );
};

function mapStateToProps(state) {
  const profile = getActiveProfile(state);

  return {
    profileName: profile.greetingName(),
    profileId: profile.data.id,
    isMainProfile: getIsMainProfile(state),
  };
}

export default connect(mapStateToProps)(DataConnectionsSettings);
