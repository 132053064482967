
import { useSelector } from 'react-redux';
import { selectors as integrationsSelectors } from '../redux/ducks/integrations';
import ProfileService from '../../apiServices/ProfileService';


function fetchIntegrations(dispatch) {
  return function fetchIntegrations_API(queryKey, profileId) {
    return ProfileService.getExistingIntegrations(profileId)
      .then(integrations => {
        dispatch({
          type: 'app/integrations/RECEIVE_ALL',
          profileId,
          payload: integrations,
        });
        return integrations;
      });
  };
}

export function useIntegrations(profileId) {
  const integrationsData = useSelector(state => integrationsSelectors.getAll(state, profileId));

  return [{
    data: integrationsData,
    isLoaded: true,
  }];
}
