import * as React from 'react';
import { connect } from 'react-redux';
import { getLoggedInUser } from '../../../redux/reduxSelectors';
import { FormattedMessage } from 'react-intl';
import UserModel from '../../../../../models/UserModel';
import PhoneFormatter from '../../../../../utilities/PhoneFormatter';

interface Props {
  user: UserModel;
  mobileNumber: string;
  countryCodeKey: string;
}

@connect((state) => {
  const user = getLoggedInUser(state);

  return {
    user,
    mobileNumber: user.data.mobile_number || '',
  };
})
export class TwoFA extends React.Component<Partial<Props>, {}> {
  render() {
    const { user, countryCodeKey, mobileNumber } = this.props;

    if (!user.isMfaEnabled()) {
      return <FormattedMessage id="settings.2faDisabled.button.text"/>;
    }

    return <>
      <FormattedMessage id="settings.2faEnabled.button.text"/>
      {' '}
      <span className="font-color-gsMedLight">
        +{PhoneFormatter.getCountryCallingCode(user.data.iso2_code)} {mobileNumber}
      </span>
      </>;
  }
}
