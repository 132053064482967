import React from 'react';

const IconFileSmall = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 16 16">
    <g id="icon-file-audio">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="icon-audio">
        <g id="Group">
          <path id="Path" style={{ fill: '#b7b7b7' }} d="M10.71,9a.32.32,0,0,1,.22.33v6.05a.32.32,0,0,1-.22.33.37.37,0,0,1-.13,0,.39.39,0,0,1-.26-.1L8.48,13.73H7a.36.36,0,0,1-.25-.1.34.34,0,0,1-.1-.26V11.24a.34.34,0,0,1,.1-.26.36.36,0,0,1,.25-.1H8.48L10.32,9A.34.34,0,0,1,10.71,9Z" />
          <path id="Path_2" data-name="Path 2" style={{ fill: '#b7b7b7' }} d="M15.34,16.61a.67.67,0,0,1-.44-.17.64.64,0,0,1-.26-.47.68.68,0,0,1,.16-.53,5,5,0,0,0,0-6.27.68.68,0,0,1-.16-.53.68.68,0,0,1,.26-.48A.61.61,0,0,1,15.42,8a.68.68,0,0,1,.48.26,6.19,6.19,0,0,1,1.43,4,6.2,6.2,0,0,1-1.43,4A.68.68,0,0,1,15.34,16.61Z" />
          <path id="Path_3" data-name="Path 3" style={{ fill: '#b7b7b7' }} d="M13,15a.72.72,0,0,1-.49-.19.7.7,0,0,1-.22-.49.68.68,0,0,1,.2-.52,2.12,2.12,0,0,0,0-2.91.68.68,0,0,1-.2-.52.7.7,0,0,1,.22-.49A.68.68,0,0,1,13,9.65a.7.7,0,0,1,.5.22,3.54,3.54,0,0,1,0,4.87A.7.7,0,0,1,13,15Z" />
        </g>
      </g>
    </g>
  </svg>
);

const IconFileMedium = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 24 24">
    <g id="icon-file-audio">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="icon-audio">
        <g id="Group">
          <path id="Path" style={{ fill: '#b7b7b7' }} d="M10.71,9a.32.32,0,0,1,.22.33v6.05a.32.32,0,0,1-.22.33.37.37,0,0,1-.13,0,.39.39,0,0,1-.26-.1L8.48,13.73H7a.36.36,0,0,1-.25-.1.34.34,0,0,1-.1-.26V11.24a.34.34,0,0,1,.1-.26.36.36,0,0,1,.25-.1H8.48L10.32,9A.34.34,0,0,1,10.71,9Z" />
          <path id="Path_2" data-name="Path 2" style={{ fill: '#b7b7b7' }} d="M15.34,16.61a.67.67,0,0,1-.44-.17.64.64,0,0,1-.26-.47.68.68,0,0,1,.16-.53,5,5,0,0,0,0-6.27.68.68,0,0,1-.16-.53.68.68,0,0,1,.26-.48A.61.61,0,0,1,15.42,8a.68.68,0,0,1,.48.26,6.19,6.19,0,0,1,1.43,4,6.2,6.2,0,0,1-1.43,4A.68.68,0,0,1,15.34,16.61Z" />
          <path id="Path_3" data-name="Path 3" style={{ fill: '#b7b7b7' }} d="M13,15a.72.72,0,0,1-.49-.19.7.7,0,0,1-.22-.49.68.68,0,0,1,.2-.52,2.12,2.12,0,0,0,0-2.91.68.68,0,0,1-.2-.52.7.7,0,0,1,.22-.49A.68.68,0,0,1,13,9.65a.7.7,0,0,1,.5.22,3.54,3.54,0,0,1,0,4.87A.7.7,0,0,1,13,15Z" />
        </g>
      </g>
    </g>
  </svg>
);

const IconFileLarge = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="36px" width="36px" viewBox="0 0 36 36">
    <g id="icon-file-audio">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="icon-audio">
        <g id="Group">
          <path id="Path" style={{ fill: '#b7b7b7' }} d="M10.71,9a.32.32,0,0,1,.22.33v6.05a.32.32,0,0,1-.22.33.37.37,0,0,1-.13,0,.39.39,0,0,1-.26-.1L8.48,13.73H7a.36.36,0,0,1-.25-.1.34.34,0,0,1-.1-.26V11.24a.34.34,0,0,1,.1-.26.36.36,0,0,1,.25-.1H8.48L10.32,9A.34.34,0,0,1,10.71,9Z" />
          <path id="Path_2" data-name="Path 2" style={{ fill: '#b7b7b7' }} d="M15.34,16.61a.67.67,0,0,1-.44-.17.64.64,0,0,1-.26-.47.68.68,0,0,1,.16-.53,5,5,0,0,0,0-6.27.68.68,0,0,1-.16-.53.68.68,0,0,1,.26-.48A.61.61,0,0,1,15.42,8a.68.68,0,0,1,.48.26,6.19,6.19,0,0,1,1.43,4,6.2,6.2,0,0,1-1.43,4A.68.68,0,0,1,15.34,16.61Z" />
          <path id="Path_3" data-name="Path 3" style={{ fill: '#b7b7b7' }} d="M13,15a.72.72,0,0,1-.49-.19.7.7,0,0,1-.22-.49.68.68,0,0,1,.2-.52,2.12,2.12,0,0,0,0-2.91.68.68,0,0,1-.2-.52.7.7,0,0,1,.22-.49A.68.68,0,0,1,13,9.65a.7.7,0,0,1,.5.22,3.54,3.54,0,0,1,0,4.87A.7.7,0,0,1,13,15Z" />
        </g>
      </g>
    </g>
  </svg>
);

const IconFileXLarge = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="56px" width="56px" viewBox="0 0 56 56">
    <g id="icon-file-audio">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="icon-audio">
        <g id="Group">
          <path id="Path" style={{ fill: '#b7b7b7' }} d="M10.71,9a.32.32,0,0,1,.22.33v6.05a.32.32,0,0,1-.22.33.37.37,0,0,1-.13,0,.39.39,0,0,1-.26-.1L8.48,13.73H7a.36.36,0,0,1-.25-.1.34.34,0,0,1-.1-.26V11.24a.34.34,0,0,1,.1-.26.36.36,0,0,1,.25-.1H8.48L10.32,9A.34.34,0,0,1,10.71,9Z" />
          <path id="Path_2" data-name="Path 2" style={{ fill: '#b7b7b7' }} d="M15.34,16.61a.67.67,0,0,1-.44-.17.64.64,0,0,1-.26-.47.68.68,0,0,1,.16-.53,5,5,0,0,0,0-6.27.68.68,0,0,1-.16-.53.68.68,0,0,1,.26-.48A.61.61,0,0,1,15.42,8a.68.68,0,0,1,.48.26,6.19,6.19,0,0,1,1.43,4,6.2,6.2,0,0,1-1.43,4A.68.68,0,0,1,15.34,16.61Z" />
          <path id="Path_3" data-name="Path 3" style={{ fill: '#b7b7b7' }} d="M13,15a.72.72,0,0,1-.49-.19.7.7,0,0,1-.22-.49.68.68,0,0,1,.2-.52,2.12,2.12,0,0,0,0-2.91.68.68,0,0,1-.2-.52.7.7,0,0,1,.22-.49A.68.68,0,0,1,13,9.65a.7.7,0,0,1,.5.22,3.54,3.54,0,0,1,0,4.87A.7.7,0,0,1,13,15Z" />
        </g>
      </g>
    </g>
  </svg>
);

const IconFileTypeAudio = {
  Small: IconFileSmall,
  Medium: IconFileMedium,
  Large: IconFileLarge,
  xLarge: IconFileXLarge,
};

export default IconFileTypeAudio;
