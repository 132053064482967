import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Card } from '../../../../bphComponents/bundle';
import DateUtil from '../../../../utilities/DateUtil';
import RouteNames from '../../../appAuthorized/RouteNames';

import { IconClock, IconPlay } from '../../../components/icons/SvgIcons';
import PreformattedText from '../../../components/PreformattedText';
import GroupSurveySummaryDates from './GroupSurveySummaryDates';
import { ProgressIndicator } from './ProgressIndicator';

import { useNavigate } from '../../../hooks/useNavigate';

export const CompletedSurvey = ({ groupId, survey, definition  }) => {
  const [, navActions] = useNavigate();

  return (
    <Card.CardShape onClick={() => navActions.goTo(RouteNames.surveyComplete(groupId, survey.id))}>
      <Card.CardContent>
        <div className="font-title-3 theme-group highlight color">
          {definition.name}
        </div>

        <div className="font-caption font-color-gsMedDark" style={{ marginTop: 12 }}>
          <FormattedMessage id="ui-data.surveys.completedDate.text" values={{ string1: DateUtil.formattedMediumDate(survey.submitted_at) }} />
        </div>
      </Card.CardContent>
    </Card.CardShape>
  );
};

export const NewSurvey = ({ groupId, survey, definition }) => {
  const [, navActions] = useNavigate();

  return (
    <Card.CardShape onClick={() => navActions.goTo(RouteNames.surveyIntro(groupId, survey.id))}>
      <Card.CardContent hasBottomBorder>
        <div className="font-title-3 theme-group highlight color" style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 14, alignSelf: 'flex-start' }}>
            <IconPlay
              fillClassName="theme-group base fill"
              borderClassName="theme-group base border"
            />
          </div>

          {definition.name}
        </div>
      </Card.CardContent>
      <Card.CardContent>
        <PreformattedText className="font-caption font-color-gsMedDark">
          {definition.short_description}
        </PreformattedText>

        <div className="font-subhead font-color-gsMedDark" style={{ margin: '18px 0 18px 0', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 14 }}>
            <IconClock strokeClassName="stroke-color-gsMedDark"/>
          </div>

          <FormattedMessage id="ui-data.surveys.estimatedTime.text" values={{ string1: definition.estimated_completion_mins }}/>
        </div>

        <GroupSurveySummaryDates
          definition={definition}
          survey={survey}
          showEndDate={true}
          showDelivered={true}
        />
      </Card.CardContent>
    </Card.CardShape>
  );
};

export const InProgressSurvey = ({ groupId, survey, definition  }) => {
  const [, navActions] = useNavigate();

  if (!definition) { return null; }

  return (
    <Card.CardShape onClick={() => navActions.goTo(RouteNames.surveyFirstQuestion(groupId, survey.id))}>
      <Card.CardContent hasBottomBorder>
        <div className="font-title-3 theme-group highlight color">
          {definition.name}
        </div>

        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 18 }}>
          <div style={{ marginRight: 14 }}>
            <IconPlay
              fillClassName="theme-group base fill"
              borderClassName="theme-group base border"
            />
          </div>

          <div style={{ flex: 1 }}>
            <ProgressIndicator percentage={survey.completion_percentage}/>
          </div>
        </div>

        <GroupSurveySummaryDates
          definition={definition}
          survey={survey}
          showEndDate={true}
          showDelivered={false}
        />
      </Card.CardContent>

      <Card.CardContent>
        <div className="font-footnote font-color-gsMedDark">
          <FormattedMessage id="ui-data.surveys.lastUpdatedDate.text" values={{ string1: DateUtil.formattedMediumDate(survey.updated_at) }} />
        </div>
      </Card.CardContent>
    </Card.CardShape>
  );
};
