import React from 'react';

import ShareCardQuicklinks from './components/ShareCardQuicklinks';
import { TrackableShortcuts } from './components/TrackableShortcuts';
import HealthEntriesList from '../../components/healthEntriesList/HealthEntriesList';

import OtherHealthEntryReminder from '../../components/OtherHealthEntryReminder';

import { TrackEvent } from '../../../utilities/UserMetrics';
import RouteNames from '../../appAuthorized/RouteNames';
import { useHealthEntriesForActiveProfile } from '../../hooks/useHealthEntry';
import { useUIState } from '../../hooks/useUIState';
import { useNavigate } from '../../hooks/useNavigate';
import { useActiveUser } from '../../hooks/useUser';

const BackpackHealthEntries: React.FC = () => {
  const [, uiStateActions] = useUIState();
  const [, navActions] = useNavigate();
  const [{ groupedHealthEntryList, healthEntryList, isLoaded }] = useHealthEntriesForActiveProfile();
  const healthEntriesCount = healthEntryList ? healthEntryList.length : 0;
  const [{ preferredLanguageCode }] = useActiveUser();
  const healthEntries = groupedHealthEntryList ? groupedHealthEntryList :
  {
    allergy: { all: [], older: [], newer: [] },
    condition: { all: [], older: [], newer: [] },
    medication: { all: [], older: [], newer: [] },
    other_treatment: { all: [], older: [], newer: [] },
    procedure: { all: [], older: [], newer: [] },
    vaccination: { all: [], older: [], newer: [] },
    lab: { all: [], older: [], newer: [] },
    measurement: { all: [], older: [], newer: [] },
    genetic_result: { all: [], older: [], newer: [] },
  };

  React.useEffect(() => {
    if (!isLoaded) {
      uiStateActions.setStartLoading();
    } else {
      uiStateActions.setStopLoading();
    }
  }, [isLoaded]);

  React.useEffect(() => {
    TrackEvent.backpackProfile.viewedBackpack();
  }, []);

  if (!isLoaded) { return null; }

  return (
    <div>
      <ShareCardQuicklinks />
      <OtherHealthEntryReminder />
      <TrackableShortcuts />
      <HealthEntriesList
        languageCode={preferredLanguageCode}
        healthEntries={healthEntries}
        healthEntriesCount={healthEntriesCount}
        goToHealthEntry={(entry) => {
          navActions.goTo(RouteNames.healthEntry(entry.id));
        }}
      />
    </div>
  );
};

export default BackpackHealthEntries;
