import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { BackpackThemeMarkdown } from './Markdown';
import { GrayCard } from './card/Card';

interface Props {
  headingId: string;
  className: string;
  linkClassName: string;
  baseTipId: string;
  countId: string;
  count: number;
  intl?: any;
}

interface State {
  tipId: string;
}

class RandomizedTips extends Component<Props, State> {
  UNSAFE_componentWillMount() {
    const { countId, count, baseTipId, intl } = this.props;

    if (countId ? count : !count) { // xor
      throw new Error('Either countId or count prop should be specified');
    }

    const tipsCount = count || Number.parseInt(intl.formatMessage({ id: countId }));
    const tipIndex = Math.floor(Math.random() * tipsCount + 1);
    const tipId = `${baseTipId}${tipIndex}`;

    this.setState({
      tipId,
    });
  }

  render() {
    const { className, linkClassName, headingId } = this.props;
    const { tipId } = this.state;

    return (
      <GrayCard className={className}>
        <div className="cmp-randomized-tips-heading">
          <FormattedMessage id={headingId} />
        </div>
        <BackpackThemeMarkdown phraseId={tipId} linkClassName={linkClassName} />
      </GrayCard>
    );
  }
}

export default injectIntl(RandomizedTips);
