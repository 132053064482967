import React from 'react';
import { FormattedMessage } from 'react-intl';

import { RoundedButton } from '../../../components/buttons/Buttons';
import HealthEntryValueSummary from '../../../components/HealthEntryValueSummary';
import { Card, Buttons } from '../../../../bphComponents/bundle';
import COLORS from '../../../styles/Colors';
import HEValueSeriesGraph from './HEValueSeriesGraph';
import { HealthEntry } from '../../../../models/HealthEntry';
import HealthEntryCategoryNames from '../../../../constants/HealthEntryCategoryNames';

const LIST_DISPLAY_LIMIT = 15;

const iconProps = {
  color: 'middle-gray',
  size: '76',
};

interface Props {
  EmptyIcon: any;
  healthEntry: HealthEntry;
  onClickAddValue(): void;
  onClickItem(d): void;
  languageCode: string;
}

const HEValueItem = (props) => {
  const { item, onClick, languageCode } = props;

  return (
    <Card.CardContent hasBottomBorder={true} onClick={() => { onClick(item); }}>
      <HealthEntryValueSummary
        languageCode={languageCode}
        heValueId={item.id}
        color="gsDarkest"
        includeName={false}
        areIconsAttachedToRight={true}
      />
    </Card.CardContent>
  );
};

const ToggleRemainingValues = (props) => {
  const { isExpanded, onClick } = props;

  return (
    <Card.CardContent hasBottomBorder={true}>
      <Card.CollapsibleControl isExpanded={isExpanded} onClick={onClick}>
        <div className="font-color-profile-accent">
          {
            isExpanded
            ? <FormattedMessage id="healthentry.hideAllList.button.label" />
            : <FormattedMessage id="healthentry.viewAllList.button.label" />
          }
        </div>
      </Card.CollapsibleControl>
    </Card.CardContent>
  );
};

const HEValueSeries: React.FC<Props> = (props) => {
  const { healthEntry, onClickAddValue, EmptyIcon, onClickItem, languageCode } = props;
  const category = healthEntry.category;
  if (!healthEntry || !healthEntry[category] || category === HealthEntryCategoryNames.missing) { return null; }
  const values = healthEntry[category].values || [];
  const [isExpanded, setIsExpanded] = React.useState(false);

  let topValues = [];
  let remainingValues = [];

  if (values.length > LIST_DISPLAY_LIMIT) {
    topValues = values.slice(0, 10);
    remainingValues = values.slice(10);
  } else {
    topValues = values;
  }

  if (values.length === 0) {
    return (
      <div className="util-application-frame-forcards-padding" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', height: '100%', marginTop: 18, marginBottom: 42 }}>
        <div style={{ marginBottom: 56, marginLeft: 'auto', marginRight: 'auto' }}>
          <EmptyIcon {...iconProps} />
        </div>
        <RoundedButton className="font-title-3 border-color-gsMedLightDT" type="primary" action="profile" onClick={onClickAddValue} style={{ borderWidth: 1, borderStyle: 'solid' }}>
          <FormattedMessage id={'heSummaryList.addValue.button.label'} />
        </RoundedButton>
        <div className="font-subhead font-color-gsDark" style={{ textAlign: 'center', marginTop: 56 }}>
          <FormattedMessage id={'heSummaryList.addValue.message.text'} />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Card.ExternalContent>
          <Buttons.InlineAddButton
            onClick={onClickAddValue}
            className="stroke-color-profile"
            color=""
          >
            <span className="font-footnote font-color-profile">
              <FormattedMessage id="heSummaryList.addNewValue.button.label" />
            </span>
          </Buttons.InlineAddButton>
        </Card.ExternalContent>

        <HEValueSeriesGraph category={category} healthEntry={healthEntry} values={values} />

        <Card.CardShape>
          {
            topValues.map((item, index) => (
              <HEValueItem
                key={index}
                item={item}
                languageCode={languageCode}
                onClick={onClickItem}
              />
            ))
          }

          {
            remainingValues.length > 0
            ? (
              <>
                <ToggleRemainingValues isExpanded={isExpanded} onClick={() => { setIsExpanded(!isExpanded); }} />

                <Card.CollapsibleContent isExpanded={isExpanded} background={COLORS.gsLightest}>
                  {
                    remainingValues.map((item, index) => (
                      <HEValueItem
                        key={index}
                        item={item}
                        languageCode={languageCode}
                        onClick={onClickItem}
                      />
                    ))
                  }
                </Card.CollapsibleContent>
              </>
            )
            : null
          }
        </Card.CardShape>
      </>
    );
  }
};

export default HEValueSeries;
