import React from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import Graphs from '@backpackhealth/graphs';

import { Card } from '../../../../bphComponents/bundle';
import { useMedliUnits } from '../../../hooks/useMedli';
import DateUtil from '../../../../utilities/DateUtil';
import HealthEntryCategoryNames from '../../../../constants/HealthEntryCategoryNames';
import HealthEntryModel, { HealthEntry } from '../../../../models/HealthEntry';
import { isRange } from '../../../../utilities/Validators';

function supportsGraphs(category: HealthEntryCategoryNames) {
  switch (category) {
    case HealthEntryCategoryNames.lab:
    case HealthEntryCategoryNames.measurement:
      return true;
    default:
      return false;
  }
}

function cuiSupportsGraphs(healthEntry) {
  const cui = HealthEntryModel.getCUI(healthEntry);
  switch (cui) {
    case 'C0005823':
    case 'C1271104':
    case 'C1272641':
      return false;
    default:
      return true;
  }
}

function hasInvalidValues(values) {
  if (values.length === 0) { return true; }
  const firstValue = values[0];

  const unit = _.get(firstValue, 'amount.term.cui', '');
  if (!unit) { return true; }

  for (let i = 0; i < values.length; i++) {
    const value = values[i];

    if (!value.date || !value.amount) { return true; }

    if (!value.date.date) { return true; }
    if (!value.amount.value) { return true; }
    if (value.amount.term.cui !== unit) { return true; }
    if (value.amount.value.indexOf('<') > -1 || value.amount.value.indexOf('>') > -1) { return true; }
    if (isRange(value.amount.value)) { return true; }
  }

  return false;
}
interface Props {
  healthEntry: HealthEntry;
  values: any[];
  category: HealthEntryCategoryNames;
  intl: any;
}
const HEValueSeriesGraph: React.FC<Props> = (props) => {
  const { category, values, healthEntry, intl } = props;
  const { locale } = intl;
  const graphRef = React.useRef(null);

  const [{ naUnitList, compositeUnitList }] = useMedliUnits();
  const [dimensions, setDimensions] = React.useState({ height: 300, width: 700 });

  React.useEffect(() => {
    const handleResize = () => {
      if (graphRef.current) {
        setDimensions({
          height: 300,
          width: graphRef.current.offsetWidth,
        });
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!supportsGraphs(category) || values.length === 0 || !cuiSupportsGraphs(healthEntry) || hasInvalidValues(values)) {
    return null;
  }

  return (
    <Card.CardShape>
      <Card.CardContent>
        <div ref={graphRef}>
          <Graphs.MultiOccurrenceGraph
            width={dimensions.width}
            healthEntryValues={values}
            locale={locale}
            generalErrorMessage={intl.formatMessage({ id: 'error.heGraph.unexpected-error.message' })}
            naUnits={naUnitList}
            compositeUnits={compositeUnitList}
            p1dFormatter={(d) => { return DateUtil.formattedMediumDate(d, 'P1D', locale); }}
            p1mFormatter={(d) => { return DateUtil.formattedMediumDate(d, 'P1M', locale); }}
            p1yFormatter={(d) => { return DateUtil.formattedMediumDate(d, 'P1Y', locale); }}
          />
        </div>
      </Card.CardContent>
    </Card.CardShape>
  );
};

export default injectIntl(HEValueSeriesGraph);
