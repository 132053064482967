import React from 'react';

interface Props {
  color?: string;
  colorClassName?: string;
}

const IconFileSmall = (props: Props) => (
  <svg height="16px" width="16px" viewBox="0 0 16 16">
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeMiterlimit: 10, strokeWidth: 1.25 }}
      d="M11.49,9.75,7.84,13.41a3.33,3.33,0,0,1-4.69,0h0a3.33,3.33,0,0,1,0-4.69l6.6-6.6a2.38,2.38,0,0,1,3.35,0h0a2.38,2.38,0,0,1,0,3.35L6.51,12.06a1.43,1.43,0,0,1-2,0h0a1.43,1.43,0,0,1,0-2L8.13,6.42"
    />
  </svg>
);

const IconFileMedium = (props: Props) => (
  <svg height="24px" width="24px" viewBox="0 0 24 24">
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeMiterlimit: 10, strokeWidth: 1.5 }}
      d="M17.23,14.63l-5.48,5.48a5,5,0,0,1-7,0h0a5,5,0,0,1,0-7l9.9-9.9a3.53,3.53,0,0,1,5,0h0a3.54,3.54,0,0,1,0,5L9.77,18.09a2.14,2.14,0,0,1-3,0h0a2.14,2.14,0,0,1,0-3L12.2,9.63"
    />
  </svg>
);

const IconFileLarge = (props: Props) => (
  <svg height="36px" width="36px" viewBox="0 0 36 36">
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeMiterlimit: 10, strokeWidth: 2 }}
      d="M25.85,22l-8.22,8.22a7.45,7.45,0,0,1-10.54,0h0a7.45,7.45,0,0,1,0-10.54L21.94,4.78a5.32,5.32,0,0,1,7.53,0h0a5.32,5.32,0,0,1,0,7.53L14.65,27.13a3.19,3.19,0,0,1-4.52,0h0a3.19,3.19,0,0,1,0-4.52l8.16-8.16"
    />
  </svg>
);

const IconFileXLarge = (props: Props) => (
  <svg height="56px" width="56px" viewBox="0 0 56 56">
    <path
      stroke={props.color}
      className={props.colorClassName && `stroke-color-${props.colorClassName}`}
      style={{ fill: 'none', strokeMiterlimit: 10, strokeWidth: 2.75 }}
      d="M40.21,34.14,27.43,46.93a11.59,11.59,0,0,1-16.4,0h0a11.59,11.59,0,0,1,0-16.4L34.13,7.44a8.27,8.27,0,0,1,11.71,0h0a8.29,8.29,0,0,1,0,11.71L22.79,42.2a5,5,0,0,1-7,0h0a5,5,0,0,1,0-7l12.7-12.69"
    />
  </svg>
);

const IconFile = {
  Small: IconFileSmall,
  Medium: IconFileMedium,
  Large: IconFileLarge,
  xLarge: IconFileXLarge,
};

export default IconFile;
