import React from 'react';

const IconFileSmall = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 16 16">
    <g id="icon-filetype-video">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="Group">
        <path id="Path" style={{ fill: '#b7b7b7' }} d="M12,8.67A1.33,1.33,0,0,1,13.33,10v4A1.33,1.33,0,0,1,12,15.33H8A1.33,1.33,0,0,1,6.67,14V10A1.33,1.33,0,0,1,8,8.67Z" />
        <path id="Path_2" data-name="Path 2" style={{ fill: '#b7b7b7' }} d="M17.12,8.69a.3.3,0,0,1,.21.31v6a.3.3,0,0,1-.21.31.3.3,0,0,1-.12,0,.32.32,0,0,1-.24-.09L14,12.47v-.94l2.76-2.77A.32.32,0,0,1,17,8.67.3.3,0,0,1,17.12,8.69Z" />
      </g>
    </g>
  </svg>
);

const IconFileMedium = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 24 24">
    <g id="icon-filetype-video">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="Group">
        <path id="Path" style={{ fill: '#b7b7b7' }} d="M12,8.67A1.33,1.33,0,0,1,13.33,10v4A1.33,1.33,0,0,1,12,15.33H8A1.33,1.33,0,0,1,6.67,14V10A1.33,1.33,0,0,1,8,8.67Z" />
        <path id="Path_2" data-name="Path 2" style={{ fill: '#b7b7b7' }} d="M17.12,8.69a.3.3,0,0,1,.21.31v6a.3.3,0,0,1-.21.31.3.3,0,0,1-.12,0,.32.32,0,0,1-.24-.09L14,12.47v-.94l2.76-2.77A.32.32,0,0,1,17,8.67.3.3,0,0,1,17.12,8.69Z" />
      </g>
    </g>
  </svg>
);

const IconFileLarge = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="36px" width="36px" viewBox="0 0 36 36">
    <g id="icon-filetype-video">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="Group">
        <path id="Path" style={{ fill: '#b7b7b7' }} d="M12,8.67A1.33,1.33,0,0,1,13.33,10v4A1.33,1.33,0,0,1,12,15.33H8A1.33,1.33,0,0,1,6.67,14V10A1.33,1.33,0,0,1,8,8.67Z" />
        <path id="Path_2" data-name="Path 2" style={{ fill: '#b7b7b7' }} d="M17.12,8.69a.3.3,0,0,1,.21.31v6a.3.3,0,0,1-.21.31.3.3,0,0,1-.12,0,.32.32,0,0,1-.24-.09L14,12.47v-.94l2.76-2.77A.32.32,0,0,1,17,8.67.3.3,0,0,1,17.12,8.69Z" />
      </g>
    </g>
  </svg>
);

const IconFileXLarge = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="56px" width="56px" viewBox="0 0 56 56">
    <g id="icon-filetype-video">
      <path style={{ fill: '#b7b7b7' }} d="M21.33,1A1.67,1.67,0,0,1,23,2.67V21.33A1.67,1.67,0,0,1,21.33,23H2.67A1.67,1.67,0,0,1,1,21.33V2.67A1.67,1.67,0,0,1,2.67,1H21.33m0-1H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V2.67A2.66,2.66,0,0,0,21.33,0Z" />
      <g id="Group">
        <path id="Path" style={{ fill: '#b7b7b7' }} d="M12,8.67A1.33,1.33,0,0,1,13.33,10v4A1.33,1.33,0,0,1,12,15.33H8A1.33,1.33,0,0,1,6.67,14V10A1.33,1.33,0,0,1,8,8.67Z" />
        <path id="Path_2" data-name="Path 2" style={{ fill: '#b7b7b7' }} d="M17.12,8.69a.3.3,0,0,1,.21.31v6a.3.3,0,0,1-.21.31.3.3,0,0,1-.12,0,.32.32,0,0,1-.24-.09L14,12.47v-.94l2.76-2.77A.32.32,0,0,1,17,8.67.3.3,0,0,1,17.12,8.69Z" />
      </g>
    </g>
  </svg>
);

const IconFileTypeVideo = {
  Small: IconFileSmall,
  Medium: IconFileMedium,
  Large: IconFileLarge,
  xLarge: IconFileXLarge,
};

export default IconFileTypeVideo;
