import React from 'react';
import classnames from 'classnames';

import './ContentWrapper.styles.scss';

interface Props {
  children: any;
  style?: any;
  isFlatContent?: boolean;
  isFullWidth?: boolean;
}

const ContentWrapper = (props: Props) => (
  <div className={classnames('cmp-content-wrapper', { '__has-elevatedcontent': !props.isFlatContent, '__max-width': !props.isFullWidth })} style={props.style}>
    {props.children}
  </div>
);

export const ContentWrapperNoTopMargin = (props: Props) => (
  <div className={classnames('cmp-content-wrapper', { '__has-elevatedcontent': !props.isFlatContent, '__max-width': !props.isFullWidth })} style={{ marginTop: 0, ...props.style }}>
    {props.children}
  </div>
);

export default ContentWrapper;
