import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import HEAttributeFooter from './components/HEAttributeFooter';

import HealthEntryGeneAttributes from '../../../constants/HealthEntryGeneAttributes';
import ContentFrame from '../../applicationFrame/ContentFrame';
import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentLoader from '../../components/ContentLoader';
import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import ContentWrapper from '../../components/ContentWrapper';
import InputWithLabel from '../../components/InputWithLabel';
import Spacer from '../../components/Spacer';
import { Card } from '../../../bphComponents/bundle';
import HealthEntryGeneHelpText from './components/HealthEntryGeneHelpText';
import { useHealthEntryAndValueForActiveProfile } from '../../hooks/useHealthEntry';
import { useNavigate } from '../../hooks/useNavigate';

import RouteNames from '../../appAuthorized/RouteNames';

const isPartOfDefaultValue = (defaultValue, value) => {
  return defaultValue.includes(value);
};

const SCHEMA = HealthEntryGeneAttributes.find(g => g.name === 'dnaChange');
const valueGetterKey = 'getDNAChange';
const defaultTextId = 'healthEntry.geneDNAChange.field.defaultText';
const valueSetterKey = 'setDNAChange';
const titleId = 'healthEntry.geneDNAChange.title.text';
const helpBlockHeadingId = 'healthEntry.geneDNAChange.helpBlock.heading';
const helpBlockBodyId = 'healthEntry.geneDNAChange.helpBlock.body';
const fieldLabelId = 'healthEntry.geneDNAChange.field.label';
const fieldHintId = 'healthEntry.geneDNAChange.field.hint';

interface Props {
  match: any;
  intl: any;
}
const HealthEntryGeneDNAChange: React.FC<Props> = (props) => {
  const healthEntryId = Number(props.match.params.healthEntryId);
  const healthEntryValueId = Number(props.match.params.valueId);
  const defaultValue = props.intl.formatMessage({ id: defaultTextId });

  const [hevState, hevActions] = useHealthEntryAndValueForActiveProfile(healthEntryId, healthEntryValueId);
  const [, navActions] = useNavigate();

  const [hasChanges, setHasChanges] = React.useState(false);
  const [heValueState, setHeValueState] = React.useState({});
  const [isDeletable, setIsDeletable] = React.useState(false);

  const pendingHevInstance = hevActions.instantiateHealthEntryValue(heValueState);
  const value = pendingHevInstance.geneVariantResult[valueGetterKey]();
  const isLoaded = hevState.isLoaded;

  const updateValueForSubmit = (newValue: string) => {
    const newHev = hevActions.instantiateHealthEntryValue(heValueState);

    if (value === newValue) return;

    newHev.geneVariantResult[valueSetterKey](newValue);
    setHeValueState(newHev.data());
    setHasChanges(true);
  };

  React.useEffect(() => {
    if (!isLoaded) { return; }

    const sourceHevInstance = hevActions.instantiateHealthEntryValue();
    setHeValueState(hevState.healthEntryValue);

    setIsDeletable(
      !!(sourceHevInstance.geneVariantResult && (sourceHevInstance.geneVariantResult[valueGetterKey]()))
    );
  }, [isLoaded]);

  return (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          cancel={() => {
            navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
          }}
          submit={() => {
            if (!hasChanges) {
              navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
              return;
            }
            hevActions.apiUpdate(heValueState)
              .then(() => {
                navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
                hevActions.resetEditing();
              });
          }}
          color="white"
        />

        <ContentLoader isContentLoaded={isLoaded} blockRenderUntilLoaded>
          <ContentFrame>
            <ScrollAwareScroller>

              <div>
                <AppHeaderScrollableText
                  id={titleId}
                />

                <ContentWrapper>
                  <HealthEntryGeneHelpText
                    heading={helpBlockHeadingId}
                    content={helpBlockBodyId}
                  />

                  <Card.CardShape>
                    <Card.CardContent hasBorder={false}>
                      <div className="font-color-gsDark font-caption-1" style={{ marginTop: 3, marginBottom: 7 }}>
                        <FormattedMessage id={fieldLabelId} />
                      </div>
                      <InputWithLabel
                        regularHint={(
                          <div className="font-color-gsMedDark font-footnote">
                            <FormattedMessage id={fieldHintId} />
                          </div>
                        )}
                        value={value || defaultValue}
                        noForcedLabel
                        noForcedHeight
                        onChange={(e) => {
                          const v = e.target.value || null;

                          if (isPartOfDefaultValue(defaultValue, v)) {
                            // change only local value but and set the value for submit as null
                            updateValueForSubmit(null);
                          } else {
                            updateValueForSubmit(v);
                          }
                        }}
                      />
                      <Spacer size="1" />
                    </Card.CardContent>
                  </Card.CardShape>

                  {
                    isDeletable  && (
                      <HEAttributeFooter
                        isFlagged={null}
                        onDelete={() => {
                          const attributeNames = SCHEMA.values.map(v => v.geneVariantResultKey);
                          const newHevInstance = hevActions.instantiateEditingHealthEntryValue();
                          newHevInstance.geneVariantResult.clearAttributeValues(attributeNames);
                          hevActions.apiUpdate(newHevInstance.data())
                            .then(() => {
                              navActions.goBack(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
                            });
                        }}
                        onToggleFlag={null}
                      />
                    )
                  }
                </ContentWrapper>
              </div>
            </ScrollAwareScroller>
          </ContentFrame>
        </ContentLoader>
      </ScrollAwareContext>
    </div>
  );
};

export default injectIntl(HealthEntryGeneDNAChange);
