import { useQuery } from 'react-query';

import { services } from '../../apiServices/services';
import { eUserType } from '../../apiServices/SurveyService';
import { useActiveUser } from './useUser';
import { useUIState } from './useUIState';
import { useProfile, useActiveProfile } from './useProfile';

const STALE_TIME = 1000;
const INITIAL_SURVEY_AND_DEF_STATE = {
  survey: {},
  definition: {},
  isLoaded: false,
};
const INITIAL_GROUP_SURVEY_DEF_STATE = {
  list: [],
  lookup: {},
  isLoaded: false,
};
function fetchSurvey(queryKey, profileId, surveyId) {
  return new Promise((res, rej) => {
    services.surveys.getSurvey(profileId, surveyId)
      .then((survey) => {
        services.surveys.getDefinition(profileId, survey.survey_definition_id)
        .then((definition) => {
          res({ survey, definition, isLoaded: true });
        });
      });
  });
}

function fetchGroupSurveyDefinition(queryKey, profileId, groupId, languageCode, userType) {
  return new Promise((res, rej) => {
    services.surveys.getDefinitions(profileId, groupId, languageCode, userType)
      .then((list) => {
        res({
          isLoaded: true,
          list,
          lookup: list.reduce((prev, cur) => {
            return {
              ...prev,
              [cur.id]: cur,
            };
          }, {}),
        });
      });
  });
}

const INITIAL_SURVEY_QUESTION_DEF_STATE = {
  data: { sections: [] },
  isLoaded: true,
};
function fetchSurveyDefinitionQuestions(queryKey, profileId, surveyDefId) {
  return new Promise((res, rej) => {
    services.surveys.getDefinitionQuestions(profileId, surveyDefId).then((s) => {
      res({
        data: s,
        isLoaded: true,
      });
    });
  });
}

const INITIAL_SURVEY_LIST_STATE = {
  list: [],
  surveysByGroup: {},
  isLoaded: true,
};

function fetchSurveys(queryKey, profileId) {
  return new Promise((res, rej) => {
    services.surveys.getSurveys(profileId)
      .then((surveys) => {
        res({
          isLoaded: true,
          list: surveys,
          surveysByGroup: surveys.reduce((acc, s) => {
            const groupSurveys = acc[s.group_id] || [];
            groupSurveys.push(s);
            return {
              ...acc,
              [s.group_id]: groupSurveys,
            };
          }, {}),
        });
      });
  });
}

export function useSurveyForProfile(profileId, surveyId) {
  const queryData: any = useQuery(['surveyAndDefinition', profileId, surveyId], fetchSurvey, { enabled: Boolean(profileId) && Boolean(surveyId), staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_SURVEY_AND_DEF_STATE;

  return [
    {
      survey: responseData.survey,
      definition: responseData.definition,
      isLoaded: responseData.isLoaded,
    }, {
      markSubmitted: () => {
        return services.surveys.markSurveySubmitted(profileId, surveyId);
      },
    }];
}

export function useSurveyForActiveProfile(surveyId) {
  const [{ activeProfileId }] = useUIState();
  return useSurveyForProfile(activeProfileId, surveyId);
}


export function useSurveyDefinitionsForProfileGroup(profileId, groupId, isDependent, languageCode) {
  const userType = isDependent ? eUserType.Dependent : eUserType.Primary;
  const queryData: any = useQuery(['definitions', profileId, groupId, languageCode, userType], fetchGroupSurveyDefinition, { staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_GROUP_SURVEY_DEF_STATE;

  return [
    {
      definitions: responseData.list,
      definitionsLookup: responseData.lookup,
      isLoaded: responseData.isLoaded,
    },
    {},
  ];
}

export function useSurveyDefinitionsForActiveProfileGroup(groupId) {
  const [{ profileId }, profileActions] = useActiveProfile();
  const profile = profileActions.instantiateProfile();
  const [{ preferredLanguageCode }] = useActiveUser();
  return useSurveyDefinitionsForProfileGroup(profileId, groupId, profile.isDependent(), preferredLanguageCode);
}

export function useSurveysForProfileGroup(profileId, groupId) {
  const [, profileActions] = useProfile(profileId);
  const profile = profileActions.instantiateProfile();
  const [{ preferredLanguageCode }] = useActiveUser();

  const [{ definitions, definitionsLookup, isLoaded }] = useSurveyDefinitionsForProfileGroup(profileId, groupId, profile.isDependent(), preferredLanguageCode);

  const queryData: any = useQuery(['surveys', profileId], fetchSurveys, { staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_SURVEY_LIST_STATE;
  const surveyList = responseData.list;
  const groupSurveyList = responseData.surveysByGroup[groupId] || [];

  return [
    {
      surveyList,
      groupSurveyList,
      definitions,
      definitionsLookup,
      isLoaded: responseData.isLoaded && isLoaded,
    },
    {
      getGroupSurveysByStatus: () => {
        const completed = [];
        const inProgress = [];
        groupSurveyList.forEach(survey => {
          if (!definitionsLookup[survey.survey_definition_id]) return;

          if (survey.submitted) {
            completed.push(survey);
          } else {
            inProgress.push(survey);
          }
        });

        completed.sort((a, b) => new Date(b.submitted_at).getTime() - new Date(a.submitted_at).getTime());
        inProgress.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

        return {
          inProgress,
          completed,
        };
      },
      requestSurveys: () => {
        return services.surveys.getSurveys(profileId);
      },
    },
  ];
}

export function useSurveysForActiveProfileGroup(groupId) {
  const [{ activeProfileId }] = useUIState();
  return useSurveysForProfileGroup(activeProfileId, groupId);
}

export function useSurveyDefinitionQuestions(profileId, surveyDefId) {
  const queryData: any = useQuery(['surveyDefQuestions', profileId, surveyDefId], fetchSurveyDefinitionQuestions, { enabled: Boolean(profileId) && Boolean(surveyDefId), staleTime: STALE_TIME });
  const responseData = queryData.data || INITIAL_SURVEY_QUESTION_DEF_STATE;

  return [
    {
      sections: responseData.data.sections,
      isLoaded: responseData.isLoaded,
    },
  ];
}

export function useSurveyDefinitionQuestionsForActiveProfile(surveyDefId) {
  const [{ activeProfileId }] = useUIState();
  return useSurveyDefinitionQuestions(activeProfileId, surveyDefId);
}
