
import React from 'react';

import RouteNames from '../../../appAuthorized/RouteNames';
import ContactModel from '../../../../models/ContactModel';

import ViewContactSelect from '../components/ViewContactSelect';
import { useNavigate } from '../../../hooks/useNavigate';
import { useContactsForAllProfiles, useContactForActiveProfile } from '../../../hooks/useContacts';
import { useUIState } from '../../../hooks/useUIState';

const ViewContactAdd: React.FC = () => {
  const [{ activeProfileId }] = useUIState();
  const [, navActions] = useNavigate();
  const [{ contactListByProfile, isLoaded }] = useContactsForAllProfiles();
  const [, contactActions] = useContactForActiveProfile();

  return (
    <ViewContactSelect
      contactListByProfile={{
        ...contactListByProfile,
        [activeProfileId]: [],
      }}
      isLoaded={isLoaded}
      noContactRoute={RouteNames.contactEditNew()}
      titlePhrase={{
        id: 'contactsAdd.title',
      }}
      isAttaching={false}
      onSubmit={(selectedContacts) => {
        const creationPromises = Object.keys(selectedContacts).map(k => {
          const newContact = ContactModel.newCopyForProfile(selectedContacts[k], activeProfileId);
          return contactActions.apiCreateContact(newContact, null, null);
        });

        return Promise.all(creationPromises).then(() => {
          navActions.goBackTo(RouteNames.contactList());
        });
      }}
      onCreate={() => {
        navActions.goTo(RouteNames.contactEditNew());
      }}
    />
  );
};

export default ViewContactAdd;
