/* global window */

import React from 'react';
import { Redirect } from 'react-router';

declare global {
  interface Window {
    backpackRoute: string;
  }
}

interface Props {
  children: any;
}

const RedirectToBackpackRoute = (props: Props) => {
  if (window.backpackRoute) {
    const route = window.backpackRoute;
    window.backpackRoute = null;
    return <Redirect to={route} />;
  }

  return props.children;
};

export default RedirectToBackpackRoute;
