import React from 'react';
import { FormattedMessage } from 'react-intl';

import ContentPanel from '../ContentPanel';
import HEValueFormatter from '../HEValueFormatter';

import healthEntryUtility from '../../../../../models/HealthEntryUtility';
import { generalHealthEntry } from '../../../../../models/HealthEntryCompose';
import { getIconFromHealthEntryCategory } from '../../../../components/icons/HealthEntryIcons';
import HealthEntryCategoryNames from '../../../../../constants/HealthEntryCategoryNames';
import MedliTermText from './MedliTermText';

import GroupTitle from './GroupTitle';
import { HealthEntryData } from '../../../../../models/HealthEntry';

import './ShareCardHealthEntryValueGroup.scss';
import COLORS from '../../../../styles/Colors';
import { Icons } from '../../../../../bphComponents/bundle';
import { iObservationDate } from '../../../../../apiServices/apiModels/Trackable';
import ShareCardIncludedTypeNames from '../../../../../constants/ShareCardIncludedTypeNames';
import { ClampedText } from '../../../../components/ClampedText';

const titles = {
  [HealthEntryCategoryNames.allergy]: 'list.healthEntry.allergies.name',
  [HealthEntryCategoryNames.condition]: 'list.healthEntry.conditions.name',
  [HealthEntryCategoryNames.medication]: 'list.healthEntry.medications.name',
  [HealthEntryCategoryNames.otherTreatment]: 'list.healthEntry.treatments.name',
  [HealthEntryCategoryNames.procedure]: 'list.healthEntry.procedures.name',
  [HealthEntryCategoryNames.vaccination]: 'list.healthEntry.vaccinations.name',
  [HealthEntryCategoryNames.lab]: 'list.healthEntry.lab.name',
  [HealthEntryCategoryNames.measurement]: 'list.healthEntry.measurements.name',
  [HealthEntryCategoryNames.geneticResult]: 'list.healthEntry.genes.name',
  [HealthEntryCategoryNames.geneVariantResult]: 'list.healthEntry.geneticVariantResults.name',
  tracking: 'list.healthEntry.trackables.name',
};

function categoryTitleForValues(category, includeType) {
  const phraseId = includeType === ShareCardIncludedTypeNames.all
    ? 'share.shareCardCategory.allValues.text'
    : 'share.shareCardCategory.mostRecentValue.text';

  return (
    <FormattedMessage id={titles[category]} values={{ digit1: 2 }}>
      {
        (string1) => (
          <FormattedMessage id={phraseId} values={{ string1 }} />
        )
      }
    </FormattedMessage>
  );
}

const commentRowStyle = {
  borderTop: `1px solid ${COLORS.gsLight}`,
};

interface ValueGroupProps {
  heValue: any;
  category: string;
  languageCode: string;
}
const HEValueRow: React.FC<ValueGroupProps> = (props) => {
  const { heValue, category, languageCode } = props;
  const hasComments = heValue.comments.length > 0;
  return (
    <>
      <tr className="font-subhead">
        <td style={{ paddingBottom: (hasComments ? 4 : 12), paddingRight: 16 }}>
          <strong>
            <HEValueFormatter.Value category={category} heValue={heValue} languageCode={languageCode} />
          </strong>
          <HEValueFormatter.AdditionalData category={category} heValue={heValue} languageCode={languageCode} />
        </td>
        <td align="left">
          <HEValueFormatter.HEValueDate category={category} heValue={heValue} languageCode={languageCode} />
        </td>
      </tr>
      {
        heValue.comments.map((c, i) => (
          <tr key={i} style={i > 0 ? commentRowStyle : null}>
            <td
              style={{
                paddingTop: i === 0 ? null : 8,
                paddingBottom: i === heValue.comments.length - 1 ? 12 : 8,
              }}
              colSpan={3}
              className="font-footnote font-color-gsDark"
            >
              {c.content}
            </td>
          </tr>
        ))
      }
    </>
  );
};

interface ValueGroupPanelProps {
  rows: any[];
  title: any;
  renderRow(d): any;
  tableStyle?: any;
  footerContent?: any;
}
const ValueGroupPanel: React.FC<ValueGroupPanelProps> = (props) => {
  const { rows, renderRow, title, tableStyle, footerContent } = props;

  if (rows.length === 0) { return null; }

  return (
    <ContentPanel
      hasPadding
      style={{ marginTop: 0 }}
      header={<div className="font-headline">{title}</div>}
    >
      <table className="cmp-sharecard-heValueGroup" style={tableStyle}>
        <tbody>
          {
            rows.map((value, index) => (
              <React.Fragment key={index}>
                {renderRow(value)}
              </React.Fragment>
            ))
          }
        </tbody>
      </table>
      {
        footerContent
      }
    </ContentPanel>
  );
};

interface ShareCardHEValueGroupProps {
  healthEntries: HealthEntryData[];
  category: string;
  languageCode: string;
}

const ShareCardHealthEntryValueGroup: React.FC<ShareCardHEValueGroupProps> = (props) => {
  const { healthEntries, category, languageCode } = props;
  if (!healthEntries || healthEntries.length === 0) { return null; }

  const hes = healthEntries.map(a => generalHealthEntry(a, languageCode));
  const sortedByName = healthEntryUtility.sort(hes, languageCode);

  return (
    <div>
      <GroupTitle Icon={getIconFromHealthEntryCategory(category)} text={categoryTitleForValues(category, ShareCardIncludedTypeNames.all)} />

      {
        sortedByName.map((heInstance, index) => {
          const he = heInstance.data();
          const heItem = he[category];

          if (heItem.values.length === 0) { return null; }

          const hasSingleAnatomy = heInstance.anatomy && heInstance.anatomy.hasAnatomy() && !heInstance.anatomy.allowsMany();

          return (
            <ValueGroupPanel
              key={index}
              title={(
                <>
                  <MedliTermText medliTerm={heItem.term} languageCode={languageCode} />
                  {hasSingleAnatomy && (
                    <span> - <MedliTermText medliTerm={heItem.anatomy} languageCode={languageCode} /></span>
                  )}
                </>
              )}
              rows={heItem.values}
              renderRow={(item) => {
                return (
                  <HEValueRow
                    heValue={item}
                    category={category}
                    languageCode={languageCode}
                  />
                );
              }}
            />
          );
        })
      }
    </div>
  );
};


interface ObservationRowProps {
  trackable: iSCMetadataTrackable;
  observation: any;
  terms: any;
  languageCode: string;
}
{/* <HEValueFormatter.Observation trackable={trackable} observation={observation} terms={terms} languageCode={languageCode} /> */}

const ObservationRow: React.FC<ObservationRowProps> = (props) => {
  const { observation, languageCode } = props;
  return (
    <tr key={languageCode} className="font-subhead" style={{ marginTop: 12 }}>
      <td style={{ paddingBottom: 12, maxWidth: '50%' }}>
        <ClampedText
          lines={1}
          moreMaskWidth={70}
          backgroundColor={COLORS.gsLightest}
          moreClassName="font-color-backpack-theme-accent"
          customMoreText={<span><FormattedMessage id="heValue.unit.search.label" /></span>}
        >
          <HEValueFormatter.Observation observation={observation} />
        </ClampedText>
      </td>
      <td className="__screen__hide-small" align="left" style={{ paddingLeft: 32, width: '25%', verticalAlign: 'top' }}>
        <HEValueFormatter.ObservationDate observation={observation} />
      </td>
      <td className="__screen__hide-small" align="left" style={{ paddingLeft: 16, width: '25%', verticalAlign: 'top' }}>
        <HEValueFormatter.ObservationTime observation={observation} />
      </td>
      <td className="__screen__show-small" align="left" style={{ paddingLeft: 32, width: '50%', verticalAlign: 'top' }}>
        <div>
          <HEValueFormatter.ObservationDate observation={observation} />
        </div>
        <div>
          <HEValueFormatter.ObservationTime observation={observation} />
        </div>
      </td>
    </tr>
  );
};


const RelatedHealthEntries = (props) => {
  const { trackable, entryTrackables, heLookup, languageCode } = props;

  const heIds = entryTrackables.filter(et => et.trackable_id === trackable.id);
  if (heIds.length === 0) { return null; }
  const heNames = heIds.map(h => {
    const he = heLookup[h.health_entry_id];
    return he ? generalHealthEntry(he, languageCode).medliTerm.formattedTermName()  : '';
  }).filter(n => n).join(', ');

  return (
    <span className="font-subhead font-color-gsMedDark">
      <FormattedMessage id="share.relatedFromTrackable.label" values={{ string1: heNames }} />
    </span>
  );
};

interface iSCMetadataObservation {
  attributes: any[];
  date: iObservationDate;
}
interface iSCMetadataTrackable {
  id: number;
  name: string;
  observations: iSCMetadataObservation[];
}
interface ShareCardTrackableGroupProps {
  trackables: {
    all: iSCMetadataTrackable[];
    latest: iSCMetadataTrackable[];
  };
  languageCode: string;
  entryTrackables: any[];
  heLookup: any;
  includeType: ShareCardIncludedTypeNames;
}

export const ShareCardTrackableGroup: React.FC<ShareCardTrackableGroupProps> = (props) => {
  const { includeType, languageCode, entryTrackables, heLookup } = props;

  const trackables = props.trackables[includeType];

  if (trackables.length === 0) { return null; }

  return (
    <div>
      <GroupTitle
        icon={<Icons.IconTracking.Medium color={COLORS.gsDark} />}
        text={categoryTitleForValues('tracking', includeType)}
      />

      {
        trackables.map((t: any, i) => {
          return (
            <ValueGroupPanel
              key={t.id}
              title={(
                <span>{t.name} <RelatedHealthEntries trackable={t} entryTrackables={entryTrackables} languageCode={languageCode} heLookup={heLookup} /></span>
              )}
              tableStyle={{ width: '100%' }}
              rows={t.observations}
              renderRow={(item) => {
                return (
                  <ObservationRow
                    trackable={t}
                    observation={item}
                    terms={{}}
                    languageCode={languageCode}
                  />
                );
              }}
              footerContent={(
                t.comments.length > 0 ? (
                  <div className="font-subhead font-color-gsMedDark">
                    {
                      t.comments.map(c => <div key={c.id}>{c.content}</div>)
                    }
                  </div>
                 ) : null
              )}
            />
          );
        })
      }
    </div>
  );
};

export default ShareCardHealthEntryValueGroup;
