import React from 'react';
import { FormattedMessage } from 'react-intl';

import HEAttributeFooter from './components/HEAttributeFooter';

import ContentFrame from '../../applicationFrame/ContentFrame';
import { ScrollAwareContext, ScrollAwareScroller, ScrollAwareAppNavStep } from '../../components/ScrollAware';
import ContentLoader from '../../components/ContentLoader';

import { AppHeaderScrollableText } from '../../components/appNav/AppHeaderScrollable';
import ContentWrapper from '../../components/ContentWrapper';
import InputWithLabel from '../../components/InputWithLabel';
import Spacer from '../../components/Spacer';
import HealthEntryGeneAttributes from '../../../constants/HealthEntryGeneAttributes';
import { Card, CardRowUI } from '../../../bphComponents/bundle';
import HealthEntryGeneHelpText from './components/HealthEntryGeneHelpText';

import { useMedliGeneExonFrameType } from '../../hooks/useMedli';
import { useHealthEntryAndValueForActiveProfile } from '../../hooks/useHealthEntry';
import { useNavigate } from '../../hooks/useNavigate';

const SCHEMA = HealthEntryGeneAttributes.find(g => g.name === 'exon');
import RouteNames from '../../appAuthorized/RouteNames';

const InputField = (props) => {
  const { onChange, exonRange } = props;

  return (
    <Card.CardShape>
      <Card.CardContent>
        <div className="font-color-gsDark font-caption-1" style={{ marginTop: 3, marginBottom: 7 }}>
          <FormattedMessage id="healthEntry.geneExonRange.field.label" />
        </div>
        <InputWithLabel
          regularHint={(
            <div className="font-color-gsMedDark font-footnote">
              <FormattedMessage id="healthEntry.geneExonRange.field.hint" />
            </div>
          )}
          value={exonRange || ''}
          noForcedLabel
          noForcedHeight
          onChange={onChange}
        />
        <Spacer size="1" />
      </Card.CardContent>
    </Card.CardShape>
  );
};

const ExonFrameType = (props) => {
  const { onSelect, cui } = props;
  const [{ geneExonFrameTypeList }] = useMedliGeneExonFrameType();
  // const selectedExonFrameTypeCui = healthEntryValueIsLoaded && healthEntryValue.geneVariantResult.getExonFrameTypeCui();
  const [isChanged, setIsChanged] = React.useState(false);

  return (
    <Card.CardShape>
      {
        geneExonFrameTypeList.map(eft => (
          <CardRowUI.SelectableRow
            key={eft.cui}
            onClick={() => {
              setIsChanged(true);
              onSelect(eft);
            }}
            selectedClassName="fill-color-profile-highlight"
            isSelected={eft.cui === cui}
            hasBottomBorder={true}
          >
            {eft.string}
          </CardRowUI.SelectableRow>
        ))
      }
      <CardRowUI.SelectableRow
        onClick={() => {
          setIsChanged(true);
          onSelect({});
        }}
        selectedClassName="fill-color-profile-highlight"
        isSelected={isChanged && !cui}
        hasBottomBorder={true}
      >
        <FormattedMessage id="healthEntry.geneExonFrameType.unsure.button.label" />
      </CardRowUI.SelectableRow>
    </Card.CardShape>
  );
};

interface Props {
  match: any;
}
const HealthEntryGeneExon: React.FC<Props> = (props) => {
  const healthEntryId = Number(props.match.params.healthEntryId);
  const healthEntryValueId = Number(props.match.params.valueId);

  const [hevState, hevActions] = useHealthEntryAndValueForActiveProfile(healthEntryId, healthEntryValueId);
  const sourceHevInstance = hevActions.instantiateHealthEntryValue();
  const pendingHevInstance = hevActions.instantiateEditingHealthEntryValue();
  const isDeletable = !!(sourceHevInstance.geneVariantResult && (sourceHevInstance.geneVariantResult.getExonRange() || sourceHevInstance.geneVariantResult.getExonFrameTypeCui()));
  const isLoaded = hevState.isLoaded;

  const [, navActions] = useNavigate();
  const [hasChanges, setHasChanges] = React.useState(false);

  return (
    <div>
      <ScrollAwareContext>
        <ScrollAwareAppNavStep
          submitDisabled={false}
          cancel={() => {
            navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
            hevActions.resetEditing();
          }}
          submit={() => {
            if (!hasChanges) {
              navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
              return;
            }
            const newHevInstance = hevActions.instantiateEditingHealthEntryValue();
            hevActions.apiUpdate(newHevInstance.data())
              .then(() => {
                navActions.goBackTo(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
                hevActions.resetEditing();
              });
          }}
          color="white"
        />

        <ContentLoader isContentLoaded={isLoaded} blockRenderUntilLoaded>
          <ContentFrame>
            <ScrollAwareScroller>
              <div>
                <AppHeaderScrollableText
                  id="healthEntry.geneExon.title.text"
                />

                <ContentWrapper>
                  <HealthEntryGeneHelpText
                    heading="healthEntry.geneExon.helpBlock.heading"
                    content="healthEntry.geneExon.helpBlock.body"
                  />

                  <InputField
                    exonRange={pendingHevInstance.geneVariantResult.getExonRange()}
                    onChange={(e) => {
                      const v = e.target.value || null;
                      const newHevInstance = hevActions.instantiateEditingHealthEntryValue();
                      newHevInstance.geneVariantResult.setExonRange(v);
                      setHasChanges(true);
                      hevActions.updateEditing(newHevInstance.data());
                    }}
                  />
                  <ExonFrameType
                    cui={pendingHevInstance.geneVariantResult.getExonFrameTypeCui()}
                    onSelect={(exonFrameType) => {
                      const newHevInstance = hevActions.instantiateEditingHealthEntryValue();
                      newHevInstance.geneVariantResult.setExonFrameType(exonFrameType.id);
                      newHevInstance.geneVariantResult.setExonFrameTypeCui(exonFrameType.cui);
                      setHasChanges(true);
                      hevActions.updateEditing(newHevInstance.data());
                    }}
                  />

                  {
                    isDeletable && (
                      <HEAttributeFooter
                        isFlagged={null}
                        onDelete={() => {
                          const attributeNames = SCHEMA.values.map(v => v.geneVariantResultKey);
                          const newHevInstance = hevActions.instantiateEditingHealthEntryValue();
                          newHevInstance.geneVariantResult.clearAttributeValues(attributeNames);
                          hevActions.apiUpdate(newHevInstance.data())
                            .then(() => {
                              navActions.goBack(RouteNames.healthEntryValue(healthEntryId, healthEntryValueId));
                            });
                        }}
                        onToggleFlag={null}
                      />
                    )
                  }
                </ContentWrapper>
              </div>
            </ScrollAwareScroller>
          </ContentFrame>
        </ContentLoader>
      </ScrollAwareContext>
    </div>
  );
};

export default HealthEntryGeneExon;
