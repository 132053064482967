import React from 'react';

import List, { BasicListItem } from '../../../../components/List';
import { PrivacyDisclaimerHeader } from '../../../../components/PrivacyHeader';
import { BackpackThemeMarkdown } from '../../../../components/Markdown';
import Spacer from '../../../../components/Spacer';
import { GrayCard } from '../../../../components/card/Card';

export const ShareCardDisclaimer = () => {
  return (
    <GrayCard className=" util-inset-content-margin">
      <List hasBorder paddingSize="large">
        <BasicListItem className="util-inset-listitem-frame-padding">
          <PrivacyDisclaimerHeader />

          <Spacer size="6" />

          <div className="font-color-gsMiddle font-caption-1">
            <BackpackThemeMarkdown phraseId="shareCard.confidentialBlock.message.md" />
          </div>
        </BasicListItem>
      </List>
    </GrayCard>
  );
};
