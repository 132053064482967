import React from 'react';
import _ from 'lodash';

// import { useQuery } from 'react-query';

import { useDispatch, useSelector } from 'react-redux';
// import { receivePolicies } from '../redux/ducks/policy';
import { actions as policyActions } from '../redux/ducks/policy';

// import PolicyService from '../../apiServices/PolicyService';

// function fetchPolicy(key) {
//   return new Promise((res) => {
//     PolicyService.getAll((data) => {
//       res(data);
//     });
//   });
// }

// export function usePolicy() {
//   const dispatch = useDispatch();
//   const { status, data, error, refetch } = useQuery(['policy'], fetchPolicy);
//   const isLoaded = Boolean(data) && status === 'success';

//   React.useEffect(() => {
//     if (isLoaded) {
//       dispatch(receivePolicies(data));
//     }
//   }, [isLoaded]);

//   return { isLoaded, data, refetch };
// }

export function usePolicy(forceRefresh?) {
  const dispatch = useDispatch();
  const policyList = useSelector(state => _.get(state, 'policy.collection', []));
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    if (forceRefresh) {
      dispatch(policyActions.requestAllPolicies())
      .then(() => {
        setIsLoaded(true);
      });
    }
  }, []);

  return [
    {
      isLoaded,
    },
    {
      getPolicyByName: (name) => {
        return policyList.find(p => p.name === name);
      },
      getPolicy: (name, id, languageCode) => {
        const policy = name ? policyList.find(p => p.name === name) : policyList.find(p => p.id === id);
        return policy ? {
          ...policy.i18n[languageCode],
          version: policy.version,
        } : null;
      },
    },
  ];
}
